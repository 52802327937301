import styles from "./components.module.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Toolbar,
  Typography,
  Chip,
  Autocomplete,
  Popper,
  Backdrop,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CircleIcon from "@mui/icons-material/Circle";
import { toast } from "react-toastify";
import Select from "react-select";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { createAxiosInstance } from "../../../../api/axios";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  getInfoByRutNameContractP_externo,
  liveSearchByRut,
} from "../../../../redux/features/users/userSlice";
import { format } from "date-fns";
import { fetchHabitacionesDisponibles } from "../../../../redux/features/habitaciones/habitacionSlice";
import { sanitizeFilterModel } from "@mui/x-data-grid/hooks/features/filter/gridFilterUtils";
import { reasignacionHabitacionesColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import { addReasignar } from "../../../../redux/features/reservas/reservaSlice";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const VerInfoTrabajador = ({
  open2,
  setOpen2,
  traStatus,
  trabajadores,
  pabellones,
  wings,
  criticidadRequerimientos,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [search, setSearch] = useState("");
  const [selectedRut, setSelectedRut] = useState(null);
  const axiosInstance = createAxiosInstance();
  const [pageSize, setPageSize] = useState(10);
  const [value, setValue] = useState(0);
  const [info, setInfo] = useState({});
  const [ruts, setRuts] = useState([]);
  const [reservas, setReservas] = useState([]);
  const [ultimaHabitacion, setUltimaHabitacion] = useState(null);
  const [habitacionActual, setHabitacionActual] = useState(null);
  const [reclamos, setReclamos] = useState([]);

  const [habitaciones, setHabitaciones] = useState([]);
  const [filterHabitaciones, setFilterHabitaciones] = useState([]);
  const finalColumnsHabitaciones = reasignacionHabitacionesColumns();

  const [pab, setPab] = useState({});
  const [reasignacion, setReasignacion] = useState(null);
  const [win, setWin] = useState({});
  const [searchValue, setSearchValue] = useState("");

  const [habitacionesFilterModel, setHabitacionesFilterModel] = useState({
    items: [],
  });

  const handleHabitacionesFilterModelChange = (newFilterModel) => {
    setHabitacionesFilterModel(sanitizeFilterModel(newFilterModel));
  };
  const seleccionTablaHabitaciones = (selection) => {
    console.log("selection", selection);
    console.log("trabajador actual", info);
    console.log("reservas", reservas);

    const trabajador = {
      trabajador: info,
    };

    const habitacionActual = filterHabitaciones.find(
      (hab) => hab.id === selection[0]
    );
    console.log("habitacionActual: ", habitacionActual);

    const mappedHabs = filterHabitaciones.map((hab) => {
      if (hab.id == habitacionActual.id) {
        return {
          ...hab,
          asignados: [trabajador],
        };
      }
      return {
        ...hab,
        asignados: [],
      };
    });
    setFilterHabitaciones(mappedHabs);
    setReasignacion({
      idSolicitud: reservas[0].solicitudReservaId,
      rut: info.rut,
      idHabitacion: selection[0],
    });
  };

  const habitacionesDisponibles = async (fechaIngreso, fechaSalida) => {
    try {
      // setCount(1);
      setOpen2(true);
      const resp = await dispatch(
        fetchHabitacionesDisponibles({
          FechaIngreso: fechaIngreso,
          FechaSalida: fechaSalida,
        })
      );
      console.log("habitacionesDisponibles", resp.payload);
      setFilterHabitaciones(resp.payload);
      setHabitaciones(resp.payload);
    } catch (error) {
      console.log("Error al traer las habitaciones disponibles.");
    } finally {
      setOpen2(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (inputValue) => {
    setSearch(inputValue);
  };

  useEffect(() => {
    // No ejecutar la búsqueda si no hay texto
    if (!searchValue) return;

    const handler = setTimeout(async () => {
      try {
        setOpen2(true);
        const resp = await dispatch(liveSearchByRut(searchValue));
        setRuts(resp.payload.data);
      } catch (error) {
        console.log(error);
      } finally {
        setOpen2(false);
      }
    }, 1000); // Espera de 1 segundo (1000 milisegundos)

    // Limpiar timeout en caso de que el usuario escriba antes de que se complete el tiempo
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue, dispatch]);

  // Handler para el cambio en el input del autocomplete
  const onChangeRutInput = (e) => {
    setSearchValue(e.target.value);
  };

  const onChangeRut = async (e) => {
    try {
      setOpen2(true);
      const resp = await dispatch(liveSearchByRut(e.target.value));
      console.log("resp: ", resp.payload.data);
      setRuts(resp.payload.data);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen2(false);
    }
  };

  const onChangeRutSelect = async (e, newValue) => {
    setInfo({});
    setReservas(null);
    setUltimaHabitacion([]);
    setReclamos([]);
    setPab({});
    setWin({});

    if (newValue) {
      setSelectedRut(newValue);
      setLoading(true);

      try {
        const resp = await axiosInstance.get(
          `/api/v1/Solicitud/InformacionHuesped/${newValue.rut}`
        );
        if (!resp.data || resp.data.message === "Trabajador no encontrado") {
          toast.warning(resp.data.message);
          return;
        }
        console.log(
          "reservasEnCursoOProximas: ",
          resp.data.data.reservasEnCursoOProximas
        );
        if (
          Array.isArray(resp.data.data.reservasEnCursoOProximas) &&
          resp.data.data.reservasEnCursoOProximas.length > 0
        ) {
          const { fechaIngreso, fechaSalida, id } =
            resp.data.data.reservasEnCursoOProximas[0];
          habitacionesDisponibles(fechaIngreso, fechaSalida);
        }

        setInfo(resp.data.data.trabajadorP_EXTERNO);
        if (
          resp.data.data.ultimaHabitacion &&
          resp.data.data.ultimaHabitacion.habitacion
        ) {
          setUltimaHabitacion(resp.data.data.ultimaHabitacion);
        }

        if (
          Array.isArray(resp.data.data.ultimosReclamos) &&
          resp.data.data.ultimosReclamos.length > 0
        ) {
          const map = resp.data.data.ultimosReclamos.map((item) => {
            const reclamo = criticidadRequerimientos.find(
              (it) => it.id === item.criticidadRequerimientoId
            );
            return {
              ...item,
              criticidadRequerimiento: reclamo,
            };
          });
          setReclamos(map);
        }
        if (
          Array.isArray(resp.data.data.reservasEnCursoOProximas) &&
          resp.data.data.reservasEnCursoOProximas.length > 0
        ) {
          const map = resp.data.data.reservasEnCursoOProximas.map((item) => {
            console.log("item", item);

            if (item.habitacion) {
              // const habitacion = habitaciones.find(
              //   (it) => it.id === item.habitacionId
              // );
              // console.log("habitacion", habitacion);
              // Verificar si la fecha actual está entre fechaIngreso y fechaSalida
              const fechaActual = new Date();
              const fechaIngreso = new Date(item.fechaIngreso);
              const fechaSalida = new Date(item.fechaSalida);

              setHabitacionActual(item);
              if (fechaActual >= fechaIngreso && fechaActual <= fechaSalida) {
                // if (fechaActual >= fechaIngreso ) {
              } else {
                console.log("fechaActual", fechaActual);
                console.log("fechaIngreso", fechaIngreso);
              }

              const pabellon =
                item.habitacion.pabellonId !== null &&
                item.habitacion.pabellonId !== undefined
                  ? pabellones.find(
                      (pabellonItem) =>
                        pabellonItem.id === item.habitacion.pabellonId
                    )
                  : null;

              const wing =
                item.habitacion.wingId !== null &&
                item.habitacion.wingId !== undefined
                  ? wings.find(
                      (wingItem) => wingItem.id === item.habitacion.wingId
                    )
                  : null;

              console.log("pabellon", pabellon);
              console.log("wing", wing);

              return {
                ...item,
                habitacion: {
                  ...item.habitacion,
                  pabellon: pabellon,
                  wing: wing,
                },
              };
            } else {
              return item;
            }
          });

          setReservas(map);
        }

        console.log("info: ", resp.data.data);
        if (resp.data.data.ultimaHabitacion.habitacion.pabellonId) {
          const findPab = pabellones.find(
            (item) =>
              item.id === resp.data.data.ultimaHabitacion.habitacion.pabellonId
          );
          console.log("findPab", findPab);
          setPab(findPab);
        } else if (resp.data.data.ultimaHabitacion.habitacion.wingId) {
          const findWing = wings.find(
            (item) =>
              item.id === resp.data.data.ultimaHabitacion.habitacion.wingId
          );
          console.log("findWing", findWing);
          setWin(findWing);
        }
      } catch (error) {
        console.log("Error al obtener la info del trabajador:", error.message);
      } finally {
        setLoading(false);
      }
    } else {
      setSelectedRut(null);
    }
  };

  const reasignar = async () => {
    setOpen2(true);
    try {
      const resp = await dispatch(addReasignar([reasignacion]));
      if (resp.payload.status === "success") {
        setOpen(false);
        setFilterHabitaciones([]);
        setHabitaciones([]);
        setInfo([]);
        setReasignacion(null);
        setReservas([]);
        setReclamos([]);
        setUltimaHabitacion([]);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setOpen2(false);
    }
  };

  useEffect(() => {
    if (open === false) {
      setValue(0);
      setInfo({});
      setRuts([]);
      setReservas([]);
      setUltimaHabitacion(null);
      setHabitacionActual(null);
      setReclamos([]);
      setHabitaciones([]);
      setFilterHabitaciones([]);
      setPab({});
      setReasignacion(null);
      setWin({});
      setSearchValue("");
      setSearch("");
      setSelectedRut(null);
    }
  }, [open]);

  useEffect(() => {
    console.log("trabajadores: ", trabajadores);
  }, [trabajadores]);
  useEffect(() => {
    console.log("pabellones: ", pabellones);
  }, [pabellones]);
  useEffect(() => {
    console.log("wings: ", wings);
  }, [wings]);
  useEffect(() => {
    console.log("criticidadRequerimientos: ", criticidadRequerimientos);
  }, [criticidadRequerimientos]);
  useEffect(() => {
    console.log("reclamos: ", reclamos);
  }, [reclamos]);
  useEffect(() => {
    console.log("reservas: ", reservas);
  }, [reservas]);
  useEffect(() => {
    console.log("reasignacion: ", reasignacion);
  }, [reasignacion]);

  return (
    <div>
      <Grid container={true}>
        <Grid item xs={12} md={7}>
          <Typography style={{ fontSize: "14px" }}>
            Buscar información de un trabajador.
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Button
            variant="contanied"
            size="large"
            className={styles.bgColor}
            onClick={() => setOpen(true)}
          >
            <CircleIcon className={styles.iconButton} />
            Buscar
          </Button>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        maxWidth="lg"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display: "flex", gap: "5px" }}>
          <PersonSearchIcon
            className={styles.iconBgCreate}
            style={{ fontSize: "3rem", marginTop: "0px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="span"
              style={{ fontSize: "24px", lineHeight: "1" }}
            >
              Buscar información
            </Typography>
            <span className={styles.subTitle}>
              Selecciona un trabajador para ver su información.
            </span>
          </div>
        </DialogTitle>
        {/*{JSON.stringify(formData)} */}
        <DialogContent style={{ height: "80vh" }}>
          <Grid container={true} spacing={1}>
            <Grid item xs={12} sm={12} mb={2}>
              <Autocomplete
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-clearIndicator": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    color: "#2d3037",
                  },
                }}
                disablePortal
                disableClearable
                id="sectores-autocomplete"
                name="rut"
                value={selectedRut}
                options={ruts || []}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    sx={{
                      overflowY: "auto",
                    }}
                  />
                )}
                getOptionLabel={(option) => `${option.rut} ${option.nombre}`}
                onChange={onChangeRutSelect}
                renderOption={(props, option) => (
                  <li {...props} key={option.rut}>
                    {option.rut} {option.nombre}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ingresa un rut para buscar un trabajador"
                    onChange={onChangeRutInput}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              style={{ borderRight: "none" }}
              sx={{
                borderRight: 1,
                borderColor: "divider",
                "& .MuiTabs-indicator": {
                  backgroundColor: "#FFF",
                  height: 3,
                },
                "& .MuiTab-root.Mui-selected": {
                  color: "#2d3037",
                },
                "& .MuiTabs-root": {
                  borderRight: "none",
                },
              }}
            >
              <Tab label="Información" {...a11yProps(0)} />
              <Tab label="Reservas" {...a11yProps(1)} />
              <Tab label="Reclamos" {...a11yProps(2)} />
              <Tab label="Reasignar" {...a11yProps(3)} />
            </Tabs>
          </Box>
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <CircularProgress color="inherit" size={24} />
            </div>
          ) : (
            info?.rut && (
              <>
                <CustomTabPanel value={value} index={0}>
                  <Grid container={true} spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <Typography>Rut: </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>{info?.rut}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>Nombre: </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>{info?.nombre}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>Apellidos: </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        {info?.apellidoPaterno} {info?.apellidoMaterno}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>Cargo:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>{info?.cargo ?? "-"}</Typography>
                    </Grid>
                    {info?.email && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <Typography>Email:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography>{info?.email}</Typography>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} sm={6}>
                      <Typography>Acreditación:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        {info?.acreditado ? "Vigente" : "No Vigente"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>Ultima entrada a garita:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        {info?.ultimaEntrada
                          ? format(info?.ultimaEntrada, "dd-MM-yyyy")
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>Ultima habitaciòn utilizada:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {ultimaHabitacion.habitacion ? (
                        <>
                          <Typography>
                            N°{ultimaHabitacion?.habitacion?.numero},{" "}
                            {pab.identificador} {win.nombre},{" "}
                            {pab.campamento?.nombre}
                          </Typography>
                          <Typography>
                            Desde{" "}
                            {ultimaHabitacion?.fechaIngreso &&
                              format(
                                ultimaHabitacion?.fechaIngreso,
                                "dd-MM-yyyy"
                              )}
                          </Typography>
                          <Typography>
                            Hasta{" "}
                            {ultimaHabitacion?.fechaSalida &&
                              format(
                                ultimaHabitacion?.fechaSalida,
                                "dd-MM-yyyy"
                              )}
                          </Typography>
                        </>
                      ) : (
                        <Typography>-</Typography>
                      )}
                    </Grid>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Grid container={true} spacing={1}>
                    {Array.isArray(reservas) && reservas.length > 0 ? (
                      reservas.map((reserva) => (
                        <>
                          <Grid item xs={12} sm={6}>
                            <Typography>ID Solicitud: </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>
                              {reserva?.solicitudReservaId}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>Estado: </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>
                              {reserva?.solicitudReserva?.estado}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>Contrato: </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>
                              {reserva?.solicitudReserva?.contrato}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>Empresa: </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>
                              {reserva?.solicitudReserva?.empresa
                                ? reserva?.solicitudReserva?.empresa
                                    ?.razonSocial
                                : "-"}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>Fecha Ingreso: </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>
                              {reserva?.fechaIngreso &&
                                format(reserva?.fechaIngreso, "dd-MM-yyyy")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>Fecha Salida: </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>
                              {reserva?.fechaSalida &&
                                format(reserva?.fechaSalida, "dd-MM-yyyy")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>Habitación: </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {reserva?.habitacion ? (
                              <Typography>
                                {reserva?.habitacion?.numero},{" "}
                                {reserva?.habitacion?.pabellon?.identificador}{" "}
                                {reserva?.habitacion?.wing?.nombre},{" "}
                                {
                                  reserva?.habitacion?.pabellon?.campamento
                                    ?.nombre
                                }{" "}
                                {reserva?.habitacion?.wing?.campamento?.nombre}
                              </Typography>
                            ) : (
                              <Typography> - </Typography>
                            )}
                          </Grid>
                          <div
                            style={{
                              height: "1px",
                              backgroundColor: "#212227",
                              width: "100%",
                              margin: "5px 0",
                            }}
                          ></div>
                        </>
                      ))
                    ) : (
                      <Typography style={{ fontSize: "16px" }}>
                        Trabajador no presenta reservas.
                      </Typography>
                    )}
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <Grid container={true} spacing={1}>
                    {Array.isArray(reclamos) && reclamos.length > 0 ? (
                      reclamos.map((reclamo) => (
                        <>
                          <Grid item xs={12} sm={6}>
                            <Typography>ID: </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>{reclamo.id}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>Fecha: </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>
                              {reclamo.fecha &&
                                format(reclamo.fecha, "dd-MM-yyyy")}
                            </Typography>
                          </Grid>
                          {/*<Grid item xs={12} sm={6}>
                              <Typography>Campamento: </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>Pioneros</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>Pabellón: </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>23 NORTE</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>Habitación:</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>32</Typography>
                          </Grid>*/}
                          <Grid item xs={12} sm={6}>
                            <Typography>Especialidad:</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>
                              {reclamo.criticidadRequerimiento.especialidad}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>Solicitud:</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>
                              {reclamo.criticidadRequerimiento.solicitud}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>Estado:</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>{reclamo.estado}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>Descripción:</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>{reclamo.descripcion}</Typography>
                          </Grid>
                          <div
                            style={{
                              height: "1px",
                              backgroundColor: "#212227",
                              width: "100%",
                              margin: "5px 0",
                            }}
                          ></div>
                        </>
                      ))
                    ) : (
                      <Typography style={{ fontSize: "16px" }}>
                        Trabajador no presenta reclamos o sugerencias.{" "}
                      </Typography>
                    )}
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                  <Grid container={true} spacing={1}>
                    {habitacionActual ? (
                      <Grid item xs={12}>
                        <Typography variant="h6">Habitación actual</Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Typography style={{ fontSize: "16px" }}>
                          Trabajador no presenta habitación actual.
                        </Typography>
                      </Grid>
                    )}
                    {habitacionActual && (
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Typography variant="span">
                          N°:{" "}
                          {habitacionActual &&
                            habitacionActual?.habitacion &&
                            habitacionActual.habitacion.numero}
                        </Typography>
                        <Typography variant="span">
                          {pab
                            ? "Pabellón: " + pab?.identificador
                            : "Wing: " + win?.nombre}
                        </Typography>
                        <Typography variant="span">
                          Tipo cama:{" "}
                          {habitacionActual &&
                            habitacionActual?.habitacion &&
                            habitacionActual.habitacion.tipoCama}
                        </Typography>
                        <Typography variant="span">
                          N° de camas:{" "}
                          {habitacionActual &&
                            habitacionActual?.habitacion &&
                            habitacionActual.habitacion.numeroTotalCamas}
                        </Typography>
                        <Typography variant="span">
                          Cargos reservados:{" "}
                          {habitacionActual &&
                            habitacionActual?.habitacion &&
                            habitacionActual.habitacion.cargosReservados
                              .map((item) => item)
                              .join(", ")}
                        </Typography>
                      </Grid>
                    )}
                    {habitacionActual && (
                      <Grid item xs={12} style={{ marginTop: "5px" }}>
                        <Typography variant="h6">
                          Habitaciónes disponibles
                        </Typography>
                        <DataGrid
                          disableMultipleSelection
                          filterModel={habitacionesFilterModel}
                          onFilterModelChange={
                            handleHabitacionesFilterModelChange
                          }
                          onRowSelectionModelChange={seleccionTablaHabitaciones}
                          getRowId={(row) => (row.id ? row.id : Math.random())}
                          lots={{ toolbar: GridToolbar }}
                          rows={filterHabitaciones || []}
                          rowHeight={80}
                          columns={finalColumnsHabitaciones}
                          pageSize={pageSize}
                          onPageSizeChange={(newPageSize) =>
                            setPageSize(newPageSize)
                          }
                          rowsPerPageOptions={[5, 10, 15, 20]}
                          localeText={{
                            ...esES.components.MuiDataGrid.defaultProps
                              .localeText,
                            noRowsLabel: "No hay datos disponibles",
                            pagination: {
                              labelRowsPerPage: "Filas por página:",
                            },
                          }}
                          classes={{ menuIcon: styles.menuIcon }}
                          style={{
                            maxHeight: "500px",
                            color: "#FFF",
                            border: "none",
                            margin: "0",
                          }}
                          sx={{
                            "& .MuiDataGrid-cell": {
                              color: "#2d3037",
                              borderBottom: "1px solid #c6c6c6",
                            },
                            "& .MuiDataGrid-columnHeader": {
                              color: "#2d3037",
                              borderBottom: "1px solid #c6c6c6",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                              borderBottom: "1px solid #c6c6c6",
                            },
                            "& .MuiDataGrid-footerContainer": {
                              color: "#2d3037",
                              borderTop: "1px solid #c6c6c6",
                            },
                            "& .MuiTablePagination-root": {
                              color: "#2d3037",
                              borderTop: "1px solid #c6c6c6",
                            },
                            "& .MuiDataGrid-toolbarContainer": {
                              color: "#2d3037",
                            },
                            "& .MuiDataGrid-menu": {
                              color: "#2d3037",
                            },
                            "& .MuiTablePagination-actions": {
                              color: "#2d3037", // Color for pagination actions
                            },
                            "& .MuiTablePagination-select": {
                              color: "#2d3037", // Color for pagination select
                            },
                            "& .MuiTablePagination-selectLabel": {
                              color: "#2d3037", // Color for pagination select label
                            },
                            "& .MuiTablePagination-displayedRows": {
                              color: "#2d3037", // Color for displayed rows text
                            },
                          }}
                          slotProps={{
                            toolbar: {
                              sx: {
                                color: "#FFF",
                              },
                            },
                            pagination: {
                              sx: {
                                color: "#2d3037",
                              },
                            },
                            menu: {
                              sx: {
                                color: "#2d3037",
                              },
                            },
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CustomTabPanel>
              </>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Grid container={true}>
            {reasignacion && (
              <Grid item xs={12} sm={6} className={styles.buttonContainer}>
                <Button
                  onClick={reasignar}
                  className={styles.bgColor}
                  variant="contained"
                >
                  Reasignar trabajador
                </Button>
              </Grid>
            )}
            <Grid item xs={12} sm={6} className={styles.buttonContainer}>
              <Button
                onClick={() => setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VerInfoTrabajador;
