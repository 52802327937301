import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import axios, { createAxiosInstance } from "../../../api/axios";
import { getRole } from "../../../utils/validate";
import { Navigate } from "react-router-dom";

const LOGIN_URL = '/api/v1/Auth/login';
const INFO_URL = '/api/v1/Auth/GetInfo';
const SETPASS_URL = '/api/v1/Auth/changePassword';

const token = localStorage.getItem('token');

const config = {
    headers: {
        'Authorization': `Bearer ${token}`
    }
};

const axiosInstance = createAxiosInstance();
export const setPass = createAsyncThunk("login/setPass", async (data, { rejectWithValue }) => {
    console.log('lo que llega', data);

    try {
        const { id, token, pass } = data;
        const encodedToken = encodeURIComponent(token);
        const url = `${SETPASS_URL}?id=${id}&token=${encodedToken}&password=${pass}`;
        const response = await axiosInstance.post(url);
        
        if (response.status === 200) {
            toast.success('Contraseña actualizada!.');
            return { data: response.data, status: 'success' };
        } else {
            // Si la respuesta no tiene un estado 200, consideramos que hay un error
            throw new Error('Error al actualizar la contraseña: ' + response.statusText);
        }
    } catch (error) {
        // Manejar errores de Axios
        if (error.response) {
            // El servidor respondió con un código de estado diferente de 2xx
            const errorMessage = error.response.data || 'Error al actualizar la contraseña';
            toast.error(errorMessage);
            return rejectWithValue({ message: errorMessage, status: 'error' });
        } else if (error.request) {
            // La solicitud fue realizada pero no se recibió una respuesta
            toast.error('No se pudo establecer conexión con el servidor');
            return rejectWithValue({ message: 'No se pudo establecer conexión con el servidor', status: 'error' });
        } else {
            // Ocurrió un error durante la configuración de la solicitud
            toast.error('Error al enviar la solicitud');
            return rejectWithValue({ message: 'Error al enviar la solicitud', status: 'error' });
        }
    }
});


// ACTIONS
export const login = createAsyncThunk("login/login", async ({ email, password })=> {
    console.log(email, password);
    try {
        let auth_token = await axios.post(LOGIN_URL,{ "Username": email, "password": password });

        if (auth_token.data.message !== "Success") {
            return { success: false, message: auth_token.data };
        } else {
            console.log('token', auth_token.data);

            let role = await axios.get(INFO_URL, config);
            // localStorage.setItem('token', auth_token.data.token);
            const decoded = jwtDecode(auth_token.data.token);
        
            Navigate('/dashboard');
            
            return {role:  decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'], user: role.data.nombre };
        }
      } catch (e) {
        return {message: e.message, status: 'error', success: false};
    }
})


// idle : no hay operaciones en curso
const initialState = {
    user: "",
    // result: [],
    role: "",
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const loginSlice = createSlice({
    name:"login",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(login.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(login.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.user) !== JSON.stringify(action.payload.user)) { //fetch
                state.role = action.payload.role;
                state.user = action.payload.user;
            }
        })
        .addCase(login.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(setPass.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                // Solo actualiza el estado si es necesario
                // state.result.push(action.payload.data);
            } else {
                state.status = 'failed';
            }
        });
    }
})

export const getActualRole = (state) => state.login.role;
export const getUser = (state) => state.login.user;
export const getLoginStatus = (state) => state.login.status;
export const getLoginError = (state) => state.login.error;

export const {} = loginSlice.actions;
export default loginSlice.reducer;