import styles from "./components.module.css";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addNewCampamento } from "../../../../redux/features/campamentos/campamentoSlice";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Card,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  format,
  isValid,
  parseISO,
  min,
  max,
  eachDayOfInterval,
  differenceInDays,
} from "date-fns";
import { Calendar } from "react-big-calendar";
import MyCalendar from "./CalendarGestion";
// import MyCalendar from './Calendar';
// import MyCalendar from '../../ingreso/components_ingreso/CalendarNew';
import Table from "./Table";
import { DataGrid } from "@mui/x-data-grid";
import {
  aprobar,
  rechazar,
  updateForecast,
} from "../../../../redux/features/forecast/forecastSlice";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import GroupsIcon from "@mui/icons-material/Groups";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ApartmentIcon from "@mui/icons-material/Apartment";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import InfoIcon from "@mui/icons-material/Info";
import PersonIcon from "@mui/icons-material/Person";
import SkeletonTable from "../../../../components/Skeleton";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Create = ({
  open2,
  setOpen2,
  data,
  vicep,
  ger,
  siArea,
  empresas,
  tramos,
  setTramos,
  registros,
  turnos,
  statusSolicitudes,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedVice, setSelectedVice] = useState(null);
  const [selectedGer, setSelectedGer] = useState(null);
  const [selectedEmp, setSelectedEmp] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);

  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const [value, setValue] = useState(dayjs("2022-04-17"));
  const [value2, setValue2] = useState("1");
  const [filteredTramos, setFilteredTramos] = useState([]);
  const [numeroCalendario, setNumeroCalendario] = useState([1]);
  const [selectedTramosForecast, setSelectedTramosForecast] = useState([]);
  const [totalesPorMes, setTotalesPorMes] = useState();
  const [diasMes, setDiasMes] = useState();
  const [mesesUnicos, setMesesUnicos] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [updatedRegistros, setUpdatedRegistros] = useState([]);
  const [value3, setValue3] = useState("1");

  const [expanded, setExpanded] = useState(false);

  const handleChangeAcordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (registros.length > 0) {
      // console.log('registrossss', registros);
      const newUpdatedRegistros = registros.map((registro) => {
        const updatedTramos = registro.tramos.map((tramo) => ({
          ...tramo,
          fechaDesde: tramo.fechaDesde ? parseISO(tramo.fechaDesde) : null,
          fechaHasta: tramo.fechaHasta ? parseISO(tramo.fechaHasta) : null,
        }));

        const fechasDesde = updatedTramos
          .map((tramo) => tramo.fechaDesde)
          .filter((date) => date !== null);
        const fechasHasta = updatedTramos
          .map((tramo) => tramo.fechaHasta)
          .filter((date) => date !== null);

        const fechaInicio = fechasDesde.length > 0 ? min(fechasDesde) : null;
        const fechaTermino = fechasHasta.length > 0 ? max(fechasHasta) : null;

        const trabajadoresDiaTotal = updatedTramos.reduce(
          (acc, tramo) => acc + tramo.trabajadoresDia,
          0
        );
        const trabajadoresNocheTotal = updatedTramos.reduce(
          (acc, tramo) => acc + tramo.trabajadoresNoche,
          0
        );

        return {
          ...registro,
          fecha_inicio: fechaInicio
            ? format(fechaInicio, "yyyy-MM-dd'T'HH:mm:ss")
            : "",
          fecha_termino: fechaTermino
            ? format(fechaTermino, "yyyy-MM-dd'T'HH:mm:ss")
            : "",
          trabajadores_dia: trabajadoresDiaTotal,
          trabajadores_noche: trabajadoresNocheTotal,
        };
      });

      // console.log(newUpdatedRegistros);
      setUpdatedRegistros(newUpdatedRegistros);
    }
  }, [registros]);

  const changeCalendarNumber = (e) => {
    const count = parseInt(e.target.value);
    if (!isNaN(count)) {
      setNumeroCalendario((prev) => {
        if (prev.length < count) {
          return [...Array(count)].map((_, index) => index + 1);
        } else if (prev.length > count) {
          return prev.slice(0, count);
        } else {
          return prev;
        }
      });
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValue2(newValue);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    const updatedTramos = selectedTramosForecast?.tramos.map((tramo, i) => {
      if (i === index) {
        return { ...tramo, [name]: value };
      }
      return tramo;
    });

    const updatedSelectedTramosForecast = {
      ...selectedTramosForecast,
      tramos: updatedTramos,
    };

    setSelectedTramosForecast(updatedSelectedTramosForecast);

    const updatedRegistros = tramos.map((registro) => {
      if (registro.id === selectedTramosForecast.id) {
        return {
          ...registro,
          tramos: updatedTramos,
        };
      }
      return registro;
    });

    setTramos(updatedRegistros);
  };

  const handleAgregarTramo = () => {
    const nuevoTramo = {
      nombre: "",
      fecha_inicio: "",
      fecha_termino: "",
      trabajadoresDia: 0,
      trabajadoresNoche: 0,
    };

    // Clona el objeto selectedTramosForecast y modifica solo el arreglo tramos
    const nuevoSelectedTramosForecast = {
      ...selectedTramosForecast,
      tramos: [...selectedTramosForecast?.tramos, nuevoTramo],
    };

    setSelectedTramosForecast(nuevoSelectedTramosForecast);
  };

  const handleEliminarTramo = (index) => {
    const updatedTramos = [...selectedTramosForecast?.tramos];
    updatedTramos.splice(index, 1);
    const nuevoSelectedTramosForecast = {
      ...selectedTramosForecast,
      tramos: updatedTramos,
    };
    setSelectedTramosForecast(nuevoSelectedTramosForecast);
  };

  useEffect(() => {
    if (updatedRegistros) {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const filter = updatedRegistros.filter((item) => {
        const startDate = new Date(item.fecha_inicio);
        const startMonth = startDate.getMonth() + 1;
        return startMonth === currentMonth;
      });
      // console.log('filter', filter);
      setTramos(filter);
    }
  }, []);

  const searchTramo = (e) => {
    const searchTerm = e.target.value.trim();
    if (searchTerm === "") {
      setFilteredTramos(tramos);
    } else {
      const filter = tramos.filter((tramo) =>
        tramo.nombre.toLowerCase().includes(e.target.value)
      );
      setFilteredTramos(filter);
    }
  };

  const canSave =
    [
      formData.cantidad_trabajadoresDia,
      formData.cantidad_trabajadoresNoche,
      formData.empresa,
      formData.gerencia,
      formData.vicepresidencia,
      formData.area,
    ].every(Boolean) && addRequestStatus === "idle";

  function calcularCantidadTotal(tramos) {
    const trabajadoresPorDia = {};

    tramos.forEach((tramo, indice) => {
      const inicio = new Date(tramo.fecha_inicio);
      const fin = new Date(tramo.fecha_termino);
      const cantidadTrabajadores =
        parseInt(tramo.trabajadoresDia) + parseInt(tramo.trabajadoresNoche);
      const tramoId = tramo.nombre;

      for (
        let fecha = new Date(inicio);
        fecha <= fin;
        fecha.setDate(fecha.getDate() + 1)
      ) {
        const fechaStr = fecha.toISOString().split("T")[0];

        // no agregar fechas que estén más allá del límite del tramo
        if (fecha <= fin) {
          if (!trabajadoresPorDia[fechaStr]) {
            trabajadoresPorDia[fechaStr] = {
              cantidad: cantidadTrabajadores,
              solapamiento: false,
              tramosPresentes: new Set([tramoId]),
              tramo: tramoId,
            };
          } else {
            trabajadoresPorDia[fechaStr].cantidad += cantidadTrabajadores;
            trabajadoresPorDia[fechaStr].tramosPresentes.add(tramoId);

            if (trabajadoresPorDia[fechaStr].tramosPresentes.size > 1) {
              trabajadoresPorDia[fechaStr].solapamiento = true;
            }
          }
        }
      }
    });

    return trabajadoresPorDia;
  }

  function agruparRegistrosPorFechas(trabajadoresPorDia) {
    const agrupados = {};

    for (const [fecha, data] of Object.entries(trabajadoresPorDia)) {
      if (!agrupados[data.cantidad]) {
        agrupados[data.cantidad] = {
          inicio: fecha,
          fin: fecha,
          solapamiento: data.solapamiento,
          tramos: Array.from(data.tramosPresentes),
        };
      } else {
        if (!agrupados[data.cantidad].solapamiento && data.solapamiento) {
          agrupados[data.cantidad].solapamiento = true;
        }
        agrupados[data.cantidad].fin = fecha;
        data.tramosPresentes.forEach((tramo) => {
          if (!agrupados[data.cantidad].tramos.includes(tramo)) {
            agrupados[data.cantidad].tramos.push(tramo);
          }
        });
      }
    }

    return agrupados;
  }

  const trabajadoresPorDia = calcularCantidadTotal(tramos);
  const registrosAgrupados = agruparRegistrosPorFechas(trabajadoresPorDia);

  function obtenerTramosConFechas(tramos) {
    const tramosConFechas = {};

    if (tramos.length > 0) {
      tramos.forEach((tramo, index) => {
        const tramoId = `T${index + 1}`;
        const fechaInicio = tramo.fecha_inicio
          ? new Date(tramo.fecha_inicio)
          : null;
        const fechaTermino = tramo.fecha_termino
          ? new Date(tramo.fecha_termino)
          : null;

        if (fechaInicio && fechaTermino) {
          tramosConFechas[tramoId] = {
            fechaInicio: fechaInicio.toISOString().split("T")[0],
            fechaTermino: fechaTermino.toISOString().split("T")[0],
          };
        }
      });
    }

    return tramosConFechas;
  }

  const tramosConFechas = obtenerTramosConFechas(tramos);

  //-------------------- NUEVO RENDER -------------------------------
  // Función para crear una instancia de Date si la fecha es válida
  const createDateInstance = (dateString) => {
    if (!dateString) return null; // Si dateString es undefined o null, retornar null
    const date = parseISO(dateString);
    return isValid(date) ? date : null;
  };

  // Luego, en tus funciones donde utilizas fechas, puedes usar esta función para crear instancias de Date solo si la fecha es válida:
  const seSolapan = (evento1, evento2) => {
    const inicio1 = createDateInstance(evento1.fecha_inicio)?.getTime();
    const termino1 = createDateInstance(evento1.fecha_termino)?.getTime();
    const inicio2 = createDateInstance(evento2.fecha_inicio)?.getTime();
    const termino2 = createDateInstance(evento2.fecha_termino)?.getTime();

    if (
      inicio1 === null ||
      termino1 === null ||
      inicio2 === null ||
      termino2 === null
    ) {
      // Manejar el caso de fechas inválidas aquí
      return false;
    }

    return inicio1 < termino2 && inicio2 < termino1;
  };

  const paresSolapadosConTrabajadores = [];

  // Llenar la lista con los pares de eventos que se solapan y la cantidad total de trabajadores
  tramos.forEach((evento1, index1) => {
    tramos.forEach((evento2, index2) => {
      if (
        index1 !== index2 &&
        seSolapan(evento1, evento2) &&
        !paresSolapadosConTrabajadores.some(
          ([e1, e2, _]) => e1 === evento2 && e2 === evento1
        )
      ) {
        const trabajadoresSolapados = tramos
          .filter(
            (evento) => seSolapan(evento1, evento) || seSolapan(evento2, evento)
          )
          .reduce(
            (total, evento) =>
              total +
              parseInt(evento.trabajadores_dia) +
              parseInt(evento.trabajadores_noche),
            0
          );
        paresSolapadosConTrabajadores.push([
          evento1,
          evento2,
          trabajadoresSolapados,
        ]);
      }
    });
  });

  const calcularTrabajadoresPorDia = () => {
    const trabajadoresPorDia = {};

    tramos.forEach((proyecto) => {
      const fechaInicio = proyecto.fecha_inicio;
      const fechaTermino = proyecto.fecha_termino;
      const trabajadoresDia = parseInt(proyecto.trabajadores_dia);
      const trabajadoresNoche = parseInt(proyecto.trabajadores_noche);

      for (
        let fecha = new Date(fechaInicio);
        fecha <= new Date(fechaTermino);
        fecha.setDate(fecha.getDate() + 1)
      ) {
        const fechaString = fecha.toISOString().split("T")[0];
        if (!trabajadoresPorDia[fechaString]) {
          trabajadoresPorDia[fechaString] = 0;
        }
        trabajadoresPorDia[fechaString] += trabajadoresDia + trabajadoresNoche;
      }
    });

    return trabajadoresPorDia;
  };

  const trabajadoresPorDiaFunct = calcularTrabajadoresPorDia();

  const inputRefs = useRef([]);

  const calcularTotalesPorMes = (registros) => {
    const totalesPorMesEmpresa = {};

    registros.forEach((registro) => {
      const fechaInicio = new Date(registro.fecha_inicio);
      const mes = fechaInicio.getMonth() + 1; // Sumamos 1 porque los meses se indexan desde 0
      const año = fechaInicio.getFullYear();
      const key = `${año}-${mes}`;

      if (!totalesPorMesEmpresa[key]) {
        totalesPorMesEmpresa[key] = {};
      }

      const empresa = registro.empresa.razonSocial;
      const trabajadoresDia = registro.trabajadores_dia;
      const trabajadoresNoche = registro.trabajadores_noche;

      if (!totalesPorMesEmpresa[key][empresa]) {
        totalesPorMesEmpresa[key][empresa] = {
          totalDia: trabajadoresDia,
          totalNoche: trabajadoresNoche,
        };
      } else {
        totalesPorMesEmpresa[key][empresa].totalDia += trabajadoresDia;
        totalesPorMesEmpresa[key][empresa].totalNoche += trabajadoresNoche;
      }
    });

    return totalesPorMesEmpresa;
  };

  const getFechasEntre = (fechaInicio, fechaFin) => {
    const fechas = [];
    let fechaActual = new Date(fechaInicio);
    const fechaFinTemp = new Date(fechaFin);

    while (fechaActual <= fechaFinTemp) {
      fechas.push(new Date(fechaActual).toISOString().slice(0, 10)); // Formato: YYYY-MM-DD
      fechaActual.setDate(fechaActual.getDate() + 1);
    }

    return fechas;
  };

  // Función para calcular la cantidad total de trabajadores para cada día
  const calcularTotalTrabajadoresPorDia = (fecha, datos) => {
    let totalTrabajadores = 0;

    datos.forEach((tramo) => {
      if (fecha >= tramo.fecha_inicio && fecha <= tramo.fecha_termino) {
        totalTrabajadores += tramo.trabajadores_dia + tramo.trabajadores_noche;
      }
    });

    return totalTrabajadores;
  };

  // Función para encontrar el día con mayor cantidad de trabajadores por mes
  const encontrarDiaMaximoPorMes = () => {
    const fechasUnicas = new Set();
    tramos.forEach((tramo) => {
      const fechas = getFechasEntre(tramo.fecha_inicio, tramo.fecha_termino);
      fechas.forEach((fecha) => fechasUnicas.add(fecha));
    });

    const arrayFechasUnicas = [...fechasUnicas].sort(
      (a, b) => new Date(a) - new Date(b)
    );

    const diasMaximosPorMes = {};

    arrayFechasUnicas.forEach((fecha) => {
      const totalTrabajadores = calcularTotalTrabajadoresPorDia(fecha, tramos);
      const mesAnyo = `${new Date(fecha).getFullYear()}-${(
        new Date(fecha).getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}`;

      if (
        !diasMaximosPorMes[mesAnyo] ||
        totalTrabajadores > diasMaximosPorMes[mesAnyo].totalTrabajadores
      ) {
        diasMaximosPorMes[mesAnyo] = { fecha, totalTrabajadores };
      }
    });

    // Obtener el mes y el año actual
    const mesActual = new Date().getMonth() + 1;
    const anyoActual = new Date().getFullYear();

    // Obtener el mes actual en el formato "YYYY-MM"
    const mesActualString = `${anyoActual}-${mesActual
      .toString()
      .padStart(2, "0")}`;

    // Obtener el resultado para el mes actual
    const resultadoMesActual = diasMaximosPorMes[mesActualString];

    // Crear el objeto de retorno con la misma estructura
    const retorno = {};
    retorno[mesActualString] = resultadoMesActual;

    return retorno;
  };

  // Función para renderizar el listado de fechas por día de los tramos
  // const renderizarListado = () => {
  //   if(tramos.length > 0){
  //     const mapped = tramos.flatMap(item => item.tramos);
  //     const a = Array.isArray(mapped) && mapped.filter(evento => evento.fechaDesde && evento.fechaHasta);

  //     // Obtener todas las fechas únicas de los tramos
  //     let minFechaDesde = tramos[0].fecha_inicio ?  parseISO(tramos[0].fecha_inicio) : '';
  //     let maxFechaHasta = tramos[0].fecha_termino ? parseISO(tramos[0].fecha_termino) : '';

  //     tramos.forEach(item => {
  //       const fechaDesde = item.fecha_inicio ? parseISO(item.fecha_inicio) : '';
  //       const fechaHasta = item.fecha_termino ? parseISO(item.fecha_termino) : '';

  //       if (fechaDesde < minFechaDesde) {
  //         minFechaDesde = fechaDesde;
  //       }
  //       if (fechaHasta > maxFechaHasta) {
  //         maxFechaHasta = fechaHasta;
  //       }
  //     });

  //     // Crear un array de fechas únicas desde minFechaDesde hasta maxFechaHasta
  //     const fechas = eachDayOfInterval({ start: minFechaDesde, end: maxFechaHasta });

  //     // Crear las columnas dinámicas basadas en las fechas encontradas
  //     const columns = [
  //       { field: 'nombre', headerName: 'Nombre', width: 250 },
  //       ...fechas.map(fecha => ({
  //         field: fecha ? format(fecha, 'yyyy-MM-dd') : '',
  //         headerName: fecha ? format(fecha, 'yyyy-MM-dd') : '',
  //         width: 110,
  //         editable: false, // Opcional: puedes permitir la edición si es necesario
  //       }))
  //     ];

  //     // Preparar las filas con los cálculos necesarios para cada fecha
  //     const rows = tramos.map((item, index) => {
  //       const row = {
  //         id: index,
  //         nombre: item.empresa.razonSocial,
  //       };

  //       fechas.forEach(fecha => {
  //         const fechaActual = fecha ? format(fecha, 'yyyy-MM-dd') : '';
  //         const fechaDesde = item.fecha_inicio ? parseISO(item.fecha_inicio) : '';
  //         const fechaHasta = item.fecha_termino ? parseISO(item.fecha_termino) : '';

  //         if (fecha >= fechaDesde && fecha <= fechaHasta) {
  //           const trabajadoresDia = parseInt(item.trabajadores_dia) || 0;
  //           const trabajadoresNoche = parseInt(item.trabajadores_noche) || 0;
  //           const totalTrabajadores = trabajadoresDia + trabajadoresNoche;
  //           row[fechaActual] = totalTrabajadores.toString();
  //         } else {
  //           row[fechaActual] = '';
  //         }
  //       });

  //       return row;
  //     });
  //     // Renderizar la DataGrid
  //     return (
  //       <div style={{ width: '100%' }}>
  //         <h4 style={{ color:'#FFF' }}>Listado de ocupación diario por forecast seleccionado </h4>
  //         <DataGrid
  //           rows={rows}
  //           columns={columns}
  //           pageSize={5} // Tamaño de la página
  //           rowsPerPageOptions={[5, 10, 20]} // Opciones de tamaño de página
  //           autoHeight // Altura automática
  //           style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
  //           sx={{
  //               '& .MuiDataGrid-cell': {
  //                 color: '#FFF',
  //                 borderBottom: '1px solid #c6c6c6',
  //               },
  //               '& .MuiDataGrid-columnHeader': {
  //                 color: '#FFF',
  //                 borderBottom: '1px solid #c6c6c6',
  //               },
  //               '& .MuiDataGrid-columnHeaders': {
  //                 borderBottom: '1px solid #c6c6c6',
  //               },
  //               '& .MuiDataGrid-footerContainer': {
  //                 color: '#FFF',
  //                 borderTop: '1px solid #c6c6c6',
  //               },
  //               '& .MuiTablePagination-root': {
  //                 color: '#FFF',
  //                 borderTop: '1px solid #c6c6c6',
  //               },
  //               '& .MuiDataGrid-toolbarContainer': {
  //                 color: '#FFF',
  //               },
  //               '& .MuiDataGrid-menu': {
  //                 color: '#FFF',
  //               },
  //               '& .MuiTablePagination-actions': {
  //                 color: '#FFF', // Color for pagination actions
  //               },
  //               '& .MuiTablePagination-select': {
  //                 color: '#FFF', // Color for pagination select
  //               },
  //               '& .MuiTablePagination-selectLabel': {
  //                 color: '#FFF', // Color for pagination select label
  //               },
  //               '& .MuiTablePagination-displayedRows': {
  //                 color: '#FFF', // Color for displayed rows text
  //               },
  //           }}
  //           slotProps={{
  //               toolbar:{
  //                 sx:{
  //                   color:'#FFF'
  //                 }
  //               },
  //               pagination: {
  //                 sx: {
  //                   color: '#FFF',
  //                 },
  //               },
  //               menu: {
  //                 sx: {
  //                   color: '#FFF',
  //                 },
  //               },
  //           }}
  //         />
  //       </div>
  //     );
  //   }
  // };

  const renderizarListado = () => {
    if (tramos.length > 0) {
      const mapped = tramos.flatMap((item) => item.tramos);
      const a =
        Array.isArray(mapped) &&
        mapped.filter((evento) => evento.fechaDesde && evento.fechaHasta);

      // console.log('filteredTramos: ', filteredTramos);
      // Encontrar la fecha mínima (fechaDesde) y máxima (fechaHasta) en todos los registros
      let minFechaDesde = parseISO(a[0].fechaDesde);
      let maxFechaHasta = parseISO(a[0].fechaHasta);

      Array.isArray(a) &&
        a.forEach((item) => {
          const fechaDesde = parseISO(item.fechaDesde);
          const fechaHasta = parseISO(item.fechaHasta);

          if (fechaDesde < minFechaDesde) {
            minFechaDesde = fechaDesde;
          }
          if (fechaHasta > maxFechaHasta) {
            maxFechaHasta = fechaHasta;
          }
        });

      // Crear un array de fechas únicas desde minFechaDesde hasta maxFechaHasta
      const fechas = eachDayOfInterval({
        start: minFechaDesde,
        end: maxFechaHasta,
      });

      // Crear las columnas dinámicas basadas en las fechas encontradas
      const columns = [
        { field: "nombre", headerName: "-", width: 150 },
        ...fechas.map((fecha) => ({
          field: format(fecha, "dd-MM-yyyy"),
          headerName: format(fecha, "dd-MM-yyyy"),
          width: 110,
          editable: false, // Opcional: puedes permitir la edición si es necesario
        })),
      ];

      // Preparar las filas con los cálculos necesarios para cada fecha
      const row = { id: 0, nombre: "Totales" };
      // const row = {
      //   id: index,
      //   nombre: item.empresa.razonSocial,
      // };

      fechas.forEach((fecha, index) => {
        const fechaActual = format(fecha, "dd-MM-yyyy");
        let totalTrabajadoresDia = 0;
        let totalTrabajadoresNoche = 0;

        a.forEach((item, index) => {
          const fechaDesde = parseISO(item.fechaDesde);
          const fechaHasta = parseISO(item.fechaHasta);

          const jornadasLaborales = item.turno
            ? item.turno.jornadasLaborales
            : 0;
          const jornadasDescanso = item.turno ? item.turno.jornadasDescanso : 0;
          const cicloTotal = jornadasLaborales + jornadasDescanso;

          // Calcular la diferencia de días desde el inicio del tramo
          const diferenciaDias = differenceInDays(fecha, fechaDesde);
          const posicionEnCiclo = diferenciaDias % cicloTotal;

          if (posicionEnCiclo < jornadasLaborales) {
            if (fecha >= fechaDesde && fecha <= fechaHasta) {
              totalTrabajadoresDia += parseInt(item.trabajadoresDia) || 0;
              totalTrabajadoresNoche += parseInt(item.trabajadoresNoche) || 0;
            }
          }
        });

        const totalTrabajadores = totalTrabajadoresDia + totalTrabajadoresNoche;
        row[fechaActual] = totalTrabajadores.toString();
      });
      // Renderizar la DataGrid
      return (
        <div style={{ width: "100%" }}>
          <h4 style={{ color: "#FFF" }}>
            Listado de ocupación diario por forecast seleccionado{" "}
          </h4>
          <DataGrid
            rows={[row]}
            columns={columns}
            pageSize={5} // Tamaño de la página
            rowsPerPageOptions={[5, 10, 20]} // Opciones de tamaño de página
            autoHeight // Altura automática
            style={{ maxHeight: "500px", color: "#FFF", border: "none" }}
            sx={{
              "& .MuiDataGrid-cell": {
                color: "#FFF",
                borderBottom: "1px solid #c6c6c6",
              },
              "& .MuiDataGrid-columnHeader": {
                color: "#FFF",
                borderBottom: "1px solid #c6c6c6",
              },
              "& .MuiDataGrid-columnHeaders": {
                borderBottom: "1px solid #c6c6c6",
              },
              "& .MuiDataGrid-footerContainer": {
                color: "#FFF",
                borderTop: "1px solid #c6c6c6",
              },
              "& .MuiTablePagination-root": {
                color: "#FFF",
                borderTop: "1px solid #c6c6c6",
              },
              "& .MuiDataGrid-toolbarContainer": {
                color: "#FFF",
              },
              "& .MuiDataGrid-menu": {
                color: "#FFF",
              },
              "& .MuiTablePagination-actions": {
                color: "#FFF", // Color for pagination actions
              },
              "& .MuiTablePagination-select": {
                color: "#FFF", // Color for pagination select
              },
              "& .MuiTablePagination-selectLabel": {
                color: "#FFF", // Color for pagination select label
              },
              "& .MuiTablePagination-displayedRows": {
                color: "#FFF", // Color for displayed rows text
              },
            }}
            slotProps={{
              toolbar: {
                sx: {
                  color: "#FFF",
                },
              },
              pagination: {
                sx: {
                  color: "#FFF",
                },
              },
              menu: {
                sx: {
                  color: "#FFF",
                },
              },
            }}
          />
        </div>
      );
    }
  };

  useEffect(() => {
    setFilteredTramos(tramos);
    // console.log('TRAAAAAMOSSSS',tramos)
    const diasMaximosPorMes = encontrarDiaMaximoPorMes();
    // const totalTrabajadoresPorDiaPorMes = calcularTotalTrabajadoresPorDia(tramos);
    setDiasMes(diasMaximosPorMes);

    const a = calcularTotalesPorMes(tramos);
    setTotalesPorMes(a);
    const b = Array.from(
      new Set(
        tramos.map((registro) => {
          const fechaInicio = new Date(registro.fecha_inicio);
          return `${fechaInicio.getFullYear()}-${fechaInicio.getMonth() + 1}`;
        })
      )
    );

    setMesesUnicos(b);
    // console.log('tramosss:', tramos);

    renderizarListado();
  }, [tramos]);

  const onChange2 = (e) => {
    // console.log(e.target.value)
  };

  const onChange = (e) => {
    // console.log(e.target.value)
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open2}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/*<DialogContent> */}
      <div></div>
      <Grid container={true} spacing={0}>
        <Grid item xs={12} sm={4}></Grid>
        <Grid
          item
          p={1}
          xs={12}
          sm={8}
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            className={[styles.textFieldMeses, styles.paddingCero]}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
              "& .MuiInputBase-input": {
                color: "#FFF",
              },
              "& .MuiInputLabel-root": {
                color: "#FFF",
              },
              "& .MuiAutocomplete-clearIndicator": {
                color: "#FFF",
              },
              "& .MuiAutocomplete-popupIndicator": {
                color: "#FFF",
              },
              width: "100%",
              backgroundColor: "#e99734",
              "@media (min-width: 600px)": {
                width: "30%",
              },
            }}
            variant="outlined"
            name="buscar"
            type="number"
            placeholder="N° de meses"
            onChange={(e) => changeCalendarNumber(e)}
          />
        </Grid>
        <Grid item xs={12} sm={4} p={1} className={styles.infoContainer} mt={0}>
          {selectedTramosForecast.tramos && selectedTramosForecast?.tramos ? (
            <Grid container={true}>
              <Grid item xs={12} mb={1}>
                <label className={styles.label}>
                  <strong className={styles.textIcon}>
                    <InfoIcon />
                    Información del forecast
                  </strong>
                </label>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: selectedTramosForecast?.estado.includes(
                    "Aprobado"
                  )
                    ? "var(--inst-green-dark)"
                    : selectedTramosForecast?.estado.includes("Rechazado")
                    ? "var(--delete-button-bg-hover)"
                    : selectedTramosForecast?.estado.includes("Creado")
                    ? "var(--inst-li-blue)"
                    : "var(--button-color-outlined)",
                  color: "#FFF",
                }}
              >
                <label
                  className={styles.label}
                  style={{
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <strong>{selectedTramosForecast?.estado}</strong>
                </label>
              </Grid>
              <Grid item xs={12}>
                <label
                  className={styles.label}
                  style={{
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <ApartmentIcon /> Empresa:{" "}
                  <strong>
                    {selectedTramosForecast?.empresa?.razonSocial}
                  </strong>
                </label>
              </Grid>
              <Grid item xs={12}>
                <label
                  className={styles.label}
                  style={{
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <CalendarMonthIcon /> Inicio:{" "}
                  <strong>
                    {format(selectedTramosForecast?.fecha_inicio, "dd-MM-yyyy")}
                  </strong>
                </label>
              </Grid>
              <Grid item xs={12}>
                <label
                  className={styles.label}
                  style={{
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <CalendarMonthIcon /> Término:{" "}
                  <strong>
                    {format(
                      selectedTramosForecast?.fecha_termino,
                      "dd-MM-yyyy"
                    )}
                  </strong>
                </label>
              </Grid>
              <Grid item xs={12}>
                <label
                  className={styles.label}
                  style={{
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "start",
                    gap: "5px",
                  }}
                >
                  <PersonIcon />{" "}
                  <label style={{ paddingRight: "2px" }}>
                    Creado por:{" "}
                    {selectedTramosForecast?.usuario?.nombre +
                      " " +
                      selectedTramosForecast?.usuario?.apellido}
                  </label>
                </label>
              </Grid>
            </Grid>
          ) : (
            <label
              className={styles.label}
              style={{
                fontSize: "14px",
                display: "flex",
                alignItems: "start",
                gap: "5px",
              }}
            >
              <InfoIcon />
              <strong>
                Selecciona algun forecast de la tabla y luego selecciona el
                calendario para ver su información.
              </strong>
            </label>
          )}
          <br />
          {selectedTramosForecast.tramos && (
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChangeAcordion("panel1")}
              style={{ backgroundColor: "#FFF", color: "var(--inst-card-bg)" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFF" }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                style={{
                  backgroundColor: "var(--inst-orange-dark)",
                  color: "#FFF",
                }}
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Tramos
                </Typography>
                Listado de tramos
              </AccordionSummary>
              <AccordionDetails>
                {selectedTramosForecast?.tramos.map(
                  (tramo, index) =>
                    tramo.nombre &&
                    tramo.fechaDesde &&
                    tramo.fechaHasta && (
                      <div key={index} className={styles.tramoContainer}>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#FFF",
                            textAlign: "center",
                            color: "var(--inst-card-bg)",
                          }}
                        >
                          <label
                            className={styles.label}
                            style={{ fontSize: "16px" }}
                          >
                            Forecast: <strong>{tramo.nombre}</strong>
                          </label>
                        </div>
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <label className={styles.label}>
                            Contrato: {tramo.contrato}
                          </label>
                        </div>

                        <Grid
                          container={true}
                          mt={1}
                          spacing={1}
                          style={{ textAlign: "center" }}
                        >
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{
                              backgroundColor: "var(--inst-li-blue)",
                              marginBottom: "4px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <label
                              className={styles.label}
                              style={{ fontSize: "12px", color: "#FFF" }}
                            >
                              VP
                            </label>
                            <label
                              className={styles.label}
                              style={{ color: "#FFF" }}
                            >
                              {tramo.vicepresidencia}
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{
                              backgroundColor: "var(--inst-li-blue)",
                              marginBottom: "4px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <label
                              className={styles.label}
                              style={{ fontSize: "12px", color: "#FFF" }}
                            >
                              Gerencia
                            </label>
                            <label
                              className={styles.label}
                              style={{ color: "#FFF" }}
                            >
                              {tramo.gerencia}
                            </label>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{
                              backgroundColor: "var(--inst-li-blue)",
                              marginBottom: "4px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <label
                              className={styles.label}
                              style={{ fontSize: "12px", color: "#FFF" }}
                            >
                              Si
                            </label>
                            <label
                              className={styles.label}
                              style={{ color: "#FFF" }}
                            >
                              {tramo.siArea}
                            </label>
                          </Grid>
                        </Grid>

                        <Grid
                          container={true}
                          className={styles.containerDates}
                          mt={1}
                        >
                          <Grid
                            item
                            xs={12}
                            md={5}
                            style={{ textAlign: "center" }}
                          >
                            <label
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                              }}
                            >
                              <CalendarMonthIcon />
                              {format(tramo.fechaDesde, "dd-MM-yyyy")}
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={2}
                            style={{ textAlign: "center" }}
                          >
                            <KeyboardDoubleArrowRightIcon />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={5}
                            style={{ textAlign: "center" }}
                          >
                            <label
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                              }}
                            >
                              <CalendarMonthIcon />
                              {format(tramo.fechaHasta, "dd-MM-yyyy")}
                            </label>
                          </Grid>
                        </Grid>

                        <Grid
                          container={true}
                          className={styles.containerIcons}
                          mt={1}
                        >
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{
                              backgroundColor: "#FFF",
                              textAlign: "center",
                            }}
                          >
                            <label
                              style={{
                                fontSize: "12px",
                                color: "var(--inst-bg-card)",
                              }}
                            >
                              Trabajadores
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className={styles.vpContainerWorkers}
                            style={{ displa: "flex", justifyContent: "center" }}
                          >
                            <label
                              className={styles.label}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <WbSunnyIcon /> {tramo.trabajadoresDia}
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className={styles.vpContainerWorkers}
                            style={{ displa: "flex", justifyContent: "center" }}
                          >
                            <label
                              className={styles.label}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              {" "}
                              <ModeNightIcon /> {tramo.trabajadoresNoche}
                            </label>
                          </Grid>
                        </Grid>

                        <hr style={{ margin: "5px 0", color: "#FFF" }} />
                      </div>
                    )
                )}
              </AccordionDetails>
            </Accordion>
          )}
          {selectedTramosForecast.modificacionesForecasts && (
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChangeAcordion("panel2")}
              style={{ backgroundColor: "#FFF", color: "#FFF" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFF" }} />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                style={{ backgroundColor: "var(--inst-orange-dark)" }}
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Historico
                </Typography>
                <Typography sx={{ color: "#FFF" }}>
                  Listado de modificaciones
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {selectedTramosForecast?.modificacionesForecasts.map(
                  (tramo, index) => (
                    <div key={index} style={{ padding: "5px" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label
                          style={{ color: "var(--textfield-bg)" }}
                          className={styles.label}
                        >
                          Estado: <strong>{tramo.estado}</strong>
                        </label>
                        <label
                          style={{ color: "var(--textfield-bg)" }}
                          className={styles.label}
                        >
                          Modificado por:{" "}
                          <strong>
                            {tramo?.usuario?.nombre} {tramo?.usuario?.apellido}
                          </strong>
                        </label>
                        <label
                          style={{ color: "var(--textfield-bg)" }}
                          className={styles.label}
                        >
                          Fecha:{" "}
                          <strong>{format(tramo.fecha, "dd-MM-yyyy")}</strong>
                        </label>
                        <label
                          style={{ color: "var(--textfield-bg)" }}
                          className={styles.label}
                        >
                          Nota: <strong>{tramo.nota}</strong>
                        </label>
                      </div>
                      <hr style={{ margin: "5px 0", color: "#202020" }} />
                    </div>
                  )
                )}
              </AccordionDetails>
            </Accordion>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Grid container spacing={2}>
            {numeroCalendario.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={numeroCalendario.length == 1 ? 12 : 6}
                p={1}
              >
                <Card
                  style={{
                    backgroundColor: "#FFF",
                    color: "var(--textfield-bg)",
                    padding: "10px",
                    marginTop: "5px",
                  }}
                >
                  <MyCalendar
                    events={tramos ?? []}
                    trabajadoresPorDia={trabajadoresPorDiaFunct}
                    inputRefs={inputRefs}
                    setSelectedTramosForecast={setSelectedTramosForecast}
                    defaultDate={moment().add(index, "month").toDate()}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid container={true} style={{ padding: "0px", margin: "0px" }}>
        <Grid item xs={12} style={{ padding: "0px", margin: "0px" }}>
          {statusSolicitudes == "loading" ? (
            <SkeletonTable />
          ) : (
            <Table
              value={value3}
              setValue={setValue3}
              registros={updatedRegistros}
              setTramos={setTramos}
              tramos={tramos}
              openModal={openModal}
              setOpenModal={setOpenModal}
              onChange2={onChange2}
              selectedTramosForecast={selectedTramosForecast}
              setSelectedTramosForecast={setSelectedTramosForecast}
              handleChange={handleChange}
              handleEliminarTramo={handleEliminarTramo}
              handleAgregarTramo={handleAgregarTramo}
              ger={ger}
              siArea={siArea}
              empresas={empresas}
              open3={open2}
              setOpen3={setOpen2}
              turnos={turnos}
            />
          )}
        </Grid>
      </Grid>

      <Grid container={true} mt={2} spacing={1}>
        <Grid
          item
          xs={12}
          sm={4}
          style={{ backgroundColor: "var(--inst-black-10)" }}
        >
          {diasMes && (
            // && diasMes.length > 0
            <h3 style={{ fontSize: "16px" }}>
              Día con mayor ocupación del mes en curso
            </h3>
          )}
          <ul>
            {diasMes &&
              // && diasMes.length > 0
              Object.values(diasMes).map((maximoPorMes, index) => (
                <li key={index} style={{ listStyle: "none" }}>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {maximoPorMes?.fecha && <CalendarMonthIcon />}
                    {maximoPorMes?.fecha &&
                    isValid(parseISO(maximoPorMes.fecha))
                      ? `${format(parseISO(maximoPorMes.fecha), "dd-MM-yyyy")}`
                      : ""}
                  </p>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {maximoPorMes?.fecha && <GroupsIcon />}
                    {maximoPorMes?.fecha &&
                    isValid(parseISO(maximoPorMes.fecha))
                      ? maximoPorMes?.totalTrabajadores
                      : ""}
                  </p>
                </li>
              ))}
          </ul>
          <br />
          {mesesUnicos &&
            mesesUnicos.map((mes) => (
              <div key={mes}>
                <h3
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <CalendarMonthIcon />
                  Totales: {mes.split("-").reverse().join("-")}
                </h3>
                {totalesPorMes &&
                  Object.entries(totalesPorMes[mes]).map(
                    ([empresa, totales]) => (
                      <div key={empresa} style={{ marginBottom: "10px" }}>
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <ApartmentIcon />
                          <strong>{empresa}</strong>
                        </p>
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <GroupsIcon />
                          Total Día: <strong>{totales.totalDia}</strong>
                        </p>
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <GroupsIcon />
                          Total noche: <strong>{totales.totalNoche}</strong>
                        </p>
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <GroupsIcon /> Total general:{" "}
                          <strong>
                            {totales.totalDia + totales.totalNoche}
                          </strong>
                        </p>
                      </div>
                    )
                  )}
              </div>
            ))}
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          style={{ backgroundColor: "var(--textfield-bg)" }}
        >
          {renderizarListado()}
        </Grid>
      </Grid>
    </div>
  );
};

export default Create;
