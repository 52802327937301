import { useEffect } from "react"
import { Marker, Popup, useMap } from "react-leaflet"
import L from 'leaflet'
import "leaflet/dist/leaflet.css"
import icon from "leaflet/dist/images/marker-icon.png"
import iconShadow from "leaflet/dist/images/marker-shadow.png"

let DefaultIcon = L.icon ({
    iconUrl: icon,
    shadowUrl: iconShadow
})

L.Marker.prototype.options.icon = DefaultIcon;

const GeoCoderMarker = ({ lat, lng, setPosition }) => {
    const map = useMap();

    useEffect(() => {
        if (lat && lng && !isNaN(lat) && !isNaN(lng)) {
            setPosition([lat, lng]);
            map.flyTo([lat, lng], 13); // Ajusta el zoom si es necesario
        }
    }, [lat, lng, map, setPosition]);

    return (
        <Marker 
            position={[lat, lng]} 
            icon={DefaultIcon}
            draggable={true}
            eventHandlers={{
                dragend: (event) => {
                    const newLatLng = event.target.getLatLng();
                    setPosition([newLatLng.lat, newLatLng.lng]);
                }
            }}
        >
            <Popup>
                <span>Arrastre el marcador</span>
            </Popup>
        </Marker>
    );
}

export default GeoCoderMarker;
