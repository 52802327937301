import styles from "./components.module.css";
import {
  Grid,
  Backdrop,
  Dialog,
  Button,
  DialogTitle,
  Autocomplete,
  DialogActions,
  TextField,
  DialogContent,
  Typography,
  Card,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import {
  asociarPersonalOficina,
  getInfoByRutAdmUsuarios,
  liveSearchByRutAdmUsuarios,
} from "../../../../redux/features/users/userSlice";
import { useDispatch } from "react-redux";
import { reenviarClaveHabitacion } from "../../../../redux/features/reservas/reservaSlice";

const ReenviarChapaHabitacion = ({ open, setOpen, seleItem }) => {
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [openBack, setOpenBack] = useState(false);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("seleItem", seleItem);
  }, [seleItem, open]);

  const handleSubmit = async () => {
    console.log("formData: ", formData);
    try {
      setOpenBack(true);
      setAddRequestStatus("loading");
      const resp = await dispatch(
        reenviarClaveHabitacion({
          rut: seleItem?.trabajador?.rut,
          fechaIngreso: seleItem?.fechaIngreso,
          fechaSalida: seleItem?.fechaSalida,
        })
      );
      console.log("resp", resp);
      if (resp.payload.status === "success") {
        setFormData({});
        setOpen(false);
        return;
      }
    } catch (error) {
      console.error("Error al crear el usuario", error);
    } finally {
      setAddRequestStatus("idle");
      setOpenBack(false);
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      PaperProps={{
        sx: {
          backgroundColor: "#efefef",
          color: "#2d3037",
          borderRadius: "0",
        },
      }}
      onBackdropClick={() => {
        setOpen(false);
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBack}
      ></Backdrop>{" "}
      <DialogTitle style={{ display: "flex", gap: "5px" }}>
        <AutoModeIcon
          className={styles.iconBgCreate}
          style={{ fontSize: "3rem", marginTop: "0px" }}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="span"
            style={{ fontSize: "24px", lineHeight: "1" }}
          >
            Reenviar clave de chapa habitación N°{seleItem?.habitacion?.numero}{" "}
            en{" "}
            {seleItem?.habitacion?.pabellon?.identificador
              ? seleItem?.habitacion?.pabellon?.identificador
              : seleItem?.habitacion?.wing?.nombre ?? ""}
            ,{" "}
            {seleItem?.habitacion?.pabellon?.campamento
              ? seleItem?.habitacion?.pabellon?.campamento.nombre
              : seleItem?.habitacion?.wing?.campamento?.nombre ?? ""}
          </Typography>
          <span className={styles.subTitle}>
            Un correo será enviado al personal asignado a la habitaciòn
          </span>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container={true} spacing={1}>
          <Grid item xs={12} sm={12}>
            <Grid container={true} spacing={2}>
              <Grid item xs={12} sm={12}>
                <label className={styles.label}>Clave chapa habitaciòn</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="email"
                  value="123456"
                  disabled
                  type="text"
                  // onChange={(e) => onChange(e, index)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} sm={12} className={styles.buttonContainer}>
          <Button
            onClick={handleSubmit}
            className={styles.bgColorModal}
            variant="contained"
            // disabled={}
          >
            {addRequestStatus === "loading" ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              "Reenviar clave chapa habitaciòn"
            )}
          </Button>

          <Button
            onClick={() => setOpen(false)}
            className={styles.bgColorOutlined}
            variant="outlined"
          >
            Cancelar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ReenviarChapaHabitacion;
