import React, { useContext, useEffect, useState } from 'react';
import { Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppContext, useAuth } from '../context/AppContext';
import Cookies from 'universal-cookie';
import Loading from '../pages/loading/Loading';
import Unauthorized from '../pages/Unauthorized/Unauthorized';
import Login from '../pages/login/Login';
import { jwtDecode } from "jwt-decode";
import { getRole } from '../utils/validate';
import { Backdrop, CircularProgress } from '@mui/material';

export function checkJWTTToken(role) {
    const cookies = document.cookie;
    
    if(cookies.includes('token')) {
            return true;
    } else {
        return false;
    }
}

const ProtectedRoute = ({ allowedRoles }) => {
    const { user, setUser, setRol, rol } = useAuth();
    const location = useLocation();
    const [loading, setIsLoading] = useState(true);
    // console.log('allowedRoles::', allowedRoles);
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            setUser(decoded);
            const role = getRole();
            setRol(role);
        }
        setIsLoading(false);
    }, []);
    
    if (loading) {
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <CircularProgress color="inherit" />
      </Backdrop>
    }

    return ( 
        allowedRoles.find(role => rol.includes(role))
        ? <Outlet/>
        : user
         ? <Navigate to="/unauthorized" state={{ from: location }} replace/>
         : <Navigate to="/" state={{ from: location }} replace/>

    )
}

export default ProtectedRoute;
