import styles from "./components.module.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { liveSearchByRut } from "../../../../redux/features/users/userSlice";
import {
  asignarOficina,
  deshabilitarOficina,
  fetchTrabajadoresOficinas,
  habilitarOficina,
  liberarOficina,
  removerTrabajadorOficinaRut,
} from "../../../../redux/features/oficinas/oficinaSlice";
import { trabajadoresEnOficinaColumns } from "../../../../lib/columns";

const data = {
  nombre: "",
  descripcion: "",
};
const Modal = ({ open, open2, seleItem, check, setOpen, setOpen2 }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [ruts, setRuts] = useState([]);
  const [selectedRut, setSelectedRut] = useState(null);
  const [newItem, setNewItem] = useState("");
  const [trabajadores, setTrabajadores] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  const onChangeRut = async (e) => {
    setOpen2(true);
    try {
      const resp = await dispatch(liveSearchByRut(e.target.value));
      setRuts(resp.payload.data);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen2(false);
    }
  };

  const onChangeRutSelect = async (e, newValue) => {
    if (newValue != null) {
      setNewItem(newValue.nombre);
      setSelectedRut(newValue);
      setFormData({ ...formData, rut: newValue });
      // try {
      //   const resp = await dispatch(getInfoByRut(newValue.rut))
      //   // setSelectedRut(resp.payload.data);
      // } catch (error) {
      //   console.log(error)
      // }
    } else {
      setFormData({ ...formData, rut: null });
    }
  };

  const getTabajadoresOficina = async () => {
    try {
      const resp = await dispatch(fetchTrabajadoresOficinas(seleItem.id));
      console.log("listado de trabajadores", resp.payload.data);
      setTrabajadores(resp.payload.data);
    } catch (error) {
      console.log("error al traer los ruts asociados a la oficina", error);
    }
  };
  const removerTrabajadorOficina = async (trabajador) => {
    try {
      setOpen2(true);
      if (!seleItem) return null;
      dispatch(
        removerTrabajadorOficinaRut({ rut: trabajador.rut, id: seleItem?.id })
      );
      getTabajadoresOficina();
      setOpen(false);
    } catch (error) {
      console.log("error al traer los ruts asociados a la oficina", error);
    } finally {
      setOpen2(false);
    }
  };

  const finalColumns = trabajadoresEnOficinaColumns(removerTrabajadorOficina);

  useEffect(() => {
    setFormData({});
    if (check == 1 && seleItem) {
      setFormData({ oficinaId: seleItem?.id });
    }
    if (check == 2 && seleItem) {
      getTabajadoresOficina();
    }
  }, [check, seleItem, open]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const canSave =
    (check === 3 ? Boolean(formData?.observaciones) : true) &&
    (check === 1
      ? Boolean(
          formData?.fechaInicio && formData?.fechaTermino && formData?.rut
        )
      : true) &&
    addRequestStatus === "idle";

  const handleSubmit = async () => {
    if (canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus("loading");
        if (check == 1) {
          let resp = await dispatch(asignarOficina(formData));
          if (resp.payload.status === "success") {
            setFormData(data);
            setOpen(false);
          }
        } else if (check == 2) {
          let resp = await dispatch(liberarOficina(seleItem?.id));
          if (resp.payload.status === "success") {
            setFormData(data);
            setOpen(false);
          }
        } else if (check == 3) {
          let resp = await dispatch(
            deshabilitarOficina({
              id: seleItem?.id,
              razon: formData?.observaciones,
            })
          );
          if (resp.payload.status === "success") {
            setFormData(data);
            setOpen(false);
          }
        } else if (check == 4) {
          let resp = await dispatch(habilitarOficina(seleItem?.id));
          if (resp.payload.status === "success") {
            setFormData(data);
            setOpen(false);
          }
        }
      } catch (error) {
        console.error("Error al ejecutar la acción sobre la oficina", error);
      } finally {
        setAddRequestStatus("idle");
        setOpen2(false);
      }
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 100000 }} open={open2}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={open}
        maxWidth="md"
        maxHeight="lg"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
            zIndex: 1300,
            width: check == 2 ? "100%" : "fit-content",
          },
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <DialogTitle style={{ display: "flex", gap: "5px" }}>
          <BookmarkAddIcon
            className={styles.iconBgCreate}
            style={{ fontSize: "3rem", marginTop: "0px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="p"
              style={{ fontSize: "24px", lineHeight: "1" }}
            >
              {(check == 1 && "Asignar oficina") ||
                (check == 2 && "Liberar oficina") ||
                (check == 3 && "Inhabilitar oficina")}
            </Typography>
            <span className={styles.subTitle}>
              {(check == 1 &&
                "Buscar un rut y asigna la oficina N°" +
                  seleItem?.numero +
                  " al trabajador seleccionado.") ||
                (check == 2 &&
                  "Estás a punto de liberar la oficina N°" +
                    seleItem?.numero) ||
                (check == 3 &&
                  "Estás a punto de inhabilitar la oficina N°" +
                    seleItem?.numero) ||
                (check == 4 &&
                  "Estás a punto de habilitar la oficina N°" +
                    seleItem?.numero)}
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            {check == 1 && (
              <>
                <Grid item sx={12} md={12} style={{ width: "100%" }}>
                  <Autocomplete
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                      "& .MuiAutocomplete-clearIndicator": {
                        color: "#2d3037",
                      },
                      "& .MuiAutocomplete-popupIndicator": {
                        color: "#2d3037",
                      },
                      zIndex: 1400,
                    }}
                    disablePortal
                    disableClearable
                    id="sectores-autocomplete"
                    name="rut"
                    value={selectedRut}
                    options={ruts ? ruts : []}
                    style={{ width: "100%" }}
                    getOptionLabel={(option) =>
                      option.rut + " " + option.nombre
                    }
                    onChange={(e, newValue) => onChangeRutSelect(e, newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona un rut"
                        style={{ width: "100%" }}
                        onChange={(e) => onChangeRut(e)}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  sx={12}
                  md={12}
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <div style={{ width: "50%" }}>
                    <label className={styles.label}>Desde</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name="fechaInicio"
                      type="date"
                      value={selectedRut?.fechaInicio}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <label className={styles.label}>Hasta</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name="fechaTermino"
                      type="date"
                      value={selectedRut?.fechaTermino}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </Grid>
              </>
            )}
            {check == 2 && (
              <>
                <Grid item xs={12} md={12}>
                  <DataGrid
                    getRowId={(row) => (row.rut ? row.rut : Math.random())}
                    lots={{ toolbar: GridToolbar }}
                    rows={trabajadores || []}
                    columns={finalColumns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    localeText={{
                      ...esES.components.MuiDataGrid.defaultProps.localeText,
                      noRowsLabel: "No hay datos disponibles",
                      pagination: {
                        labelRowsPerPage: "Filas por página:",
                      },
                    }}
                    classes={{ menuIcon: styles.menuIcon }}
                    style={{
                      maxHeight: "500px",
                      color: "#FFF",
                      border: "none",
                    }}
                    sx={{
                      "& .MuiDataGrid-cell": {
                        color: "#2d3037",
                        borderBottom: "1px solid #c6c6c6",
                      },
                      "& .MuiDataGrid-columnHeader": {
                        color: "#2d3037",
                        borderBottom: "1px solid #c6c6c6",
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        borderBottom: "1px solid #c6c6c6",
                      },
                      "& .MuiDataGrid-footerContainer": {
                        color: "#2d3037",
                        borderTop: "1px solid #c6c6c6",
                      },
                      "& .MuiTablePagination-root": {
                        color: "#2d3037",
                        borderTop: "1px solid #c6c6c6",
                      },
                      "& .MuiDataGrid-toolbarContainer": {
                        color: "#2d3037",
                      },
                      "& .MuiDataGrid-menu": {
                        color: "#2d3037",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#9ca91c",
                      },
                      "& .MuiTablePagination-actions": {
                        color: "#2d3037", // Color for pagination actions
                      },
                      "& .MuiTablePagination-select": {
                        color: "#2d3037", // Color for pagination select
                      },
                      "& .MuiTablePagination-selectLabel": {
                        color: "#2d3037", // Color for pagination select label
                      },
                      "& .MuiTablePagination-displayedRows": {
                        color: "#2d3037", // Color for displayed rows text
                      },
                      "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer":
                        {
                          color: "red",
                        },
                      "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root":
                        {
                          color: "red",
                        },
                    }}
                    slotProps={{
                      toolbar: {
                        sx: {
                          color: "#FFF",
                        },
                      },
                      pagination: {
                        sx: {
                          color: "#f19d38",
                        },
                      },
                      menu: {
                        sx: {
                          color: "#f19d38",
                        },
                      },
                    }}
                  />
                </Grid>
              </>
            )}
            {check == 3 && (
              <>
                <Grid item xs={12} md={12}>
                  <label className={styles.label}>Observaciones</label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    name="observaciones"
                    type="text"
                    value={selectedRut?.observaciones}
                    onChange={(e) => onChange(e)}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained"
                disabled={!canSave}
              >
                {addRequestStatus === "loading" ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  (check === 1 && "Asignar oficina") ||
                  (check === 2 && "Remover a todos los trabajadores") ||
                  (check === 3 && "Inhabilitar oficina") ||
                  (check === 4 && "Habilitar oficina")
                )}
              </Button>
              <Button
                onClick={() => setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Modal;
