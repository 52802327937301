import React from 'react';
import styles from './components.module.css'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNewReserva } from '../../../../redux/features/reservas/reservaSlice';
import { toast } from 'react-toastify';
import { AppBar, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, MenuItem, Paper, Select, TextField, Toolbar, Typography, Box, Chip, Autocomplete, Popper, Backdrop, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { reservasP_externoColumns } from '../../../../lib/columns';
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getInfoByRut, getInfoByRutNameContractP_externo, liveSearchByRut } from '../../../../redux/features/users/userSlice';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import { createAxiosInstance } from '../../../../api/axios';
import { format, parseISO } from 'date-fns';
import SelectReact from 'react-select';

const jornadas = [ 
  {label: 'DIURNA', value: 'DIURNA' },
  {label: 'NOCTURNA', value: 'NOCTURNA'}, 
];
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const data = {
  fechaIngreso:'',
  fechaSalida:'',
  descripcion:'',
  trabajadores:[]
}

const Edit2 = ({ checkedState, setCheckedState, selectedTurnos, setSelectedTurnos, fechaInicio, fechaTermino, descripcion, jornada, turnoGeneral, setFechaInicio, setFechaTermino, setDescripcion, setJornada, setTurnoGeneral, check, setCheck, turnos, setOpen6, data, openBack, setOpenBack, cargos, open, setOpen, empresas, gerencia, siArea, viceprecidencia, seleItemSolicitud ,setSeleItemSolicitud}) => {

  const dispatch = useDispatch();
  // const [open, setOpen] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [formData, setFormData] = useState(data);

  const [turnoGrupal, setTurnoGrupal] = useState('');
  const [desde, setDesde] = useState(0);
  const [hasta, setHasta] = useState(0);

  const [fechaInicioIntervalo, setFechaInicioIntervalo] = useState('');
  const [fechaTerminoIntervalo, setFechaTerminoIntervalo] = useState('');
  
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  
  const [check3, setCheck3] = useState(false);
  
  const [seleItem, setSeleItem] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [value, setValue] = useState(0);
  const [items, setItems] = useState([]);

  const [newItem, setNewItem] = useState('');
  const [itemsNew, setItemsNew] = useState([]);

  const [newItem2, setNewItem2] = useState('');
  const [itemsNew2, setItemsNew2] = useState([]);
  
  const [itemsNew3, setItemsNew3] = useState([]);

  const [ruts, setRuts] = useState([])
  const [selectedRuts, setSelectedRuts] = useState([])
  
  const [acreditados, setAcreditados] = useState([])
  const [noAcreditados, setNoAcreditados] = useState([])

  const [tramos, setTramos] = useState([{}]);
  
  const [completed, setCompleted] = useState(false);
  const [completed2, setCompleted2] = useState(false);
  
  const [selectedRut, setSelectedRut] = useState(null);

  const [file, setFile] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);


  const [selectedEmpresa, setSelectedEmpresa] = useState(null);
  const [selectedVicepresidencias, setSelectedVicepresidencias] = useState(null);
  const [selectedGerencia, setSelectedGerencia] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedContrato, setSelectedContrato] = useState('');
  const [filterContratos, setFilterContratos] = useState([]);
  const [openAlerta, setOpenAlerta] = useState(true);
  const axiosInstance = createAxiosInstance();

  const [search, setSearch] = useState('');

  const [totalDiurna, setTotalDiurna] = useState(0);
  const [totalNocturna, setTotalNocturna] = useState(0);

  const handleInputChange = (inputValue) => {
    setSearch(inputValue);
  };

  const getContratos = async () => {
    try {
      // setOpen3(true);
      const resp = await axiosInstance.get('/api/v1/Solicitud/Contratos/'+seleItemSolicitud?.empresa.idEmpresa);
      setFilterContratos(resp.data);
      setSelectedContrato(seleItemSolicitud?.contrato);
    } catch (error) {
      console.log(error);
    } finally {
      // setOpen3(false);
    }
  }
  const getTrabajadores = async () => {
    try {
      setOpenBack(true);
      const resp2 = await axiosInstance.get('/api/v1/Solicitud/TrabajadoresParaCopia/'+seleItemSolicitud?.id);
      console.log('resp2', resp2)
      const resp = await axiosInstance.get('/api/v1/Solicitud/TrabajadoresSolicitud/'+seleItemSolicitud?.id);
      console.log('trabajadores getTrabajadores', resp.data);
      const trabajadoresAcreditados = resp.data.filter(item => item.trabajador?.acreditado && item.trabajador?.acreditado == true); 
      const trab = trabajadoresAcreditados.map(async (item )=> {
        console.log('itemm', item)
          
        const infoP_externo = await dispatch(getInfoByRutNameContractP_externo(item.trabajador.rut))
        const p_externo = infoP_externo.payload.data[0];
        console.log('payloaddd', p_externo)

          setItems(prevItems => [...prevItems, {rut: item.trabajador.rut, nombreCompleto: `${item.trabajador.nombre} ${item.trabajador.apellidoPaterno} ${item.trabajador.apellidoMaterno}`} ]);
          setItemsNew(prevItems => {
            const newItem = {
              nombreCompleto: `${item.trabajador.nombre} ${item.trabajador.apellidoPaterno} ${item.trabajador.apellidoMaterno}`,
              rut: item.trabajador.rut,
              genero: item.trabajador.genero,
              email: item.trabajador.email,
              // cargo: item.trabajador?.cargo,
              cargo: cargos.find(item => item.idCargo == p_externo?.cargoId),
              trabajadorP_EXTERNOId: p_externo?.trabajadorP_EXTERNOId,
              tipoCargo: item?.tipoCargo,
              jornada: item.jornada,
              turno: item.turno.idTurno,
              llegada: item.fechaIngreso ? format(parseISO(item.fechaIngreso), 'yyyy-MM-dd') : '',
              salida: item.fechaSalida ? format(parseISO(item.fechaSalida), 'yyyy-MM-dd') : '',
              externo:false
            };
          
            // Actualizar setItemsNew3 con el nuevo ítem
            setItemsNew3(prevItems3 => {
                const itemExistsInPrevItems3 = prevItems3.some(existingItem => existingItem.rut === newItem.rut);
                if (itemExistsInPrevItems3) {
                  // Si el `rut` ya existe en prevItems3, no hacer nada
                  return prevItems3;
                }
                return [...prevItems3, newItem];
            });
            // Retornar el nuevo estado para setItemsNew
            return [...prevItems, newItem];
          });
      }); 
      const trabajadoresNoAcreditados = resp.data.filter(item => !item.trabajador?.acreditado || item.trabajador?.acreditado != true); 
      const trabNo = trabajadoresNoAcreditados.map(item => {
        console.log('itemmms', item)
          setItemsNew2(prevItems => {
            const newItem = {
              nombres: item.trabajador.nombres ?? item.trabajador.nombre,
              apellidos: item.trabajador.apellidos ?? `${item.trabajador.apellidoPaterno} ${item.trabajador.apellidoMaterno}`,
              direccion: item.trabajador.direccion,
              telefono: item.trabajador.telefono,
              edad: item.trabajador.edad,
              rut: item.trabajador.rut,
              genero: item.trabajador.genero,
              email: item.trabajador.email,
              cargo: item.trabajador?.cargo,
              tipoCargo: item?.tipoCargo,
              jornada: item.jornada,
              turno: turnos?.find(itemt => itemt.idTurno == item?.turno.idTurno),
              llegada: item.fechaIngreso ? format(parseISO(item.fechaIngreso), 'yyyy-MM-dd') : '',
              salida: item.fechaSalida ? format(parseISO(item.fechaSalida), 'yyyy-MM-dd') : '',
              externo:true
            };
          
            // Actualizar setItemsNew3 con el nuevo ítem
            setItemsNew3(prevItems3 => {
                const itemExistsInPrevItems3 = prevItems3.some(existingItem => existingItem.rut === newItem.rut);
                if (itemExistsInPrevItems3) {
                  // Si el `rut` ya existe en prevItems3, no hacer nada
                  return prevItems3;
                }
                return [...prevItems3, newItem];
            });
            // Retornar el nuevo estado para setItemsNew
            return [...prevItems, newItem];
          });
      }); 
      setAcreditados(trab)
      setNoAcreditados(trabNo)
      
    } catch (error) {
      console.log(error);
    } finally {
      setOpenBack(false);
    }
  }

  useEffect(() => {    
    if(seleItemSolicitud){
      setOpenAlerta(true)
      setFormData({})
      setItems([])
      setItemsNew([])
      setItemsNew2([])
      setItemsNew3([])
      setSelectedEmpresa({})
      setSelectedVicepresidencias({})
      setSelectedArea({})
      setSelectedGerencia({})
      console.log('seleItemSolicitud:',seleItemSolicitud)
      getTrabajadores();
      setFormData(prev => ({
        ...prev,
        empresa: seleItemSolicitud?.empresa.idEmpresa,
        gerencia: seleItemSolicitud?.gerencia,
        siArea: seleItemSolicitud?.sI_Area,
        contrato: seleItemSolicitud?.contrato,
        vicepresidencia: seleItemSolicitud?.vicepresidencia,
        fechaIngreso: seleItemSolicitud?.fechaIngreso ? format(parseISO(seleItemSolicitud?.fechaIngreso), 'yyyy-MM-dd') : '',
        fechaSalida: seleItemSolicitud?.fechaSalida ? format(parseISO(seleItemSolicitud?.fechaSalida), 'yyyy-MM-dd') : '',
        trabajadoresTotales: seleItemSolicitud?.trabajadoresTotales,
        trabajadoresDia: seleItemSolicitud?.trabajadoresDia,
        trabajadoresNoche: seleItemSolicitud?.trabajadoresNoche,
        descripcion: seleItemSolicitud?.observaciones
      }));
      setSelectedEmpresa(seleItemSolicitud?.empresa)
      getContratos()
      setSelectedVicepresidencias(seleItemSolicitud?.vicepresidencia)
      setSelectedArea(seleItemSolicitud?.sI_Area)
      setSelectedGerencia(seleItemSolicitud?.gerencia)
    }
  }, [seleItemSolicitud])
  


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleEmpresaChange = async (event, newValue) => {
    setSelectedEmpresa(newValue);
    setFormData(prevFormData => ({
      ...prevFormData,
      empresa: newValue ? newValue.idEmpresa : null
    }));
        try {
          // setOpen3(true);
          const resp = await axiosInstance.get('/api/v1/Solicitud/Contratos/'+newValue.idEmpresa);
          setFilterContratos(resp.data);
          setSelectedContrato('');
        } catch (error) {
          console.log(error);
        } finally {
          // setOpen3(false);
        }
  };

  const handleChangeFilter = (field, value) => {
    switch(field) {
      case 'vicepresidencia':
        setSelectedVicepresidencias(value);
        setFormData(prevFormData => ({
          ...prevFormData,
          vicepresidencia: value
        }));
        break;
      case 'gerencia':
        setSelectedGerencia(value);
        setFormData(prevFormData => ({
          ...prevFormData,
          gerencia: value
        }));
        break;
      case 'siArea':
        setSelectedArea(value);
        setFormData(prevFormData => ({
          ...prevFormData,
          siArea: value
        }));
        break;
      case 'contrato':
        setSelectedContrato(value);
        setFormData(prevFormData => ({
          ...prevFormData,
          contrato: value
        }));
        break;
      default:
        break;
    }
  };

  const [filtro, setFiltro] = useState('');

  const handleAgregarTramo = () => {
    // setTramos([...tramos, {}]);
    setItemsNew2([...itemsNew2, {}]);
  };

  const tiposCargo = ['Administrador de contrato', 'Administrativo', 'Supervisor', 'Operador', 'Mantenedor', 'Conductor'];
  const tiposGenero = ['Hombre', 'Mujer'];
  
  const handleChangeTramos = (e, index) => {
    const { name, value } = e.target;
    const updatedTramos = [...tramos];
    updatedTramos[index][name] = value;
    console.log(updatedTramos);
    setTramos(updatedTramos);
  };

  const handleAddTrabajadorSolicitud = (item) => {
    const updatedItemsNew3 = [...itemsNew3];
  
    itemsNew2.forEach((newItem) => {
      const existingIndex = updatedItemsNew3.findIndex((item) => item.rut === newItem.rut);
      if (existingIndex !== -1) {
        // Si el objeto ya existe en updatedItemsNew3, actualiza solo las propiedades modificadas y añade 'externo: true'
        updatedItemsNew3[existingIndex] = { ...updatedItemsNew3[existingIndex], ...newItem, externo: true };
      } else {
        // Si el objeto no existe en updatedItemsNew3, agrégalo con 'externo: true'
        updatedItemsNew3.push({ ...newItem, externo: true });
      }
    });
  
    setItemsNew3(updatedItemsNew3);
  };
  
  const handleAddTrabajadorSolicitud2 = () => {
    const updatedItemsNew3 = [...itemsNew3];
  
    itemsNew.forEach((newItem) => {
      const existingIndex = updatedItemsNew3.findIndex((item) => item.rut === newItem.rut);
      if (existingIndex !== -1) {
        // Si el objeto ya existe en updatedItemsNew3, actualiza solo las propiedades modificadas y añade 'externo: false'
        updatedItemsNew3[existingIndex] = { ...updatedItemsNew3[existingIndex], ...newItem, externo: false };
      } else {
        // Si el objeto no existe en updatedItemsNew3, agrégalo con 'externo: false'
        updatedItemsNew3.push({ ...newItem, externo: false });
      }
    });
    
    const mapped = updatedItemsNew3.map(item => ({
      ...item,
      turno: turnos.find(it => it.idTurno === item.turnoId),
      turnoId: item.turnoId
    }))
    console.log('updatedItemsNew3',mapped);
    console.log('turnos', turnos);
    setItemsNew3(mapped);
  };
  
  
  const handleEliminarTramo = (index, item) => {
    
    const updatedTramos = [...tramos];
    updatedTramos.splice(index, 1);
    setTramos(updatedTramos);

    const updatedItems = [...itemsNew2];
    updatedItems.splice(index, 1);
    setItemsNew2(updatedItems);
    
    setItemsNew3(itemsNew3.filter((i) => i.rut !== item.rut));

  };

  const handleAddItem = () => {
    if (newItem.trim() !== '') {
        setItems([...items, newItem]);
    }
  }; 
  const handleDeleteItem = (rut, nombre) => {
    console.log(rut, nombre)
    setItems(items.filter((i) => i.nombreCompleto !== nombre));
    setItemsNew(itemsNew.filter((i) => (i.rut != rut) && (i.nombreCompleto != nombre)));
    setItemsNew2(itemsNew2.filter((i) => (i.rut != rut) && (i.nombres != nombre)));
    setItemsNew3(itemsNew3.filter((i) => (i.rut != rut) && (i.nombreCompleto != nombre || i.nombres != nombre) ));
    // setItemsNew(itemsNew.filter((i) => (i.nombreCompleto === nombre) || (i.rut === rut) || (i.nombres === nombre)));
    // setItemsNew2(itemsNew2.filter((i) => (i.nombreCompleto === nombre) || (i.rut === rut) || (i.nombres === nombre)));
  };
  
  const finalColumns = reservasP_externoColumns(setOpen, setSeleItem, handleDeleteItem);

  const handleDeleteItem2 = (index) => {
    // setItemsNew2(itemsNew2.filter((_, i) => i !== index));
  };

  const onChangeTurno = (e, index, item) => {
    const { name, value } = e.target;
    console.log('value', value);
    const turn = turnos.find(item => item.idTurno == value)

    setItemsNew(prevItems => {
      const updatedItems = [...prevItems];
        const updatedItem = { ...updatedItems[index], turno: turn, turnoId: turn.idTurno }; 
        updatedItems[index] = updatedItem;
        return updatedItems;
    }); 
  };
  const onChangeTurno2 = (e, index, item) => {
    const { name, value } = e.target;
    console.log('value', value);
    const turn = turnos.find(item => item.idTurno == value.idTurno)

    setItemsNew2(prevItems => {
      const updatedItems = [...prevItems];
        const updatedItem = { ...updatedItems[index], turno: turn, turnoId: turn.idTurno }; 
        updatedItems[index] = updatedItem;
        return updatedItems;
    }); 
  };

  const formatRut = (rut) => { // Lógica para formatear el rut a 00.000.000-0
    if (!rut) return '';
    rut = rut.replace(/[^\dkK]/g, '');
    if (rut.length > 1) {
      const withoutCheckDigit = rut.slice(0, -1).replace(/\D/g, '');
      const checkDigit = rut.slice(-1).toUpperCase();
      return withoutCheckDigit.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + '-' + checkDigit;
    }
    return rut;
  };

  const onChangeRut = async (e) => {
    try {
      setOpenBack(true);
      const resp = await dispatch(getInfoByRutNameContractP_externo(e.target.value))
      setRuts(resp.payload.data);
    } catch (error) {
      console.log(error)
    } finally{
      setOpenBack(false)
    }
  }

  const onChangeRutSelect = async (e,newValue) => {
    if(newValue != null){
      try {
        console.log(newValue)
        setNewItem(newValue.rut);
        setSelectedRuts(prevState => ({
          ...prevState,
          ...newValue,
          turno: {
            descripcion: newValue.turno,
            idTurno: newValue.turnoId
          }
        }));
        const cargoFind = cargos && cargos?.find(item => item.idCargo == newValue.cargoId);
        setItemsNew(prevItems => [
          ...prevItems,
          {
            ...newValue,
            turno: newValue.turnoId,
            cargo: cargoFind
          }
        ]);
        
        setItems(prevItems => [...prevItems, {rut: newValue.rut, nombreCompleto: newValue.nombreCompleto}]);
      } catch (error) {
        console.log(error)
      }
    } else{
      setFormData({...formData, rut: null})
    }
  }
  

//   useEffect(() => {
//     // Filtrar itemsNew3 para incluir elementos que están en itemsNew2 o en itemsNew
//     const updatedItemsNew3 = [...itemsNew2.filter(item => !itemsNew.includes(item)), ...itemsNew.filter(item => !itemsNew2.includes(item))];
//     setItemsNew3(updatedItemsNew3);
// }, [itemsNew, itemsNew2]);

  const onChange2 = (e, index, item) => {
    const { name, value } = e.target;
    setItemsNew(prevItems => {
      const updatedItems = [...prevItems];
        const updatedItem = { ...updatedItems[index], [name]: value }; 
        updatedItems[index] = updatedItem;
        return updatedItems;
    }); 
  };

  const onChange22 = (e, index) => {
    const { name, value } = e.target;
    if(name === 'rut'){
      console.log('value', formatRut(value))
      console.log('name', name)
      setItemsNew2(prevItems => {
        const formatedRut = formatRut(value);
        const updatedItems = [...prevItems];
        const updatedItem = { ...updatedItems[index], [name]:  formatedRut};
        updatedItems[index] = updatedItem;
        return updatedItems;
      });  
    } else{
      setItemsNew2(prevItems => {
        const updatedItems = [...prevItems];
        const updatedItem = { ...updatedItems[index], [name]: value }; 
        updatedItems[index] = updatedItem;
        return updatedItems;
      });
    }
  };

  const onChangeCargo = async (e,newValue, index) => {
    setItemsNew2(prevItems => {
      const updatedItems = [...prevItems];
      const updatedItem = { ...updatedItems[index], cargo: newValue, idCargo: newValue.idCargo }; 
      updatedItems[index] = updatedItem;
      return updatedItems;
    });
  }
  const onChangeCargo2 = async (e,newValue, index) => {
    setItemsNew(prevItems => {
      const updatedItems = [...prevItems];
      const updatedItem = { ...updatedItems[index], cargo: newValue, idCargo: newValue.idCargo}; 
      updatedItems[index] = updatedItem;
      return updatedItems;
    });
  }

  useEffect(() => {
    const requiredFields = ['nombreCompleto', 'email', 'cargo', 'tipo_cargo', 'jornada', 'turno', 'llegada', 'salida'];
    const areAllItemsComplete = itemsNew.every(item => requiredFields.every(field => item[field] !== undefined && item[field] !== ''));
    setCompleted(areAllItemsComplete);
    console.log('itemsNew',itemsNew)
  }, [itemsNew])
  
  useEffect(() => {
    const requiredFields = ['rut','nombres', 'apellidos', 'email','edad','telefono','direccion','genero', 'cargo', 'tipo_cargo', 'jornada', 'turno', 'llegada', 'salida'];
    const areAllItemsComplete = itemsNew2.every(item => requiredFields.every(field => item[field] !== undefined && item[field] !== ''));
    setCompleted2(areAllItemsComplete);
  }, [itemsNew2])
  
  // useEffect(() => {
  //   if(completed2 || completed){
  //     toast.success('Actualiza la solicitud')
  //   }
  // }, [completed2, completed])



  const areFieldsComplete = (item, fields) => {
    return fields.every(field => item[field] !== undefined && item[field] !== '');
  };
  
  useEffect(() => {
    const requiredFields = ['nombreCompleto', 'email', 'cargo', 'tipo_cargo', 'jornada', 'turno', 'llegada', 'salida'];
    const requiredFieldsExt = ['rut', 'nombres', 'apellidos', 'email', 'edad', 'telefono', 'direccion', 'genero', 'cargo', 'tipo_cargo', 'jornada', 'turno', 'llegada', 'salida'];
  
    const areAllItemsComplete = itemsNew3.every(item =>
      areFieldsComplete(item, requiredFields) || areFieldsComplete(item, requiredFieldsExt)
    );
  
    setCheck3(areAllItemsComplete);
  }, [itemsNew3])

  const handleFile = async (e) => {
    const file = e.target.files[0];
    
    if (file) {
      let reader = new FileReader();
    
      reader.onload = async function(event) {
        let arrayBuffer = event.target.result;
        setFile(arrayBuffer);
    
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(arrayBuffer);
        const worksheet = workbook.worksheets[0];
      
        const headers = [];
        worksheet.getRow(5).eachCell((cell) => {
          headers.push(cell.text);
        });
      
        const startRow = 6;
        const jsonData = [];
        worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
          if (rowNumber >= startRow) {
            const rowData = {
              rut: row.getCell(headers.indexOf('Rut') + 1).text,
              nombre: row.getCell(headers.indexOf('Nombres') + 1).text,
              apellido: row.getCell(headers.indexOf('Apellidos') + 1).text,
              edad: row.getCell(headers.indexOf('Edad') + 1).text,
              telefono: row.getCell(headers.indexOf('Telefono') + 1).text,
              direccion: row.getCell(headers.indexOf('Direccion') + 1).text,
              email: row.getCell(headers.indexOf('Email') + 1).text,
              genero: row.getCell(headers.indexOf('Genero') + 1).text,
              cargo: row.getCell(headers.indexOf('Cargo') + 1).text,
              tipo_cargo: row.getCell(headers.indexOf('TipoCargo') + 1).text,
              jornada: row.getCell(headers.indexOf('Jornada') + 1).text,
              turno: row.getCell(headers.indexOf('Turno') + 1).text,
              llegada: row.getCell(headers.indexOf('FechaIngreso') + 1).text,
              salida: row.getCell(headers.indexOf('FechaSalida') + 1).text
            };
            jsonData.push(rowData);
          }
        });
      
        setItemsNew3(jsonData);
      };
    
      reader.readAsArrayBuffer(file);
    }
    e.target.value = null;
  };


  const handleClose = () => setOpen(false);
  const handleClickOpen2 = () => {
    // const dataWithDescription = selectedTurnos.map(item => ({ ...item, descripcion: descripcion }));
    // setSelectedTurnos(dataWithDescription);
    setFormData(prev => {
      console.log('selectedTurnos', selectedTurnos);
      const newData = { ...prev, trabajadores: itemsNew3 };
      console.log('newData', newData);
      return newData;
    });

    setOpen2(true)
  };
  const handleClose2 = () => setOpen2(false)
  const handleClose3 = () => setOpen3(false)
  const handleClose4 = () => setOpen4(false)

  const handleTurnoChange = (rut, turnoSeleccionado, nombre, fechaIngreso, fechaSalida, descripcion) => {
    // Encuentra el índice de la selección para el rut actual
    console.log(turnoSeleccionado, rut)
    const index = selectedTurnos.findIndex(item => item.rut === rut);
    const info = checkedState.find(item => item.rut === rut);
    console.log('info', info)
    // Crea el objeto que contiene el turno, la fecha de ingreso y la fecha de salida
    const nuevoTurno = { rut, nombre, turnoId: turnoSeleccionado.idTurno, jornada: turnoSeleccionado.tipoJornada, fechaIngreso, fechaSalida, descripcion: turnoSeleccionado.descripcion, trabajadorP_INTERNOId: info.trabajadorP_INTERNOId,
      trabajadorP_EXTERNOId: info.trabajadorP_EXTERNOId };
  
    // Si el rut ya tiene una selección, actualiza el turno seleccionado
    if (index !== -1) {
      const newSelectedTurnos = [...selectedTurnos];
      newSelectedTurnos[index] = nuevoTurno;
      setSelectedTurnos(newSelectedTurnos);
    } else {
      // Si es la primera selección para este rut, agrega una nueva entrada al estado
      setSelectedTurnos(prevState => [...prevState, nuevoTurno]);
    }
  };

  const handleFechaIngresoChange = (rut, fechaIngreso) => {
    const newSelectedTurnos = selectedTurnos.map(turno => {
      if (turno.rut === rut) {
        return { ...turno, fechaIngreso };
      }
      return turno;
    });
    setSelectedTurnos(newSelectedTurnos);
  };
  const handleFechaSalidaChange = (rut, fechaSalida) => {
    const newSelectedTurnos = selectedTurnos.map(turno => {
      if (turno.rut === rut) {
        return { ...turno, fechaSalida };
      }
      return turno;
    });
    setSelectedTurnos(newSelectedTurnos);
  };
  const handleJornadaChange = (rut, jornada) => {
    const newJornadaTurnos = selectedTurnos.map(turno => {
      if (turno.rut === rut) {
        return { ...turno, jornada };
      }
      return turno;
    });
    setSelectedTurnos(newJornadaTurnos);
  };
  

  useEffect(() => {
    const revisarDatosEnTurnos = () => {
      for (const el of selectedTurnos) {
          if (!el.nombre || !el.rut || !el.fechaIngreso || !el.fechaSalida || !el.jornada || !el.turnoId) {
              setCheck(0);
              return;
          }
      }
      setCheck(2);
  }
  
  revisarDatosEnTurnos();

  console.log('selected Turnos',selectedTurnos)
  console.log('checkedState',checkedState)
}, [selectedTurnos, turnoGrupal]);

  
  const modificarTurnoIntervalo = () => {
    const desdeIndex = parseInt(desde);
    const hastaIndex = parseInt(hasta);
  
    if (isNaN(desdeIndex) || isNaN(hastaIndex)) {
      alert('Por favor, ingrese valores válidos para los campos desde y hasta.');
      return;
    }
  
    if (desdeIndex > hastaIndex) {
      alert('El valor desde no puede ser mayor que el valor hasta.');
      return;
    }
    console.log('turnoGrupal',turnoGrupal)
    const nuevosTurnos = selectedTurnos.map((turno, index) => {
      if (index >= desdeIndex - 1 && index <= hastaIndex - 1) {
        return { ...turno, turnoId: turnoGrupal.idTurno, descripcion: turnoGrupal.descripcion }; 
      }
      return turno;
    });
    toast.success('Turnos modificados')
    setDesde(0);
    setHasta(0);
    setSelectedTurnos(nuevosTurnos);
  };
  const modificarFechasIntervalo = () => {
    const desdeIndex = parseInt(desde);
    const hastaIndex = parseInt(hasta);
  
    if (isNaN(desdeIndex) || isNaN(hastaIndex)) {
      alert('Por favor, ingrese valores válidos para los campos desde y hasta.');
      return;
    }
  
    if (desdeIndex > hastaIndex) {
      alert('El valor desde no puede ser mayor que el valor hasta.');
      return;
    }
    const nuevosTurnos = selectedTurnos.map((item, index) => {
      if (index >= desdeIndex - 1 && index <= hastaIndex - 1) {
        return { ...item, fechaIngreso: fechaInicioIntervalo, fechaSalida: fechaTerminoIntervalo }; 
      }
      return item;
    });
    toast.success('Fechas modificadas')
    setDesde(0);
    setHasta(0);
    setSelectedTurnos(nuevosTurnos);
  };
  const handleFiltroChange = (e) => {
    setFiltro(e.target.value);
  };
  const turnosFiltrados = selectedTurnos.filter(item => {
    return item.nombre.toLowerCase().includes(filtro.toLowerCase());
  });

  const canSave = [itemsNew3.length != 0].every(Boolean) 
  && addRequestStatus === 'idle' && check == 2;
  
  const canClickIngreso = [ formData.vicepresidencia, formData.gerencia, formData.siArea, formData.contrato, formData.empresa, formData.fechaIngreso,formData.fechaSalida,
    //  formData.trabajadoresNoche, formData.trabajadoresDia,
  formData.trabajadoresTotales,
  //  formData.descripcion 
  ].every(Boolean)  && addRequestStatus === 'idle';  

  useEffect(() => {
    setFormData({...formData, trabajadores: itemsNew3});
    console.log('cambio')
  }, [itemsNew3])
  useEffect(() => {
    console.log(formData)
  }, [formData])
  
  const handleSubmit = async () => {
    if(canSave) {
      setAddRequestStatus('loading');
      try {
        setOpen6(true)
        const resp = await dispatch(addNewReserva(formData));
        if(resp.payload.status == 'success'){
          setOpen(false);
          setFormData(data)
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          return;
        }
      } catch (error) {
        toast.error('Error al crear la reserva.');
        console.error('Error al crear la reseva.', error);
      } finally{
        setOpen6(false)
        setAddRequestStatus('idle');
      }
    }
  }

  const lista = [
    {
      "Rut" : "",
      "Nombres" : "",
      "Apellidos" : "",
      "Edad" : "",
      "Telefono" : "",
      "Direccion" : "",
      "Email" : "",
      "Genero" : "",
      "Cargo" : "",
      "TipoCargo" : "",
      "Jornada" : "",
      "Turno" : "",
      "FechaIngreso" : "",
      "FechaSalida" : "",
    }
  ]
  const lista_turnos = turnos?.map(turno => turno.descripcion);
  const lista_tiposCargo = tiposCargo.map(cargo => cargo).join('\n');
  const lista_jornadas = jornadas.map(jornada => jornada.value).join('\n');
  const lista_tiposGeneros = tiposGenero.map(genero => genero).join('\n');

  function extraerTurnoNumeros(elemento) {
    const match = elemento.match(/Turno\s+(\d+)/i);
    return match ? match[1] : null;
  }
  
  function sonDuplicados(elemento1, elemento2) {
    const turnoNumeros1 = extraerTurnoNumeros(elemento1);
    const turnoNumeros2 = extraerTurnoNumeros(elemento2);
    return turnoNumeros1 && turnoNumeros2 && turnoNumeros1 === turnoNumeros2;
  }
  
  const elementosExcluidos = ['Administratico Ciudad', '4x3 Opendat'];
  
  const lista_turnos_filtrada = lista_turnos.filter((turno, index, self) => {
    if (elementosExcluidos.includes(turno)) {
      return false;
    }
  
    const duplicadoIndex = self.slice(0, index).findIndex((otroTurno) => sonDuplicados(turno, otroTurno));
    return duplicadoIndex === -1;
  });
  
  const lista_turnoss = lista_turnos_filtrada.join('\n');

  const tabla = [
    ["Rut", "Nombres", "Apellidos", "Edad", "Telefono", "Direccion", "Email", "Genero", "Cargo", "TipoCargo", "Jornada", "Turno", "FechaIngreso", "FechaSalida"],
    ["Debe ingresar el rut sin puntos (.) y debe agregar el gión (-) por ejemplo: 11111111-1", "Jhon", "Perez Alvarez", "Debe ingresar solo números por ejemplo: 30", "+56992233456", "Pje alamos", "EMAIL@gmail.com", lista_tiposGeneros, "Cargo", lista_tiposCargo, lista_jornadas, lista_turnoss, "Debe ingresar la fecha en este formato aaaa-mm-dd ejemplo: 2024-05-20", "Debe ingresar la fecha en este formato aaaa-mm-dd ejemplo: 2024-05-20"]
  ];
  
  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Asignación');
  
    const defaultColumnWidth = 20;
    const specialColumnWidth = 25;
    const specialColumns = [9, 12];
  
    const headerColor = { type: 'pattern', pattern: 'solid', fgColor: { argb: '6f98b8' } };
    const dataColor = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'acafb1' } };
    const border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    const avisoColor = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffbc00' } };
  
    tabla.forEach((row, rowIndex) => {
      row.forEach((value, colIndex) => {
        const cell = worksheet.getCell(rowIndex + 1, colIndex + 1);
        cell.value = value.replace(/\n/g, '\r\n');
        cell.alignment = { wrapText: true };
        const columnWidth = specialColumns.includes(colIndex + 1) ? specialColumnWidth : defaultColumnWidth;
        worksheet.getColumn(colIndex + 1).width = columnWidth;
        cell.fill = rowIndex === 0 ? headerColor : dataColor;
        cell.border = border;
      });
    });

    const leyendaRow = 4;
    const leyenda = "Debe ingresar los datos de los trabajadores iniciando de la fila 6, para los campos de ejemplo en la parte superior cuando sea una lista de detalle solo copiar el nombre que necesita e insertarlo en la casilla correspondiente de cada campo en los datos de los trabajadores.";
    worksheet.mergeCells(`A${leyendaRow}:N${leyendaRow}`);
    const cell = worksheet.getCell(`A${leyendaRow}`);
    cell.value = leyenda;
    cell.alignment = { horizontal: 'left', vertical: 'middle' };
    cell.fill = avisoColor;
    cell.border = border;
  
    const listStartRow = 5;
    const listColumns = Object.keys(lista[0]).map(key => ({ header: key, key }));
    listColumns.forEach((col, colIndex) => {
      const cell = worksheet.getCell(listStartRow, colIndex + 1);
      cell.value = col.header.replace(/\n/g, '\r\n');
      cell.alignment = { wrapText: true };
      const columnWidth = specialColumns.includes(colIndex + 1) ? specialColumnWidth : defaultColumnWidth;
      worksheet.getColumn(colIndex + 1).width = columnWidth;
    });
    lista.forEach((item, rowIndex) => {
      listColumns.forEach((col, colIndex) => {
        const cell = worksheet.getCell(listStartRow + rowIndex + 1, colIndex + 1);
        cell.value = item[col.key].replace(/\n/g, '\r\n');
        cell.alignment = { wrapText: true };
      });
    });
  
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Asignación.xlsx');
  };  


  useEffect(() => {
    console.log('trabajadores',formData.trabajadores)
  }, [formData.trabajadores])
  
  const canSaveGeneral = [formData?.empresa, formData?.gerencia, formData?.vicepresidencia, formData?.siArea, formData?.      contrato, formData?.fechaSalida, formData?.fechaIngreso, formData?.trabajadoresTotales,
    // formData?.trabajadoresDia, 
    // formData?.trabajadoresNoche,
    // formData?.descripcion
  ].every(Boolean) && addRequestStatus === 'idle';

  const options = Array.isArray(viceprecidencia) ? viceprecidencia.map((item) => ({ value: item, label: item })) : [];
  const gerOptions = Array.isArray(gerencia) ? gerencia.map(item => ({ value: item, label: item })) : [];
  const siAreaOptions = Array.isArray(siArea) ? siArea.map(item => ({ value: item, label: item })) : [];

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBack}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{
          sx: {
           backgroundColor:'#efefef',
           color:'#2d3037',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Dialog
              open={open2}
              onClose={handleClose2}
              PaperComponent={PaperComponent}
              PaperProps={{
                sx: {
                  backgroundColor:'#efefef',
                  color:'#2d3037',
                  borderRadius:'0'
                }
              }}
        >
          <DialogTitle style={{ display:'flex', gap:'5px'}}>
            <HolidayVillageIcon  className={styles.iconBgCreate}  style={{ fontSize:'3rem', marginTop:'0px' }}/>
            <div style={{ display:'flex', flexDirection:'column' }}>
              <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Registro de reserva</Typography>
              <span className={styles.subTitle} style={{color:'#FFF'}}>Estás a punto de completar el ingreso de la reserva</span>
            </div>
          </DialogTitle>
          <DialogContent>
              <Typography>N° Trabajadores seleccionados:<span style={{ fontWeight:'600' }}>{' '}{formData?.trabajadores?.length}</span></Typography>
              <Typography>Desde: <span style={{ fontWeight:'600' }}>{formData?.fechaIngreso}</span> Hasta: <span style={{ fontWeight:'600' }}>{formData.fechaSalida}</span></Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit} className={styles.bgColorModal} variant="contained" disabled={addRequestStatus == 'loading'}>
            {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Confirmar'}
            </Button>
            <Button autoFocus className={styles.bgColorOutlined} variant="outlined" onClick={handleClose2}>Cancelar</Button>
          </DialogActions>
        </Dialog>
        
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ backgroundColor:'#333' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openBack}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
    
            <DialogTitle style={{ display:'flex', gap:'5px'}} id="draggable-dialog-title">
            <HolidayVillageIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }} />
            <div style={{ display:'flex', flexDirection:'column' }}>
              <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Registro de reserva</Typography>
              <span className={styles.subTitle}>Rellena los siguientes campos para completar el ingreso de la reserva</span>
            </div>
          </DialogTitle>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ padding:'10px' }}>
          <Grid container={true} spacing={1} style={{ padding:'0' }}>
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              style={{ borderRight:'none' }}
              sx={{ borderRight: 1, borderColor: 'divider',
                "& .MuiTabs-indicator": {
                    backgroundColor: "#FFF",
                    height: 3,
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color:'#2d3037'
                  },
                  "& .MuiTabs-root":{
                    borderRight:'none'
                  }
              }}
            >
              <Tab label="Datos generales" {...a11yProps(0)} />
              <Tab label="Trabajadores acreditados" 
              disabled={!canSaveGeneral} {...a11yProps(1)} 
              />
              <Tab label="Trabajadores sin acreditación" 
              disabled={!canSaveGeneral} {...a11yProps(2)} 
              />
              <Tab label="Carga masiva" {...a11yProps(3)} 
              disabled={!canSaveGeneral} 
              />
          </Tabs>
          <TabPanel value={value} index={0} style={{ paddingLeft:'0', paddingRight:'0' }}>
            <Grid container={true} spacing={1} >
                <Grid item  xs={12} sm={12} mt={2} style={{ marginBottom:'15px' }}>
                  <label style={{ fontWeight:'600' }}>
                      <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                        <div style={{ display:'flex', flexDirection:'column', alignItems:'start', gap:'2px' }}>
                          <span>Datos generales*</span>
                          <span style={{ fontWeight:'400' }}>Complete los siguientes campos de los datos generales de la reserva.</span>
                        </div>
                      </div>
                    </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label className={styles.label}>Fecha Ingreso *</label>
                  <TextField
                   className={styles.textField}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color:'#2d3037',
                      },
                      '& .MuiInputLabel-root': {
                        color:'#2d3037',
                      },
                      '& .MuiAutocomplete-clearIndicator': {
                        color:'#2d3037',
                      },
                      '& .MuiAutocomplete-popupIndicator': {
                        color:'#2d3037',
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name='fechaIngreso'
                    value={formData?.fechaIngreso || ''}
                    type="date"
                    onChange={(e) => setFormData(prev => ({...prev, [e.target.name]: e.target.value}))}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label className={styles.label}>Fecha Salida *</label>
                  <TextField
                   className={styles.textField}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color:'#2d3037',
                      },
                      '& .MuiInputLabel-root': {
                        color:'#2d3037',
                      },
                      '& .MuiAutocomplete-clearIndicator': {
                        color:'#2d3037',
                      },
                      '& .MuiAutocomplete-popupIndicator': {
                        color:'#2d3037',
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name='fechaSalida'
                    value={formData?.fechaSalida || ''}
                    type="date"
                    onChange={(e) => setFormData(prev => ({...prev, [e.target.name]: e.target.value}))}
                  />
                </Grid>
                <Grid item  xs={12} sm={4} >
                  <label className={styles.label}>Trabajadores Totales *</label>
                  <TextField
                   className={styles.textField}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color:'#2d3037',
                      },
                      '& .MuiInputLabel-root': {
                        color:'#2d3037',
                      },
                      '& .MuiAutocomplete-clearIndicator': {
                        color:'#2d3037',
                      },
                      '& .MuiAutocomplete-popupIndicator': {
                        color:'#2d3037',
                      },
                    }}
                        variant="outlined"
                        fullWidth
                        name='trabajadoresTotales'
                        value={formData?.trabajadoresTotales || ''}
                        type="number"
                        onChange={(e) => setFormData({...formData, [e.target.name]:e.target.value})}
                      />
                </Grid>
              
                <Grid item xs={12} md={4}>
                  <label className={styles.label}>Empresas</label>
                  <SelectReact
                    className={styles.textField}
                    options={empresas}
                    value={empresas.find(option => option.idEmpresa === formData?.empresa) || null}
                    onChange={(selectedOption) => handleEmpresaChange(null, selectedOption)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: '#FFF',
                        border: 'none',
                        color:'#2d3037',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color:'#2d3037',
                      }),
                      input: (provided) => ({
                        ...provided,
                        color:'#2d3037',
                        padding:'11px'
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: '#FFF',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? '#3182ce' : 'transparent',
                      }),
                    }}
                    getOptionLabel={(option) => option.razonSocial}
                    getOptionValue={(option) => option.id}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <label className={styles.label}>Contratos</label>
                  <Autocomplete
                    className={styles.textField}
                    sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                              },
                              '&:hover fieldset': {
                                border: 'none',
                              },
                              '&.Mui-focused fieldset': {
                                border: 'none',
                              },
                            },
                            '& .MuiInputBase-input': {
                              color:'#2d3037',
                            },
                            '& .MuiInputLabel-root': {
                              color:'#2d3037',
                            },
                            '& .MuiAutocomplete-clearIndicator': {
                              color:'#2d3037',
                            },
                            '& .MuiAutocomplete-popupIndicator': {
                              color:'#2d3037',
                            },
                          }}
                    disablePortal
                    id="contrato-autocomplete"
                    name="contrato"
                    options={filterContratos}
                    value={selectedContrato || null}
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => handleChangeFilter('contrato', value)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>  
                <Grid item xs={12} md={4}>
                  <label className={styles.label}>Vicepresidencias</label>
                  <SelectReact
                    className={styles?.textField}
                    options={options}
                    value={options.find(option => option.value === formData?.vicepresidencia) || null}
                    onChange={(selectedOption) => handleChangeFilter('vicepresidencia', selectedOption.value)}
                    onInputChange={handleInputChange}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: '#FFF',
                        border: 'none',
                        color:'#2d3037',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color:'#2d3037',
                      }),
                      input: (provided) => ({
                        ...provided,
                        color:'#2d3037',
                        padding:'11px',
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: '#FFF',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? '#3182ce' : 'transparent',
                      }),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <label className={styles.label}>Gerencias</label>
                  <SelectReact
                    className={styles?.textField}
                    options={gerOptions}
                    value={gerOptions.find(option => option.value === formData?.gerencia) || null}
                    onChange={(selectedOption) => handleChangeFilter('gerencia', selectedOption.value)}
                    onInputChange={handleInputChange}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: '#FFF',
                        border: 'none',
                        color:'#2d3037',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color:'#2d3037',
                      }),
                      input: (provided) => ({
                        ...provided,
                        color:'#2d3037',
                        padding:'11px',
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: '#FFF',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? '#3182ce' : 'transparent',
                      }),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <label className={styles.label}>siAreas</label>
                  <SelectReact
                    className={styles?.textField}
                    options={siAreaOptions}
                    value={siAreaOptions.find(option => option.value === formData?.siArea) || null}
                    onChange={(selectedOption) => handleChangeFilter('siArea', selectedOption.value)}
                    onInputChange={handleInputChange}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: '#FFF',
                        border: 'none',
                        color:'#2d3037',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color:'#2d3037',
                      }),
                      input: (provided) => ({
                        ...provided,
                        color:'#2d3037',
                        padding:'11px',
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: '#FFF',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? '#3182ce' : 'transparent',
                      }),
                    }}
                  />
                </Grid>
                <Grid item  xs={12} sm={12} >
                  <label className={styles.label}>Descripción *</label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color:'#2d3037',
                      },
                      '& .MuiInputLabel-root': {
                        color:'#2d3037',
                      },
                      '& .MuiAutocomplete-clearIndicator': {
                        color:'#2d3037',
                      },
                      '& .MuiAutocomplete-popupIndicator': {
                        color:'#2d3037',
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name='descripcion'
                    type="text"
                    value={formData?.descripcion || ''}
                    multiline
                    rows={2}
                    maxRows={4}
                    onChange={(e) => setFormData({...formData, [e.target.name]:e.target.value})}
                  />
                </Grid> 
              </Grid> 
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container={true} spacing={2}>
              <Grid item  xs={12} sm={12} mb={2}>
              { openAlerta &&
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert severity="warning" onClose={() => setOpenAlerta(false)}>
                    Completa todos los campos para poder actualizar la solicitud.
                  </Alert>
                </Stack>
              }
            </Grid>
              <Grid item  xs={12} sm={9} mb={2}>
                <label style={{ fontWeight:'600' }}>
                    <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                      <div style={{ display:'flex', flexDirection:'column', alignItems:'start', gap:'2px' }}>
                        <span>Asignar trabajadores que están en SISTEMA DE ACREDITACION.</span>
                        <span style={{ fontWeight:'400' }}>Ingresa el rut y/o nombre de un trabajador. Ingrese sus datos, y luego presione el boton de "Actualizar solicitud" para agregar el trabajador a la lista.</span>
                      </div>
                    </div>
                </label>
              </Grid>
            
              <Grid item xs={12} md={5} >
                <div className={styles.inventario}>
                  <Autocomplete
                    className={styles.textField}
                    sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                              },
                              '&:hover fieldset': {
                                border: 'none',
                              },
                              '&.Mui-focused fieldset': {
                                border: 'none',
                              },
                            },
                            '& .MuiInputBase-input': {
                              color:'#2d3037',
                            },
                            '& .MuiInputLabel-root': {
                              color:'#2d3037',
                            },
                            '& .MuiAutocomplete-clearIndicator': {
                              color:'#2d3037',
                            },
                            '& .MuiAutocomplete-popupIndicator': {
                              color:'#2d3037',
                            },
                          }}
                        disablePortal
                        disableClearable
                        id="sectores-autocomplete"
                        name="rut"
                        value={selectedRut}
                        options={ruts ? ruts : [] }
                        PopperComponent={(props) => (
                          <Popper
                            {...props}
                            sx={{
                              overflowY: 'auto',
                            }}
                          />
                        )}
                        getOptionLabel={(option) => option.rut + ' ' + option.nombreCompleto}
                        onChange={(e, newValue) => onChangeRutSelect(e, newValue)}
                        renderInput={(params) => <TextField {...params} label="Busca y selecciona un rut" onChange={(e) => onChangeRut(e)}/>}
                      />
                   </div>
              </Grid>
              <Grid item xs={5}>
                {items.map((item, index) => (
                  <Chip
                    key={index}
                    sx={{ 
                      '& .MuiSvgIcon-root':{
                        color:'#FFF'
                      }
                     }}
                    label={item.nombreCompleto}
                    onDelete={() => handleDeleteItem(item.rut,item.nombreCompleto)}
                    style={{ marginRight: '8px', marginBottom: '8px', color:'#FFF' }}
                  />
                ))}
              </Grid>
              </Grid>

              <div style={{ maxHeight:'400px', overflowY:'auto', border:itemsNew.length != 0 ? '1px solid rgb(199, 199, 199)': '', borderRadius:'2px', padding:'5px', margin:'0', marginTop:'20px', }}> 
              {itemsNew.length != 0 && itemsNew?.map((item, index) => (
                <Grid container={true} spacing={1} key={index} style={{ borderTop:'1px solid black', padding:'10px 5px' }}>
                  <Grid item xs={12} md={2}>
                    <label className={styles.label}>Rut</label>
                    <TextField
                       className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color:'#2d3037',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='rut'
                      value={item.rut}
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <label className={styles.label}>Nombre</label>
                    <TextField
                       className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color:'#2d3037',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='descripcion'
                      value={item.nombreCompleto}
                      type="text"
                      onChange={(e) => onChange2(e, index, item)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <label className={styles.label}>Email</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color:'#2d3037',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='email'
                      value={item.email}
                      type="email"
                      onChange={(e) => onChange2(e, index, item)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <label className={styles.label}>Cargo</label>
                    <Autocomplete
                      className={styles.textField}
                      sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                                '&:hover fieldset': {
                                  border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                  border: 'none',
                                },
                              },
                              '& .MuiInputBase-input': {
                                color:'#2d3037',
                              },
                              '& .MuiInputLabel-root': {
                                color:'#2d3037',
                              },
                              '& .MuiAutocomplete-clearIndicator': {
                                color:'#2d3037',
                              },
                              '& .MuiAutocomplete-popupIndicator': {
                                color:'#2d3037',
                              },
                            }}
                      disablePortal
                      disableClearable
                      id="sectores-autocomplete"
                      name="cargo"
                      value={item.cargo || null}
                      options={cargos ? cargos : [] }
                      getOptionLabel={(option) => option.nCargo}
                      onChange={(e, value) => onChangeCargo2(e, value, index)}
                      renderInput={(params) => <TextField {...params} label="Busque y seleccione un cargo"/>}
                  />
                  </Grid>
                  <Grid item  xs={12} sm={2}>
                      <label className={styles.label}>Género</label>
                      <Select
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label=""
                        name='genero'
                        value={item.genero}
                        style={{ width:'100%' }}
                        onChange={(e)=>onChange2(e,index)}
                        >
                        <MenuItem value='Mujer'>Mujer</MenuItem>
                        <MenuItem value='Hombre'>Hombre</MenuItem>
                    </Select>
                    </Grid>
                  <Grid item xs={12} md={2}>
                    <label className={styles.label}>Tipo Cargo</label>
                    <Select
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label=""
                      name='tipo_cargo'
                      value={item.tipo_cargo}
                      style={{ width:'100%' }}
                      onChange={(e)=>onChange2(e,index, item)}
                      >
                        <MenuItem value='Administrador de contrato'>Administrador de contrato</MenuItem>
                        <MenuItem value='Administrativo'>Administrativo</MenuItem>
                        <MenuItem value='Supervisor'>Supervisor</MenuItem>
                        <MenuItem value='Operador'>Operador</MenuItem>
                        <MenuItem value='Mantenedor'>Mantenedor</MenuItem>
                        <MenuItem value='Conductor'>Conductor</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <label className={styles.label}>Jornada</label>
                    <Select
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label=""
                      value={item.jornada}
                      name='jornada'
                      style={{ width:'100%' }}
                      onChange={(e)=>onChange2(e,index, item)}
                      >
                        <MenuItem value='Diurna'>Diurna</MenuItem>
                        <MenuItem value='Nocturna'>Nocturna</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <label className={styles.label}>Turno</label>
                    <Select
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                      style={{ width:'100%' }}
                      // displayEmpty
                      name='turno'
                      value={item.turno}
                      onChange={e=>onChange2(e,index, item)}
                      >
                        {turnos && turnos?.map((option) => (
                          <MenuItem key={option.idTurno} value={option.idTurno}>
                            {option.descripcion}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <label className={styles.label}>Llegada</label>
                    <TextField
                     className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color:'#2d3037',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      value={item.llegada}
                      name='llegada'
                      type="date"
                      onChange={(e) => onChange2(e, index, item)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <label className={styles.label}>Salida</label>
                    <TextField
                     className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color:'#2d3037',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      value={item.salida}
                      name='salida'
                      type="date"
                      onChange={(e) => onChange2(e, index, item)}
                    />
                  </Grid>
                </Grid>
              )) }
            </div>
            <Button
                style={{ marginTop:'5px' }}
                className={styles.bgColorBlue}
                variant="contained"
                disabled={!completed}
                onClick={() => handleAddTrabajadorSolicitud2()}
              >
                Actualizar solicitud
              </Button>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid container={true}>
              <Grid item  xs={12} sm={6} style={{ display:'flex', flexDirection:'column', justifyContent:'space-between' }} mt={2}>
                <span style={{ display:'block', fontWeight:'600' }}>Asignar trabajadores que no están en SISTEMA DE ACREDITACION.</span>
                <Typography>El siguiente boton mostrará un nuevo formulario para poder agregar un nuevo trabajador a la solicitud. Presionalo cada vez que quieras agregar un trabajador no listado en SISTEMA DE ACREDITACION</Typography>
              </Grid>
              <Grid item  xs={12} sm={12} mt={1} mb={2}>
                <Button 
                  className={styles.bgColorOrange} 
                  variant="fulled" 
                  onClick={handleAgregarTramo}>
                  Añadir formulario
                </Button>
              </Grid>
            </Grid>
            {/*
          */}
            <div style={{ maxHeight:'400px', overflowY:'auto' }}>
              {itemsNew2.map((item, index) => (
                <Grid container={true} spacing={1} style={{ borderBottom:'1px solid #333', padding:'5px 0' }}>
                    <Grid item  xs={12} sm={2}>
                      <label className={styles.label}>Rut</label>
                      <TextField
                       className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                        variant="outlined"
                        fullWidth
                        value={item.rut}
                        name='rut'
                        type="text"
                        onChange={(e) => onChange22(e,index)}
                        />
                    </Grid>
                    <Grid item  xs={12} sm={3}>
                      <label className={styles.label}>Nombres</label>
                      <TextField
                       className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color:'#2d3037',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      value={item.nombres}
                      name='nombres'
                      type="text"
                      onChange={(e) => onChange22(e,index)}
                      />
                    </Grid>
                    <Grid item  xs={12} sm={4}>
                      <label className={styles.label}>apellidos</label>
                      <TextField
                       className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                      variant="outlined"
                      fullWidth
                      value={item.apellidos}
                      name='apellidos'
                      type="text"
                      onChange={(e) => onChange22(e,index)}
                      />
                    </Grid>
                    <Grid item  xs={12} sm={1}>
                      <label className={styles.label}>Edad</label>
                      <TextField
                       className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color:'#2d3037',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='edad'
                      value={item.edad}
                      InputProps={{ inputProps: { min: 0 } }}
                      type="number"
                      onChange={(e) => onChange22(e,index)}
                      />
                    </Grid>
                    <Grid item  xs={12} sm={2}>
                      <label className={styles.label}>Teléfono</label>
                      <TextField
                       className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                      variant="outlined"
                      fullWidth
                      name='telefono'
                      value={item.telefono}
                      InputProps={{ inputProps: { min: 0 } }}
                      type="number"
                      onChange={(e) => onChange22(e,index)}
                      />
                    </Grid>
                    <Grid item  xs={12} sm={4}>
                      <label className={styles.label}>Dirección</label>
                      <TextField
                       className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color:'#2d3037',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='direccion'
                      value={item.direccion}
                      InputProps={{ inputProps: { min: 0 } }}
                      type="text"
                      onChange={(e) => onChange22(e,index)}
                      />
                    </Grid>
                    <Grid item  xs={12} sm={3}>
                      <label className={styles.label}>Email</label>
                      <TextField
                       className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                        variant="outlined"
                        fullWidth
                        name='email'
                        value={item.email}
                        InputProps={{ inputProps: { min: 0 } }}
                        type="email"
                        onChange={(e) => onChange22(e,index)}
                      />
                    </Grid>
                    <Grid item  xs={12} sm={2}>
                      <label className={styles.label}>Género</label>
                      <Select
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label=""
                      name='genero'
                      value={item.genero}
                      style={{ width:'100%' }}
                      onChange={(e)=>onChange22(e,index)}
                      >
                        {tiposGenero.map((tipo) => 
                        <MenuItem value={tipo}>{tipo}</MenuItem>
                      )}
                    </Select>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <label className={styles.label}>Cargo</label>
                      <SelectReact
                        className={styles.textField}
                        options={cargos}
                        value={item.cargo || null}
                        onChange={(selectedOption) => onChangeCargo(null, selectedOption, index)}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: '#FFF',
                            border: 'none',
                            color:'#2d3037',
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color:'#2d3037',
                          }),
                          input: (provided) => ({
                            ...provided,
                            color:'#2d3037',
                            padding:'11px'
                          }),
                          menu: (provided) => ({
                            ...provided,
                            backgroundColor: '#FFF',
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? '#3182ce' : 'transparent',
                          }),
                        }}
                        getOptionLabel={(option) => option.nCargo}
                        getOptionValue={(option) => option.idCargo}
                      />
                    </Grid>
                  <Grid item xs={12} md={3}>
                    <label className={styles.label}>Tipo Cargo</label>
                    <Select
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label=""
                      name='tipo_cargo'
                      value={item.tipo_cargo}
                      style={{ width:'100%' }}
                      onChange={(e)=>onChange22(e,index)}
                    >
                      {tiposCargo.map((tipo) => 
                        <MenuItem value={tipo}>{tipo}</MenuItem>
                      )}
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <label className={styles.label}>Turno</label>
                    <Select
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                      style={{ width:'100%' }}
                      // displayEmpty
                      name='turno'
                      value={item.turno}
                      onChange={e=>onChange22(e,index, item)}
                      >
                        {turnos && turnos?.map((option) => (
                          <MenuItem key={option.idTurno} value={option}>
                            {option.descripcion}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <label className={styles.label}>Jornada</label>
                    <Select
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label=""
                      value={item.jornada}
                      name='jornada'
                      style={{ width:'100%' }}
                      onChange={(e)=>onChange22(e,index)}
                      >
                        <MenuItem value='Diurna'>Diurna</MenuItem>
                        <MenuItem value='Nocturna'>Nocturna</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <label className={styles.label}>Llegada</label>
                    <TextField
                     className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color:'#2d3037',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      value={item.llegada}
                      name='llegada'
                      type="date"
                      onChange={(e) => onChange22(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2  }>
                    <label className={styles.label}>Salida</label>
                    <TextField
                     className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color:'#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color:'#2d3037',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color:'#2d3037',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      value={item.salida}
                      name='salida'
                      type="date"
                      onChange={(e) => onChange22(e, index)}
                    />
                  </Grid>
                   
                    <Grid item  xs={12} sm={2} style={{ display:'flex', flexDirection:'column' }}>
                      <label className={styles.label} style={{ opacity:'0' }}>---</label>
                      <Button 
                      style={{ marginTop:'5px', fontSize:'12px' }}
                      className={styles.bgColor}
                      variant="contained"
                      disabled={!completed2}
                      onClick={() => handleAddTrabajadorSolicitud(item)}
                      >
                      Agregar a la solicitud
                      </Button>
                    </Grid> 
                    <Grid item  xs={12} sm={2} style={{ display:'flex', flexDirection:'column' }}>
                      <label className={styles.label} style={{ opacity:'0' }}>---</label>
                      <Button 
                      style={{ marginTop:'5px',fontSize:'12px', border:'none' }}
                      className={styles.bgColorDelete}
                      variant="outlined"
                      onClick={() => handleEliminarTramo(index, item)}
                      >
                        Remover
                      </Button>
                    </Grid>

                    </Grid>
                  ))}
                </div>
          </TabPanel>
          <TabPanel value={value} index={3} style={{ width:'84%' }}>
            <Grid item  xs={12} sm={12}>
            <label style={{ fontWeight:'600' }}>
                <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                  <div style={{ display:'flex', flexDirection:'column', alignItems:'start', gap:'2px' }}>
                    <span>Asignar trabajadores a tráves de archivo EXCEL. </span>
                    <span style={{ fontWeight:'400' }}>Sube un archivo excel (.xlsx) con los siguientes datos en orden.</span>
                    <ul>
                      <li>Rut</li>
                      <li>Nombres</li>
                      <li>Apellidos</li>
                      <li>Edad</li>
                      <li>Teléfono</li>
                      <li>Dirección</li>
                      <li>Email</li>
                      <li>Género</li>
                      <li>Cargo</li>
                      <li>Tipo de cargo</li>
                      <li>Jornada</li>
                      <li>Turno</li>
                      <li>Fecha de llegada</li>
                      <li>Fecha de salida</li>
                    </ul>
                  </div>
                </div>
            </label>
          </Grid>
          <Grid item  xs={12} sm={12} mt={1} mb={2}>
                <Button 
                  style={{ marginTop:'5px' }}
                  className={styles.bgColor}
                  variant="contained"
                  onClick={handleDownload}>
                  Descargar Template
                </Button>
          </Grid>

          {
              <Grid item xs={12} sm={6} mt={2} mb={1}>
              <input
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
                onChange={(e) => handleFile(e)}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  Subir archivo
                </Button>
              </label>
            </Grid>
            }
            <div style={{ width:'100%', maxHeight:'300px', overflowY:'scroll', position:'relative', 
              border:(check != 0 && descripcion && jornada && fechaInicio && fechaTermino) ? '1px solid #333':'', borderRadius:'5px'}}>
              {/*(check != 0 && fechaInicio && fechaTermino && jornada && descripcion) && */}
            {
                  turnosFiltrados.map((item, i)=> (
                    <Grid container={true} spacing={2} style={{ paddingLeft:'9px' }} key={i}>
                      <Grid item  xs={12} sm={3}>
                        <label className={styles.label}><strong style={{ fontSize:'12px' }}>{item.nombre}</strong></label>
                          <Select
                            className={styles.textField}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                                '&:hover fieldset': {
                                  border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                  border: 'none',
                                },
                              },
                              '& .MuiInputBase-input': {
                                color:'#2d3037',
                              },
                              '& .MuiInputLabel-root': {
                                color:'#2d3037',
                              },
                              '& .MuiAutocomplete-clearIndicator': {
                                color:'#2d3037',
                              },
                              '& .MuiAutocomplete-popupIndicator': {
                                color:'#2d3037',
                              },
                            }}
                            style={{ width:'100%' }}
                            displayEmpty
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Seleccione un turno para {item.nombre}</em>;
                              }
                              return selected;
                            }}
                            name='turno'
                            value={selectedTurnos.find(turno => turno.rut === item.rut)?.descripcion || ''}
                            onChange={(e) => handleTurnoChange(item.rut, e.target.value, item.nombre, item.fechaIngreso, item.fechaSalida, item.descripcion)}
                          >
                          {turnos && turnos?.map((option) => (
                            <MenuItem key={option.idTurno} value={option}>
                              {option.descripcion}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item  xs={12} sm={3}>
                        <label className={styles.label}>Jornada</label>
                          <Select
                            className={styles.textField}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                                '&:hover fieldset': {
                                  border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                  border: 'none',
                                },
                              },
                              '& .MuiInputBase-input': {
                                color:'#2d3037',
                              },
                              '& .MuiInputLabel-root': {
                                color:'#2d3037',
                              },
                              '& .MuiAutocomplete-clearIndicator': {
                                color:'#2d3037',
                              },
                              '& .MuiAutocomplete-popupIndicator': {
                                color:'#2d3037',
                              },
                            }}
                            style={{ width:'100%' }}
                            displayEmpty
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Seleccione una jornada para {item.nombre}</em>;
                              }
                              return selected;
                            }}
                            name='turno'
                            value={selectedTurnos.find(turno => turno.rut === item.rut)?.jornada || ''}
                            onChange={(e) => handleJornadaChange(item.rut, e.target.value)}
                          >
                          {jornadas.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item  xs={12} sm={3}>
                        <label className={styles.label}>Fecha ingreso</label>
                        <TextField
                         className={styles.textField}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                              },
                              '&:hover fieldset': {
                                border: 'none',
                              },
                              '&.Mui-focused fieldset': {
                                border: 'none',
                              },
                            },
                            '& .MuiInputBase-input': {
                              color:'#2d3037',
                            },
                            '& .MuiInputLabel-root': {
                              color:'#2d3037',
                            },
                            '& .MuiAutocomplete-clearIndicator': {
                              color:'#2d3037',
                            },
                            '& .MuiAutocomplete-popupIndicator': {
                              color:'#2d3037',
                            },
                          }}
                          variant="outlined"
                          fullWidth
                          name='fechaInicio'
                          type="date"
                          value={selectedTurnos.find(turno => turno.rut === item.rut)?.fechaIngreso || ''}
                          placeholder={`Selecciona una fecha para ${item.nombre}`}
                          onChange={(e) => handleFechaIngresoChange(item.rut, e.target.value)}
                        />
                      </Grid>
                      <Grid item  xs={12} sm={3}>
                        <label className={styles.label}>Fecha Salida</label>
                          <TextField
                           className={styles.textField}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                                '&:hover fieldset': {
                                  border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                  border: 'none',
                                },
                              },
                              '& .MuiInputBase-input': {
                                color:'#2d3037',
                              },
                              '& .MuiInputLabel-root': {
                                color:'#2d3037',
                              },
                              '& .MuiAutocomplete-clearIndicator': {
                                color:'#2d3037',
                              },
                              '& .MuiAutocomplete-popupIndicator': {
                                color:'#2d3037',
                              },
                            }}
                            variant="outlined"
                            fullWidth
                            name='fechaInicio'
                            type="date"
                            value={selectedTurnos.find(turno => turno.rut === item.rut)?.fechaSalida || ''}
                            placeholder={`Selecciona una fecha para ${item.nombre}`}
                            onChange={(e) => handleFechaSalidaChange(item.rut, e.target.value)}
                          />
                        </Grid>
                      </Grid>
                  ))}

                </div>
          </TabPanel>
        </Grid>

        <Dialog
              open={open3}
              onClose={handleClose3}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">En esta sección podrás modificar turno y fechas por grupo de trabajadores.</DialogTitle>
          <DialogContent>
            <Grid item  xs={12} sm={12} mt={2}>
              <label style={{ fontWeight:'600' }}>
                  <div style={{ display:'flex', flexDirection:'column', alignItems:'start', gap:'2px' }}>
                    <span>{`Ingrese intervalo de trabajadores para modificar turno, total de trabajadores seleccionados: ${checkedState.length}` }</span>
                    
                  </div>
              </label>
            </Grid>
            <Grid item  xs={12} sm={12} mt={1}>
              <TextField
                variant="outlined"
                fullWidth
                name='desde'
                placeholder='Desde trabajador N°'
                type="number"
                // disabled={!(check != 0 && fechaInicio && fechaTermino && jornada && descripcion) }
                onChange={(e) => setDesde(e.target.value)}
              />
            </Grid>
            <Grid item  xs={12} sm={12} mt={1}>
              <TextField
                variant="outlined"
                fullWidth
                name='hasta'
                placeholder='Hasta trabajador N°'
                type="number"
                // disabled={!(check != 0 && fechaInicio && fechaTermino && jornada && descripcion) }
                onChange={(e) => setHasta(e.target.value)}
              />
            </Grid>
            <Grid item  xs={12} sm={12} mt={1}>
              <Select
              className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color:'#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color:'#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color:'#2d3037',
                          },
                        }}
                style={{ width:'100%' }}
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Seleccione turno</em>;
                  }
                  return selected.descripcion;
                }}
                name='turno'
                value={turnoGrupal}
                onChange={(e)=>setTurnoGrupal(e.target.value)}
              >
                {turnos && turnos?.map((option) => (
                  <MenuItem key={option.idTurno} value={option}>
                    {option.descripcion}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item  xs={12} sm={12} mt={2}>
              <Button 
                variant='contanied'
                size='large'
                // disabled={!(check != 0 && fechaInicio && fechaTermino && jornada && descripcion) && !(desde && hasta && turnoGrupal) }
                className={styles.bgColor} 
                onClick={modificarTurnoIntervalo}>Modificar turnos</Button>
            </Grid>

            <Grid item  xs={12} sm={12} mt={2}>
              <label style={{ fontWeight:'600' }}>
                  <div style={{ display:'flex', flexDirection:'column', alignItems:'start', gap:'2px' }}>
                    <span>{`Ingrese intervalo de trabajadores para modificar las fechas de ingreso y salida, total de trabajadores seleccionados: ${checkedState.length}` }</span>
                    { !turnoGeneral && <span style={{ fontWeight:'400' }}>Primero debes seleccionar un turno general.</span> }
                  </div>
              </label>
            </Grid>
            <Grid item  xs={12} sm={12} mt={1}>
              <TextField
               className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color:'#2d3037',
                  },
                  '& .MuiInputLabel-root': {
                    color:'#2d3037',
                  },
                  '& .MuiAutocomplete-clearIndicator': {
                    color:'#2d3037',
                  },
                  '& .MuiAutocomplete-popupIndicator': {
                    color:'#2d3037',
                  },
                }}
                variant="outlined"
                fullWidth
                name='desde'
                placeholder='Desde trabajador N°'
                // disabled={!(check != 0 && fechaInicio && fechaTermino && jornada && descripcion) }
                type="number"
                onChange={(e) => setDesde(e.target.value)}
              />
            </Grid>
            <Grid item  xs={12} sm={12} mt={1}>
              <TextField
               className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color:'#2d3037',
                  },
                  '& .MuiInputLabel-root': {
                    color:'#2d3037',
                  },
                  '& .MuiAutocomplete-clearIndicator': {
                    color:'#2d3037',
                  },
                  '& .MuiAutocomplete-popupIndicator': {
                    color:'#2d3037',
                  },
                }}
                variant="outlined"
                fullWidth
                name='hasta'
                placeholder='Hasta trabajador N°'
                type="number"
                // disabled={!(check != 0 && fechaInicio && fechaTermino && jornada && descripcion) }
                onChange={(e) => setHasta(e.target.value)}
              />
            </Grid>
            <Grid item  xs={12} sm={12} mt={1}>
              <label className={styles.label}>Fecha de ingreso</label>
              <TextField
               className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color:'#2d3037',
                  },
                  '& .MuiInputLabel-root': {
                    color:'#2d3037',
                  },
                  '& .MuiAutocomplete-clearIndicator': {
                    color:'#2d3037',
                  },
                  '& .MuiAutocomplete-popupIndicator': {
                    color:'#2d3037',
                  },
                }}
                variant="outlined"
                fullWidth
                name='fechaInicio'
                type="date"
                // disabled={!(check != 0 && fechaInicio && fechaTermino && jornada && descripcion) }
                onChange={(e) => setFechaInicioIntervalo(e.target.value)}
              />
            </Grid>
            <Grid item  xs={12} sm={12} mt={1}>
              <label className={styles.label}>Fecha de salida</label>
              <TextField
               className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color:'#2d3037',
                  },
                  '& .MuiInputLabel-root': {
                    color:'#2d3037',
                  },
                  '& .MuiAutocomplete-clearIndicator': {
                    color:'#2d3037',
                  },
                  '& .MuiAutocomplete-popupIndicator': {
                    color:'#2d3037',
                  },
                }}
                variant="outlined"
                fullWidth
                name='fechaInicio'
                // disabled={!(check != 0 && fechaInicio && fechaTermino && jornada && descripcion) }
                type="date"
                onChange={(e) => setFechaTerminoIntervalo(e.target.value)}
              />
            </Grid>
            <Grid item  xs={12} sm={12} mt={2}>
              <Button 
                variant='contanied'
                size='large'
                // disabled={!(check != 0 && fechaInicio && fechaTermino && jornada && descripcion) && !(desde && hasta && fechaInicioIntervalo && fechaTerminoIntervalo) }
                className={styles.bgColor} 
                onClick={modificarFechasIntervalo}>Modificar fechas</Button>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button autoFocus className={styles.bgColorOutlined} variant="outlined" onClick={handleClose3}>Cerrar</Button>
          </DialogActions>
        </Dialog>
  
              {
                canSaveGeneral && <>
                <Accordion style={{ margin:'16px', marginBottom:'0', backgroundColor:'var(--inst-card-bg)',color:'#FFF', border:'1px solid var(--border-blue)' }}>
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>Click aquí para ver el resumen de la solicitud</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <Typography>
                    <Grid container={true} spacing={1}> 
                      <Grid item  xs={12} sm={4} style={{ display:'flex', flexDirection:'column' }}>
                        <span>Empresa: <span style={{ fontWeight:'600' }}>{formData?.empresa}</span></span>
                        <span>Contrato: <span style={{ fontWeight:'600' }}>{formData?.contrato}</span></span>
                        <span>Vicepresidencia: <span style={{ fontWeight:'600' }}>{formData?.vicepresidencia}</span></span>
                        <span>Gerencia: <span style={{ fontWeight:'600' }}>{formData?.gerencia}</span></span>
                        <span>SiArea: <span style={{ fontWeight:'600' }}>{formData?.siArea}</span></span>
                      </Grid>
                      <Grid item  xs={12} sm={4} style={{ display:'flex', flexDirection:'column' }}>
                        <span>Fecha Ingreso: <span style={{ fontWeight:'600' }}>{formData?.fechaIngreso}</span></span>
                        <span>Fecha Salida: <span style={{ fontWeight:'600' }}>{formData?.fechaSalida}</span></span>
                        <p>Descripción</p>
                        <span style={{ fontWeight:'600' }}>{formData?.descripcion}</span>
                      </Grid>
                      <Grid item  xs={12} sm={4} style={{ display:'flex', flexDirection:'column' }}>
                        <span>Trabajadores totales: <span style={{ fontWeight:'600' }}>{formData?.trabajadoresTotales}</span></span>
                        <span>Trabajadores dia: <span style={{ fontWeight:'600' }}>{formData?.trabajadoresDia}</span></span>
                        <span>Trabajadores noche: <span style={{ fontWeight:'600' }}>{formData?.trabajadoresNoche}</span></span>
                      </Grid>
                    </Grid>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                  <Grid container={true} spacing={1} style={{ padding:'16px', paddingTop:'0' }}> 
                    <Grid item  xs={12} sm={12} >
                      <DataGrid
                        getRowId={row=>row.rut ? row.rut : Math.random() }
                        lots={{ toolbar: GridToolbar }}
                        rows={formData?.trabajadores || []}
                        columns={finalColumns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        // isRowSelectable={(params) => params.row.acreditacion_vigente == true}
                        localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                        noRowsLabel: "No hay datos disponibles",
                        pagination: {
                            labelRowsPerPage: "Filas por página:",
                        }
                        }}
                        classes={{ menuIcon: styles.menuIcon }}
                           style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
                        sx={{ 
                          '& .MuiDataGrid-cell': {
                            color:'#2d3037',
                            borderBottom: '1px solid #c6c6c6',
                          },
                          '& .MuiDataGrid-columnHeader': {
                            color:'#2d3037',
                            borderBottom: '1px solid #c6c6c6',
                          },
                          '& .MuiDataGrid-columnHeaders': {
                            borderBottom: '1px solid #c6c6c6',
                          },
                          '& .MuiDataGrid-footerContainer': {
                            color:'#2d3037',
                            borderTop: '1px solid #c6c6c6',
                          },
                          '& .MuiTablePagination-root': {
                            color:'#2d3037',
                            borderTop: '1px solid #c6c6c6',
                          },
                          '& .MuiDataGrid-toolbarContainer': {
                            color:'#2d3037',
                          },
                          '& .MuiDataGrid-menu': {
                            color:'#2d3037',
                          },
                          '& .MuiTablePagination-actions': {
                            color:'#2d3037', // Color for pagination actions
                          },
                          '& .MuiTablePagination-select': {
                            color:'#2d3037', // Color for pagination select
                          },
                          '& .MuiTablePagination-selectLabel': {
                            color:'#2d3037', // Color for pagination select label
                          },
                          '& .MuiTablePagination-displayedRows': {
                            color:'#2d3037', // Color for displayed rows text
                          },
                        }}
                        slotProps={{
                          toolbar:{
                            sx:{
                              color:'#FFF'
                            }
                          },
                          pagination: {
                            sx: {
                              color:'#2d3037',
                            },
                          },
                          menu: {
                            sx: {
                              color:'#2d3037',
                            },
                          },
                        }}
                      />                 
                    </Grid> 
                  </Grid> 
                </>
              }
        </DialogContent>

        {/*((canSave && completed) || (canSave && completed2) || file) &&  */}
        {
          ((canSave && check3) || file) && 
          <DialogActions>
            <Grid container={true} spacing={1} style={{ paddingLeft:'16px', paddingRight:'16px', display:'flex', alignItems:'center', justifyContent:'center', textAlign:'center'}}>
              <Grid item  xs={12} sm={12} mt={3}>
                <Button 
                  onClick={handleClickOpen2}
                  className={styles.bgColorModal}
                  variant="contained"
                  disabled={(!canSave || !check) && (!canSave || !check) && !file}
                  style={{ width:'400px' }}
                >
                  {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Crear'}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        }
      </Dialog>
    </div>
  )
}

export default Edit2

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}