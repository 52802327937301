import styles from './components.module.css'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Autocomplete, Backdrop, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { addNewResponsable } from '../../../../redux/features/responsables/responsableSlice';
import PersonIcon from '@mui/icons-material/Person';
import CircleIcon from '@mui/icons-material/Circle';

const data = {
  problema: '',
  correo: [],
}
const options = [
  { label: "Modificacion Reserva", value: "Modificacion Reserva" },
  { label: "Problema Tecnico", value: "Problema Tecnico" },
  { label: "Creacion Usuario", value: "Creacion Usuario" },
  { label: "Registro de Reclamo", value: "Registro de Reclamo" },
  { label: "Forecast", value: "Forecast" },
];
const Create = ({ setOpen2, open2 }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [problema, setProblema] = useState([])
  
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState('');
  
  const patron = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleAddItem = () => {
    if (newItem.trim() !== '') {
      if(patron.test(newItem)){
        setItems([...items, newItem]);
        setNewItem('');
      } else{
        toast.error('El correo ingresado no es valido.')
      }
    }
  }; 

  const handleDeleteItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  useEffect(()=>{
    setFormData({...formData, correo: items})
  },[items])
  
  const handleChangeOption = (event, newValue) => {
    setProblema(newValue);
    if(newValue != null){
      setFormData({...formData, problema: newValue.value});
    } else{
      setFormData({...formData, problema: 0})
    }
  };
  
  const canSave = [formData.correo.length > 0, formData.problema].every(Boolean) && addRequestStatus === 'idle';
  

  const handleSubmit = async () => {
    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        let count = 0;
        for (let index = 0; index < formData.correo.length; index++) {
          await dispatch(addNewResponsable({...formData, correo: formData.correo[index]}));
          count++;
        }
        if(count === formData.correo.length){
          setFormData(data);
          setOpen(false);
          setItems([])
          setNewItem('');
          return false;
        }
      } catch (error) {
        console.error('Error al actualizar registros.', error);
      } finally{
        setAddRequestStatus('idle');
        setOpen2(false);
      }
    }
  }

  return (
    <div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
        <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para poder asignar un responsable.</Typography>
        <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> setOpen(true)}><CircleIcon className={styles.iconButton}/> Asignar responsables</Button>
      </div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor:'#efefef',
            color:'#2d3037',
            borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
          <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open2}
            >
              <CircularProgress color="inherit" />
          </Backdrop>
          <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <PersonIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }} />
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Asignar responsables</Typography>
            <span className={styles.subTitle}>Rellena los siguientes campos para crear asignar al o los responsables.</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={2}>
              <Grid item  xs={12} sm={12}>
                <label className={styles.label}>Problema</label>
                <Autocomplete
                  disablePortal
                  className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color:'#2d3037',
                    },
                    '& .MuiInputLabel-root': {
                      color:'#2d3037',
                    },
                  }}
                  disableClearable
                  id="campamentos-autocomplete"
                  options={options}
                  name="problema"
                  getOptionLabel={(option) => option.value}
                  onChange={(e, value)=> handleChangeOption(e, value)}
                  renderInput={(params) => <TextField {...params} label="Selecciona un problema" />}
               />
              </Grid>
              <Grid item xs={12} md={12}>
                <label className={styles.label}>Correo</label>
                <div className={styles.inventario}>
                  <TextField
                    name='correo'
                    className={styles.textField}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color:'#2d3037',
                      },
                      '& .MuiInputLabel-root': {
                        color:'#2d3037',
                      },
                    }}
                    type="email"
                    value={newItem}
                    onChange={(e) => setNewItem(e.target.value)}
                    fullWidth
                  />
                  <Button 
                    variant='outlined' 
                    className={styles.buttonAddCorreo} 
                    style={{ color:'#FFF' }} 
                    disabled={newItem.length === 0}
                    onClick={handleAddItem}
                    >
                    Añadir correo
                    </Button>
                </div>
            </Grid>
            <Grid item xs={12}>
              {items.map((item, index) => (
                <Chip
                  className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color:'#2d3037',
                    },
                    '& .MuiSvgIcon-root ': {
                      color:'#2d3037',
                    },
                    '& .MuiInputLabel-root': {
                      color:'#2d3037',
                    },
                    '& .MuiChip-label': {
                      color:'#2d3037',
                    },
                  
                  }}
                  key={index}
                  label={item}
                  onDelete={() => handleDeleteItem(index)}
                  style={{ marginRight: '8px', marginBottom: '8px' }}
                />
              ))}
            </Grid>
            
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid style={{ padding:'0 16px' }}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                <Button 
                  onClick={handleSubmit}
                  className={styles.bgColorModal}
                  variant="contained" 
                  disabled={!canSave}>
                  {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Agregar'}
                </Button>
                <Button 
                  onClick={()=>setOpen(false)}
                  className={styles.bgColorOutlined}
                  variant="outlined" 
                  >
                  Cancelar
                </Button>
              </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Create