import React, { useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es'; // Importa la localización de moment en español
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment-timezone'; // Importa moment-timezone
import { useState } from 'react';
import { addDays, parseISO, isValid, format } from 'date-fns';

const calendarStyles = {
  color: 'white',
  backgroundColor: 'var(--border-orange)',
  borderRadius: '0px',
  border: 'none',
};

const messages = {
  today: 'Hoy',
  previous: 'Anterior',
  next: 'Siguiente',
  month: 'Mes',
  week: 'Semana',
  day: 'Día',
  agenda: 'Agenda',
  date: 'Fecha',
  time: 'Hora',
  event: 'Evento',
};

// Configura el localizador de fechas usando moment.js
const localizer = momentLocalizer(moment);

const CalendarioCreate = ({ events, ruts, setSelectedCampamento, franjas, setSelectedGroup, personal, setSelectedRuts, setFormData, setTipo, tareas, setCheckedItems, setActualizar, setSelecItem, setSelectedTareas }) => {
  // Mapea los eventos en un formato compatible con react-big-calendar
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [eventos, setEventos] = useState([])

  const mappedEvents = events.map(evento => {
    // Ajusta la fecha y las horas de inicio y término
    const fechaInicio = parseISO(evento.desde);
    // const fechaTermino = moment.tz(`${evento.hasta}`, localTimeZone).toDate();
    const fechaTermino = addDays(parseISO(evento.hasta), 1);

    // Construye el título del evento
    const title = (() => {
      let habitaciones = 0;
    
      if (evento.grupo?.pabellones) {
        habitaciones = evento.grupo.pabellones.reduce((sum, item) => sum + item.numeroHabitaciones, 0);
      } else if (evento.grupo?.wing) {
        habitaciones = evento.grupo.wing.reduce((sum, item) => sum + item.numeroHabitaciones, 0);
      } else if (evento.grupo?.edificio) {
        habitaciones = evento.grupo.edificio.reduce((sum, item) => sum + item.numeroHabitaciones, 0);
      }
    
      return `${evento.grupo.nombre} : ${habitaciones} habitaciones`;
    })();
    
    return {
      ...evento,
      title,
      start: fechaInicio,
      end: fechaTermino,
    };
    
  });
  
  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    if (isValid(date)) {
      return format(date, 'yyyy-MM-dd');
    } else {
      return dateString;
    }
  };

  const handleEventClick = (event) => {
    if(event){
      console.log('event',event);
      const index = Array.isArray(events) && events.find(e => e.id === event.id);
      console.log('desde, fecha hasta', index.desde , index.hasta);
      console.log('desde, fecha hasta', format(index.desde, 'yyyy-MM-dd') , format(index.hasta, 'yyyy-MM-dd'));

      if (index !== -1) {
        const mapped = {
          ...index,
          desde: formatDate(index.desde),
          hasta: formatDate(index.hasta),
        }
        console.log(mapped)
        const horario = franjas.find(item => mapped.horario === item.id)
        setSelectedCampamento(horario)
        setSelectedGroup(mapped.grupo)
        
        const ruts = personal.filter(item =>{
          const rut = mapped.trabajadoresRut.find(it => it === item.rut)
          return rut
        })
        console.log('ruts-->', ruts);
        setSelectedRuts(ruts);

        setTipo(mapped.tipo)
        const tarea = tareas.filter(item =>{
          const tar = mapped.tareasId.find(it => it === item.id)
          return tar
        })
        setCheckedItems(tarea)
        setSelectedTareas(tarea)
        setFormData(prev => ({...prev, desde: mapped.desde, hasta: mapped.hasta, grupo: mapped.grupo, horario: horario.id, tipo: mapped.tipo, ruts: ruts, trabajadoresRut: ruts, tareasId: mapped.tareasId }))
        // setTramos(mapped)
        setSelecItem(mapped)
        setActualizar(true)
      }

    }
  }

  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        fontSize: '12px', // Tamaño de la fuente deseado
        wordBreak: 'break-word',
      },
    };
  };

  return (
    <div style={{ overflowX: 'auto', width: '100%', height:500 }}>
    <style>{`
      .rbc-header {
        ${calendarStyles} 
      }
      .rbc-btn-group > button {
        ${calendarStyles}
      }
      .rbc-toolbar button {
        color: #373a3c;
        display: inline-block;
        margin: 0;
        text-align: center;
        vertical-align: middle;
        background: none;
        background-image: none;
        border: 1px solid #ccc;
        padding: 0.375rem 1rem;
        border-radius: 4px;
        line-height: normal;
        white-space: nowrap;
        color: white;
      }
        .rbc-today {
          background-color: var(--border-blue);
        }
        .rbc-off-range-bg {
          background-color: var(--textfield-bg);
        }
    `}</style>
      <Calendar
        localizer={localizer}
        events={mappedEvents}
        onSelectEvent={handleEventClick}
        startAccessor="start"
        endAccessor="end"
        views={['month', 'week']}
        defaultView="month"
        messages={messages}
        eventPropGetter={(event, start, end, isSelected) => {
          let newStyle = {
            backgroundColor: "var(--border-orange)",
            color: 'white',
            borderRadius: "0px",
            border: "none",
            fontSize: '12px', // Tamaño de la fuente deseado
            wordBreak: 'break-word',
          };
      
          if (event.isMine) {
            newStyle.backgroundColor = "#174300";
          }
      
          return {
            className: "",
            style: newStyle
          };
        }}
      />
    </div>
  );
};

export default CalendarioCreate;
