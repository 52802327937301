import styles from './components.module.css';
import { Card, Typography, CircularProgress } from '@mui/material';

const ProximaSubida = ({ reservas, status }) => {
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  };

  if (status === 'loading') {
    return <Card className={[styles.card, styles.colorBlack]} style={{ backgroundColor:'var(--inst-card-bg)', padding:'16px', color:'#FFF', margin:'10px 0', border:'1px solid var(--border-orange)', textAlign:'center' }} >
              <Typography variant='p' style={{ display: 'block', fontSize: '14px', fontWeight:'400' }}>
                <CircularProgress color="inherit" size={24} />
              </Typography>
          </Card>
  }

  if (!reservas || reservas.length === 0) {
    return <Card className={[styles.card, styles.colorBlack]} style={{ backgroundColor:'var(--inst-card-bg)', padding:'16px', color:'#FFF', margin:'10px 0', border:'1px solid var(--border-orange)' }} >
              <Typography variant='p' style={{ display: 'block', fontSize: '14px', fontWeight:'400' }}>Sin reservas.</Typography>
          </Card>
  }

  const reserva = reservas[0];

  return (
    <Card className={[styles.card, styles.colorBlack]} style={{ backgroundColor:'var(--inst-card-bg)', padding:'16px', color:'#FFF', margin:'10px 0', border:'none' }} >
      <Typography variant='p' style={{ display: 'block', fontSize: '12px' }}>Próxima subida:</Typography>
      <Typography variant='h7' style={{ marginLeft: '5px', display: 'block' }}><strong>Desde</strong>: {formatDate(reserva.fechaIngreso)}</Typography>
      <Typography variant='h7' style={{ marginLeft: '5px', display: 'block' }}><strong>Hasta</strong>: {formatDate(reserva.fechaSalida)}</Typography>
      <Typography variant='h7' style={{ marginLeft: '5px', display: 'block' }}><strong>Turno</strong>: {reserva.turno.descripcion}</Typography>
      <Typography variant='h7' style={{ marginLeft: '5px', display: 'block' }}><strong>Habitación</strong>: {reserva.habitacion.numero}</Typography>
      <Typography variant='h7' style={{ marginLeft: '5px', display: 'block' }}><strong>Piso</strong>: {reserva.habitacion.piso}</Typography>
      <Typography variant='h7' style={{ marginLeft: '5px', display: 'block' }}>
        <strong>
          {reserva.habitacion?.pabellon ? 'Pabellón' : reserva.habitacion?.wing ? 'Wing' : ''}
        </strong>: {' '}
        {reserva.habitacion?.pabellon?.identificador ?? reserva.habitacion?.wing?.nombre}
      </Typography>
    </Card>
  );
};

export default ProximaSubida;
