import styles from './components.module.css';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Popper, Select, TextField, Typography, Card, Box } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { ingresoBodegaShowColumns } from '../../../lib/columns';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';

const Show = ({ open3, setOpen3, handleClose, seleItem  }) => {
    
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = ingresoBodegaShowColumns();
  useEffect(() => { seleItem && console.log('seleItem:', seleItem) }, [seleItem]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCellValue, setSelectedCellValue] = useState('');
  const [selectedCellName, setSelectedCellName] = useState('');
  
  const handleCellClick = (params) => {
    console.log(params);
    if (params.field === 'opciones') return;
    setSelectedCellValue(params.value);
    setSelectedCellName(params.field);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog
      open={open3}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
         backgroundColor:'#efefef',
         color:'#2d3037',
         borderRadius:'0'
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" >
        {"Resumen solicitud de ingreso"}
      </DialogTitle>
      <DialogContent>
      <Card style={{ borderRadius:'5px', overflowX:'auto', padding:'16px' }}>
        <Grid container={true} spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography align="left">ID:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.id}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Fecha Ingreso:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.fechaIngreso && format(seleItem?.fechaIngreso, 'dd-MM-yyyy') } { seleItem?.fechaIngreso && format(seleItem?.fechaIngreso, 'HH:mm')}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Tipo documento:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.tipoDocumento}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Órden de compra:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.ordenDeCompra}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Monto Total:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>${seleItem?.montoTotal}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Receptor:</Typography>
            </Grid>
            <Grid item xs={12} md={8} style={{ marginBottom:'15px' }}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.receptor}</strong></Typography>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Typography align="left">Observaciones:</Typography>
            </Grid>
            <Grid item xs={12} md={8} style={{ marginBottom:'15px' }}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.observaciones}</strong></Typography>
            </Grid>
            </Grid>
          </Card>
        <Grid container={true} spacing={2} mt={2}>
            {seleItem?.imagen1 && 
            <Grid item xs={12} md={4}>
              <img style={{ height: '100%', width: '100%' }} src={`${process.env.REACT_APP_API_URL}${seleItem?.imagen1}`} alt="img_plano" />
            </Grid>}
            {seleItem?.imagen2 && <Grid item xs={12} md={4}>
              <img style={{ height: '100%', width: '100%' }} src={`${process.env.REACT_APP_API_URL}${seleItem?.imagen2}`} alt="img_plano" />
            </Grid>}
            {seleItem?.imagen3 && <Grid item xs={12} md={4} style={{ marginBottom:'10px' }}>
              <img style={{ height: '100%', width: '100%' }} src={`${process.env.REACT_APP_API_URL}${seleItem?.imagen3}`} alt="img_plano" />
            </Grid>}
        </Grid>

        <Card style={{ borderRadius:'5px', padding:'16px' }}>
          <Grid container={true} mt={2}>
            <Grid item xs={12} md={12}>
              <DataGrid
                getRowId={row=>row.id ? row.id : Math.random() }
                lots={{ toolbar: GridToolbar }}
                rows={seleItem?.ingresoArticulos}
                onCellClick={handleCellClick}
                columns={finalColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 20]}
                localeText={{
                ...esES.components.MuiDataGrid.defaultProps.localeText,
                noRowsLabel: "No hay datos disponibles",
                pagination: {
                    labelRowsPerPage: "Filas por página:",
                }
                }}
                classes={{ menuIcon: styles.menuIcon }}
                style={{ maxHeight:'500px', color:'#2d3037', border:'none' }}
                sx={{ 
                  '& .MuiDataGrid-cell': {
                    color: '#2d3037',
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-columnHeader': {
                    color: '#2d3037',
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-footerContainer': {
                    color: '#2d3037',
                    borderTop: '1px solid #c6c6c6',
                  },
                  '& .MuiTablePagination-root': {
                    color: '#2d3037',
                    borderTop: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-toolbarContainer': {
                    color: '#2d3037',
                  },
                  '& .MuiDataGrid-menu': {
                    color: '#2d3037',
                  },
                  '& .MuiSvgIcon-root':{
                    color: '#9ca91c',
                  },
                  '& .MuiTablePagination-actions': {
                    color: '#2d3037', // Color for pagination actions
                  },
                  '& .MuiTablePagination-select': {
                    color: '#2d3037', // Color for pagination select
                  },
                  '& .MuiTablePagination-selectLabel': {
                    color: '#2d3037', // Color for pagination select label
                  },
                  '& .MuiTablePagination-displayedRows': {
                    color: '#2d3037', // Color for displayed rows text
                  },
                  '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                    color: 'red',
                  },
                  '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                    color: 'red',
                  },
                }}
                slotProps={{
                      toolbar:{
                        sx:{
                          color:'#FFF'
                        }
                      },
                      pagination: {
                        sx: {
                          color: '#f19d38',
                        },
                      },
                      menu: {
                        sx: {
                          color: '#f19d38',
                        },
                      },
                }}
              />
              <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogContent>
                  <Box>
                    {selectedCellValue ?? '-'}
                  </Box>
                </DialogContent>
              </Dialog>
            </Grid>
          </Grid>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className={styles.bgColorOutlined}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Show;
