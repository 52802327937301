import styles from './components.module.css'
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Grid, Typography } from "@mui/material";
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { useState, useEffect } from 'react';
import * as L from "leaflet";  
import InfoIcon from '@mui/icons-material/Info';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DescriptionIcon from '@mui/icons-material/Description';

const defaultPosition = { lat: 0, lng: 0 };

const More = ({openMore, setOpenMore, seleItem, setScroll, scroll}) => {
    const [position, setPosition] = useState(defaultPosition);
    const [mapKey, setMapKey] = useState(0);
    const [isValid, setIsValid] = useState(false);

    const LeafIcon = L.Icon.extend({
        options: {}
    });
    
    const blueIcon = new LeafIcon({
        iconUrl: 'http://leafletjs.com/examples/custom-icons/leaf-green.png',
        shadowUrl: 'http://leafletjs.com/examples/custom-icons/leaf-shadow.png'
      })
    
    const [icon, setIcon] = useState(blueIcon);

    useEffect(() => {
        if(seleItem){
            const regex = /^-?\d+(\.\d+)?,\s-?\d+(\.\d+)?$/;
            const isValid = regex.test(seleItem?.direccion);
            setIsValid(isValid);
        
            if (seleItem?.latitud && seleItem?.longitud) {
                setPosition({
                    lat: parseFloat(seleItem.latitud),
                    lng: parseFloat(seleItem.longitud)
                });
            } else if (!seleItem?.latitud && !seleItem?.longitud && isValid) {
                const [lat, lng] = seleItem.direccion.split(',').map(coord => parseFloat(coord.trim()));
                setPosition({
                    lat: lat,
                    lng: lng
                });
            } else {
                setPosition(defaultPosition);
            }
        }
    }, [seleItem]);
    
    
    

    // Actualizar la key del MapContainer para forzar su actualización y centrado
    useEffect(() => {
        // Incrementar la key en 1 para forzar la actualización del MapContainer
        setMapKey(prevKey => prevKey + 1);
    }, [seleItem]);

    return (
        <div>
            <Dialog
                open={openMore}
                maxWidth="md"
                onBackdropClick={() => {
                    setOpenMore(false);
                }}
                scroll={scroll}
                PaperProps={
                    {
                      sx: {
                        width: { xs: '100%', sm: '75%', md: '50%' },
                        backgroundColor:'#efefef',
                        maxHeight: '80vh',
                        color:'#2d3037',
                        borderRadius:'0',
                        overflowY: 'auto',
                      }
                    }
                }
            >
                <DialogTitle>
                    <span className={styles.label} style={{ display:'flex', alignItems:'center', gap:'5px', fontWeight:'600', fontSize:'18px' }}>
                        <InfoIcon style={{ fontSize:'18px' }}/>
                        Detalles del campamento {seleItem?.nombre}
                    </span>
                </DialogTitle>
                {
                    <DialogContent dividers={scroll === 'paper'}>
                        <Grid container={true} mt={1}>
                            <Grid children sx={12} md={12} style={{ display:'flex', flexDirection:'column' }} mb={1}>
                                <span className={styles.label} style={{ display:'flex', alignItems:'center', gap:'5px' }}>
                                    <FormatColorTextIcon style={{ fontSize:'16px' }}/> <strong>Nombre</strong>: {seleItem?.nombre}
                                </span>
                            </Grid>
                            <Grid children sx={12} md={12} style={{ display:'flex', flexDirection:'column' }} mb={1}>
                                <span className={styles.label} style={{ display:'flex', alignItems:'start', gap:'5px' }}>
                                    <LocationOnIcon style={{ fontSize:'16px' }}/> <strong>Dirección</strong>: {seleItem?.direccion}
                                </span>
                            </Grid>
                            <Grid children sx={12} md={12} style={{ display:'flex', flexDirection:'column' }} mb={1}>
                                {
                                    seleItem?.descripcion ? 
                                    <span className={styles.label} style={{ display:'flex', alignItems:'start', gap:'5px' }}>
                                        <DescriptionIcon style={{ fontSize:'16px' }}/> <strong>Descripción</strong>: {seleItem?.descripcion}
                                    </span> : ' - '
                                }
                            </Grid>
                            {
                                seleItem?.plano && 
                                <Grid item xs={12} sm={12} mb={2} style={{ marginBottom:'5px' }}>
                                    <img style={{ height: '100%', width: '100%' }} src={`${process.env.REACT_APP_API_URL}${seleItem?.plano}`} alt="img_plano" />
                                </Grid>
                            }
                            <Grid children sx={12} md={12} style={{ marginTop:'5px' }}>
                                <MapContainer key={mapKey} center={position} zoom={13} scrollWheelZoom={true} className={styles.map}>
                                    <TileLayer
                                        attribution=''
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker position={position} icon={icon}></Marker>
                                </MapContainer>
                            </Grid>
                        </Grid>
                    </DialogContent>
                }
                <DialogActions>
                    <Grid container={true} style={{ padding:'0 16px', textAlign:'end', display:'flex', justifyContent:'end' }}>
                        <Button 
                            onClick={()=>setOpenMore(false)}
                            className={styles.bgColorOutlined}
                            variant="outlined" 
                        >
                            Cerrar
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default More
