import styles from "./components.module.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Card,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { createAxiosInstance } from "../../../api/axios";
import { addNewIngreso } from "../../../redux/features/bodegas/bodegaSlice";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import {
  fetchTrabajadores,
  getAllTrabajadores,
  getTrabajadoresStatus,
  rechazarSolicitud,
} from "../../../redux/features/reservas/reservaSlice";
import {
  liveSearchByRut,
  liveSearchByRutAndName,
} from "../../../redux/features/users/userSlice";
import CircleIcon from "@mui/icons-material/Circle";

const data = {
  bodegaId: "",
  receptor: "",
  tipoDocumento: "",
  ordenDeCompra: "",
  numeroDocumento: "",
  montoTotal: 0,
  observacion: "",
  fechaIngreso: "",
  imagen1: "",
  imagen2: "",
  imagen3: "",
  ingresoArticulos: [],
};

const Edit = ({
  open,
  setOpen,
  campamentos,
  bodegas,
  seleItem,
  setOpen2,
  open2,
  users,
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [selectedBodega, setSelectedBodega] = useState(null);
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState("");
  const [itemsNew, setItemsNew] = useState([]);
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [filterBodegas, setFilterBodegas] = useState(null);
  const [searching, setSearching] = useState(false);
  const [compraDirecta, setCompraDirecta] = useState(false);
  const [ruts, setRuts] = useState([]);
  const [listItems, setListItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(null);
  const [selectedRut, setSelectedRut] = useState(null);
  const axiosInstance = createAxiosInstance();
  const [imagePreviews, setImagePreviews] = useState({});
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleExpandDescription = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleChangeCampamento = (event, newValue) => {
    console.log(newValue);
    setSelectedCampamento(newValue);
    setSelectedBodega(null);
    setFormData({ ...formData, bodegaId: null });
    const filter = bodegas.filter((bod) => bod.campamento.id == newValue.id);
    setFilterBodegas(filter);
  };
  const onChange = (e) => {
    if (e.target.type === "file") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
      setImagePreviews({
        ...imagePreviews,
        [e.target.name]: URL.createObjectURL(e.target.files[0]),
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const getBodegas = async (bodega) => {
    try {
      const response = await axiosInstance.get(
        "/api/v1/Inventario/productos-bodega/" + bodega
      );
      console.log("bodegas productos: ", response.data);
      // setBodegasItems(response.data);
      setListItems(response.data);
      // return response.data
    } catch (error) {
      console.log("Error al traer los items: ", error);
    }
  };
  const handleChangeBodega = async (event, newValue) => {
    setSelectedBodega(newValue);
    if (newValue != null) {
      try {
        const response = await getBodegas(newValue.id);
        // setListItems(response)
        setFormData({ ...formData, bodegaId: newValue.id });
      } catch (error) {}
    } else {
      setFormData({ ...formData, bodegaId: 0 });
    }
  };
  const onChange2 = (e, index) => {
    const { name, value } = e.target;
    setItemsNew((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index][name] = value;

      setFormData((prev) => ({
        ...prev,
        ingresoArticulos: updatedItems,
      }));

      return updatedItems;
    });
  };
  const getProducts = async () => {
    try {
      const response = await axiosInstance.get("/api/v1/producto");
      console.log(response);
      setListItems(response.data);
    } catch (error) {
      console.log("Error al traer los items: ", error);
    }
  };
  useEffect(() => {
    if (seleItem) {
      console.log("seleItem", seleItem);
      const camp =
        campamentos &&
        campamentos.find((camp) => {
          const bodega = bodegas.find((b) => b.id === seleItem.bodega.id);
          return bodega && bodega.campamento.id === camp.id;
        });

      setFormData({
        tipoDocumento: seleItem.tipoDocumento,
        bodegaId: seleItem.bodega.id,
        numeroDocumento: seleItem.numeroDocumento,
        ordenDeCompra: seleItem.ordenDeCompra,
        montoTotal: seleItem.montoTotal,
        receptor: seleItem.receptor,
        ingresoArticulos: seleItem.ingresoArticulos,
        imagen1: seleItem.imagen1
          ? `${process.env.REACT_APP_API_URL}${seleItem?.imagen1}`
          : "",
        imagen2: seleItem.imagen2
          ? `${process.env.REACT_APP_API_URL}${seleItem?.imagen2}`
          : "",
        imagen3: seleItem.imagen3
          ? `${process.env.REACT_APP_API_URL}${seleItem?.imagen3}`
          : "",
        fechaIngreso: seleItem.fechaIngreso.split("T")[0],
        campamento: camp,
      });

      setImagePreviews({
        imagen1: seleItem?.imagen1
          ? `${process.env.REACT_APP_API_URL}${seleItem.imagen1}`
          : null,
        imagen2: seleItem?.imagen2
          ? `${process.env.REACT_APP_API_URL}${seleItem.imagen2}`
          : null,
        imagen3: seleItem?.imagen3
          ? `${process.env.REACT_APP_API_URL}${seleItem.imagen3}`
          : null,
      });

      setSelectedCampamento(camp);
      const filter = bodegas.filter((bod) => bod.campamento.id == camp.id);
      setFilterBodegas(filter);
      setSelectedBodega(seleItem.bodega);
      getBodegas(seleItem.bodega.id);
      const mappedArticulos = seleItem.ingresoArticulos.map((item) => ({
        codigo: item.producto.codigo,
        nombre: item.producto.nombre,
        descripcion: item.producto.descripcion,
        productoId: item.producto.id,
        cantidadRecibida: item.cantidadRecibida,
        cantidadDelDocumento: item.cantidadDelDocumento,
        precio: item.precio || 0,
      }));
      setItems(mappedArticulos);
      setItemsNew(mappedArticulos);

      setRuts(users);
      const find = users.find((item) => item.rut === seleItem.receptor);
      setSelectedRut(find);
      // const onChangeRut = async (e) => {
      //   try {
      //     setOpen2(true);
      //     setRuts([]);
      //     const resp = await dispatch(liveSearchByRut(e))
      //     console.log('trabajadores', resp);
      //     setRuts(resp.payload.data);
      //     setSelectedRut(resp.payload.data[0])

      //   } catch (error) {
      //     console.log(error)
      //   } finally{
      //     setOpen2(false)
      //   }
      // }
      // onChangeRut(seleItem.receptor)
    }
  }, [seleItem]);
  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);
  const search = async (event, newValue) => {
    try {
      if (newValue) {
        setSearching(true);
        setSelectedItems(newValue);
        console.log(" ca", newValue);
        const filterItem = listItems.find(
          (item) =>
            item.codigo === newValue.codigo ||
            item.nombre.toUpperCase() === newValue.nombre.toUpperCase()
        );
        console.log("FilterItem", filterItem);
        if (filterItem) {
          const alreadyIn = itemsNew.find(
            (item) => item.codigo === filterItem.codigo
          );
          if (alreadyIn) {
            toast.error("El artículo ya está agregado.");
          } else {
            setItems([...items, newValue.codigo]);
            const item = {
              codigo: filterItem.codigo,
              nombre: filterItem.nombre,
              descripcion: filterItem.descripcion,
              productoId: filterItem.id,
              cantidadRecibida: 0,
              cantidadDelDocumento: 0,
              precio: filterItem.precio || 0,
            };

            setItemsNew([...itemsNew, item]);
            setFormData((prev) => ({
              ...prev,
              ingresoArticulos: [...prev.ingresoArticulos, item],
            }));
            // setSelectedItems();
          }
        } else {
          toast.error("No se ha encontrado el artículo.");
        }
      }
      return;
    } catch (error) {
      console.error(error);
    } finally {
      setSearching(false);
    }
  };
  const canSave =
    [
      formData.bodegaId,
      compraDirecta || formData.tipoDocumento,
      compraDirecta || formData.numeroDocumento,
      formData.receptor,
      formData.montoTotal,
      formData.ingresoArticulos.length > 0,
    ].every(Boolean) && addRequestStatus === "idle";
  const handleSubmit = async () => {
    if (canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus("loading");
        let resp = await dispatch(addNewIngreso(formData));
        if (resp.payload.status === "success") {
          setFormData(data);
          setOpen(false);
          setSelectedBodega();
          setSelectedCampamento();
          setItems([]);
          setItemsNew([]);
          // setListItems([])
          setNewItem("");
          setFilterBodegas([]);
          setSelectedItems();
          setRuts([]);
          setCompraDirecta();
          setSearching();
        }
      } catch (error) {
        console.error("Error al registrar el ingreso a bodega", error);
      } finally {
        setOpen2(false);
        setAddRequestStatus("idle");
      }
    }
  };
  const handleRemove = (index) => {
    const newItems = [...itemsNew];
    newItems.splice(index, 1);
    setItemsNew(newItems);

    const newFormData = { ...formData };
    newFormData.ingresoArticulos = newItems;
    setFormData(newFormData);
  };
  const handleCompraDirectaChange = (event) => {
    setCompraDirecta(event.target.checked);
    if (event.target.checked) {
      setFormData({ ...formData, tipoDocumento: "", numeroDocumento: "" });
    }
  };
  const onChangeRut = async (e) => {
    try {
      setOpen2(true);
      setRuts([]);
      const resp = await dispatch(liveSearchByRut(e.target.value));
      console.log("trabajadores", resp);
      // const mappedResp = resp.filter(item => item.)
      setRuts(resp.payload.data);
      // setRuts(mappedResp);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen2(false);
    }
  };
  const onChangeRutSelect = (e, newValue) => {
    if (newValue) {
      setFormData({ ...formData, receptor: newValue.rut });
      setSelectedRut(newValue);
    } else {
      setRuts({});
    }
  };
  const handleInputChange = (event, newInputValue) => {
    if (!newInputValue) {
      setFormData({ ...formData, receptor: "" });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="md"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display: "flex", gap: "5px" }}>
          <AlignHorizontalCenterIcon
            className={styles.iconBgCreate}
            style={{ fontSize: "3rem", marginTop: "0px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="span"
              style={{ fontSize: "24px", lineHeight: "1" }}
            >
              Actualizar registro de ingreso de bodega
            </Typography>
            <span className={styles.subTitle}>
              Rellena los siguientes campos para actualizar el registro.
            </span>
          </div>
        </DialogTitle>

        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item xs={12} sm={6}>
              <label className={styles.label}>Campamento</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-clearIndicator": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    color: "#2d3037",
                  },
                }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                options={campamentos || []}
                name="bodega"
                value={selectedCampamento}
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value) => handleChangeCampamento(e, value)}
                renderInput={(params) => (
                  <TextField {...params} label="Selecciona un campamento" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className={styles.label}>Bodega</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-clearIndicator": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    color: "#2d3037",
                  },
                }}
                disablePortal
                disableClearable
                disabled={!selectedCampamento}
                id="campamentos-autocomplete"
                options={filterBodegas}
                name="bodega"
                value={selectedBodega}
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value) => handleChangeBodega(e, value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      selectedCampamento ? (
                        "Selecciona una bodega"
                      ) : (
                        <span>Por favor, seleccione un campamento</span>
                      )
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Receptor</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-clearIndicator": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    color: "#2d3037",
                  },
                }}
                name="receptor"
                disableClearable
                value={selectedRut}
                options={users ? users : []}
                getOptionLabel={(option) => {
                  if (option) {
                    const rut = option.rut ?? "";
                    const nombre = option.nombre ?? "";
                    const apellidos =
                      option.apellido ??
                      option.apellidos ??
                      (option.apellidoPaterno ?? "") +
                        " " +
                        (option.apellidoMaterno ?? "");
                    return `${rut} ${nombre} ${apellidos}`;
                  }
                  return "";
                }}
                onChange={onChangeRutSelect}
                onInputChange={handleInputChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecciona un rut"
                    // onChange={onChangeRut}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label className={styles.label}>Fecha de ingreso</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="fechaIngreso"
                type="date"
                value={formData.fechaIngreso}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Checkbox
                checked={compraDirecta}
                onChange={handleCompraDirectaChange}
                name="compraDirecta"
                color="primary"
              />
              <label>Compra directa</label>
            </Grid>
            <Grid item xs={12} sm={3}>
              <label className={styles.label}>Tipo de documento</label>
              <Select
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-clearIndicator": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    color: "#2d3037",
                  },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData?.tipoDocumento}
                name="tipoDocumento"
                onChange={(e) => onChange(e)}
                style={{ width: "100%" }}
                disabled={compraDirecta}
              >
                <MenuItem value="Factura">Factura</MenuItem>
                <MenuItem value="Boleta">Boleta</MenuItem>
                <MenuItem value="Guía de despacho">Guía de despacho</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={3}>
              <label className={styles.label}>N° de documento</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                value={formData?.numeroDocumento}
                name="numeroDocumento"
                type="number"
                onChange={(e) => onChange(e)}
                disabled={compraDirecta}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <label className={styles.label}>Ord.de compra (opcional)</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                value={formData?.ordenDeCompra}
                name="ordenDeCompra"
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <label className={styles.label}>Monto total (CLP)</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="montoTotal"
                value={formData?.montoTotal}
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Observaciones (Opcional)</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                value={formData.observacion}
                name="observacion"
                type="text"
                multiline
                rows={4}
                onChange={(e) => onChange(e)}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <label className={styles.label}>Imagen (Opcional)</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="imagen1"
                type="file"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <label className={styles.label}>Imagen (Opcional)</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="imagen2"
                type="file"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <label className={styles.label}>Imagen (Opcional)</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="imagen3"
                type="file"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <img
                style={{ height: "100%", width: "100%" }}
                src={imagePreviews.imagen1}
                alt=""
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <img
                style={{ height: "100%", width: "100%" }}
                src={imagePreviews.imagen2}
                alt=""
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <img
                style={{ height: "100%", width: "100%" }}
                src={imagePreviews.imagen3}
                alt=""
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <label className={styles.label}>
                {selectedBodega
                  ? "Lista de artículos (Busca artículos por nombre o por código)"
                  : "Primero selecciona una bodega para poder buscar artículos."}
              </label>
              <div className={styles.inventario}>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      color: "#2d3037",
                    },
                  }}
                  disablePortal
                  disableClearable
                  id="campamentos-autocomplete"
                  options={listItems}
                  name="bodega"
                  disabled={!selectedBodega}
                  value={selectedItems}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(event, value) => search(event, value)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            container={true}
            spacing={2}
            style={{
              maxHeight: "500px",
              overflowY: "auto",
              display: itemsNew.length === 0 ? "none" : "",
              borderRadius: "5px",
              padding: "5px",
              marginTop: "10px",
            }}
          >
            {itemsNew.length !== 0 ? (
              itemsNew.map((item, index) => (
                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  key={index}
                  style={{
                    position: "relative",
                    padding: "10px",
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  <Card style={{ padding: "10px" }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            <strong>Código {index + 1}:</strong> {item.codigo}
                          </span>
                          <Button
                            onClick={() => handleRemove(index)}
                            className={styles.bgColorOutlinedButton}
                            style={{ padding: "0" }}
                          >
                            <DeleteIcon />
                          </Button>
                        </div>
                        <span>
                          <strong>Nombre:</strong> {item.nombre}
                        </span>
                        <br />
                        <span
                          onClick={() => handleExpandDescription(index)}
                          style={{ cursor: "pointer" }}
                        >
                          <strong>Descripción:</strong>{" "}
                          {expandedIndex === index ? (
                            item.descripcion
                          ) : (
                            <>
                              {item.descripcion.slice(0, 20)}
                              {item.descripcion.length > 20 && (
                                <>
                                  ...
                                  <span style={{ color: "orange" }}>
                                    {" "}
                                    leer más
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </span>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={4}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          flexDirection: "column",
                        }}
                      >
                        <label className={styles.label}>Precio (CLP)</label>
                        <TextField
                          className={styles.textField}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": { border: "none" },
                              "&:hover fieldset": { border: "none" },
                              "&.Mui-focused fieldset": { border: "none" },
                            },
                            "& .MuiInputBase-input": { color: "#2d3037" },
                            "& .MuiInputLabel-root": { color: "#2d3037" },
                          }}
                          variant="outlined"
                          fullWidth
                          name="precio"
                          value={item.precio}
                          type="number"
                          onChange={(e) => onChange2(e, index)}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <label className={styles.label}>Cant. documento</label>
                        <TextField
                          className={styles.textField}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": { border: "none" },
                              "&:hover fieldset": { border: "none" },
                              "&.Mui-focused fieldset": { border: "none" },
                            },
                            "& .MuiInputBase-input": { color: "#2d3037" },
                            "& .MuiInputLabel-root": { color: "#2d3037" },
                          }}
                          variant="outlined"
                          fullWidth
                          name="cantidadDelDocumento"
                          value={item.cantidadDelDocumento}
                          type="number"
                          onChange={(e) => onChange2(e, index)}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <label className={styles.label}>
                          Cantidad recibida
                        </label>
                        <TextField
                          className={styles.textField}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": { border: "none" },
                              "&:hover fieldset": { border: "none" },
                              "&.Mui-focused fieldset": { border: "none" },
                            },
                            "& .MuiInputBase-input": { color: "#2d3037" },
                            "& .MuiInputLabel-root": { color: "#2d3037" },
                          }}
                          variant="outlined"
                          fullWidth
                          name="cantidadRecibida"
                          value={item.cantidadRecibida}
                          type="number"
                          onChange={(e) => onChange2(e, index)}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))
            ) : (
              <span>Buscar artículos para poder ver su información.</span>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={12} className={styles.buttonContainer}>
            <Button
              onClick={handleSubmit}
              className={styles.bgColorModal}
              variant="contained"
              disabled={!canSave}
            >
              {addRequestStatus == "loading" ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "Actualizar"
              )}
            </Button>
            <Button
              onClick={() => setOpen(false)}
              className={styles.bgColorOutlined}
              variant="outlined"
            >
              Cancelar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Edit;
