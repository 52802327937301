import { Backdrop, Box, Container } from "@mui/material"
import Header from "../../../components/header/Header"
import MapIcon from '@mui/icons-material/Map';
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchSectores, getAllSectores, getSectoresStatus } from "../../../redux/features/sectores/sectorSlice";
import SkeletonTable from "../../../components/Skeleton";
import { fetchCampamento, fetchCampamentos, getAllCampamentos, getCampamentosStatus } from "../../../redux/features/campamentos/campamentoSlice";
import { useState } from "react";
import { toast } from "react-toastify";
import { CircularProgress } from '@mui/material';
import { fetchWings, getAllWings } from "../../../redux/features/wings/wingSlice";
import { fetchEdificios, getAllEdificios } from "../../../redux/features/edificios/edificioSlice";

import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import Calendario from "./components/Calendario";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const Listado = () => {

  const dispatch = useDispatch();
  const campamentos = useSelector(getAllCampamentos);
  const wings = useSelector(getAllWings);
  const pabellones = useSelector(getAllEdificios);
  // const status = useSelector(getSectoresStatus);
  const status = 'idle';
  const [count, setCount] = useState(0);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    dispatch(fetchCampamentos());
    dispatch(fetchWings());
    dispatch(fetchEdificios());
  }, []);


  const registros = [
    {
      id:1,
      nombre: 'Limpieza de vidrios',
      fecha: '2024-05-02',
      hora:'16:00',
      campamento: {
        id:1,
        nombre: 'Coposa',
        pabellon: {
          id:1,
          identificador: '1 NORTE'
        }
      },
      piso: 2,
      descripcion: 'descripción falsa',
      asignados: ["1232133-3", "1232321-2"]
    },
    {
      id:2,
      nombre: 'Encerado de pasillo',
      fecha: '2024-05-06',
      hora:'09:30',
      campamento: {
        id:1,
        nombre: 'Pioneros',
        wing: {
          id:1,
          nombre: 'wing 1'
        }
      },
      piso: 3,
      descripcion: 'descripción falsa2'
    },
  ];

  const events= [

  ]


  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open3}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Listado de tareas especificas"
          subtitle="En este módulo, podrás visualizar un listado actual de las tareas especificas registradas en la plataforma.">
          {<CalendarMonthIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>
        <TableCard>
          <Calendario events={registros}/>
        {/*
          <Create setOpen2={setOpen2} open2={open2} campamentos={campamentos} wings={wings} pabellones={pabellones}/>
          { status == 'loading' ? <SkeletonTable/> : <Table setOpen2={setOpen2} open2={open2} registros={registros} setOpen3={setOpen3} /> }
        */}
        </TableCard> 
      </Container>
    </Box>
  )
}

export default Listado