import styles from "./components.module.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { deleteSector } from "../../../redux/features/sectores/sectorSlice";
import { deleteCategoria } from "../../../redux/features/inventario/inventarioSlice";
import { format } from "date-fns";
import {
  confirmarRecepcionCambiar,
  confirmarSalidaAprobar,
} from "../../../redux/features/bodegas/bodegaSlice";

export default function CambiarEstado({ open, setOpen, seleItem, setOpen3 }) {
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [estado, setEstado] = useState("");
  const [razon, setRazon] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(seleItem);
    if (seleItem) {
      setEstado(seleItem?.estado);
    }
  }, [seleItem]);

  const handleDelete = async () => {
    try {
      setOpen3(true);
      setAddRequestStatus("loading");

      if (seleItem?.tipo === "traspaso") {
        const resp = await dispatch(confirmarRecepcionCambiar(seleItem.id));
      } else if (seleItem?.tipo === "salida") {
        const resp = await dispatch(
          confirmarSalidaAprobar({
            id: seleItem?.numeroDocumento,
            estado: estado,
            razon: razon,
          })
        );
      }
    } catch (error) {
      console.error(
        "Error al actualizar el estado del registro: ",
        error.message
      );
    } finally {
      setAddRequestStatus("idle");
      setOpen(false);
      setOpen3(false);
    }
  };

  return (
    <div>
      <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
        {seleItem && (
          <Box
            width={1}
            mt={0}
            style={{
              backgroundColor: "#FFF",
              color: "var(--inst-card-bg)",
              padding: "16px",
            }}
          >
            <Container className={styles.containerDelete}>
              <div className={styles.textContainer}>
                <Typography variant="h6" className={styles.textIconDelete}>
                  <ErrorIcon />
                  Estás a punto de actualizar el estado{" "}
                  {seleItem?.tipo === "traspaso"
                    ? "del traspaso de bodega"
                    : "de la salida de artículos"}{" "}
                  a{" "}
                  <strong>
                    {seleItem?.tipo === "traspaso" ? "Recibido" : ""}
                  </strong>
                </Typography>
                <Typography variant="span" className={styles.paragrap}>
                  ¿Estas seguro de proceder?
                </Typography>
              </div>

              <div
                className={styles.textContainer}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Typography
                  variant="span"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    width: "100%",
                    fontSize: "16px",
                  }}
                  className={styles.textIcon}
                >
                  Desde:{" "}
                  {seleItem?.bodegaOrigen
                    ? seleItem?.bodegaOrigen?.nombre
                    : seleItem?.bodega
                    ? seleItem?.bodega?.nombre
                    : ""}
                </Typography>
                {seleItem?.tipo === "traspaso" && (
                  <Typography
                    variant="span"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      width: "100%",
                      fontSize: "16px",
                    }}
                    className={styles.textIcon}
                  >
                    Hacia: {seleItem?.bodegaDestino?.nombre}
                  </Typography>
                )}
                <Typography
                  variant="span"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    width: "100%",
                    fontSize: "16px",
                  }}
                  className={styles.textIcon}
                >
                  {seleItem?.fecha ? format(seleItem?.fecha, "dd-MM-yyyy") : ""}
                </Typography>
              </div>

              {seleItem?.tipo === "salida" && (
                <Grid
                  container={true}
                  spacing={1}
                  mt={1}
                  style={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Grid item md={6} xs={12}>
                    <Select
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={estado}
                      name="estado"
                      onChange={(e) => {
                        if (e.target.value != "Rechazada") {
                          setRazon(null);
                        }
                        setEstado(e.target.value);
                      }}
                      style={{ width: "100%" }}
                    >
                      <MenuItem value="Pendiente">Pendiente</MenuItem>
                      <MenuItem value="Aprobada">Aprobada</MenuItem>
                      <MenuItem value="Rechazada">Rechazada</MenuItem>
                    </Select>
                  </Grid>
                  {estado === "Rechazada" && (
                    <Grid item md={6} xs={12}>
                      <label className={styles.label}>Razón rechazo</label>
                      <TextField
                        className={styles.textField}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "1px solid black",
                            },
                            "&:hover fieldset": {
                              border: "1px solid black",
                            },
                            "&.Mui-focused fieldset": {
                              border: "1px solid black",
                            },
                          },
                          "& .MuiInputBase-input": {
                            color: "#2d3037",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#2d3037",
                          },
                        }}
                        variant="outlined"
                        fullWidth
                        name="codigo"
                        value={razon ?? ""}
                        type="text"
                        onChange={(e) => setRazon(e.target.value)}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
              <Grid container={true} spacing={1} mt={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={styles.buttonContainerDelete}
                >
                  <Button
                    onClick={handleDelete}
                    className={styles.bgColorOrange}
                    variant="contained"
                    disabled={
                      estado === "Rechazada" && (!razon || razon.trim() === "")
                    }
                  >
                    {addRequestStatus == "loading" ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      "Actualizar"
                    )}
                  </Button>
                  <Button
                    onClick={() => setOpen(false)}
                    className={styles.bgColorOutlined}
                    variant="outlined"
                    style={{ border: "none" }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Box>
        )}
      </Drawer>
    </div>
  );
}
