import styles from './components.module.css'
import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import { useState, useTransition } from "react";
import { useForm } from 'react-hook-form';
import { validateLoginEmail } from "../../../utils/validate";
import LoginIcon from '@mui/icons-material/Login';
import { useAuth } from "../../../context/AppContext";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../../redux/features/login/loginSlice";
import PinIcon from '@mui/icons-material/Pin';
import { getPass } from '../../../redux/features/users/userSlice';
import { toast } from 'react-toastify';

const ResetForm = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ email: "" });
    const updateData = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })
    const [errors, setErrors] = useState({ email: "" });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const onSubmit = async () => {
        setError('');
        setLoading(true);
        try {
            errors.email = '';
            const validate = validateLoginEmail(formData);
            if (validate.error) {
                setErrors({ ...errors, [validate.error.type]: validate.error.message });
                console.log(errors);
                return;
            }
            const resp = await dispatch(getPass(formData.email));
            console.log('resp', resp);
            
            if(resp.payload){
                toast.success('Ingreso exitoso, revisa tu correo.');
                navigate('/');
                setFormData({email: ""});
              } else {
                setError(resp.message);
            }

            return;
        } catch (error) {
            console.log('error', error);
        } finally{
            setLoading(false);
        }
    }

    return (
        <Grid container={true} spacing={2} style={{ padding:'5px' }}>
            <Grid item={true} xs={12}>
                <h3 className="font-400" style={{ display:'flex', alignItems:'center', gap:'5px', fontWeight:'500' }}>Ingreso tu correo <PinIcon/></h3>
            </Grid>
            <Grid item={true} xs={12}>
                <span>Correo</span>
                <TextField
                    variant="outlined"
                    type="email"
                    name="email"
                    value={formData.email}
                    className={styles.bgInput}
                    placeholder="correo@psinet.cl"
                    error={errors.email.length > 0 ? true : false}
                    helperText={errors.email.length > 0 && <span style={{ fontFamily: 'Poppins' }}>{errors.email}</span>}
                    fullWidth={true}
                    onChange={(e) => updateData(e)}
                />
            </Grid>
            
            <Grid item={true} xs={12} mb={2} className="text-start">
                <span>Se enviará un enlace al correo ingresado para que puedas recuperar tu contraseña.</span>
            </Grid>
            <Grid item={true} xs={12} mb={2} >
                <Button
                    style={{ background: !loading ? '#0086ae' : '#004a91', fontFamily: 'Poppins', marginBottom: error ? '10px' : '0'}}
                    variant="contained"
                    size="large"
                    disabled={loading}
                    fullWidth={true}
                    onClick={onSubmit}
                >
                    {!loading && 
                        <span style={{ display:'flex', alignItems:'center', gap:'5px' }}>
                            recuperar contraseña
                            <LoginIcon style={{ fontSize:'16px' }}/>
                        </span>}
                    {loading && <CircularProgress size={24} style={{ color:'white' }}/>}
                </Button>
                {error && <span className="error-toast">{error}</span>}
            </Grid>
            <Grid item={true} xs={12} mb={2} className="text-start">
                <Link to='/' style={{ textDecoration:'none', color:'#f19d38' }}>
                    <span>Volver al inicio de sesión</span>
                </Link>
            </Grid>
        </Grid>
    )
}


export default ResetForm