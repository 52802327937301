import styles from "./components.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addNewMotivo,
  fetchMotivos,
} from "../../../../redux/features/motivos/motivoSlice";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
const data = {
  tipo_requerimiento: "",
  prioridad: "",
  descripcion: "",
};
const tipos = [
  { label: "Obras civiles", value: "Obras civiles" },
  { label: "Eléctricos", value: "Eléctricos" },
  { label: "Sanitarios", value: "Sanitarios" },
  { label: "Climatización", value: "Climatización" },
  { label: "Agua caliente", value: "Agua caliente" },
  { label: "Cerrajería", value: "Cerrajería" },
  { label: "Luces de emergencia", value: "Luces de emergencia" },
  { label: "TV", value: "TV" },
  { label: "WIFI", value: "WIFI" },
];
const options = [
  { label: "Urgente", value: "Urgente" },
  { label: "Dentro del día", value: "Dentro del día" },
  { label: "Baja", value: "Baja" },
];
const Create = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); //variable para abrir y cerrar modal
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  // const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });
  const onChange = (e) => {
    const value = e.target.value;
    const invalidCharacters = /[<>\/]/;

    if (!invalidCharacters.test(value)) {
      setFormData({ ...formData, [e.target.name]: value });
    } else {
      toast.error("Carácter no válido");
    }
  };

  const canSave =
    [
      formData.tipo_requerimiento,
      formData.descripcion,
      formData.prioridad,
    ].every(Boolean) && addRequestStatus === "idle";

  const handleChangeTipo = (event, newValue) => {
    if (newValue != null) {
      setFormData({ ...formData, prioridad: newValue.value });
    } else {
      setFormData({ ...formData, prioridad: 0 });
    }
  };
  const handleChangeTipoRequerimiento = (event, newValue) => {
    if (newValue != null) {
      setFormData({ ...formData, tipo_requerimiento: newValue.value });
    } else {
      setFormData({ ...formData, tipo_requerimiento: 0 });
    }
  };

  const handleSubmit = async () => {
    if (
      !formData.tipo_requerimiento ||
      !formData.descripcion ||
      !formData.prioridad
    ) {
      toast.error("Por favor ingrese los datos.");
      return;
    }

    if (canSave) {
      try {
        setAddRequestStatus("loading");
        const resp = await dispatch(addNewMotivo(formData));
        if (resp.payload.status == "success") {
          setOpen(false);
          dispatch(fetchMotivos());
          return;
        }
      } catch (error) {
        toast.error("Error al crear el motivo de solicitud.");
        console.error("Error al crear el motivo", error);
      } finally {
        setAddRequestStatus("idle");
      }
    }
  };

  return (
    <div>
      <Button
        variant="contanied"
        size="large"
        className={styles.bgColor}
        onClick={() => setOpen(true)}
      >
        Crear motivo
      </Button>
      <Dialog
        open={open}
        maxwidth="75%"
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>
          <Typography variant="p">Crear motivo de solicitud</Typography>
          <span className={styles.subTitle}>
            Rellena los siguientes campos para crear el motivo.
          </span>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Tipo de requerimiento</label>
              <Autocomplete
                disablePortal
                id="campamentos-autocomplete"
                name="tipo_requerimiento"
                options={tipos}
                getOptionLabel={(option) => option.label}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    sx={{
                      height: "200px",
                      overflowY: "auto",
                    }}
                  />
                )}
                onChange={(e, value) => handleChangeTipoRequerimiento(e, value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecciona un tipo de requerimiento"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Descripción</label>
              <TextField
                variant="outlined"
                fullWidth
                name="descripcion"
                type="text"
                multiline
                rows={2}
                maxRows={4}
                value={formData.descripcion}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Prioridad</label>
              <Autocomplete
                disablePortal
                id="campamentos-autocomplete"
                options={options}
                name="prioridad"
                getOptionLabel={(option) => option.label}
                onChange={(e, value) => handleChangeTipo(e, value)}
                renderInput={(params) => (
                  <TextField {...params} label="Selecciona una prioridad" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button
                onClick={handleSubmit}
                className={styles.bgColor}
                variant="contained"
                disabled={!canSave}
              >
                {addRequestStatus == "loading" ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Crear"
                )}
              </Button>
              <Button
                onClick={() => setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Create;
