import styles from "./components/components.module.css";
import { Box, Container } from "@mui/material";
import Header from "../../../components/header/Header";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import TableCard from "../../../components/tableCard/TableCard";
// import Create from "./components/Create";
import Table from "./components/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchCampamentos,
  getAllCampamentos,
  getCampamentosStatus,
} from "../../../redux/features/campamentos/campamentoSlice";
import SkeletonTable from "../../../components/Skeleton";
import { Backdrop, CircularProgress } from "@mui/material";
import {
  fetchMonitoreo,
  getgetMonitoreoStatus,
  getMonitoreo,
} from "../../../redux/features/reservas/reservaSlice";

const Monitoreo = () => {
  const dispatch = useDispatch();
  const monitoreos = useSelector(getMonitoreo);
  const status = useSelector(getgetMonitoreoStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const usuarios = [
    {
      id: 3166,
      trabajador: {
        rut: "10005635-6",
        nombre: "LUIS ISAAC",
        apellidoPaterno: "ARRIAGADA",
        apellidoMaterno: "LARRE",
        fechaDeNacimiento: "1968-02-01",
        email: "LIARRIAG@PSINET.CL",
        telefono: "998423278",
        cargo: null,
        cargoId: "10000302",
        genero: "Masculino",
        acreditado: true,
        habitacionPermanente: null,
        enFaena: false,
        turno: "7X7 Grupo Diurno  21",
        ultimoConsumo: null,
      },
      trabajadorRut: "10005635-6",
      fechaCambio: "2024-10-28T17:28:17.9061617",
      habitacion: null,
      status: 3,
      nuevoCargo: "ELECTRICO INSTRUMENTISTA B",
      nuevoTurno: "7X7 Grupo Diurno  21",
      turno: "Sin Turno",
    },
    {
      id: 2805,
      trabajador: {
        rut: "10011768-1",
        nombre: "DANIEL IVAN",
        apellidoPaterno: "CASTRO",
        apellidoMaterno: "CHALLAPA",
        fechaDeNacimiento: "1964-11-08",
        email: "DICASTRO@PSINET.CL",
        telefono: " ",
        cargo: null,
        cargoId: "10000191",
        genero: "Masculino",
        acreditado: true,
        habitacionPermanente: null,
        enFaena: false,
        turno: "7X7  Grupo Diurno 22",
        ultimoConsumo: null,
      },
      trabajadorRut: "10011768-1",
      fechaCambio: "2024-10-28T17:28:17.2211454",
      habitacion: null,
      status: 3,
      nuevoCargo: "OPERADOR BODEGA",
      nuevoTurno: "7X7  Grupo Diurno 22",
      turno: "Sin Turno",
    },
  ];

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchMonitoreo());
    }
    console.log("monitoreos->", monitoreos);
    // const monitoreoConHabitacion = monitoreos.filter(
    //   (item) => item.status == 1
    // );
    // console.log("monitoreosConHabitacion->", monitoreoConHabitacion);
  }, [monitoreos]);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Monitoreo de trabajadores"
          subtitle="A continuación verás un listado de trabajadores nuevos, sin acreditación o que cambiarón de cargo."
        >
          {
            <HolidayVillageIcon
              style={{ fontSize: "1.5rem" }}
              className={styles.iconBgDelete}
            />
          }
        </Header>

        <TableCard>
          {/* <Create setOpen2={setOpen2} open2={open2} /> */}
          {status == "loading" ? (
            <SkeletonTable />
          ) : (
            <Table
              rows={monitoreos || []}
              setOpen3={setOpen3}
              scroll={scroll}
              setScroll={setScroll}
            />
          )}
        </TableCard>
      </Container>
    </Box>
  );
};
export default Monitoreo;
