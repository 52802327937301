import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Autocomplete, Button, Chip, CircularProgress, Container, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateCampamento } from '../../../../redux/features/campamentos/campamentoSlice';
import { useEffect } from 'react';
import { MuiFileInput } from 'mui-file-input';
import { updateResponsable } from '../../../../redux/features/responsables/responsableSlice';

const options = [
    { label: "Modificacion Reserva", value: "Modificacion Reserva" },
    { label: "Problema Tecnico", value: "Problema Tecnico" },
    { label: "Creacion Usuario", value: "Creacion Usuario" },
    { label: "Registro de Reclamo", value: "Registro de Reclamo" },
    { label: "Forecast", value: "Forecast" },
  ];

  const data = {
    correo: [],
  }

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3 }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState(data);
    const [problema, setProblema] = useState(null)
    const dispatch = useDispatch();
    
    const [items, setItems] = useState([]);
    const [newItem, setNewItem] = useState('');
    
    const patron = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleAddItem = () => {
      if (newItem.trim() !== '') {
        if(patron.test(newItem)){
          setItems([...items, newItem]);
          setNewItem('');
        } else{
          toast.error('El correo ingresado no es valido.')
        }
      }
    }; 

    const handleDeleteItem = (index) => {
      setItems(items.filter((_, i) => i !== index));
    };
    useEffect(()=>{
      setFormData({...formData, correo: items})
    },[items])
    
    useEffect(() => {
            setFormData(seleItem)
            setItems(seleItem?.correo);
    }, [seleItem])

    const handleChangeOption = (event, newValue) => {
        setProblema(newValue);
        if(newValue != null){
          setFormData({...formData, problema: newValue.value});
        } else{
          setFormData({...formData, problema: 0})
        }
        
      };
        
    const canSave = [formData?.problema, formData?.correo].every(Boolean) && addRequestStatus === 'idle';

    const handleEdit = async () => {
        try {
            setOpen3(true);
            setAddRequestStatus('loading');
            const resp = dispatch(updateResponsable(formData))
            if(resp.payload.status === 'success'){
              setOpenEdit(false);
            }
        } catch (error) {
            console.error('Error al actualizar el registro', error.message);
        } finally{
            setAddRequestStatus('idle');
            setOpen3(false);
        }
    }

  return (
    <div>
      <Drawer
        anchor='right'
        PaperProps={
          {
            sx: {
              width: { xs: '100%', sm: '75%', md: '50%' },
              backgroundColor:'#efefef',
              color:'#2d3037',
              borderRadius:'0'
            }
          }
      }
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        {seleItem && (
            <>
              <DialogTitle>
                <Grid container={true} spacing={1} px={2}>
                    <Grid item  xs={12} sm={12} style={{ display:'flex', gap:'5px'}}>
                        <ErrorIcon style={{ fontSize:'3rem' }} className={styles.iconBg}/>
                        <div style={{ display:'flex', flexDirection:'column' }}>
                            <Typography variant="h6" className={styles.textIconEdit}>Actualizar registro {seleItem?.nombre}</Typography>
                            <Typography variant="span" className={styles.textIconSpan}>Modifica los campos para actualizar el registro.</Typography>
                        </div>
                    </Grid>
                </Grid>
            </DialogTitle> 
                <DialogContent>
                    <Grid container={true} spacing={1} p={2}>
                        <Grid item  xs={12} sm={12}>
                            <label className={styles.label}>Problema</label>
                            <Autocomplete
                            className={styles.textField}
                                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color:'#2d3037',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color:'#2d3037',
                                    },
                                  }}
                                disablePortal
                                id="campamentos-autocomplete"
                                options={options}
                                name="problema"
                                getOptionLabel={(option) => option.value}
                                value={options.find(option => option.value === formData?.problema) || null}
                                onChange={(e, value)=> handleChangeOption(e, value)}
                                renderInput={(params) => <TextField {...params} label="Selecciona un problema" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <label className={styles.label}>Correo</label>
                            <div className={styles.inventario}>
                              <TextField
                              className={styles.textField}
                                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color:'#2d3037',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color:'#2d3037',
                                    },
                                  }}
                                name='correo'
                                type="email"
                                value={formData?.correo}
                                onChange={(e) => setFormData({...formData, correo: e.target.value})}
                                fullWidth
                              />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                  <Grid container={true} style={{ padding:'0 16px' }}>
                    <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                      <Button 
                        onClick={handleEdit}
                        className={styles.bgColorEdit}
                        variant="contained" 
                        disabled={!canSave}
                      >
                      {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                      </Button>
                      <Button 
                        onClick={()=>setOpenEdit(false)}
                        className={styles.bgColorOutlined}
                        variant="outlined" 
                      >
                      Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
            </>
        )}
      </Drawer>
    </div>
  );
}