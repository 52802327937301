import styles from './components.module.css';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { historialSolicitudesColumns } from "../../../../lib/columns";
import { useEffect, useState } from "react";
import SkeletonTable from '../../../../components/Skeleton';

export const Reservas = ({ solicitudes, seleItem, setSeleItem, setOpenDetalle, setValue, statusReservas }) => {

    const [open, setOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const finalColumns = historialSolicitudesColumns(setOpen, setSeleItem, setOpenDetalle, setValue);

//     const solicitudesAprobadas = solicitudes && Array.isArray(solicitudes)
//   ? solicitudes.filter(item => item.estado === 'Aprobada')
//   : [];

    console.log('solicitudes', solicitudes)

    return (
            statusReservas == 'loading' ? <SkeletonTable/> :<DataGrid
                getRowId={row=>row.id ? row.id : Math.random() }
                lots={{ toolbar: GridToolbar }}
                // rows={solicitudesAprobadas}
                rows={solicitudes}
                columns={finalColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 20]}
                localeText={{
                ...esES.components.MuiDataGrid.defaultProps.localeText,
                noRowsLabel: "No hay datos disponibles",
                pagination: {
                    labelRowsPerPage: "Filas por página:",
                }
                }}
                classes={{ menuIcon: styles.menuIcon }}
                style={{ maxHeight:'500px' }}    
            />
    )
}
