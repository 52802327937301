import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  createAxiosFormDataInstance,
  createAxiosInstance,
} from "../../../api/axios";
import { format, parse } from "date-fns";

const ASEOS_HORARIOS_URL = "/api/v1/Aseo/horario-aseo";
const DELETE_HORARIO_ASEO_URL = "/api/v1/Aseo/horario-aseo/";
const UPDATE_HORARIO_ASEO_URL = "/api/v1/Aseo/horario-aseo/";
const ADD_HORARIO_ASEO_URL = "/api/v1/Aseo/horario-aseo";

const ASEOS_GRUPOS_URL = "/api/v1/Aseo/grupo-habitacion";
const DELETE_GRUPO_ASEO_URL = "/api/v1/Aseo/grupo-habitacion/";
const UPDATE_GRUPO_ASEO_URL = "/api/v1/Aseo/grupo-habitacion/";
const ADD_GRUPO_ASEO_URL = "/api/v1/Aseo/grupo-habitacion";

const ASEOS_PLANIFICACION_URL = "/api/v1/Aseo/planificacion-diaria";
const DELETE_PLANIFICACION_ASEO_URL = "/api/v1/Aseo/planificacion-diaria/";
const UPDATE_PLANIFICACION_ASEO_URL = "/api/v1/Aseo/planificacion-diaria/";
const ADD_PLANIFICACION_ASEO_URL = "/api/v1/Aseo/planificacion-diaria";

const ASEOS_PLANIFICACION_ES_URL = "/api/v1/Aseo/planificacion-especifica";
const DELETE_PLANIFICACION_ES_ASEO_URL =
  "/api/v1/Aseo/planificacion-especifica/";
const UPDATE_PLANIFICACION_ES_ASEO_URL =
  "/api/v1/Aseo/planificacion-especifica/";
const ADD_PLANIFICACION_ES_ASEO_URL = "/api/v1/Aseo/planificacion-especifica";

const PERSONAL_ASEO_URL = "/api/v1/Register/registro-personal-aseo";
const UPDATE_PERSONAL_ASEO_URL = "/api/v1/Register/SuspendUser/";
const ADD_PERSONAL_ASEO_URL = "/api/v1/Register/registro-personal-aseo";

const TAREAS_ASEO_URL = "/api/v1/Aseo/tarea";
const DELETE_TAREAS_ASEO_URL = "/api/v1/Aseo/tarea/";
const UPDATE_TAREAS_ASEO_URL = "/api/v1/Aseo/tarea/";
const ADD_TAREA_ASEO_URL = "/api/v1/Aseo/tarea";

const ASIGNADAS_ASEO_URL = "/api/v1/Aseo/GetTareasAsignadas";
const HABITACIONES_NO_ASIGNADAS_ASEO_URL =
  "/api/v1/Aseo/habitaciones-no-asignadas";

const ADD_TAREAS_ASIGNADAS_URL = "/api/v1/Aseo/tarea-asignada";

const EVENTOS_DE_CADA_PERSONAL = "/api/v1/Aseo/planificacion-diaria/rut/";
const ASIGNAR_GRUPO_HABITACION_ASEO_URL =
  "/api/v1/Aseo/grupo-habitacion/AsignarHabitaciones/";

const GET_GRUPO_URL = "/api/v1/Aseo/grupo-habitacion/";

const axiosInstance = createAxiosInstance();
const axiosFormData = createAxiosFormDataInstance();

export const planificacionPersona = createAsyncThunk(
  "aseo/planificacionPersona",
  async (rut, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${EVENTOS_DE_CADA_PERSONAL}${rut}`
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const fetchAsignadas = createAsyncThunk(
  "aseo/fetchAsignadas",
  async () => {
    try {
      const response = await axiosInstance.get(ASIGNADAS_ASEO_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const getGrupo = createAsyncThunk("aseo/getGrupo", async (id) => {
  try {
    const response = await axiosInstance.get(GET_GRUPO_URL + id);
    return response.data;
  } catch (e) {
    return e.message;
  }
});
export const fetchHabitacionesNoAsignadas = createAsyncThunk(
  "aseo/fetchNoAsignadas",
  async () => {
    try {
      const response = await axiosInstance.get(
        HABITACIONES_NO_ASIGNADAS_ASEO_URL
      );
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const fetchTareasAseo = createAsyncThunk(
  "aseo/fetchTareasAseo",
  async () => {
    try {
      const response = await axiosInstance.get(TAREAS_ASEO_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const fetchHorariosAseo = createAsyncThunk(
  "aseo/fetchHorariosAseo",
  async () => {
    try {
      const response = await axiosInstance.get(ASEOS_HORARIOS_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const fetchGruposAseo = createAsyncThunk(
  "aseo/fetchGruposAseo",
  async () => {
    try {
      const response = await axiosInstance.get(ASEOS_GRUPOS_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const fetchPlanificacionDiaria = createAsyncThunk(
  "aseo/fetchPlanificacionDiaria",
  async () => {
    try {
      const response = await axiosInstance.get(ASEOS_PLANIFICACION_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const fetchPlanificacionEspecifica = createAsyncThunk(
  "aseo/fetchPlanificacionEspecifica",
  async () => {
    try {
      const response = await axiosInstance.get(ASEOS_PLANIFICACION_ES_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const fetchPersonalAseo = createAsyncThunk(
  "aseo/fetchPersonalAseo",
  async () => {
    try {
      const response = await axiosInstance.get(PERSONAL_ASEO_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const updatePersonal = createAsyncThunk(
  "aseo/updatePersonal",
  async (id, { dispatch }) => {
    try {
      const response = await axiosInstance.put(UPDATE_PERSONAL_ASEO_URL + id);
      if (response.status >= 200 && response.status < 300) {
        toast.success("Estado actualizado correctamente.");
        dispatch(fetchPersonalAseo());
        return { data: { id }, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      return e.message;
    }
  }
);
export const deleteHorarioAseo = createAsyncThunk(
  "aseo/deleteHorarioAseo",
  async (id, { dispatch }) => {
    console.log("id", id);
    try {
      const response = await axiosInstance.delete(DELETE_HORARIO_ASEO_URL + id);

      if (response.status >= 200 && response.status < 300) {
        toast.success("Horario de aseo eliminado correctamente.");
        dispatch(fetchHorariosAseo());
        return { data: { id }, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al eliminar el horario de aseo: " + e.response.data);
      return e.message;
    }
  }
);
export const deletePlanificacionAseo = createAsyncThunk(
  "aseo/deletePlanificacionAseo",
  async (id, { dispatch }) => {
    console.log("id", id);
    try {
      const response = await axiosInstance.delete(
        DELETE_PLANIFICACION_ASEO_URL + id
      );

      if (response.status >= 200 && response.status < 300) {
        toast.success("Planificación de aseo eliminada correctamente.");
        dispatch(fetchPlanificacionDiaria());
        return { data: { id }, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error(
        "Error al eliminar la planificación diaria: " + e.response.data
      );
      return e.message;
    }
  }
);
export const deletePlanificacionEspecificaAseo = createAsyncThunk(
  "aseo/deletePlanificacionEspecificaAseo",
  async (id, { dispatch }) => {
    console.log("id", id);
    try {
      const response = await axiosInstance.delete(
        DELETE_PLANIFICACION_ES_ASEO_URL + id
      );

      if (response.status >= 200 && response.status < 300) {
        toast.success("Planificación especifica eliminada correctamente.");
        dispatch(fetchPlanificacionEspecifica());
        return { data: { id }, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error(
        "Error al eliminar la planificación especifica: " + e.response.data
      );
      return e.message;
    }
  }
);
export const deleteTareaAseo = createAsyncThunk(
  "aseo/deleteTareaAseo",
  async (id, { dispatch }) => {
    console.log("id", id);
    try {
      const response = await axiosInstance.delete(DELETE_TAREAS_ASEO_URL + id);

      if (response.status >= 200 && response.status < 300) {
        toast.success("Tarea eliminada correctamente.");
        dispatch(fetchTareasAseo());
        return { data: { id }, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al eliminar la tarea: " + e.response.data);
      return e.message;
    }
  }
);
const formatTime = (time) => {
  const parts = time.split(":");
  if (parts.length === 2) {
    return `${time}:00`;
  } else if (parts.length === 1) {
    return `${time}:00:00`;
  }
  return time;
};
export const updateHorarioAseo = createAsyncThunk(
  "aseo/updateHorarioAseo",
  async (initialCampamento, { dispatch }) => {
    console.log("horario", initialCampamento);
    const formattedHoraInicio = formatTime(initialCampamento.horaInicio);
    const formattedHoraTermino = formatTime(initialCampamento.horaTermino);
    try {
      //aqui
      const response = await axiosInstance.put(
        UPDATE_HORARIO_ASEO_URL + initialCampamento.id,
        {
          nombre: initialCampamento.nombre,
          descripcion: initialCampamento.descripcion,
          horaInicio: formattedHoraInicio,
          horaTermino: formattedHoraTermino,
        }
      );

      if (response.status === 200) {
        toast.success("Horario de aseo actualizado correctamente.");
        dispatch(fetchHorariosAseo());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al actualizar el horario de aseo: " + e.response.data);
      return e.message;
    }
  }
);
export const updateTareaAseo = createAsyncThunk(
  "aseo/updateTareaAseo",
  async (initialCampamento, { dispatch }) => {
    console.log("horario", initialCampamento);
    try {
      const response = await axiosInstance.put(
        UPDATE_TAREAS_ASEO_URL + initialCampamento.id,
        {
          nombre: initialCampamento.nombre,
          descripcion: initialCampamento.descripcion,
        }
      );

      if (response.status === 200) {
        toast.success("Tarea actualizada correctamente.");
        dispatch(fetchTareasAseo());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al actualizar la tarea: " + e.response.data);
      return e.message;
    }
  }
);

export const addNewTareaAseo = createAsyncThunk(
  "aseo/addNewTareaAseo",
  async (initialCampamento, { dispatch }) => {
    try {
      const response = await axiosInstance.post(ADD_TAREA_ASEO_URL, {
        nombre: initialCampamento.nombre,
        descripcion: initialCampamento.descripcion,
      });
      if (response.status >= 200 && response.status < 300) {
        toast.success("Tarea creado correctamente.");
        dispatch(fetchTareasAseo());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al crear la tarea: " + e.response.data);
      return { message: e.message, status: "error" };
    }
  }
);

export const asignarHabitacionGrupo = createAsyncThunk(
  "aseo/asignarHabitacionGrupo",
  async (data, { dispatch }) => {
    try {
      const response = await axiosInstance.put(
        ASIGNAR_GRUPO_HABITACION_ASEO_URL + data.id,
        data.habitaciones
      );
      if (response.status >= 200 && response.status < 300) {
        toast.success("Habitaciones asignadas correctamente.");
        dispatch(fetchHabitacionesNoAsignadas());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al asignar las habitaciones: " + e.response.data);
      return { message: e.message, status: "error" };
    }
  }
);

export const addNewHorarioAseo = createAsyncThunk(
  "aseo/addNewHorarioAseo",
  async (initialCampamento, { dispatch }) => {
    console.log("llega:", initialCampamento);
    const start = format(
      parse(initialCampamento.horaInicio, "HH:mm", new Date()),
      "HH:mm:ss"
    );
    const end = format(
      parse(initialCampamento.horaTermino, "HH:mm", new Date()),
      "HH:mm:ss"
    );

    try {
      const response = await axiosInstance.post(ADD_HORARIO_ASEO_URL, {
        nombre: initialCampamento.nombre,
        descripcion: initialCampamento.descripcion,
        horaInicio: start,
        horaTermino: end,
      });
      if (response.status >= 200 && response.status < 300) {
        toast.success("Horario de aseo creado correctamente.");
        dispatch(fetchHorariosAseo());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al crear el horario de aseo: " + e.response.data);
      return { message: e.message, status: "error" };
    }
  }
);
export const addNewGrupoAseo = createAsyncThunk(
  "aseo/addNewGrupoAseo",
  async (data, { dispatch }) => {
    console.log("llega:", data);
    // const mappedData = data.habitaciones.map((item) => item.id)

    try {
      const response = await axiosInstance.post(ADD_GRUPO_ASEO_URL, {
        nombre: data.nombre,
        descripcion: data.descripcion,
        habitaciones: data.habitaciones,
        oficinas: data.oficinas,
        espacios: data.espacios,
      });
      if (response.status >= 200 && response.status < 300) {
        toast.success("Grupo de habitaciones creado correctamente.");
        dispatch(fetchGruposAseo());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error(
        "Error al crear el grupo de habitaciones: " + e.response.data
      );
      return { message: e.message, status: "error" };
    }
  }
);
export const updateNewGrupoAseo = createAsyncThunk(
  "aseo/updateNewGrupoAseo",
  async (data, { dispatch }) => {
    console.log("llega:", data);

    try {
      const response = await axiosInstance.put(
        UPDATE_GRUPO_ASEO_URL + data.id,
        {
          nombre: data.nombre,
          descripcion: data.descripcion,
          habitaciones: data.habitaciones,
          oficinas: data.oficinas,
          espacios: data.espacios,
        }
      );
      if (response.status >= 200 && response.status < 300) {
        toast.success("Grupo de habitaciones actualizado correctamente.");
        dispatch(fetchGruposAseo());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error(
        "Error al actualizado el grupo de habitaciones: " + e.response.data
      );
      return { message: e.message, status: "error" };
    }
  }
);
export const deleteGrupoAseo = createAsyncThunk(
  "aseo/deleteGrupoAseo",
  async (id, { dispatch }) => {
    console.log("id", id);
    try {
      const response = await axiosInstance.delete(DELETE_GRUPO_ASEO_URL + id);

      if (response.status >= 200 && response.status < 300) {
        toast.success("Grupo de aseo eliminado correctamente.");
        dispatch(fetchGruposAseo());
        return { data: { id }, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al eliminar el grupo de aseo: " + e.response.data);
      return e.message;
    }
  }
);

export const addNewPlanificacionAseo = createAsyncThunk(
  "aseo/addNewPlanificacionAseo",
  async (initialCampamento, { dispatch }) => {
    console.log("llega:", initialCampamento);
    const mappedData = initialCampamento.map((item) => {
      return {
        horarioAseoId: item.horario,
        desde: item.desde,
        hasta: item.hasta,
        gruposHabitaciones: [item.grupo.id],
        trabajadoresRut: item.trabajadoresRut,
        tareasId: item.tareasId,
        tipoAseo: item.tipo,
      };
    });
    console.log(mappedData);
    try {
      const response = await axiosInstance.post(
        ADD_PLANIFICACION_ASEO_URL,
        mappedData
      );
      if (response.status >= 200 && response.status < 300) {
        toast.success("Planificación diaria creada correctamente.");
        dispatch(fetchPlanificacionDiaria());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al crear la planificación diaria: " + e.response.data);
      return { message: e.message, status: "error" };
    }
  }
);

export const addNewPlanificacionAseoRepetir = createAsyncThunk(
  "aseo/addNewPlanificacionAseoRepetir",
  async (initialCampamento, { dispatch }) => {
    console.log("llega:", initialCampamento);
    const mappedData = initialCampamento.map((item) => {
      return {
        horarioAseoId: item.horario,
        desde: item.desde,
        hasta: item.hasta,
        gruposHabitaciones: [item.grupo.id],
        trabajadoresRut: item.ruts,
        tareasId: item.tareasId,
        tipoAseo: item.tipo,
      };
    });
    console.log(mappedData);
    try {
      const response = await axiosInstance.post(
        ADD_PLANIFICACION_ASEO_URL,
        mappedData
      );
      if (response.status >= 200 && response.status < 300) {
        toast.success("Planificación diaria creada correctamente.");
        dispatch(fetchPlanificacionDiaria());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al crear la planificación diaria: " + e.response.data);
      return { message: e.message, status: "error" };
    }
  }
);

export const editPlanificacionAseo = createAsyncThunk(
  "aseo/editPlanificacionAseo",
  async (item, { dispatch }) => {
    console.log("llega:", item);
    try {
      const response = await axiosInstance.put(
        UPDATE_PLANIFICACION_ASEO_URL + item.id,
        {
          horarioAseoId: item.horario,
          desde: item.desde,
          hasta: item.hasta,
          gruposHabitaciones: item.grupo,
          trabajadoresRut: item.ruts,
          tareasId: item.tareasId,
          tipoAseo: item.tipoAseo,
        }
      );
      if (response.status >= 200 && response.status < 300) {
        toast.success("Planificación diaria actualizada correctamente.");
        dispatch(fetchPlanificacionDiaria());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error(
        "Error al actualizar la planificación diaria: " + e.response.data
      );
      return { message: e.message, status: "error" };
    }
  }
);

export const addNewPlanificacionEspecificaAseo = createAsyncThunk(
  "aseo/addNewPlanificacionEspecificaAseo",
  async (initialCampamento, { dispatch }) => {
    console.log("llega:", initialCampamento);
    const combinedDateTime = `${initialCampamento.fecha}T${initialCampamento.hora}`;
    const alarma = `${initialCampamento.fechaAlarma}T${initialCampamento.horaAlarma}`;
    console.log("alarma", alarma);

    try {
      const response = await axiosInstance.post(ADD_PLANIFICACION_ES_ASEO_URL, {
        nombre: initialCampamento.nombre,
        descripcion: initialCampamento.descripcion,
        fecha: combinedDateTime,
        wingId: initialCampamento.wing,
        pabellonId: initialCampamento.pabellon,
        edificioId: initialCampamento.edificio,
        piso: initialCampamento.piso,
        trabajadoresRut: initialCampamento.trabajadoresId,
        repetirPlanificacion: initialCampamento.frecuencia,
        // alarma: alarma,
      });
      if (response.status >= 200 && response.status < 300) {
        toast.success("Planificación especifica creada correctamente.");
        dispatch(fetchPlanificacionEspecifica());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error(
        "Error al crear la planificación especifica: " + e.response.data
      );
      return { message: e.message, status: "error" };
    }
  }
);

export const updatelanificacionEspecificaAseo = createAsyncThunk(
  "aseo/updatelanificacionEspecificaAseo",
  async (initialCampamento, { dispatch }) => {
    console.log("llega:", initialCampamento);
    const combinedDateTime = `${initialCampamento.fecha}T${initialCampamento.hora}`;
    const alarma = `${initialCampamento.fechaAlarma}T${initialCampamento.horaAlarma}`;

    try {
      const response = await axiosInstance.put(
        UPDATE_PLANIFICACION_ES_ASEO_URL + initialCampamento.id,
        {
          nombre: initialCampamento.nombre,
          descripcion: initialCampamento.descripcion,
          fecha: combinedDateTime,
          wingId: initialCampamento.wing,
          pabellonId: initialCampamento.pabellon,
          edificioId: initialCampamento.edificio,
          piso: initialCampamento.piso,
          repetirPlanificacion: initialCampamento.repetirPlanificacion,
          trabajadoresRut: initialCampamento.trabajadoresId,
          // alarma: alarma ?? null,
        }
      );
      if (response.status >= 200 && response.status < 300) {
        toast.success("Planificación especifica actualizada correctamente.");
        dispatch(fetchPlanificacionEspecifica());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error(
        "Error al actualizar la planificación especifica: " + e.response.data
      );
      return { message: e.message, status: "error" };
    }
  }
);

export const addPersonalDeAseo = createAsyncThunk(
  "aseo/addPersonalDeAseo",
  async (data, { dispatch }) => {
    const mappedData = data.map((item, i) => {
      return {
        rut: item.rut,
        nombre: item.nombre,
        apellido: item.apellido,
        email: item.email,
        fechaNacimiento: item.fechaNacimiento,
        telefono: item.telefono || "",
      };
    });
    console.log("llega:", mappedData);
    try {
      const response = await axiosInstance.post(ADD_PERSONAL_ASEO_URL, {
        personalAseo: mappedData,
      });

      if (response.status >= 200 && response.status < 300) {
        toast.success(
          "Trabajadores añadidos al personal de aseo correctamente, se les enviará un correo."
        );
        dispatch(fetchPersonalAseo());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      console.log(e.response);
      dispatch(fetchPersonalAseo());
      toast.error("Error al añadir al personal: " + e.response.data.message);
      return { message: e.message, status: "error" };
    }
  }
);

export const addTareaAsignada = createAsyncThunk(
  "aseo/addTareaAsignada",
  async (data, { dispatch }) => {
    try {
      const mappedData = {
        planificacionDiariaId: data.planificacionDiariaId,
        planificacionEspecificaId: data.planificacionEspecificaId,
        habitacionId: data.habitacionId,
        realizada: data.realizada,
        observaciones: data.observaciones,
        tareasIds: data.item,
      };

      const formData = new FormData();
      formData.append("planificacionDiariaId", data.planificacionDiariaId);
      if (data.planificacionEspecificaId) {
        formData.append(
          "planificacionEspecificaId",
          data.planificacionEspecificaId
        );
      }
      if (data.habitacionId) {
        formData.append("habitacionId", data.habitacionId);
      }
      if (data.oficinaId) {
        formData.append("oficinaId", data.oficinaId);
      }
      if (data.espacioId) {
        formData.append("espacioId", data.espacioId);
      }

      formData.append("realizada", data.realizada);
      formData.append("observaciones", data.observaciones);
      if (data.item && data.item.length > 0) {
        data.item.forEach((id, index) => {
          formData.append(`tareasIds[${index}]`, id);
        });
      } else {
        formData.append(`tareasIds`, null);
      }

      if (data.imagen) {
        formData.append("imagen", data.imagen);
      }

      console.log("formData:-->", formData);
      // return;
      const response = await axiosFormData.post(
        ADD_TAREAS_ASIGNADAS_URL,
        formData
      );
      // const response = await axiosInstance.post(ADD_TAREAS_ASIGNADAS_URL, mappedData);

      if (response.status >= 200 && response.status < 300) {
        toast.success("Estado del aseo en la habitación actualizado.");
        // dispatch(fetchAsignadas());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error(
        "Error al actualizar el estado de aseo de la habitación: " +
          e.response.data
      );
      return { message: e.message, status: "error" };
    }
  }
);

// idle : no hay operaciones en curso
const initialState = {
  horarios: [],
  asignadas: [],
  habitacionesNoAsignadas: [],
  tareas: [],
  planificaciones_diarias: [],
  planificaciones_especificas: [],
  grupos: [],
  personal: [],
  status: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusTarea: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusHorarios: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusGrupos: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusPlanificacionesDiarias: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusPlanificacionesEspecificas: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusPersonal: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusAsignadas: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusHabitacionesNoAsignadas: "idle", //'idle'|'loading'|'succeeded'|'failed'
  error: null,
};

export const aseoSlice = createSlice({
  name: "aseo",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchHabitacionesNoAsignadas.pending, (state, action) => {
        state.statusHabitacionesNoAsignadas = "loading";
      }) //fetch
      .addCase(fetchHabitacionesNoAsignadas.fulfilled, (state, action) => {
        state.statusHabitacionesNoAsignadas = "succeeded";
        if (
          JSON.stringify(state.habitacionesNoAsignadas) !==
          JSON.stringify(action.payload)
        ) {
          //fetch
          state.habitacionesNoAsignadas = action.payload;
        }
      })
      .addCase(fetchHabitacionesNoAsignadas.rejected, (state, action) => {
        //fetch
        state.statusHabitacionesNoAsignadas = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAsignadas.pending, (state, action) => {
        state.statusAsignadas = "loading";
      }) //fetch
      .addCase(fetchAsignadas.fulfilled, (state, action) => {
        state.statusAsignadas = "succeeded";
        if (
          JSON.stringify(state.asignadas) !== JSON.stringify(action.payload)
        ) {
          //fetch
          state.asignadas = action.payload;
        }
      })
      .addCase(fetchAsignadas.rejected, (state, action) => {
        //fetch
        state.statusAsignadas = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchPersonalAseo.pending, (state, action) => {
        state.statusPersonal = "loading";
      }) //fetch
      .addCase(fetchPersonalAseo.fulfilled, (state, action) => {
        state.statusPersonal = "succeeded";
        if (JSON.stringify(state.personal) !== JSON.stringify(action.payload)) {
          //fetch
          state.personal = action.payload;
        }
      })
      .addCase(fetchPersonalAseo.rejected, (state, action) => {
        //fetch
        state.statusPersonal = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTareasAseo.pending, (state, action) => {
        state.statusTarea = "loading";
      }) //fetch
      .addCase(fetchTareasAseo.fulfilled, (state, action) => {
        state.statusTarea = "succeeded";
        if (JSON.stringify(state.personal) !== JSON.stringify(action.payload)) {
          //fetch
          state.tareas = action.payload;
        }
      })
      .addCase(fetchTareasAseo.rejected, (state, action) => {
        //fetch
        state.statusTarea = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchHorariosAseo.pending, (state, action) => {
        state.statusHorarios = "loading";
      }) //fetch
      .addCase(fetchHorariosAseo.fulfilled, (state, action) => {
        state.statusHorarios = "succeeded";
        if (JSON.stringify(state.horarios) !== JSON.stringify(action.payload)) {
          //fetch
          state.horarios = action.payload;
        }
      })
      .addCase(fetchHorariosAseo.rejected, (state, action) => {
        //fetch
        state.statusHorarios = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchPlanificacionDiaria.pending, (state, action) => {
        state.statusPlanificacionesDiarias = "loading";
      }) //fetch
      .addCase(fetchPlanificacionDiaria.fulfilled, (state, action) => {
        state.statusPlanificacionesDiarias = "succeeded";
        if (JSON.stringify(state.horarios) !== JSON.stringify(action.payload)) {
          //fetch
          state.planificaciones_diarias = action.payload;
        }
      })
      .addCase(fetchPlanificacionDiaria.rejected, (state, action) => {
        //fetch
        state.statusPlanificacionesDiarias = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchPlanificacionEspecifica.pending, (state, action) => {
        state.statusPlanificacionesEspecificas = "loading";
      }) //fetch
      .addCase(fetchPlanificacionEspecifica.fulfilled, (state, action) => {
        state.statusPlanificacionesEspecificas = "succeeded";
        if (JSON.stringify(state.horarios) !== JSON.stringify(action.payload)) {
          //fetch
          state.planificaciones_especificas = action.payload;
        }
      })
      .addCase(fetchPlanificacionEspecifica.rejected, (state, action) => {
        //fetch
        state.statusPlanificacionesEspecificas = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchGruposAseo.pending, (state, action) => {
        state.statusGrupos = "loading";
      }) //fetch
      .addCase(fetchGruposAseo.fulfilled, (state, action) => {
        state.statusGrupos = "succeeded";
        if (JSON.stringify(state.grupos) !== JSON.stringify(action.payload)) {
          //fetch
          state.grupos = action.payload;
        }
      })
      .addCase(fetchGruposAseo.rejected, (state, action) => {
        //fetch
        state.statusGrupos = "failed";
        state.error = action.error.message;
      })
      .addCase(addTareaAsignada.rejected, (state, action) => {
        //fetch
        state.statusAsignadas = "failed";
        state.error = action.error.message;
      })
      .addCase(addTareaAsignada.fulfilled, (state, action) => {
        //ADD
        state.statusAsignadas = "succeeded";
        // state.asignadas.push(action.payload);
      })
      .addCase(addPersonalDeAseo.rejected, (state, action) => {
        //fetch
        state.statusPersonal = "failed";
        state.error = action.error.message;
      })
      .addCase(addPersonalDeAseo.fulfilled, (state, action) => {
        //ADD
        state.statusPersonal = "succeeded";
        state.personal.push(action.payload);
      })
      .addCase(addNewHorarioAseo.rejected, (state, action) => {
        //fetch
        state.statusHorarios = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewHorarioAseo.fulfilled, (state, action) => {
        //ADD
        state.statusHorarios = "succeeded";
        state.horarios.push(action.payload);
      })
      .addCase(addNewGrupoAseo.rejected, (state, action) => {
        //fetch
        state.statusGrupos = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewGrupoAseo.fulfilled, (state, action) => {
        //ADD
        state.statusGrupos = "succeeded";
        state.grupos.push(action.payload);
      })
      .addCase(addNewPlanificacionAseo.rejected, (state, action) => {
        //fetch
        state.statusPlanificacionesDiarias = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewPlanificacionAseo.fulfilled, (state, action) => {
        //ADD
        state.statusPlanificacionesDiarias = "succeeded";
        state.planificaciones_diarias.push(action.payload);
      })
      .addCase(addNewPlanificacionAseoRepetir.rejected, (state, action) => {
        //fetch
        state.statusPlanificacionesDiarias = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewPlanificacionAseoRepetir.fulfilled, (state, action) => {
        //ADD
        state.statusPlanificacionesDiarias = "succeeded";
        state.planificaciones_diarias.push(action.payload);
      })
      .addCase(addNewPlanificacionEspecificaAseo.rejected, (state, action) => {
        //fetch
        state.statusPlanificacionesEspecificas = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewPlanificacionEspecificaAseo.fulfilled, (state, action) => {
        //ADD
        state.statusPlanificacionesEspecificas = "succeeded";
        state.planificaciones_especificas.push(action.payload);
      })
      .addCase(deleteHorarioAseo.pending, (state, action) => {
        state.statusHorarios = "loading";
      }) //fetch
      .addCase(deleteHorarioAseo.fulfilled, (state, action) => {
        if (action.payload.status === "success") {
          state.statusHorarios = "succeeded";
          state.horarios = state.horarios.filter(
            (horario) => horario.id !== action.payload.id
          );
        } else {
          state.statusHorarios = "failed";
        }
      })
      .addCase(deleteHorarioAseo.rejected, (state, action) => {
        //fetch
        state.statusHorarios = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteTareaAseo.pending, (state, action) => {
        state.statusTarea = "loading";
      }) //fetch
      .addCase(deleteTareaAseo.fulfilled, (state, action) => {
        if (action.payload.status === "success") {
          state.statusTarea = "succeeded";
          state.tareas = state.tareas.filter(
            (tarea) => tarea.id !== action.payload.id
          );
        } else {
          state.statusTarea = "failed";
        }
      })
      .addCase(deleteTareaAseo.rejected, (state, action) => {
        //fetch
        state.statusTarea = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteGrupoAseo.pending, (state, action) => {
        state.statusGrupos = "loading";
      }) //fetch
      .addCase(deleteGrupoAseo.fulfilled, (state, action) => {
        if (action.payload.status === "success") {
          state.statusGrupos = "succeeded";
          state.grupos = state.grupos.filter(
            (tarea) => tarea.id !== action.payload.id
          );
        } else {
          state.statusGrupos = "failed";
        }
      })
      .addCase(deleteGrupoAseo.rejected, (state, action) => {
        //fetch
        state.statusGrupos = "failed";
        state.error = action.error.message;
      })
      .addCase(deletePlanificacionAseo.pending, (state, action) => {
        state.statusPlanificacionesDiarias = "loading";
      }) //fetch
      .addCase(deletePlanificacionAseo.fulfilled, (state, action) => {
        if (action.payload.status === "success") {
          state.statusPlanificacionesDiarias = "succeeded";
          state.planificaciones_diarias = state.planificaciones_diarias.filter(
            (tarea) => tarea.id !== action.payload.id
          );
        } else {
          state.statusPlanificacionesDiarias = "failed";
        }
      })
      .addCase(deletePlanificacionAseo.rejected, (state, action) => {
        //fetch
        state.statusPlanificacionesDiarias = "failed";
        state.error = action.error.message;
      })
      .addCase(deletePlanificacionEspecificaAseo.pending, (state, action) => {
        state.statusPlanificacionesEspecificas = "loading";
      }) //fetch
      .addCase(deletePlanificacionEspecificaAseo.fulfilled, (state, action) => {
        if (action.payload.status === "success") {
          state.statusPlanificacionesEspecificas = "succeeded";
          state.planificaciones_especificas =
            state.planificaciones_especificas.filter(
              (tarea) => tarea.id !== action.payload.id
            );
        } else {
          state.statusPlanificacionesEspecificas = "failed";
        }
      })
      .addCase(deletePlanificacionEspecificaAseo.rejected, (state, action) => {
        //fetch
        state.statusPlanificacionesEspecificas = "failed";
        state.error = action.error.message;
      })
      .addCase(updateHorarioAseo.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateHorarioAseo.fulfilled, (state, action) => {
        if (action.payload.status === "success") {
          state.statusHorarios = "succeeded";
          state.horarios = state.horarios.map((horario) => {
            if (horario.id === action.payload.data.id) {
              return action.payload.data;
            }
            return horario;
          });
        } else {
          state.statusHorarios = "failed";
        }
      })
      .addCase(updateHorarioAseo.rejected, (state, action) => {
        state.statusHorarios = "failed";
        state.error = action.error.message;
      })
      .addCase(updateNewGrupoAseo.pending, (state, action) => {
        state.statusGrupos = "loading";
      })
      .addCase(updateNewGrupoAseo.fulfilled, (state, action) => {
        if (action.payload.status === "success") {
          state.statusGrupos = "succeeded";
          state.grupos = state.grupos.map((grupo) => {
            if (grupo.id === action.payload.data.id) {
              return action.payload.data;
            }
            return grupo;
          });
        } else {
          state.statusGrupos = "failed";
        }
      })
      .addCase(updateNewGrupoAseo.rejected, (state, action) => {
        state.statusGrupos = "failed";
        state.error = action.error.message;
      })
      .addCase(updatelanificacionEspecificaAseo.pending, (state, action) => {
        state.statusPlanificacionesEspecificas = "loading";
      })
      .addCase(updatelanificacionEspecificaAseo.fulfilled, (state, action) => {
        if (action.payload.status === "success") {
          state.statusPlanificacionesEspecificas = "succeeded";
          state.planificaciones_especificas =
            state.planificaciones_especificas.map((horario) => {
              if (horario.id === action.payload.data.id) {
                return action.payload.data;
              }
              return horario;
            });
        } else {
          state.statusPlanificacionesEspecificas = "failed";
        }
      })
      .addCase(updatelanificacionEspecificaAseo.rejected, (state, action) => {
        state.statusPlanificacionesEspecificas = "failed";
        state.error = action.error.message;
      })
      .addCase(editPlanificacionAseo.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(editPlanificacionAseo.fulfilled, (state, action) => {
        if (action.payload.status === "success") {
          state.statusPlanificacionesDiarias = "succeeded";
          state.planificaciones_diarias = state.planificaciones_diarias.map(
            (horario) => {
              if (horario.id === action.payload.data.id) {
                return action.payload.data;
              }
              return horario;
            }
          );
        } else {
          state.statusPlanificacionesDiarias = "failed";
        }
      })
      .addCase(editPlanificacionAseo.rejected, (state, action) => {
        state.statusPlanificacionesDiarias = "failed";
        state.error = action.error.message;
      });
  },
});

export const getAllHorariosAseos = (state) => state.aseo.horarios;
export const getHorariosAseoStatus = (state) => state.aseo.statusHorarios;

export const getAllGruposAseos = (state) => state.aseo.grupos;
export const getGruposAseoStatus = (state) => state.aseo.statusGrupos;

export const getAllPlanificacionesDiarias = (state) =>
  state.aseo.planificaciones_diarias;
export const getPlanificacionesDiariasStatus = (state) =>
  state.aseo.statusPlanificacionesDiarias;

export const getAllPlanificacionesEspecificas = (state) =>
  state.aseo.planificaciones_especificas;
export const getPlanificacionesEspecificasStatus = (state) =>
  state.aseo.statusPlanificacionesEspecificas;

export const getAseo = (state) => state.aseo.aseo;
export const getAseoError = (state) => state.aseo.error;

export const getAllPersonal = (state) => state.aseo.personal;
export const getPersonalStatus = (state) => state.aseo.statusPersonal;

export const getAllTareas = (state) => state.aseo.tareas;
export const getTareasStatus = (state) => state.aseo.statusTarea;

export const getAllAsignadas = (state) => state.aseo.asignadas;
export const getAsignadasStatus = (state) => state.aseo.statusAsignadas;

export const getAllHabitacionesNoAsignadas = (state) =>
  state.aseo.habitacionesNoAsignadas;
export const getHabitacionesNoAsignadasStatus = (state) =>
  state.aseo.statusHabitacionesNoAsignadas;

export const {} = aseoSlice.actions;
export default aseoSlice.reducer;
