import styles from "./components.module.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
  Popper,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { updateWing } from "../../../../redux/features/wings/wingSlice";
import { getAllEstandares } from "../../../../redux/features/estandares/estandarSlice";
import { getAllCampamentos } from "../../../../redux/features/campamentos/campamentoSlice";

const tipo_huesped = ["Solo mujeres", "Solo hombres", "Unisex"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      with: "100%",
    },
  },
};

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3 }) {
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [formData, setFormData] = useState();
  const dispatch = useDispatch();
  const campamentos = useSelector(getAllCampamentos);
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const standares = useSelector(getAllEstandares);
  const [standars, setStandars] = useState([]);
  const [selectedStandar, setSelectedStandar] = useState(null);
  const [standarsList, setstandarsList] = useState([]);
  const letras = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(65 + i)
  );
  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
    setFormData({ ...formData, nombre: event.target.value });
  };

  useEffect(() => {
    setFormData(seleItem);
    setAge(seleItem?.nombre);
    if (seleItem && seleItem.standard) {
      setstandarsList(seleItem.standard.map((item) => item.nombre));
    } else {
      setstandarsList([]);
    }
    setSelectedCampamento(seleItem?.campamentoId);
    setStandars(
      standares.filter((item) => item.campamentoId == seleItem?.campamentoId)
    );
  }, [seleItem]);

  useEffect(() => {
    if (formData) {
      const selectedIds = standars
        .filter((item) => standarsList.includes(item.nombre))
        .map((item) => item.id);
      const selectedName = standars.filter((item) =>
        standarsList.includes(item.nombre)
      );
      setFormData({
        ...formData,
        standardId: selectedIds,
        standard: selectedName,
      });
    }
  }, [formData]);

  // useEffect(() => {
  //   if(formData){
  //     const selectedIds = formData?.standard.map(item => item.id);
  //   setFormData({...formData, standardId: selectedIds})
  //   }
  // }, [formData])

  const handleChangeStandars = (event) => {
    const { value } = event.target;
    setstandarsList(value);
    setFormData((prevFormData) => ({ ...prevFormData, standarsList: value }));
  };

  const handleChangeCampamento = (event, newValue) => {
    console.log(newValue);
    setSelectedCampamento(newValue);
    setstandarsList([]);
    setStandars(standares.filter((item) => item.campamentoId == newValue.id));
    if (newValue != null) {
      setFormData({
        ...formData,
        campamentoId: newValue.id,
        standardId: 0,
        campamento: newValue,
      });
      setSelectedStandar(null);
    } else {
      setFormData({ ...formData, campamentoId: 0, campamento: null });
    }
  };

  // const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
  const onChange = (e) => {
    const value = e.target.value;
    const invalidCharacters = /[<>\/]/;

    if (!invalidCharacters.test(value)) {
      setFormData({ ...formData, [e.target.name]: value });
    } else {
      toast.error("Carácter no válido");
    }
  };

  const handleChangeTipo = (event, newValue) => {
    if (newValue != null) {
      setFormData({ ...formData, generoHuesped: newValue });
    } else {
      setFormData({ ...formData, generoHuesped: 0 });
    }
  };
  const handleChangeStandar = (event, newValue) => {
    if (newValue != null) {
      // setFormData({...formData, standardId: newValue.id})
      setSelectedStandar(newValue);
      console.log("estandars", newValue);
      const selectedIds = newValue.map((item) => item.id);
      setFormData({ ...formData, standardId: selectedIds, standard: newValue });
    } else {
      // setFormData({...formData, standardId: 0})
      setFormData({ ...formData, standardId: [], standard: [] });
    }
  };

  const canSave =
    [
      formData?.nombre,
      formData?.campamentoId,
      formData?.standard.length != 0,
      formData?.numeroPisos || formData?.numeroPisos == 0,
      formData?.numeroHabitaciones || formData?.numeroHabitaciones == 0,
    ].every(Boolean) && addRequestStatus === "idle";

  const handleEdit = async () => {
    try {
      setOpen3(true);
      setAddRequestStatus("loading");
      let resp = await dispatch(updateWing(formData));
      if (resp.payload.status == "success") {
        setOpen3(false);
      } else {
        setOpen3(false);
      }
    } catch (error) {
      toast.error("Error al actualizar el wing.");
      console.error("Error al actualizar el wing", error.message);
    } finally {
      setAddRequestStatus("idle");
      setOpenEdit(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={openEdit}
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: "75%", md: "50%" },
          backgroundColor: "#efefef",
          color: "#2d3037",
          borderRadius: "0",
        },
      }}
      onClose={() => setOpenEdit(false)}
    >
      {seleItem && (
        <>
          <DialogTitle>
            <Grid container={true} spacing={1} p={2}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{ display: "flex", gap: "5px" }}
              >
                <ErrorIcon
                  style={{ fontSize: "3rem", marginTop: "5px" }}
                  className={styles.iconBg}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h6" className={styles.textIconEdit}>
                    Actualizar wing {seleItem?.nombre}
                  </Typography>
                  <Typography variant="span" className={styles.textIconSpan}>
                    Modifica los campos para actualizar el wing.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container={true} spacing={1} p={2}>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Nombre</label>
                <Select
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age || ""}
                  label="nombre"
                  onChange={handleChange}
                  style={{ width: "100%" }}
                >
                  {letras.map((letra) => (
                    <MenuItem value={letra}>{letra}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Campamento</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  disablePortal
                  disableClearable
                  id="sectores-autocomplete"
                  options={campamentos ? campamentos : []}
                  name="sectorId"
                  getOptionLabel={(option) => option.nombre}
                  value={formData?.campamento || null}
                  onChange={(e, value) => handleChangeCampamento(e, value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecciona un campamento" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>N° de habitaciones</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="numeroHabitaciones"
                  type="number"
                  value={formData?.numeroHabitaciones}
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>N°Pisos</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="numeroPisos"
                  type="number"
                  value={formData?.numeroPisos}
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Tipo de huesped</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  disablePortal
                  id="campamentos-autocomplete"
                  options={tipo_huesped}
                  value={formData?.generoHuesped || []}
                  name="generoHuesped"
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => handleChangeTipo(event, value)} // Asegúrate de que esta función esté definida y actualizando el estado adecuadamente
                  renderInput={(params) => <TextField {...params} />}
                  disableClearable
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Standard</label>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Selecciona 1 o más standard
                  </InputLabel>
                  <Select
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={standarsList}
                    onChange={handleChangeStandars}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {standars.map((stnd) => (
                      <MenuItem key={stnd.nombre} value={stnd.nombre}>
                        <Checkbox
                          checked={standarsList.indexOf(stnd.nombre) > -1}
                        />
                        <ListItemText primary={stnd.nombre} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <label className={styles.label}>Descripción</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="descripcion"
                  type="text"
                  multiline
                  rows={4}
                  maxRows={4}
                  value={formData?.descripcion ? formData?.descripcion : ""}
                  onChange={(e) => onChange(e)}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Grid container={true} spacing={1} p={2}>
              <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                <Button
                  className={styles.bgColorEdit}
                  variant="contained"
                  disabled={!canSave}
                  onClick={handleEdit}
                >
                  {addRequestStatus == "loading" ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Actualizar"
                  )}
                </Button>
                <Button
                  onClick={() => setOpenEdit(false)}
                  className={styles.bgColorOutlined}
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      )}
    </Drawer>
  );
}
