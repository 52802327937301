import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';
import { createAxiosInstance } from "../../../api/axios";

const ESPACIOS_URL = '/api/v1/Guardarropia/Espacios';
const INGRESOS_URL = '/api/v1/Guardarropia/Ingresos';
const SALIDAS_URL = '/api/v1/Guardarropia/Retiros';
const SOLICITUDES_ESPACIOS_URL = '/api/v1/Guardarropia/Espacios';
const ITEMS_HUESPED_URL = '/api/v1/Guardarropia/Items';
const ADD_URL = '/api/v1/Guardarropia/Espacio';
const ADD_INGRESO_URL = '/api/v1/Guardarropia/Ingreso';
const ADD_INGRESO_HUESPED_URL = '/api/v1/Guardarropia/IngresoHuesped';
const ADD_RETIRO_URL = '/api/v1/Guardarropia/Retiro';
const UPDATE_URL = '/api/v1/Edificio/';
const UPDATE_ESPACIO_URL = '/api/v1/Guardarropia/Espacio/';
const DELETE_URL = '/api/v1/Guardarropia/Espacio/';
const OCUPACION_URL = '/api/v1/Guardarropia/OcupacionCampamento';
const RETIRO_ITEMS_RUT_URL = '/api/v1/Guardarropia/ItemsHuesped/';

const axiosInstance = createAxiosInstance();

// ACTIONS
export const fetchOcupacion = createAsyncThunk("otros/fetchOcupacion", async ()=> {
    try {
        const response = await axiosInstance.get(OCUPACION_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchItemsRut = createAsyncThunk("otros/fetchItemsRut", async ()=> {
    try {
        const response = await axiosInstance.get(RETIRO_ITEMS_RUT_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchEspacios = createAsyncThunk("otros/fetchEspacios", async ()=> {
    try {
        const response = await axiosInstance.get(ESPACIOS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchIngresosGuardarropia = createAsyncThunk("otros/fetchIngresosGuardarropia", async ()=> {
    try {
        const response = await axiosInstance.get(INGRESOS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchSalidasGuardarropia = createAsyncThunk("otros/fetchSalidasGuardarropia", async ()=> {
    try {
        const response = await axiosInstance.get(SALIDAS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchSolicitudesEspacios = createAsyncThunk("otros/fetchSolicitudesEspacios", async ()=> {
    try {
        const response = await axiosInstance.get(SOLICITUDES_ESPACIOS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchItemsHuesped = createAsyncThunk("otros/fetchItemsHuesped", async ()=> {
    try {
        const response = await axiosInstance.get(ITEMS_HUESPED_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const deleteEspacio = createAsyncThunk("otros/deleteEspacio", async (id, {dispatch})=> {
    console.log('espacio id', id);
    try {
        const response = await axiosInstance.delete(DELETE_URL+id);
        if (response.status === 200) {
            toast.success('Espacio eliminado correctamente.');
            dispatch(fetchEspacios());
            return { data:  response.data, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al eliminar el espacio: ' + e.response.data);
        return e.message;
    }
})
export const updateEspacio = createAsyncThunk("otros/updateEspacio", async (upCampamento, {dispatch})=> {
    console.log('id', upCampamento.id);
    try {
        const response = await axiosInstance.put(UPDATE_URL+upCampamento.id, {
            nombre: upCampamento.nombre,
            campamentoid: upCampamento.campamentoId,
            numeroPisos: upCampamento.numeroPisos,
        });
        
        if (response.status === 200 ) {
            toast.success('Espacio actualizado correctamente.');
            dispatch(fetchEspacios());
            return { data: response.data, status: 'success' };
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar el espacio: ' + e.response.data);
        return e.message;
    }
})
export const updateEspacioAlmacenamiento = createAsyncThunk("otros/updateEspacioAlmacenamiento", async (upCampamento, {dispatch})=> {
    console.log('id', upCampamento.numero);
    try {
        const response = await axiosInstance.put(UPDATE_ESPACIO_URL+upCampamento.id, {
            numero: upCampamento.numero.toString(),
            campamentoid: upCampamento.campamento.id,
            fila: upCampamento.fila,
            nivel: upCampamento.nivel,
        });
        
        if (response.status === 200 ) {
            toast.success('Espacio de almacenamiento actualizado correctamente.');
            dispatch(fetchEspacios());
            return { data: response.data, status: 'success' };
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar el espacio de almacenamiento: ' + e.response.data);
        return e.message;
    }
})

export const addNewEspacio = createAsyncThunk("otros/createEspacio", async (initialCampamento, { dispatch }) => {
    console.log(initialCampamento)
    try {
        const response = await axiosInstance.post(ADD_URL, {
            numero: initialCampamento.numero.toString(),
            campamentoId: initialCampamento.campamentoId,
            fila: initialCampamento.fila,
            nivel: initialCampamento.nivel,
        });
        if (response.status == 200) {
            toast.success('Espacio creado correctamente.');
            dispatch(fetchEspacios());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error'} ;
    } catch (e) {
        toast.error('Error al crear el espacio: ' + e.response.data);
        console.log(e.message)
        return {message: e.message, status: 'error'};
    }
})
export const addNewIngreso = createAsyncThunk("otros/createIngreso", async (initialCampamento, { dispatch }) => {
    console.log(initialCampamento)
    try {
        const response = await axiosInstance.post(ADD_INGRESO_URL, {
            // espacioId: initialCampamento.espacioId,
            rutTrabajador: initialCampamento.rut.rut,
            items: initialCampamento.items,
        });
        if (response.status == 200) {
            toast.success('Ingreso exitoso.');
            dispatch(fetchIngresosGuardarropia());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error'} ;
    } catch (e) {
        toast.error('Error al crear el registro: ' + e.response.data);
        console.log(e.message)
        return {message: e.message, status: 'error'};
    }
})
export const addNewIngresoDesdeHuesped = createAsyncThunk("otros/addNewIngresoDesdeHuesped", async (data, { dispatch }) => {
    console.log('llega:',data)
    try {
        const response = await axiosInstance.post(ADD_INGRESO_HUESPED_URL, {
            espacioId: data.espacioId,
            rutTrabajador: data.rutTrabajador,
            items: data.items,
        });
        if (response.status == 200) {
            toast.success(response.data);
            dispatch(fetchItemsHuesped());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error'} ;
    } catch (e) {
        toast.error('Error al crear el registro: ' + e.response.data);
        console.log(e.message)
        return {message: e.message, status: 'error'};
    }
})
export const addNewRetiro = createAsyncThunk("otros/createRetiro", async (initialCampamento, { dispatch }) => {
    console.log(initialCampamento)
    try {
        const response = await axiosInstance.post(ADD_RETIRO_URL, {
            espacioId: initialCampamento.espacioId,
            rutTrabajador: initialCampamento.rut.rut,
            items: initialCampamento.items,
        });
        if (response.status == 200) {
            toast.success('Retiro exitoso.');
            dispatch(fetchSalidasGuardarropia());
            dispatch(fetchIngresosGuardarropia());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error'} ;
    } catch (e) {
        toast.error('Error al ingresar el retiro: ' + e.response.data);
        console.log(e.message)
        return {message: e.message, status: 'error'};
    }
})


// idle : no hay operaciones en curso
const initialState = {
    espacios: [],
    ingresos: [],
    retiros: [],
    solicitudes: [],
    items: [],
    itemsRetiro: [],
    espacio: {},
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    statusItems: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    statusSolicitudes: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const guardarropiaSlice = createSlice({
    name:"guardarropia",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchItemsRut.pending, (state, action) => {state.statusItems = 'loading'} ) //fetch
        .addCase(fetchItemsRut.fulfilled, (state, action) => {
            state.statusItems = 'succeeded';
            if (JSON.stringify(state.itemsRetiro) !== JSON.stringify(action.payload)) { //fetch
                state.itemsRetiro = action.payload;
            }
        })
        .addCase(fetchItemsRut.rejected, (state, action) => { //fetch
            state.statusItems = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchEspacios.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchEspacios.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.espacios) !== JSON.stringify(action.payload)) { //fetch
                state.espacios = action.payload;
            }
        })
        .addCase(fetchEspacios.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchIngresosGuardarropia.pending, (state, action) => {state.statusSolicitudes = 'loading'} ) //fetch
        .addCase(fetchIngresosGuardarropia.fulfilled, (state, action) => {
            state.statusSolicitudes = 'succeeded';
            if (JSON.stringify(state.ingresos) !== JSON.stringify(action.payload)) { //fetch
                state.ingresos = action.payload;
            }
        })
        .addCase(fetchIngresosGuardarropia.rejected, (state, action) => { //fetch
            state.statusSolicitudes = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchSalidasGuardarropia.pending, (state, action) => {state.statusSolicitudes = 'loading'} ) //fetch
        .addCase(fetchSalidasGuardarropia.fulfilled, (state, action) => {
            state.statusSolicitudes = 'succeeded';
            if (JSON.stringify(state.retiros) !== JSON.stringify(action.payload)) { //fetch
                state.retiros = action.payload;
            }
        })
        .addCase(fetchSalidasGuardarropia.rejected, (state, action) => { //fetch
            state.statusSolicitudes = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchSolicitudesEspacios.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchSolicitudesEspacios.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.solicitudes) !== JSON.stringify(action.payload)) { //fetch
                state.solicitudes = action.payload;
            }
        })
        .addCase(fetchSolicitudesEspacios.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchItemsHuesped.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchItemsHuesped.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.items) !== JSON.stringify(action.payload)) { //fetch
                state.items = action.payload;
            }
        })
        .addCase(fetchItemsHuesped.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(addNewEspacio.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                state.espacios.push(action.payload.data);
            } else {
                state.status = 'failed'
            }
        })
        .addCase(addNewIngreso.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                state.ingresos.push(action.payload.data);
            } else {
                state.status = 'failed'
            }
        })
        .addCase(addNewIngresoDesdeHuesped.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.statusItems = 'succeeded';
                state.items.push(action.payload.data);
            } else {
                state.statusItems = 'failed'
            }
        })
        .addCase(deleteEspacio.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(deleteEspacio.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                state.espacios = state.espacios.filter(otro => otro.id !== action.payload.id);
            }else{
                state.status = 'failed';
            }
        })
        .addCase(deleteEspacio.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(updateEspacio.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(updateEspacio.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                state.espacios = state.espacios.map(otro => {
                    if (otro.id === action.payload.id) {
                        return action.payload.data;
                    }
                    return otro;
                });
            }else{
                state.status = 'failed';
            }
        })
        .addCase(updateEspacio.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
        
    }
})

export const getAllEspacios = (state) => state.guardarropias.espacios;
export const getAllItemsRut = (state) => state.guardarropias.itemsRetiro;
export const getAllItems = (state) => state.guardarropias.items;
export const getAllIngresosGuardarropia = (state) => state.guardarropias.ingresos;
export const getAllRetirosGuardarropia = (state) => state.guardarropias.retiros;
export const getEspacio = (state) => state.guardarropias.espacio;
export const getEspacioStatus = (state) => state.guardarropias.status;
export const getSolicitudesStatus = (state) => state.guardarropias.statusSolicitudes;
export const getsEspacioError = (state) => state.guardarropias.error;

export const {} = guardarropiaSlice.actions;
export default guardarropiaSlice.reducer;