import styles from './components.module.css';
import { Grid, Typography, capitalize } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import SkeletonTable from "../../../../components/Skeleton";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { trabajadoresReservaColumns } from "../../../../lib/columns";

const Detalle = ({ reserva, trabajadores, checkedState, setCheckedState, setCheckedHabitaciones, setAsignacionHabitacionesTrabajadores, formatFecha  }) => {
    
    const [seleItem, setSeleItem] = useState();
    const [open, setOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const finalColumns = trabajadoresReservaColumns(setOpen, setSeleItem);
    
    // const dispatch = useDispatch();
    // const status = useSelector(getCampamentosStatus);
    let status = 'idle';
    
    const handleRowSelectionChange = (selectionModel) => {
        // setCheckedHabitaciones([]);
        console.log('seleccciionado', selectionModel);
        const rutUsuarios = selectionModel.map((id) => {
            const row = trabajadores.find((row) => row?.trabajadordP_INTERNO ? row?.trabajadordP_INTERNO.rut === id : row?.trabajadorP_EXTERNO?.rut === id);
            return {id: row.rut ,rut: row.rut, nombre: row?.trabajadordP_INTERNO ? row?.trabajadordP_INTERNO.nombre : row?.trabajadorP_EXTERNO.nombre, acreditado: row?.trabajadordP_INTERNO ? row?.trabajadordP_INTERNO.acreditado : row?.trabajadorP_EXTERNO.acreditado};
          });
        setCheckedState(rutUsuarios);
        setAsignacionHabitacionesTrabajadores(rutUsuarios);
      };

    useEffect(()=>{
        // ejecutar dispatch del fetch de trabajadores asociados a la reserva
        if(status === 'idle'){
            // dispatch(fetchTrabajadoresReserva())
            status = 'loading';
            // console.log('info: ',reserva);
        }
        status = 'idle';
    },[reserva])
    useEffect(()=>{
        console.log('chekedstate',checkedState)
    },[checkedState])
    
  return (
    <Grid container spacing={1}>
    <Info reserva={reserva} formatFecha={formatFecha}/>
    {
        status == 'loading' 
        ? <SkeletonTable/>
        :
        <Grid item xs={12} sm={12} mt={2}>
            <DataGrid
                getRowId={row=> row?.trabajadordP_INTERNO ? row?.trabajadordP_INTERNO?.rut : row?.trabajadorP_EXTERNO?.rut }
                checkboxSelection
                rowSelectionModel ={checkedState.map(item => item.id)}
                onRowSelectionModelChange={handleRowSelectionChange}
                lots={{ toolbar: GridToolbar }}
                rows={trabajadores && trabajadores ? trabajadores : []}
                columns={finalColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 20]}
                // isRowSelectable={(params) => params.row?.trabajadordP_INTERNO ? params.row?.trabajadordP_INTERNO.acreditado == true : params.row?.trabajadorP_EXTERNO?.acreditado == true}
                localeText={{
                ...esES.components.MuiDataGrid.defaultProps.localeText,
                noRowsLabel: "No hay datos disponibles",
                pagination: {
                    labelRowsPerPage: "Filas por página:",
                }
                }}
                classes={{ menuIcon: styles.menuIcon }}
            />
        </Grid>
    }
    </Grid>
  )
}

export default Detalle

const Info = ({reserva, formatFecha}) => {
    return (
        <>
        {
            Object.entries(reserva).map(([key, value], i) => (
                <Grid item xs={12} sm={6} key={i}>
                    <Typography variant="span">
                    {
                    capitalize(key === 'cantidad_trabajadores' ? 'cantidad trabajadores': key === 'emailSolicitante' ? 'Email Solicitante' : key === 'apellidoSolicitante' ? 'Apellido Solicitante': key === 'nombreSolicitante' ? 'Nombre Solicitante' : key === 'fechaIngreso' ? 'Fecha Ingreso' : key === 'razonRechazo' ? '' : key)
                    } 
                </Typography>
                    <Typography variant="span"><strong>
                    {
                         key === 'fechaIngreso' ? ': '+formatFecha(value) 
                         : key === 'fechaSolicitud' ? ': '+formatFecha(value) 
                         : key === 'fechaSalida' ? ': '+formatFecha(value) 
                         : key === 'razonRechazo' ? key ? value : ''
                        : ': '+value
                    }</strong></Typography>
                </Grid>
            ))   
        }
        </>
    )
}
