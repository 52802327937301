import styles from './components/components.module.css'
import React, { forwardRef, useEffect, useState } from 'react'
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, ListItemText, TextField, Typography,Card } from '@mui/material';
import Slide from '@mui/material/Slide';
import StormIcon from '@mui/icons-material/Storm';
import Create from './components/Create';
import { fetchOtros, getAllOtros, getOtrosStatus } from '../../redux/features/otros/otroSlice';
import { fetchMotivos, getAllMotivos, getMotivosStatus } from '../../redux/features/motivos/motivoSlice';
import { fetchCampamentos, getAllCampamentos, getCampamentosStatus } from '../../redux/features/campamentos/campamentoSlice';
import { fetchWings, getAllWings, getWingsStatus } from '../../redux/features/wings/wingSlice';
import { fetchEdificios, getAllEdificios, getEdificiosStatus } from '../../redux/features/edificios/edificioSlice';
import { fetchHabitaciones, getAllHabitaciones, getHabitacionesStatus } from '../../redux/features/habitaciones/habitacionSlice';
import { fetchEspacios } from '../../redux/features/espacios/espacioSlice';
import { getAllEspacios, getEspaciosStatus } from '../../redux/features/bodegas/bodegaSlice';
import { useDispatch, useSelector } from 'react-redux';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReclamosSugerenciasAll = () => {

  const [open2, setOpen2] = useState(false);

  const campamentos = useSelector(getAllCampamentos);
  const camStatus = useSelector(getCampamentosStatus);
  const wings = useSelector(getAllWings);
  const winStatus = useSelector(getWingsStatus);
  const otros = useSelector(getAllOtros);
  const otStatus = useSelector(getOtrosStatus);
  const pabellones = useSelector(getAllEdificios);
  const pabStatus = useSelector(getEdificiosStatus);
  const habitaciones = useSelector(getAllHabitaciones);
  const habStatus = useSelector(getHabitacionesStatus);
  const espacios = useSelector(getAllEspacios);
  const espStatus = useSelector(getEspaciosStatus);
  const motivos = useSelector(getAllMotivos);
  const motStatus = useSelector(getMotivosStatus);

  const dispatch = useDispatch();

  useEffect(()=>{
    if(motStatus === 'idle'){
      dispatch(fetchMotivos());
    }
    if(camStatus === 'idle'){
        dispatch(fetchCampamentos());
    }
    if(winStatus === 'idle'){
      dispatch(fetchWings());
    }
    if(otStatus === 'idle'){
      dispatch(fetchOtros());
    }
    if(pabStatus === 'idle'){
      dispatch(fetchEdificios());
    }
    if(habStatus === 'idle'){
      dispatch(fetchHabitaciones());
    }
    if(espStatus === 'idle'){
      dispatch(fetchEspacios());
    }
  },[]);

  // const motivos = [];
  // const campamentos = [];
  // const wings = [];
  // const pabellones = [];
  // const otros = [];
  // const espacios = [];
  // const habitaciones = [];

  return (
    <div className="App2" style={{ position: 'relative', minHeight: '100vh' }}>
      <Create setOpen2={setOpen2} open2={open2} motivos={motivos} campamentos={campamentos} wings={wings} pabellones={pabellones} habitaciones={habitaciones} otros={otros} espacios={espacios}/>
    </div>
  )
}

export default ReclamosSugerenciasAll