import { useState, useRef, useEffect, memo } from 'react';
import { MapContainer, Marker, Popup, TileLayer, Icon, CircleMarker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Button, Grid, TextField, Autocomplete, Popper } from "@mui/material";
import styles from './components.module.css';
import * as L from "leaflet";

const Map = ({ position, setPosition, formData, setFormData }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const inputRef = useRef(null);
    const mapRef = useRef(null);
    
    const LeafIcon = L.Icon.extend({
      options: {}
    });
  
    const blueIcon = new LeafIcon({
        iconUrl: 'http://leafletjs.com/examples/custom-icons/leaf-green.png',
        shadowUrl: 'http://leafletjs.com/examples/custom-icons/leaf-shadow.png'
      })
      
      const [icon, setIcon] = useState(blueIcon);

    useEffect(() => {
        inputRef.current.focus(); // Mantener el enfoque en el input
    }, [searchResults]);
    

    const searchAddress = async () => {
        if (!searchQuery) return;

        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(searchQuery)}`);
        const data = await response.json();
        
        setSearchResults(data);
    };

    const handleSearch = () => {
        searchAddress();
    };

    const handleResultClick = (e, result) => {
        console.log('result-->', result);
        setPosition({lat: parseFloat(result.lat), lng: parseFloat(result.lon)});
        setFormData({...formData, latitud: parseFloat(result.lat), longitud:parseFloat(result.lon) })
        setSearchResults([]);
        if (mapRef.current) {
            mapRef.current.setView([parseFloat(result.lat), parseFloat(result.lon)]);
        }
    };

    return (
        <Grid item  xs={12} sm={12} mt={2}>
            <div style={{ position: 'relative', width:'100%' }}>
                <div style={{ display:'flex', gap:'5px', width:'100%' }}>
                  <TextField
                    className={styles.textField}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            border: 'none',
                        },
                        '&:hover fieldset': {
                            border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                            border: 'none',
                        },
                        },
                        '& .MuiInputBase-input': {
                        color: '#2d3037',
                        },
                        '& .MuiInputLabel-root': {
                        color: '#2d3037',
                        },
                    }}
                    ref={inputRef}
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Buscar dirección..."
                    style={{ width: '100%', marginBottom: '8px' }}
                />
                <Button className={styles.bgColorEdit} style={{ marginBottom: '8px', padding:'0 20px' }} onClick={handleSearch}>Buscar</Button>
                </div>

                {Array.isArray(searchResults) && searchResults.length > 0 && 
                  <Autocomplete
                    disablePortal
                    className={styles.textField}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color:'#2d3037',
                      },
                      '& .MuiInputLabel-root': {
                        color:'#2d3037',
                      },
                    }}
                    id="campamentos-autocomplete"
                    name="campamento"
                    options={Array.isArray(searchResults) && searchResults.length > 0 ? searchResults : []}
                    getOptionLabel={(option) => option.display_name}
                    disableClearable
                    onChange={(e, value)=> handleResultClick(e, value)}
                    renderInput={(params) => <TextField {...params} label="Selecciona una dirección" />}
                  />
                }
            </div>
            
            {(position.lat && position.lng) ? <label className={styles.label} style={{ display:'flex', alignItems:'center', gap:'4px', marginTop:'10px' }}>Seleccione el marcador y arrástrelo</label> : ''}

            {(position.lat && position.lng) ? <MapContainer center={position} zoom={13} scrollWheelZoom={true} style={{ height: "480px", width: "100%" }} ref={mapRef}>
                <TileLayer
                    attribution=''
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                    position={position}
                    draggable={true}
                    
                    eventHandlers={{
                        dragend: (event) => {
                            setPosition(event.target.getLatLng());
                        }
                    }}
                    icon={icon}
                >
                    <Popup minWidth={90}>
                      <span>Arrastre el marcador</span>
                    </Popup>
                </Marker>
            </MapContainer> : ''}
        </Grid>
    );
};

export default Map;
