import styles from './components/components.module.css';
import { Backdrop, Box, CircularProgress, Container } from "@mui/material"
import Header from "../../../components/header/Header"
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import { useEffect, useState } from "react";
import TableCard from '../../../components/tableCard/TableCard';
import Table from './components/estado/TableRecepcionista';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonTable from '../../../components/Skeleton';
import { fetchReservas, getAllreservas, getReservasStatus } from '../../../redux/features/reservas/reservaSlice';

const EstadoReservaRecepcionista = () => {
    const dispatch = useDispatch();
    const reservas = useSelector(getAllreservas);
    const status = useSelector(getReservasStatus);
    
    const [open3, setOpen3] = useState(false);
    useEffect(() => {
        if(status == 'idle'){
            dispatch(fetchReservas());
        }
    }, [])
    

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Historial de solicitudes de reservas"
          subtitle="Este módulo ofrece la capacidad de ver el estado de las reservas solicitadas, rechazar aquellas reservas pendientes y cancelar las reservas programadas.">
          {<HolidayVillageIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>

        <TableCard>
            { status == 'loading' ? <SkeletonTable/> : <Table reservas={reservas ? reservas : []} setOpen3={setOpen3}/> }
        </TableCard>

    </Container>
    </Box>
  )
}

export default EstadoReservaRecepcionista