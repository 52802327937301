import styles from "./components/components.module.css";
import { Box, Container } from "@mui/material";
import Header from "../../../components/header/Header";
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from "./components/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchOtros,
  getAllOtros,
  getOtrosStatus,
} from "../../../redux/features/otros/otroSlice";
import SkeletonTable from "../../../components/Skeleton";
import { Backdrop, CircularProgress } from "@mui/material";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import {
  fetchCampamento,
  fetchCampamentos,
  getAllCampamentos,
} from "../../../redux/features/campamentos/campamentoSlice";

const Otros = () => {
  const dispatch = useDispatch();
  const otros = useSelector(getAllOtros);
  const campamentos = useSelector(getAllCampamentos);
  const [rows, setRows] = useState([]);
  const status = useSelector(getOtrosStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchOtros());
      dispatch(fetchCampamentos());
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (typeof otros.map === "function") {
        const updatedRows = await Promise.all(
          otros.map(async (otro) => {
            const campamentoInfo = await dispatch(
              fetchCampamento(otro.campamentoId)
            );
            return { ...otro, campamento: campamentoInfo.payload };
          })
        );
        setRows(updatedRows);
      } else {
        setRows([]);
      }
    };
    fetchData();

    if (count === 0) {
      setCount(1);
    }
    console.log("otros", otros);
  }, [otros]);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Gestión de otros tipos de recintos"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados con los recintos que no son pabellones ni wings en la plataforma."
        >
          {
            <OtherHousesIcon
              style={{ fontSize: "1.5rem" }}
              className={styles.iconBgDelete}
            />
          }
        </Header>

        <TableCard>
          <Create setOpen2={setOpen2} open2={open2} campamentos={campamentos} />
          {status == "loading" ? (
            <SkeletonTable />
          ) : (
            <Table otros={rows ? rows : []} setOpen3={setOpen3} />
          )}
        </TableCard>
      </Container>
    </Box>
  );
};
export default Otros;
