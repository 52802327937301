import styles from '../components.module.css';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useState } from 'react';
import { vistaPreviaColumns } from "../../../../../lib/columns";
import { Button, Grid, Typography } from '@mui/material';
import { toast } from 'react-toastify';

const VistaPrevia = ({ asignacionHabitacionesTrabajadores, reserva }) => {
    const [seleItem, setSeleItem] = useState();
    const [open, setOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const finalColumns = vistaPreviaColumns(setOpen, setSeleItem);

    const asignar = () => {
        toast.success('check');
    }

  return (
    <Grid container={true} spacing={2}>
        <Grid item xs={12} sm={3}>
            <Typography variant="span">Empresa: <strong>{reserva.nombre}</strong></Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
            <Typography variant="span">Contrato: <strong>{reserva.contrato}</strong></Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
            <Typography variant="span">Fecha de ingreso: <strong>{reserva.ingreso}</strong></Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
            <Typography variant="span">Fecha de salida: <strong>{reserva.salida}</strong></Typography>
        </Grid>
        <Grid item xs={12} sm={12} style={{ textAlign:'end' }}>
        <Button
            onClick={asignar}
            className={styles.bgColor}
            variant="contained"
        >
            Asignar
        </Button>
        </Grid>
        <Grid item xs={12} sm={12} mt={1}>
            <DataGrid
                getRowId={row=>row.id ? row.id : Math.random() }
                // checkboxSelection
                // onRowSelectionModelChange={handleRowSelectionChange}
                lots={{ toolbar: GridToolbar }}
                rows={asignacionHabitacionesTrabajadores}
                columns={finalColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 20]}
                localeText={{
                    ...esES.components.MuiDataGrid.defaultProps.localeText,
                    noRowsLabel: "No hay datos disponibles",
                    pagination: {
                        labelRowsPerPage: "Filas por página:",
                    }
                }}
                classes={{ menuIcon: styles.menuIcon }}
            />
        </Grid>
    </Grid>
  )
}

export default VistaPrevia