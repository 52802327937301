import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Backdrop, Button, CircularProgress, Container, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { deleteSector } from '../../../../redux/features/sectores/sectorSlice';
import { changeStatus } from '../../../../redux/features/reclamos_sugerencias/reclamos_sugerenciaSlice';

export default function Delete({ open, setOpen, seleItem, setOpen3, open3 }) {
    
    const [formData, setFormData] = useState({
      estado: ''
    });
    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const dispatch = useDispatch();
    
    useEffect(() => {
      if(seleItem){
        setFormData(seleItem)
        setFormData(prev => (
          {
            ...prev,
            estado: seleItem.estado,
            ot: seleItem.ot
          }
        ))
        console.log(seleItem)
      }
    }, [seleItem])

    // -------------- PREGUNTAR LOS ESTADOS DISPONIBLES PARA LOS RECLAMOS Y SUGERENCIAS ----------------
    const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

    const handleDelete = async () => {
      try {
        setOpen3(true);
        setAddRequestStatus('loading');
        const resp = await dispatch(changeStatus(formData))
      } catch (error) {
        console.error('Error al modificar el estado de la solicitud', error.message);
      } finally{
        setAddRequestStatus('idle');
        setOpen(false);
        setOpen3(false);
      }
    }

  return (
    <div>
      <Drawer
        anchor='bottom'
        open={open}
        onClose={() => setOpen(false)}
      >
        {seleItem && (
            <Box width={1} mt={0} style={{ backgroundColor:'#FFF', color:'var(--inst-card-bg)', padding:'16px' }}>
                <Container className={styles.containerDelete}>
                    <div className={styles.textContainer}>
                        <Typography variant="h6" className={styles.textIconDelete}><ErrorIcon/>Estás a punto de modificar el estado de la solicitud {seleItem.id}</Typography>
                        <Typography variant='span' className={styles.paragrap}><strong>¿Estas seguro de proceder?</strong></Typography>
                    </div>
                    <Grid container={true} spacing={1} mt={1} style={{ alignItems:'center', justifyContent:'center' }}>
                      <Grid item xs={12} sm={4}>
                        <label className={styles.label}>Modificar estado</label>
                        <Select
                          className={styles.textField}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                              },
                              '&:hover fieldset': {
                                border: 'none',
                              },
                              '&.Mui-focused fieldset': {
                                border: 'none',
                              },
                            },
                            '& .MuiInputBase-input': {
                              color: '#2d3037',
                            },
                            '& .MuiInputLabel-root': {
                              color: '#2d3037',
                            },
                          }}  
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData?.estado}
                          name='estado'
                          onChange={(e) => onChange(e)}
                          style={{ width:'100%' }}
                        >
                        <MenuItem value='Finalizada'>Finalizada</MenuItem>
                        <MenuItem value='En curso'>En curso</MenuItem>
                        <MenuItem value='Creada'>Creada</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item  xs={12} sm={4}>
                        <label className={styles.label}>OT (opcional)</label>
                        <TextField
                          className={styles.textField}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                // border: 'none',
                              },
                              '&:hover fieldset': {
                                // border: 'none',
                              },
                              '&.Mui-focused fieldset': {
                                // border: 'none',
                              },
                            },
                            '& .MuiInputBase-input': {
                              color: '#2d3037',
                            },
                            '& .MuiInputLabel-root': {
                              color: '#2d3037',
                            },
                          }}
                          variant="outlined"
                          fullWidth
                          name='ot'
                          value={formData?.ot}
                          type="text"
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                        <Grid item xs={12} sm={12} className={styles.buttonContainerDelete} style={{ margint:'10px' }}>
                            <Button
                                onClick={handleDelete}
                                className={styles.bgColorOrange}
                                variant="contained" 
                                >
                                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Modificar estado'}
                            </Button>
                            <Button 
                                onClick={()=>setOpen(false)}
                                className={styles.bgColorOutlined}
                                variant="outlined" 
                                >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>

                </Container>                
            </Box>
        )}
      </Drawer>
    </div>
  );
}