import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es'; // Importa la localización de moment en español
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment-timezone'; // Importa moment-timezone
import { addDays, format, parseISO } from 'date-fns';


const calendarStyles = {
  color: 'white',
  backgroundColor: 'var(--border-orange)',
  borderRadius: '0px',
  border: 'none',
};


const messages = {
  today: 'Hoy',
  previous: 'Anterior',
  next: 'Siguiente',
  month: 'Mes',
  week: 'Semana',
  day: 'Día',
  agenda: 'Agenda',
  date: 'Fecha',
  time: 'Hora',
  event: 'Evento',
};

const localizer = momentLocalizer(moment);

const Calendario = ({ events, defaultDate }) => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const mappedEvents = events.map(evento => {
    const fechaInicio = parseISO(evento.desde);
    const fechaTermino = addDays(parseISO(evento.hasta), 1);

    const mappedNames = evento.trabajadores.map(item => `(${item.rut}) ${item.nombre}`);
    const title = `${evento.horarioAseo.nombre}: ${mappedNames.join(', ')}`;

  return {
      title,
      start: fechaInicio,
      end: fechaTermino,
    };
  });

  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        fontSize: '12px', // Tamaño de la fuente deseado
        wordBreak: 'break-word',
      },
    };
  };

  return (
    <div style={{ height: '400px', width:'100%' }}>
    <style>{`
      .rbc-header {
        ${calendarStyles} 
      }
      .rbc-btn-group > button {
        ${calendarStyles}
      }
      .rbc-toolbar button {
        color: #373a3c;
        display: inline-block;
        margin: 0;
        text-align: center;
        vertical-align: middle;
        background: none;
        background-image: none;
        border: 1px solid #ccc;
        padding: 0.375rem 1rem;
        border-radius: 4px;
        line-height: normal;
        white-space: nowrap;
        color: white;
      }
        .rbc-today {
          background-color: var(--border-blue);
        }
        .rbc-off-range-bg {
          background-color: var(--textfield-bg);
        }
    `}</style>
      <Calendar
        localizer={localizer}
        events={mappedEvents}
        startAccessor="start"
        defaultDate={defaultDate}
        endAccessor="end"
        views={['month', 'week']}
        defaultView="month"
        messages={messages}
        eventPropGetter={(event, start, end, isSelected) => {
          let newStyle = {
            backgroundColor: "var(--border-orange)",
            color: 'white',
            borderRadius: "0px",
            border: "none",
            fontSize: '12px', // Tamaño de la fuente deseado
            wordBreak: 'break-word',
          };
      
          if (event.isMine) {
            newStyle.backgroundColor = "#174300";
          }
      
          return {
            className: "",
            style: newStyle
          };
        }}
      />
    </div>
  );
};

export default Calendario;
