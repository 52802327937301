import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  createAxiosFormDataInstance,
  createAxiosInstance,
} from "../../../api/axios";

const RESERVA_URL = "/api/v1/Solicitud";
const ROTATIVOS_URL = "/api/v1/Solicitud/ReemplazoRotativos";
// const ROTATIVOS_URL = "/api/v1/Solicitud/Rotativos";
const RESERVA_HUESPED_URL = "/api/v1/Huesped/info";
const TRABAJADORES_URL = "/api/v1/Solicitud/TrabajadoresSolicitud/";
const TRABAJADORES_ALL_URL = "/api/v1/Solicitud/Trabajadores";
const TRABAJADORES_P_INTERNO_URL = "/api/v1/Solicitud/TrabajadoresP_INTERNORotativos";
const ADD_RESERVA_URL = "/api/v1/Solicitud/P_EXTERNO";
const ASIGNAR_HABITACIONES_URL = "/api/v1/Solicitud/SolictudHabitacion";
const CHANGE_STATUS_URL = "/api/v1/Solicitud/CambioEstadoSolicitud";
const REASIGNAR_URL = "/api/v1/Solicitud/ReasignacionMasiva";
const REASIGNAR_P_INTERNO_URL = "/api/v1/Solicitud/GuardarSolicitudP_INTERNO";
const PERMANENTE_URL = "/api/v1/Solicitud/AsignarHabitacionPermanente";
const CANCELAR_SOLICITUD = "/api/v1/Solicitud/CancelarSolicitud";
const GENERAR_PROPUESTA_URL = "/api/v1/Solicitud/GenerarPropuesta";
const ASIGNAR_HABITACIONES = "/api/v1/Solicitud/ModificarAsignacion/";
const RESERVAS_P_INTERNO = "/api/v1/Solicitud/ReservasP_INTERNO";
const ROTATIVOS_P_INTERNO = "/api/v1/Solicitud/GuardarSolicitudP_INTERNO";
const HABITACIONES_P_INTERNO = "/api/v1/Solicitud/HabitacionesRotativos";
const HABITACIONES_P_INTERNO_DISPONIBLES =
  "/api/v1/Solicitud/HabitacionesRotativos";
const CARGOS_URL = "/api/v1/Solicitud/Cargos";
const TRABAJADORES_REASIGNACION = "/api/v1/Solicitud/TrabajadoresAsignados";
const TRABAJADORES_REASIGNACION_P_INTERNO =
  "/api/v1/Solicitud/TrabajadoresAsignadosP_INTERNO";
const CLEAR_PROPUESTA_URL = "/api/v1/Solicitud/ClearPropuesta/";
const REENVIAR_CLAVE_CHAPA_HABITACION_URL = "/api/v1/Solicitud/ReSendEmail";
const MONITOREO_URL = "/api/v1/Solicitud/MonitoreoP_INTERNO";

const axiosInstance = createAxiosInstance();
const axiosFormDataInstance = createAxiosFormDataInstance();

// ACTIONS
export const fetchTrabajadoresReasignacion = createAsyncThunk(
  "habitaciones/fetchTrabajadoresReasignacion",
  async (data) => {
    console.log("llega", data);
    const { pabellonId, wingId, piso, fechaIngreso } = data;

    try {
      let url = TRABAJADORES_REASIGNACION + "?";

      if (wingId) {
        url += `WingId=${wingId}`;
      } else {
        url += `PabellonId=${pabellonId}`;
      }

      url += `&Piso=${piso}&FechaIngreso=${fechaIngreso}`;

      const response = await axiosInstance.get(url);
      return response.data;
    } catch (e) {
      toast.error("Error al cargar los trabajadores");
      return e.message;
    }
  }
);
export const fetchTrabajadoresReasignacionP_INTERNO = createAsyncThunk(
  "habitaciones/fetchTrabajadoresReasignacion",
  async (data) => {
    console.log("llega", data);
    const { pabellonId, wingId, piso, fechaIngreso } = data;

    try {
      let url = TRABAJADORES_REASIGNACION_P_INTERNO + "?";

      if (wingId) {
        url += `WingId=${wingId}`;
      } else {
        url += `PabellonId=${pabellonId}`;
      }

      url += `&Piso=${piso}&FechaIngreso=${fechaIngreso}`;

      const response = await axiosInstance.get(url);
      return response.data;
    } catch (e) {
      toast.error("Error al cargar los trabajadores");
      return e.message;
    }
  }
);
export const fetchReservasP_INTERNO = createAsyncThunk(
  "habitaciones/fetchReservasP_INTERNO",
  async (data) => {
    try {
      const response = await axiosInstance.get(RESERVAS_P_INTERNO);
      return response.data;
    } catch (e) {
      toast.error("Error al cargar las reservas");
      return e.message;
    }
  }
);
export const fetchHabitacionesP_INTERNO = createAsyncThunk(
  "habitaciones/fetchHabitacionesP_INTERNO",
  async (data) => {
    try {
      const response = await axiosInstance.get(HABITACIONES_P_INTERNO);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const fetchReservasHuesped = createAsyncThunk(
  "reservas/fetchReservasHuesped",
  async () => {
    try {
      const response = await axiosInstance.get(RESERVA_HUESPED_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const fetchMonitoreo = createAsyncThunk(
  "reservas/fetchMonitoreo",
  async () => {
    try {
      const response = await axiosInstance.get(MONITOREO_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const fetchCargos = createAsyncThunk(
  "reservas/fetchCargosReservas",
  async () => {
    try {
      const response = await axiosInstance.get(CARGOS_URL);
      return response.data;
    } catch (e) {
      toast.error("Error al cargar los cargos");
      return e.message;
    }
  }
);
export const fetchReservas = createAsyncThunk(
  "reservas/fetchReservas",
  async () => {
    try {
      const response = await axiosInstance.get(RESERVA_URL);
      return response.data;
    } catch (e) {
      toast.error("Error al cargar las reservas");
      return e.message;
    }
  }
);
export const fetchRotativos = createAsyncThunk(
  "reservas/fetchRotativos",
  async () => {
    try {
      const response = await axiosInstance.get(ROTATIVOS_URL);
      return response.data;
    } catch (e) {
      toast.error("Error al cargar los turnos rotativos");
      return e.message;
    }
  }
);
export const fetchHabitacionesP_INTERNODisponibles = createAsyncThunk(
  "reservas/fetchHabitacionesP_INTERNODisponibles",
  async () => {
    try {
      const response = await axiosInstance.get(HABITACIONES_P_INTERNO_DISPONIBLES);
      return response.data;
    } catch (e) {
      toast.error("Error al cargar las habitaciones disponibles de P_INTERNO");
      return e.message;
    }
  }
);
export const fetchTrabajadoresP_INTERNO = createAsyncThunk(
  "reservas/fetchTrabajadoresP_INTERNO",
  async () => {
    try {
      const response = await axiosInstance.get(TRABAJADORES_P_INTERNO_URL);
      console.log("trabajadores", response);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const reenviarClaveHabitacion = createAsyncThunk(
  "reservas/reenviarClaveHabitacion",
  async (data, { dispatch }) => {
    console.log("enviando:", data);
    try {
      const response = await axiosInstance.post(
        REENVIAR_CLAVE_CHAPA_HABITACION_URL +
          "?rut=" +
          data.rut +
          "?fechaIngreso=" +
          data.fechaIngreso +
          "?fechaSalida=" +
          data.fechaSalida
      );
      if (response.status >= 200 && response.status < 300) {
        toast.success("Correo enviado.");
        // dispatch(fetchOficinas());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al enviar el correo: " + e.response.data);
      return { message: e.message, status: "error" };
    }
  }
);
export const changeRotativos = createAsyncThunk(
  "reservas/changeRotativos",
  async (data, { dispatch }) => {
    console.log("lo que llega", data);
    try {
      const mappedData = {
        rut: data.rut,
        fechaInicio: data.fechaInicio,
        fechaTermino: data.fechaTermino,
        habitacionId: data.habitacionId,
        turno: data.turno,
      };
      console.log("mappedData", mappedData);
      const response = await axiosInstance.post(ROTATIVOS_P_INTERNO, mappedData);
      console.log("resp en slice", response);

      if (response.status == 200) {
        toast.success("Trabajador asignado a la habitación.");
        dispatch(fetchRotativos);
        dispatch(fetchHabitacionesP_INTERNO);
        return { data: response.data, status: "success" };
      }
      //   return { status: "error" };
    } catch (e) {
      toast.error("Error al asignar el trabajador a la habitación.");
      return { message: e.message, status: "error" };
    }
  }
);
export const fetchTrabajadores = createAsyncThunk(
  "reservas/fetchTrabajadores",
  async (id) => {
    try {
      const response = await axiosInstance.get(TRABAJADORES_URL + id);
      console.log("trabajadores", response);
      return response.data;
    } catch (e) {
      toast.error("Error al cargar los trabajadores");
      return e.message;
    }
  }
);
export const fetchTrabajadoresAll = createAsyncThunk(
  "reservas/fetchTrabajadoresAll",
  async ({ contrato, rut, nombre }) => {
    try {
      const params = new URLSearchParams();

      if (contrato) params.append("contrato", contrato);
      if (rut) params.append("rut", rut);
      if (nombre) params.append("nombre", nombre);

      const response = await axiosInstance.get(
        `${TRABAJADORES_ALL_URL}?${params.toString()}`
      );
      console.log("trabajadores", response);
      return response.data;
    } catch (e) {
      toast.error("Error al cargar los trabajadores");
      return e.message;
    }
  }
);
export const generarPropuesta = createAsyncThunk(
  "reservas/generarPropuesta",
  async (data, { dispatch }) => {
    console.log("data", data);
    // return;
    try {
      const resp = await axiosInstance.post(
        GENERAR_PROPUESTA_URL +
          "?IdSolicitud=" +
          data.id +
          "&Usar2x1=" +
          data.usarDosxUno
      );
      // toast.warning(resp.data.message);
      if (resp.status == 200) {
        toast.success("Propuesta generada exitosamente.");
        dispatch(fetchReservas());
        return { data: resp.data, status: "success" };
      }
      return resp.data;
    } catch (e) {
      toast.error("Error al generar la propuesta");
      return { message: e.message, status: "error" };
    }
  }
);
export const clearPropuesta = createAsyncThunk(
  "reservas/generarPropuesta",
  async (id, { dispatch }) => {
    try {
      const resp = await axiosInstance.post(CLEAR_PROPUESTA_URL + id);
      // toast.warning(resp.data.message);
      if (resp.status == 200) {
        // toast.success('Propuesta generada exitosamente.');
        dispatch(fetchReservas());
        return { data: resp.data, status: "success" };
      }
      return resp.data;
    } catch (e) {
      toast.error("Error al deshacer la propuesta");
      return { message: e.message, status: "error" };
    }
  }
);
export const addNewReserva = createAsyncThunk(
  "reservas/createReservas",
  async (initialReserva) => {
    console.log("lo que llega", initialReserva);
    const formatISODate = (dateString) => {
      const parsedDate = Date.parse(dateString);

      if (!isNaN(parsedDate)) {
        const date = new Date(parsedDate);
        return date.toISOString().split("T")[0]; // Retorna 'yyyy-MM-dd'
      }

      return dateString; // Retorna la cadena original si no se puede parsear
    };

    const formData = new FormData();
    const mappedTrabajadoresExternoTrue = initialReserva.trabajadores
      .filter(
        (item) =>
          item.externo === true ||
          item.externo == null ||
          item.externo == undefined
      )
      .map((item) => ({
        edad: parseInt(item.edad),
        fechaSalida: formatISODate(item.salida),
        tipoCargo: item.tipo_cargo,
        nombres: item.nombres ?? item.nombre,
        turnoId: item.turno.idTurno, // arreglar el turno que no esta llegando para los externos
        direccion: item.direccion,
        rut: item.rut,
        genero: item.genero,
        fechaIngreso: formatISODate(item.llegada),
        telefono: item.telefono,
        apellidos: item.apellidos ?? item.apellido,
        cargoId: item.cargo.idCargo ?? item.cargo,
        email:
          item.email ??
          `${item.rut.replace(/\./g, "").split("-")[0]}@noemail.cl`,
        jornada: item.jornada,
      }));

    const mappedTrabajadoresExternoFalse = initialReserva.trabajadores
      .filter((item) => item.externo === false)
      .map((item) => ({
        trabajadorP_EXTERNOId: item.trabajadorP_EXTERNOId,
        email:
          item.email ??
          `${item.rut.replace(/\./g, "").split("-")[0]}@noemail.cl`,
        rut: item.rut,
        genero: item.genero,
        turnoId: item.turnoId,
        fechaIngreso: formatISODate(item.llegada),
        fechaSalida: formatISODate(item.salida),
        jornada: item.jornada,
        tipoCargo: item.tipo_cargo,
      }));

    try {
      formData.append("EmpresaId", initialReserva.empresa);
      formData.append("Gerencia", initialReserva.gerencia);
      formData.append("Vicepresidencia", initialReserva.vicepresidencia);
      formData.append("SI_Area", initialReserva.siArea);
      formData.append("Contrato", initialReserva.contrato);
      formData.append(
        "TrabajadoresTotales",
        initialReserva.trabajadoresTotales
      );
      formData.append("TrabajadoresDia", 0);
      formData.append("TrabajadoresNoche", 0);
      formData.append(
        "FechaIngreso",
        formatISODate(initialReserva.fechaIngreso)
      );
      formData.append("FechaSalida", formatISODate(initialReserva.fechaSalida));
      formData.append("Observaciones", initialReserva.descripcion);
      const trabajadores = JSON.stringify(mappedTrabajadoresExternoFalse);
      const trabajadoresExternos = JSON.stringify(
        mappedTrabajadoresExternoTrue
      );
      formData.append("Trabajadores", trabajadores);
      formData.append("TrabajadoresExternos", trabajadoresExternos);

      console.log("trabajadores", trabajadores);
      console.log("trabajadoresExternos", trabajadoresExternos);

      const response = await axiosFormDataInstance.post(
        ADD_RESERVA_URL,
        formData
      );
      if (response.status == 200) {
        toast.success("Reserva creada correctamente.");
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al crear la reserva: " + e.response.data);
      return { message: e.message, status: "error" };
    }
  }
);
export const asignarHabitacionesRecepcionista = createAsyncThunk(
  "reservas/asignarHabitaciones",
  async (data, { dispatch }) => {
    console.log("lo que llega", data);
    // return;
    // const idSolicitud = data.asignaciones[0].idSolicitud;
    const idSolicitud = data[0].idSolicitud;
    const mapped = data.map((item) => ({
      rut: item.rut,
      // idHabitacion: item.habitacion.id
      idHabitacion: item.idHabitacion,
    }));
    console.log("mapped", mapped);
    try {
      const response = await axiosInstance.post(
        ASIGNAR_HABITACIONES + idSolicitud,
        mapped
      );

      if (response.status == 200) {
        toast.success("Habitaciones asignadas correctamente.");
        dispatch(fetchReservas());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al asignar las habitaciones: " + e.response.data);
      return { message: e.message, status: "error" };
    }
  }
);
export const addReasignar = createAsyncThunk(
  "reservas/addReasignar",
  async (data, { dispatch }) => {
    console.log(data);

    try {
      const response = await axiosInstance.post(REASIGNAR_URL, data);
      console.log("response: ", response);
      if (response?.status === 200) {
        toast.success("Reasignación exitosa.");
        dispatch(fetchReservas());
        return { data: response.data, status: "success" };
      } else {
        toast.error("Error: Respuesta inesperada del servidor.");
        return { status: "error" };
      }
    } catch (e) {
      toast.error(`Error al actualizar el estado de la reserva`);
      console.error("Error en la reasignación:", e);
      return { message: e, status: "error" };
    }
  }
);
export const modificarReservaP_interno = createAsyncThunk(
  "reservas/modificarReservaP_interno",
  async (data, { dispatch }) => {
    console.log(data);

    try {
      const response = await axiosInstance.post(REASIGNAR_P_INTERNO_URL, data);
      console.log("response: ", response);
      if (response?.status === 200) {
        toast.success("Reasignación exitosa.");
        dispatch(fetchReservasP_INTERNO());
        dispatch(fetchHabitacionesP_INTERNO());
        return { data: response.data, status: "success" };
      } else {
        toast.error("Error: Respuesta inesperada del servidor.");
        return { status: "error" };
      }
    } catch (e) {
      toast.error(`Error al actualizar el estado de la reserva`);
      console.error("Error en la reasignación:", e);
      return { message: e, status: "error" };
    }
  }
);
export const removerTrabajadorP_INTERNOdeListado = createAsyncThunk(
  "reservas/removerTrabajadorP_INTERNOdeListado",
  async (id, { dispatch }) => {
    console.log(id);
    try {
      const response = await axiosInstance.post(
        "api/v1/Solicitud/EliminarMonitoreoP_INTERNO/" + id
      );

      if (response.status == 200) {
        // toast.success("Solicitud cancelada correctamente.");
        // dispatch(fetchReservas());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error(
        "Error al remover al trabajador del listado: " + e.response.data
      );
      return { message: e.message, status: "error" };
    }
  }
);
export const removerTrabajadorP_INTERNOdeListado2 = createAsyncThunk(
  "reservas/removerTrabajadorP_INTERNOdeListado",
  async (id, { dispatch }) => {
    console.log(id);
    try {
      const response = await axiosInstance.post(
        "api/v1/Solicitud/EliminarMonitoreoP_INTERNO/" + id
      );

      if (response.status == 200) {
        toast.success("Trabajador removido del listado.");
        dispatch(fetchMonitoreo());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error(
        "Error al remover al trabajador del listado: " + e.response.data
      );
      return { message: e.message, status: "error" };
    }
  }
);
export const asignarHabitacionDisponibleP_INTERNO = createAsyncThunk(
  "reservas/modificarReservaP_interno",
  async (data, { dispatch }) => {
    console.log(data);
    try {
      const newData = {
        rut: data.rut,
        habitacionId: data.habitacionId,
        turno: data.turno,
        fechaInicio: data.fechaInicio,
        fechaTermino: data.fechaTermino,
      };
      const response = await axiosInstance.post(REASIGNAR_P_INTERNO_URL, newData);
      console.log("response: ", response);
      if (response?.status === 200) {
        dispatch(removerTrabajadorP_INTERNOdeListado(data.id));
        dispatch(fetchMonitoreo());
        dispatch(fetchHabitacionesP_INTERNODisponibles());

        toast.success(
          "Registro actualizado y removido del listado exitosamente."
        );
        return { data: response.data, status: "success" };
      } else {
        toast.error("Error: Respuesta inesperada del servidor.");
        return { status: "error" };
      }
    } catch (e) {
      toast.error(`Error al actualizar el estado de la reserva`);
      console.error("Error en la reasignación:", e);
      return { message: e, status: "error" };
    }
  }
);
export const asignarHabitacionPermanente = createAsyncThunk(
  "reservas/asignarHabitacionPermanente",
  async (data, { dispatch }) => {
    console.log(data);

    try {
      const response = await axiosInstance.post(
        PERMANENTE_URL +
          "?rut=" +
          data.rut +
          "&HabitacionId=" +
          data.habitacionId
      );
      console.log("response: ", response);
      if (response?.status === 200) {
        toast.success("Registro actualizado correctamente.");
        dispatch(fetchReservasP_INTERNO());
        dispatch(fetchHabitacionesP_INTERNO());
        return { data: response.data, status: "success" };
      } else {
        toast.error("Error: Respuesta inesperada del servidor.");
        return { status: "error" };
      }
    } catch (e) {
      toast.error(`Error al actualizar el estado de la habitación.`);
      console.error("Error en la reasignación:", e);
      return { message: e, status: "error" };
    }
  }
);
export const rechazarSolicitud = createAsyncThunk(
  "reservas/rechazarSolicitud",
  async (initialReserva, { dispatch }) => {
    console.log("lo que llega");

    try {
      const response = await axiosInstance.post(CHANGE_STATUS_URL, {
        id: initialReserva.id,
        // estado: initialReserva.estado,
        estado: "Rechazada",
        RazonRechazo: initialReserva.razonRechazo,
      });

      if (response.status == 200) {
        toast.success(`Solicitud rechazada correctamente.`);
        dispatch(fetchReservas());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error(
        "Error al actualizar el estado de la reserva: " + e.response.data
      );
      return { message: e.message, status: "error" };
    }
  }
);
export const cancelarSolicitud = createAsyncThunk(
  "reservas/cancelarSolicitud",
  async (initialReserva, { dispatch }) => {
    console.log(initialReserva);
    try {
      const response = await axiosInstance.post(CANCELAR_SOLICITUD, {
        id: initialReserva.id,
        razon: initialReserva.razonRechazo,
      });

      if (response.status == 200) {
        toast.success("Solicitud cancelada correctamente.");
        dispatch(fetchReservas());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al rechazar la reserva: " + e.response.data);
      return { message: e.message, status: "error" };
    }
  }
);
export const pendienteSolicitud = createAsyncThunk(
  "reservas/pendienteSolicitud",
  async (initialReserva, { dispatch }) => {
    console.log("lo que llega");

    try {
      const response = await axiosInstance.post(CHANGE_STATUS_URL, {
        id: initialReserva.id,
        estado: "Pendiente",
        // RazonRechazo: initialReserva.razonRechazo,
      });

      if (response.status == 200) {
        toast.success(`Estado de la solicitud modificado correctamente.`);
        dispatch(fetchReservas());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error(
        "Error al actualizar el estado de la reserva: " + e.response.data
      );
      return { message: e.message, status: "error" };
    }
  }
);
export const aprobarSolicitud = createAsyncThunk(
  "reservas/aprobarSolicitud",
  async (initialReserva, { dispatch }) => {
    console.log("lo que llega");

    try {
      const response = await axiosInstance.post(CHANGE_STATUS_URL, {
        id: initialReserva.id,
        estado: "Aprobada",
        RazonRechazo: initialReserva.razonRechazo,
      });

      if (response.status == 200) {
        toast.success(`Solicitud aprobada correctamente.`);
        dispatch(fetchReservas());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error(
        "Error al actualizar el estado de la reserva: " + e.response.data
      );
      return { message: e.message, status: "error" };
    }
  }
);
export const asignarHabitaciones = createAsyncThunk(
  "reservas/asignarHabitaciones",
  async (initialReserva, { dispatch }) => {
    console.log(initialReserva);
    try {
      const response = await axiosInstance.post(ASIGNAR_HABITACIONES_URL, {
        id: initialReserva.id,
        razon: initialReserva.razonRechazo,
      });

      if (response.status == 200) {
        toast.success("Solicitud cancelada correctamente.");
        dispatch(fetchReservas());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al cancelar la reserva: " + e.response.data);
      return { message: e.message, status: "error" };
    }
  }
);

// idle : no hay operaciones en curso
const initialState = {
  monitoreo: [],
  reservas: [],
  reservasP_INTERNO: [],
  habitacionesP_INTERNO: [],
  habitacionesP_INTERNODisponibles: [],
  rotativos: [],
  propuesta: [],
  cargos: [],
  asignaciones: [],
  reservasHuesped: [],
  trabajadores: [],
  trabajadoresP_INTERNO: [],
  trabajadoresP_INTERNOStatus: [],
  trabajadoresAll: [],
  status: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusMonitoreo: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusReservasP_INTERNO: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusHabitacionesP_INTERNO: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusHabitacionesP_INTERNODisponibles: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusRotativos: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusP_INTERNO: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusAsignaciones: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusPropuesta: "idle", //'idle'|'loading'|'succeeded'|'failed'
  trabajadoresStatus: "idle", //'idle'|'loading'|'succeeded'|'failed'
  trabajadoresAllStatus: "idle", //'idle'|'loading'|'succeeded'|'failed'
  cargosStatus: "idle", //'idle'|'loading'|'succeeded'|'failed'
  error: null,
};

export const reservaSlice = createSlice({
  name: "reserva",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCargos.pending, (state, action) => {
        state.cargosStatus = "loading";
      }) //fetch
      .addCase(fetchCargos.fulfilled, (state, action) => {
        state.cargosStatus = "succeeded";
        if (
          JSON.stringify(state.cargosStatus) !== JSON.stringify(action.payload)
        ) {
          //fetch
          state.cargos = action.payload;
        }
      })
      .addCase(fetchCargos.rejected, (state, action) => {
        //fetch
        state.cargosStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchMonitoreo.pending, (state, action) => {
        state.statusMonitoreo = "loading";
      }) //fetch
      .addCase(fetchMonitoreo.fulfilled, (state, action) => {
        state.statusMonitoreo = "succeeded";
        if (
          JSON.stringify(state.statusMonitoreo) !==
          JSON.stringify(action.payload)
        ) {
          //fetch
          state.monitoreo = action.payload;
        }
      })
      .addCase(fetchMonitoreo.rejected, (state, action) => {
        //fetch
        state.statusMonitoreo = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchReservas.pending, (state, action) => {
        state.status = "loading";
      }) //fetch
      .addCase(fetchReservas.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (JSON.stringify(state.reservas) !== JSON.stringify(action.payload)) {
          //fetch
          state.reservas = action.payload;
        }
      })
      .addCase(fetchReservas.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchHabitacionesP_INTERNO.pending, (state, action) => {
        state.statusHabitacionesP_INTERNO = "loading";
      }) //fetch
      .addCase(fetchHabitacionesP_INTERNO.fulfilled, (state, action) => {
        state.statusHabitacionesP_INTERNO = "succeeded";
        if (
          JSON.stringify(state.habitacionesP_INTERNO) !==
          JSON.stringify(action.payload)
        ) {
          //fetch
          state.habitacionesP_INTERNO = action.payload;
        }
      })
      .addCase(fetchHabitacionesP_INTERNO.rejected, (state, action) => {
        //fetch
        state.statusHabitacionesP_INTERNO = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchHabitacionesP_INTERNODisponibles.pending, (state, action) => {
        state.statusHabitacionesP_INTERNODisponibles = "loading";
      }) //fetch
      .addCase(fetchHabitacionesP_INTERNODisponibles.fulfilled, (state, action) => {
        state.statusHabitacionesP_INTERNODisponibles = "succeeded";
        if (
          JSON.stringify(state.habitacionesP_INTERNODisponibles) !==
          JSON.stringify(action.payload)
        ) {
          //fetch
          state.habitacionesP_INTERNODisponibles = action.payload;
        }
      })
      .addCase(fetchHabitacionesP_INTERNODisponibles.rejected, (state, action) => {
        //fetch
        state.statusHabitacionesP_INTERNODisponibles = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchReservasP_INTERNO.pending, (state, action) => {
        state.statusReservasP_INTERNO = "loading";
      }) //fetch
      .addCase(fetchReservasP_INTERNO.fulfilled, (state, action) => {
        state.statusReservasP_INTERNO = "succeeded";
        if (
          JSON.stringify(state.reservasP_INTERNO) !== JSON.stringify(action.payload)
        ) {
          //fetch
          state.reservasP_INTERNO = action.payload;
        }
      })
      .addCase(fetchReservasP_INTERNO.rejected, (state, action) => {
        //fetch
        state.statusReservasP_INTERNO = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchRotativos.pending, (state, action) => {
        state.statusRotativos = "loading";
      }) //fetch
      .addCase(fetchRotativos.fulfilled, (state, action) => {
        state.statusRotativos = "succeeded";
        if (JSON.stringify(state.reservas) !== JSON.stringify(action.payload)) {
          //fetch
          state.rotativos = action.payload;
        }
      })
      .addCase(fetchRotativos.rejected, (state, action) => {
        //fetch
        state.statusRotativos = "failed";
        state.error = action.error.message;
      })
      .addCase(generarPropuesta.pending, (state, action) => {
        state.statusPropuesta = "loading";
      }) //fetch
      .addCase(generarPropuesta.fulfilled, (state, action) => {
        state.statusPropuesta = "succeeded";
        if (JSON.stringify(state.reservas) !== JSON.stringify(action.payload)) {
          //fetch
          state.propuesta = action.payload;
        }
      })
      .addCase(generarPropuesta.rejected, (state, action) => {
        //fetch
        state.statusPropuesta = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchReservasHuesped.pending, (state, action) => {
        state.status = "loading";
      }) //fetch
      .addCase(fetchReservasHuesped.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (
          JSON.stringify(state.reservasHuesped) !==
          JSON.stringify(action.payload)
        ) {
          //fetch
          state.reservasHuesped = action.payload;
        }
      })
      .addCase(fetchReservasHuesped.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTrabajadores.pending, (state, action) => {
        state.trabajadoresStatus = "loading";
      }) //fetch
      .addCase(fetchTrabajadores.fulfilled, (state, action) => {
        state.trabajadoresStatus = "succeeded";
        if (
          JSON.stringify(state.trabajadores) !== JSON.stringify(action.payload)
        ) {
          //fetch
          state.trabajadores = action.payload;
        }
      })
      .addCase(fetchTrabajadores.rejected, (state, action) => {
        //fetch
        state.trabajadoresStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTrabajadoresP_INTERNO.pending, (state, action) => {
        state.trabajadoresP_INTERNOStatus = "loading";
      }) //fetch
      .addCase(fetchTrabajadoresP_INTERNO.fulfilled, (state, action) => {
        state.trabajadoresP_INTERNOStatus = "succeeded";
        if (
          JSON.stringify(state.trabajadoresP_INTERNO) !==
          JSON.stringify(action.payload)
        ) {
          //fetch
          state.trabajadoresP_INTERNO = action.payload;
        }
      })
      .addCase(fetchTrabajadoresP_INTERNO.rejected, (state, action) => {
        //fetch
        state.trabajadoresP_INTERNOStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTrabajadoresAll.pending, (state, action) => {
        state.trabajadoresAllStatus = "loading";
      }) //fetch
      .addCase(fetchTrabajadoresAll.fulfilled, (state, action) => {
        state.trabajadoresAllStatus = "succeeded";
        if (
          JSON.stringify(state.trabajadoresAll) !==
          JSON.stringify(action.payload)
        ) {
          //fetch
          state.trabajadoresAll = action.payload;
        }
      })
      .addCase(fetchTrabajadoresAll.rejected, (state, action) => {
        //fetch
        state.trabajadoresAllStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewReserva.fulfilled, (state, action) => {
        //ADD
        if (action.payload.status === "success") {
          state.status = "succeeded";
          state.reservas.push(action.payload);
        } else {
          state.status = "failed";
        }
      })
      .addCase(asignarHabitacionesRecepcionista.fulfilled, (state, action) => {
        //ADD
        if (action.payload.statusAsignaciones === "success") {
          state.statusAsignaciones = "succeeded";
          state.asignaciones.push(action.payload);
        } else {
          state.statusAsignaciones = "failed";
        }
      })
      .addCase(rechazarSolicitud.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addReasignar.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getAllreservas = (state) => state.reservas.reservas;
export const getMonitoreo = (state) => state.reservas.monitoreo;
export const getgetMonitoreoStatus = (state) => state.reservas.statusMonitoreo;
export const getAllreservasP_INTERNO = (state) => state.reservas.reservasP_INTERNO;
export const getReservasP_INTERNOStatus = (state) =>
  state.reservas.statusReservasP_INTERNO;
export const getAllHabitacionesP_INTERNO = (state) =>
  state.reservas.habitacionesP_INTERNO;
export const getHabitacionesP_INTERNOStatus = (state) =>
  state.reservas.statusHabitacionesP_INTERNO;
export const getAllHabitacionesP_INTERNODisponibles = (state) =>
  state.reservas.habitacionesP_INTERNODisponibles;
export const getHabitacionesP_INTERNODisponiblesStatus = (state) =>
  state.reservas.statusHabitacionesP_INTERNODisponiblesn;
export const getAllRotativos = (state) => state.reservas.rotativos;
export const getRotativosStatus = (state) => state.reservas.statusRotativos;
export const getAllCargos = (state) => state.reservas.cargos;
export const getCargosStatus = (state) => state.reservas.cargosStatus;
export const getAllAsignaciones = (state) => state.reservas.asiganciones;
export const getAsignacionesStatus = (state) =>
  state.reservas.statusAsignaciones;
export const getPropuesta = (state) => state.reservas.propuesta;
export const getAllReservasHuesped = (state) => state.reservas.reservasHuesped;
export const getAllTrabajadores = (state) => state.reservas.trabajadores;
export const getAllTrabajadoresP_INTERNO = (state) =>
  state.reservas.trabajadoresP_INTERNO;
export const getTrabajadoresP_INTERNOStatus = (state) =>
  state.reservas.trabajadoresP_INTERNOStatus;
export const getTrabajadoresStatus = (state) =>
  state.reservas.trabajadoresStatus;
export const getTrabajadoresAll = (state) => state.reservas.trabajadoresAll;
export const getTrabajadoresAllStatus = (state) =>
  state.reservas.trabajadoresAllStatus;
export const getReservasStatus = (state) => state.reservas.status;
export const getReservasError = (state) => state.reservas.error;

export const {} = reservaSlice.actions;
export default reservaSlice.reducer;
