import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Autocomplete, Backdrop, Button, Checkbox, CircularProgress, Container, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCampamentos } from '../../../../redux/features/campamentos/campamentoSlice';
import { useEffect } from 'react';
import { updateSector } from '../../../../redux/features/sectores/sectorSlice';

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3, registros }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState(null);
    const dispatch = useDispatch();
    const campamentos = useSelector(getAllCampamentos);
    const [ocupado, setOcupado] = useState(false);
    const [ultimoNumero, setUltimoNumero] = useState(0);

    useEffect(() => {
            setFormData(seleItem)
            setOcupado(!formData?.ocupado)
    }, [seleItem])
    
    // useEffect(() => {
    //     if(registros){
    //       setUltimoNumero(registros[registros.length - 1].numero)
    //     }
    // }, [registros])
      
    
    const handleChangeCheckbox = () => {
        setOcupado(!ocupado);
        setFormData({...formData, ocupado: !ocupado });
    };

    const handleChangeCampamento = (e, value) => setFormData({ ...formData, campamento: value ? value.id : null });
        
    const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

    const canSave = [ formData?.nombre, formData?.campamento, formData?.numero ].every(Boolean) && addRequestStatus === 'idle';

    const handleEdit = async () => {
        try {
            setOpen3(true);
            setAddRequestStatus('loading');
            // let resp = await dispatch(updateSector(formData))
            // if(resp.payload.status === 'success'){
            //     setOpen3(false);
            // }
        } catch (error) {
            console.error('Error al actualizar el espacio', error.message);
        } finally{
            setAddRequestStatus('idle');
            setOpenEdit(false);
        }
    }

  return (
    <div>
      <Drawer
        anchor='right'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        {seleItem && (
            <Box width={1} mt={0} mb={2}>
                <Container >
                {/*{JSON.stringify(formData)} */}
                    <Grid container={true} spacing={1} p={2}>
                        <Grid item  xs={12} sm={12} mb={2}>
                            <Typography variant="h6" className={styles.textIcon}><ErrorIcon/>Estás a punto de actualizar el espacio N°{seleItem?.numero}</Typography>
                        </Grid>
                        <Grid item  xs={12} sm={12}>
                            <label className={styles.label}>Número</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='numero'
                                type="number"
                                value={formData?.numero}
                                onChange={(e) => onChange(e)}
                            />
                            <label className={styles.label} style={{ fontSize:'12px' }}>Ultimo número ingresado: <strong>{ultimoNumero}</strong></label>
                            </Grid>
                            <Grid item  xs={12} sm={12} >
                            <label className={styles.label}>Campamento</label>
                            <Autocomplete
                                disablePortal
                                id="campamentos-autocomplete"
                                options={campamentos}
                                name="idCampamento"
                                value={formData?.campamento}
                                getOptionLabel={(option) => option.nombre}
                                onChange={(e, value)=> handleChangeCampamento(e, value)}
                                renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
                            />
                            </Grid>
                            <Grid item  xs={12} sm={12} >
                            <label className={styles.label}>¿El espacio está ocupado?</label>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={ocupado} onChange={handleChangeCheckbox} />} label="Si" />
                                    <FormControlLabel control={<Checkbox checked={!ocupado} onChange={handleChangeCheckbox} />} label="No" />
                                </FormGroup>
                            </Grid>
                        <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                            <Button 
                                onClick={handleEdit}
                                className={styles.bgColor}
                                variant="contained" 
                                disabled={!canSave}
                                >
                                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                            </Button>
                            <Button 
                                onClick={()=>setOpenEdit(false)}
                                className={styles.bgColorOutlined}
                                variant="outlined" 
                                >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        )}
      </Drawer>
    </div>
  );
}