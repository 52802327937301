import styles from "./components.module.css";
import { Card, Typography, CircularProgress } from "@mui/material";

const OficinaAsignadaHuesped = ({ reservas, status }) => {
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  };

  if (status === "loading") {
    return (
      <Card
        className={[styles.card, styles.colorBlack]}
        style={{
          backgroundColor: "var(--inst-card-bg)",
          padding: "16px",
          color: "#FFF",
          margin: "10px 0",
          border: "1px solid var(--border-orange)",
          textAlign: "center",
        }}
      >
        <Typography
          variant="p"
          style={{ display: "block", fontSize: "14px", fontWeight: "400" }}
        >
          <CircularProgress color="inherit" size={24} />
        </Typography>
      </Card>
    );
  }

  if (!reservas || reservas.length === 0) {
    return null;
  }

  const reserva = reservas[0];

  return (
    <Card
      className={[styles.card, styles.colorBlack]}
      style={{
        backgroundColor: "var(--inst-card-bg)",
        padding: "16px",
        color: "#FFF",
        margin: "10px 0",
        border: "none",
      }}
    >
      <Typography variant="p" style={{ display: "block", fontSize: "12px" }}>
        Oficina asignada:
      </Typography>
      <Typography variant="h7" style={{ marginLeft: "5px", display: "block" }}>
        <strong>Desde</strong>: {formatDate(reserva.fechaInicio)}
      </Typography>
      <Typography variant="h7" style={{ marginLeft: "5px", display: "block" }}>
        <strong>Hasta</strong>: {formatDate(reserva.fechaTermino)}
      </Typography>
      <Typography variant="h7" style={{ marginLeft: "5px", display: "block" }}>
        <strong>Numero</strong>: {reserva.oficina.numero}
      </Typography>
      <Typography variant="h7" style={{ marginLeft: "5px", display: "block" }}>
        <strong>Piso</strong>: {reserva.oficina.piso}
      </Typography>
      <Typography variant="h7" style={{ marginLeft: "5px", display: "block" }}>
        <strong>
          {reserva.oficina?.pabellon
            ? "Pabellón"
            : reserva.oficina?.wing
            ? "Wing"
            : ""}
        </strong>
        :{" "}
        {reserva.oficina?.pabellon?.identificador ??
          reserva.oficina?.wing?.nombre}
      </Typography>
    </Card>
  );
};

export default OficinaAsignadaHuesped;
