import styles from "./components.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Popper,
  TextField,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
import { addNewEspacio } from "../../../../redux/features/espacios/espacioSlice";
import ExtensionIcon from "@mui/icons-material/Extension";
import CircleIcon from "@mui/icons-material/Circle";
import { toast } from "react-toastify";

const data = {
  nombre: "",
  piso: 0,
  descripcion: "",
};

const Create = ({
  open2,
  setOpen2,
  sectores,
  campamentos,
  pabellones,
  wings,
  otros,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedWing, setSelectedWing] = useState(null);
  const [selectedPabellon, setSelectedPabellon] = useState(null);
  const [selectedOtro, setSelectedOtro] = useState();

  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState("");

  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [pabellonWing, setPabellonWing] = useState(0);

  const [filterPabellones, setFilterPabellones] = useState([]);
  const [filterWings, setFilterWings] = useState([]);
  const [filterOtros, setFilterOtros] = useState([]);

  const handleChange = (event) => {
    setPabellonWing(event.target.value);
  };

  // const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

  const onChange = (e) => {
    const value = e.target.value;
    const invalidCharacters = /[<>\/]/;

    if (!invalidCharacters.test(value)) {
      setFormData({ ...formData, [e.target.name]: value });
    } else {
      toast.error("Carácter no válido");
    }
  };

  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    if (newValue != null) {
      setFormData({
        ...formData,
        campamento: newValue.id,
        sector: null,
        wingId: null,
        pabellonId: null,
      });
      setFilterPabellones(
        pabellones.filter(
          (pabellon) =>
            pabellon.campamento && pabellon.campamento.id === newValue.id
        )
      );
      setFilterWings(
        wings.filter(
          (wing) => wing.campamento && wing.campamento.id === newValue.id
        )
      );
      setFilterOtros(
        otros.filter(
          (edificio) =>
            edificio?.campamento && edificio.campamento.id === newValue.id
        )
      );

      setSelectedSector(null);
      setSelectedPabellon(null);
      setSelectedWing(null);
    } else {
      setFormData({ ...formData, campamento: 0 });
    }
  };
  const handleChangeSector = (event, newValue) => {
    if (newValue != null) {
      setFormData({
        ...formData,
        sector: newValue.id,
        wingId: null,
        pabellonId: null,
      });
      setSelectedSector(newValue);
      setSelectedPabellon(null);
      setSelectedWing(null);
    } else {
      setFormData({ ...formData, sector: 0 });
    }
  };
  const handleChangePabellon = (event, newValue) => {
    if (newValue != null) {
      setSelectedWing(null);
      setFormData({ ...formData, pabellonId: newValue.id, wingId: null });
      setSelectedPabellon(newValue);
    } else {
      setFormData({ ...formData, pabellonId: 0 });
    }
  };
  const handleChangeWing = (event, newValue) => {
    if (newValue != null) {
      setFormData({
        ...formData,
        wingId: newValue.id,
        pabellonId: null,
        sector: null,
      });
      setSelectedSector(null);
      setSelectedPabellon(null);
      setSelectedWing(newValue);
    } else {
      setFormData({ ...formData, wingId: 0 });
    }
  };

  const handleChangeOtro = (event, newValue) => {
    if (newValue != null) {
      setSelectedPabellon(null);
      setSelectedWing(null);
      setFormData({
        ...formData,
        wing: null,
        pabellon: null,
        otro: newValue.id,
      });
      setSelectedOtro(newValue);
    } else {
      setFormData({ ...formData, wing: 0 });
    }
  };

  const canSave =
    [
      formData.pabellonId || formData.wingId || formData.otro,
      formData.nombre,
      formData.piso,
      formData.descripcion,
    ].every(Boolean) && addRequestStatus === "idle";

  const handleSubmit = async () => {
    if (canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus("loading");
        let resp = await dispatch(addNewEspacio(formData));
        if (resp.payload.data.success) {
          setFormData(data);
          setOpen(false);
          return;
        }
      } catch (error) {
        console.error("Error al crear el espacio común", error);
      } finally {
        setOpen2(false);
        setAddRequestStatus("idle");
      }
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          El siguiente <strong>botón</strong> te mostrará un formulario para
          poder registrar un espacio común.
        </Typography>
        <Button
          variant="contanied"
          disabled={sectores.length == 0}
          size="large"
          className={styles.bgColor}
          onClick={() => setOpen(true)}
        >
          <CircleIcon className={styles.iconButton} />
          Crear espacio común
        </Button>
      </div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display: "flex", gap: "5px" }}>
          <ExtensionIcon
            className={styles.iconBgCreate}
            style={{ fontSize: "3rem", marginTop: "0px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="span"
              style={{ fontSize: "24px", lineHeight: "1" }}
            >
              Crear espacio comun
            </Typography>
            <span className={styles.subTitle}>
              Rellena los siguientes campos para crear el espacio común.
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Campamento</label>
              <Autocomplete
                disablePortal
                id="campamentos-autocomplete"
                options={campamentos}
                name="campamento"
                disableClearable
                getOptionLabel={(option) => option.nombre}
                className={styles.textField}
                onChange={(e, value) => handleChangeCampamento(e, value)}
                renderInput={(params) => (
                  <TextField {...params} label="Selecciona un campamento" />
                )}
              />
            </Grid>
            {selectedCampamento && (
              <Grid item xs={12} sm={12}>
                <Typography>
                  <small>
                    <strong style={{ fontWeight: "500" }}>
                      Si el espacio común pertenece a un pabellón selecciona un
                      pabellón , caso contrario, selecciona un wing.
                    </strong>
                  </small>
                </Typography>
              </Grid>
            )}
            {/*{selectedCampamento && (
              <Grid item  xs={12} sm={12}>
                <label className={styles.label}>Sector</label>
                <Autocomplete
                  disablePortal
                  id="sectores-autocomplete"
                  name="sector"
                  disableClearable
                  value={selectedSector}
                  options={selectedCampamento.sectores}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e, value) => handleChangeSector(e, value)}    
                  renderInput={(params) => <TextField {...params} label="Selecciona un sector" />}
                />
              </Grid>
            )} */}
            <Grid item xs={12} sm={6}>
              <label className={styles.label}>Pabellón, Wing o Recinto</label>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={pabellonWing}
                label="Age"
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                onChange={handleChange}
                style={{ width: "100%" }}
              >
                <MenuItem value={1}>Pabellón</MenuItem>
                <MenuItem value={2}>Wing</MenuItem>
                <MenuItem value={3}>Recinto</MenuItem>
              </Select>
            </Grid>
            {pabellonWing == 0 && (
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>{"-"}</label>
                <Autocomplete
                  disablePortal
                  disabled
                  className={styles.textField}
                  disableClearable
                  id="sectores-autocomplete"
                  name="wing"
                  options={[]}
                  getOptionLabel={(option) => option.identificador}
                  renderInput={(params) => <TextField {...params} label="" />}
                />
              </Grid>
            )}
            {/*{selectedSector && ( */}
            {pabellonWing == 1 && (
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Pabellón</label>
                <Autocomplete
                  disablePortal
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  disableClearable
                  id="sectores-autocomplete"
                  name="wing"
                  value={selectedPabellon}
                  options={filterPabellones ?? []}
                  getOptionLabel={(option) => option.identificador}
                  onChange={(e, value) => handleChangePabellon(e, value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecciona un pabellón" />
                  )}
                />
              </Grid>
            )}

            {/* 
            {selectedCampamento && ( */}
            {pabellonWing == 2 && (
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Wing</label>
                <Autocomplete
                  disablePortal
                  disableClearable
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  id="sectores-autocomplete"
                  name="wing"
                  value={selectedWing}
                  options={filterWings ?? []}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e, value) => handleChangeWing(e, value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecciona un wing" />
                  )}
                />
              </Grid>
            )}

            {pabellonWing == 3 && (
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Recinto</label>
                <Autocomplete
                  disablePortal
                  disableClearable
                  id="sectores-autocomplete"
                  name="wing"
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  value={selectedOtro}
                  options={filterOtros ?? []}
                  getOptionLabel={(option) => option.nombre}
                  onChange={handleChangeOtro}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecciona un recinto" />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={8}>
              <label className={styles.label}>Nombre</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="nombre"
                type="text"
                value={formData.nombre}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label className={styles.label}>Piso</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="piso"
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Descripción</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="descripcion"
                type="text"
                multiline
                rows={4}
                maxRows={4}
                value={formData.descripcion}
                onChange={(e) => onChange(e)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true} style={{ padding: "0 16px" }}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained"
                disabled={!canSave}
              >
                {addRequestStatus == "loading" ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Crear"
                )}
              </Button>
              <Button
                onClick={() => setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Create;
