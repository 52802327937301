import styles from './components.module.css';
import { useState } from "react";
import { reclamosSugerenciasRecepcionistaColumns } from "../../../../lib/columns";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { Card } from '@mui/material';
import SkeletonTable from '../../../../components/Skeleton'
import Delete from './Delete';
import Show from './Show';
import { Dialog, DialogContent, DialogTitle, Box } from '@mui/material'

const TableReclamos = ({reclamos_sugerencias, addRequestStatus, setOpen3, open3}) => {
  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openShow, setOpenShow] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = reclamosSugerenciasRecepcionistaColumns(setOpen, setSeleItem, setOpenShow);
  const handleClose = () => setOpenShow(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCellValue, setSelectedCellValue] = useState('');
  const [selectedCellName, setSelectedCellName] = useState('');
    
  const handleCellClick = (params) => {
    console.log(params);
    if (params.field === 'opciones') return;
    setSelectedCellValue(params.value);
    setSelectedCellName(params.field);
    setOpenDialog(true);
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div style={{ width:'100%', marginTop:'10px' }}>
      <Delete open={open} setOpen={setOpen} seleItem={seleItem} setOpen3={setOpen3} open3={open3}/>
      <Show seleItem={seleItem} open3={openShow} setOpen3={setOpenShow} handleClose={handleClose}/>
    { addRequestStatus == 'loading' 
        ? <SkeletonTable/> 
        : <Card style={{ backgroundColor:'#FFF' }}>
              <DataGrid
                getRowId={row=>row.id ? row.id : Math.random() }
                lots={{ toolbar: GridToolbar }}
                rows={reclamos_sugerencias}
                onCellClick={handleCellClick}
                rowHeight={80}
                columns={finalColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 20]}
                localeText={{
                ...esES.components.MuiDataGrid.defaultProps.localeText,
                noRowsLabel: "No hay datos disponibles",
                pagination: {
                    labelRowsPerPage: "Filas por página:",
                }
                }}
                classes={{ menuIcon: styles.menuIcon }}
                style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
                sx={{ 
                  '& .MuiDataGrid-cell': {
                    color: '#2d3037',
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-columnHeader': {
                    color: '#2d3037',
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-footerContainer': {
                    color: '#2d3037',
                    borderTop: '1px solid #c6c6c6',
                  },
                  '& .MuiTablePagination-root': {
                    color: '#2d3037',
                    borderTop: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-toolbarContainer': {
                    color: '#2d3037',
                  },
                  '& .MuiDataGrid-menu': {
                    color: '#2d3037',
                  },
                  '& .MuiSvgIcon-root':{
                    color: '#9ca91c',
                  },
                  '& .MuiTablePagination-actions': {
                    color: '#2d3037', // Color for pagination actions
                  },
                  '& .MuiTablePagination-select': {
                    color: '#2d3037', // Color for pagination select
                  },
                  '& .MuiTablePagination-selectLabel': {
                    color: '#2d3037', // Color for pagination select label
                  },
                  '& .MuiTablePagination-displayedRows': {
                    color: '#2d3037', // Color for displayed rows text
                  },
                  '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                    color: 'red',
                  },
                  '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                    color: 'red',
                  },
                }}
                slotProps={{
                      toolbar:{
                        sx:{
                          color:'#FFF'
                        }
                      },
                      pagination: {
                        sx: {
                          color: '#f19d38',
                        },
                      },
                      menu: {
                        sx: {
                          color: '#f19d38',
                        },
                      },
                }}
            />
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
              <DialogContent>
                <Box>
                  {selectedCellValue ?? '-'}
                </Box>
              </DialogContent>
            </Dialog>
        </Card>
     }
    </div>
  )
}

export default TableReclamos