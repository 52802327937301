import { purple } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
    color:'#262626',
    h1:{
      fontSize: '1.5rem',
      fontWeight: '400'
    },
    h2:{
      fontSize: '1.5rem',
      fontWeight: '400'
    },
    p:{
      fontSize: '1.5rem',
      fontWeight: '400',
      display:'block',
    },
  },
  grid:{
    container:{
      marginTop: '10px',
    }
  },
  palette:{
    secondary: purple
  },
  // xs: {
  //   flexContainer: {
  //     justifyContent:'center',
  //   }
  // },
});