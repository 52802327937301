import styles from "../admAplicacion.module.css";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Tab,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import Header from "../../../components/header/Header";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { createAxiosInstance } from "../../../api/axios";
import { toast } from "react-toastify";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import { horariosColumns, integridadColumns } from "../../../lib/columns";

const CargaInicial = () => {
  const [progressEmpresa, setProgressEmpresa] = useState(0);
  const [executeEmpresa, setExecuteEmpresa] = useState(false);
  const [disabledCargos, setDisabledCargos] = useState(true);

  const [progressCargo, setProgressCargo] = useState(0);
  const [executeCargo, setExecuteCargo] = useState(false);

  // Estados y funciones para otros casos
  const [progressTurno, setProgressTurno] = useState(0);
  const [executeTurno, setExecuteTurno] = useState(false);
  const [disabledTurno, setDisabledTurno] = useState(true);

  const [progressContrato, setProgressContrato] = useState(0);
  const [executeContrato, setExecuteContrato] = useState(false);
  const [disabledContrato, setDisabledContrato] = useState(true);

  const [progressP_INTERNO, setProgressP_INTERNO] = useState(0);
  const [executeP_INTERNO, setExecuteP_INTERNO] = useState(false);
  const [disabledP_INTERNO, setDisabledP_INTERNO] = useState(true);

  const [progressTrabajadoresP_EXTERNO, setProgressTrabajadoresP_EXTERNO] = useState(0);
  const [executeTrabajadoresP_EXTERNO, setExecuteTrabajadoresP_EXTERNO] =
    useState(false);
  const [disabledTrabajadoresP_EXTERNO, setDisabledTrabajadoresP_EXTERNO] =
    useState(true);

  const [progressAcreditacionEmpresa, setProgressAcreditacionEmpresa] =
    useState(0);
  const [executeAcreditacionEmpresa, setExecuteAcreditacionEmpresa] =
    useState(false);
  const [disabledAcreditacionEmpresa, setDisabledAcreditacionEmpresa] =
    useState(true);

  const [progressAcreditacionTrabajador, setProgressAcreditacionTrabajador] =
    useState(0);
  const [executeAcreditacionTrabajador, setExecuteAcreditacionTrabajador] =
    useState(false);
  const [disabledAcreditacionTrabajador, setDisabledAcreditacionTrabajador] =
    useState(true);

  const [progressVPyAreas, setProgressVPyAreas] = useState(0);
  const [executeVPyAreas, setExecuteVPyAreas] = useState(false);
  const [disabledVPyAreas, setDisabledVPyAreas] = useState(true);

  const [progressHistorial, setProgressHistorial] = useState(0);
  const [executeHistorial, setExecuteHistorial] = useState(false);
  const [disabledHistorial, setDisabledHistorial] = useState(true);

  const [progressCampamentos, setProgressCampamentos] = useState(0);
  const [executeCampamentos, setExecuteCampamentos] = useState(false);
  const [disabledCampamentos, setDisabledCampamentos] = useState(true);

  const [
    progressCriticidadRequerimientos,
    setProgressCriticidadRequerimientos,
  ] = useState(0);
  const [executeCriticidadRequerimientos, setExecuteCriticidadRequerimientos] =
    useState(false);
  const [
    disabledCriticidadRequerimientos,
    setDisabledCriticidadRequerimientos,
  ] = useState(true);

  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const [progressSectores, setProgressSectores] = useState(0);
  const [executeSectores, setExecuteSectores] = useState(false);
  const [disabledSectores, setDisabledSectores] = useState(true);

  const [progressStandards, setProgressStandards] = useState(0);
  const [executeStandards, setExecuteStandards] = useState(false);
  const [disabledStandards, setDisabledStandards] = useState(true);

  const [progressPabellones, setProgressPabellones] = useState(0);
  const [executePabellones, setExecutePabellones] = useState(false);
  const [disabledPabellones, setDisabledPabellones] = useState(true);

  const [progressWings, setProgressWings] = useState(0);
  const [executeWings, setExecuteWings] = useState(false);
  const [disabledWings, setDisabledWings] = useState(true);

  const [progressDistancias, setProgressDistancias] = useState(0);
  const [executeDistancias, setExecuteDistancias] = useState(false);
  const [disabledDistancias, setDisabledDistancias] = useState(true);

  const [progressEspacios, setProgressEspacios] = useState(0);
  const [executeEspacios, setExecuteEspacios] = useState(false);
  const [disabledEspacios, setDisabledEspacios] = useState(true);

  const [progressHabitaciones, setProgressHabitaciones] = useState(0);
  const [executeHabitaciones, setExecuteHabitaciones] = useState(false);
  const [disabledHabitaciones, setDisabledHabitaciones] = useState(true);

  const [progressOficinas, setProgressOficinas] = useState(0);
  const [executeOficinas, setExecuteOficinas] = useState(false);
  const [disabledOficinas, setDisabledOficinas] = useState(true);

  const [progressEstructuraHabitaciones, setProgressEstructuraHabitaciones] =
    useState(0);
  const [executeEstructuraHabitaciones, setExecuteEstructuraHabitaciones] =
    useState(false);
  const [progressCargaInventario, setProgressCargaInventario] = useState(0);
  const [executeCargaInventario, setExecuteCargaInventario] = useState(false);
  const [disabledEstructuraHabitaciones, setDisabledEstructuraHabitaciones] =
    useState(true);
  const [disabledCargaInventario, setDisabledCargaInventario] = useState(true);

  const [integridad, setIntegridad] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = integridadColumns();

  const axiosInstance = createAxiosInstance();

  const callEmpresas = async () =>
    await callAPI(
      "/api/v1/Sistema_de_acreditacion/Empresas",
      setProgressEmpresa,
      setExecuteEmpresa,
      setDisabledCargos
    );
  const callCargos = async () =>
    await callAPI(
      "/api/v1/Sistema_de_acreditacion/Cargos",
      setProgressCargo,
      setExecuteCargo,
      setDisabledTurno
    );
  const callTurno = async () =>
    await callAPI(
      "/api/v1/Sistema_de_acreditacion/Turnos",
      setProgressTurno,
      setExecuteTurno,
      setDisabledP_INTERNO
    );
  const callP_INTERNO = async () =>
    await callAPI(
      "/api/v1/Sistema_de_acreditacion/TrabajadoresP_INTERNO",
      setProgressP_INTERNO,
      setExecuteP_INTERNO,
      setDisabledTrabajadoresP_EXTERNO
    );
  const callTrabajadoresP_EXTERNO = async () =>
    await callAPI(
      "/api/v1/Sistema_de_acreditacion/trabajadoresP_EXTERNO",
      setProgressTrabajadoresP_EXTERNO,
      setExecuteTrabajadoresP_EXTERNO,
      setDisabledContrato
    );
  const callContratos = async () =>
    await callAPI(
      "/api/v1/Sistema_de_acreditacion/ContratosTrabajadoresP_EXTERNO",
      setProgressContrato,
      setExecuteContrato,
      setDisabledAcreditacionEmpresa
    );
  const callAcreditacionEmpresa = async () =>
    await callAPI(
      "/api/v1/Sistema_de_acreditacion/AcreditacionEmpresa",
      setProgressAcreditacionEmpresa,
      setExecuteAcreditacionEmpresa,
      setDisabledAcreditacionTrabajador
    );
  const callAcreditacionTrabajador = async () =>
    await callAPI(
      "/api/v1/Sistema_de_acreditacion/AcreditacionTrabajador",
      setProgressAcreditacionTrabajador,
      setExecuteAcreditacionTrabajador,
      setDisabledVPyAreas
    );
  const callVPyAreas = async () =>
    await callAPI(
      "/api/v1/Sistema_de_acreditacion/VPyAreas",
      setProgressVPyAreas,
      setExecuteVPyAreas,
      setDisabledHistorial
    );
  const callHistorialProcesos = async () =>
    await callAPI(
      "/api/v1/Sistema_de_acreditacion/HistorialProcesos",
      setProgressHistorial,
      setExecuteHistorial
    );

  const callCampamentos = async () =>
    await callAPI(
      "/api/v1/CargasInicial/Campamentos",
      setProgressCampamentos,
      setExecuteCampamentos,
      setDisabledCriticidadRequerimientos
    );
  const callCriticidadRequerimientos = async () =>
    await callAPI(
      "/api/v1/CargasInicial/CriticidadRequerimientos",
      setProgressCriticidadRequerimientos,
      setExecuteCriticidadRequerimientos,
      setDisabledSectores
    );
  const callSectores = async () =>
    await callAPI(
      "/api/v1/CargasInicial/Sectores",
      setProgressSectores,
      setExecuteSectores,
      setDisabledStandards
    );
  const callStandards = async () =>
    await callAPI(
      "/api/v1/CargasInicial/Standards",
      setProgressStandards,
      setExecuteStandards,
      setDisabledPabellones
    );
  const callPabellones = async () =>
    await callAPI(
      "/api/v1/CargasInicial/Pabellones",
      setProgressPabellones,
      setExecutePabellones,
      setDisabledWings
    );
  const callWings = async () =>
    await callAPI(
      "/api/v1/CargasInicial/Wings",
      setProgressWings,
      setExecuteWings,
      setDisabledDistancias
    );
  const callDistancias = async () =>
    await callAPI(
      "/api/v1/CargasInicial/Distancias",
      setProgressDistancias,
      setExecuteDistancias,
      setDisabledEspacios
    );
  const callEspacios = async () =>
    await callAPI(
      "/api/v1/CargasInicial/EspaciosAlmacenamiento",
      setProgressEspacios,
      setExecuteEspacios,
      setDisabledHabitaciones
    );
  // const callHabitaciones = async () => await callAPI('/api/v1/CargasInicial/Habitaciones', setProgressHabitaciones, setExecuteHabitaciones, setDisabledEstructuraHabitaciones);
  const callHabitaciones = async () =>
    await callAPI(
      "/api/v1/CargasInicial/Habitaciones",
      setProgressHabitaciones,
      setExecuteHabitaciones,
      setDisabledOficinas
    );
  const callOficinas = async () =>
    await callAPI(
      "/api/v1/CargasInicial/Oficinas",
      setProgressOficinas,
      setExecuteOficinas,
      setDisabledEstructuraHabitaciones
    );
  const callEstructuraHabitaciones = async () =>
    await callAPI(
      "/api/v1/Sistema_de_acreditacion/HistorialProcesos",
      setProgressEstructuraHabitaciones,
      setExecuteEstructuraHabitaciones,
      setDisabledCargaInventario
    );
  const callCargaInventario = async () =>
    await callAPI(
      "/api/v1/Sistema_de_acreditacion/CargaInventario",
      setProgressCargaInventario,
      setExecuteCargaInventario
    );

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const callAPI = async (
    url,
    setProgress,
    setExecute,
    setNextDisabled = () => {}
  ) => {
    let timer;
    try {
      setExecute(true);
      setProgress(0);
      setNextDisabled(true); // Disable next button until the current call is successful

      timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 90) {
            clearInterval(timer);
            return 90; // Detener en 90% y esperar la respuesta de la API
          }
          return prevProgress + 10;
        });
      }, 200); // Aumentar más rápido para ver el progreso

      const resp = await axiosInstance.get(url);
      console.log("resp", resp);
      if (resp.status === 200) {
        clearInterval(timer);
        setProgress(100);
        setNextDisabled(false); // Enable next button after successful call
      } else {
        console.log(resp.status);
        toast.error(resp.data.message);
        clearInterval(timer);
        setExecute(false);
        setNextDisabled(false); // Re-enable the next button in case of error
      }
    } catch (error) {
      console.log(error);
      toast.error(`Error en la carga de datos : ${error.message}`);
      clearInterval(timer);
      setExecute(false);
      setNextDisabled(true); // Re-enable the next button in case of error
    }
  };

  const callVerificarIntegridad = async () => {
    try {
      setAddRequestStatus("loading");
      const resp = await axiosInstance.get("/api/v1/Sistema_de_acreditacion/Integridad");
      console.log("respuesta de /VerificarIntegridad ->", resp.data);
      setIntegridad(resp.data);

      const todosTienenDatos = resp.data.every(
        (registro) => registro.tieneDatos === true
      );

      if (todosTienenDatos) {
        toast.success("Verificación exitosa");
      } else {
        toast.error("Tablas sin datos.");
      }
    } catch (error) {
      console.log("error en verificar integridad", error);
    } finally {
      setAddRequestStatus("idle");
    }
  };

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              sx={{
                "& .MuiTab-root": { color: "gray", fontWeight: "600" },
                "& .MuiTab-root.Mui-selected": { color: "#0086ae" },
                "& .MuiTabs-indicator": { bgcolor: "#0086ae" },
              }}
            >
              <Tab label="Carga Inicial" value="1" />
              <Tab label="SISTEMA DE ACREDITACION" value="2" />
              <Tab label="Verificar Integridad" value="3" />
            </TabList>
          </Box>
          <TabPanel style={{ padding: "0", paddingTop: "10px" }} value="1">
            <Header
              title="Carga inicial"
              subtitle="A continuación podrás iniciar la carga de datos críticos para el funcionamiento del sistema de gestión de campamentos."
            >
              {
                <HolidayVillageIcon
                  style={{ fontSize: "1.5rem" }}
                  className={styles.iconBg}
                />
              }
            </Header>

            <LoadCard
              execute={executeCampamentos}
              progress={progressCampamentos}
              call={callCampamentos}
              title="Campamentos"
              desc=""
            />

            <LoadCard
              execute={executeCriticidadRequerimientos}
              progress={progressCriticidadRequerimientos}
              call={callCriticidadRequerimientos}
              title="Criticidad Requerimientos"
              desc=""
              disabled={disabledCriticidadRequerimientos}
            />

            <LoadCard
              execute={executeSectores}
              progress={progressSectores}
              call={callSectores}
              title="Sectores"
              desc=""
              disabled={disabledSectores}
            />
            <LoadCard
              execute={executeStandards}
              progress={progressStandards}
              call={callStandards}
              title="Standards"
              desc=""
              disabled={disabledStandards}
            />
            <LoadCard
              execute={executePabellones}
              progress={progressPabellones}
              call={callPabellones}
              title="Pabellones"
              desc=""
              disabled={disabledPabellones}
            />
            <LoadCard
              execute={executeWings}
              progress={progressWings}
              call={callWings}
              title="Wings"
              desc=""
              disabled={disabledWings}
            />
            <LoadCard
              execute={executeDistancias}
              progress={progressDistancias}
              call={callDistancias}
              title="Distancias"
              desc=""
              disabled={disabledDistancias}
            />
            <LoadCard
              execute={executeEspacios}
              progress={progressEspacios}
              call={callEspacios}
              title="Espacios de almacenamiento"
              desc=""
              disabled={disabledEspacios}
            />
            <LoadCard
              execute={executeHabitaciones}
              progress={progressHabitaciones}
              call={callHabitaciones}
              title="Habitaciones"
              desc=""
              disabled={disabledHabitaciones}
            />

            <LoadCard
              execute={executeOficinas}
              progress={progressOficinas}
              call={callOficinas}
              title="Oficinas"
              desc=""
              disabled={disabledOficinas}
            />

            <LoadCard
              execute={executeEstructuraHabitaciones}
              progress={progressEstructuraHabitaciones}
              call={callEstructuraHabitaciones}
              title="Estructura habitaciones"
              desc=""
              disabled={disabledEstructuraHabitaciones}
            />
            <LoadCard
              execute={executeCargaInventario}
              progress={progressCargaInventario}
              call={callCargaInventario}
              title="Carga inventario"
              desc=""
              disabled={disabledCargaInventario}
            />
          </TabPanel>
          <TabPanel value="2" style={{ padding: "0", paddingTop: "10px" }}>
            <Header
              title="SISTEMA DE ACREDITACION"
              subtitle="A continuación podrás iniciar la carga de datos de partida desde SISTEMA DE ACREDITACION."
            >
              {
                <HolidayVillageIcon
                  style={{ fontSize: "1.5rem" }}
                  className={styles.iconBg}
                />
              }
            </Header>
            <LoadCard
              execute={executeEmpresa}
              progress={progressEmpresa}
              call={callEmpresas}
              title="Empresas"
              desc=""
            />

            <LoadCard
              execute={executeCargo}
              progress={progressCargo}
              call={callCargos}
              title="Cargos"
              desc=""
              disabled={disabledCargos}
            />

            <LoadCard
              execute={executeTurno}
              progress={progressTurno}
              call={callTurno}
              title="Turnos"
              desc=""
              disabled={disabledTurno}
            />
            <LoadCard
              execute={executeP_INTERNO}
              progress={progressP_INTERNO}
              call={callP_INTERNO}
              title="Trabajadores P_INTERNO"
              desc=""
              disabled={disabledP_INTERNO}
            />
            <LoadCard
              execute={executeTrabajadoresP_EXTERNO}
              progress={progressTrabajadoresP_EXTERNO}
              call={callTrabajadoresP_EXTERNO}
              title="Trabajadores P_EXTERNO"
              desc=""
              disabled={disabledTrabajadoresP_EXTERNO}
            />
            <LoadCard
              execute={executeContrato}
              progress={progressContrato}
              call={callContratos}
              title="Contratos"
              desc=""
              disabled={disabledContrato}
            />
            <LoadCard
              execute={executeAcreditacionEmpresa}
              progress={progressAcreditacionEmpresa}
              call={callAcreditacionEmpresa}
              title="Acreditación empresa"
              desc=""
              disabled={disabledAcreditacionEmpresa}
            />
            <LoadCard
              execute={executeAcreditacionTrabajador}
              progress={progressAcreditacionTrabajador}
              call={callAcreditacionTrabajador}
              title="Acreditación trabajador"
              desc=""
              disabled={disabledAcreditacionTrabajador}
            />
            <LoadCard
              execute={executeVPyAreas}
              progress={progressVPyAreas}
              call={callVPyAreas}
              title="VP y Áreas"
              desc=""
              disabled={disabledVPyAreas}
            />

            <LoadCard
              execute={executeHistorial}
              progress={progressHistorial}
              call={callHistorialProcesos}
              title="Historial de Procesos"
              desc=""
              disabled={disabledHistorial}
            />
          </TabPanel>
          <TabPanel value="3" style={{ padding: "0", paddingTop: "10px" }}>
            <Header
              title="Verificar"
              subtitle="A continuación se verificará que todas las tablas críticas del sistema tengan información, entre ellas distancias, lista de trabajadores, contratos, entre otras."
            >
              {
                <HolidayVillageIcon
                  style={{ fontSize: "1.5rem" }}
                  className={styles.iconBg}
                />
              }
            </Header>
            <Grid container={true} spacing={1} mt={1}>
              <Grid item md={12} xs={12}>
                <Button
                  onClick={callVerificarIntegridad}
                  className={styles.bgColorGreen}
                  variant="contained"
                  disabled={addRequestStatus === "loading"}
                >
                  {addRequestStatus == "loading" ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Verificar Integridad"
                  )}
                </Button>
              </Grid>
              {integridad.length != 0 && (
                <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
                  <Card>
                    <DataGrid
                      getRowId={(row) => (row.id ? row.id : Math.random())}
                      lots={{ toolbar: GridToolbar }}
                      rows={integridad}
                      columns={finalColumns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[5, 10, 15, 20]}
                      localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                        noRowsLabel: "No hay datos disponibles",
                        pagination: {
                          labelRowsPerPage: "Filas por página:",
                        },
                      }}
                      classes={{ menuIcon: styles.menuIcon }}
                      style={{
                        maxHeight: "500px",
                        color: "#FFF",
                        border: "none",
                      }}
                      sx={{
                        "& .MuiDataGrid-cell": {
                          color: "#2d3037",
                          borderBottom: "1px solid #c6c6c6",
                        },
                        "& .MuiDataGrid-columnHeader": {
                          color: "#2d3037",
                          borderBottom: "1px solid #c6c6c6",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          borderBottom: "1px solid #c6c6c6",
                        },
                        "& .MuiDataGrid-footerContainer": {
                          color: "#2d3037",
                          borderTop: "1px solid #c6c6c6",
                        },
                        "& .MuiTablePagination-root": {
                          color: "#2d3037",
                          borderTop: "1px solid #c6c6c6",
                        },
                        "& .MuiDataGrid-toolbarContainer": {
                          color: "#2d3037",
                        },
                        "& .MuiDataGrid-menu": {
                          color: "#2d3037",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#9ca91c",
                        },
                        "& .MuiTablePagination-actions": {
                          color: "#2d3037", // Color for pagination actions
                        },
                        "& .MuiTablePagination-select": {
                          color: "#2d3037", // Color for pagination select
                        },
                        "& .MuiTablePagination-selectLabel": {
                          color: "#2d3037", // Color for pagination select label
                        },
                        "& .MuiTablePagination-displayedRows": {
                          color: "#2d3037", // Color for displayed rows text
                        },
                        "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer":
                          {
                            color: "red",
                          },
                        "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root":
                          {
                            color: "red",
                          },
                      }}
                      slotProps={{
                        toolbar: {
                          sx: {
                            color: "#FFF",
                          },
                        },
                        pagination: {
                          sx: {
                            color: "#f19d38",
                          },
                        },
                        menu: {
                          sx: {
                            color: "#f19d38",
                          },
                        },
                      }}
                    />
                  </Card>
                </Grid>
              )}
            </Grid>
          </TabPanel>
        </TabContext>
      </Container>
    </Box>
  );
};

export default CargaInicial;

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          <span style={{ color: "#2d3037" }}>{`${Math.round(
            props.value
          )}%`}</span>
        </Typography>
      </Box>
    </Box>
  );
}

const LoadCard = ({
  execute,
  progress,
  call,
  title,
  desc,
  disabled = false,
}) => {
  return (
    <Card
      style={{
        marginTop: "10px",
        padding: "16px",
        backgroundColor: "#FFF",
        color: "#2d3037",
        border: "none",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} className={styles.textBoxContainer}>
          <div className={styles.textBox}>
            {/*<label className={styles.label}>{title}</label> */}
            <label style={{ fontWeight: "400" }}>{title}</label>
            <label className={styles.labelDesc}>{desc}</label>
          </div>
          <div>
            {execute ? (
              <CircularProgressWithLabel value={progress} />
            ) : (
              <Button
                variant="contained"
                onClick={call}
                className={styles.button}
                disabled={disabled}
              >
                Cargar
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
