import styles from "./components.module.css";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Popper,
  Select,
  TextField,
  Typography,
  Card,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import TableHabitaciones from "./TableHabitacionesEdit";
import { updateNewGrupoAseo } from "../../../../redux/features/aseo/aseoSlice";
import ErrorIcon from "@mui/icons-material/Error";
import { getAllCampamentos } from "../../../../redux/features/campamentos/campamentoSlice";
import { getAllWings } from "../../../../redux/features/wings/wingSlice";
import { getAllEdificios } from "../../../../redux/features/edificios/edificioSlice";
import { getAllOtros } from "../../../../redux/features/otros/otroSlice";
import { createAxiosInstance } from "../../../../api/axios";

const data = {
  nombre: "",
  descripcion: "",
  habitaciones: [],
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Edit = ({
  open2,
  setOpen2,
  setOpen3,
  habitaciones,
  oficinas,
  espaciosComunes,
  seleItem,
  open3,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [selectedWing, setSelectedWing] = useState([]);
  const [selectedPabellon, setSelectedPabellon] = useState([]);
  const [selectedOtro, setSelectedOtro] = useState([]);
  const [pabellonWing, setPabellonWing] = useState(0);

  const [selectedHabitaciones, setSelectedHabitaciones] = useState([]);
  const [selectedHabitacionIds, setSelectedHabitacionIds] = useState([]);

  const [selectedOficinas, setSelectedOficinas] = useState([]);
  const [selectedOficinaIds, setSelectedOficinaIds] = useState([]);

  const [selectedEspacios, setSelectedEspacios] = useState([]);
  const [selectedEspacioIds, setSelectedEspacioIds] = useState([]);

  const [filtroPabellones, setFiltroPabellones] = useState([]);
  const [filtrowings, setFiltroWings] = useState([]);
  const [filtrootros, setFiltroOtros] = useState([]);
  const [defaultHabitaciones, setDefaultHabitaciones] = useState([]);
  const [defaultOficinas, setDefaultOficinas] = useState([]);
  const [defaultEspacios, setDefaultEspacios] = useState([]);

  const campamentos = useSelector(getAllCampamentos);
  const wings = useSelector(getAllWings);
  const pabellones = useSelector(getAllEdificios);
  const otros = useSelector(getAllOtros);

  const handleChangePW = (event) => {
    setPabellonWing(event.target.value);
    if (event.target.value === 1) {
      setSelectedWing([]);
      setSelectedOtro([]);
      let newPabellones = pabellones.filter(
        (item) => item.campamentoId == selectedCampamento.id
      );
      setFiltroPabellones(newPabellones);
    } else if (event.target.value === 2) {
      setSelectedPabellon([]);
      setSelectedOtro([]);
      let newWings = wings.filter(
        (item) => item.campamentoId == selectedCampamento.id
      );
      setFiltroWings(newWings);
    } else if (event.target.value === 3) {
      setSelectedPabellon([]);
      setSelectedWing([]);
      let newOtros = otros.filter(
        (item) => item.campamentoId == selectedCampamento.id
      );
      setFiltroOtros(newOtros);
    }
    setFormData({ ...formData, pabellon: null, wing: null, otro: null });
  };

  const handleChangeCampamento = (event, newValue) => {
    //al ejecutar esta función lo otro debe cambiar
    setSelectedCampamento(newValue);
    // if(newValue != null){
    //   setFormData({...formData, campamento: newValue.id});
    // } else{
    //   setFormData({...formData, campamento: 0})
    // }
    if (newValue != null) {
      setFormData({ ...formData, campamento: newValue.id });
      setSelectedPabellon(null);
      setSelectedWing(null);
      setFormData({ ...formData, pabellon: null, wing: null, otro: null });

      let newPabellones = pabellones.filter(
        (item) => item.campamentoId == newValue.id
      );
      setFiltroPabellones(newPabellones);
      let newWings = wings.filter((item) => item.campamentoId == newValue.id);
      setFiltroWings(newWings);
      let newOtros = otros.filter((item) => item.campamentoId == newValue.id);
      setFiltroOtros(newOtros);
    } else {
      setFormData({ ...formData, campamento: 0 });
    }
  };
  const handleChangePabellon = (event, newValue) => {
    // if(newValue != null){
    //   console.log(newValue);
    //   setFormData({...formData, pabellon: newValue.map(item => item.id), wing: null, otro: null })
    //   setSelectedPabellon(newValue);
    //   // const filterHabitaciones = formData?.habitaciones.filter(item => newValue.includes(item.pabellon.nombre));
    //   // console.log(filterHabitaciones)

    //   // const filterHabitaciones = setFormData(prev => {...prev,)
    //   setSelectedWing([]);
    //   setSelectedOtro([]);
    // } else{
    //   setFormData({...formData, pabellon: 0})
    //   }
    if (newValue != null) {
      setDefaultHabitaciones(habitaciones);
      setDefaultOficinas(oficinas);
      setDefaultEspacios(espaciosComunes);
      setFormData({
        ...formData,
        pabellon: newValue.map((item) => item.id),
        pabellones: newValue.map((item) => item.id),
        wing: null,
        otro: null,
        wings: null,
      });
      setSelectedPabellon(newValue);
      setSelectedWing([]);
      setSelectedOtro([]);
    } else {
      setFormData({ ...formData, pabellon: 0 });
    }
  };
  const handleChangeWing = (event, newValue) => {
    // if(newValue != null){
    //   setFormData({...formData, wing:  newValue.map(wing => wing.id), pabellon: null, otro: null })
    //   setSelectedPabellon([]);
    //   setSelectedOtro([]);
    //   setSelectedWing(newValue);
    // } else{
    //   setFormData({...formData, wing: 0})
    // }
    if (newValue != null) {
      setDefaultHabitaciones(habitaciones);
      setDefaultOficinas(oficinas);
      setDefaultEspacios(espaciosComunes);
      setFormData({
        ...formData,
        wing: newValue.map((wing) => wing.id),
        wings: newValue.map((wing) => wing.id),
        pabellon: null,
        otro: null,
        pabellones: null,
      });
      setSelectedPabellon([]);
      setSelectedOtro([]);
      setSelectedWing(newValue);
    } else {
      setFormData({ ...formData, wing: 0 });
    }
  };

  const handleChangeOtro = (event, newValue) => {
    // if(newValue != null){
    //   setSelectedPabellon([]);
    //   setSelectedWing([]);
    //   setFormData({...formData, wing: null, pabellon: null, otro: newValue.map(wing => wing.id) })
    //   setSelectedOtro(newValue);
    // } else{
    //   setFormData({...formData, wing: 0})
    // }
    if (newValue != null) {
      setDefaultHabitaciones(habitaciones);
      setDefaultOficinas(oficinas);
      setDefaultEspacios(espaciosComunes);
      setSelectedPabellon([]);
      setSelectedWing([]);
      setFormData({
        ...formData,
        wing: null,
        pabellon: null,
        otro: newValue.map((wing) => wing.id),
        otros: newValue.map((wing) => wing.id),
        wings: null,
        pabellones: null,
      });
      setSelectedOtro(newValue);
    } else {
      setFormData({ ...formData, wing: 0 });
    }
  };
  const axiosInstance = createAxiosInstance();

  const getGrupo = async (seleItem) => {
    try {
      setOpen3(true);
      const resp = await axiosInstance.get(
        "/api/v1/Aseo/grupo-habitacion/" + seleItem.id
      );
      console.log("respuesta-->", resp.data);

      const mappedIds = resp.data.habitaciones
        ? resp.data.habitaciones.map((item) => item.id)
        : [];
      const mappedIdsOficinas = resp.data.oficinas
        ? resp.data.oficinas.map((item) => item.id)
        : [];
      const mappedIdsEspacios = resp.data.espacios
        ? resp.data.espacios.map((item) => item.id)
        : [];

      setDefaultHabitaciones(habitaciones);
      setDefaultOficinas(oficinas);
      setDefaultEspacios(espaciosComunes);

      if (seleItem.pabellones) {
        if (mappedIds.length > 0) {
          const habitaciones = resp.data.habitaciones;
          const oficinas = resp.data.oficinas;
          const espacios = resp.data.espacios;
          const pabellonesMap = new Map();

          if (habitaciones && habitaciones.length > 0) {
            habitaciones.forEach((item) => {
              const pabellon = item.pabellon;
              if (pabellon && !pabellonesMap.has(pabellon.id)) {
                pabellonesMap.set(pabellon.id, pabellon);
              }
            });
          }

          if (oficinas && oficinas.length > 0) {
            oficinas.forEach((item) => {
              const pabellon = item.pabellon;
              if (pabellon && !pabellonesMap.has(pabellon.id)) {
                pabellonesMap.set(pabellon.id, pabellon);
              }
            });
          }

          if (espacios && espacios.length > 0) {
            espacios.forEach((item) => {
              const pabellon = item.pabellon;
              if (pabellon && !pabellonesMap.has(pabellon.id)) {
                pabellonesMap.set(pabellon.id, pabellon);
              }
            });
          }
          const pabellonesUnicos = Array.from(pabellonesMap.values());
          setSelectedPabellon(pabellonesUnicos);
        }
      } else if (seleItem.wings) {
        if (mappedIds.length > 0) {
          const habitaciones = resp.data.habitaciones;
          const oficinas = resp.data.oficinas;
          const espacios = resp.data.espacios;
          const wingsMap = new Map();

          if (habitaciones && habitaciones.length > 0) {
            habitaciones.forEach((item) => {
              const wing = item.wing;
              if (wing && !wingsMap.has(wing.id)) {
                wingsMap.set(wing.id, wing);
              }
            });
          }

          if (oficinas && oficinas.length > 0) {
            oficinas.forEach((item) => {
              const wing = item.wing;
              if (wing && !wingsMap.has(wing.id)) {
                wingsMap.set(wing.id, wing);
              }
            });
          }

          if (espacios && espacios.length > 0) {
            espacios.forEach((item) => {
              const wing = item.wing;
              if (wing && !wingsMap.has(wing.id)) {
                wingsMap.set(wing.id, wing);
              }
            });
          }
          const wingsUnicos = Array.from(wingsMap.values());
          setSelectedWing(wingsUnicos);
        }
      } else if (seleItem.otros) {
        if (mappedIds.length > 0) {
          const habitaciones = resp.data.habitaciones;
          const oficinas = resp.data.oficinas;
          const espacios = resp.data.espacios;
          const otrosMap = new Map();

          if (habitaciones && habitaciones.length > 0) {
            habitaciones.forEach((item) => {
              const otro = item.otro;
              if (otro && !otrosMap.has(otro.id)) {
                otrosMap.set(otro.id, otro);
              }
            });
          }

          if (oficinas && oficinas.length > 0) {
            oficinas.forEach((item) => {
              const otro = item.otro;
              if (otro && !otrosMap.has(otro.id)) {
                otrosMap.set(otro.id, otro);
              }
            });
          }

          if (espacios && espacios.length > 0) {
            espacios.forEach((item) => {
              const otro = item.otro;
              if (otro && !otrosMap.has(otro.id)) {
                otrosMap.set(otro.id, otro);
              }
            });
          }
          const otrosUnicos = Array.from(otrosMap.values());
          setSelectedOtro(otrosUnicos);
        }
      }

      setSelectedHabitaciones(mappedIds);
      setSelectedHabitacionIds(mappedIds);

      setSelectedOficinas(mappedIdsOficinas);
      setSelectedOficinaIds(mappedIdsOficinas);

      setSelectedEspacioIds(mappedIdsEspacios);
      setSelectedEspacioIds(mappedIdsEspacios);

      setFormData((prev) => ({
        ...prev,
        habitaciones: mappedIds,
        oficinas: mappedIdsOficinas,
        espacios: mappedIdsEspacios,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setOpen3(false);
    }
  };

  useEffect(() => {
    if (seleItem) {
      // Verifica si hay campamentos en seleItem
      const campamentoId = seleItem.campamentos?.[0]?.id;

      if (seleItem.pabellones?.length > 0 && campamentoId) {
        setFiltroPabellones(
          pabellones.filter(
            (pabellon) => pabellon.campamentoId === campamentoId
          )
        );
      } else if (seleItem.wings?.length > 0 && campamentoId) {
        setFiltroWings(
          wings.filter((wing) => wing.campamentoId === campamentoId)
        );
      } else if (
        seleItem.otros &&
        Array.isArray(seleItem.otros) &&
        seleItem.otros.length > 0 &&
        campamentoId
      ) {
        setFiltroOtros(
          otros.filter((otro) => otro.campamentoId === campamentoId)
        );
      }

      setPabellonWing(
        seleItem.pabellones?.length > 0
          ? 1
          : seleItem.wings?.length > 0
          ? 2
          : seleItem.edificios?.length > 0
          ? 3
          : 0
      );

      getGrupo(seleItem);
      setFormData(seleItem);
      console.log("seleItem", seleItem);

      // Asegúrate de que campamentos[0] existe antes de establecer selectedCampamento
      if (seleItem.campamentos?.length > 0) {
        setSelectedCampamento(seleItem.campamentos[0]);
      }
    }
  }, [seleItem, open2]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const canSave =
    [formData.nombre, formData.habitaciones].every(Boolean) &&
    addRequestStatus === "idle";

  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  const handleSubmit = async () => {
    setOpen2(true);
    if (canSave) {
      try {
        setOpen3(true);
        setAddRequestStatus("loading");
        console.log("formData", formData);
        // return;
        let resp = await dispatch(updateNewGrupoAseo(formData));
        if (resp.payload.status === "success") {
          setFormData(data);
          setOpen2(false);
        }
      } catch (error) {
        console.error("Error al actualizar el grupo de habitaciones", error);
      } finally {
        setOpen3(false);
        setAddRequestStatus("idle");
      }
    }
  };

  return (
    <div>
      <Dialog
        open={open2}
        maxWidth="lg"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        TransitionComponent={Transition}
        onBackdropClick={() => {
          setOpen2(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>
          <Grid container={true} spacing={1} px={2}>
            <Grid item xs={12} sm={12} style={{ display: "flex", gap: "5px" }}>
              <ErrorIcon
                style={{ fontSize: "3rem" }}
                className={styles.iconBg}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6" className={styles.textIconEdit}>
                  Actualizar grupo {seleItem?.nombre}
                </Typography>
                <Typography variant="span" className={styles.textIconSpan}>
                  Modifica los campos y selecciona habitaciones para actualizar
                  el grupo.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item xs={12} sm={3} style={{ overflowY: "auto" }}>
              <div>
                <label className={styles.label}>Nombre</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      color: "#2d3037",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="nombre"
                  type="text"
                  value={formData?.nombre || ""}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div>
                <label className={styles.label}>Campamento</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      color: "#2d3037",
                    },
                  }}
                  disablePortal
                  disableClearable
                  id="campamentos-autocomplete"
                  name="campamento"
                  // value={formData?.campamentos ? formData?.campamentos[0] : null}
                  value={selectedCampamento}
                  options={
                    Array.isArray(campamentos) && campamentos.length > 0
                      ? campamentos
                      : []
                  }
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e, value) => handleChangeCampamento(e, value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecciona un campamento" />
                  )}
                />
              </div>
              <div>
                <label className={styles.label}>Pabellón, Wing, recinto.</label>
                <Select
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    ".MuiChip-label": {
                      color: "white",
                    },
                    ".MuiSvgIcon-root": {
                      color: "#FFF !important",
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      color: "#2d3037",
                    },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pabellonWing}
                  label="Age"
                  onChange={handleChangePW}
                  style={{ width: "100%" }}
                >
                  <MenuItem value={1}>Pabellón</MenuItem>
                  <MenuItem value={2}>Wing</MenuItem>
                  <MenuItem value={3}>Recinto</MenuItem>
                </Select>
              </div>
              <div>
                {/* {selectedSector && ( */}
                {pabellonWing == 1 && (
                  <>
                    <label className={styles.label}>Pabellón</label>
                    <Autocomplete
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        ".MuiChip-label": {
                          color: "#2d3037",
                        },
                        ".MuiSvgIcon-root": {
                          color: "#2d3037",
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          color: "#2d3037",
                        },
                        "& .MuiAutocomplete-popupIndicator": {
                          color: "#2d3037",
                        },
                      }}
                      disablePortal
                      disableClearable
                      id="sectores-autocomplete"
                      name="pabellon"
                      multiple
                      value={selectedPabellon || []}
                      // options={pabellones ? pabellones : [] }
                      options={filtroPabellones ? filtroPabellones : []}
                      getOptionLabel={(option) => option.identificador}
                      onChange={handleChangePabellon}
                      renderInput={(params) => (
                        <TextField {...params} label="Selecciona un pabellón" />
                      )}
                    />
                  </>
                )}
                {/* {selectedCampamento && ( */}
                {pabellonWing == 2 && (
                  <>
                    <label className={styles.label}>Wing</label>
                    <Autocomplete
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        ".MuiChip-label": {
                          color: "#2d3037",
                        },
                        ".MuiSvgIcon-root": {
                          color: "#2d3037",
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          color: "#2d3037",
                        },
                        "& .MuiAutocomplete-popupIndicator": {
                          color: "#2d3037",
                        },
                      }}
                      multiple
                      disablePortal
                      disableClearable
                      id="sectores-autocomplete"
                      name="wing"
                      value={selectedWing || []}
                      options={filtrowings || []}
                      getOptionLabel={(option) => option.nombre}
                      onChange={handleChangeWing}
                      renderInput={(params) => (
                        <TextField {...params} label="Selecciona un wing" />
                      )}
                    />
                  </>
                )}
                {pabellonWing == 3 && (
                  <>
                    <label className={styles.label}>Recinto</label>
                    <Autocomplete
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        ".MuiChip-label": {
                          color: "white",
                        },
                        ".MuiSvgIcon-root": {
                          color: "#FFF !important",
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          color: "#2d3037",
                        },
                        "& .MuiAutocomplete-popupIndicator": {
                          color: "#2d3037",
                        },
                      }}
                      disablePortal
                      multiple
                      disableClearable
                      id="sectores-autocomplete"
                      name="wing"
                      value={filtrootros || []}
                      options={otros ? otros : []}
                      getOptionLabel={(option) => option.nombre}
                      onChange={handleChangeOtro}
                      renderInput={(params) => (
                        <TextField {...params} label="Selecciona un recinto" />
                      )}
                    />
                  </>
                )}
              </div>
              <div>
                <label className={styles.label}>Piso (Opcional)</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      color: "#2d3037",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="piso"
                  type="number"
                  value={formData?.piso || ""}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div>
                <label className={styles.label}>Descripción (opcional)</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      color: "#2d3037",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="descripcion"
                  type="text"
                  value={formData?.descripcion || ""}
                  multiline
                  rows={2}
                  maxRows={4}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={9} style={{ marginTop: "7px" }}>
              {!habitaciones ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                <Card style={{ padding: "16px" }}>
                  <TableHabitaciones
                    setOpen3={setOpen3}
                    selectedWing={selectedWing}
                    selectedPabellon={selectedPabellon}
                    selectedOtro={selectedOtro}
                    setSelectedWing={setSelectedWing}
                    setSelectedPabellon={setSelectedPabellon}
                    setSelectedOtro={setSelectedOtro}
                    formData={formData}
                    setFormData={setFormData}
                    habitaciones={defaultHabitaciones}
                    setSelectedHabitaciones={setSelectedHabitaciones}
                    selectedHabitacionIds={selectedHabitacionIds}
                    setSelectedHabitacionIds={setSelectedHabitacionIds}
                    oficinas={defaultOficinas}
                    setSelectedOficinas={setSelectedOficinas}
                    selectedOficinaIds={selectedOficinaIds}
                    setSelectedOficinaIds={setSelectedOficinaIds}
                    espacios={defaultEspacios}
                    setSelectedEspacios={setSelectedEspacios}
                    selectedEspacioIds={selectedEspacioIds}
                    setSelectedEspacioIds={setSelectedEspacioIds}
                  />
                </Card>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true} style={{ padding: "0 16px" }}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button
                onClick={handleSubmit}
                className={styles.bgColorEdit}
                variant="contained"
                disabled={!canSave || selectedHabitaciones.length <= 0}
              >
                {addRequestStatus == "loading" ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Actualizar"
                )}
              </Button>
              <Button
                onClick={() => setOpen2(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Edit;
