import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { createAxiosInstance } from "../../../api/axios";

const OFICINAS_URL = "/api/v1/Oficina";
const OFICINAS_DISPONIBLES_URL = "/api/v1/Oficina/Disponibles";
const OFICINA_URL = "/api/v1/Oficina/";
const GETS_RUTS_OFICINA = "/api/v1/Oficina/GetRutsPorOficina";
const REMOVER_TRABAJADOR_OFICINA = "/api/v1/Oficina/RemoveTrabajador";
const DELETE_OFICINA_URL = "/api/v1/Oficina/";
const UPDATE_OFICINA_URL = "/api/v1/Oficina/";
const ADD_OFICINA_URL = "/api/v1/Oficina";
const ASIGNAR_OFICINA_URL = "/api/v1/Oficina/reservar";
const LIBERAR_OFICINA_URL = "/api/v1/Oficina/liberar/";
const RESERVAS_OFICINAS_URL = "/api/v1/Oficina/reservas";
const DESHABILITAR_OFICINA_URL = "/api/v1/Oficina/deshabilitar/";
const HABILITAR_OFICINA_URL = "/api/v1/Oficina/habilitar/";
const REENVIAR_CLAVE_OFICINA_URL = "/api/v1/Oficina/ReSendEmail";
const axiosInstance = createAxiosInstance();

// ACTIONS
export const fetchOficinas = createAsyncThunk(
  "oficinas/fetchOficinas",
  async () => {
    try {
      const response = await axiosInstance.get(OFICINAS_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const fetchTrabajadoresOficinas = createAsyncThunk(
  "oficinas/fetchTrabajadoresOficinas",
  async (id) => {
    try {
      const response = await axiosInstance.get(GETS_RUTS_OFICINA + "/" + id);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const removerTrabajadorOficinaRut = createAsyncThunk(
  "oficinas/removerTrabajadorOficinaRut",
  async (data) => {
    const { rut, id } = data;
    try {
      const response = await axiosInstance.get(
        `${REMOVER_TRABAJADOR_OFICINA}/${rut}/${id}`
      );
      console.log(
        "Petición enviada a:",
        `${REMOVER_TRABAJADOR_OFICINA}/${rut}/${id}`
      );
      if (response.status >= 200 && response.status < 300) {
        toast.success("Trabajador removido correctamente.");
        return response.data;
      }
      return { status: "error" };
    } catch (e) {
      console.error(
        "Error en la función removerTrabajadorOficinaRut:",
        e.message
      );
      throw new Error(e.message);
    }
  }
);

export const fetchReservasOficinas = createAsyncThunk(
  "oficinas/fetchReservas",
  async () => {
    try {
      const response = await axiosInstance.get(RESERVAS_OFICINAS_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const fetchOficinasDisponibles = createAsyncThunk(
  "oficinas/fetchOficinasDisponibles",
  async () => {
    try {
      const response = await axiosInstance.get(OFICINAS_DISPONIBLES_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const fetchOficina = createAsyncThunk(
  "sector/fetchOficina",
  async (id) => {
    try {
      const response = await axiosInstance.get(OFICINA_URL + id);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);
export const deleteOficina = createAsyncThunk(
  "oficinas/deleteOficina",
  async (id, { dispatch }) => {
    console.log("id", id);
    try {
      const response = await axiosInstance.delete(DELETE_OFICINA_URL + id);

      if (response.status >= 200 && response.status < 300) {
        toast.success("Oficina eliminada correctamente.");
        dispatch(fetchOficinas());
        return { data: { id }, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al eliminar la oficina: " + e.response.data);
      return e.message;
    }
  }
);
export const updateOficina = createAsyncThunk(
  "oficinas/updateOficina",
  async (upOficina, { dispatch, rejectWithValue }) => {
    try {
      console.log("Oficina llegó", upOficina);
      const response = await axiosInstance.put(
        UPDATE_OFICINA_URL + upOficina.id,
        {
          pabellonId: upOficina.pabellon ? upOficina.pabellon.id : null,
          wingId: upOficina.wing ? upOficina.wing.id : null,
          edificioId: upOficina.otro ? upOficina.otro.id : null,
          piso: upOficina.piso,
          numero: upOficina.numero,
          descripcion: upOficina.descripcion,
          chapa: upOficina.chapa,
        }
      );

      console.log(response);

      if (response.status === 200) {
        toast.success("Oficina actualizada correctamente.");
        dispatch(fetchOficinas());
        return { data: response.data, status: "success" };
      } else {
        return rejectWithValue("Error en la actualización");
      }
    } catch (e) {
      toast.error(
        "Error al actualizar la oficina: " + (e.response?.data || e.message)
      );
      console.log("Error: ", e);
      return rejectWithValue(e.response?.data || e.message);
    }
  }
);
export const addNewOficina = createAsyncThunk(
  "oficinas/createSectores",
  async (initialCampamento, { dispatch }) => {
    try {
      const response = await axiosInstance.post(ADD_OFICINA_URL, {
        pabellonId: initialCampamento.pabellonId,
        wingId: initialCampamento.wingId,
        edificioId: initialCampamento.edificioId,
        piso: initialCampamento.piso,
        numero: initialCampamento.numero,
        descripcion: initialCampamento.descripcion,
        chapa: initialCampamento.chapa,
      });
      if (response.status >= 200 && response.status < 300) {
        toast.success("Oficina creada correctamente.");
        dispatch(fetchOficinas());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al crear la oficina: " + e.response.data);
      return { message: e.message, status: "error" };
    }
  }
);
export const reenviarClaveOficina = createAsyncThunk(
  "oficinas/reenviarClaveOficina",
  async (data, { dispatch }) => {
    console.log("enviando:", data);
    try {
      const response = await axiosInstance.post(
        REENVIAR_CLAVE_OFICINA_URL +
          "?rut=" +
          data.rut +
          "&fechaInicio=" +
          data.fechaInicio +
          "&fechaTermino=" +
          data.fechaTermino
      );

      if (response.status >= 200 && response.status < 300) {
        toast.success("Clave enviada exitosamente.");
        dispatch(fetchOficinas());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al enviar la clave de la chapa: " + e.response.data);
      return { message: e.message, status: "error" };
    }
  }
);
export const asignarOficina = createAsyncThunk(
  "oficinas/asignarOficina",
  async (initialCampamento, { dispatch }) => {
    console.log("enviando:", initialCampamento);
    try {
      const response = await axiosInstance.post(ASIGNAR_OFICINA_URL, {
        rut: initialCampamento.rut.rut,
        fechaInicio: initialCampamento.fechaInicio,
        fechaTermino: initialCampamento.fechaHasta,
        oficinaId: initialCampamento.oficinaId,
      });
      if (response.status >= 200 && response.status < 300) {
        toast.success("Oficina asignada correctamente.");
        dispatch(fetchOficinas());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al asignar la oficina: " + e.response.data);
      return { message: e.message, status: "error" };
    }
  }
);
export const habilitarOficina = createAsyncThunk(
  "oficinas/habilitarOficina",
  async (id, { dispatch }) => {
    console.log("enviando:", id);
    try {
      const response = await axiosInstance.post(HABILITAR_OFICINA_URL + id);
      if (response.status >= 200 && response.status < 300) {
        toast.success("Oficina habilitada correctamente.");
        dispatch(fetchOficinas());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al habilitar la oficina: " + e.response.data);
      return { message: e.message, status: "error" };
    }
  }
);
export const liberarOficina = createAsyncThunk(
  "oficinas/liberarOficina",
  async (id, { dispatch }) => {
    console.log("enviando:", id);
    try {
      const response = await axiosInstance.post(LIBERAR_OFICINA_URL + id);
      if (response.status >= 200 && response.status < 300) {
        await axiosInstance.post(HABILITAR_OFICINA_URL + id);
        toast.success("Oficina liberada correctamente.");
        dispatch(fetchOficinas());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al liberar la oficina: " + e.response.data);
      return { message: e.message, status: "error" };
    }
  }
);
export const deshabilitarOficina = createAsyncThunk(
  "oficinas/deshabilitarOficina",
  async (data, { dispatch }) => {
    console.log("enviando:", data);
    try {
      const response = await axiosInstance.post(
        DESHABILITAR_OFICINA_URL + data.id + "?razon=" + data.razon
      );
      if (response.status >= 200 && response.status < 300) {
        toast.success("Oficina deshabilitada correctamente.");
        dispatch(fetchOficinas());
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      toast.error("Error al deshabilitada la oficina: " + e.response.data);
      return { message: e.message, status: "error" };
    }
  }
);

// idle : no hay operaciones en curso
const initialState = {
  oficinas: [],
  oficinasDisponibles: [],
  oficina: {},
  status: "idle", //'idle'|'loading'|'succeeded'|'failed'
  statusDisponibles: "idle",
  error: null,
};

export const oficinaSlice = createSlice({
  name: "oficina",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchOficinas.pending, (state, action) => {
        state.status = "loading";
      }) //fetch
      .addCase(fetchOficinas.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (JSON.stringify(state.oficinas) !== JSON.stringify(action.payload)) {
          //fetch
          state.oficinas = action.payload;
        }
      })
      .addCase(fetchOficinas.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchOficinasDisponibles.pending, (state, action) => {
        state.statusDisponibles = "loading";
      }) //fetch
      .addCase(fetchOficinasDisponibles.fulfilled, (state, action) => {
        state.statusDisponibles = "succeeded";
        if (
          JSON.stringify(state.oficinasDisponibles) !==
          JSON.stringify(action.payload)
        ) {
          //fetch
          state.oficinasDisponibles = action.payload;
        }
      })
      .addCase(fetchOficinasDisponibles.rejected, (state, action) => {
        //fetch
        state.statusDisponibles = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchOficina.pending, (state, action) => {
        state.status = "loading";
      }) //fetch
      .addCase(fetchOficina.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (JSON.stringify(state.oficina) !== JSON.stringify(action.payload)) {
          //fetch
          state.oficina = action.payload;
        }
      })
      .addCase(fetchOficina.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewOficina.fulfilled, (state, action) => {
        //ADD
        state.status = "succeeded";
        state.oficinas.push(action.payload);
      })
      .addCase(asignarOficina.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(liberarOficina.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deshabilitarOficina.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteOficina.pending, (state, action) => {
        state.status = "loading";
      }) //fetch
      .addCase(deleteOficina.fulfilled, (state, action) => {
        if (action.payload.status === "success") {
          state.status = "succeeded";
          state.oficinas = state.oficinas.filter(
            (oficina) => oficina.id !== action.payload.id
          );
        } else {
          state.status = "failed";
        }
      })
      .addCase(deleteOficina.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateOficina.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateOficina.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.oficinas = state.oficinas.map((oficina) => {
          if (oficina.id === action.payload.data.id) {
            return action.payload.data;
          }
          return oficina;
        });
      })
      .addCase(updateOficina.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      });
  },
});

export const getAllOficinas = (state) => state.oficinas.oficinas;
export const getAllOficinasDisponibles = (state) =>
  state.oficinas.oficinasDisponibles;
export const getOficina = (state) => state.oficinas.oficina;
export const getOficinasStatus = (state) => state.oficinas.status;
export const getOficinasDisponiblesStatus = (state) =>
  state.oficinas.statusDisponibles;
export const getOficinasError = (state) => state.oficinas.error;

export const {} = oficinaSlice.actions;
export default oficinaSlice.reducer;
