import styles from "./componentsDashboard/components.module.css";
import {
  Box,
  Card,
  Container,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import React, { useEffect, useState } from "react";
import Table from "./componentsDashboard/TableReclamos";
import Chart from "./componentsDashboard/Chart";
import SingleChart from "./componentsDashboard/SingleChart";
import SingleChartTotal from "./componentsDashboard/SingleChartTotal";
import { useAuth } from "../../context/AppContext";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEspacios,
  getEspacioStatus,
  getAllEspacios,
  fetchOcupacion,
} from "../../redux/features/guardarropia/guardarropiaSlice";
import SkeletonTable from "../../components/Skeleton";

const DashboardGuardarropia = () => {
  const { info } = useAuth();
  const rol = info?.roles;
  const addRequestStatus = "idle";
  const espacios = useSelector(getAllEspacios);
  const dispatch = useDispatch();
  const status = useSelector(getEspacioStatus);
  const [data, setData] = useState([]);
  const [generalInfo, setGeneralInfo] = useState([]);

  const getOcupacion = async () => {
    try {
      const resp = await dispatch(fetchOcupacion());
      console.log("rep ocupación: ->", resp.payload);
      setData(resp.payload);
    } catch (error) {
      console.log("error al traer la ocupación: ", error);
    }
  };

  useEffect(() => {
    if (status == "idle") {
    }
    dispatch(fetchEspacios());
    getOcupacion();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const info = data.map((campamento) => ({
        nombre: campamento.nombre,
        capacidadTotal: campamento.ocupados + campamento.disponibles,
        ocupados: campamento.ocupados,
        disponibles: campamento.disponibles,
        porcentajeDeOcupacion: campamento.porcentajeDeOcupacion,
      }));
      setGeneralInfo(info);
    }
  }, [data]);

  if (!data) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Box width={1} mt={0} mb={2}>
        <Container className={styles.container} style={{ padding: 0 }}>
          <Card
            style={{
              width: "100%",
              padding: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              backgroundColor: "var(--inst-li-blue)",
            }}
          >
            <Grid container spacing={1}>
              {rol?.includes("Administrador de Guardarropía (AGU)") && (
                <Grid item xs={12} sm={4} md={3}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "20px",
                    }}
                  >
                    <NavLink
                      to="/almacenamiento"
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "5px",
                        textDecoration: "none",
                      }}
                    >
                      <DashboardIcon style={{ color: "var(--inst-orange)" }} />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "6px",
                        }}
                      >
                        <Typography style={{ color: "#FFF", marginLeft: "0" }}>
                          Almacenamiento
                        </Typography>
                        <Typography
                          style={{
                            marginBottom: "10px",
                            color: "#FFF",
                            marginLeft: "0",
                            fontSize: "12px",
                            border: "none",
                          }}
                        >
                          En este módulo, podrás administrar de manera eficiente
                          los espacios de almacenamiento de las instalaciones.
                        </Typography>
                      </div>
                    </NavLink>
                    <NavLink
                      to="/asignacion-espacios"
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "5px",
                        textDecoration: "none",
                      }}
                    >
                      <DashboardIcon style={{ color: "var(--inst-orange)" }} />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "6px",
                        }}
                      >
                        <Typography style={{ color: "#FFF", marginLeft: "0" }}>
                          Asignaciòn de espacios
                        </Typography>
                        <Typography
                          style={{
                            marginBottom: "10px",
                            color: "#FFF",
                            marginLeft: "0",
                            fontSize: "12px",
                            border: "none",
                          }}
                        >
                          Podras asignar un espacio disponible a un trabajador,
                          ingresar sus implementos de seguridad e ingresar un
                          registro del retiro.
                        </Typography>
                      </div>
                    </NavLink>
                  </div>
                </Grid>
              )}
              {Array.isArray(data) &&
                data.length > 0 &&
                data.map((campamento, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    key={index}
                    style={{ textAlign: "center" }}
                  >
                    {/*<Typography style={{ fontWeight:'500' }} gutterBottom>{campamento.nombre}</Typography> */}
                    <SingleChart data={campamento} />
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#FFF",
                        fontWeight: "500",
                      }}
                      gutterBottom
                    >
                      Capacidad Total:{" "}
                      {campamento.ocupados + campamento.disponibles}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#FFF",
                        fontWeight: "500",
                      }}
                      gutterBottom
                    >
                      Disponibles: {campamento.disponibles}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#FFF",
                        fontWeight: "500",
                      }}
                      gutterBottom
                    >
                      Ocupación Actual: {campamento.ocupados}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#FFF",
                        fontWeight: "500",
                      }}
                      gutterBottom
                    >
                      Porcentaje de Ocupación:{" "}
                      {campamento.porcentajeDeOcupacion}%
                    </Typography>
                  </Grid>
                ))}
              <Grid item xs={12} sm={4} md={3} style={{ textAlign: "center" }}>
                {/*<Typography style={{ fontWeight:'500' }} gutterBottom>Campamentos</Typography> */}
                {/*<Chart data={generalInfo}/> */}
              </Grid>

              {/*<Grid item xs={12} sm={6} md={3}>
                        <Chart data={data}/>
                      </Grid> */}
            </Grid>
          </Card>

          {status == "loading" ? (
            <SkeletonTable />
          ) : (
            <Table rows={espacios} addRequestStatus={addRequestStatus} />
          )}
        </Container>
      </Box>
    </div>
  );
};

export default DashboardGuardarropia;
