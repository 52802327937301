import styles from "./components.module.css";
import { useEffect, useState } from "react";
import { oficinaRecepcionestaColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import Delete from "./Delete";
import Edit from "./Edit";
import Modal from "./Modal";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
} from "@mui/material";
import Asignar from "../../oficinas/components/Asignar";

const Table = ({
  open2,
  setOpen2,
  registros,
  setOpen3,
  setCheck,
  check,
  campamentos,
  pabellones,
  wings,
  otros,
}) => {
  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openActualizar, setOpenActualizar] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCellValue, setSelectedCellValue] = useState("");
  const [selectedCellName, setSelectedCellName] = useState("");
  const [openAsignar, setOpenAsignar] = useState(false);

  const finalColumns = oficinaRecepcionestaColumns(
    setOpenActualizar,
    setOpen,
    setSeleItem,
    setOpenEdit,
    setCheck,
    setOpenAsignar,
    setOpenDelete
  );

  const handleCellClick = (params) => {
    console.log(params);
    if (params.field === "opciones") return;
    setSelectedCellValue(params.value);
    setSelectedCellName(params.field);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div style={{ marginTop: "16px" }}>
      <Delete
        open={openDelete}
        setOpen={setOpenDelete}
        seleItem={seleItem}
        setOpen3={setOpen3}
      />
      <Edit
        openEdit={openActualizar}
        open2={open2}
        setOpen2={setOpen2}
        setOpenEdit={setOpenActualizar}
        seleItem={seleItem}
        setOpen3={setOpen3}
        campamentos={campamentos}
        wings={wings}
        pabellones={pabellones}
        otros={otros}
      />
      <Backdrop sx={{ color: "#fff", zIndex: 1000000000000 }} open={open2}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        check={check}
        seleItem={seleItem}
        open={open}
        setOpen={setOpen}
        setOpen2={setOpen2}
      />
      <Asignar
        open={openAsignar}
        setOpen={setOpenAsignar}
        seleItem={seleItem}
        setOpenBack={setOpen2}
      />
      <DataGrid
        getRowId={(row) => (row.id ? row.id : Math.random())}
        lots={{ toolbar: GridToolbar }}
        rows={registros}
        columns={finalColumns}
        onCellClick={handleCellClick}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15, 20]}
        localeText={{
          ...esES.components.MuiDataGrid.defaultProps.localeText,
          noRowsLabel: "No hay datos disponibles",
          pagination: {
            labelRowsPerPage: "Filas por página:",
          },
        }}
        classes={{ menuIcon: styles.menuIcon }}
        style={{ maxHeight: "500px", color: "#FFF", border: "none" }}
        sx={{
          "& .MuiDataGrid-cell": {
            color: "#2d3037",
            borderBottom: "1px solid #c6c6c6",
          },
          "& .MuiDataGrid-columnHeader": {
            color: "#2d3037",
            borderBottom: "1px solid #c6c6c6",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "1px solid #c6c6c6",
          },
          "& .MuiDataGrid-footerContainer": {
            color: "#2d3037",
            borderTop: "1px solid #c6c6c6",
          },
          "& .MuiTablePagination-root": {
            color: "#2d3037",
            borderTop: "1px solid #c6c6c6",
          },
          "& .MuiDataGrid-toolbarContainer": {
            color: "#2d3037",
          },
          "& .MuiDataGrid-menu": {
            color: "#2d3037",
          },
          "& .MuiSvgIcon-root": {
            color: "#9ca91c",
          },
          "& .MuiTablePagination-actions": {
            color: "#2d3037", // Color for pagination actions
          },
          "& .MuiTablePagination-select": {
            color: "#2d3037", // Color for pagination select
          },
          "& .MuiTablePagination-selectLabel": {
            color: "#2d3037", // Color for pagination select label
          },
          "& .MuiTablePagination-displayedRows": {
            color: "#2d3037", // Color for displayed rows text
          },
          "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
            color: "red",
          },
          "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root":
            {
              color: "red",
            },
        }}
        slotProps={{
          toolbar: {
            sx: {
              color: "#FFF",
            },
          },
          pagination: {
            sx: {
              color: "#f19d38",
            },
          },
          menu: {
            sx: {
              color: "#f19d38",
            },
          },
        }}
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Box>{selectedCellValue ?? "-"}</Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Table;
