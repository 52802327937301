import styles from "./components.module.css";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState, forwardRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import Slide from "@mui/material/Slide";
import ChecklistIcon from "@mui/icons-material/Checklist";
import {
  addTareaAsignada,
  fetchAsignadas,
} from "../../../../redux/features/aseo/aseoSlice.js";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const options = [
  "Habitación ocupada",
  "Habitación sin uso",
  "Habitación en mantención",
  "Otro",
];

export default function ModalChange({
  openEdit,
  setOpenEdit,
  seleItem,
  setOpen3,
  open3,
  tareasList,
  planificacionId,
  setOpenModal,
  openModal,
  type,
  setOpenEditModal,
  getGrupo,
  getHabitacionesListasPlanificacionDiaria,
}) {
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [formData, setFormData] = useState(null);
  const dispatch = useDispatch();

  const [selectedItems, setSelectedItems] = useState([]);
  const [aseo, setAseo] = useState(true);
  const [image, setImage] = useState("");

  const handleToggle = (item) => {
    setSelectedItems((prevSelectedItems) => {
      let updatedSelectedItems;
      if (prevSelectedItems.includes(item)) {
        updatedSelectedItems = prevSelectedItems.filter(
          (selectedItem) => selectedItem !== item
        );
      } else {
        updatedSelectedItems = [...prevSelectedItems, item];
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        item: updatedSelectedItems,
      }));
      return updatedSelectedItems;
    });
  };

  const hizoAseo = (value) => {
    console.log(value);
    setAseo(value);
    setFormData((prev) => ({ ...prev, realizada: value }));
    if (value) {
      const tareasIds = tareasList.map((it) => it.id);
      console.log("tareasIds: -->", tareasIds);
      setFormData((prev) => ({ ...prev, item: tareasIds, observaciones: "" }));
      setSelectedItems([]);
    } else {
      setFormData((prev) => ({ ...prev, item: [], observaciones: "" }));
    }
  };

  useEffect(() => {
    setAseo(true);
    if (seleItem) {
      console.log("tareasList", tareasList);
      const tareasIds = tareasList.map((it) => it.id);
      if (type === "habitaciones") {
        setFormData((prev) => ({
          ...prev,
          habitacionId: seleItem?.id,
          realizada: true,
          planificacionDiariaId: planificacionId,
          item: tareasIds,
          observaciones: "",
        }));
      } else if (type === "oficinas") {
        setFormData((prev) => ({
          ...prev,
          oficinaId: seleItem?.id,
          realizada: true,
          planificacionDiariaId: planificacionId,
          item: tareasIds,
          observaciones: "",
        }));
      } else if (type === "espacios") {
        setFormData((prev) => ({
          ...prev,
          espacioId: seleItem?.id,
          realizada: true,
          planificacionDiariaId: planificacionId,
          item: tareasIds,
          observaciones: "",
        }));
      }
      setSelectedItems([]);
    }
    console.log(seleItem);
  }, [seleItem]);

  useEffect(() => {
    console.log("formData.item:-->->", formData?.item);
  }, [formData?.item]);

  // useEffect(() => {
  //   if(selectedItems.length > 0 || selectedItems.length == 1){
  //       console.log('selectedItems cambio', selectedItems);
  //       setFormData(prev => ({...prev, item: selectedItems}))
  //     }
  // }, [selectedItems])

  const onChange = (e) => {
    if (e.target.value != "Otro") {
      setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    } else {
      setFormData((prev) => ({ ...prev, otro: "otro", observaciones: "" }));
    }
  };

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Verifica si el archivo es una imagen
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const imageUrl = e.target.result;
          // Aquí puedes hacer algo con la URL de la imagen, como mostrarla en un <img>
          console.log("Imagen cargada:", imageUrl);
          setImage(imageUrl);
        };
        reader.readAsDataURL(file);
        setFormData((prev) => ({ ...prev, imagen: file }));
      } else {
        console.error("Por favor, selecciona un archivo de imagen.");
      }
    }
  };

  const canSave =
    (formData?.habitacionId || formData?.oficinaId || formData?.espacioId) &&
    addRequestStatus === "idle";

  const handleEdit = async () => {
    try {
      console.log("handleEdit");
      setOpen3(true);
      setAddRequestStatus("loading");
      let resp = await dispatch(addTareaAsignada(formData));
      if (resp.payload.status === "success") {
        getGrupo();
        getHabitacionesListasPlanificacionDiaria();
        setOpenModal(false);
        // await dispatch(fetchAsignadas());
        // console.log("seleItem aa: ", seleItem);
      }
    } catch (error) {
      console.error("Error al actualizar el registro", error.message);
    } finally {
      setAddRequestStatus("idle");
      setOpen3(false);
    }
  };

  return (
    <div>
      <Dialog
        open={openModal}
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        TransitionComponent={Transition}
        onBackdropClick={() => {
          setOpenModal(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display: "flex", gap: "5px" }}>
          <ChecklistIcon
            className={styles.iconBgCreate}
            style={{ fontSize: "3rem", marginTop: "0px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="span" style={{ fontSize: "24px" }}>
              {type === "habitaciones" &&
                `Listado de tareas habitación ${seleItem?.numero}`}
              {type === "oficinas" &&
                `Listado de tareas oficina ${seleItem?.numero}`}
              {type === "espacio" &&
                `Listado de tareas espacio común ${seleItem?.nombre}`}
            </Typography>
            <span className={styles.subTitle}>
              Al seleccionar la opción "No", marque las tareas que pudo
              completar, y luego seleccione el motivo por el cual no pudo
              terminar el aseo.
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1} mt={1}>
            <Grid item xs={12} md={12}>
              <label>¿Se hizo aseo?</label>
              <FormControlLabel
                style={{ marginLeft: "10px" }}
                control={
                  <Checkbox
                    checked={aseo === true}
                    onChange={() => hizoAseo(true)}
                  />
                }
                label="Sí"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={aseo === false}
                    onChange={() => hizoAseo(false)}
                  />
                }
                label="No"
              />
            </Grid>
            {!aseo && (
              <>
                <Grid item xs={12} md={12}>
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      fontWeight: "500",
                      fontSize: "16px",
                      marginBottom: "10px",
                    }}
                  >
                    Marque las tareas que si pudo completar
                  </Typography>
                  <FormGroup>
                    {tareasList.map((item) => (
                      <FormControlLabel
                        key={item.id}
                        control={
                          <Checkbox
                            checked={selectedItems.includes(item.id)}
                            onChange={() => handleToggle(item.id)}
                          />
                        }
                        label={item.nombre}
                      />
                    ))}
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={12}>
                  <input
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={(e) => handleFile(e)}
                    accept="image/*"
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Subir archivo
                    </Button>
                  </label>
                </Grid>

                {image && (
                  <img
                    src={process.env.PUBLIC_URL + image}
                    alt="loginImg"
                    style={{ width: "100%", marginTop: "10px" }}
                  />
                )}

                <Grid item xs={12} md={12}>
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      fontWeight: "500",
                      fontSize: "16px",
                      marginBottom: "10px",
                    }}
                  >
                    {type === "habitaciones" &&
                      "Seleccione el motivo por el cual no se completó el aseo en la habitación."}
                    {type === "oficinas" &&
                      "Seleccione el motivo por el cual no se completó el aseo en la oficina."}
                    {type === "espacios" &&
                      "Seleccione el motivo por el cual no se completó el aseo en el espacio común."}
                  </Typography>
                  <Select
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                      "& .MuiAutocomplete-clearIndicator": {
                        color: "#2d3037",
                      },
                      "& .MuiAutocomplete-popupIndicator": {
                        color: "#2d3037",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData?.observaciones || ""}
                    name="observaciones"
                    onChange={(e) => onChange(e)}
                    style={{ width: "100%" }}
                  >
                    {options.map((item) => (
                      <MenuItem value={item} key={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {formData.otro == "otro" && (
                  <Grid item xs={12} md={12}>
                    <label className={styles.label}>Ingrese el motivo</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          color: "#2d3037",
                        },
                        "& .MuiAutocomplete-popupIndicator": {
                          color: "#2d3037",
                        },
                      }}
                      // value={formData?.observaciones}
                      variant="outlined"
                      name="observaciones"
                      type="text"
                      maxRows={4}
                      style={{ width: "100%" }}
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true} style={{ padding: "0 16px" }}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button
                onClick={handleEdit}
                className={styles.bgColorModal}
                variant="contained"
                disabled={!canSave}
              >
                {addRequestStatus == "loading" ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Actualizar"
                )}
              </Button>
              <Button
                onClick={() => setOpenModal(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
