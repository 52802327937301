import styles from "./components/components.module.css";
import { Container, Box, Card, CardContent, Button } from "@mui/material";
import LoginForm from "./components/LoginForm";
import { useEffect, useState } from "react";
// import WorkerTypeForm from "./WorkType";
// import SSO from "./SSO";
import { toast } from "react-toastify";

const Login = () => {
  const [workerType, setWorkerType] = useState(null);

  useEffect(() => {
    const storedWorkerType = localStorage.getItem("tipo_trabajador");
    const errorGetToken = localStorage.getItem("error_get_token");
    if (storedWorkerType) {
      setWorkerType(storedWorkerType);
    }
    if (errorGetToken) {
      toast.error("Trabajador no encontrado");
    }
  }, []);

  const handleWorkerTypeRegister = () => {
    const storedWorkerType = localStorage.getItem("tipo_trabajador");
    setWorkerType(storedWorkerType);
  };

  const toggleWorkerType = () => {
    const newWorkerType = workerType === "P_EXTERNO" ? "P_INTERNO" : "P_EXTERNO";
    localStorage.setItem("tipo_trabajador", newWorkerType);
    setWorkerType(newWorkerType);
  };

  return (
    <div className="w-full d-flex align-items-center justify-content-center h-full">
      <Box className="h-100 login_box d-flex align-items-center justify-content-center">
        <Container maxWidth="xs" style={{ display: "flex" }}>
          <Card
            style={{
              boxShadow: "0px 10px 20px 2px rgba(0, 0, 0, 0.25)",
              borderRadius: "6px",
              // paddingTop: "20px",
              minHeight: "550px",
            }}
            className={styles.cardBgBlur}
          >
            <CardContent className="text-start" style={{ padding: "0" }}>
              <img
                src={process.env.PUBLIC_URL + "/psinet.jpg"}
                alt="loginImg"
                width={100}
                height={100}
                style={{ objectFit: "contain", backgroundColor: "var(--inst-li-Head-Module)",padding: "20px", marginBottom: "4px" }}
              />
            </CardContent>
            <CardContent>
              <LoginForm />
            </CardContent>
            {/* <CardContent>
              {workerType ? (
                workerType === "P_EXTERNO" ? (
                  <>
                    <LoginForm />
                    <Button
                      onClick={toggleWorkerType}
                      className={styles.bgColorOutlined}
                      style={{
                        width: "100%",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                      }}
                    >
                      Cambiar a P_INTERNO
                    </Button>
                  </>
                ) : (
                  <>
                    <SSO />
                    <Button
                      onClick={toggleWorkerType}
                      className={styles.bgColorOutlined}
                      style={{
                        width: "100%",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                      }}
                    >
                      Cambiar a P_EXTERNO
                    </Button>
                  </>
                )
              ) : (
                <WorkerTypeForm onRegister={handleWorkerTypeRegister} />
              )}
            </CardContent> */}
          </Card>
        </Container>
      </Box>
    </div>
  );
};

export default Login;
