import styles from "./components.module.css";
import { useEffect, useState } from "react";
import { planificacionColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import Delete from "./Delete";
import Edit from "./Edit";
import Edit2 from "./Edit2";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";

const Table = ({
  registros,
  setOpen3,
  open3,
  grupos,
  franjas,
  setSelectedItems,
  selectedItems,
  tareas,
  personal,
}) => {
  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [openReset, setOpenReset] = useState(false);
  const [openDatos, setOpenDatos] = useState(false);
  const [verCalendario, setVerCalendario] = useState(false);
  const [editOrCreate, setEditOrCreate] = useState(false);
  const [selectModel, setSelectModel] = useState();
  const [rowHeight, setRowHeight] = useState(100);
  const [selectedFranja, setSelectedFranja] = useState("");
  const [selectedInicio, setSelectedInicio] = useState("");
  const [selectedPersonal, setSelectedPersonal] = useState("");

  const [abrirEdit, setAbrirEdit] = useState(false);
  const [abrirRepetir, setAbrirRepetir] = useState(false);

  const [filterRegistros, setFilterRegistros] = useState([]);

  const finalColumns = planificacionColumns(
    setOpen,
    setSeleItem,
    setAbrirEdit,
    setAbrirRepetir
  );

  const handleRowSelectionModelChange = (selectionModel) => {
    const selectedRows = selectionModel.map((id) => {
      return registros.find((row) => row.id === id);
    });
    setSelectedItems(selectedRows.map((row) => ({ ...row })));
    console.log("selectedRows:", selectedRows);
  };

  useEffect(() => {
    setFilterRegistros(registros);
    console.log(registros);
  }, [registros]);

  const handleChange = (name, value) => {
    if (value) {
      // Almacenar el valor anterior de los filtros antes de cambiarlos
      const previousFranja = selectedFranja;
      const previousInicio = selectedInicio;
      const previousPersonal = selectedPersonal;

      if (name === "franja") {
        const filteredRegistros = registros.filter((item) =>
          item.horarioAseo.nombre
            .toLowerCase()
            .includes(value.nombre.toLowerCase())
        );
        setFilterRegistros(filteredRegistros);
        setSelectedFranja(value);
        // Mantener los valores anteriores para los otros campos
        setSelectedInicio(previousInicio);
        setSelectedPersonal(previousPersonal);
      } else if (name === "inicio") {
        const filteredRegistros = registros.filter((item) =>
          item.desde.toLowerCase().includes(value)
        );
        setFilterRegistros(filteredRegistros);
        setSelectedInicio(value);
        setSelectedFranja(previousFranja);
        setSelectedPersonal(previousPersonal);
      } else if (name === "personal") {
        const filteredRegistros = registros.filter((item) =>
          item.trabajadores.some((trabajador) =>
            trabajador.rut.includes(value.rut)
          )
        );
        setFilterRegistros(filteredRegistros);
        setSelectedPersonal(value);
        setSelectedFranja(previousFranja);
        setSelectedInicio(previousInicio);
      }
    } else {
      // Si el valor es vacío, restablecer todos los filtros
      setFilterRegistros(registros);
      setSelectedFranja("");
      setSelectedInicio("");
      setSelectedPersonal("");
    }
  };

  const clearDate = () => {
    setSelectedInicio("");
    setFilterRegistros(registros);
  };

  return (
    <>
      <Delete
        open={open}
        setOpen={setOpen}
        seleItem={seleItem}
        setOpen3={setOpen3}
      />
      <Edit
        setOpen2={setAbrirEdit}
        open2={abrirEdit}
        seleItem={seleItem}
        setOpen3={setOpen3}
        grupos={grupos}
        open3={open3}
        franjas={franjas}
        tareas={tareas}
        personal={personal}
      />
      <Edit2
        setOpen2={setAbrirRepetir}
        open2={abrirRepetir}
        seleItem={seleItem}
        setOpen3={setOpen3}
        grupos={grupos}
        open3={open3}
        franjas={franjas}
        tareas={tareas}
        personal={personal}
      />

      <Typography
        style={{ fontSize: "16px", fontWeight: "500", marginTop: "20px" }}
      >
        Busqueda por filtros
      </Typography>

      <Grid container={true} spacing={1} mb={2}>
        <Grid item xs={12} md={4}>
          <label className={styles.label}>Franja</label>
          <Autocomplete
            className={styles.textField}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
              ".MuiChip-label": {
                color: "#2d3037",
              },
              ".MuiSvgIcon-root": {
                color: "#2d3037",
              },
              "& .MuiInputBase-input": {
                color: "#2d3037",
              },
              "& .MuiInputLabel-root": {
                color: "#2d3037",
              },
              "& .MuiAutocomplete-clearIndicator": {
                color: "#2d3037",
              },
              "& .MuiAutocomplete-popupIndicator": {
                color: "#2d3037",
              },
              borderBottom: "1px solid #2d3037",
              borderRadius: "0px !important",
            }}
            disablePortal
            id="franja-autocomplete"
            options={franjas || []}
            name="franja"
            value={selectedFranja || null}
            getOptionLabel={(option) => option.nombre}
            onChange={(e, value) => handleChange("franja", value)}
            renderInput={(params) => (
              <TextField {...params} placeholder="Seleccione una franja" />
            )}
          />
        </Grid>
        <Grid item xs={10} md={3}>
          <label className={styles.label}>Inicio</label>
          <TextField
            className={styles.textField}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
              ".MuiChip-label": {
                color: "#2d3037",
              },
              ".MuiSvgIcon-root": {
                color: "#2d3037",
              },
              "& .MuiInputBase-input": {
                color: "#2d3037",
              },
              "& .MuiInputLabel-root": {
                color: "#2d3037",
              },
              "& .MuiAutocomplete-clearIndicator": {
                color: "#2d3037",
              },
              "& .MuiAutocomplete-popupIndicator": {
                color: "#2d3037",
              },
              borderBottom: "1px solid #2d3037",
              borderRadius: "0px !important",
            }}
            variant="outlined"
            fullWidth
            name="inicio"
            value={selectedInicio || ""}
            type="date"
            onChange={(e, value) => handleChange("inicio", e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={2}
          md={1}
          style={{ display: "flex", alignItems: "end", width: "100%" }}
        >
          <div
            onClick={clearDate}
            className={styles.bgColorDelete}
            variant="contained"
            style={{
              padding: "0",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              cursor: "pointer",
              opacity: ".8",
              height: "56px",
              width: "100%",
            }}
          >
            x
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <label className={styles.label}>Asignado</label>
          <Autocomplete
            className={styles.textField}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
              ".MuiChip-label": {
                color: "#2d3037",
              },
              ".MuiSvgIcon-root": {
                color: "#2d3037",
              },
              "& .MuiInputBase-input": {
                color: "#2d3037",
              },
              "& .MuiInputLabel-root": {
                color: "#2d3037",
              },
              "& .MuiAutocomplete-clearIndicator": {
                color: "#2d3037",
              },
              "& .MuiAutocomplete-popupIndicator": {
                color: "#2d3037",
              },
              borderBottom: "1px solid #2d3037",
              borderRadius: "0px !important",
            }}
            disablePortal
            id="franja-autocomplete"
            options={personal || []}
            name="personal"
            value={selectedPersonal || null}
            getOptionLabel={(option) => option.nombre}
            onChange={(e, value) => handleChange("personal", value)}
            renderInput={(params) => (
              <TextField {...params} placeholder="Seleccione un asignado" />
            )}
          />
        </Grid>
      </Grid>
      {/*
        <NuevoDatosAnteriores openDatos={openDatos} setOpenDatos={setOpenDatos} setOpen3={setOpen3} grupos={grupos} habitaciones={habitaciones} franjas={franjas} seleItem={seleItem}/>
        */}
      <DataGrid
        getRowId={(row) => (row.id ? row.id : Math.random())}
        lots={{ toolbar: GridToolbar }}
        rows={filterRegistros}
        checkboxSelection
        rowSelectionModel={selectedItems.map((item) => item.id)}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        columns={finalColumns}
        rowHeight={80}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15, 20]}
        localeText={{
          ...esES.components.MuiDataGrid.defaultProps.localeText,
          noRowsLabel: "No hay datos disponibles",
          pagination: {
            labelRowsPerPage: "Filas por página:",
          },
        }}
        classes={{ menuIcon: styles.menuIcon }}
        style={{ maxHeight: "500px", color: "#FFF", border: "none" }}
        sx={{
          "& .MuiDataGrid-cell": {
            color: "#2d3037",
            borderBottom: "1px solid #c6c6c6",
          },
          "& .MuiDataGrid-columnHeader": {
            color: "#2d3037",
            borderBottom: "1px solid #c6c6c6",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "1px solid #c6c6c6",
          },
          "& .MuiDataGrid-footerContainer": {
            color: "#2d3037",
            borderTop: "1px solid #c6c6c6",
          },
          "& .MuiTablePagination-root": {
            color: "#2d3037",
            borderTop: "1px solid #c6c6c6",
          },
          "& .MuiDataGrid-toolbarContainer": {
            color: "#2d3037",
          },
          "& .MuiDataGrid-menu": {
            color: "#2d3037",
          },
          "& .MuiSvgIcon-root": {
            color: "#9ca91c",
          },
          "& .MuiTablePagination-actions": {
            color: "#2d3037", // Color for pagination actions
          },
          "& .MuiTablePagination-select": {
            color: "#2d3037", // Color for pagination select
          },
          "& .MuiTablePagination-selectLabel": {
            color: "#2d3037", // Color for pagination select label
          },
          "& .MuiTablePagination-displayedRows": {
            color: "#2d3037", // Color for displayed rows text
          },
          "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
            color: "red",
          },
          "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root":
            {
              color: "red",
            },
        }}
        slotProps={{
          toolbar: {
            sx: {
              color: "#FFF",
            },
          },
          pagination: {
            sx: {
              color: "#f19d38",
            },
          },
          menu: {
            sx: {
              color: "#f19d38",
            },
          },
        }}
      />
    </>
  );
};

export default Table;

{
  /* 
        <Calendar openEdit={openEdit} setOpenEdit={setOpenEdit} seleItem={seleItem} setOpen3={setOpen3} open3={open3} verCalendario={verCalendario} setVerCalendario={setVerCalendario}/> 
        <Reset open={openReset} setOpen={setOpenReset} seleItem={seleItem} setOpen3={setOpen3}/> 
      */
}

{
  /*
        <NuevoDatosAnteriores openDatos={openDatos} setOpenDatos={setOpenDatos} setOpen3={setOpen3} grupos={grupos} habitaciones={habitaciones} franjas={franjas} seleItem={seleItem}/>
        */
}
