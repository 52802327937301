import styles from "./components.module.css";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { forwardRef, useEffect, useState, useTransition } from "react";
import { useForm } from "react-hook-form";
import { validateLoginCredentials } from "../../../utils/validate";
import NightShelterIcon from "@mui/icons-material/NightShelter";
import GavelIcon from "@mui/icons-material/Gavel";
import LoginIcon from "@mui/icons-material/Login";
import { useAuth } from "../../../context/AppContext";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../../redux/features/login/loginSlice";
import PinIcon from "@mui/icons-material/Pin";
import Slide from "@mui/material/Slide";
import { toast } from "react-toastify";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CodeModal = ({ openModal, setOpenModal, id, sendCode }) => {
  const [formData, setFormData] = useState({ codigo: "" });
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    console.log("id-->", id);
    setFormData({ ...formData, id: id });
  }, [id]);

  const handleSubmit = async () => {
    try {
      setAddRequestStatus("loading");
      console.log("enviando: ", formData);
      await sendCode(formData);
    } catch (error) {
      toast.error("Código incorrecto o expirado.");
      console.log("error al enviar el codigo en LoginForm.jsx->");
    } finally {
      setAddRequestStatus("idle");
    }
  };

  return (
    <Dialog
      open={openModal}
      maxWidth="sm"
      PaperProps={{
        sx: {
          backgroundColor: "#efefef",
          color: "#2d3037",
          borderRadius: "0",
        },
      }}
      TransitionComponent={Transition}
      onBackdropClick={() => {
        setOpenModal(true);
      }}
    >
      <DialogTitle style={{ display: "flex", gap: "5px" }}>
        <NightShelterIcon
          className={styles.iconBgCreate}
          style={{ fontSize: "3rem", marginTop: "0px" }}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="span"
            style={{ fontSize: "24px", lineHeight: "1" }}
          >
            Autenticación de cuenta
          </Typography>
          <span className={styles.subTitle}>
            Ingresa el código que se ha enviado a tu correo electrónico.
          </span>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container={true} spacing={1}>
          <Grid item xs={12}>
            <label className={styles.label}>Código</label>
            <TextField
              className={styles.textField}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#2d3037",
                },
                "& .MuiInputLabel-root": {
                  color: "#2d3037",
                },
                "& .MuiAutocomplete-clearIndicator": {
                  color: "#2d3037",
                },
                "& .MuiAutocomplete-popupIndicator": {
                  color: "#2d3037",
                },
              }}
              variant="outlined"
              fullWidth
              name="codigo"
              type="text"
              onChange={(e) => onChange(e)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container={true} style={{ padding: "0 16px" }}>
          <Grid item xs={12} sm={12} className={styles.buttonContainer}>
            <Button
              onClick={handleSubmit}
              className={styles.bgColorModal}
              variant="contained"
              disabled={!formData.codigo}
            >
              {addRequestStatus == "loading" ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "Ingresar"
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const LoginForm = () => {
  const { login, sendCode } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ email: "", pass: "" });
  const updateData = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const [errors, setErrors] = useState({ email: "", pass: "" });
  const [error, setError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(null);

  const onSubmit = async () => {
    if (!formData.acceptTerms) {
      toast.error("Debes aceptar los términos y condiciones.");
      return null;
    }

    setError("");
    setLoading(true);

    try {
      errors.email = "";
      errors.pass = "";
      const validate = validateLoginCredentials(formData);

      if (validate.error) {
        setErrors({ ...errors, [validate.error.type]: validate.error.message });
        console.log(errors);
        return;
      }
      const resp = await login({
        email: formData.email,
        password: formData.pass,
      });

      console.log("resp del login", resp);
      if (resp.success === false) {
        console.log("<--- entra aqui -->");

        setError(resp.message);
        toast.error(resp.message);
        return;
      }
      console.log("success", resp);

      if (
        resp.data.message &&
        resp.data.message == "Requiere Autenticación de dos factores"
      ) {
        toast.success(
          "Se ha enviado un código a tu correo. Debes ingresar ese código para completar la autenticación de tu cuenta."
        );

        setOpenModal(true);
        setId(resp.data.id);
        return;
      }
    } catch (error) {
      console.log("error en login form-->", error);

      if (error.response) {
        if (error.response.status === 500) {
          console.log("status: ", error.response.status);
          setError("Ups, problemas con la conexión.");
          toast.error("Ups, problemas con la conexión.");
          console.log("error en el login", error.response);
        } else {
          setError(error.response.data);
          toast.error(error.response.data);
        }
      } else if (error) {
        // setError("Ocurrió un error inesperado. Por favor, intenta nuevamente.");
        // toast.error(
        //   "Ocurrió un error inesperado. Por favor, intenta nuevamente."
        // );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container={true} spacing={2} style={{ padding: "5px" }}>
      <Dialog
        open={modal}
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        TransitionComponent={Transition}
        onBackdropClick={() => {
          setModal(false);
        }}
      >
        <DialogTitle
          style={{ display: "flex", gap: "5px", alignItems: "center" }}
        >
          <GavelIcon
            className={styles.iconBgCreate}
            style={{ fontSize: "2rem", marginTop: "0px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Declaraciones y Autorizaciones en el uso del Sistema de Campamento
              Psinet
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true}>
            <Grid item xs={12} md={12}>
              <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
                Sin perjuicio de los términos y condiciones de la propia
                aplicación Sistema de Campamento, las personas trabajadoras de
                Psinet y P_EXTERNO que aceptan utilizar el la Plataforma de
                Sistema de Campamento Psinet declaran y autorizan lo
                siguiente:
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
              >
                Personas que utilizan Sistema de Campamento
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
                Solamente las personas trabajadoras dependientes de Psinet y
                trabajadores (as) P_EXTERNO que pernocten en Faena Cordillera o que
                utilicen oficinas en Faena Cordillera o Puerto Psinet en
                Patache, podrán utilizar la plataforma del Sistema de
                Campamento.
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
              >
                Voluntariedad
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
                Las personas trabajadoras declaran que el uso de la Plataforma
                de Sistema de Campamento, donde se realizan solicitudes de
                información por parte del huésped, reclamos o sugerencias,
                recepción de información desde Campamento, plataforma que
                Psinet pone a disposición de sus trabajadores y de personal
                de las P_EXTERNO, es voluntario, no constituyendo una obligación
                laboral. De esta forma, las personas trabajadoras en su calidad
                de huésped, que no utilicen la plataforma no sufrirán
                represalias de parte de Psinet.
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
              >
                Autorización tratamiento datos personales
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
                Las personas trabajadoras o visitas que utilizan el Sistema de
                Campamento Psinet autorizan a Psinet a gestionar, por sí
                o por terceros, sus datos personales que registren en el Sistema
                de Campamento, con el propósito de garantizar el correcto uso de
                la plataforma y la seguridad de las personas, con fines
                estadísticos y para introducirle mejoras al servicio, de ser
                necesario. Psinet en el tratamiento de datos personales
                respetará las normas sobre confidencialidad y las propias de
                tratamiento de datos personales
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
              >
                Relación entre la declaración y autorización Psinet y los
                términos y condiciones de Sistema de Campamento
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
                En caso de contradicción entre la presente declaración y
                autorización otorgada a Psinet y los términos y condiciones
                del Sistema de Campamneto Psinet, prevalecerá lo establecido
                en la presente declaración.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true} style={{ padding: "0 16px" }}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button
                onClick={() => setModal(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <CodeModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        id={id}
        sendCode={sendCode}
      />
      <Grid item={true} xs={12} style={{ paddingTop: "0px" }}>
        <h3
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            fontWeight: "500",
            paddingTop: "0",
          }}
        >
          Inicio de sesión <PinIcon />
        </h3>
      </Grid>
      <Grid item={true} xs={12}>
        <span>Correo</span>
        <TextField
          variant="outlined"
          type="email"
          name="email"
          className={styles.bgInput}
          placeholder="correo@psinet.cl"
          error={errors.email.length > 0 ? true : false}
          helperText={
            errors.email.length > 0 && (
              <span style={{ fontFamily: "Poppins" }}>{errors.email}</span>
            )
          }
          fullWidth={true}
          onChange={(e) => updateData(e)}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <span>Contraseña</span>
        <TextField
          variant="outlined"
          type="password"
          name="pass"
          placeholder="*******"
          fontFamily="Poppins"
          className={styles.bgInput}
          error={errors.pass.length > 0 ? true : false}
          helperText={
            errors.pass.length > 0 && (
              <span style={{ fontFamily: "Poppins" }}>{errors.pass}</span>
            )
          }
          fullWidth={true}
          onChange={(e) => updateData(e)}
          style={{ fontFamily: "Poppins" }}
        />
      </Grid>
      <Grid item={true} xs={12} mb={0} className="text-start">
        <span>
          Estás a punto de entrar al sistema de Gestión de Campamentos de
          Psinet.
        </span>
      </Grid>
      <Grid item={true} xs={12} mb={0}>
        <FormControlLabel
          control={
            <Checkbox
              name="acceptTerms"
              onChange={(e) =>
                setFormData({ ...formData, acceptTerms: e.target.checked })
              }
              checked={formData.acceptTerms || false}
              required
            />
          }
          label={
            <span style={{ fontSize: "14px" }}>
              Acepto los{" "}
              <span
                onClick={() => setModal(true)}
                style={{ color: "#f19d38", fontSize: "14px" }}
              >
                Términos y Condiciones
              </span>
            </span>
          }
        />
      </Grid>
      <Grid item={true} xs={12} mb={0}>
        <Button
          style={{
            background: !loading ? "#0086ae" : "#004a91",
            fontFamily: "Poppins",
            marginBottom: error ? "10px" : "0",
          }}
          variant="contained"
          size="large"
          disabled={loading}
          fullWidth={true}
          onClick={onSubmit}
        >
          {!loading && (
            <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              Entrar
              <LoginIcon style={{ fontSize: "16px" }} />
            </span>
          )}
          {loading && <CircularProgress size={24} style={{ color: "white" }} />}
        </Button>
        {/*{error && <span className="error-toast">{error}</span>} */}
      </Grid>
      <Grid item={true} xs={12} mb={2} className="text-start">
        <Link
          to="/getpass"
          style={{ textDecoration: "none", color: "#f19d38" }}
        >
          <span>Olvidaste tu contraseña? Recuperala aquí</span>
        </Link>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
