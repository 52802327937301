import styles from "./admAplicacion.module.css";
import { Box, Card, Container, Grid, Typography } from "@mui/material";
import CardSlider from "./components/Slider";
import { NavLink } from "react-router-dom";
import TextsmsIcon from "@mui/icons-material/Textsms";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import HistorialReclamos from "./components/HistorialReclamos";
import ProximaSubido from "./components/ProximaSubido";
import OficinaAsignadaHuesped from "./components/OficinaAsignadaHuesped";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchReclamosSugerenciasHuesped,
  fetchReclamosSugerenciasHuespedData,
  getAllReclamosSugerencias,
  getAllReclamosSugerenciasHuesped,
  getReclamosSugerenciasStatus,
} from "../../redux/features/reclamos_sugerencias/reclamos_sugerenciaSlice";
import { useEffect } from "react";
import {
  fetchReservasHuesped,
  getAllReservasHuesped,
  getReservasStatus,
} from "../../redux/features/reservas/reservaSlice";
import {
  fetchEspacios,
  getAllEspacios,
  getEspacioStatus,
} from "../../redux/features/guardarropia/guardarropiaSlice";

const DashboardHuesped = () => {
  const reclamosSugerencias = useSelector(getAllReclamosSugerenciasHuesped);
  const status = useSelector(getReclamosSugerenciasStatus);
  const reservas = useSelector(getAllReservasHuesped);
  const oficinaAsignada = useSelector(getAllReservasHuesped);
  const espacios = useSelector(getAllEspacios);
  const espaciosStatus = useSelector(getEspacioStatus);
  const statusRes = useSelector(getReservasStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (status == "idle") {
      dispatch(fetchReclamosSugerenciasHuesped());
    }
    if (statusRes == "idle") {
      dispatch(fetchReservasHuesped());
    }
    if (espaciosStatus == "idle") {
      dispatch(fetchEspacios());
    }
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Grid
        container={true}
        className={styles.container}
        style={{ padding: 0 }}
        spacing={1}
      >
        {/* <Hero/> */}
        <Grid item xs={12} sm={3}>
          <Card
            className={styles.card}
            style={{
              backgroundColor: "var(--inst-li-blue)",
              padding: "16px",
              color: "#FFF",
              margin: "10px 0",
              border: "none",
            }}
          >
            <NavLink
              to="/info-reservas"
              style={{ textDecoration: "none", color: "#FFF" }}
            >
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <EventAvailableIcon
                    sx={{
                      fontSize: "20px",
                      color: "#f19d38",
                      marginRight: "2px",
                    }}
                  />
                  <label className={styles.label} style={{ fontSize: "14px" }}>
                    <strong>Reservas</strong>
                  </label>
                </div>
                <Typography
                  style={{
                    color: "#FFF",
                    marginLeft: "0",
                    fontSize: "14px",
                    border: "none",
                  }}
                >
                  Aquí podrás visualizar un historial de tus reservas.
                </Typography>
              </div>
            </NavLink>

            <NavLink
              to="/reclamos-sugerencias"
              style={{ textDecoration: "none", color: "#FFF" }}
            >
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextsmsIcon
                    sx={{
                      fontSize: "20px",
                      color: "#f19d38",
                      marginRight: "2px",
                    }}
                  />
                  <label className={styles.label} style={{ fontSize: "14px" }}>
                    <strong>Reclamos y sugerencias</strong>
                  </label>
                </div>
                <Typography
                  style={{
                    color: "#FFF",
                    marginLeft: "0",
                    fontSize: "14px",
                    border: "none",
                  }}
                >
                  Aquí podrás visualizar y crear reclamos y sugerencias.
                </Typography>
              </div>
            </NavLink>
          </Card>
          <ProximaSubido
            reservas={reservas.reservas ? reservas.reservas : []}
            status={statusRes}
          />
          <OficinaAsignadaHuesped
            reservas={oficinaAsignada.reservaOficina ?? []}
            status={statusRes}
          />
          <CardSlider espacios={espacios} espaciosStatus={espaciosStatus} />
        </Grid>
        <Grid item xs={12} sm={9}>
          <HistorialReclamos reclamosSugerencias={reclamosSugerencias} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardHuesped;
