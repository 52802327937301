import styles from "../components.module.css";
import {
  Backdrop,
  Box,
  Container,
  CircularProgress,
  Grid,
} from "@mui/material";
import Header from "../../../components/header/Header";
import MapIcon from "@mui/icons-material/Map";
import Snackbar from "@mui/material/Snackbar";
import TableCard from "../../../components/tableCard/TableCard";
import Table from "./components/TableHabReservas";
import TableAsignaciones from "./components/TableAsignaciones";
import TablePorAsignar from "./components/TablePorAsignarReservas";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SkeletonTable from "../../../components/Skeleton";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { sanitizeFilterModel } from "@mui/x-data-grid/hooks/features/filter/gridFilterUtils";
import { toast } from "react-toastify";
import {
  asignarHabitacionPermanente,
  fetchHabitacionesP_INTERNO,
  fetchReservasP_INTERNO,
  fetchRotativos,
  fetchTrabajadoresP_INTERNO,
  getAllHabitacionesP_INTERNO,
  getAllreservasP_INTERNO,
  getAllRotativos,
  getAllTrabajadoresP_INTERNO,
  getHabitacionesP_INTERNOStatus,
  getReservasP_INTERNOStatus,
  getRotativosStatus,
  getTrabajadoresP_INTERNOStatus,
  modificarReservaP_interno,
} from "../../../redux/features/reservas/reservaSlice";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ToastWithAlert({
  open,
  handleClose,
  message,
  severity,
  handleSubmit,
}) {
  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={null} // Desactiva el autosecado
      disableWindowBlurListener // Evita el cierre cuando se hace clic fuera
    >
      <Alert
        onClose={handleClose}
        severity={severity} // 'success', 'error', 'info', 'warning'
        sx={{ width: "100%" }}
        style={{ display: "flex", alignItems: "center" }}
        action={
          <Button color="inherit" onClick={handleSubmit}>
            Confirmar
          </Button>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

const habitaciones = [
  {
    id: 27562,
    numero: "100",
    standard: {
      id: 60,
      nombre: "ROTATIVO",
      descripcion: "",
      campamento: {
        id: 25,
        nombre: "Coposa",
        direccion: "dsaadsa",
        descripcion: "descripción",
        plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
        latitud: "-33.5281715",
        longitud: "-70.5807978",
      },
      campamentoId: 25,
    },
    nroCamarotes: 0,
    nroCamas: 2,
    numeroTotalCamas: 2,
    tipoHuesped: "Solo mujeress",
    tieneOxigeno: false,
    pabellon: null,
    idChapa: null,
    piso: 1,
    wing: {
      id: 14,
      nombre: "A",
      descripcion: "",
      numeroPisos: 3,
      numeroHabitaciones: 0,
      generoHuesped: "Unisex",
      campamento: {
        id: 26,
        nombre: "Pioneros",
        direccion: "santiago",
        descripcion: null,
        plano: null,
        latitud: "-33.3660954",
        longitud: "-70.7514249",
      },
      campamentoId: 26,
      standard: [],
    },
    reservasP_INTERNO: [
      {
        trabajador: {
          rut: "12122745-2",
          nombre: "CARLOS ALBERTO",
          apellidoPaterno: "VALDES",
          apellidoMaterno: "VALDES",
          fechaDeNacimiento: "1971-05-07",
          email: "CAVALDES@PSINET.CL",
          telefono: " ",
          cargo: null,
          cargoId: "10000251",
          acreditado: true,
          habitacionPermanente: null,
          enFaena: false,
          turno: "7X7 Grupo Rotativo  2",
        },
        trabajadorRut: "12122745-2",
        fechaInicio: "2024-08-10T18:47:39.98",
        fechaTermino: "2024-08-12T18:47:39.98",
        habitacion: null,
        turno: "7X7 Grupo Rotativo 2",
      },
      {
        trabajador: {
          rut: "8248386-1",
          nombre: "ALEXIS VITAL",
          apellidoPaterno: "MALUENDA",
          apellidoMaterno: "CARVAJAL",
          fechaDeNacimiento: "1969-11-13",
          email: "AVMALUENDA@PSINET.CL",
          telefono: "982846491",
          cargo: null,
          cargoId: "10001001",
          acreditado: true,
          habitacionPermanente: null,
          enFaena: false,
          turno: "7X7 Grupo Rotativo  3",
        },
        trabajadorRut: "8248386-1",
        fechaInicio: "2024-08-08T16:43:06.175",
        fechaTermino: "2024-08-09T16:43:06.175",
        habitacion: null,
        turno: "7X7 Grupo Rotativo 3",
      },
    ],
  },
  {
    id: 27563,
    numero: "101",
    standard: {
      id: 60,
      nombre: "ROTATIVO",
      descripcion: "",
      campamento: {
        id: 25,
        nombre: "Coposa",
        direccion: "dsaadsa",
        descripcion: "descripción",
        plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
        latitud: "-33.5281715",
        longitud: "-70.5807978",
      },
      campamentoId: 25,
    },
    nroCamarotes: 0,
    nroCamas: 2,
    numeroTotalCamas: 2,
    tipoHuesped: "Solo hombres",
    tieneOxigeno: false,
    pabellon: null,
    idChapa: null,
    piso: 1,
    wing: {
      id: 14,
      nombre: "A",
      descripcion: "",
      numeroPisos: 3,
      numeroHabitaciones: 0,
      generoHuesped: "Unisex",
      campamento: {
        id: 26,
        nombre: "Pioneros",
        direccion: "santiago",
        descripcion: null,
        plano: null,
        latitud: "-33.3660954",
        longitud: "-70.7514249",
      },
      campamentoId: 26,
      standard: [],
    },
    reservasP_INTERNO: [
      {
        trabajador: {
          rut: "8248386-1",
          nombre: "ALEXIS VITAL",
          apellidoPaterno: "MALUENDA",
          apellidoMaterno: "CARVAJAL",
          fechaDeNacimiento: "1969-11-13",
          email: "AVMALUENDA@PSINET.CL",
          telefono: "982846491",
          cargo: null,
          cargoId: "10001001",
          acreditado: true,
          habitacionPermanente: null,
          enFaena: false,
          turno: "7X7 Grupo Rotativo  3",
        },
        trabajadorRut: "8248386-1",
        fechaInicio: "2024-08-12T16:43:06.175",
        fechaTermino: "2024-08-14T16:43:06.175",
        habitacion: null,
        turno: "7X7 Grupo Rotativo 3",
      },
      {
        trabajador: {
          rut: "8248386-1",
          nombre: "ALEXIS VITAL",
          apellidoPaterno: "MALUENDA",
          apellidoMaterno: "CARVAJAL",
          fechaDeNacimiento: "1969-11-13",
          email: "AVMALUENDA@PSINET.CL",
          telefono: "982846491",
          cargo: null,
          cargoId: "10001001",
          acreditado: true,
          habitacionPermanente: null,
          enFaena: false,
          turno: "7X7 Grupo Rotativo  3",
        },
        trabajadorRut: "8248386-1",
        fechaInicio: "2024-08-05T16:43:06.175",
        fechaTermino: "2024-08-08T16:43:06.175",
        habitacion: null,
        turno: "7X7 Grupo Rotativo 3",
      },
    ],
  },
  {
    id: 27564,
    numero: "102",
    standard: {
      id: 60,
      nombre: "ROTATIVO",
      descripcion: "",
      campamento: {
        id: 25,
        nombre: "Coposa",
        direccion: "dsaadsa",
        descripcion: "descripción",
        plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
        latitud: "-33.5281715",
        longitud: "-70.5807978",
      },
      campamentoId: 25,
    },
    nroCamarotes: 0,
    nroCamas: 2,
    numeroTotalCamas: 2,
    tipoHuesped: "Unisex",
    tieneOxigeno: false,
    pabellon: null,
    idChapa: null,
    piso: 1,
    wing: {
      id: 14,
      nombre: "A",
      descripcion: "",
      numeroPisos: 3,
      numeroHabitaciones: 0,
      generoHuesped: "Unisex",
      campamento: {
        id: 26,
        nombre: "Pioneros",
        direccion: "santiago",
        descripcion: null,
        plano: null,
        latitud: "-33.3660954",
        longitud: "-70.7514249",
      },
      campamentoId: 26,
      standard: [],
    },
    reservasP_INTERNO: [
      {
        trabajador: {
          rut: "8248386-1",
          nombre: "ALEXIS VITAL",
          apellidoPaterno: "MALUENDA",
          apellidoMaterno: "CARVAJAL",
          fechaDeNacimiento: "1969-11-13",
          email: "AVMALUENDA@PSINET.CL",
          telefono: "982846491",
          cargo: null,
          cargoId: "10001001",
          acreditado: true,
          habitacionPermanente: null,
          enFaena: false,
          turno: "7X7 Grupo Rotativo  3",
        },
        trabajadorRut: "8248386-1",
        fechaInicio: "2024-08-13T16:43:06.175",
        fechaTermino: "2024-08-14T16:43:06.175",
        habitacion: null,
        turno: "7X7 Grupo Rotativo 3",
      },
      {
        trabajador: {
          rut: "8248386-1",
          nombre: "ALEXIS VITAL",
          apellidoPaterno: "MALUENDA",
          apellidoMaterno: "CARVAJAL",
          fechaDeNacimiento: "1969-11-13",
          email: "AVMALUENDA@PSINET.CL",
          telefono: "982846491",
          cargo: null,
          cargoId: "10001001",
          acreditado: true,
          habitacionPermanente: null,
          enFaena: false,
          turno: "7X7 Grupo Rotativo  3",
        },
        trabajadorRut: "8248386-1",
        fechaInicio: "2024-08-05T16:43:06.175",
        fechaTermino: "2024-08-08T16:43:06.175",
        habitacion: null,
        turno: "7X7 Grupo Rotativo 3",
      },
    ],
  },
  {
    id: 27565,
    numero: "103",
    standard: {
      id: 60,
      nombre: "ROTATIVO",
      descripcion: "",
      campamento: {
        id: 25,
        nombre: "Coposa",
        direccion: "dsaadsa",
        descripcion: "descripción",
        plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
        latitud: "-33.5281715",
        longitud: "-70.5807978",
      },
      campamentoId: 25,
    },
    nroCamarotes: 0,
    nroCamas: 2,
    numeroTotalCamas: 2,
    tipoHuesped: "Solo hombres",
    tieneOxigeno: false,
    pabellon: null,
    idChapa: null,
    piso: 1,
    wing: {
      id: 14,
      nombre: "A",
      descripcion: "",
      numeroPisos: 3,
      numeroHabitaciones: 0,
      generoHuesped: "Unisex",
      campamento: {
        id: 26,
        nombre: "Pioneros",
        direccion: "santiago",
        descripcion: null,
        plano: null,
        latitud: "-33.3660954",
        longitud: "-70.7514249",
      },
      campamentoId: 26,
      standard: [],
    },
    reservasP_INTERNO: false,
  },
  {
    id: 27566,
    numero: "104",
    standard: {
      id: 60,
      nombre: "ROTATIVO",
      descripcion: "",
      campamento: {
        id: 25,
        nombre: "Coposa",
        direccion: "dsaadsa",
        descripcion: "descripción",
        plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
        latitud: "-33.5281715",
        longitud: "-70.5807978",
      },
      campamentoId: 25,
    },
    nroCamarotes: 0,
    nroCamas: 2,
    numeroTotalCamas: 2,
    tipoHuesped: "Solo mujeres",
    tieneOxigeno: false,
    pabellon: null,
    idChapa: null,
    piso: 1,
    wing: {
      id: 14,
      nombre: "A",
      descripcion: "",
      numeroPisos: 3,
      numeroHabitaciones: 0,
      generoHuesped: "Unisex",
      campamento: {
        id: 26,
        nombre: "Pioneros",
        direccion: "santiago",
        descripcion: null,
        plano: null,
        latitud: "-33.3660954",
        longitud: "-70.7514249",
      },
      campamentoId: 26,
      standard: [],
    },
    reservasP_INTERNO: false,
  },
  {
    id: 27567,
    numero: "105",
    standard: {
      id: 60,
      nombre: "ROTATIVO",
      descripcion: "",
      campamento: {
        id: 25,
        nombre: "Coposa",
        direccion: "dsaadsa",
        descripcion: "descripción",
        plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
        latitud: "-33.5281715",
        longitud: "-70.5807978",
      },
      campamentoId: 25,
    },
    nroCamarotes: 0,
    nroCamas: 2,
    numeroTotalCamas: 2,
    tipoHuesped: "Solo mujeres",
    tieneOxigeno: false,
    pabellon: null,
    idChapa: null,
    piso: 1,
    wing: {
      id: 14,
      nombre: "A",
      descripcion: "",
      numeroPisos: 3,
      numeroHabitaciones: 0,
      generoHuesped: "Unisex",
      campamento: {
        id: 26,
        nombre: "Pioneros",
        direccion: "santiago",
        descripcion: null,
        plano: null,
        latitud: "-33.3660954",
        longitud: "-70.7514249",
      },
      campamentoId: 26,
      standard: [],
    },
    reservasP_INTERNO: false,
  },
  {
    id: 27568,
    numero: "106",
    standard: {
      id: 60,
      nombre: "ROTATIVO",
      descripcion: "",
      campamento: {
        id: 25,
        nombre: "Coposa",
        direccion: "dsaadsa",
        descripcion: "descripción",
        plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
        latitud: "-33.5281715",
        longitud: "-70.5807978",
      },
      campamentoId: 25,
    },
    nroCamarotes: 0,
    nroCamas: 2,
    numeroTotalCamas: 2,
    tipoHuesped: "Solo hombres",
    tieneOxigeno: false,
    pabellon: null,
    idChapa: null,
    piso: 1,
    wing: {
      id: 14,
      nombre: "A",
      descripcion: "",
      numeroPisos: 3,
      numeroHabitaciones: 0,
      generoHuesped: "Unisex",
      campamento: {
        id: 26,
        nombre: "Pioneros",
        direccion: "santiago",
        descripcion: null,
        plano: null,
        latitud: "-33.3660954",
        longitud: "-70.7514249",
      },
      campamentoId: 26,
      standard: [],
    },
    reservasP_INTERNO: false,
  },
];

const rotativos = [
  {
    trabajador: {
      rut: "12122745-2",
      nombre: "CARLOS ALBERTO",
      apellidoPaterno: "VALDES",
      apellidoMaterno: "VALDES",
      fechaDeNacimiento: "1971-05-07",
      email: "CAVALDES@PSINET.CL",
      telefono: " ",
      cargo: null,
      genero: "Hombre",
      cargoId: "10000251",
      acreditado: true,
      habitacionPermanente: null,
      enFaena: false,
      turno: "7X7 Grupo Rotativo  2",
    },
    trabajadorRut: "12122745-2",
    fechaInicio: "2024-08-10T18:47:39.98",
    fechaTermino: "2024-08-12T18:47:39.98",
    habitacion: {
      id: 27562,
      numero: "100",
      standard: {
        id: 60,
        nombre: "ROTATIVO",
        descripcion: "",
        campamento: {
          id: 25,
          nombre: "Coposa",
          direccion: "dsaadsa",
          descripcion: "descripción",
          plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
          latitud: "-33.5281715",
          longitud: "-70.5807978",
        },
        campamentoId: 25,
      },
      nroCamarotes: 0,
      nroCamas: 2,
      numeroTotalCamas: 2,
      tipoHuesped: "Solo hombres",
      tieneOxigeno: false,
      pabellon: null,
      idChapa: null,
      piso: 1,
      wing: {
        id: 14,
        nombre: "A",
        descripcion: "",
        numeroPisos: 3,
        numeroHabitaciones: 0,
        generoHuesped: "Unisex",
        campamento: {
          id: 26,
          nombre: "Pioneros",
          direccion: "santiago",
          descripcion: null,
          plano: null,
          latitud: "-33.3660954",
          longitud: "-70.7514249",
        },
        campamentoId: 26,
        standard: [],
      },
    },
    turno: "7X7 Grupo Rotativo 2",
  },
  {
    trabajador: {
      rut: "8235983-4",
      nombre: "ALFREDO GUIDO ENRIQUE",
      apellidoPaterno: "CRUZ",
      apellidoMaterno: "OSORIO",
      fechaDeNacimiento: "1963-02-06",
      email: "AGCRUZ@PSINET.CL",
      telefono: " ",
      cargo: null,
      genero: "Mujer",
      cargoId: "10001201",
      acreditado: true,
      habitacionPermanente: null,
      enFaena: false,
      turno: "7X7 Grupo Rotativo 14",
    },
    trabajadorRut: "8235983-4",
    fechaInicio: "2024-04-01T00:00:00",
    fechaTermino: "2024-04-03T00:00:00",
    habitacion: {
      id: 27563,
      numero: "101",
      standard: {
        id: 60,
        nombre: "ROTATIVO",
        descripcion: "",
        campamento: {
          id: 25,
          nombre: "Coposa",
          direccion: "dsaadsa",
          descripcion: "descripción",
          plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
          latitud: "-33.5281715",
          longitud: "-70.5807978",
        },
        campamentoId: 25,
      },
      nroCamarotes: 0,
      nroCamas: 2,
      numeroTotalCamas: 2,
      tipoHuesped: "Solo hombres",
      tieneOxigeno: false,
      pabellon: null,
      idChapa: null,
      piso: 1,
      wing: {
        id: 14,
        nombre: "A",
        descripcion: "",
        numeroPisos: 3,
        numeroHabitaciones: 0,
        generoHuesped: "Unisex",
        campamento: {
          id: 26,
          nombre: "Pioneros",
          direccion: "santiago",
          descripcion: null,
          plano: null,
          latitud: "-33.3660954",
          longitud: "-70.7514249",
        },
        campamentoId: 26,
        standard: [],
      },
      reservasP_INTERNO: false,
    },
    turno: "7X7 Grupo Rotativo 14",
  },
  {
    trabajador: {
      rut: "8235983-4",
      nombre: "ALFREDO GUIDO ENRIQUE",
      apellidoPaterno: "CRUZ",
      apellidoMaterno: "OSORIO",
      fechaDeNacimiento: "1963-02-06",
      email: "AGCRUZ@PSINET.CL",
      telefono: " ",
      cargo: null,
      genero: "Mujer",
      cargoId: "10001201",
      acreditado: true,
      habitacionPermanente: null,
      enFaena: false,
      turno: "7X7 Grupo Rotativo 14",
    },
    trabajadorRut: "8235983-4",
    fechaInicio: "2024-04-11T00:00:00",
    fechaTermino: "2024-04-17T00:00:00",
    habitacion: null,
    turno: "7X7 Grupo Rotativo 14",
  },
  {
    trabajador: {
      rut: "8235983-4",
      nombre: "ALFREDO GUIDO ENRIQUE",
      apellidoPaterno: "CRUZ",
      apellidoMaterno: "OSORIO",
      fechaDeNacimiento: "1963-02-06",
      email: "AGCRUZ@PSINET.CL",
      telefono: " ",
      cargo: null,
      genero: "Hombre",
      cargoId: "10001201",
      acreditado: true,
      habitacionPermanente: null,
      enFaena: false,
      turno: "7X7 Grupo Rotativo 14",
    },
    trabajadorRut: "8235983-4",
    fechaInicio: "2024-04-25T00:00:00",
    fechaTermino: "2024-04-30T00:00:00",
    habitacion: null,
    turno: "7X7 Grupo Rotativo 14",
  },
  {
    trabajador: {
      rut: "8248386-1",
      nombre: "ALEXIS VITAL",
      apellidoPaterno: "MALUENDA",
      apellidoMaterno: "CARVAJAL",
      fechaDeNacimiento: "1969-11-13",
      email: "AVMALUENDA@PSINET.CL",
      telefono: "982846491",
      cargo: null,
      genero: "Hombre",
      cargoId: "10001001",
      acreditado: true,
      habitacionPermanente: null,
      enFaena: false,
      turno: "7X7 Grupo Rotativo  3",
    },
    trabajadorRut: "8248386-1",
    fechaInicio: "2024-04-02T00:00:00",
    fechaTermino: "2024-04-08T00:00:00",
    habitacion: null,
    turno: "7X7 Grupo Rotativo  3",
  },
  {
    trabajador: {
      rut: "8248386-1",
      nombre: "ALEXIS VITAL",
      apellidoPaterno: "MALUENDA",
      apellidoMaterno: "CARVAJAL",
      fechaDeNacimiento: "1969-11-13",
      email: "AVMALUENDA@PSINET.CL",
      telefono: "982846491",
      cargo: null,
      cargoId: "10001001",
      acreditado: true,
      habitacionPermanente: null,
      enFaena: false,
      turno: "7X7 Grupo Rotativo  3",
    },
    trabajadorRut: "8248386-1",
    fechaInicio: "2024-04-16T00:00:00",
    fechaTermino: "2024-04-22T00:00:00",
    habitacion: null,
    turno: "7X7 Grupo Rotativo  3",
  },
];

const ReservaP_interno = () => {
  const rotativos = useSelector(getAllreservasP_INTERNO);
  const status = useSelector(getReservasP_INTERNOStatus);
  const habitaciones = useSelector(getAllHabitacionesP_INTERNO);
  const statusHabitaciones = useSelector(getHabitacionesP_INTERNOStatus);
  // const status = 'idle';
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [openBack, setOpenBack] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [filterTrabajadoresTabla, setFilterTrabajadoresTabla] = useState([]);
  const [filterTrabajadoresConId, setFilterTrabajadoresConId] = useState([]);
  const [filterHabitaciones, setFilterHabitaciones] = useState([]);
  const [asignaciones, setAsignaciones] = useState([]);
  const [selectedTrabajadores, setSelectedTrabajadores] = useState({
    trabajador: {},
  });
  const [data, setData] = useState({});

  const [cantidadHabitacionesRestantes, setCantidadHabitacionesRestantes] =
    useState(0);

  const [habitacionesFilterModel, setHabitacionesFilterModel] = useState({
    items: [],
  });
  const handleChange = (event, newValue) => setValue(newValue);
  const handleHabitacionesFilterModelChange = (newFilterModel) =>
    setHabitacionesFilterModel(sanitizeFilterModel(newFilterModel));

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (status == "idle") {
    }
    dispatch(fetchReservasP_INTERNO()); // SE UTILIZA EL LITADO DE RESERVAS
    if (statusHabitaciones == "idle") {
    }
    dispatch(fetchHabitacionesP_INTERNO());
  }, []);

  useEffect(() => {
    const rotativosIds = rotativos.map((item) => ({
      ...item,
      id: uuidv4(),
      trabajador: {
        ...item.trabajador,
        id: uuidv4(),
      },
    }));
    setFilterTrabajadoresTabla(rotativosIds);
    setFilterTrabajadoresConId(rotativosIds); // este estado no se toca, se utiliza para encontrar a trabajadores con el id
    console.log("rotativosIds: -->", rotativosIds);
  }, [rotativos]);

  useEffect(() => {
    if (Array.isArray(habitaciones)) {
      // const fechaActual = new Date();

      // const mappedHabitaciones = habitaciones.map(hab => {
      //   // Encuentra la reserva en reservasP_INTERNO
      //   const reservaEncontrada = Array.isArray(hab.reservasP_INTERNO) ? hab.reservasP_INTERNO.find(reserva => {
      //     const fechaInicio = new Date(reserva.fechaInicio);
      //     const fechaTermino = new Date(reserva.fechaTermino);
      //     return fechaActual >= fechaInicio && fechaActual <= fechaTermino;
      //   }) : null;

      //   // Si se encuentra una reserva que cumple con el criterio, retorna una nueva habitación con el trabajador asociado
      //   if (reservaEncontrada) {
      //     return {
      //       ...hab,
      //       trabajador: reservaEncontrada.trabajador, // Asegúrate de que `trabajador` tenga el valor correcto
      //     };
      //   }
      //   // Si no se encuentra ninguna reserva, retorna la habitación original
      //   return hab;
      // });

      // setFilterHabitaciones(mappedHabitaciones);
      setFilterHabitaciones(habitaciones);
      console.log("habitaciones-->-->", habitaciones);
    }
  }, [habitaciones]);
  useEffect(() => {
    console.log("data cambio", data);
    // if(data?.trabajador){
    //   handleSubmit();
    // }
  }, [data]);

  useEffect(() => {
    if (asignaciones.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    console.log("asignaciones", asignaciones);
  }, [asignaciones]);

  const validarAsignacion = (trabajador, habitacion) => {
    console.log("Entra a la valicación.");
    if (!trabajador.nombre) {
      toast.error("Selecciona un trabajador");
      return false;
    }

    if (!habitacion) {
      toast.error("No se ha encontrado la habitación.");
      return false;
    }
    // if (habitacion.grupo_rrhh != trabajador.grupo_rrhh) {
    //     toast.warning('El grupo de la habitación no coincide con el del trabajadador seleccionado.');
    //     // return false;
    // }

    let genero = habitacion.pabellon ?? habitacion.wing;
    if (!trabajador.genero) {
      // toast.warning('Advertencia: El trabajador seleccionado no tiene género registrado.');
    } else if (
      (genero.tipoHuesped === "Solo hombres" &&
        trabajador.genero !== "Hombre") ||
      (genero.tipoHuesped === "Solo mujeres" && trabajador.genero !== "Mujer")
    ) {
      toast.error("Error: Género no permitido en la habitación.");
      return false;
    }
    return true;
  };

  //   const seleccionTablaHabitaciones = async (selection) => {
  //     console.log('selection habitación: -->', selection[0]);

  //     const habitacionSeleccionada = filterHabitaciones.find((row) => row.id === selection[0]);

  //     if (!habitacionSeleccionada) {
  //         toast.error('No se ha encontrado la habitación.');
  //         return;
  //     }

  //     // codigo eliminado, revisar los otros archivos
  //     // console.log('selectedTrabajadores--->', selectedTrabajadores);
  //     // if (Object.keys(selectedTrabajadores).length === 0 || !selectedTrabajadores?.trabajador.id ) {
  //     //     if (habitacionSeleccionada.trabajador) {
  //     //         // Si no hay trabajador seleccionado pero la habitación tiene uno asignado, eliminarlo
  //     //         const findTrab = filterTrabajadoresTabla.find(item => item.trabajador.id === habitacionSeleccionada.trabajador.id);
  //     //         console.log('findTrab---> ', findTrab)

  //     //         const updatedHabitaciones = filterHabitaciones.map(habitacion =>
  //     //             habitacion.id === selection[0]
  //     //                 ? { ...habitacion, trabajador: undefined, permanente: false }
  //     //                 : habitacion
  //     //         );
  //     //         if(!selectedTrabajadores?.rut){
  //     //           console.log('habitacionSeleccionada.trabajador ------> ', habitacionSeleccionada.trabajador);
  //     //           setFilterTrabajadoresTabla(prev => [...prev, {...findTrab, habitacion: null}])
  //     //         }else{
  //     //           // const findTrab = rotativos.find(item => item.rut == habitacionSeleccionada.trabajador.id);
  //     //           const findTrab = filterTrabajadoresTabla.find(item => item.trabajador.id == habitacionSeleccionada.trabajador.id);
  //     //           console.log('findTrab: ----->', {...findTrab, habitacion: null});
  //     //           setFilterTrabajadoresTabla(prev => [...prev, {...findTrab, habitacion: null}])
  //     //         }
  //     //         setFilterHabitaciones(updatedHabitaciones);
  //     //         toast.success('Trabajador removido de la habitación.');
  //     //         return;
  //     //     } else {
  //     //         toast.error('Selecciona un trabajador para asignar.');
  //     //         return;
  //     //     }
  //     // }

  //     // if (habitacionSeleccionada?.rut) {
  //     //     toast.error('La habitación ya tiene un trabajador asignado. Deselecciona el trabajador que seleccionaste y vuelve a seleccionar la habitación para liberarla.');
  //     //     // setSelectedTrabajadores({});
  //     //     return;
  //     // }

  //     // //Capturar las 4 habitaciones con el mismo ID, y comprobar si es que ya se encuentra el turno en alguna habitación
  //     // const cuatrohab = filterHabitaciones.filter(hab => hab.trabajador && (hab.id == habitacionSeleccionada.id) && (hab.trabajador.turno == selectedTrabajadores?.trabajador.turno));
  //     // console.log('cuatrohab', cuatrohab);
  //     // if(cuatrohab.length > 0){
  //     //   toast.error('El turno ya se encuentra registrado en la habitación.');
  //     //   return;
  //     // }

  //     if (!validarAsignacion(selectedTrabajadores?.trabajador, habitacionSeleccionada)) return;

  //     console.log('habitacionSeleccionada: --> ', habitacionSeleccionada);
  //     console.log('Trabajador a asignar: --> ', selectedTrabajadores);
  //     // return;

  //     if(selectedTrabajadores?.habitacion){ // En caso de que el trabajador tenga una habitación ya asignada

  //       const habitacionActualDelTrabajador = selectedTrabajadores?.habitacion;
  //       const estaEnElListado = filterHabitaciones.findIndex(hab => hab.id === habitacionActualDelTrabajador.id)
  //       console.log('estaEnElListado: -->',estaEnElListado);

  //       if(estaEnElListado === -1){ // la habitación actual del trabajador está en el listado, hay que actualizarla
  //         console.log('No está en el listado --->');
  //         const updatedHabitaciones = [...filterHabitaciones, habitacionActualDelTrabajador]

  //         const updatedHabitacionesNuevo = updatedHabitaciones.map(habitacion =>  //habitacion seleccionada
  //             habitacion.id === selection[0]
  //                 ? { ...habitacion, trabajador: selectedTrabajadores?.trabajador }
  //                 : habitacion
  //         );

  //         const updatedTrabajador = filterTrabajadoresTabla.map(trab =>  // trabajador seleccionado con trab
  //             // trab.rut === selectedTrabajadores?.trabajador?.rut
  //             trab.trabajador.id === selectedTrabajadores?.trabajador.id
  //                 ? { ...trab, habitacion: undefined }
  //                 : trab
  //         );

  //         // const updatedTrabajadores = updatedTrabajador.filter(trab => trab.rut != selectedTrabajadores?.rut );
  //         const updatedTrabajadores = updatedTrabajador.filter(trab => trab.trabajador.id != selectedTrabajadores?.trabajador.id );

  //         try {
  //           const resp = await handleSubmit(selectedTrabajadores)
  //           if(resp == 'ok'){
  //             setAsignaciones(prev => {
  //               const trabajadorExistente = prev.some(trab => trab.trabajador.id === selectedTrabajadores.trabajador.id);
  //               if (trabajadorExistente) {
  //                 return prev.map(trab =>
  //                   trab.trabajador.id === selectedTrabajadores.trabajador.id
  //                     ? {
  //                       ...selectedTrabajadores,
  //                       habitacion: habitacionSeleccionada
  //                     }
  //                     : trab
  //                 );
  //               } else {
  //                 return [...prev, {...selectedTrabajadores, habitacion: habitacionSeleccionada}];
  //               }
  //             });
  //             setOpenAlert(false);
  //             setSelectedTrabajadores({trabajador:{}});
  //           }
  //         } catch (error) {
  //           console.log('error al modificar asignaciones: ', error);
  //         }
  //         // setFilterTrabajadoresTabla(updatedTrabajadores);
  //         // setFilterHabitaciones(updatedHabitacionesNuevo);

  //         // toast.success('Trabajador asignado exitosamente.');
  //         // setOpenAlert(false);
  //         // setSelectedTrabajadores({});

  //         return;

  //       } else{ //está en el listado
  //         // console.log('Se encontro la habitación');
  //         // filterHabitaciones[estaEnElListado].trabajador = null;
  //         // setFilterHabitaciones(filterHabitaciones);

  //         // Crear una copia del objeto y modificarlo
  //         // const nuevaHabitacion = { ...filterHabitaciones[estaEnElListado], trabajador: null };
  //         // const nuevasHabitaciones = [...filterHabitaciones];
  //         // nuevasHabitaciones[estaEnElListado] = nuevaHabitacion;
  //         // setFilterHabitaciones(nuevasHabitaciones);
  //       }
  //     }

  //     // Si la habitación no  tiene un trabajador asignado:
  //     // Se agrega el trabajador a la habitación
  //     // const updatedHabitaciones = filterHabitaciones.map(habitacion =>
  //     //     habitacion.id === selection[0]
  //     //         ? { ...habitacion, trabajador: selectedTrabajadores?.trabajador }
  //     //         : habitacion
  //     // );

  //     // Actualiza la propiedad habitación del trabajador seleccionado en lugar de removerlo de la tabla
  //     let trabajadorAsignacion = {};
  //     const updatedTrabajadores = filterTrabajadoresTabla.map(trab => {
  //       if (trab.trabajador.id === selectedTrabajadores?.trabajador.id) {
  //         trabajadorAsignacion = { ...trab, habitacion: habitacionSeleccionada };
  //         // setData(trabajadorAsignacion)
  //         return trabajadorAsignacion;
  //       }
  //       return trab;
  //     });

  //     console.log('trabajadorAsignacion', trabajadorAsignacion);

  //     try {
  //       const resp = await handleSubmit(trabajadorAsignacion)
  //       if(resp == 'ok'){
  //         setAsignaciones(prev => {
  //           const trabajadorExistente = prev.some(trab => trab.trabajador.id === trabajadorAsignacion.trabajador.id);
  //           if (trabajadorExistente) {
  //             return prev.map(trab =>
  //               trab.trabajador.id === trabajadorAsignacion.trabajador.id
  //                 ? trabajadorAsignacion
  //                 : trab
  //             );
  //           } else {
  //             return [...prev, trabajadorAsignacion];
  //           }
  //         });
  //         setOpenAlert(false);
  //         setSelectedTrabajadores({trabajador:{}});
  //       }
  //     } catch (error) {
  //       console.log('error al modificar asignaciones: ', error);
  //     }
  //     // setFilterHabitaciones(updatedHabitaciones);
  //     // setFilterTrabajadoresTabla(updatedTrabajadores);
  //     // toast.success('Trabajador asignado exitosamente.');
  // };

  const seleccionTablaHabitaciones = async (selection) => {
    console.log("selection habitación: -->", selection[0]);

    const habitacionSeleccionada = filterHabitaciones.find(
      (row) => row.id === selection[0]
    );

    if (!habitacionSeleccionada) {
      toast.error("No se ha encontrado la habitación.");
      return;
    }

    if (
      !validarAsignacion(
        selectedTrabajadores?.trabajador,
        habitacionSeleccionada
      )
    )
      return;

    console.log("habitacionSeleccionada: --> ", habitacionSeleccionada);
    console.log("FilterTrabajadoresTabla: --> ", filterTrabajadoresTabla);
    console.log("Trabajador a asignar: --> ", selectedTrabajadores);

    // Actualiza la propiedad habitación del trabajador seleccionado en lugar de removerlo de la tabla
    let trabajadorAsignacion = {};
    const updatedTrabajadores = filterTrabajadoresTabla.map((trab) => {
      if (trab.trabajador.id === selectedTrabajadores?.trabajador.id) {
        trabajadorAsignacion = { ...trab, habitacion: habitacionSeleccionada };
        // setData(trabajadorAsignacion)
        return trabajadorAsignacion;
      }
      return trab;
    });

    console.log("trabajadorAsignacion", trabajadorAsignacion);

    try {
      const resp = await handleSubmit(trabajadorAsignacion);
      if (resp == "ok") {
        setAsignaciones((prev) => {
          const trabajadorExistente = prev.some(
            (trab) => trab.trabajador.id === trabajadorAsignacion.trabajador.id
          );
          if (trabajadorExistente) {
            return prev.map((trab) =>
              trab.trabajador.id === trabajadorAsignacion.trabajador.id
                ? trabajadorAsignacion
                : trab
            );
          } else {
            return [...prev, trabajadorAsignacion];
          }
        });
        setOpenAlert(false);
        setSelectedTrabajadores({ trabajador: {} });
        // setFilterHabitaciones(filterHabitaciones);
      }
    } catch (error) {
      console.log("error al modificar asignaciones: ", error);
    }

    // setFilterTrabajadoresTabla(updatedTrabajadores);
    // toast.success('Trabajador asignado exitosamente.');
  };

  const handleRowSelectionModelChange = (selectionModel) => {
    console.log("Fila seleccionada: ", selectionModel);
    // if (selectedTrabajadores && selectedTrabajadores && selectedTrabajadores?.trabajador?.rut === selectionModel[0]) {
    if (
      selectedTrabajadores &&
      selectedTrabajadores &&
      selectedTrabajadores?.trabajador.id === selectionModel
    ) {
      // Si el trabajador ya está seleccionado, quitar la selección
      setSelectedTrabajadores({ trabajador: {} });
      console.log("Selección eliminada");
      setOpenAlert(false);
    } else {
      // Si el trabajador no está seleccionado, agregar la selección
      const trabajadorEncontrado = filterTrabajadoresTabla.find(
        (row) => row.trabajador.id === selectionModel
      );
      setSelectedTrabajadores(trabajadorEncontrado ? trabajadorEncontrado : {});
      console.log("Trabajador encontrado: ", trabajadorEncontrado);
      if (!trabajadorEncontrado.trabajador.genero) {
        toast.warning(
          "Advertencia: El trabajador seleccionado no tiene género registrado."
        );
      }
      if (trabajadorEncontrado.trabajador.genero === "Mujer") {
        console.log("Solo mujeres.");
        setFilterHabitaciones(
          habitaciones.filter(
            (habitacion) =>
              habitacion.tipoHuesped === "Solo mujeres" ||
              habitacion.tipoHuesped === "Unisex"
          )
        );
        const restantes = habitaciones.filter(
          (habitacion) => habitacion.tipoHuesped === "Solo hombres"
        ).length;
        setCantidadHabitacionesRestantes(restantes);
      } else if (trabajadorEncontrado.trabajador.genero === "Hombre") {
        console.log("Solo hombres.");
        setFilterHabitaciones(
          habitaciones.filter(
            (habitacion) =>
              habitacion.tipoHuesped === "Solo hombres" ||
              habitacion.tipoHuesped === "Unisex"
          )
        );
        const restantes = habitaciones.filter(
          (habitacion) => habitacion.tipoHuesped === "Solo mujeres"
        ).length;
        setCantidadHabitacionesRestantes(restantes);
      } else {
        console.log("Sin género.");
        setFilterHabitaciones(filterHabitaciones);
      }

      setOpenAlert(true);
      setValue(1);
    }
  };

  const changePermanente = async (registro) => {
    // console.log('registro-->', registro);
    const findTrab = filterTrabajadoresTabla.find(
      (trab) => trab.trabajador.id === registro.trabajador.id
    );
    const data = {
      ...findTrab,
      habitacion: {
        ...findTrab.habitacion,
        permanente:
          findTrab.habitacion.permanente !== undefined
            ? !findTrab.habitacion.permanente
            : true,
      },
    };
    console.log("data", data);
    try {
      const resp = await handleSubmitPermanente(data);
      if (resp === "ok") {
        setAsignaciones((prev) => {
          const trabajadorExistente = prev.some(
            (trab) => trab.trabajador.id === registro.trabajador.id
          );

          if (trabajadorExistente) {
            return prev.map((trab) =>
              trab.trabajador.id === registro.trabajador.id
                ? {
                    ...trab,
                    habitacion: {
                      ...trab.habitacion,
                      permanente:
                        trab.habitacion.permanente !== undefined
                          ? !trab.habitacion.permanente
                          : true,
                    },
                  }
                : trab
            );
          } else {
            return [
              ...prev,
              {
                ...data,
              },
            ];
          }
        });
      }
    } catch (error) {
      console.log("Error en changePermanente:", error);
    }
  };

  const handleSubmitPermanente = async (data) => {
    try {
      console.log("data", data);
      setOpenBack(true);
      const mappedData = {
        rut: data.trabajador.rut,
        habitacionId: data.habitacion.id,
      };
      console.log("Por enviar: ", mappedData);
      const resp = await dispatch(asignarHabitacionPermanente(mappedData));
      if (resp.payload.status === "success") {
        setData({});
        return "ok";
      }
    } catch (error) {
      console.log("Error en handleSubmit:", error);
      return "not ok";
    } finally {
      setOpenBack(false);
    }
  };

  // MODIFICAR EL FLUJO DE PERMANENTE, AÑADIR LA OPCIÓN Y LA LOGÍCA EN LA TABLA DE RESERVAS, Y PARA EL CASO DE HABITACIONES
  // ACTUALIZAR ASIGNACIONES SI RESP ES EXITOSO, REMOVER EL SETEO DE ASIGANCIONES DE OTROS LUGARES

  const handleSubmit = async (data) => {
    try {
      console.log("data", data);
      setOpenBack(true);
      const mappedData = {
        rut: data.trabajador.rut,
        // fechaInicio: format(data.fechaInicio, 'yyyy-MM-dd'),
        // fechaTermino: format(data.fechaTermino, 'yyyy-MM-dd'),
        fechaInicio: data.fechaInicio,
        fechaTermino: data.fechaTermino,
        habitacionId: data.habitacion.id,
        turno: data.turno,
      };

      console.log("Por enviar: ", mappedData);
      // return;
      const resp = await dispatch(modificarReservaP_interno(mappedData));
      console.log("resp", resp);

      if (resp.payload.status == "success") {
        // setFilterHabitaciones(filterHabitaciones);
        dispatch(fetchReservasP_INTERNO()); // SE UTILIZA EL LITADO DE RESERVAS
        dispatch(fetchHabitacionesP_INTERNO());
        setData({});
        setSelectedTrabajadores({ trabajador: {} });
        setValue(0);
        setOpenAlert(false);
        return "ok";
      }
    } catch (error) {
      console.log("error en handleSubmit", error);
      return "not ok";
    } finally {
      setOpenBack(false);
    }
  };

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBack}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Gestión de asignación P_INTERNO"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados a las asignaciones P_INTERNO generadas por el sistema."
        >
          {
            <FactCheckIcon
              style={{ fontSize: "1.5rem" }}
              className={styles.bgIcon}
            />
          }
        </Header>

        <TableCard>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Asignaciones" {...a11yProps(0)} />
            <Tab label="Habitaciones" {...a11yProps(1)} />
            {/*<Tab label="Asignaciones/modificaciones" {...a11yProps(1)} /> */}
          </Tabs>
          <Box sx={{ width: "100%" }}>
            <Collapse in={openAlert}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                      setSelectedTrabajadores({ trabajador: {} });
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 0 }}
              >
                <span>
                  Trabajador seleccionado:{" "}
                  {selectedTrabajadores
                    ? selectedTrabajadores?.trabajador.nombre
                    : ""}{" "}
                  {selectedTrabajadores
                    ? selectedTrabajadores?.trabajador.apellidoPaterno
                    : ""}{" "}
                  {selectedTrabajadores
                    ? selectedTrabajadores?.trabajador.apellidoMaterno
                    : ""}
                  ,{" "}
                  {selectedTrabajadores
                    ? selectedTrabajadores?.trabajador.genero
                    : ""}
                </span>
                <p>
                  Fecha Inicio:{" "}
                  {selectedTrabajadores
                    ? selectedTrabajadores?.fechaInicio
                      ? format(selectedTrabajadores?.fechaInicio, "dd-MM-yyyy")
                      : ""
                    : ""}
                </p>

                <p>
                  Fecha Termino:{" "}
                  {selectedTrabajadores
                    ? selectedTrabajadores?.fechaTermino
                      ? format(selectedTrabajadores?.fechaTermino, "dd-MM-yyyy")
                      : ""
                    : ""}
                </p>
              </Alert>
            </Collapse>
          </Box>
          <CustomTabPanel value={value} index={0}>
            {status == "loading" ? (
              <SkeletonTable />
            ) : (
              <TablePorAsignar
                changePermanente={changePermanente}
                trabajadores={filterTrabajadoresTabla || []}
                Reservas
                setOpenBack={setOpenBack}
                selectedTrabajadores={selectedTrabajadores}
                handleRowSelectionModelChange={handleRowSelectionModelChange}
                setFilterTrabajadoresTabla={setFilterTrabajadoresTabla}
              />
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {statusHabitaciones == "loading" ? (
              <SkeletonTable />
            ) : (
              <Table
                habitaciones={filterHabitaciones || []}
                setOpenBack={setOpenBack}
                habitacionesFilterModel={habitacionesFilterModel}
                handleHabitacionesFilterModelChange={
                  handleHabitacionesFilterModelChange
                }
                seleccionTablaHabitaciones={seleccionTablaHabitaciones}
                setFilterHabitaciones={setFilterHabitaciones}
                selectedTrabajadores={selectedTrabajadores}
                changePermanente={changePermanente}
                filterTrabajadoresTabla={filterTrabajadoresTabla}
                cantidadHabitacionesRestantes={cantidadHabitacionesRestantes}
              />
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            {status == "loading" ? (
              <SkeletonTable />
            ) : (
              <TableAsignaciones asignaciones={asignaciones || []} />
            )}
          </CustomTabPanel>

          {/*<ToastWithAlert
              open={open}
              handleClose={handleClose}
              message="Revisa la pestaña de 'Asignaciones'"
              severity="success"
              handleSubmit={handleSubmit}
            /> */}
        </TableCard>
      </Container>
    </Box>
  );
};

export default ReservaP_interno;
