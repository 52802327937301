import { LogLevel } from "@azure/msal-browser";

export const msalConfig =
  process.env.REACT_APP_ENABLE_SSO === "true"
    ? {
        auth: {
          clientId: "b6b5ce4e-0699-40d5-b19c-d6bb5b4ea6b2",
          authority:
            "https://login.microsoftonline.com/d7bfe7d4-f40c-48a3-ae1d-df323b98d4ef",
          redirectUri: process.env.PUBLIC_URL + "/getToken",
        },
        cache: {
          cacheLocation: "sessionStorage",
          storeAuthStateInCookie: true,
        },
        system: {
          loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
              if (containsPii) return;
              switch (level) {
                case LogLevel.Error:
                  console.error(message);
                  return;
                case LogLevel.Info:
                  console.info(message);
                  return;
                case LogLevel.Verbose:
                  console.debug(message);
                  return;
                case LogLevel.Warning:
                  console.warn(message);
                  return;
              }
            },
            logLevel: LogLevel.Verbose,
          },
        },
      }
    : null;
