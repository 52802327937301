import React, { useEffect } from "react";
import styles from "./components.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
// import { addNewCampamento } from '../../../../redux/features/campamentos/campamentoSlice';
import { toast } from "react-toastify";
import {
  Autocomplete,
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  Box,
  Tab,
} from "@mui/material";
import {
  addNewProducto,
  addNewToma,
} from "../../../redux/features/inventario/inventarioSlice";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { createAxiosInstance } from "../../../api/axios";
import Table from "./TableToma";
import { addNewMermaAutomatic } from "../../../redux/features/merma/mermaSlice";
import { isNumber } from "@mui/x-data-grid/internals";
import { formatRFC3339 } from "date-fns";
import SkeletonTable from "../../../components/Skeleton";

const data = {
  articulos: [],
  montoTotal: 0,
  montoTotalTomaInventario: 0,
  motivo: "",
};

const Create = ({
  open2,
  setOpen2,
  inventario,
  bodegas,
  espacios,
  mermas,
  tomas,
  tomasStatus,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openBack, setOpenBack] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [searching, setSearching] = useState(false);

  const [items, setItems] = useState([]);
  const [items2, setItems2] = useState([]);

  const [itemsNew, setItemsNew] = useState([]);
  const [itemsNew2, setItemsNew2] = useState(null);

  const [newItem, setNewItem] = useState("");
  const [newItem2, setNewItem2] = useState("");

  const [check, setCheck] = useState(false);
  const [value, setValue] = useState("1");

  const [totalPrice, setTotalPrice] = useState(0);
  const [enterPrice, setEnterPrice] = useState(0);

  const [selectedBodega, setSelectedBodega] = useState();

  const [filterMermas, setFilterMermas] = useState([]);
  const [listItems, setListItems] = useState([]);
  const [listItems2, setListItems2] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItem2, setSelectedItem2] = useState(null);
  const [bodegasItems, setBodegasItems] = useState([]);
  const [dataMerma, setDataMerma] = useState([]);
  const [filterEspacios, setFilterEspacios] = useState([]);
  const [canSaveMerma, setCanSaveMerma] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getProducts = async () => {
    try {
      const response = await axiosInstance.get("/api/v1/producto");
      console.log(response);
      setListItems2(response.data);
    } catch (error) {
      console.log("Error al traer los items: ", error);
    }
  };
  const getBodegas = async (bodega) => {
    try {
      setOpenBack(true);
      const response = await axiosInstance.get(
        "/api/v1/Inventario/productos-bodega/" + bodega
      );
      console.log("bodegas productos: ", response.data);
      // setBodegasItems(response.data);
      const filter = espacios.filter((bod) => bod.bodega.id === bodega);
      console.log("espacios Filtrados", filter);
      setFilterEspacios(filter);
      setListItems(response.data);
      return response.data;
    } catch (error) {
      console.log("Error al traer los items: ", error);
    } finally {
      setOpenBack(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onChange2 = (e, index, newValue) => {
    const { name, value } = e.target || {};
    const updatedValue = newValue || value; // Use newValue if available, fallback to value
    console.log("name", name);
    setItemsNew((prevItems) => {
      const updatedItems = prevItems.map((item, idx) => {
        if (idx === index) {
          return { ...item, [name]: updatedValue };
        }
        return item;
      });

      let totalPrice = 0;
      updatedItems.forEach((item) => {
        totalPrice +=
          parseFloat(item.precio || 0) * parseFloat(item.cantidad || 0);
        if (item.hasOwnProperty("cantidad")) {
          item.perdida =
            parseFloat(item.precio || 0) * parseFloat(item.stock || 0) -
            parseFloat(item.precio || 0) * parseFloat(item.cantidad || 0);
        }
      });
      setEnterPrice(totalPrice);
      setFormData((prev) => ({
        ...prev,
        montoTotalTomaInventario: totalPrice,
      }));

      return updatedItems;
    });
  };

  const onChangeEspacio = (e, index, newValue, name) => {
    const updatedValue = newValue || (e && e.target && e.target.value); // Use newValue if available, fallback to event value
    console.log("name", name);
    console.log("updatedValue", updatedValue);

    setItemsNew((prevItems) => {
      const updatedItems = prevItems.map((item, idx) => {
        if (idx === index) {
          return { ...item, [name]: updatedValue };
        }
        return item;
      });

      let totalPrice = 0;
      updatedItems.forEach((item) => {
        totalPrice +=
          parseFloat(item.precio || 0) * parseFloat(item.cantidad || 0);
        if (item.hasOwnProperty("cantidad")) {
          item.perdida =
            parseFloat(item.precio || 0) * parseFloat(item.stock || 0) -
            parseFloat(item.precio || 0) * parseFloat(item.cantidad || 0);
        }
      });
      setEnterPrice(totalPrice);
      setFormData((prev) => ({
        ...prev,
        montoTotalTomaInventario: totalPrice,
      }));

      return updatedItems;
    });
  };

  const handleTotalPrice = () => {
    const totalPrice = itemsNew.reduce(
      (total, item) => total + item.precio * item.stock,
      0
    );
    setTotalPrice(totalPrice);
    setFormData((prev) => ({ ...prev, montoTotal: totalPrice }));
  };

  const handleClickOpen = () => setOpen3(true);
  const handleClose = () => setOpen3(false);

  // const canSave = [formData.fecha_creacion,].every(Boolean) && addRequestStatus === 'idle';

  const changeSelect = (event) => {
    setFormData({ ...formData, categoria: event.target.value });
  };

  const handleAddItem = () => {
    if (newItem.trim() !== "") {
      setItems([...items, newItem]);
      setNewItem("");
    }
  };

  useEffect(() => {
    if (itemsNew.length != 0) {
      handleTotalPrice();
    }
    setFormData((prev) => ({ ...prev, articulos: itemsNew }));
    console.log("formData", formData);
    if (itemsNew.length != 0) {
      const mapped = itemsNew
        .map((item) => {
          if (item.stock - item.cantidad != 0) {
            return {
              espacioBodegaId: item?.espacioBodega?.id,
              productoId: item.id,
              cantidad: item.stock - item.cantidad,
              motivo: item.motivo,
              observaciones: "",
            };
          } else {
            return null;
          }
        })
        .filter((item) => item !== null);
      setDataMerma(mapped);
      console.log("---------mapped-------", mapped);
    }
  }, [itemsNew]);

  useEffect(() => {
    const allPropertiesPresent = dataMerma.every(
      (item) =>
        item &&
        item.espacioBodegaId !== undefined &&
        item.productoId !== undefined &&
        item.cantidad !== undefined &&
        item.motivo !== undefined
    );

    console.log("dataMerma", dataMerma);
    setCanSaveMerma(allPropertiesPresent);
  }, [dataMerma]);

  const handleChangeBodega = async (event, newValue) => {
    setSelectedBodega(newValue);
    if (newValue != null) {
      try {
        const response = await getBodegas(newValue.id);
        setBodegasItems(response);
        console.log("items::", response);
        setFormData(data);
        setItemsNew([]);
        setItems([]);
        setTotalPrice(null);
        setEnterPrice(null);
        setSelectedItem2(null);
      } catch (error) {
        console.log("error al traer los artículos de la bodega", error);
      }

      setFormData({ ...formData, bodega: newValue.id });
    } else {
      setFormData({ ...formData, bodega: 0 });
    }
  };

  const handleDeleteItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
    setItemsNew(itemsNew.filter((_, i) => i !== index));
  };

  const handleAddItem2 = () => {
    if (newItem2.trim() !== "") {
      setItems2([...items2, newItem2]);
      setNewItem2("");
    }
  };

  const handleDeleteItem2 = (index) => {
    setItems2(items2.filter((_, i) => i !== index));
    setItemsNew2(itemsNew2.filter((_, i) => i !== index));
  };
  const axiosInstance = createAxiosInstance();
  const search = async (e, newValue) => {
    try {
      if (newValue) {
        console.log("aqui");
        setSearching(true);
        const filterItem =
          Array.isArray(bodegasItems) &&
          bodegasItems.find((item) => item.codigo === newValue.codigo);
        console.log(filterItem);
        console.log("filterItem", filterItem);
        if (filterItem) {
          const alreadyIn = itemsNew.find(
            (item) => item.codigo === filterItem.codigo
          );
          if (alreadyIn) {
            toast.error("El artículo ya esta agregado.");
          } else {
            let newValueMapped = {
              ...newValue,
              precio: Math.floor(newValue.precio),
            };
            console.log("newValueMapped", newValueMapped);
            setItemsNew([...itemsNew, newValueMapped]);
            setItems([...items, newValueMapped]);
            // setNewItem('');
            // setSelectedItem2(newValueMapped)
            setSelectedItem2(null);
          }
        } else {
          setSelectedItem2(null);
          toast.error("No se ha encontrado el artículo.");
        }
      }
    } catch (error) {
      console.log("error en el search: ", error);
    } finally {
      setSearching(false);
    }
  };

  const handleSearchItem = (e, newValue) => {
    if (newValue) {
      setSelectedItem(newValue);
      const articulo = listItems2.find(
        (item) => item.codigo == newValue.codigo
      );
      if (!articulo) {
        toast.error("Artículo no encontrado.");
      } else {
        console.log("articulo:", articulo);
        setItemsNew2(articulo);
      }
    }
  };

  useEffect(() => {
    console.log("totalPrice - enterPrice", totalPrice - enterPrice);
    if (totalPrice - enterPrice === 0) {
      setFormData((prev) => ({ ...prev, motivo: "" }));
    }
  }, [totalPrice, enterPrice]);

  useEffect(() => {
    console.log("formData-->", formData);
    console.log("dataMerma-->", dataMerma);
  }, [formData, dataMerma]);

  const canSave =
    [
      formData?.articulos.length > 0,
      formData?.montoTotal !== 0,
      typeof formData?.montoTotalTomaInventario === "number",
      totalPrice - enterPrice > 0
        ? formData.articulos.every((item) =>
            item.perdida > 0 ? item.motivo && item.espacioBodega : true
          )
        : true,
    ].every(Boolean) && addRequestStatus === "idle";

  const handleSubmit = async () => {
    setCheck(true);
    if (canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus("loading");
        console.log("envio", formData);
        let resp = await dispatch(addNewToma(formData));
        if (resp.payload.status === "success") {
          // console.log('entra aqui')
          await Promise.all(
            dataMerma.map((item) => dispatch(addNewMermaAutomatic(item)))
          );
          setFormData(data);
          setOpen(false);
          setItems([]);
          setItems2([]);
          setItemsNew([]);
          setItemsNew2([]);
          setSelectedItem();
          setSelectedBodega();
          setSelectedItem2();
          setBodegasItems([]);
          setOpen2(false);
          setOpen3(false);
          setValue("1");
          return;
        }
      } catch (error) {
        console.error("Error la toma de inventario.", error.message);
      } finally {
        setAddRequestStatus("idle");
        setOpen2(false);
      }
    }
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBack}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            sx={{
              "& .MuiTab-root.Mui-selected": { color: "#0086ae" },
              "& .MuiTabs-indicator": { bgcolor: "#FFF" },
              "& .MuiTab-root": {
                color: "#868686",
              },
            }}
          >
            <Tab label="Registros" value="1" />
            <Tab label="Comparación" value="2" />
            <Tab label="Búsqueda" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1" style={{ padding: "0px" }}>
          {tomasStatus === "loading" ? (
            <SkeletonTable />
          ) : (
            <Table registros={tomas} />
          )}
        </TabPanel>
        <TabPanel value="2">
          <Grid container={true} spacing={1}>
            <Grid item xs={12} md={4}>
              <div>
                <label className={styles.label}>Selecciona una bodega</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      color: "#2d3037",
                    },
                  }}
                  disablePortal
                  disableClearable
                  id="bodegas-autocomplete"
                  options={bodegas}
                  value={selectedBodega}
                  name="idBodega"
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e, value) => handleChangeBodega(e, value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecciona una bodega" />
                  )}
                />
              </div>
              <div className={styles.inventario}>
                <label className={styles.label}>Código del artículo</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      color: "#2d3037",
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      color: "#2d3037",
                    },
                  }}
                  disablePortal
                  disableClearable
                  disabled={!selectedBodega}
                  id="bodegas-autocomplete"
                  options={listItems}
                  value={selectedItem2 || null}
                  name="idBodega"
                  getOptionLabel={(option) => option.nombre}
                  onChange={(event, value) => search(event, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Busca y selecciona articulos"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={5}>
              {items.map((item, index) => (
                <Chip
                  sx={{
                    "& .MuiSvgIcon-root": {
                      color: "#2d3037",
                    },
                  }}
                  key={index}
                  label={item.nombre}
                  onDelete={() => handleDeleteItem(index)}
                  style={{
                    marginRight: "8px",
                    marginBottom: "8px",
                    color: "#2d3037",
                  }}
                />
              ))}
            </Grid>
          </Grid>

          <Grid
            container={true}
            spacing={1}
            style={{
              maxHeight: "500px",
              overflowY: "auto",
              border: "1px solid rgb(199, 199, 199)",
              borderRadius: "5px",
              padding: "5px",
              margin: "0",
              marginTop: "20px",
            }}
          >
            {itemsNew.length !== 0 ? (
              itemsNew.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} md={1}>
                    <label className={styles.label}>Código {index + 1}</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name="codigo"
                      value={item.codigo}
                      type="text"
                      // onChange={(e) => onChange2(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <label className={styles.label}>Descripción</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name="descripcion"
                      value={item.descripcion}
                      type="text"
                      onChange={(e) => onChange2(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <label className={styles.label}>Precio (CLP)</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name="precio"
                      value={item.precio || ""}
                      type="number"
                      onChange={(e) => onChange2(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <label className={styles.label}>Stock en sistema</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name="stock"
                      value={item.stock || ""}
                      type="text"
                      // onChange={(e) => onChange2(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <label className={styles.label}>Cant. inventario</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name="cantidad"
                      type="text"
                      onChange={(e) => onChange2(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <label className={styles.label}>Pérdida</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      // disabled
                      value={item.perdida}
                      name="perdida"
                      type="text"
                      // onChange={(e) => onChange2(e, index)}
                    />
                  </Grid>
                </React.Fragment>
              ))
            ) : (
              <span>Buscar artículos para poder ver su información.</span>
            )}
          </Grid>

          <Grid container={true} spacing={1} mt={2}>
            {itemsNew.length != 0 && (
              <>
                <Grid item xs={12} md={4}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label className={styles.label}>
                      Monto total sistema (CLP)
                    </label>
                    <label
                      className={styles.label}
                      style={{ fontSize: "12px" }}
                    >
                      (Lo que está en stock)
                    </label>
                  </div>
                  <TextField
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    value={totalPrice}
                    name="montoTotal"
                    type="number"
                    // onChange={(e) => onChange(e)}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label className={styles.label}>
                      Monto total toma de inventario (CLP)
                    </label>
                    <label
                      className={styles.label}
                      style={{ fontSize: "12px" }}
                    >
                      (Cantidad de dinero de la información revisada)
                    </label>
                  </div>
                  <TextField
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name="cantidad"
                    value={enterPrice}
                    type="text"
                    // onChange={(e) => onChange(e)}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6}>
              {itemsNew.length !== 0 &&
                itemsNew.every((item) => item.hasOwnProperty("cantidad")) && (
                  <label className={styles.label}>
                    Resumen de la comparación:
                  </label>
                )}
              {itemsNew.length !== 0 &&
                itemsNew.every((item) => item.hasOwnProperty("cantidad")) && (
                  <p>
                    {totalPrice > enterPrice
                      ? "Se ha generado una perdida de: $" +
                        (totalPrice - enterPrice)
                      : "No hay perdidas."}
                  </p>
                )}
            </Grid>
          </Grid>
          <Grid container={true} spacing={1} mt={2}>
            {itemsNew.length !== 0 &&
              itemsNew.every((item) => item.hasOwnProperty("cantidad")) &&
              totalPrice - enterPrice > 0 &&
              itemsNew.map((item, index) => {
                if (item.perdida > 0) {
                  return (
                    <>
                      <Grid item xs={12} md={6}>
                        <label className={styles.label}>
                          Ingrese el motivo de la perdida para el item{" "}
                          {item.nombre}
                        </label>
                        <Select
                          className={styles.textField}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                              },
                              "&:hover fieldset": {
                                border: "none",
                              },
                              "&.Mui-focused fieldset": {
                                border: "none",
                              },
                            },
                            "& .MuiInputBase-input": {
                              color: "#2d3037",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#2d3037",
                            },
                            "& .MuiAutocomplete-clearIndicator": {
                              color: "#2d3037",
                            },
                            "& .MuiAutocomplete-popupIndicator": {
                              color: "#2d3037",
                            },
                          }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={item?.motivo || ""}
                          label=""
                          name="motivo"
                          onChange={(e) => onChange2(e, index)}
                          style={{ width: "100%" }}
                        >
                          <MenuItem value="Deterioro">Deterioro</MenuItem>
                          <MenuItem value="Caducidad">Caducidad</MenuItem>
                          <MenuItem value="Rotura o daño durante el transporte y manipulación">
                            Rotura o daño durante el transporte y manipulación
                          </MenuItem>
                          <MenuItem value="Obsolescencia">
                            Obsolescencia
                          </MenuItem>
                          <MenuItem value="Defectuoso">Defectuoso</MenuItem>
                          <MenuItem value="Extravío">Extravío</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <label className={styles.label}>
                          Espacio de bodega para el item {item.nombre}
                        </label>
                        <Autocomplete
                          className={styles.textField}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                              },
                              "&:hover fieldset": {
                                border: "none",
                              },
                              "&.Mui-focused fieldset": {
                                border: "none",
                              },
                            },
                            "& .MuiInputBase-input": {
                              color: "#2d3037",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#2d3037",
                            },
                            "& .MuiAutocomplete-clearIndicator": {
                              color: "#2d3037",
                            },
                            "& .MuiAutocomplete-popupIndicator": {
                              color: "#2d3037",
                            },
                          }}
                          disablePortal
                          disableClearable
                          id="campamentos-autocomplete"
                          options={
                            (Array.isArray(filterEspacios) &&
                              filterEspacios &&
                              filterEspacios) ||
                            []
                          }
                          name="espacioBodega"
                          value={item.espacioBodega || null}
                          getOptionLabel={(option) => option.identificador}
                          onChange={(e, newValue) =>
                            onChangeEspacio(e, index, newValue, "espacioBodega")
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Seleccion un espacio de la bodega"
                            />
                          )}
                        />
                      </Grid>
                    </>
                  );
                }
              })}
          </Grid>

          <Grid container={true} spacing={1} mt={2}>
            <Grid item xs={12} sm={3}>
              <Button
                onClick={() => setOpen3(true)}
                className={styles.bgColorOrange}
                style={{ opacity: !canSave ? ".7" : "" }}
                variant="contained"
                disabled={!canSave}
              >
                Ingresar registro
              </Button>
            </Grid>
          </Grid>

          <Dialog
            open={open3}
            PaperProps={{
              sx: {
                width: "60%",
                backgroundColor: "#efefef",
                color: "#2d3037",
                borderRadius: "0",
              },
            }}
            onClose={handleClose}
          >
            <DialogTitle id="alert-dialog-title">
              {"Comparaciòn de Artìculos"}
            </DialogTitle>
            <DialogContent>
              <Grid container={true} spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h7"
                    style={{ fontWeight: "600", marginBottom: "5px" }}
                  >
                    Artículos en stock
                  </Typography>
                  {itemsNew?.map((item, index) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderBottom: "1px solid rgb(39, 42, 48)",
                        marginBottom: "5px",
                      }}
                    >
                      <h5>{item.nombre}</h5>
                      <span style={{ fontSize: "14px" }}>
                        Código: {item.codigo}
                      </span>
                      <span style={{ fontSize: "14px" }}>
                        Descripción: {item.descripcion}
                      </span>
                      <span style={{ fontSize: "14px" }}>
                        stock: {item.stock}
                      </span>
                    </div>
                  ))}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h7"
                    style={{ fontWeight: "600", marginBottom: "5px" }}
                  >
                    Artículos inventariados
                  </Typography>
                  {itemsNew?.map((item, index) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderBottom: "1px solid rgb(39, 42, 48)",
                        marginBottom: "5px",
                      }}
                    >
                      <h5>{item.nombre}</h5>
                      <span style={{ fontSize: "14px" }}>
                        Código: {item.codigo}
                      </span>
                      <span style={{ fontSize: "14px" }}>
                        Descripción: {item.descripcion}
                      </span>
                      <span style={{ fontSize: "14px" }}>
                        Cantidad: {item.cantidad}
                      </span>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleSubmit}
                className={styles.bgColorOrange}
                style={{ opacity: !canSave ? ".7" : "" }}
                variant="contained"
                disabled={!canSave}
              >
                {addRequestStatus == "loading" ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Ingresar registro"
                )}
              </Button>
              <Button onClick={handleClose} style={{ color: "#2d3037" }}>
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        </TabPanel>

        <TabPanel value="3">
          <Typography>Búsqueda de un artículo perdido</Typography>
          <Grid container={true} spacing={0}>
            <Grid item xs={12} sm={4}>
              <div>
                <label className={styles.label}>Código</label>
                <div className={styles.inventario}>
                  <Autocomplete
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                      "& .MuiAutocomplete-clearIndicator": {
                        color: "#2d3037",
                      },
                      "& .MuiAutocomplete-popupIndicator": {
                        color: "#2d3037",
                      },
                    }}
                    disablePortal
                    disableClearable
                    id="bodegas-autocomplete"
                    options={listItems2}
                    value={selectedItem}
                    name="idBodega"
                    getOptionLabel={(option) => option.nombre}
                    onChange={(event, value) => handleSearchItem(event, value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Busca y selecciona una artículo"
                      />
                    )}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={8} style={{ padding: "5px" }}>
              {itemsNew2 && (
                <Grid container style={{ padding: "5px" }}>
                  <Grid item xs={12} md={3}>
                    <span>Código: </span>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <span>{itemsNew2?.codigo ?? "-"}</span>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <span>Stock mínimo: </span>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <span>{itemsNew2?.stockMinimo ?? "-"}</span>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <span>Duración (meses): </span>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <span>{itemsNew2?.tiempoDeReposicion ?? "-"}</span>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <span>Precio: </span>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <span>{itemsNew2?.precio ?? "-"}</span>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <span>Descripción: </span>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <span>{itemsNew2?.descripcion ?? "-"}</span>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default Create;
