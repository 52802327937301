import styles from "./components.module.css";
import {
  Grid,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  Autocomplete,
  Popper,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import {
  getInfoByRut,
  liveSearchByRut,
} from "../../../redux/features/users/userSlice";
import { changeStatusDashAseo } from "../../../redux/features/reclamos_sugerencias/reclamos_sugerenciaSlice";

const data = {
  estado: "",
  rut: "",
};
const Edit = ({ openEdit, setOpenEdit, seleItem, setOpen3, personal }) => {
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [formData, setFormData] = useState(data);
  const dispatch = useDispatch();
  const [ruts, setRuts] = useState([]);
  const [person, setPerson] = useState(null);

  useEffect(() => {
    if (seleItem) {
      setFormData(seleItem);
      console.log("seleItem", seleItem);
    }
  }, [seleItem]);

  const handleChangeEstado = (e) =>
    setFormData({ ...formData, estado: e.target.value });

  const onChangeRut = async (e) => {
    try {
      setOpen3(true);
      const resp = await dispatch(liveSearchByRut(e.target.value));
      setRuts(resp.payload.data);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen3(false);
    }
  };

  const onChangeRutSelect = async (e, newValue) => {
    if (newValue != null) {
      setFormData({ ...formData, rut: newValue });
    } else {
      setFormData({ ...formData, rut: null });
    }
  };

  const handleSubmit = async () => {
    try {
      setOpen3(true);
      setAddRequestStatus("loading");
      let resp = await dispatch(changeStatusDashAseo(formData));
      if (resp.payload.status === "success") {
        setOpen3(false);
        setFormData(data);
      }
    } catch (error) {
      console.error("Error al actualizar la solicitud", error.message);
    } finally {
      setAddRequestStatus("idle");
      setOpenEdit(false);
      setOpen3(false); // ver si es que no se elimina esta linea
    }
  };

  return (
    <Dialog
      open={openEdit}
      maxWidth="sm"
      PaperProps={{
        sx: {
          backgroundColor: "#efefef",
          color: "#2d3037",
          borderRadius: "0",
        },
      }}
      onBackdropClick={() => {
        setOpenEdit(false);
      }}
    >
      <DialogTitle>
        <Typography variant="p">Registro N°123</Typography>
        <span className={styles.subTitle} style={{ color:'#2d3037' }}>
          A continuación podrás modificar el estado de la solicitud, ademas de
          poder asignar una persona.
        </span>
      </DialogTitle>
      <DialogContent style={{ height: "250px" }}>
        <Grid container={true}>
          <Grid item xs={12} md={12}>
            <label className={styles.label}>Estado</label>
            <Select
              className={styles.textField2}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#2d3037",
                },
                "& .MuiInputLabel-root": {
                  color: "#2d3037",
                },
                "& .MuiAutocomplete-clearIndicator": {
                  color: "#2d3037",
                },
                "& .MuiAutocomplete-popupIndicator": {
                  color: "#2d3037",
                },
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData?.estado || ""}
              label="solicitud"
              onChange={(e, value) => handleChangeEstado(e, value)}
              style={{ width: "100%" }}
            >
              <MenuItem value="En curso">En curso</MenuItem>
              <MenuItem value="Finalizada">Finalizada</MenuItem>
              <MenuItem value="Rechazada">Rechazada</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} md={12}>
            <label className={styles.label}>
              Asigna una persona (busca por rut)
            </label>
            <Autocomplete
              className={styles.textField2}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#2d3037",
                },
                "& .MuiInputLabel-root": {
                  color: "#2d3037",
                },
                "& .MuiAutocomplete-clearIndicator": {
                  color: "#2d3037",
                },
                "& .MuiAutocomplete-popupIndicator": {
                  color: "#2d3037",
                },
              }}
              disablePortal
              disableClearable
              id="sectores-autocomplete"
              name="rut"
              value={formData?.rut || null}
              options={personal ? personal : []}
              getOptionLabel={(option) =>
                option.rut + " " + option.nombre + " " + option.apellido
              }
              onChange={(e, value) => onChangeRutSelect(e, value)}
              renderInput={(params) => (
                <TextField {...params} label="Busca y selecciona un rut" />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container={true} style={{ padding: "0 16px" }}>
          <Grid item xs={12} sm={12} className={styles.buttonContainer}>
            <Button
              onClick={handleSubmit}
              className={styles.bgColorModal}
              variant="contained"
              disabled={
                !formData?.rut ||
                !formData?.estado ||
                addRequestStatus === "loading"
              }
            >
              {addRequestStatus == "loading" ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "Modificar"
              )}
            </Button>
            <Button
              onClick={() => setOpenEdit(false)}
              className={styles.bgColorOutlinedBlack}
              variant="outlined"
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default Edit;
