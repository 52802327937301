import styles from "./components.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { liveSearchByRut } from "../../../../redux/features/users/userSlice";
import {
  addNewRetiro,
  fetchEspacios,
  getAllEspacios,
  getEspacioStatus,
} from "../../../../redux/features/guardarropia/guardarropiaSlice";
import { createAxiosInstance } from "../../../../api/axios";
import MapIcon from "@mui/icons-material/Map";

const data = {
  rutTrabajador: "",
  espacioId: "",
  items: [],
};

const Retiro = ({
  campamentos,
  open2,
  setOpen2,
  ingresos,
  setValue,
  espacios,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [ocupado, setOcupado] = useState(false);
  const [ultimoNumero, setUltimoNumero] = useState(0);
  const [primerCampamento, setPrimerCampamento] = useState(0);
  const [ruts, setRuts] = useState([]);
  const [selectedRuts, setSelectedRuts] = useState([]);
  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState("");
  const [selectedEspacio, setSelectedEspacio] = useState(null);
  const [check, setCheck] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [objectsArray, setObjectsArray] = useState([]);
  const [rut, setRut] = useState("");

  const [filasNivelesNumeros, setFilasNivelesNumeros] = useState([]);

  const [isCampamentoEnabled, setIsCampamentoEnabled] = useState(false);
  const [isFilaEnabled, setIsFilaEnabled] = useState(false);
  const [isNivelEnabled, setIsNivelEnabled] = useState(false);
  const [isNumerosEnabled, setIsNumerosEnabled] = useState(false);

  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [selectedFila, setSelectedFila] = useState(null);
  const [selectedNivel, setSelectedNivel] = useState(null);
  const [selectedNumero, setSelectedNumero] = useState(null);

  const [campamento, setCampamento] = useState(null);
  const [fila, setFila] = useState(null);
  const [nivel, setNivel] = useState(null);
  const [numero, setNumero] = useState(null);
  const [filterEspacios, setFilterEspacios] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const axiosInstance = createAxiosInstance();

  const getItems = async (rut) => {
    try {
      const response = await axiosInstance.get(
        "/api/v1/Guardarropia/ItemsHuesped/" + rut
      );
      const itemsList = response.data[0].items;
      return itemsList;
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    setFormData(data);
    setSelectedEspacio(null);
    setCheckedItems({});
  }, [open]);
  useEffect(() => {
    const filt =
      espacios && espacios.filter((item) => item.estado !== "Disponible");
    setFilterEspacios(filt);
  }, [espacios]);

  const handleCheckboxChange = (item) => {
    const checked = !checkedItems[item];

    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [item]: checked,
    }));

    setFormData((prevFormData) => {
      const newItems = checked
        ? [...prevFormData.items, item]
        : prevFormData.items.filter((itemValue) => itemValue !== item);
      return {
        ...prevFormData,
        items: newItems,
      };
    });
  };

  const handleCheckAll = () => {
    const allCheckedItems = items.reduce((acc, item) => {
      acc[item] = true;
      return acc;
    }, {});

    setCheckedItems(allCheckedItems);
    setFormData((prev) => ({ ...prev, items }));
  };

  const handleChangeEspacio = async (event, newValue) => {
    setSelectedEspacio(newValue);
    if (newValue != null) {
      const ingresoEncontrado = ingresos.find(
        (item) => item.espacioAlmacenamiento.id === newValue.id
      );
      if (!ingresoEncontrado) {
        toast.error("No hay ningún trabajador asignado a este espacio.");
        return;
      }
      setItems([]);
      setAllItems([]);
      setCheckedItems([]);

      const trabajador = ingresoEncontrado.trabajador;
      if (!trabajador) {
        setItems([]);
        setAllItems([]);
        setCheckedItems([]);
        setFormData(data);
        toast.error("No hay ningún trabajador asignado a este espacio.");
        return;
      }

      try {
        const itemsList = await getItems(trabajador.rut);
        if (itemsList.length > 0) {
          console.log("items", itemsList);
          setItems(itemsList);
          setCheckedItems(itemsList);
          setAllItems(itemsList);
          setFormData((prev) => ({
            ...prev,
            items: [],
            espacioId: newValue.id,
            rut: trabajador,
          }));
        } else {
          toast.error("El usuario no cuenta con items almacenados.");
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setFormData({ ...formData, espacioId: 0 });
    }
  };

  useEffect(() => {
    if (filterEspacios) {
      const primerEspacioLibre = filterEspacios.find(
        (espacio) => espacio.estado !== "Disponible"
      );
      if (primerEspacioLibre) {
        setUltimoNumero(primerEspacioLibre.numero);
        setPrimerCampamento(primerEspacioLibre.campamento);
      }
    }
  }, [filterEspacios]);

  useEffect(() => {
    if (!searchValue) {
      console.log("No hay searchValue");
      return;
    }
    const handler = setTimeout(async () => {
      try {
        setOpen2(true);
        setRuts([]);
        const resp = await dispatch(liveSearchByRut(searchValue));
        setRuts(resp.payload.data);
      } catch (error) {
        console.log(error);
      } finally {
        setSearchValue("");
        setOpen2(false);
      }
    }, 1000); // Espera de 1 segundo (1000 milisegundos)

    // Limpiar timeout en caso de que el usuario escriba antes de que se complete el tiempo
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue, dispatch]);

  // Handler para el cambio en el input del autocomplete
  const onChangeRutInput = (e) => {
    setSearchValue(e.target.value);
  };

  // const onChangeRut = async (e) => {
  //   try {
  //     setOpen2(true);
  //     const resp = await dispatch(liveSearchByRut(e.target.value))
  //     setRuts(resp.payload.data);
  //   } catch (error) {
  //     console.log(error)
  //   } finally{
  //     setOpen2(false)
  //   }
  // }

  useEffect(() => {
    dispatch(fetchEspacios());
  }, []);

  const onChangeRutSelect = async (e, newValue) => {
    if (newValue != null) {
      setRut(newValue);
      setFormData({ ...formData, rut: newValue });
      setSelectedRuts({ ...selectedRuts, newValue });
      try {
        const filt = ingresos.find(
          (item) => item.trabajador.rut === newValue.rut
          // && item.espacioAlmacenamiento.id === formData.espacioId
        );

        if (!filt) {
          toast.error("El trabajador no tiene items ingresados.");
          setFormData(data);
          setSelectedEspacio(null);
          setAllItems([]);
          setCheckedItems([]);
          setItems([]);
          return;
        } else {
          setCheck(true);
          console.log(filt);
        }

        const espacio = ingresos.find(
          (item) => item.trabajador.rut === newValue.rut
        ).espacioAlmacenamiento;
        console.log("espacio", espacio);
        setSelectedEspacio(espacio);
        const itemsList = await getItems(newValue.rut);
        console.log("items", itemsList);
        setItems(itemsList || []);
        setCheckedItems(itemsList || []);
        setAllItems(itemsList || []);
        setFormData((prev) => ({
          ...prev,
          items: itemsList || [],
          espacioId: espacio.id,
        }));
      } catch (e) {
        console.log(e);
      }
    } else {
      setCheck(false);
      setRut("");
      setFormData({ ...formData, rutTrabajador: "" });
    }
  };

  const canSave =
    [formData?.items.length > 0].every(Boolean) && addRequestStatus === "idle";

  useEffect(() => {
    if (check) {
      handleCheckAll();
    }
  }, [check, allItems]);

  const handleSubmit = async () => {
    if (canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus("loading");
        console.log("send", formData);
        let resp = await dispatch(addNewRetiro(formData));
        if (resp.payload.status === "success") {
          setFormData(data);
          setSearchValue("");
          setSelectedEspacio(null);
          setOpen(false);
          setItems([]);
          setAllItems([]);
          setValue("2");
          dispatch(fetchEspacios());
        }
      } catch (error) {
        console.error("Error al asignar el espacio de almacenamiento", error);
      } finally {
        setOpen2(false);
        setAddRequestStatus("idle");
      }
    }
  };

  const handleCampamentoChange = (event, newValue) => {
    handleDefault();
    setIsCampamentoEnabled(false);
    setIsFilaEnabled(false);
    setIsNivelEnabled(false);
    setSelectedFila(null);
    setSelectedNivel(null);
    setSelectedNumero(null);
    setSelectedCampamento(newValue);
    if (newValue != null) {
      setIsCampamentoEnabled(true);
    }
  };

  const handleFilaChange = (event, newValue) => {
    setSelectedFila(newValue);
    setIsFilaEnabled(true);
    if (newValue != null) {
      setIsFilaEnabled(true);
    } else {
      setIsFilaEnabled(false);
      setSelectedNivel(null);
    }
  };

  const handleNivelChange = (event, newValue) => {
    setIsCampamentoEnabled(false);
    setSelectedNivel(newValue);
    if (newValue != null) {
      setIsNivelEnabled(true);
    } else {
      setIsCampamentoEnabled(true);
      setIsNivelEnabled(false);
      setSelectedNumero(null);
    }
  };

  const handleNumberChange = (event, newValue) => {
    setIsFilaEnabled(false);
    setSelectedNumero(newValue);
    if (newValue != null) {
      setIsNumerosEnabled(true);
      setFormData((prev) => ({ ...prev, espacioId: newValue }));

      const selectedSpace = filterEspacios.find(
        (espacio) =>
          espacio.campamento.id === selectedCampamento?.id &&
          espacio.fila === selectedFila &&
          espacio.nivel === selectedNivel &&
          espacio.numero === newValue
      );
      if (selectedSpace && selectedSpace.espacioItems.length > 0) {
        const trabajador = selectedSpace.espacioItems[0];
        setFormData((prev) => ({
          ...prev,
          rutTrabajador: trabajador.rutTrabajador,
        }));
        const newItems = selectedSpace.espacioItems[0].items;
        setItems(newItems);
      } else {
        setItems([]);
      }
    } else {
      setItems([]);
      setIsFilaEnabled(true);
    }
  };

  const handleObjectChange = (e, value) => {
    setCheckedItems({});
    setFormData((prev) => ({ ...prev, items: [] }));
    if (value) {
      setItems(value.items);
      setFormData((prev) => ({
        ...prev,
        espacioId: value.espacioAlmacenamiento.numero,
      }));
    } else {
      setItems([]);
      setFormData((prev) => ({ ...prev, espacioId: "" }));
    }
  };

  const handleDefault = () => {
    setFormData((prev) => ({
      ...prev,
      rutTrabajador: "",
      espacioId: "",
      items: [],
    }));
    setObjectsArray([]);
    setCheckedItems({});
    setRuts([]);
    setItems([]);
    setSelectedRuts([]);
    setRut("");
  };

  const inputDefault = () => {
    setSelectedCampamento(null);
    setSelectedFila(null);
    setSelectedNivel(null);
    setSelectedNumero(null);
    setIsCampamentoEnabled(false);
    setIsFilaEnabled(false);
    setIsNivelEnabled(false);
    setIsNumerosEnabled(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "5px",
        }}
        className={styles.textMobile}
      >
        <Typography>
          El siguiente <strong>botón</strong> te permitirá ingresar el retiro
          total o parcial de los implementos de seguridad de un trabajador.
        </Typography>
        <Button
          variant="contanied"
          size="large"
          disabled={campamentos.length == 0}
          className={[styles.bgColorOrange, styles.fullwidth]}
          onClick={() => {
            setOpen(true);
            handleDefault();
            inputDefault();
          }}
        >
          Retiro de items
        </Button>
      </div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display: "flex", gap: "5px" }}>
          <MapIcon
            className={styles.iconBgCreate}
            style={{ fontSize: "3rem", marginTop: "0px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="span"
              style={{ fontSize: "24px", lineHeight: "1" }}
            >
              Retirar elementos de guardarropia
            </Typography>
            <span className={styles.subTitle}>
              Rellena los siguientes campos para registrar el retiro de
              elementos de guardarropia.
            </span>
          </div>
        </DialogTitle>
        {/*
          {JSON.stringify(formData)} 
          */}
        <DialogContent style={{ minHeight: "300px" }}>
          <Grid container={true} spacing={1}>
            <Grid item xs={12} sm={12}>
              <label className={styles.label}>
                N°Espacio de almacenamiento
              </label>
              <Autocomplete
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-clearIndicator": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    color: "#2d3037",
                  },
                }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                options={filterEspacios}
                name="idEspacio"
                value={selectedEspacio || null}
                getOptionLabel={(option) =>
                  option.numero + " - " + option.campamento.nombre
                }
                onChange={(e, value) => handleChangeEspacio(e, value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Busca y selecciona un espacio"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Trabajador</label>
              <Autocomplete
                className={styles.textField}
                disablePortal
                disableClearable
                id="sectores-autocomplete"
                name="rut"
                value={formData?.rut || null}
                options={ruts ? ruts : []}
                getOptionLabel={(option) => option.rut + " " + option.nombre}
                onChange={(e, value) => onChangeRutSelect(e, value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Busca y selecciona un rut"
                    onChange={onChangeRutInput}
                  />
                )}
              />
            </Grid>
            {objectsArray.length > 0 && (
              <Grid item xs={12} sm={12}>
                <label className={styles.label}>Numero de espacio</label>
                <Autocomplete
                  className={styles.textField}
                  disablePortal
                  disableClearable
                  id="objects-autocomplete"
                  options={objectsArray}
                  getOptionLabel={(option) =>
                    option.espacioAlmacenamiento
                      ? option.espacioAlmacenamiento.numero
                      : ""
                  }
                  onChange={handleObjectChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Busca y selecciona un numero de espacio"
                    />
                  )}
                />
              </Grid>
            )}
            {objectsArray.length > 0 && items.length != 0 && (
              <Grid
                item
                xs={12}
                md={12}
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Button
                  onClick={handleCheckAll}
                  className={styles.bgColorOrange}
                  variant="contained"
                >
                  Seleccionar todos
                </Button>
              </Grid>
            )}
            {items.length != 0 && (
              <Grid container style={{ margin: "10px" }}>
                <Grid xs={12} md={12}>
                  <label className={styles.label} style={{ display: "block" }}>
                    Retiro de items
                  </label>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                {items.slice(0, items.length / 2).map((item, index) => (
                  <Grid item xs={12}>
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={checkedItems[item] || false}
                          onChange={() => handleCheckboxChange(item)}
                          color="primary"
                        />
                      }
                      label={item}
                    />
                  </Grid>
                ))}
              </Grid> */}
                <Grid item xs={12} md={6}>
                  {/*{items.slice(items.length / 2).map((item, index) => ( */}
                  {items.map((item, index) => (
                    <Grid item xs={12}>
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={checkedItems[item] || false}
                            onChange={() => handleCheckboxChange(item)}
                            color="primary"
                          />
                        }
                        label={item}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={12} className={styles.buttonContainer}>
            <Button
              onClick={handleSubmit}
              className={styles.bgColorModal}
              variant="contained"
              disabled={!canSave}
            >
              {addRequestStatus == "loading" ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "Ingresar retiro"
              )}
            </Button>
            <Button
              onClick={() => setOpen(false)}
              className={styles.bgColorOutlined}
              variant="outlined"
            >
              Cerrar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Retiro;

// sx={{
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       border: "none",
//     },
//     "&:hover fieldset": {
//       border: "none",
//     },
//     "&.Mui-focused fieldset": {
//       border: "none",
//     },
//   },
//   "& .MuiInputBase-input": {
//     color: "#2d3037",
//   },
//   "& .MuiInputLabel-root": {
//     color: "#2d3037",
//   },
//   "& .MuiAutocomplete-clearIndicator": {
//     color: "#2d3037",
//   },
//   "& .MuiAutocomplete-popupIndicator": {
//     color: "#2d3037",
//   },
// }}
