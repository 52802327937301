import styles from './components/components.module.css';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Backdrop, Container, CircularProgress, Card, Drawer, DialogTitle, Typography } from '@mui/material';
import Header from '../../../components/header/Header';
import { fetchHabitaciones, getAllHabitaciones, getHabitacionesStatus } from '../../../redux/features/habitaciones/habitacionSlice';
import { useDispatch, useSelector } from 'react-redux';
import Table, { TabsContainer } from "./components/historial/Table";
import { toast } from 'react-toastify';
import { fetchReservas, getAllreservas, getReservasStatus } from '../../../redux/features/reservas/reservaSlice';

const data = {nombre:'', list:[]}

const Historial = () => {
    const [open3, setOpen3] = useState(false);
    const [value, setValue] = useState(0);
    const [seleItem, setSeleItem] = useState(); //reservas
    const [checkedState, setCheckedState] = useState([]); //detalle

    const [formData, setFormData] = useState(data)
    const [openDetalle, setOpenDetalle] = useState(false); //reservas
    const [openDrawer, setOpenDrawer] = useState(false); //reservas
    const solicitudes = useSelector(getAllreservas);
    const statusReservas = useSelector(getReservasStatus);
    const dispatch = useDispatch();//habitaciones
    const [checkedHabitaciones, setCheckedHabitaciones] = useState([]); //habitaciones
    const status = useSelector(getHabitacionesStatus); //habitaciones
    const [asignacionHabitacionesTrabajadores, setAsignacionHabitacionesTrabajadores] = useState([]);
    const habitaciones = useSelector(getAllHabitaciones);
    const [check, setCheck] = useState(false);

    useEffect(() => {
        if(status == 'idle'){
            dispatch(fetchHabitaciones());
          }
        if(statusReservas == 'idle'){
          dispatch(fetchReservas());
        }
    }, [])
    
      useEffect(()=>{
        setCheckedState([]);
        setCheckedHabitaciones([]);
        // const trabajadores = useSelector(getAllTrabajadoresReserva);
        if(seleItem){
          // toast.success('Solicitud seleccionada, puedes ingresar a "DETALLE RESERVA"')
        }
      },[seleItem])

      const asignar = () => {
        console.log('listado de trabajdores seleccionados: ',checkedState);
        // AQUI VA LA LOGICA QUE ENVIA EL LISTADO Y LA FECHA EN QUE ESTARÁN EN FAENA
      }

  return (
    <Box width={1} mt={0} mb={2}>
        <Container>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open3}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Header 
                title="Historial de reservas"
                subtitle="Este módulo ofrece la capacidad de gestionar las solicitudes de reservas dentro del historial de reservas.">
                {<HolidayVillageIcon style={{ fontSize:'1.5rem' }}/>}
            </Header>


            <Drawer
                anchor='right'
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
            >
                <DialogTitle>
                    <Typography variant="span" className={styles.textIcon}><ErrorIcon/>Estás a punto de actualizar la orden</Typography>
                </DialogTitle>
            </Drawer>


            <Card style={{ marginTop:'10px' }}>
                <TabsContainer
                    setValue={setValue} 
                    value={value} 
                    seleItem={seleItem} 
                    checkedState={checkedState} 
                    checkedHabitaciones={checkedHabitaciones}
                    check={check}
                />

                <Table 
                    setOpenDrawer={setOpenDrawer}
                    value={value}
                    statusReservas={statusReservas}
                    setValue={setValue} 
                    setFormData={setFormData}
                    formData={formData}
                    solicitudes={solicitudes}
                    seleItem={seleItem}
                    setSeleItem={setSeleItem}
                    setOpenDetalle={setOpenDetalle}
                    checkedState={checkedState} 
                    setCheckedState={setCheckedState}
                    checkedHabitaciones={checkedHabitaciones}
                    setCheckedHabitaciones={setCheckedHabitaciones}
                    asignar={asignar}
                    setCheck={setCheck}
                    check={check}
                    asignacionHabitacionesTrabajadores={asignacionHabitacionesTrabajadores}
                    setAsignacionHabitacionesTrabajadores={setAsignacionHabitacionesTrabajadores}
                    />
            </Card>

        </Container>
    </Box>
  )
}

export default Historial