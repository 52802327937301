import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Autocomplete, Backdrop, Button, CircularProgress, Container, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCampamentos } from '../../../redux/features/campamentos/campamentoSlice';
import { useEffect } from 'react';
import { updateSector } from '../../../redux/features/sectores/sectorSlice';
import { getAllBodegas, updateEspacioBodega } from '../../../redux/features/bodegas/bodegaSlice';

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3 }) {
  const GRID_SIZE = 12;


    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState();
    const dispatch = useDispatch();
    const bodegas = useSelector(getAllBodegas);
    const [selectedBodega, setSelectedBodega] = useState(null);
    const [grid, setGrid] = useState(Array(GRID_SIZE).fill().map(() => Array(GRID_SIZE).fill(false)));
    const [identificador, setIdentificador] = useState(null);

    useEffect(() => {
            if(seleItem){
              setFormData(seleItem)
              setSelectedBodega(seleItem?.bodega)
              setIdentificador(seleItem?.identificador)
              setFormData(prev => {
                return {
                  ...prev,
                  bodegaId: seleItem?.bodega?.id,
                  identificador: seleItem?.identificador
                }
              })
              console.log('seleItem-->', seleItem);
            }
    }, [seleItem])

        
    const onChange = (e) => {
      let { name, value } = e.target;
      if (name === 'altura') {
        if (!isNaN(value)) {
          value = parseInt(value, 10);
        } else {
          value = '';
        }
      }
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
    
    const handleChangeBodega = (event, newValue) => {
      setSelectedBodega(newValue);
      if(newValue != null){
        setFormData({...formData, bodegaId: newValue});
      } else{
        setFormData({...formData, bodegaId: 0})
      }
      
    };

    const canSave = [
      // formData?.pasilloHorizontal, formData?.pasilloVertical, formData?.altura, 
      formData?.bodegaId, formData?.identificador].every(value => value === 0 || Boolean(value)) && addRequestStatus === 'idle';

    const handleEdit = async () => {
        try {
            setOpen3(true);
            setAddRequestStatus('loading');
            let resp = await dispatch(updateEspacioBodega(formData))
            if(resp.payload.status === 'success'){
              setOpenEdit(false);
            }
          } catch (error) {
            console.error('Error al actualizar el espacio', error.message);
          } finally{
            setOpen3(false);
            setAddRequestStatus('idle');
        }
    }
    
    const handleGridClick = (i, j) => {
      const newIdentificador = i * GRID_SIZE + j;
      setIdentificador(newIdentificador);
      const newGrid = grid.map((row, rowIndex) => row.map((cell, cellIndex) => rowIndex * GRID_SIZE + cellIndex === newIdentificador));
      setGrid(newGrid);
      setFormData(prevState => ({
        ...prevState,
        identificador: newIdentificador
      }));
    };
    
    useEffect(() => {
      if (identificador !== null) {
        const i = Math.floor(identificador / GRID_SIZE);
        const j = identificador % GRID_SIZE;
        const newGrid = grid.map((row, rowIndex) => row.map((cell, cellIndex) => rowIndex === i && cellIndex === j));
        setGrid(newGrid);
      }
    }, [identificador]);

    // useEffect(() => {
    //   if (openEdit && seleItem) {
    //     const i = Math.floor(seleItem.identificador / GRID_SIZE);
    //     const j = seleItem.identificador % GRID_SIZE;
    //     const newGrid = Array(GRID_SIZE).fill().map(() => Array(GRID_SIZE).fill(false));
    //     newGrid[i][j] = true;
    //     setGrid(newGrid);
    //     setIdentificador(seleItem.identificador);
    //     setFormData(prev => {
    //       return {
    //         ...prev,
    //         identificador: seleItem?.identificador
    //       }
    //     })
    //   }
    // }, [openEdit]);

  return (
      <Drawer
        anchor='right'
        open={openEdit}
        PaperProps={
          {
            sx: {
              width: { xs: '100%', sm: '80%', md: '60%' },
              backgroundColor:'#efefef',
              color:'#2d3037',
              borderRadius:'0'
            }
          }
      }
        onClose={() => setOpenEdit(false)}
      >
        {seleItem && (
            <>
            <DialogTitle>
                <Grid container={true} spacing={1} px={2}>
                    <Grid item  xs={12} sm={12} style={{ display:'flex', gap:'5px'}}>
                        <ErrorIcon style={{ fontSize:'3rem' }} className={styles.iconBg}/>
                        <div style={{ display:'flex', flexDirection:'column' }}>
                            <Typography variant="h6" className={styles.textIconEdit}>Actualizar espacio en bodega {seleItem?.bodega.nombre}</Typography>
                            <Typography variant="span" className={styles.textIconSpan}>Modifica los campos para actualizar el espacio en bodega.</Typography>
                        </div>
                    </Grid>
                </Grid>
            </DialogTitle>
                {/*{JSON.stringify(formData)} */}
                <DialogContent>
                
                    <Grid container={true} spacing={1} p={2}>
                        
                        <Grid item  xs={12} sm={6}>
                          <label className={styles.label}>Bodega</label>
                          <Autocomplete
                            className={styles.textField}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                  },
                                  '&:hover fieldset': {
                                    border: 'none',
                                  },
                                  '&.Mui-focused fieldset': {
                                    border: 'none',
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  color:'#2d3037',
                                },
                                '& .MuiInputLabel-root': {
                                  color:'#2d3037',
                                },
                                '& .MuiAutocomplete-clearIndicator': {
                                  color:'#2d3037',
                                },
                                '& .MuiAutocomplete-popupIndicator': {
                                  color:'#2d3037',
                                },
                            }}
                            disablePortal
                            disableClearable
                            id="campamentos-autocomplete"
                            options={bodegas}
                            value={selectedBodega || ''}
                            name="bodega"
                            getOptionLabel={(option) => option.nombre}
                            onChange={(e, value)=> handleChangeBodega(e, value)}
                            renderInput={(params) => <TextField {...params} label="Selecciona una bodega" />}
                          />      
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <label>Selección - Identificador</label>
                          <TextField
                            className={styles.textField}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                                '&:hover fieldset': {
                                  border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                  border: 'none',
                                },
                              },
                              '& .MuiInputBase-input': {
                                color:'#2d3037',
                              },
                              '& .MuiInputLabel-root': {
                                color:'#2d3037',
                              },
                            }}
                            variant="outlined"
                            fullWidth
                            name='identificador'
                            value={formData?.identificador || ''}
                            onChange={(e) => onChange(e)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {(formData?.bodegaId && selectedBodega && selectedBodega?.imagen) ? 
                            <div>
                              {/*
                              <label>Realice una selección con un <strong style={{ color: 'red' }}>click</strong> para el Identificador</label>
                              <div style={{ height: '5px' }}></div>
                              */}
                              <img style={{ height: '95%', width: '95%' }} src={`${process.env.REACT_APP_API_URL}${selectedBodega?.imagen}`} alt="img_bodega_sin" />
                              {/*
                                <div style={{ position: 'relative', width: '95%', height: '95%' }}>
                                <div   style={{ 
                                    top: 0, 
                                    left: 0, 
                                    width: '95%', 
                                    height: '98.5%', 
                                    display: 'grid', 
                                    gridTemplateColumns: `repeat(${GRID_SIZE}, 1fr)`, 
                                    gridTemplateRows: `repeat(${GRID_SIZE}, 1fr)`,
                                    backgroundColor:'#FFF !important'
                                  }}>
                                  {grid.map((row, i) => row.map((cell, j) => (
                                    <div key={`${i}-${j}`}  style={{ 
                                      border: '1px solid #FFF',
                                      padding:'10px',
                                      color:'#FFF', 
                                    }} onClick={() => handleGridClick(i, j)} />
                                  )))}
                                </div>
                              </div>
                              */}
                             </div>
                            :
                            <span style={{ fontSize:'14px', fontWeight:'500' }}>La bodega seleccionada no tiene una imagen asociada.</span>
                          }
                        </Grid>        
                      </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Grid container={true}>
                        <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                            <Button 
                                onClick={handleEdit}
                                className={styles.bgColorEdit}
                                variant="contained" 
                                disabled={!canSave}
                                >
                                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                            </Button>
                            <Button 
                                onClick={()=>setOpenEdit(false)}
                                className={styles.bgColorOutlined}
                                variant="outlined" 
                                >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                  </DialogActions>
            </>
        )}
      </Drawer>
  );
}