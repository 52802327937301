import styles from './components.module.css';
import { useState } from "react";
import { planificacionDashboardColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { Dialog, DialogContent, DialogTitle, Box } from '@mui/material'

const TableDash = ({ registros, setSelectedItems, selectedItems}) => {

  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [openReset, setOpenReset] = useState(false);
  const [openDatos, setOpenDatos] = useState(false);
  const [verCalendario, setVerCalendario] = useState(false);
  const [editOrCreate, setEditOrCreate] = useState(false);
  const finalColumns = planificacionDashboardColumns(setOpen, setSeleItem, setOpenEdit, setOpenReset, setOpenDatos, setEditOrCreate, setVerCalendario, verCalendario);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCellValue, setSelectedCellValue] = useState('');
  const [selectedCellName, setSelectedCellName] = useState('');
  
  const handleCellClick = (params, event) => {
    console.log(params);
    // if (params.field === 'opciones') return;
    if (params.field === 'asignados') {
      event.stopPropagation();
      setSelectedCellValue(params.value);
      setSelectedCellName(params.field);
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  const handleRowSelectionModelChange = (selectionModel) => {
    console.log(selectionModel);
    
    const selectedRows = selectionModel.map((id) => {
      return registros.find((row) => row.id === id);
    });
    setSelectedItems(selectedRows.map((row) => ({ ...row })));
  };

  return (
    <div>    
      <DataGrid
        getRowId={row=>row.id ? row.id : Math.random() }
        lots={{ toolbar: GridToolbar }}
        rows={registros}
        onCellClick={handleCellClick}
        checkboxSelection
        rowSelectionModel={selectedItems.map(item => item.id)}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        columns={finalColumns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15, 20]}
        localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
                labelRowsPerPage: "Filas por página:",
            }
        }}
        classes={{ menuIcon: styles.menuIcon }}
        style={{ maxHeight:'500px', color:'#2d3037', border:'none' }}
        sx={{ 
              '& .MuiDataGrid-cell': {
                color: '#2d3037',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeader': {
                color: '#2d3037',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-footerContainer': {
                color: '#2d3037',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiTablePagination-root': {
                color: '#2d3037',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-toolbarContainer': {
                color: '#2d3037',
              },
              '& .MuiDataGrid-menu': {
                color: '#2d3037',
              },
              '& .MuiSvgIcon-root':{
                color: '#9ca91c',
              },
              '& .MuiTablePagination-actions': {
                color: '#2d3037', // Color for pagination actions
              },
              '& .MuiTablePagination-select': {
                color: '#2d3037', // Color for pagination select
              },
              '& .MuiTablePagination-selectLabel': {
                color: '#2d3037', // Color for pagination select label
              },
              '& .MuiTablePagination-displayedRows': {
                color: '#2d3037', // Color for displayed rows text
              },
              '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                color: 'red',
              },
              '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                color: 'red',
              },
        }}
        slotProps={{
                  toolbar:{
                    sx:{
                      color:'#FFF'
                    }
                  },
                  pagination: {
                    sx: {
                      color: '#f19d38',
                    },
                  },
                  menu: {
                    sx: {
                      color: '#f19d38',
                    },
                  },
        }}
      />
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogContent>
          <Box>{selectedCellValue ?? '-'}</Box>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default TableDash