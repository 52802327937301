import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip, Legend, ArcElement, CategoryScale, PointElement, Title } from "chart.js";
import { useEffect, useState } from "react";
import { randomNumberBetween } from '@mui/x-data-grid/utils/utils';

ChartJS.register(Tooltip, Legend, ArcElement, CategoryScale, PointElement, Title);

const colores = [
  "rgba(255, 99, 132, 0.2)",
  "rgba(54, 162, 235, 0.5)",
  "rgba(255, 206, 86, 0.5)",
  "rgba(75, 192, 192, 0.5)",
  "rgba(153, 102, 255, 0.5)",
  "rgba(255, 159, 64, 0.5)",
];

const SingleChart = ({ data }) => {

  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [colors, setColors] = useState([]);
  
  useEffect(() => {
    if(data){
      setLabels([data.nombre]);
      setValues([data.porcentajeDeOcupacion]);
      const coloresX = colores.slice(0, randomNumberBetween(0,7));
      setColors(coloresX);
    }
  }, [data]);
  
  const pieChartData = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: [data.porcentajeDeOcupacion, 100 - data.porcentajeDeOcupacion],
        backgroundColor: [
          "#ed6412", // Color para la parte ocupada
          "rgba(0, 0, 0, 0.5)" // Color para el espacio no ocupado 
        ],
        hoverOffset: 4
      }
    ]
  };
  
  const options = {
    responsive: true,
    color:'#FFF',
    plugins: {
      legend: {
        position: "bottom"
      },
      title: {
        display: true,
        text: `Porcentaje de ocupación - ${data.nombre}`, // Agrega el nombre aquí,
        color:'#FFF'
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.dataIndex === 0) {
              label += context.raw + '% del espacio utilizado ';
            } else {
              label += (100 - context.raw) + '% del espacio no utilizado';
            }
            return label;
          }
        }
      }
    }
  };

  return (
    <>
      <Pie data={pieChartData} options={options}/>
    </>
  );
};

export default SingleChart;
