import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';
import { createAxiosInstance } from "../../../api/axios";

const ESPACIOS_URL = '/api/v1/Espacio';
const ADD_ESPACIO_URL = '/api/v1/Espacio';
const DELETE_ESPACIO_URL = '/api/v1/Espacio/';
const UPDATE_ESPACIO_URL = '/api/v1/Espacio/';

const axiosInstance = createAxiosInstance();

// ACTIONS
export const fetchEspacios = createAsyncThunk("espacios/fetchEspacios", async ()=> {
    try {
        const response = await axiosInstance.get(ESPACIOS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})

export const addNewEspacio = createAsyncThunk("espacios/createEspacio", async (initialCampamento, {dispatch}) => {
    try {
        const response = await axiosInstance.post(ADD_ESPACIO_URL, {
            pabellonId: initialCampamento.pabellonId,
            wingId: initialCampamento.wingId,
            nombre: initialCampamento.nombre,
            piso: initialCampamento.piso,
            descripcion: initialCampamento.descripcion,
            // inventario: initialCampamento.inventario,
            });
        if (response.status === 200) {
            toast.success('Espacio creado correctamente.');
            dispatch(fetchEspacios());
            return {data: response.data, status: 'success'};
        }
        return {status: 'error'};
      } catch (e) {
        toast.error('Error al crear el espacio: ' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})

export const deleteEspacio = createAsyncThunk("espacios/deleteEspacio", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.delete(DELETE_ESPACIO_URL+id);
        
        if (response.status === 200) {
            toast.success('Espacio común eliminado correctamente.');
            dispatch(fetchEspacios());
            return {data: {id}, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al eliminar el espacio: ' + e.response.data);
        return e.message;
    }
})
export const updateEspacio = createAsyncThunk("espacios/updateEspacio", async (upEspacio, {dispatch})=> {
    console.log('espacio', upEspacio);
    try {
        const response = await axiosInstance.put(UPDATE_ESPACIO_URL+upEspacio.id, {
            pabellonId: upEspacio.pabellonId,
            wingId: upEspacio.wingId,
            nombre: upEspacio.nombre,
            piso: upEspacio.piso,
            descripcion: upEspacio.descripcion,
        });
        
        if (response.status === 200) {
            toast.success('Espacio común actualizado correctamente.');
            dispatch(fetchEspacios());
            return {data: response.data, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar el espacio: ' + e.response.data);
        return e.message;
    }
})


// idle : no hay operaciones en curso
const initialState = {
    espacios: [],
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const espacioSlice = createSlice({
    name:"espacio",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchEspacios.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchEspacios.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.espacios) !== JSON.stringify(action.payload)) { //fetch
                state.espacios = action.payload;
            }
        })
        .addCase(fetchEspacios.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(addNewEspacio.fulfilled, (state, action) => { //ADD
            if(action.payload.status === 'success'){
                state.status = 'succeeded'
                state.espacios.push(action.payload.data);
            } else{
                state.status = 'failed'
            }
        })
        .addCase(deleteEspacio.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(deleteEspacio.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.espacios = state.espacios.filter(espacio => espacio.id !== action.payload.data.id);
            }else{
                state.status = 'failed'    
            }
        })
        .addCase(deleteEspacio.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(updateEspacio.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(updateEspacio.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.espacios = state.espacios.map(espacio => {
                    if (espacio.id === action.payload.data.id) {
                        return action.payload;
                    }
                    return espacio;
                });
            }else{
                state.status = 'failed'    
            }
        })
        .addCase(updateEspacio.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    }
})

export const getAllEspacios = (state) => state.espacios.espacios;
export const getEspaciosStatus = (state) => state.espacios.status;
export const getEspaciosError = (state) => state.espacios.error;

export const {} = espacioSlice.actions;
export default espacioSlice.reducer;