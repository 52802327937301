import styles from "./components.module.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getAllCampamentos } from "../../../../redux/features/campamentos/campamentoSlice";
import { useEffect } from "react";
import { updateEstandar } from "../../../../redux/features/estandares/estandarSlice";

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3 }) {
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [formData, setFormData] = useState(null);
  const dispatch = useDispatch();
  const campamentos = useSelector(getAllCampamentos);

  useEffect(() => {
    setFormData(seleItem);
  }, [seleItem]);

  const handleChangeCampamento = (e, value) =>
    setFormData({ ...formData, campamentoId: value ? value.id : null });

  // const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });
  const onChange = (e) => {
    const value = e.target.value;
    const invalidCharacters = /[<>\/]/;

    if (!invalidCharacters.test(value)) {
      setFormData({ ...formData, [e.target.name]: value });
    } else {
      toast.error("Carácter no válido");
    }
  };

  const canSave =
    [formData?.nombre, formData?.descripcion, formData?.campamentoId].every(
      Boolean
    ) && addRequestStatus === "idle";

  const handleEdit = async () => {
    try {
      setOpen3(true);
      setAddRequestStatus("loading");
      let resp = await dispatch(updateEstandar(formData));
      if (resp.payload.data.success) {
        setOpenEdit(false);
        setFormData(null);
        setOpen3(false);
        return;
      } else {
        setOpen3(false);
      }
    } catch (error) {
      // toast.error('Error al actualizar el estandar de habitación.');
      console.error(
        "Error al actualizar el estandar de habitación.",
        error.message
      );
    } finally {
      setAddRequestStatus("idle");
    }
  };

  return (
    <Drawer
      anchor="right"
      open={openEdit}
      onClose={() => setOpenEdit(false)}
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: "75%", md: "50%" },
          backgroundColor: "#efefef",
          color: "#2d3037",
          borderRadius: "0",
        },
      }}
    >
      {seleItem && (
        <>
          <DialogTitle>
            <Grid container={true} spacing={1} p={2}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{ display: "flex", gap: "5px" }}
              >
                <ErrorIcon
                  style={{ fontSize: "3rem" }}
                  className={styles.iconBg}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h6" className={styles.textIconEdit}>
                    Actualizar estandar {seleItem?.nombre}
                  </Typography>
                  <Typography variant="span" className={styles.textIconSpan}>
                    Modifica los campos para actualizar el estandar.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container={true} spacing={1} p={2}>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Campamento</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  disablePortal
                  disableClearable
                  id="campamentos-autocomplete"
                  options={campamentos ? campamentos : []}
                  name="idCampamento"
                  getOptionLabel={(option) => option.nombre}
                  value={
                    campamentos.find(
                      (option) => option.id === formData?.campamentoId
                    ) || null
                  }
                  onChange={(e, value) => handleChangeCampamento(e, value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecciona un campamento" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Nombre</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="nombre"
                  type="text"
                  value={formData?.nombre}
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <label className={styles.label}>Descripción</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2d3037",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2d3037",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="descripcion"
                  type="text"
                  multiline
                  value={formData?.descripcion}
                  rows={4}
                  maxRows={4}
                  onChange={(e) => onChange(e)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container={true}>
              <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                <Button
                  onClick={handleEdit}
                  className={styles.bgColorEdit}
                  variant="contained"
                  disabled={!canSave}
                >
                  {addRequestStatus == "loading" ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Actualizar"
                  )}
                </Button>
                <Button
                  onClick={() => setOpenEdit(false)}
                  className={styles.bgColorOutlined}
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      )}
    </Drawer>
  );
}
