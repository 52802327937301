import styles from "./components/components.module.css";
import { Container, Box, Card, CardContent } from "@mui/material";
import { useAppContext } from "../../context/AppContext";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import ResetForm from "./components/RecuperarPass";

const ResetPass = () => {
  return (
    <div className="w-full d-flex align-items-center justify-content-center h-full">
      <Box className="h-100 login_box d-flex align-items-center justify-content-center">
        <Container maxWidth="xs" style={{ display: "flex" }}>
          <Card
            style={{
              boxShadow: "0px 10px 20px 2px rgba(0, 0, 0, 0.25)",
              borderRadius: "6px",
              paddingTop: "20px",
            }}
            className={styles.cardBgBlur}
          >
            <CardContent className="text-start" style={{ paddingBottom: "0" }}>
              <img
                src={process.env.PUBLIC_URL + "/psinet.jpg"}
                alt="loginImg"
              />
            </CardContent>
            <CardContent>
              <ResetForm />
            </CardContent>
          </Card>
        </Container>
      </Box>
    </div>
  );
};

export default ResetPass;
