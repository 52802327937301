import styles from "./components.module.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  DialogActions,
  Autocomplete,
  Select,
  MenuItem,
  Stack, 
  Alert
} from "@mui/material";
import PropTypes from 'prop-types';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import { historialReasignacionColumns, reasignacionColumns, reasignacionHabitacionesColumns, reasignacionTrabajadoresColumns } from "../../../lib/columns";
import { addReasignar, fetchTrabajadoresAll, fetchTrabajadoresReasignacion, fetchTrabajadoresReasignacionP_INTERNO } from "../../../redux/features/reservas/reservaSlice";
import CircleIcon from "@mui/icons-material/Circle";
import { toast } from "react-toastify";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { fetchHabitacionesDisponibles, fetchHabitacionesDisponiblesP_INTERNO } from "../../../redux/features/habitaciones/habitacionSlice";

const data = {
  pabellon: '',
  wing:'',
  asignaciones: []
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ReasignarP_INTERNO = ({
  open2,
  setOpen2,
  traStatus,
  // trabajadores,
  wings,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openBack, setOpenBack] = useState(false);
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [addRequestStatusRevisar, setAddRequestStatusRevisar] = useState("idle");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedTrabajadores, setSelectedTrabajadores] = useState([]);
  const [filterTrabajadores, setFilterTrabajadores] = useState([]);
  const [filterTrabajadoresTabla, setFilterTrabajadoresTabla] = useState([]);
  const [ruts, setRuts] = useState([]);
  
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = reasignacionColumns();
  const finalColumnsTrabajadores = reasignacionTrabajadoresColumns();
  const finalColumnsHabitaciones = reasignacionHabitacionesColumns();
  const historialColumns = historialReasignacionColumns();

  const [selectedPabellon, setSelectedPabellon] = useState();
  const [selectedWing, setSelectedWing] = useState();
  const [pabellonWing, setPabellonWing] = useState(0);
  const [selectedSolicitud, setSelectedSolicitud] = useState(null);
  const [selectedItems2, setSelectedItems2] = useState([]);
  const [closeInfo, setCloseInfo] = useState(true);
  const [closeInfo2, setCloseInfo2] = useState(true);
  const [closeInfo3, setCloseInfo3] = useState(true);

  const [infoReasignacion, setinfoReasignacion] = useState(null);
  const [value, setValue] = useState(0);
  
  const [selectedData, setSelectedData] = useState(0);
  const [almacenados, setAlmacenados] = useState([]);
  const [filterHabitaciones, setFilterHabitaciones] = useState([]);
  const [habitaciones, setHabitaciones] = useState([]);
  const [historial, setHistorial] = useState([]);
  const [trabajadoresOriginales, setrabajadoresOriginales] = useState([]);
  const [asignados, setAsignados] = useState([]);

  const [trabajadoresFilterModel, setTrabajadoresFilterModel] = useState({
    items: []
  });
  const [habitacionesFilterModel, setHabitacionesFilterModel] = useState({
    items: []
  });
  const [count, setCount] = useState(0);

  const handleTrabajadoresFilterModelChange = (newFilterModel) => {
    setTrabajadoresFilterModel(sanitizeFilterModel(newFilterModel));
  };

  const handleHabitacionesFilterModelChange = (newFilterModel) => {
    setHabitacionesFilterModel(sanitizeFilterModel(newFilterModel));
  };

  const sanitizeFilterModel = (filterModel) => {
    if (!filterModel.items) {
      filterModel.items = [];
    }
    return filterModel;
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleRowSelectionModelChange = (selectionModel) => {
    console.log('selectionModel: ',selectionModel);
    const selectedRows = selectionModel.map((rut) => {
      return filterTrabajadoresTabla.find((row) => row.trabajador.rut === rut);
    });
    setSelectedItems2(selectedRows);
    console.log('selectedRows:',selectedRows)
  };

  const verificarGeneroHabitacion = (habitacionSeleccionada, selectedItems2) => {
    const generoHabitacion = habitacionSeleccionada.tipoHuesped; // "Solo hombres", "Solo mujeres" o "Unisex"
    
    const esValidoElGenero = selectedItems2.every(item => {
      console.log('item.trabajador.genero: ', item.trabajador.genero, 'generoHabitacion: ', generoHabitacion);
      if ((item.trabajador.genero === 'Hombre' && (generoHabitacion === 'Solo hombres' || generoHabitacion === 'Unisex')) || 
          (item.trabajador.genero === 'Mujer' && (generoHabitacion === 'Solo mujeres' || generoHabitacion === 'Unisex'))) {
        return true;
      } else if (!item.trabajador.genero || item.trabajador.genero === '') {
        toast.warning('El trabajador ' + (item.trabajador.nombre ?? item.trabajador.nombres) + ' no tiene registrado su género');
        return true;
      }
      return false;
    });
  
    if (!esValidoElGenero) {
      toast.error('Género no aceptado en la habitación.');
      return false;
    }
  
    return true;
  };

  const jornadasIguales = (items) => {
    const jornadaCount = {};
    
    if (items.length === 1) return 1; 
  
    items.forEach(item => {
      const jornada = item.jornada;
      if (jornadaCount[jornada]) {
        jornadaCount[jornada]++;
      } else {
        jornadaCount[jornada] = 1;
      }
    });
  
    const uniqueCounts = Object.values(jornadaCount);
    
    const allEqual = uniqueCounts.length === 1; // Todos los registros tienen la misma jornada si solo hay un tipo de jornada.
    const allDistinct = uniqueCounts.every(count => count === 1); // Todos los registros tienen jornadas distintas si cada jornada aparece solo una vez.
    
    if (allEqual) {
      return 0; // 'Todas las jornadas son iguales';
    } else if (allDistinct) {
      return 1; // 'Todas las jornadas son distintas';
    } else {
      return 2; // 'Hay algunas jornadas repetidas pero no todas son iguales';
    }
  };

  const cargosIgualesDistintos = (items) => {
    const cargoCount = {};
    
    if (items.length === 1) return 0; // Si hay solo un registro, se considera que todos los cargos son iguales.
  
    items.forEach(item => {
      const cargo = item.tipoCargo;
      if (cargoCount[cargo]) {
        cargoCount[cargo]++;
      } else {
        cargoCount[cargo] = 1;
      }
    });
  
    const uniqueCounts = Object.values(cargoCount);
    
    const allEqual = uniqueCounts.length === 1; // Todos los registros tienen distinto cargo
    const allDistinct = uniqueCounts.every(count => count === 1); // Todos los registros tienen tipos de cargo distintos si cada tipo de cargo aparece solo una vez.
    
    if (allEqual) {
      return 0; // 'Todos los cargos son iguales';
    } else if (allDistinct) {
      return 1; // 'Todos los cargos son distintos';
    } else {
      return 2; // 'Hay algunos cargos repetidos pero no todos son iguales';
    }
  };

  const empresasIgualesDistintos = (items) => {
    const empresaCount = {};
    
    if (items.length === 1) return 0; // Si hay solo un registro, se considera que las empresas son iguales
  
    items.forEach(item => {
      const empresa = item.empresa.idEmpresa;
      if (empresaCount[empresa]) {
        empresaCount[empresa]++;
      } else {
        empresaCount[empresa] = 1;
      }
    });
  
    const uniqueCounts = Object.values(empresaCount);
    
    const allEqual = uniqueCounts.length === 1; // Todos los registros tienen el mismo tipo de cargo si solo hay un tipo de cargo.
    const allDistinct = uniqueCounts.every(count => count === 1); // Todos los registros tienen tipos de cargo distintos si cada tipo de cargo aparece solo una vez.
    
    if (allEqual) {
      return 0; // 'Todos los empresas son iguales';
    } else if (allDistinct) {
      return 1; // 'Todos los empresas son distintos';
    } else {
      return 2; // 'Hay algunos empresas repetidos pero no todos son iguales';
    }
  };

  const cargosReservadosHabitacion = (items, habitacion) => {
    let verificar = false;
    console.log('items', items);
    console.log('habitacion: ', habitacion);
    // validacion de cargosReservados de la empresa concuerdan con los de selectedItems2 o si la habitación no tiene
    items.map(item => {
        if(!habitacion.cargosReservados || habitacion.cargosReservados.length === 0 || !habitacion.cargosReservados.includes(item.tipoCargo)){
          if(!habitacion.cargosReservados || habitacion.cargosReservados.length === 0){
            console.log('item-->', item);
            toast.warning(`La habitación N°${habitacion.numero} no presenta cargos reservados.`);
            verificar = false  
          }
          verificar = true
        } else{
          verificar = false
        }
      }
    )
    return verificar;
  }

  const seleccionTablaHabitaciones = (selection) => {
    const selectedID = selection[0];
    console.log('selectedID: ',selectedID);
    const habitacionSeleccionada = filterHabitaciones.find(row => row.id === selectedID); //habitación seleccionada
    console.log('habitacionSeleccionada: ',habitacionSeleccionada);

    if(!habitacionSeleccionada){
      toast.error('No se ha encontrado la habitación');
      return false;
    }

    if(habitacionSeleccionada?.asignados && Array.isArray(habitacionSeleccionada?.asignados) && habitacionSeleccionada?.asignados.length > 0){ 
          //si la habitación tiene asignados
          const asignadosAhora = [...habitacionSeleccionada.asignados, ...selectedItems2]

          if(selectedItems2.length > 0){

            if (!verificarGeneroHabitacion(habitacionSeleccionada, asignadosAhora)) return false
            
            const resultado = jornadasIguales(asignadosAhora);
            if (resultado === 0) {
              toast.error('Jornadas iguales.');
              return;
            } else if (resultado === 2) {
              toast.warning('Hay jornadas repetidas.');
            }

            const isValidCargosEnHabitacion = cargosReservadosHabitacion(asignadosAhora, habitacionSeleccionada);
            
            if(!isValidCargosEnHabitacion){
              // toast.error('Cargos no permitidos en la habitación');
              return;
            }

            const cargosIgualDistinto = cargosIgualesDistintos(asignadosAhora);
            if (cargosIgualDistinto === 1 || cargosIgualDistinto === 2) {
              toast.warning('Hay cargos distintos.');
              return;
            }

            const empresasIgualDistinto = empresasIgualesDistintos(asignadosAhora);
            if (empresasIgualDistinto === 1 || empresasIgualDistinto === 2) {
              toast.warning('Hay empresas distintas.');
              return;
            }

            const hab = { 
              ...habitacionSeleccionada,
              asignados: selectedItems2.length > 0 ? asignadosAhora : null
            };
            
            console.log('filterHabitaciones__: ', filterHabitaciones);
            setFilterHabitaciones(prev => prev.map(item => 
              item.id === selectedID ? { ...item, ...hab } : item
            ));

             // 1. Agrega a almacenados los registros de selectedItems2
             const nuevosAlmacenados = [...almacenados, ...selectedItems2];
             console.log('nuevosAlmacenados: ', nuevosAlmacenados);
             // 2. Quita del arreglo de filterTrabajadoresTabla los registros de selectedItems2
             const selectedRuts = selectedItems2.map(item => item.trabajador.rut);
             console.log('filterTrabajadoresTabla: ', filterTrabajadoresTabla);
             const nuevosFilterTrabajadoresTabla = filterTrabajadoresTabla.filter(item => !selectedRuts.includes(item.trabajador.rut));
             
             // 3. Filtra almacenados para eliminar los asignados actuales y agregar los seleccionados
             const rutsAsignadosAhora = asignadosAhora.map(item => item.trabajador.rut);
             const nuevosAlmacenadosFiltrados = nuevosAlmacenados.filter(item => !rutsAsignadosAhora.includes(item.trabajador.rut));
             console.log('nuevosAlmacenadosFiltrados: ', nuevosAlmacenadosFiltrados);
             
             console.log('almacenados: ', almacenados);
             console.log('rutsAsignadosAhora: ', rutsAsignadosAhora);
             console.log('nuevosFilterTrabajadoresTabla: ', nuevosFilterTrabajadoresTabla);
 
             // setAlmacenados(nuevosAlmacenadosFiltrados);
             setAlmacenados(nuevosAlmacenados);
             setFilterTrabajadoresTabla(nuevosFilterTrabajadoresTabla);
             setSelectedItems2([]);

            return;
          } else{
            // remover trabajadores de la solicitud

            const hab = { 
              ...habitacionSeleccionada,
              asignados: null
            };

            console.log('filterHabitaciones__: ', filterHabitaciones);
            setFilterHabitaciones(prev => prev.map(item =>  // actualizar la habitacion seleccionada
              item.id === selectedID ? { ...item, ...hab } : item
            ));

            const ruts = habitacionSeleccionada.asignados.map(item => item.trabajador.rut);
            console.log('ruts: ', ruts);
            const mapped = almacenados.filter(item => !ruts.includes(item.trabajador.rut));
            console.log('mapped: ', mapped);
            const outMapped = almacenados.filter(item => ruts.includes(item.trabajador.rut));

            setAlmacenados(mapped); // remover de almacenados las asignaciones actuales
            setFilterTrabajadoresTabla(prev => ([...prev, ...outMapped])); //incorporar esos trabajadores a la tabla de trabajadores

            return;
          }
    }

    if (selectedItems2.length <= 0) {
      toast.error('Selecciona un trabajador');
      console.log('No items in selectedItems2 to assign');
      return;
    }
        
    const numeroOcupantes = habitacionSeleccionada.asignados ?? 0;
    const totalOcupantes = habitacionSeleccionada.trabajadores ? (numeroOcupantes + habitacionSeleccionada.trabajadores.length) : 0;
    const camasDisponibles =  habitacionSeleccionada.nroCamas - (totalOcupantes + selectedItems2.length);

    if (!verificarGeneroHabitacion(habitacionSeleccionada, selectedItems2)) return false
      const resultado = jornadasIguales(selectedItems2);
    if(resultado === 0 ){
        toast.error('Jornadas iguales.');
        return;
    }else if(resultado === 2){
        toast.warning('Hay jornadas repetidas.');
    }

    const isValidCargosEnHabitacion = cargosReservadosHabitacion(selectedItems2, habitacionSeleccionada);
    if(!isValidCargosEnHabitacion){
      toast.error('Cargos no permitidos en la habitación');
      return;
    }

    const cargosIgualDistinto = cargosIgualesDistintos(selectedItems2);
    if (cargosIgualDistinto === 1 || cargosIgualDistinto === 2) {
      toast.warning('Hay cargos distintos.');
      return;
    }

    const empresasIgualDistinto = empresasIgualesDistintos(selectedItems2);
    if (empresasIgualDistinto === 1 || empresasIgualDistinto === 2) {
      toast.warning('Hay empresas distintas.');
      return;
    }

    if(camasDisponibles >= 0){
      // toast.info(`La habitación ahora cuenta con ${camasDisponibles} camas disponibles`);
    } else{
      toast.error(`La habitación no tiene camas disponibles para la cantidad de trabajadores seleccionada.`)
      return;
    }
        
    const hab = {
      ...habitacionSeleccionada,
      asignados: selectedItems2  // trabajadores seleccionados
    };
    // console.log('selectedItem2', selectedItems2);

    setAlmacenados(prev => {
      const almacenadosMap = new Map();
      // Añadir los elementos del array `prev` al Map
      prev.forEach(item => almacenadosMap.set(item.trabajador.rut, item));
      // Añadir los elementos del array `selectedItems2` al Map
      selectedItems2.forEach(item => almacenadosMap.set(item.trabajador.rut, item));
      // Convertir el Map de nuevo a un array
      return Array.from(almacenadosMap.values());
    });
        
      // Filtrar los trabajadores para eliminar aquellos cuyo rut esté en la lista ruts
    const selectedRuts = selectedItems2.map(item => item.trabajador.rut);

      // Filtrar `filterTrabajadoresTabla` para eliminar los trabajadores cuyo `rut` está en `selectedRuts`
    const removeTrabajadores = filterTrabajadoresTabla.filter(item => !selectedRuts.includes(item.trabajador.rut));

    console.log('filterTrabajadoresTabla', filterTrabajadoresTabla);
    console.log('selectedRuts', selectedRuts);
    console.log('removeTrabajadores', removeTrabajadores);
    setFilterTrabajadoresTabla(removeTrabajadores);

    console.log('hab: ', hab);
    const findIndex = filterHabitaciones.findIndex(item => item.id === hab.id);
    if (findIndex !== -1) {
      setFilterHabitaciones(prev => 
        prev.map((item, index) => 
          index === findIndex ? { ...item, ...hab } : item
        )
      );
    }
        
    console.log('filterHabitaciones2: ', filterHabitaciones);
    toast.success(`Trabajadores asignados: ${selectedItems2.length}`);
    setSelectedItems2([])
    return;
  };

  const autoAsignar = () => {
    console.log('filterTrabajadores: ', filterTrabajadoresTabla);
    console.log('filterHabitaciones: ', filterHabitaciones);
    if(filterTrabajadoresTabla.length == 0){
      toast.error('Todos los trabajadores han sido asignados.');
      return;
    }

    
    const mujeres = filterTrabajadoresTabla.filter(item => item.trabajador.genero === 'Mujer');
    const hombres = filterTrabajadoresTabla.filter(item => item.trabajador.genero === 'Hombre' || !item.trabajador.genero || item.trabajador.genero == '');
    
    let habitacionesHombres = [];
    let habitacionesMujeres = [];

    if(habitacionesFilterModel.items[0] && (habitacionesFilterModel.items[0].field == 'pabellon' || habitacionesFilterModel.items[0].field == 'wing') && habitacionesFilterModel.items[0].value && habitacionesFilterModel.items[0].value.length > 0){
      console.log('habitacionesFilterModel.items[0].value: ', habitacionesFilterModel.items[0].value);
      console.log('habitacionesFilterModel.items[0].field: ', habitacionesFilterModel.items[0].field);
      if(habitacionesFilterModel.items[0].field == 'wing'){
        habitacionesHombres = filterHabitaciones.filter(item => (item.tipoHuesped === 'Solo hombres' || item.tipoHuesped === 'Unisex') && item.wing && item.wing.nombre.toLowerCase().includes(habitacionesFilterModel.items[0].value));
        habitacionesMujeres = filterHabitaciones.filter(item => item.tipoHuesped === 'Solo mujeres' && item.wing && item.wing.nombre.toLowerCase().includes(habitacionesFilterModel.items[0].value));
      }
    }else{
      console.log('entra else');
      habitacionesHombres = filterHabitaciones.filter(item => item.tipoHuesped === 'Solo hombres' || item.tipoHuesped === 'Unisex');
      habitacionesMujeres = filterHabitaciones.filter(item => item.tipoHuesped === 'Solo mujeres');
    }

    console.log('mujeres', mujeres);
    console.log('hombres', hombres);

    const asignarTrabajadores = (trabajadores, habitaciones) => {
      const habitacionesActualizadas = [...habitaciones];
      const trabajadoresNoAsignados = [];
    
      trabajadores.forEach(trabajador => {
        let trabajadorAsignado = false;
    
        for (let i = 0; i < habitacionesActualizadas.length; i++) {
          const habitacion = habitacionesActualizadas[i];
    
          // Verifica si el trabajador cumple con los criterios de la habitación
          if (habitacion.cargosReservados && habitacion.cargosReservados.length > 0) {
            if (!habitacion.cargosReservados.includes(trabajador.tipoCargo)) {
              continue;
            }
          }
    
          if (habitacion.asignados && (habitacion.nroCamas - habitacion.asignados.length) > 0) {
            let esDiferente = false;
            let jornadasDiferentes = false;
            let empresasDiferentes = false;
            let cargosDiferentes = false;

            habitacion.asignados.forEach(item => {
              if (item.tipoCargo !== trabajador.tipoCargo) {
                esDiferente = true;
              }
            });
    
            if (jornadasIguales([...habitacion.asignados, trabajador]) !== 1) {
              jornadasDiferentes = true;
            }
            
            if (empresasIgualesDistintos([...habitacion.asignados, trabajador]) !== 1) {
              empresasDiferentes = true;
            }

            if (cargosIgualesDistintos([...habitacion.asignados, trabajador]) !== 0) {
              cargosDiferentes = true;
            }

            if (esDiferente || jornadasDiferentes || empresasDiferentes || cargosDiferentes) {
              // Trabajadores con distintos cargos, jornadas o empresas, buscar otra habitación
              continue;
            }
    
            if (!trabajador.trabajador.genero) {
              toast.warning(`El trabajador ${trabajador.trabajador.nombre || trabajador.trabajador.nombres} no tiene registrado un género, ha sido asignado a la habitación N°${habitacion.numero}`);
            }
    
            // Asigna el trabajador a la habitación
            habitacionesActualizadas[i] = {
              ...habitacion,
              asignados: [...habitacion.asignados, trabajador]
            };
            trabajadorAsignado = true;
            break;
          } else if (!habitacion.asignados) {
            if (!trabajador.trabajador.genero) {
              toast.warning(`El trabajador ${trabajador.trabajador.nombre || trabajador.trabajador.nombres} no tiene registrado un género, ha sido asignado a la habitación N°${habitacion.numero}`);
            }
    
            // Asigna el trabajador a la habitación
            habitacionesActualizadas[i] = {
              ...habitacion,
              asignados: [trabajador]
            };
            trabajadorAsignado = true;
            break;
          }
        }
    
        if (!trabajadorAsignado) {
          trabajadoresNoAsignados.push(trabajador);
          toast.error(`No se encontró una habitación adecuada para el trabajador ${trabajador.trabajador.nombre || trabajador.trabajador.nombres}`);
        }
      });
    
      // Vuelve a intentar asignar trabajadores que no cumplieron los criterios en el primer paso
      trabajadoresNoAsignados.forEach(trabajador => {
        let trabajadorAsignado = false;
    
        for (let i = 0; i < habitacionesActualizadas.length; i++) {
          const habitacion = habitacionesActualizadas[i];
    
          // Verifica si el trabajador cumple con los criterios de la habitación
          if (habitacion.cargosReservados && habitacion.cargosReservados.length > 0) {
            if (!habitacion.cargosReservados.includes(trabajador.tipoCargo)) {
              continue;
            }
          }
    
          if (habitacion.asignados && (habitacion.nroCamas - habitacion.asignados.length) > 0) {
            let esDiferente = false;
            let jornadasDiferentes = false;
            let empresasDiferentes = false;
            let cargosDiferentes = false;

            habitacion.asignados.forEach(item => {
              if (item.tipoCargo !== trabajador.tipoCargo) {
                esDiferente = true;
              }
            });
    
            if (jornadasIguales([...habitacion.asignados, trabajador]) !== 1) {
              jornadasDiferentes = true;
            }
  
            if (empresasIgualesDistintos([...habitacion.asignados, trabajador]) !== 1) {
              empresasDiferentes = true;
            }
            
            if (cargosIgualesDistintos([...habitacion.asignados, trabajador]) !== 0) {
              cargosDiferentes = true;
            }
  
            if (esDiferente || jornadasDiferentes || empresasDiferentes || cargosDiferentes) {
              // Trabajadores con distintos cargos, jornadas o empresas, buscar otra habitación
              continue;
            }
    
            if (!trabajador.trabajador.genero) {
              toast.warning(`El trabajador ${trabajador.trabajador.nombre || trabajador.trabajador.nombres} no tiene registrado un género, ha sido asignado a la habitación N°${habitacion.numero}`);
            }
    
            // Asigna el trabajador a la habitación
            habitacionesActualizadas[i] = {
              ...habitacion,
              asignados: [...habitacion.asignados, trabajador]
            };
            trabajadorAsignado = true;
            break;
          } else if (!habitacion.asignados) {
            if (!trabajador.trabajador.genero) {
              toast.warning(`El trabajador ${trabajador.trabajador.nombre || trabajador.trabajador.nombres} no tiene registrado un género, ha sido asignado a la habitación N°${habitacion.numero}`);
            }
    
            // Asigna el trabajador a la habitación
            habitacionesActualizadas[i] = {
              ...habitacion,
              asignados: [trabajador]
            };
            trabajadorAsignado = true;
            break;
          }
        }
    
        if (!trabajadorAsignado) {
          toast.error(`No se encontró una habitación adecuada para el trabajador ${trabajador.trabajador.nombre || trabajador.trabajador.nombres}`);
        }
      });
    
      // Filtra y devuelve solo las habitaciones con asignaciones
      return habitacionesActualizadas.filter(habitacion => habitacion.asignados && habitacion.asignados.length > 0);
    };

    const mappedHombres = asignarTrabajadores(hombres, habitacionesHombres);
    console.log('Habitaciones con asignaciones para hombres:', mappedHombres);

    const mappedMujeres = asignarTrabajadores(mujeres, habitacionesMujeres);
    console.log('Habitaciones con asignaciones para mujeres:', mappedMujeres);

    //Agrego a almacenados
    setAlmacenados(prev => {
      const almacenadosMap = new Map();
    
      // Asegurarse de que prev no esté vacío y contenga la estructura esperada
      if (prev && prev.length > 0) {
        prev.forEach(item => {
          if (item.trabajador && item.trabajador.rut) {
            almacenadosMap.set(item.trabajador.rut, item);
          }
        });
      }
    
      // Asegurarse de que mappedHombres y mappedMujeres contengan la estructura esperada
      if (mappedHombres && mappedHombres.length > 0) {
        mappedHombres.forEach(habitacion => {
          if (habitacion.asignados && habitacion.asignados.length > 0) {
            habitacion.asignados.forEach(trabajador => {
              if (trabajador.trabajador && trabajador.trabajador.rut) {
                almacenadosMap.set(trabajador.trabajador.rut, trabajador);
              }
            });
          }
        });
      }
    
      if (mappedMujeres && mappedMujeres.length > 0) {
        mappedMujeres.forEach(habitacion => {
          if (habitacion.asignados && habitacion.asignados.length > 0) {
            habitacion.asignados.forEach(trabajador => {
              if (trabajador.trabajador && trabajador.trabajador.rut) {
                almacenadosMap.set(trabajador.trabajador.rut, trabajador);
              }
            });
          }
        });
      }
    
      return Array.from(almacenadosMap.values());
    });

    //remuevo de la tabla de trabajadores
    const selectedRutsMujeres = mappedMujeres.flatMap(habitacion => 
      habitacion.asignados.map(trabajador => trabajador.trabajador.rut)
    );
    
    const selectedRutsHombres = mappedHombres.flatMap(habitacion => 
      habitacion.asignados.map(trabajador => trabajador.trabajador.rut)
    );
    

    console.log('selectedRutsMujeres: ', selectedRutsMujeres);
    console.log('selectedRutsHombres: ', selectedRutsHombres);

    const removeTrabajadores = filterTrabajadoresTabla.filter(item => !selectedRutsMujeres.includes(item.trabajador.rut) && !selectedRutsHombres.includes(item.trabajador.rut));

    console.log('removeTrabajadores: ', removeTrabajadores);

    setFilterTrabajadoresTabla(removeTrabajadores);

    const actualizarHabitaciones = (filterHabitaciones, mappedHombres, mappedMujeres) => {
      const habitacionesActualizadas = new Map();
    
      mappedHombres.forEach(habitacion => {
        habitacionesActualizadas.set(habitacion.id, habitacion);
      });
    
      mappedMujeres.forEach(habitacion => {
        habitacionesActualizadas.set(habitacion.id, habitacion);
      });
    
      return filterHabitaciones.map(habitacion => 
        habitacionesActualizadas.get(habitacion.id) || habitacion
      );
    };
    
    const filterHabitacionesActualizadas = actualizarHabitaciones(filterHabitaciones, mappedHombres, mappedMujeres);
    
    console.log('Habitaciones actualizadas:', filterHabitacionesActualizadas);
    
    // Actualizar el estado de filterHabitaciones
    setFilterHabitaciones(filterHabitacionesActualizadas);

  }

  useEffect(() => {
    console.log('almacenados: ', almacenados)
  }, [almacenados])

  useEffect(() => {
    console.log('trabajadores seleccionados: ', selectedItems2)
  }, [selectedItems2])

  useEffect(() => {
    console.log('almacenados: ', almacenados)
  }, [almacenados])

  useEffect(() => {
    console.log('setFilterTrabajadoresTabla: ', setFilterTrabajadoresTabla)
  }, [setFilterTrabajadoresTabla])
  
  useEffect(() => {
    console.log('formData: ', formData)
  }, [formData])
  
  useEffect(() => {
    console.log('filterHabitaciones: ', filterHabitaciones)
    if(Array.isArray(filterHabitaciones) && filterHabitaciones.length > 0){
      const mapped = filterHabitaciones.filter(item => item.asignados && Array.isArray(item.asignados) && item.asignados.length > 0)
      let asignaciones = [];
      mapped.forEach(item => {
          item.asignados.forEach(it => {
              const asignacion = {
                  idSolicitud: it.id,
                  idHabitacion: item.id,
                  rut: it.trabajador.rut
              };
              asignaciones.push(asignacion);
          });
      });

      setFormData(prev => ({...prev, asignaciones: asignaciones}))
      console.log('mapped: ', mapped);
      console.log('asignaciones: ', asignaciones);
      
      const asignadosMap = asignaciones.map(item => {
        const trabajador = trabajadoresOriginales.find(it => it.trabajador.rut === item.rut);
        const habitacion = filterHabitaciones.find(it => it.id === item.idHabitacion);

        return {
          ...trabajador,
          habitacion
        }
      })
      console.log('asignadosMap::', asignadosMap);
      setAsignados(asignadosMap)
    }


  }, [filterHabitaciones])

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const fetchAndSetTrabajadores = async () => {
    try {
      setOpen2(true);
      const { contrato, rut, nombre } = formData;
      const resp = await dispatch(
        fetchTrabajadoresAll({ contrato, rut, nombre })
      );
      console.log(resp.payload);
      if (!Array.isArray(resp.payload)) {
        toast.error("No se han encontrado trabajadores");
      }
      setFilterTrabajadores(resp.payload);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen2(false);
    }
  };
  const handleChangeRuts = (event, newValue) => {
    if (newValue != null) {
      setSelectedTrabajadores(newValue);
      setFormData({ ...formData, ruts: newValue.map((item) => item.rut) });
    } else {
      setFormData({ ...formData, ruts: [] });
    }
  };
  const canSave = [(formData?.pabellon || formData?.wing), formData?.piso, formData?.ingreso, formData?.salida].every(Boolean) && addRequestStatus === "idle";
  const handleChangePW = (event) => {
    setPabellonWing(event.target.value);
  };
  
  const handleChangeWing = (event, newValue) => {
    if (newValue != null) {
      setSelectedPabellon(null);
      setSelectedWing(newValue);
      setFormData({ ...formData, wing: newValue.id, pabellon: null });
    } else {
      setFormData({ ...formData, wing: null, pabellon: null });
    }
  };
  
  const habitacionesDisponibles = async (fechaIngreso, fechaSalida) => {
    try {
      // setCount(1);
      setOpenBack(true);
      const resp = await dispatch(fetchHabitacionesDisponiblesP_INTERNO({FechaIngreso: fechaIngreso, FechaSalida:fechaSalida}));
      console.log('habitacionesDisponibles', resp.payload);
      setFilterHabitaciones(resp.payload);
      setHabitaciones(resp.payload);
    } catch (error) {
      console.log('Error al traer las habitaciones disponibles.');
    } finally{
      setOpenBack(false);
    }
  }
  const trabajadoresList = async (pabellonId, wingId, piso, fechaIngreso) => {
    try {
      // setFilterTrabajadoresTabla([])
      // setrabajadoresOriginales([])
      setAlmacenados([])

      setOpenBack(true);
      const resp = await dispatch(fetchTrabajadoresReasignacionP_INTERNO({pabellonId, wingId, piso, fechaIngreso}));
      console.log('trabajadoresList:', resp.payload);
      
      // Filtra los elementos de resp.payload que no están en almacenados
      const nuevosTrabajadores = resp.payload.filter(payloadItem => 
        !almacenados.some(almacenadoItem => 
            almacenadoItem.trabajador.rut === payloadItem.trabajador.rut
        )
      );

      // Actualiza los estados con los trabajadores filtrados y originales
      setFilterTrabajadoresTabla(nuevosTrabajadores);
      setrabajadoresOriginales(nuevosTrabajadores);

      console.log('Trabajadores actualizados:', almacenados);

    } catch (error) {
      console.log('Error al traer los trabajadores de la reasignación.')
    } finally{
      setOpenBack(false);
    }
  }
  const buscar = () => {
    habitacionesDisponibles(formData?.ingreso, formData.salida)
    trabajadoresList(formData?.pabellon, formData?.wing, formData?.piso, formData?.ingreso)
  }
  const handleSubmit = async () => {
    if (canSave) {
      try {
        setOpenBack(true);
        setAddRequestStatus("loading");
        let resp = await dispatch(addReasignar(formData?.asignaciones));
        console.log('resp reasignar', resp.payload.data.data);
        setinfoReasignacion(resp.payload.data.data);
        
        if (resp.payload.status === "success") {
          const mapped = formData?.asignaciones.map(item => {
            const findHab = habitaciones.find(hab => hab.id === item.idHabitacion);
            const findTra = trabajadoresOriginales.find(trab => trab.trabajador.rut === item.rut);
            console.log('trabajadoresOriginales: ',trabajadoresOriginales);
            console.log('findTra: ',findTra);
            return {
              trabajador: findTra.trabajador,
              habitacion: findHab
            }
          })
          // setHistorial([...historial, ...mapped]);
          setHistorial([]);
          setOpen(false);
          // setOpenDetalle(false);
          setAsignados([])
          setFilterHabitaciones([])
          setFilterTrabajadores([])

          // buscar();
          a11yProps(2)
          setPabellonWing(0)
          setSelectedItems2([])
          setCloseInfo(true);
          setCloseInfo2(true);
          setinfoReasignacion(null);
          setSelectedData(0);
          setAlmacenados([])
        }
      } catch (error) {
        console.error("Error al crear la tarea", error);
      } finally {
        setOpenBack(false);
        setAddRequestStatus("idle");
      }
    }
  };

  return (
    <div>
      <Grid container={true}>
        <Grid item xs={12} md={6}>
          <Typography style={{ fontSize:'14px' }}>Reasignar habitaciones.</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contanied"
            size="large"
            className={styles.bgColor}
            onClick={() => setOpen(true)}
          >
            <CircleIcon className={styles.iconButton} />
            Reasignar
          </Button>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        maxWidth="lg"
        PaperProps={{
          sx: {
            backgroundColor:'#efefef',
            color:'#2d3037',
            borderRadius:'0'
          },
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBack}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display: "flex", gap: "5px" }}>
          <CalendarMonthIcon
            className={styles.iconBgCreate}
            style={{ fontSize: "3rem", marginTop: "0px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="span"
              style={{ fontSize: "24px", lineHeight: "1" }}
            >
              Reasignar trabajadores
            </Typography>
            <span className={styles.subTitle}>
              Selecciona el Wing y piso que quieres reasignar junto a las fechas de ingreso y salida de o las asignaciones.
            </span>
          </div>
        </DialogTitle>
        <DialogContent style={{ minHeight:'320px' }}>
          <Grid container={true} spacing={1}>
            <Grid item sm={12} sx={{ width: '100%' }}>
              <Stack sx={{ width: '100%' }} spacing={2}>
                {closeInfo2 && (
                  <Alert severity="info" onClose={() => setCloseInfo2(false)} sx={{ width: '100%' }}>
                    El siguiente formulario te mostrará un listado de trabajadores hospedados en el pabellón o wing, en el piso y fecha seleccionada.
                  </Alert>
                )}
              </Stack>
            </Grid>
              <Grid item xs={12} sm={3}>
                <label className={styles.label}>Wing</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color:'#2d3037',
                    },
                    "& .MuiInputLabel-root": {
                      color:'#2d3037',
                    },
                  }}
                  disablePortal
                  disableClearable
                  id="sectores-autocomplete"
                  name="wing"
                  value={selectedWing || null}
                  options={wings || []}
                  getOptionLabel={(option) => option.nombre}
                  onChange={handleChangeWing}
                  renderInput={(params) => (
                    <TextField {...params} label="Busca y selecciona un wing" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <label className={styles.label}>Piso</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color:'#2d3037',
                    },
                    "& .MuiInputLabel-root": {
                      color:'#2d3037',
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="piso"
                  type="number"
                  onChange={onChange}
                  value={formData?.piso || null}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <label className={styles.label}>Ingreso</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color:'#2d3037',
                    },
                    "& .MuiInputLabel-root": {
                      color:'#2d3037',
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="ingreso"
                  type="date"
                  onChange={onChange}
                  value={formData?.ingreso || null}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <label className={styles.label}>Salida</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color:'#2d3037',
                    },
                    "& .MuiInputLabel-root": {
                      color:'#2d3037',
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="salida"
                  type="date"
                  onChange={onChange}
                  value={formData?.salida || null}
                />
              </Grid>
              <Grid item xs={12} sm={1} style={{ display:'flex', justifyContent:'end', alignItems:'end' }}>
                <Button
                  onClick={buscar}
                  className={styles.bgColorOrange}
                  variant="outlined"
                  disabled={!canSave}
                >
                  {openBack ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Buscar"
                  )}
                </Button>
              </Grid>
          </Grid>
            <Box sx={{ width: '100%' }}>
              <Box>
              {((Array.isArray(filterHabitaciones) && filterHabitaciones.length > 0) || historial.length > 0) && 
                <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example" style={{ borderRight:'none' }}
                  sx={{ borderRight: 1, borderColor: 'divider',
                    "& .MuiTabs-indicator": {
                        backgroundColor: "#FFF",
                        height: 3,
                      },
                      "& .MuiTab-root.Mui-selected": {
                        color: '#2d3037'
                      },
                      "& .MuiTab-root:not(.Mui-selected)": {
                        color: 'gray',
                      },
                      "& .MuiTabs-root": {
                        borderRight: 'none',
                      }
                  }}>
                  <Tab label="Trabajadores" {...a11yProps(0)} />
                  <Tab label="Habitaciones" {...a11yProps(1)} />
                  <Tab label="Asignados" {...a11yProps(2)} />
                </Tabs>}
                <CustomTabPanel value={value} index={0} style={{ padding:'0', margin:'0' }}>
                  {Array.isArray(filterHabitaciones) && filterHabitaciones.length > 0 && <Grid item xs={12} sm={12}>
                    <Card>
                      <DataGrid
                          rowSelectionModel={selectedItems2.map(item => item.trabajador.rut)}
                          checkboxSelection
                          onRowSelectionModelChange={handleRowSelectionModelChange}
                          getRowId={row=> row.trabajador ? row.trabajador.rut ? row.trabajador.rut : Math.random() : Math.random() }
                          lots={{ toolbar: GridToolbar }}
                          rows={filterTrabajadoresTabla || []}
                          columns={finalColumnsTrabajadores}
                          pageSize={pageSize}
                          rowHeight={80}
                          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                          rowsPerPageOptions={[5, 10, 15, 20]}
                          localeText={{
                          ...esES.components.MuiDataGrid.defaultProps.localeText,
                          noRowsLabel: "No hay datos disponibles",
                          pagination: {
                              labelRowsPerPage: "Filas por página:",
                          }
                          }}
                          classes={{ menuIcon: styles.menuIcon }}
                          style={{ maxHeight:'500px', color:'#FFF', border:'none', margin:'0' }}
                          sx={{ 
                            '& .MuiDataGrid-cell': {
                              color: '#2d3037',
                              borderBottom: '1px solid #c6c6c6',
                            },
                            '& .MuiDataGrid-columnHeader': {
                              color: '#2d3037',
                              borderBottom: '1px solid #c6c6c6',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                              borderBottom: '1px solid #c6c6c6',
                            },
                            '& .MuiDataGrid-footerContainer': {
                              color: '#2d3037',
                              borderTop: '1px solid #c6c6c6',
                            },
                            '& .MuiTablePagination-root': {
                              color: '#2d3037',
                              borderTop: '1px solid #c6c6c6',
                            },
                            '& .MuiDataGrid-toolbarContainer': {
                              color: '#2d3037',
                            },
                            '& .MuiDataGrid-menu': {
                              color: '#2d3037',
                            },
                            '& .MuiSvgIcon-root':{
                              color: '#9ca91c',
                            },
                            '& .MuiTablePagination-actions': {
                              color: '#2d3037', // Color for pagination actions
                            },
                            '& .MuiTablePagination-select': {
                              color: '#2d3037', // Color for pagination select
                            },
                            '& .MuiTablePagination-selectLabel': {
                              color: '#2d3037', // Color for pagination select label
                            },
                            '& .MuiTablePagination-displayedRows': {
                              color: '#2d3037', // Color for displayed rows text
                            },
                            '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                              color: 'red',
                            },
                            '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                              color: 'red',
                            },
                          }}
                          slotProps={{
                                toolbar:{
                                  sx:{
                                    color:'#FFF'
                                  }
                                },
                                pagination: {
                                  sx: {
                                    color: '#f19d38',
                                  },
                                },
                                menu: {
                                  sx: {
                                    color: '#f19d38',
                                  },
                                },
                          }}
                      />
                    </Card>
                  </Grid>}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Grid item xs={12} sm={12}>
                  <Stack sx={{ width: '100%' }} spacing={2} mt={2}>
                      {closeInfo && (
                      <Alert severity="info" onClose={() => setCloseInfo(false)}>
                      El siguiente listado proporciona las habitaciones disponibles según sus camas disponibles. Para buscar una o un grupo de habitaciones según el campo de la tabla, debes utilizar los <strong>filtros de la tabla</strong>.
                      </Alert>
                      )}
                  </Stack>
                </Grid>
                {Array.isArray(filterHabitaciones) && filterHabitaciones.length > 0 &&  
                  <Grid item xs={12} sm={12}>
                    <Card>
                      <DataGrid
                          disableMultipleSelection
                          filterModel={habitacionesFilterModel}
                          onFilterModelChange={handleHabitacionesFilterModelChange}
                          onRowSelectionModelChange={seleccionTablaHabitaciones}
                          getRowId={row=>row.id ? row.id : Math.random() }
                          lots={{ toolbar: GridToolbar }}
                          rows={filterHabitaciones || []}
                          rowHeight={80}
                          columns={finalColumnsHabitaciones}
                          pageSize={pageSize}
                          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                          rowsPerPageOptions={[5, 10, 15, 20]}
                          localeText={{
                          ...esES.components.MuiDataGrid.defaultProps.localeText,
                          noRowsLabel: "No hay datos disponibles",
                          pagination: {
                              labelRowsPerPage: "Filas por página:",
                          }
                          }}
                          classes={{ menuIcon: styles.menuIcon }}
                          style={{ maxHeight:'500px', color:'#FFF', border:'none', margin:'0' }}
                          sx={{ 
                            '& .MuiDataGrid-cell': {
                              color: '#2d3037',
                              borderBottom: '1px solid #c6c6c6',
                            },
                            '& .MuiDataGrid-columnHeader': {
                              color: '#2d3037',
                              borderBottom: '1px solid #c6c6c6',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                              borderBottom: '1px solid #c6c6c6',
                            },
                            '& .MuiDataGrid-footerContainer': {
                              color: '#2d3037',
                              borderTop: '1px solid #c6c6c6',
                            },
                            '& .MuiTablePagination-root': {
                              color: '#2d3037',
                              borderTop: '1px solid #c6c6c6',
                            },
                            '& .MuiDataGrid-toolbarContainer': {
                              color: '#2d3037',
                            },
                            '& .MuiDataGrid-menu': {
                              color: '#2d3037',
                            },
                            '& .MuiSvgIcon-root':{
                              color: '#9ca91c',
                            },
                            '& .MuiTablePagination-actions': {
                              color: '#2d3037', // Color for pagination actions
                            },
                            '& .MuiTablePagination-select': {
                              color: '#2d3037', // Color for pagination select
                            },
                            '& .MuiTablePagination-selectLabel': {
                              color: '#2d3037', // Color for pagination select label
                            },
                            '& .MuiTablePagination-displayedRows': {
                              color: '#2d3037', // Color for displayed rows text
                            },
                            '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                              color: 'red',
                            },
                            '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                              color: 'red',
                            },
                          }}
                          slotProps={{
                                toolbar:{
                                  sx:{
                                    color:'#FFF'
                                  }
                                },
                                pagination: {
                                  sx: {
                                    color: '#f19d38',
                                  },
                                },
                                menu: {
                                  sx: {
                                    color: '#f19d38',
                                  },
                                },
                          }}
                      />
                    </Card>
                  </Grid>
                }
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Grid item xs={12} sm={12}>
                  <Card>
                    <DataGrid
                        getRowId={row=>row.trabajador ? row.trabajador.rut : Math.random() }
                        lots={{ toolbar: GridToolbar }}
                        rows={asignados || []}
                        columns={finalColumnsTrabajadores}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        rowHeight={80}
                        localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                        noRowsLabel: "No hay datos disponibles",
                        pagination: {
                            labelRowsPerPage: "Filas por página:",
                        }
                        }}
                        classes={{ menuIcon: styles.menuIcon }}
                        style={{ maxHeight:'500px', color:'#FFF', border:'none', margin:'0' }}
                        sx={{ 
                          '& .MuiDataGrid-cell': {
                            color: '#2d3037',
                            borderBottom: '1px solid #c6c6c6',
                          },
                          '& .MuiDataGrid-columnHeader': {
                            color: '#2d3037',
                            borderBottom: '1px solid #c6c6c6',
                          },
                          '& .MuiDataGrid-columnHeaders': {
                            borderBottom: '1px solid #c6c6c6',
                          },
                          '& .MuiDataGrid-footerContainer': {
                            color: '#2d3037',
                            borderTop: '1px solid #c6c6c6',
                          },
                          '& .MuiTablePagination-root': {
                            color: '#2d3037',
                            borderTop: '1px solid #c6c6c6',
                          },
                          '& .MuiDataGrid-toolbarContainer': {
                            color: '#2d3037',
                          },
                          '& .MuiDataGrid-menu': {
                            color: '#2d3037',
                          },
                          '& .MuiSvgIcon-root':{
                            color: '#9ca91c',
                          },
                          '& .MuiTablePagination-actions': {
                            color: '#2d3037', // Color for pagination actions
                          },
                          '& .MuiTablePagination-select': {
                            color: '#2d3037', // Color for pagination select
                          },
                          '& .MuiTablePagination-selectLabel': {
                            color: '#2d3037', // Color for pagination select label
                          },
                          '& .MuiTablePagination-displayedRows': {
                            color: '#2d3037', // Color for displayed rows text
                          },
                          '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                            color: 'red',
                          },
                          '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                            color: 'red',
                          },
                        }}
                        slotProps={{
                              toolbar:{
                                sx:{
                                  color:'#FFF'
                                }
                              },
                              pagination: {
                                sx: {
                                  color: '#f19d38',
                                },
                              },
                              menu: {
                                sx: {
                                  color: '#f19d38',
                                },
                              },
                        }}
                    />
                  </Card>
                </Grid>
              </CustomTabPanel>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container={true}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <div style={{ display:'flex', gap:'5px', flexWrap:'wrap' }}>
                <Button
                  onClick={autoAsignar}
                  className={styles.bgColorOutlinedOrange}
                  variant="outlined"
                >
                  {addRequestStatus == "loading" ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "AutoAsignar"
                  )}
                </Button>
                <Button
                  onClick={handleSubmit}
                  className={styles.bgColorModal}
                  variant="contained"
                  disabled={formData?.asignaciones.length <= 0}
                >
                  {addRequestStatus == "loading" ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Reasignar"
                  )}
                </Button>
              </div>
              <Button
                onClick={() => setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReasignarP_INTERNO;