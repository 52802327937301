import styles from './components.module.css';
import { useEffect, useState } from "react";
import { espaciosGroupColumns, habitacionGruposColumns, tableOficinasGroupColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import Delete from './Delete'
import Edit from './Edit'
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Table = ({ habitaciones, oficinas, espacios, setOpen3, setSelectedHabitaciones, setSelectedOficinas, setSelectedEspacios, selectedPabellon, selectedWing, selectedOtro, setSelectedWing, setSelectedPabellon, setSelectedOtro, formData, setFormData}) => {

  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = habitacionGruposColumns(setOpen, setSeleItem, setOpenEdit);
  const finalColumnsOficinas = tableOficinasGroupColumns(setOpen, setSeleItem, setOpenEdit);
  const finalColumnsEspacios = espaciosGroupColumns(setOpen, setSeleItem, setOpenEdit);
  const [value, setValue] = useState(0);
  const [filterHabitaciones, setFilterHabitaciones] = useState([]);
  const [selectedHabitacionIds, setSelectedHabitacionIds] = useState([]);
  
  const [filterOficinas, setFilterOficinas] = useState([]);
  const [selectedOficinaIds, setSelectedOficinaIds] = useState([]);

  const [filterEspacios, setFilterEspacios] = useState([]);
  const [selectedEspacioIds, setSelectedEspacioIds] = useState([]);
  
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleRowSelectionChange = (selectionModel) => {
    console.log('Selection Model', selectionModel);
    setSelectedHabitaciones(selectionModel);
    setSelectedHabitacionIds(selectionModel);
    setFormData((prevFormData) => ({
      ...prevFormData,
      habitaciones: selectionModel,
    }));
  };
  const handleRowSelectionChangeOficina = (selectionModel) => {
    console.log('Selection Model', selectionModel);
    setSelectedOficinas(selectionModel);
    setSelectedOficinaIds(selectionModel);
    setFormData((prevFormData) => ({
      ...prevFormData,
      oficinas: selectionModel,
    }));
  };
  const handleRowSelectionChangeEspacio = (selectionModel) => {
    console.log('Selection Model', selectionModel);
    setSelectedEspacios(selectionModel);
    setSelectedEspacioIds(selectionModel);
    setFormData((prevFormData) => ({
      ...prevFormData,
      espacios: selectionModel,
    }));
  };

  useEffect(() => {
    const filterBySelectedIds = (items, selectedItems, keyPath) => {
      if (selectedItems && selectedItems.length > 0) {
        const selectedIds = selectedItems.map(item => item.id);
        const keyParts = keyPath.split('.');
    
        const itemsFiltrados = items.filter(item => {
          let value = item;
          for (let part of keyParts) {
            value = value ? value[part] : undefined;
          }
          return selectedIds.includes(value);
        });
    
        return itemsFiltrados;
      }
      return [];
    };
    const handleFiltering = () => {
      let filteredHabitaciones = [];
      let filteredOficinas = [];
      let filteredEspacios = [];
  
      if (Array.isArray(habitaciones)) {
        filteredHabitaciones = [
          ...filterBySelectedIds(habitaciones, selectedPabellon, 'pabellonId'),
          ...filterBySelectedIds(habitaciones, selectedWing, 'wingId'),
          ...filterBySelectedIds(habitaciones, selectedOtro, 'recintoId')
        ];
  
        // if (filteredHabitaciones.length === 0) {
        //   toast.info('El pabellón no tiene habitaciones asociadas.');
        // }
      } else {
        console.warn('Habitaciones no está cargado o no es un arreglo');
      }
  
      setFilterHabitaciones(filteredHabitaciones);
  
      if (Array.isArray(oficinas)) {
        filteredOficinas = [
          ...filterBySelectedIds(oficinas, selectedPabellon, 'pabellon.id'),
          ...filterBySelectedIds(oficinas, selectedWing, 'wing.id'),
          ...filterBySelectedIds(oficinas, selectedOtro, 'recinto.id')
        ];
  
        // if (filteredOficinas.length === 0) {
        //   toast.info('El pabellón no tiene oficinas asociadas.');
        // }
      } else {
        console.warn('Oficinas no está cargado o no es un arreglo');
      }
  
      setFilterOficinas(filteredOficinas);

      if (Array.isArray(espacios)) {
        filteredEspacios = [
          ...filterBySelectedIds(espacios, selectedPabellon, 'pabellonId'),
          ...filterBySelectedIds(espacios, selectedWing, 'wingId'),
          ...filterBySelectedIds(espacios, selectedOtro, 'recintoId')
        ];
  
        // if (filteredHabitaciones.length === 0) {
        //   toast.info('El pabellón no tiene espacios comunes asociados.');
        // }
      } else {
        console.warn('Espacios comunes no están cargados o no es un arreglo.');
      }
  
      setFilterEspacios(filteredEspacios);
    };
  
    handleFiltering();
  }, [selectedPabellon, selectedWing, selectedOtro, habitaciones, oficinas, espacios]);
  

  return (
    <div style={{ marginTop:'0px' }}>
        <Delete open={open} setOpen={setOpen} seleItem={seleItem} setOpen3={setOpen3}/>
        <Edit openEdit={openEdit} setOpenEdit={setOpenEdit} seleItem={seleItem} setOpen3={setOpen3} selectedHabitacionIds={selectedHabitacionIds} setSelectedHabitacionIds={setSelectedHabitacionIds}/>
        <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example" style={{ borderRight:'none' }}
          sx={{ borderRight: 1, borderColor: 'divider',
                    "& .MuiTabs-indicator": {
                        backgroundColor: "#FFF",
                        height: 3,
                      },
                      "& .MuiTab-root.Mui-selected": {
                        color: '#2d3037'
                      },
                      "& .MuiTab-root:not(.Mui-selected)": {
                        color: 'gray',
                      },
                      "& .MuiTabs-root": {
                        borderRight: 'none',
                      }
                  }}>
          <Tab label="Habitaciones" {...a11yProps(0)} />
          <Tab label="Oficinas" {...a11yProps(1)} />
          <Tab label="Espacios Comunes" {...a11yProps(2)} />
        </Tabs>
        <CustomTabPanel value={value} index={0} style={{ padding:'0', margin:'0' }}>
          <DataGrid
              getRowId={row=>row.id ? row.id : Math.random() }
              checkboxSelection
              onRowSelectionModelChange={handleRowSelectionChange}
              rowSelectionModel={selectedHabitacionIds}
              lots={{ toolbar: GridToolbar }}
              rows={filterHabitaciones}
              columns={finalColumns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 15, 20]}
              // isRowSelectable={(params) => params.row.grupo == true}
              localeText={{
              ...esES.components.MuiDataGrid.defaultProps.localeText,
              noRowsLabel: "No hay datos disponibles",
              pagination: {
                  labelRowsPerPage: "Filas por página:",
              }
              }}
              classes={{ menuIcon: styles.menuIcon }}
              style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
              sx={{ 
                '& .MuiDataGrid-cell': {
                  color: '#2d3037',
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-columnHeader': {
                  color: '#2d3037',
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-footerContainer': {
                  color: '#2d3037',
                  borderTop: '1px solid #c6c6c6',
                },
                '& .MuiTablePagination-root': {
                  color: '#2d3037',
                  borderTop: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-toolbarContainer': {
                  color: '#2d3037',
                },
                '& .MuiDataGrid-menu': {
                  color: '#2d3037',
                },
                '& .MuiSvgIcon-root':{
                  color: '#9ca91c',
                },
                '& .MuiTablePagination-actions': {
                  color: '#2d3037', // Color for pagination actions
                },
                '& .MuiTablePagination-select': {
                  color: '#2d3037', // Color for pagination select
                },
                '& .MuiTablePagination-selectLabel': {
                  color: '#2d3037', // Color for pagination select label
                },
                '& .MuiTablePagination-displayedRows': {
                  color: '#2d3037', // Color for displayed rows text
                },
                '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                  color: 'red',
                },
                '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                  color: 'red',
                },
              }}
              slotProps={{
                    toolbar:{
                      sx:{
                        color:'#FFF'
                      }
                    },
                    pagination: {
                      sx: {
                        color: '#f19d38',
                      },
                    },
                    menu: {
                      sx: {
                        color: '#f19d38',
                      },
                    },
              }}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} style={{ padding:'0', margin:'0' }}>
          <DataGrid
                getRowId={row=>row.id ? row.id : Math.random() }
                checkboxSelection
                onRowSelectionModelChange={handleRowSelectionChangeOficina}
                rowSelectionModel={selectedOficinaIds}
                lots={{ toolbar: GridToolbar }}
                rows={filterOficinas}
                columns={finalColumnsOficinas}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 20]}
                // isRowSelectable={(params) => params.row.grupo == true}
                localeText={{
                ...esES.components.MuiDataGrid.defaultProps.localeText,
                noRowsLabel: "No hay datos disponibles",
                pagination: {
                    labelRowsPerPage: "Filas por página:",
                }
                }}
                classes={{ menuIcon: styles.menuIcon }}
                style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
                sx={{ 
                  '& .MuiDataGrid-cell': {
                    color: '#2d3037',
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-columnHeader': {
                    color: '#2d3037',
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-footerContainer': {
                    color: '#2d3037',
                    borderTop: '1px solid #c6c6c6',
                  },
                  '& .MuiTablePagination-root': {
                    color: '#2d3037',
                    borderTop: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-toolbarContainer': {
                    color: '#2d3037',
                  },
                  '& .MuiDataGrid-menu': {
                    color: '#2d3037',
                  },
                  '& .MuiSvgIcon-root':{
                    color: '#9ca91c',
                  },
                  '& .MuiTablePagination-actions': {
                    color: '#2d3037', // Color for pagination actions
                  },
                  '& .MuiTablePagination-select': {
                    color: '#2d3037', // Color for pagination select
                  },
                  '& .MuiTablePagination-selectLabel': {
                    color: '#2d3037', // Color for pagination select label
                  },
                  '& .MuiTablePagination-displayedRows': {
                    color: '#2d3037', // Color for displayed rows text
                  },
                  '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                    color: 'red',
                  },
                  '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                    color: 'red',
                  },
                }}
                slotProps={{
                      toolbar:{
                        sx:{
                          color:'#FFF'
                        }
                      },
                      pagination: {
                        sx: {
                          color: '#f19d38',
                        },
                      },
                      menu: {
                        sx: {
                          color: '#f19d38',
                        },
                      },
                }}
            />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2} style={{ padding:'0', margin:'0' }}>
        <DataGrid
            getRowId={row=>row.id ? row.id : Math.random() }
            checkboxSelection
            onRowSelectionModelChange={handleRowSelectionChangeEspacio}
            rowSelectionModel={selectedEspacioIds}
            lots={{ toolbar: GridToolbar }}
            rows={filterEspacios}
            columns={finalColumnsEspacios}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15, 20]}
            // isRowSelectable={(params) => params.row.grupo == true}
            localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
                labelRowsPerPage: "Filas por página:",
            }
            }}
            classes={{ menuIcon: styles.menuIcon }}
            style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
            sx={{ 
              '& .MuiDataGrid-cell': {
                color: '#2d3037',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeader': {
                color: '#2d3037',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-footerContainer': {
                color: '#2d3037',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiTablePagination-root': {
                color: '#2d3037',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-toolbarContainer': {
                color: '#2d3037',
              },
              '& .MuiDataGrid-menu': {
                color: '#2d3037',
              },
              '& .MuiSvgIcon-root':{
                color: '#9ca91c',
              },
              '& .MuiTablePagination-actions': {
                color: '#2d3037', // Color for pagination actions
              },
              '& .MuiTablePagination-select': {
                color: '#2d3037', // Color for pagination select
              },
              '& .MuiTablePagination-selectLabel': {
                color: '#2d3037', // Color for pagination select label
              },
              '& .MuiTablePagination-displayedRows': {
                color: '#2d3037', // Color for displayed rows text
              },
              '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                color: 'red',
              },
              '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                color: 'red',
              },
            }}
            slotProps={{
                  toolbar:{
                    sx:{
                      color:'#FFF'
                    }
                  },
                  pagination: {
                    sx: {
                      color: '#f19d38',
                    },
                  },
                  menu: {
                    sx: {
                      color: '#f19d38',
                    },
                  },
            }}
        />  
        </CustomTabPanel>
    </div>
  )
}

export default Table