import { Pie } from 'react-chartjs-2';
import { Typography } from '@mui/material'
import { Chart as ChartJS, Tooltip, Legend, ArcElement, CategoryScale, PointElement, Title } from "chart.js";
import { useEffect, useState } from "react";

ChartJS.register(Tooltip, Legend, ArcElement, CategoryScale, PointElement, Title);

const colores = [
  "rgba(255, 99, 132, 0.5)",
  "rgba(54, 162, 235, 0.5)",
  "rgba(255, 206, 86, 0.5)",
  "rgba(75, 192, 192, 0.5)",
  "rgba(153, 102, 255, 0.5)",
  "rgba(255, 159, 64, 0.5)",
];

const calculateTotals = (generalInfo) => {
  const totals = generalInfo.reduce((acc, curr) => {
    acc.capacidadTotal += curr.capacidadTotal || 0;
    acc.ocupacionTotal += curr.ocupados || 0;
    return acc;
  }, { capacidadTotal: 0, ocupacionTotal: 0 });

  totals.porcentajeOcupacion = (totals.ocupacionTotal / totals.capacidadTotal) * 100;
  return totals;
}

const Chart = ({ data }) => {
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [colors, setColors] = useState([]);
  const [totals, setTotals] = useState({ capacidadTotal: 0, ocupacionTotal: 0, porcentajeOcupacion: 0 });

  useEffect(() => {
    if (data && data.length > 0) {
      const names = data.map(item => item.nombre);
      setLabels(names);
      const valores = data.map(item => item.porcentajeDeOcupacion);
      setValues(valores);
      const coloresX = colores.slice(0, names.length);
      setColors(coloresX);
      const totals = calculateTotals(data);
      setTotals(totals);
    }
  }, [data]);

  const pieChartData = {
    labels: labels,
    datasets: [
      {
        label: "% de ocupación",
        data: values,
        backgroundColor: colors,
        hoverOffset: 4
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom"
      },
      title: {
        display: true,
        text: `Porcentaje de ocupación campamentos`
      }
    }
  };

  return (
    <div>
      <Pie data={pieChartData} options={options} />
      <div style={{ marginTop: '0px' }}>
        <Typography style={{ fontSize:'13px', color:'#FFF', fontWeight:'500' }} gutterBottom>Resumen General</Typography>
        <Typography style={{ fontSize:'13px', color:'#FFF', fontWeight:'500' }} gutterBottom>
        Capacidad Total: {totals.capacidadTotal}</Typography>
        <Typography style={{ fontSize:'13px', color:'#FFF', fontWeight:'500' }} gutterBottom>
        Ocupación Total: {totals.ocupacionTotal}</Typography>
        <Typography style={{ fontSize:'13px', color:'#FFF', fontWeight:'500' }} gutterBottom>
        Porcentaje de Ocupación Total: {totals.porcentajeOcupacion.toFixed(2)}%</Typography>
      </div>
    </div>
  );
}

export default Chart;
