import { Button, Chip, IconButton, Typography, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import { Badge } from "@mui/material";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { RenderCell } from "./RenderCell";
import { RenderCell2 } from "./RenderCell2";
import { RenderCell3 } from "./RenderCell3";
import { RenderCell4 } from "./RenderCell4";
import { RenderCell5 } from "./RenderCell5";
import { format, max, min, parseISO } from "date-fns";
import SettingsIcon from "@mui/icons-material/Settings";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import PostAddIcon from "@mui/icons-material/PostAdd";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import BlockIcon from "@mui/icons-material/Block";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import WorkIcon from "@mui/icons-material/Work";

export const campamentosColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit,
  setOpenMore,
  setScroll
) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.nombre,
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
        }}
      >
        {row.row.nombre}
      </Box>
    ),
  },
  {
    field: "direccion",
    headerName: "Dirección",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.direccion,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.direccion}
      </Box>
    ),
  },
  {
    field: "descripcion",
    headerName: "Descripcion",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenMore(true);
              setScroll("Paper");
            }}
          >
            <VisibilityIcon style={{ color: "var(--border-blue)" }} />
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>
        </div>
      );
    },
  },
];

export const monitoreoColumns = (setOpen, setSeleItem, setOpenMore, handleRemover) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => (row.row?.trabajador ? row.row?.trabajador.rut : ""),
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
        }}
      >
        {row.row?.trabajador ? row.row?.trabajador?.rut : ""}
      </Box>
    ),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.trabajador
        ? row.row?.trabajador.nombre +
          " " +
          row.row?.trabajador.apellidoPaterno +
          " " +
          row.row?.trabajador.apellidoMaterno
        : "",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
        }}
      >
        {row.row?.trabajador
          ? row.row?.trabajador.nombre +
            " " +
            row.row?.trabajador.apellidoPaterno +
            " " +
            row.row?.trabajador.apellidoMaterno
          : ""}
      </Box>
    ),
  },
  {
    field: "email",
    headerName: "Correo",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.trabajador ? row.row?.trabajador.email : "",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
        }}
      >
        {row.row?.trabajador ? row.row?.trabajador?.email : ""}
      </Box>
    ),
  },
  {
    field: "icono",
    headerName: "",
    // flex: 1,
    width: 60,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {},
    renderCell: (row) => {
      let IconComponent;
      switch (row.row.status) {
        case 1:
          IconComponent = NewReleasesIcon;
          break;
        case 2:
          IconComponent = BlockIcon;
          break;
        case 3:
          IconComponent = SwapHorizIcon;
          break;
        case 4:
          IconComponent = WorkIcon;
          break;
        default:
          IconComponent = PostAddIcon;
      }

      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
          }}
        >
          <IconComponent style={{ color: "#000" }} />
        </Box>
      );
    },
  },
  {
    field: "detalle",
    headerName: "Detalle",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const trabajador = row.row?.trabajador ?? {};
      const habitacion = row.row?.habitacion ?? {};

      const detalle = [
        trabajador?.contrato ? `Contrato: ${trabajador.contrato}` : "",
        row.row?.nuevoCargo ? `Nuevo cargo: ${row.row.nuevoCargo}` : "",
        row.row?.nuevoTurno ? `Nuevo turno: ${row.row.nuevoTurno}` : "",
        row.row?.turno ? `Turno anterior: ${row.row.turno}` : "",
        habitacion?.numero
          ? `Habitación: ${habitacion.numero}, ${
              habitacion.pabellon?.identificador ||
              habitacion.wing?.nombre ||
              habitacion.edificio?.nombre ||
              ""
            }, Campamento: ${
              habitacion.pabellon?.campamento?.nombre ||
              habitacion.wing?.campamento?.nombre ||
              habitacion.edificio?.campamento?.nombre ||
              ""
            }`
          : "",
      ]
        .filter(Boolean)
        .join(" | ");

      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
          }}
        >
          {detalle || "No disponible"}
        </Box>
      );
    },
    renderCell: (row) => {
      const trabajador = row.row?.trabajador ?? {};
      const habitacion = row.row?.habitacion ?? {};

      const detalle = [
        row.row?.contrato ? `Contrato: ${row.row.contrato}` : "",
        row.row?.nuevoCargo ? `Nuevo cargo: ${row.row?.nuevoCargo}` : "",
        row.row?.nuevoTurno ? `Nuevo turno: ${row.row?.nuevoTurno}` : "",
        row.row?.turno ? `Turno anterior: ${trabajador.turno}` : "",
        habitacion?.numero
          ? `Habitación: ${habitacion.numero}, ${
              habitacion.pabellon?.identificador ||
              habitacion.wing?.nombre ||
              habitacion.edificio?.nombre ||
              ""
            }, Campamento: ${
              habitacion.pabellon?.campamento?.nombre ||
              habitacion.wing?.campamento?.nombre ||
              habitacion.edificio?.campamento?.nombre ||
              ""
            }`
          : "",
      ]
        .filter(Boolean)
        .join(" | ");

      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
          }}
        >
          {detalle || "No disponible"}
        </Box>
      );
    },
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <IconButton
          onClick={() => {
            if(params.row.status === 2){
              handleRemover(params.row.id)
              }else{
                setOpenMore(true);
                setSeleItem(params.row);
            }
            
          }}
        >
          <EditIcon style={{ color: "var(--border-blue)" }} />
          {params.row.status == 1 ||
          params.row.status == 3 ||
          params.row.status == 4 ? (
            <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Asignar habitación
            </span>
          ) : (
            <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Liberar información.
            </span>
          )}
        </IconButton>
      );
    },
  },
];

export const trabajadorReservaOficina = (reenviar) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.rut,
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
        }}
      >
        {row.row.rut ?? ""}
      </Box>
    ),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.nombre,
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
        }}
      >
        {row.row.nombre ?? ""}
      </Box>
    ),
  },
  {
    field: "apellidoPaterno",
    headerName: "Apellido",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.apellidoPaterno,
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
        }}
      >
        {row.row.apellidoPaterno ?? ""}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <Button onClick={() => reenviar(params.row.rut)}>
          <span style={{ fontSize: "12px" }}>Reenviar clave chapa</span>
        </Button>
      );
    },
  },
];
export const trabajadoresReservaOficina = (
  setSelectedReserva,
  setOpenTrab,
  setClaveChapa
) => [
  {
    field: "fechaInicio",
    headerName: "Desde",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.fechaInicio,
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
        }}
      >
        {row.row.fechaInicio && format(row.row.fechaInicio, "dd-MM-yyyy")}
      </Box>
    ),
  },
  {
    field: "fechaTermino",
    headerName: "hasta",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.fechaTermino,
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
        }}
      >
        {row.row.fechaTermino && format(row.row.fechaTermino, "dd-MM-yyyy")}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <IconButton
          onClick={function () {
            setSelectedReserva(params.row);
            setOpenTrab(true);
            setClaveChapa(params.row.oficina.claveChapa);
          }}
        >
          <VisibilityIcon style={{ color: "var(--border-blue)" }} />
        </IconButton>
      );
    },
  },
];
export const estandarColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "campamentoName",
    headerName: "Campamento",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.campamento?.nombre ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.campamento?.nombre ?? ""}
      </Box>
    ),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.nombre ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.nombre ?? ""}
      </Box>
    ),
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.descripcion ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.descripcion ?? ""}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];
export const historicoColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row?.row?.fecha && format(row?.row?.fecha, "dd-MM-yyyy"),
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row?.row?.fecha && format(row?.row?.fecha, "dd-MM-yyyy")}
      </Box>
    ),
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.estado,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.estado}
      </Box>
    ),
  },
  {
    field: "usuario",
    headerName: "Usuario",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row?.row?.usuario
        ? row?.row?.usuario?.nombre + " " + row?.row?.usuario?.apellido
        : "--",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row?.row?.usuario
          ? row?.row?.usuario?.nombre + " " + row?.row?.usuario?.apellido
          : "--"}
      </Box>
    ),
  },
  {
    field: "nota",
    headerName: "Observación",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.nota,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.nota ?? "--"}
      </Box>
    ),
  },
];
export const habitacionesDisponiblesP_INTERNO = (asignar, handleRemover) => [
  {
    field: "numero",
    headerName: "N°",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.numero,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.numero}
      </Box>
    ),
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.piso,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.piso}
      </Box>
    ),
  },
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row.wing
        ? row.row.wing.campamento.nombre
        : row.row.pabellon
        ? row.row.pabellon.campamento.nombre
        : row.row.edificio
        ? row.row.edificio.campamento.nombre
        : "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.wing
          ? row.row.wing.campamento.nombre
          : row.row.pabellon
          ? row.row.pabellon.campamento.nombre
          : row.row.edificio
          ? row.row.edificio.campamento.nombre
          : ""}
      </Box>
    ),
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => (row.row.wing ? row.row.wing.nombre : ""),
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.wing ? row.row.wing.nombre : ""}
      </Box>
    ),
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row.pabellon ? row.row.pabellon.identificador : "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.pabellon ? row.row.pabellon.identificador : ""}
      </Box>
    ),
  },
  {
    field: "tipo_cama",
    headerName: "Tipo cama",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.tipoCama ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.tipoCama ?? ""}
      </Box>
    ),
  },
  {
    field: "tipo_huesped",
    headerName: "Húesped",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.tipoHuesped ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.tipoHuesped ?? ""}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() =>
            params.row.status === 2
              ? handleRemover()
              : asignar(params.row.id)
          }
        >
          <AddCircleOutlineIcon style={{ color: "#2d3037" }} />
        </IconButton>
      </div>
    ),
  },
];
export const tramosColumns = () => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.nombre,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.nombre}
      </Box>
    ),
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.contrato,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.contrato}
      </Box>
    ),
  },
  {
    field: "vicepresidencia",
    headerName: "Vicepresidencia",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.vicepresidencia,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.vicepresidencia}
      </Box>
    ),
  },
  {
    field: "gerencia",
    headerName: "Gerencia",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.gerencia,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.gerencia}
      </Box>
    ),
  },
  {
    field: "siArea",
    headerName: "SiArea",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.siArea,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.siArea}
      </Box>
    ),
  },
  {
    field: "fechaDesde",
    headerName: "Desde",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row.fechaDesde ? format(row.row.fechaDesde, "dd-MM-yyyy") : "-",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.fechaDesde ? format(row.row.fechaDesde, "dd-MM-yyyy") : "-"}
      </Box>
    ),
  },
  {
    field: "fechaHasta",
    headerName: "Hasta",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row.fechaHasta ? format(row.row.fechaHasta, "dd-MM-yyyy") : "-",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.fechaHasta ? format(row.row.fechaHasta, "dd-MM-yyyy") : "-"}
      </Box>
    ),
  },
  {
    field: "trabajadoresDia",
    headerName: "N°Trabajadores día",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.trabajadoresDia,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.trabajadoresDia}
      </Box>
    ),
  },
  {
    field: "trabajadoresNoche",
    headerName: "N°Trabajadores noche",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.trabajadoresNoche,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.trabajadoresNoche}
      </Box>
    ),
  },
  {
    field: "comentarios",
    headerName: "comentarios",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.comentarios,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.comentarios}
      </Box>
    ),
  },
];
export const forecastColumns = (
  setOpen,
  setSeleItem,
  setOpenHistorico,
  rol
) => [
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    // align: "center",
    renderCell: (params) => {
      const estado = params.row?.estado || ""; // Manejar caso cuando estado es undefined

      const canEdit =
        (rol.includes("Administrador de Contrato P_EXTERNO (ACE)") &&
          (estado.includes("Creado") ||
            estado.includes("Con cambios aplicados"))) ||
        (rol.includes("Administrador de RRHH (ARH)") &&
          estado === "Aprobado por P_INTERNO") ||
        rol.includes("Administrador de Contrato P_INTERNO (ACC)");

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <IconButton
            onClick={() => {
              setSeleItem(params.row);
              setOpenHistorico(true);
            }}
          >
            <VisibilityIcon style={{ color: "#0086ae" }} />
          </IconButton>
          {canEdit && (
            <IconButton
              onClick={() => {
                setSeleItem(params.row);
                setOpen(true);
              }}
            >
              <EditIcon style={{ color: "#f19d38" }} />
            </IconButton>
          )}
        </div>
      );
    },
  },
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.id,
    renderCell: (params) => params.row?.id,
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.estado,
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {params.row?.estado}
      </Box>
    ),
  },
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "start",
    valueGetter: (params) => params.row?.empresa?.razonSocial,
    renderCell: (params) => params.row?.empresa?.razonSocial,
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "start",
    valueGetter: (params) =>
      params.row?.tramos?.map((item) => item.contrato).join(", "),
    renderCell: (params) =>
      params.row?.tramos?.map((item) => item.contrato).join(", "),
  },
  {
    field: "vicepresidencia",
    headerName: "Vicepresidencia",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "start",
    valueGetter: (params) =>
      params.row?.tramos?.map((item) => item.vicepresidencia).join(", "),
    renderCell: (params) => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "flex-start",
        }}
      >
        {params.row?.tramos?.map((item, index) => (
          <span key={index}>{item.vicepresidencia}</span>
        ))}
      </div>
    ),
  },
  {
    field: "gerencia",
    headerName: "Gerencia",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "start",
    valueGetter: (params) =>
      params.row?.tramos?.map((item) => item.gerencia).join(", "),
    renderCell: (params) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {params.row?.tramos?.map((item, index) => (
          <span key={index}>{item.gerencia}</span>
        ))}
      </div>
    ),
  },
  {
    field: "siArea",
    headerName: "SI Área",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "start",
    valueGetter: (params) =>
      params.row?.tramos?.map((item) => item.siArea).join(", "),
    renderCell: (params) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {params.row?.tramos?.map((item, index) => (
          <span key={index}>{item.siArea}</span>
        ))}
      </div>
    ),
  },
  {
    field: "trabajadoresDia",
    headerName: "Total Trabajadores Día",
    minWidth: 180,
    flex: 1,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.tramos?.reduce(
        (acc, item) => acc + parseInt(item.trabajadoresDia, 10),
        0
      ),
    renderCell: (params) => {
      const sum = params.row?.tramos?.reduce(
        (acc, item) => acc + parseInt(item.trabajadoresDia, 10),
        0
      );
      return sum;
    },
  },
  {
    field: "trabajadoresNoche",
    headerName: "Total Trabajadores Noche",
    flex: 1,
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.tramos?.reduce(
        (acc, item) => acc + parseInt(item.trabajadoresNoche, 10),
        0
      ),
    renderCell: (params) => {
      const sum = params.row?.tramos?.reduce(
        (acc, item) => acc + parseInt(item.trabajadoresNoche, 10),
        0
      );
      return sum;
    },
  },
  {
    field: "fechaDesde",
    headerName: "Desde",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const tramos = Array.isArray(params.row?.tramos) ? params.row.tramos : [];
      const fechasDesde = tramos
        .map((item) => (item.fechaDesde ? parseISO(item.fechaDesde) : null))
        .filter((date) => date !== null);
      const fechaMinima = fechasDesde.length > 0 ? min(fechasDesde) : null;
      return fechaMinima ? format(fechaMinima, "dd/MM/yyyy") : "";
    },
    renderCell: (params) => {
      const tramos = Array.isArray(params.row?.tramos) ? params.row.tramos : [];
      const fechasDesde = tramos
        .map((item) => (item.fechaDesde ? parseISO(item.fechaDesde) : null))
        .filter((date) => date !== null);
      const fechaMinima = fechasDesde.length > 0 ? min(fechasDesde) : null;
      return fechaMinima ? format(fechaMinima, "dd/MM/yyyy") : "";
    },
  },
  {
    field: "fechaHasta",
    headerName: "Hasta",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const tramos = Array.isArray(params.row?.tramos) ? params.row.tramos : [];
      const fechasHasta = tramos
        .map((item) => (item.fechaHasta ? parseISO(item.fechaHasta) : null))
        .filter((date) => date !== null);
      const fechaMaxima = fechasHasta.length > 0 ? max(fechasHasta) : null;
      return fechaMaxima ? format(fechaMaxima, "dd/MM/yyyy") : "";
    },
    renderCell: (params) => {
      const tramos = Array.isArray(params.row?.tramos) ? params.row.tramos : [];
      const fechasHasta = tramos
        .map((item) => (item.fechaHasta ? parseISO(item.fechaHasta) : null))
        .filter((date) => date !== null);
      const fechaMaxima = fechasHasta.length > 0 ? max(fechasHasta) : null;
      return fechaMaxima ? format(fechaMaxima, "dd/MM/yyyy") : "";
    },
  },
];
export const gestionForecastColumns = (
  setOpen,
  setSeleItem,
  setOpenModal,
  rol,
  setOpenModal2
) => [
  {
    field: "opciones",
    headerName: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton size="small" style={{ marginLeft: 5, color: "#0086ae" }}>
          <SettingsIcon fontSize="small" />
        </IconButton>
      </div>
    ),
    width: 60,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <>
          <IconButton
            onClick={(event) => {
              if (!rol?.includes("Administrador de Contrato P_EXTERNO (ACE)")) {
                setSeleItem(params.row);
                event.stopPropagation();
                setOpenModal(true);
              }
            }}
          >
            {!rol?.includes("Administrador de Contrato P_EXTERNO (ACE)") ? (
              rol?.includes("Administrador de Contrato P_INTERNO (ACC)") &&
              (params.row?.estado.includes("Creado") ||
                params.row?.estado.includes("cambios aplicados")) ? (
                <EditIcon style={{ color: "#f19d38" }} />
              ) : rol?.includes("Administrador de RRHH (ARH)") &&
                params.row?.estado.includes("P_INTERNO") ? (
                <EditIcon style={{ color: "#f19d38" }} />
              ) : (
                <VisibilityIcon style={{ color: "#0086ae" }} />
              )
            ) : (
              ""
            )}
          </IconButton>
          {((rol?.includes("Administrador de Contrato P_INTERNO (ACC)") &&
            params.row?.estado.includes("Creado")) ||
            (rol?.includes("Administrador de Contrato P_INTERNO (ACC)") &&
              params.row?.estado.includes("Con cambios aplicados")) ||
            (rol?.includes("Administrador de RRHH (ARH)") &&
              params.row?.estado.includes("Aprobado por P_INTERNO"))) && (
            <IconButton
              onClick={(event) => {
                setSeleItem(params.row);
                event.stopPropagation();
                setOpenModal2(true);
              }}
            >
              <AutoModeIcon style={{ color: "#2d3037" }} />
            </IconButton>
          )}
        </>
      );
    },
  },
  // {
  //   field: "id",
  //   headerName: "ID",
  //   flex: 1,
  //   minWidth: 300,
  //   headerAlign: "center",
  //   align: "center",
  //   valueGetter: (params) => params.row.id,
  //   renderCell: (params) => params.row.id,
  // },
  {
    field: "vicepresidencia",
    headerName: "Vicepresidencia",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.tramos?.map((item) => item.vicepresidencia).join(", "),
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {params.row.tramos.map((item, index) => (
          <span key={index} style={{ textAlign: "center" }}>
            {item.vicepresidencia}
          </span>
        ))}
      </Box>
    ),
  },
  {
    field: "gerencia",
    headerName: "Gerencia",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.tramos?.map((item) => item.gerencia).join(", "),
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {params.row.tramos.map((item, index) => (
          <span key={index} style={{ textAlign: "center" }}>
            {item.gerencia}
          </span>
        ))}
      </Box>
    ),
  },
  {
    field: "siArea",
    headerName: "SI Área",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.tramos?.map((item) => item.siArea).join(", "),
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {params.row.tramos.map((item, index) => (
          <span key={index} style={{ textAlign: "center" }}>
            {item.siArea}
          </span>
        ))}
      </Box>
    ),
  },
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.empresa?.razonSocial,
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {params.row?.empresa?.razonSocial}
      </Box>
    ),
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.tramos?.map((item) => item.contrato).join(", "),
    renderCell: (params) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {params.row.tramos.map((item, index) => (
          <span key={index}>{item.contrato}</span>
        ))}
      </div>
    ),
  },
  {
    field: "trabajadoresDia",
    headerName: "Total Trabajadores Día",
    minWidth: 180,
    flex: 1,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.tramos?.reduce(
        (acc, item) => acc + parseInt(item.trabajadoresDia, 10),
        0
      ),
    renderCell: (params) => {
      const sum = params.row?.tramos?.reduce(
        (acc, item) => acc + parseInt(item.trabajadoresDia, 10),
        0
      );
      return sum;
    },
  },
  {
    field: "trabajadoresNoche",
    headerName: "Total Trabajadores Noche",
    flex: 1,
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.tramos?.reduce(
        (acc, item) => acc + parseInt(item.trabajadoresNoche, 10),
        0
      ),
    renderCell: (params) => {
      const sum = params.row?.tramos?.reduce(
        (acc, item) => acc + parseInt(item.trabajadoresNoche, 10),
        0
      );
      return sum;
    },
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.estado,
    renderCell: (params) => {
      return (
        <div
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {params.value}
        </div>
      );
    },
  },
  {
    field: "fecha_modificacion",
    headerName: "Última modificación",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const modificaciones = params.row?.modificacionesForecasts;
      const lastModification =
        modificaciones && modificaciones.length > 0
          ? modificaciones[modificaciones.length - 1].fecha
          : null;
      const formattedDate = lastModification
        ? format(new Date(lastModification), "dd-MM-yyyy")
        : format(new Date(params?.row?.fechaCreacion), "dd-MM-yyyy");

      return formattedDate;
    },
    renderCell: (params) => {
      const modificaciones = params.row?.modificacionesForecasts;
      const lastModification =
        modificaciones && modificaciones.length > 0
          ? modificaciones[modificaciones.length - 1].fecha
          : null;
      const formattedDate = lastModification
        ? format(new Date(lastModification), "dd-MM-yyyy")
        : format(new Date(params?.row?.fechaCreacion), "dd-MM-yyyy");

      return formattedDate;
    },
  },
];
export const categoriasColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.nombre || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.descripcion || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];
export const notificationsSupervisorColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  {
    field: "desde",
    headerName: "Desde",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.bodegaOrigen?.nombre ?? "N/A",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "hacia",
    headerName: "Hacia",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.bodegaDestino?.nombre ?? "N/A",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "encargado",
    headerName: "Encargado",
    flex: 1,
    minWidth: 230,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.encardoDeBodega?.nombre +
        " " +
        params.row?.encardoDeBodega?.apellidos ?? "--",
    renderCell: (params) => {
      const nombre = params.row?.encardoDeBodega
        ? params.row?.encardoDeBodega?.nombre ?? ""
        : "";
      const apellido = params.row?.encardoDeBodega
        ? params.row?.encardoDeBodega?.apellidos ??
          params.row?.encardoDeBodega?.apellidoPaterno ??
          ""
        : "";

      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {nombre + " " + apellido}
        </Box>
      );
    },
  },
  {
    field: "montoTotal",
    headerName: "Total (CLP)",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.montoTotal ?? "N/A",
    renderCell: (params) => params.value,
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.fecha
        ? format(new Date(params.row.fecha), "dd-MM-yyyy")
        : "--",
    renderCell: (params) => params.value,
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <Chip label={params.row?.estado} sx={{ color: "#0086ae" }} />
    ),
  },
  {
    field: "articulos",
    headerName: "Artículos",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const articulos = params.row?.articulos || [];
      return articulos
        .map(
          (articulo) =>
            `${articulo?.producto?.codigo}: ${articulo?.producto?.nombre}`
        )
        .join(", ");
    },
    renderCell: (params) => {
      const articulos = params.row?.articulos || [];
      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {articulos
            .map(
              (articulo) =>
                `${articulo?.producto?.codigo}: ${articulo?.producto?.nombre}`
            )
            .join(", ")}
        </Box>
      );
    },
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      const { row } = params;
      return (
        <div>
          {row.estado === "Pendiente" && (
            <IconButton
              onClick={() => {
                setSeleItem(params.row);
                setOpenEdit(true);
              }}
            >
              <EditIcon style={{ color: "#f19d38" }} />
            </IconButton>
          )}
        </div>
      );
    },
  },
];
export const notificationsColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "tipo",
    headerName: "Tipo",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tipo ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          textTransform: "capitalize",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "desde",
    headerName: "Desde",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.bodegaOrigen?.nombre
        ? params.row?.bodegaOrigen?.nombre
        : params.row?.bodega?.nombre
        ? params.row?.bodega?.nombre
        : "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "hacia",
    headerName: "Hacia",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.bodegaDestino?.nombre ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "encargado",
    headerName: "Encargado de bodega",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const encargado = params.row.encargadoDeBodega || params.row.usuario;

      return encargado?.nombre + " " + encargado?.apellidos;
    },
    renderCell: (params) => {
      const encargado = params.row.encargadoDeBodega || params.row.usuario;

      const nombre = encargado?.nombre ?? "";
      const apellido = encargado?.apellidos ?? encargado?.apellidoPaterno ?? "";

      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {nombre + " " + apellido}
        </Box>
      );
    },
  },
  {
    field: "montoTotal",
    headerName: "Monto total (CLP)",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.montoTotal,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const date = params.row.fecha ? new Date(params.row.fecha) : "";
      return date ? format(date, "dd-MM-yyyy") : "--";
    },
    renderCell: (params) => {
      const date = params.row.fecha ? new Date(params.row.fecha) : "";
      return date ? format(date, "dd-MM-yyyy") : "--";
    },
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 240,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <Chip
        sx={{ color: "#0086ae" }}
        label={
          params.row?.estado === "Aprobado"
            ? "En tránsito"
            : params.row?.estado === "Entregado"
            ? "Recibido"
            : params.row?.estado === "Pendiente"
            ? "Pendiente"
            : params.row?.estado
        }
      />
    ),
    valueGetter: (params) =>
      params.row?.estado === "Aprobado"
        ? "En tránsito"
        : params.row?.estado === "Entregado"
        ? "Recibido"
        : params.row?.estado === "Pendiente"
        ? "Pendiente"
        : params.row?.estado,
  },
  {
    field: "articulos",
    headerName: "Artículos",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      // Verifica si viene como articulos o salidaArticulos
      const articulos = params.row.articulos || params.row.salidaArticulos;

      return articulos
        ?.map((articulo) => articulo?.producto?.nombre)
        .join(", ");
    },
    renderCell: (params) => {
      // Verifica si viene como articulos o salidaArticulos
      const articulos =
        params.row?.articulos || params.row?.salidaArticulos || [];

      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {articulos
            .map(
              (articulo) =>
                `${articulo?.producto?.codigo}: ${articulo?.producto?.nombre}`
            )
            .join(", ")}
        </Box>
      );
    },
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        {(params.row.estado === "Aprobado" ||
          (params.row.tipo === "salida" &&
            params.row.estado === "Pendiente")) && (
          <IconButton
            onClick={() => {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
        )}
      </div>
    ),
  },
];
export const trabajadoresReasignacionColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 250,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "apellido",
    headerName: "apellido",
    flex: 1,
    minWidth: 250,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "email",
    headerName: "Correo",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cargo",
    headerName: "Cargo",
    flex: 1,
    minWidth: 250,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "rol",
    headerName: "Rol",
    flex: 1,
    minWidth: 320,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <Box display="flex" flexDirection="column">
        {row.row?.roles?.map((role, index) => (
          <span key={index}>{role}</span>
        ))}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const reasignacionColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "habitacion",
    headerName: "Habitación",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params?.row?.habitacion?.numero ?? "",
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      params?.row?.habitacion?.pabellon?.identificador ?? "",
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params?.row?.habitacion?.wing?.nombre ?? "",
  },
  {
    field: "campamento",
    headerName: "campamento",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      if (row?.row?.habitacion?.wing) {
        return row?.row?.habitacion?.wing?.campamento?.nombre;
      } else if (row?.row?.habitacion?.pabellon) {
        return row?.row?.habitacion?.pabellon?.campamento?.nombre;
      } else {
        return "";
      }
    },
  },
];
export const reasignacionHabitacionesColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  {
    field: "asignado",
    headerName: "Asignados",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.asignados && Array.isArray(row.row?.asignados)
        ? row.row.asignados
            .map(
              (item) =>
                `${item.trabajador.nombre ?? item.trabajador.nombres} ${
                  item.trabajador.apellidos ?? item.trabajador.apellidoPaterno
                } (${item.trabajador.rut})`
            )
            .join(", ")
        : "",
    renderCell: (row) =>
      row.row?.asignados &&
      Array.isArray(row.row?.asignados) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {row.row.asignados.map((item, index) => (
            <span key={index}>
              {item.trabajador.nombre ?? item.trabajador.nombres}{" "}
              {item.trabajador.apellidos ?? item.trabajador.apellidoPaterno} (
              {item.trabajador.rut})
            </span>
          ))}
        </Box>
      ),
  },
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.asignados &&
      Array.isArray(row.row?.asignados) &&
      row.row?.asignados.map((item, index) => (
        <span key={index}>{item.empresa ? item.empresa.razonSocial : "-"}</span>
      )),
    renderCell: (row) =>
      row.row?.asignados &&
      Array.isArray(row.row?.asignados) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {row.row?.asignados.map((item, index) => (
            <span key={index}>
              {item.empresa ? item.empresa.razonSocial : "-"}
            </span>
          ))}
        </Box>
      ),
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.asignados &&
      Array.isArray(row.row?.asignados) &&
      row.row?.asignados.map((item, index) => (
        <span key={index}>{item.contrato}</span>
      )),
    renderCell: (row) =>
      row.row?.asignados &&
      Array.isArray(row.row?.asignados) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {row.row?.asignados.map((item, index) => (
            <span key={index}>{item.contrato ?? item.contrato}</span>
          ))}
        </Box>
      ),
  },
  {
    field: "numero",
    headerName: "Número",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => (row?.row?.pabellon ? row?.row?.piso : ""),
    renderCell: (row) => (row?.row?.pabellon ? row?.row?.piso : ""),
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row?.row?.pabellon ? row?.row?.pabellon.identificador : "",
    renderCell: (row) =>
      row?.row?.pabellon ? row?.row?.pabellon.identificador : "",
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => (row?.row?.wing ? row?.row?.wing.nombre : ""),
    renderCell: (row) => (row?.row?.wing ? row?.row?.wing.nombre : ""),
  },
  {
    field: "numeroTotalCamas",
    headerName: "Total Camas",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row?.row?.numeroTotalCamas ? row?.row?.numeroTotalCamas : "",
    renderCell: (row) =>
      row?.row?.numeroTotalCamas ? row?.row?.numeroTotalCamas : "",
  },
  {
    field: "disponibles",
    headerName: "Camas disponibles",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      Array.isArray(row.row?.trabajadores) &&
      row?.row?.numeroTotalCamas &&
      row?.row?.trabajadores.length
        ? row?.row?.numeroTotalCamas - row?.row?.trabajadores.length
        : row.row?.numeroTotalCamas,
    renderCell: (row) =>
      Array.isArray(row.row?.trabajadores) &&
      row?.row?.numeroTotalCamas &&
      row?.row?.trabajadores.length
        ? row?.row?.numeroTotalCamas - row?.row?.trabajadores.length
        : row.row?.numeroTotalCamas,
  },
  {
    field: "tipoHuesped",
    headerName: "Tipo Huésped",
    flex: 1,
    minWidth: 140,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => (row?.row?.tipoHuesped ? row?.row?.tipoHuesped : ""),
    renderCell: (row) => (row?.row?.tipoHuesped ? row?.row?.tipoHuesped : ""),
  },
  {
    field: "cargosReservados",
    headerName: "Cargos reservados",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      Array.isArray(row?.row?.cargosReservados)
        ? row.row.cargosReservados.join(", ")
        : "",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {Array.isArray(row?.row?.cargosReservados)
          ? row.row.cargosReservados.join(", ")
          : ""}
      </Box>
    ),
  },
];
export const reasignacionTrabajadoresColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.trabajador?.rut ?? "",
    renderCell: (row) => row.row?.trabajador?.rut ?? "",
  },
  {
    field: "nombreCompleto",
    headerName: "Nombre",
    flex: 1,
    minWidth: 140,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const nombre = row.row?.trabajador?.nombre
        ? row.row?.trabajador?.nombre
        : row.row?.trabajador?.nombres
        ? row.row?.trabajador?.nombres
        : "";
      const apellidos =
        row.row?.trabajador?.apellido ??
        row.row?.trabajador?.apellidos ??
        row.row?.trabajador?.apellidoPaterno +
          " " +
          row.row?.trabajador?.apellidoMaterno ??
        "";
      return `${nombre} ${" "} ${apellidos}`;
    },
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.jornada ?? "",
    renderCell: (row) => row.row?.jornada ?? "",
  },
  {
    field: "turno",
    headerName: "Turno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.turno?.descripcion ?? row.row?.turno ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {params.value ?? ""}
      </Box>
    ),
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.contrato ?? "",
    renderCell: (row) => row.row?.contrato ?? "",
  },
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 140,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.empresa ? row.row?.empresa?.razonSocial : "",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row.row?.empresa ? row.row?.empresa?.razonSocial : ""}
      </Box>
    ),
  },
  {
    field: "genero",
    headerName: "Género",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.trabajador?.genero ?? "",
    renderCell: (row) => row.row?.trabajador?.genero ?? "",
  },
  {
    field: "tipo",
    headerName: "Tipo cargo",
    flex: 1,
    minWidth: 125,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =>
      row.row?.tipoCargo
        ? row.row?.tipoCargo
        : row.row?.trabajador?.tipoCargo
        ? row.row?.trabajador?.tipoCargo
        : "",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row.row?.tipoCargo
          ? row.row?.tipoCargo
          : row.row?.trabajador?.tipoCargo
          ? row.row?.trabajador?.tipoCargo
          : ""}
      </Box>
    ),
  },
  {
    field: "fechaIngreso",
    headerName: "Ingreso",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      return row?.row?.fechaIngreso
        ? format(row?.row?.fechaIngreso, "dd-MM-yyyy")
        : format(row?.row?.fechaInicio, "dd-MM-yyyy") ?? "";
    },
    renderCell: (params) => params.value,
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return row?.row?.fechaSalida
        ? format(row?.row?.fechaSalida, "dd-MM-yyyy")
        : format(row?.row?.fechaTermino, "dd-MM-yyyy") ?? "";
    },
  },
];
export const historialReasignacionColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.trabajador?.rut ?? "",
    renderCell: (row) => row.row?.trabajador?.rut ?? "",
  },
  {
    field: "nombreCompleto",
    headerName: "Nombre",
    flex: 1,
    minWidth: 140,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.trabajador?.nombre
        ? row.row?.trabajador?.nombre
        : row.row?.trabajador?.nombres
        ? row.row?.trabajador?.nombres
        : "",
    renderCell: (row) => {
      let nombre = row.row?.trabajador?.nombre
        ? row.row?.trabajador?.nombre
        : row.row?.trabajador?.nombres
        ? row.row?.trabajador?.nombres
        : "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {nombre}
        </Box>
      );
    },
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.jornada ?? "",
    renderCell: (row) => row.row?.jornada ?? "",
  },
  {
    field: "turno",
    headerName: "Turno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.turno?.descripcion ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row.row?.turno?.descripcion ?? ""}
      </Box>
    ),
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.contrato ?? "",
    renderCell: (row) => row.row?.contrato ?? "",
  },
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 140,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.empresa ? row.row?.empresa?.razonSocial : "",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row.row?.empresa ? row.row?.empresa?.razonSocial : ""}
      </Box>
    ),
  },
  {
    field: "genero",
    headerName: "Género",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.trabajador?.genero ?? "",
    renderCell: (row) => row.row?.trabajador?.genero ?? "",
  },
  {
    field: "habitacion",
    headerName: "Habitación",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.numero : "",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row.row?.habitacion ? row.row?.habitacion?.numero : ""}
      </Box>
    ),
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const identificador = row.row?.habitacion?.pabellon?.identificador || "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {identificador}
        </Box>
      );
    },
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const identificador = row.row?.habitacion?.wing?.nombre || "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {identificador}
        </Box>
      );
    },
  },
  {
    field: "tipo",
    headerName: "Tipo cargo",
    flex: 1,
    minWidth: 125,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =>
      row.row?.tipoCargo
        ? row.row?.tipoCargo
        : row.row?.trabajador?.tipoCargo
        ? row.row?.trabajador?.tipoCargo
        : "",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row.row?.tipoCargo
          ? row.row?.tipoCargo
          : row.row?.trabajador?.tipoCargo
          ? row.row?.trabajador?.tipoCargo
          : ""}
      </Box>
    ),
  },
  {
    field: "fechaIngreso",
    headerName: "Ingreso",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      if (row?.row?.fechaIngreso) {
        return format(row?.row?.fechaIngreso, "dd-MM-yyyy");
      } else {
        return "";
      }
    },
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      if (row?.row?.fechaSalida) {
        return format(row?.row?.fechaSalida, "dd-MM-yyyy");
      } else {
        return "";
      }
    },
  },
];
export const mermaColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit,
  setOpenShow
) => [
  {
    field: "codigo",
    headerName: "Código",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.producto?.codigo,
    renderCell: (row) => row.row?.producto?.codigo,
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.producto?.descripcion ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "cantidad",
    headerName: "Cantidad",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.cantidad,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "motivo",
    headerName: "Motivo",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.motivo,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row.fecha ? format(params.row.fecha, "dd-MM-yyyy") : "-",
    renderCell: (row) =>
      row.row.fecha ? format(row.row.fecha, "dd-MM-yyyy") : "-",
  },
  {
    field: "observaciones",
    headerName: "Observaciones",
    flex: 1,
    minWidth: 230,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.observaciones,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenShow(true);
          }}
        >
          <VisibilityIcon style={{ color: "#0086ae" }} />
        </IconButton>
        {/* 
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton> 
        */}
      </div>
    ),
  },
];
export const salidaBodegaShowColumns = () => [
  {
    field: "codigo",
    headerName: "Código",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.producto?.codigo ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.producto?.nombre,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 230,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.producto?.descripcion ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "precio",
    headerName: "Precio",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    valueGetter: (row) => row.row?.precio ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "cantidad",
    headerName: "Cantidad",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    valueGetter: (row) => row.row?.cantidad ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
];
export const ingresoBodegaShowColumns = () => [
  {
    field: "codigo",
    headerName: "Código",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.producto?.codigo,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.producto?.nombre,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 230,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.producto?.descripcion ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "cantidadRecibida",
    headerName: "Cantidad",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    valueGetter: (row) => row.row?.cantidadRecibida ?? "",
    renderCell: (row) => (
      <div style={{ width: "100%", textAlign: "center" }}>
        {row.row?.cantidadRecibida}
      </div>
    ),
  },
];
export const trasladoShowColumns = () => [
  {
    field: "codigo",
    headerName: "Código",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.producto?.codigo,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.producto?.nombre,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.producto?.descripcion ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "cantidad",
    headerName: "Cantidad",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "center",
        }}
      >
        {params.value}
      </Box>
    ),
  },
];
export const trasladoColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit,
  setOpenShow
) => [
  {
    field: "desde",
    headerName: "Desde",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.bodegaOrigen?.nombre ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "hacia",
    headerName: "Hacia",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.bodegaDestino?.nombre ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "encargado",
    headerName: "Encargado",
    flex: 1,
    minWidth: 230,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const nombre = params.row.encardoDeBodega?.nombre ?? "";
      const apellidoPaterno = params.row.encardoDeBodega?.apellidoPaterno ?? "";
      const apellidoMaterno = params.row.encardoDeBodega?.apellidoMaterno ?? "";
      const apellidos =
        params.row.encardoDeBodega?.apellidos ??
        params.row.encardoDeBodega?.apellido ??
        "";
      return `${nombre} ${apellidoPaterno} ${apellidoMaterno} ${apellidos}`;
    },
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "montoTotal",
    headerName: "Monto total (CLP)",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.montoTotal,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const date = params.row?.fecha ? new Date(params.row?.fecha) : "";
      return date ? format(date, "dd/MM/yyyy") : "--";
    },
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "articulos",
    headerName: "Artículos",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row.articulos
        ?.map((articulo) => articulo.producto?.nombre)
        .join(", "),
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenShow(true);
          }}
        >
          <VisibilityIcon style={{ color: "#0086ae" }} />
        </IconButton>
      </div>
    ),
  },
];
export const ingresosColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit,
  setOpenShow
) => [
  {
    field: "bodega",
    headerName: "Bodega",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.bodega?.nombre,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "numeroDocumento",
    headerName: "Documento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.numeroDocumento,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "tipoDocumento",
    headerName: "Tipo documento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.tipoDocumento,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "montoTotal",
    headerName: "Monto total (CLP)",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.montoTotal,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "fechaIngreso",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      try {
        const date = parseISO(params.row?.fechaIngreso);
        return format(date, "dd/MM/yyyy");
      } catch (error) {
        return "Fecha inválida";
      }
    },
    renderCell: (params) => {
      try {
        const date = parseISO(params.row?.fechaIngreso);
        return format(date, "dd/MM/yyyy");
      } catch (error) {
        return "Fecha inválida";
      }
    },
  },
  {
    field: "articulos",
    headerName: "Artículos",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.ingresoArticulos?.map((pab) => pab.producto?.codigo).join(", "),
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.row.ingresoArticulos
          ?.map((pab) => pab.producto?.codigo)
          .join(", ")}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenShow(true);
          }}
        >
          <VisibilityIcon style={{ color: "#0086ae" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        {/* <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton> */}
      </div>
    ),
  },
];
export const salidasColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit,
  setOpenShow
) => [
  {
    field: "bodega",
    headerName: "Bodega",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.bodega?.nombre ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "numeroDocumento",
    headerName: "Documento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.numeroDocumento,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "montoTotal",
    headerName: "Monto total (CLP)",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.montoTotal,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      try {
        const date = parseISO(params.row.fecha);
        return format(date, "dd/MM/yyyy");
      } catch (error) {
        return "Fecha inválida";
      }
    },
    renderCell: (params) => {
      try {
        const date = parseISO(params.row.fecha);
        return format(date, "dd/MM/yyyy");
      } catch (error) {
        return "Fecha inválida";
      }
    },
  },
  {
    field: "articulos",
    headerName: "Artículos",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const salidaArticulos = Array.isArray(params.row.salidaArticulos)
        ? params.row.salidaArticulos
        : []; // Asegúrate de que es un array
      return salidaArticulos
        .map((pab) => pab?.producto?.nombre || "")
        .join(", ");
    },
    renderCell: (params) => {
      const articulos = Array.isArray(params.row.salidaArticulos)
        ? params.row.salidaArticulos
            .map((pab) => pab?.producto?.nombre || "")
            .join(", ")
        : ""; // Si no es array, devuelve cadena vacía

      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {articulos}
        </Box>
      );
    },
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenShow(true);
          }}
        >
          <VisibilityIcon style={{ color: "#0086ae" }} />
        </IconButton>
      </div>
    ),
  },
];
export const espaciosDashboardColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "numero",
    headerName: "ID",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.numero || "",
    renderCell: (params) => params.row?.numero || "",
  },
  {
    field: "Campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.campamento?.nombre || "",
    renderCell: (params) => params.row?.campamento?.nombre || "",
  },
  {
    field: "nombreTrabajador",
    headerName: "Trabajador",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const trabajador = params.row?.espacioItems?.[0]?.trabajador;
      return trabajador &&
        trabajador.nombre &&
        trabajador.apellidoPaterno &&
        trabajador.apellidoMaterno
        ? `${trabajador.nombre} ${trabajador.apellidoPaterno} ${trabajador.apellidoMaterno}`
        : "";
    },
    renderCell: (params) => {
      const trabajador = params.row?.espacioItems?.[0]?.trabajador;
      return trabajador &&
        trabajador.nombre &&
        trabajador.apellidoPaterno &&
        trabajador.apellidoMaterno
        ? `${trabajador.nombre} ${trabajador.apellidoPaterno} ${trabajador.apellidoMaterno}`
        : "";
    },
  },
  {
    field: "espacioItems",
    headerName: "Rut",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.espacioItems?.[0]?.trabajador.rutTrabajador || "",
    renderCell: (params) =>
      params.row?.espacioItems?.[0]?.trabajador.rutTrabajador || "",
  },
  {
    field: "items",
    headerName: "Items",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.espacioItems?.[0]?.items || "",
    renderCell: (params) => {
      const items = params.row?.espacioItems?.[0]?.items || [];
      const displayItems = items.slice(0, 3).join(", ");
      return items.length > 4 ? `${displayItems}...` : displayItems;
    },
  },
  {
    field: "fila",
    headerName: "Fila",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.fila || "",
    renderCell: (params) => params.row?.fila || "",
  },
  {
    field: "nivel",
    headerName: "Nivel",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.nivel || "",
    renderCell: (params) => params.row?.nivel || "",
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    maxWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.estado || "",
    renderCell: (params) =>
      params.row?.estado === "Disponible" ? (
        <span
          style={{
            backgroundColor: "green",
            color: "white",
            borderRadius: "5px",
            padding: "2px 4px",
          }}
        >
          {params.row?.estado}
        </span>
      ) : (
        <span
          style={{
            backgroundColor: "#212227",
            color: "white",
            borderRadius: "5px",
            padding: "2px 4px",
          }}
        >
          {params.row?.estado}
        </span>
      ),
  },
];
export const espaciosBodegaColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit,
  setOpenShow
) => [
  {
    field: "identificador",
    headerName: "Identificador",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.identificador,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "bodega",
    headerName: "Bodega",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.bodega?.nombre,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];
export const bodegaColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.campamento?.nombre,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.nombre,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.descripcion,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "latitud",
    headerName: "Latitud",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.latitud,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "longitud",
    headerName: "Longitud",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.longitud,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];
export const sectorColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.nombre,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.nombre}
      </Box>
    ),
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.descripcion,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.descripcion}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const trazabilidadColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "usuario",
    headerName: "Usuario",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const usuario = row.row?.usuario;
      return usuario ? usuario.nombre + " " + usuario.apellido : "";
    },
    renderCell: (row) => {
      const usuario = row.row?.usuario;
      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {usuario ? usuario.nombre + " " + usuario.apellido : ""}
        </Box>
      );
    },
  },
  {
    field: "accion",
    headerName: "Acción",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const accion = row.row?.accion;
      return accion ?? "";
    },
    renderCell: (row) => {
      const accion = row.row?.accion;
      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {accion ?? ""}
        </Box>
      );
    },
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const fecha = row.row?.fecha;
      return fecha ? format(fecha, "dd-MM-yyyy") : "";
    },
    renderCell: (row) => {
      const fecha = row.row?.fecha;
      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {fecha ? format(fecha, "dd-MM-yyyy") : ""}
        </Box>
      );
    },
  },
  {
    field: "tabla",
    headerName: "Módulo",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const tabla = row.row?.tabla;
      return tabla ?? "";
    },
    renderCell: (row) => {
      const tabla = row.row?.tabla;
      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {tabla ?? ""}
        </Box>
      );
    },
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <IconButton
          onClick={function () {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <VisibilityIcon style={{ color: "#0086ae" }} />
        </IconButton>
      );
    },
  },
];
export const reservasAsignacionesEditColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  {
    field: "trabajador",
    headerName: "RUT",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.trabajador?.rut,
  },
  {
    field: "trabajadorNombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.trabajador?.nombre,
  },
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.campamento?.nombre,
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.wing?.nombre,
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.piso,
  },
  {
    field: "habitacion",
    headerName: "Habitación",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.habitacion?.numero,
  },

  // {
  //   field: "opciones",
  //   headerName: "Opciones",
  //   width: 130,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => {
  //     return (
  //       <div>
  //         <IconButton
  //           onClick={function () {
  //             setSeleItem(params.row);
  //             setOpenEdit(true);
  //           }}
  //         >
  //           <EditIcon style={{ color:"#f19d38" }}/>
  //         </IconButton>
  //       </div>

  //     );
  //   },
  // },
];
export const reservasAsignacionesColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  // {
  //   field: 'checkbox',
  //   headerName: 'Permanente?',
  //   width: 120,
  // },
  {
    field: "rut",
    headerName: "RUT",
    flex: 1,
    minWidth: 90,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.rut,
    renderCell: (row) => row?.row?.rut,
  },
  {
    field: "nombres",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row.nombres ? params.row.nombres : params.row.nombre ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {params.row.nombres ? params.row.nombres : params.row.nombre ?? ""}
      </Box>
    ),
  },
  {
    field: "habitacion",
    headerName: "Habitación",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.habitacion?.numero,
    renderCell: (row) => row?.row?.habitacion?.numero,
  },
  // {
  //   field: "numeroTotalCamas",
  //   headerName: "Camas",
  //   flex:1,
  //   minWidth:100,
  //   headerAlign: "center",
  //   align: "center",
  //   valueGetter: (row) => row?.row?.habitacion?.numeroTotalCamas,
  //   renderCell: (row) => row?.row?.habitacion?.numeroTotalCamas
  // },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.piso,
    renderCell: (row) => row?.row?.piso,
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 90,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row?.row?.pabellon ? row?.row?.habitacion?.pabellon?.identificador : "",
    renderCell: (row) =>
      row?.row?.pabellon ? row?.row?.habitacion?.pabellon?.identificador : "",
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 90,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row?.row?.wing ? row?.row?.habitacion?.wing?.nombre : "",
    renderCell: (row) =>
      row?.row?.wing ? row?.row?.habitacion?.wing?.nombre : "",
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 90,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.jornada,
    renderCell: (row) => row?.row?.jornada,
  },
  {
    field: "turno",
    headerName: "Turno",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.turno?.descripcion,
    renderCell: (row) => row?.row?.turno?.descripcion,
  },
  {
    field: "genero",
    headerName: "Género",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.genero,
    renderCell: (row) => row?.row?.trabajador?.genero,
  },
  {
    field: "tipoCargo",
    headerName: "Tipo Cargo",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.tipoCargo,
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row?.row?.trabajador?.tipoCargo}
      </Box>
    ),
  },
  {
    field: "acreditado",
    headerName: "Acreditación",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row?.row?.trabajador?.acreditado ? "Vigente" : "No vigente",
    renderCell: (row) =>
      row?.row?.trabajador?.acreditado ? (
        <span
          style={{
            color: "#004912",
            background: "#7cfb7c",
            padding: "5px 10px",
            borderRadius: "8px",
          }}
        >
          Vigente
        </span>
      ) : (
        <span
          style={{
            color: "red",
            background: "rgb(255, 207, 207)",
            padding: "3px 10px",
            borderRadius: "10px",
          }}
        >
          No Vigente
        </span>
      ),
  },
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row?.row?.campamento ? row?.row?.campamento?.nombre : "",
    renderCell: (row) =>
      row?.row?.campamento ? row?.row?.campamento?.nombre : "",
  },

  // {
  //   field: "opciones",
  //   headerName: "Opciones",
  //   width: 130,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => {
  //     return (
  //       <div>
  //         <IconButton
  //           onClick={function () {
  //             setSeleItem(params.row);
  //             setOpenEdit(true);
  //           }}
  //         >
  //           <EditIcon style={{ color:"#f19d38" }}/>
  //         </IconButton>
  //       </div>

  //     );
  //   },
  // },
];
export const reservasRecepcionistaColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.wing?.nombre,
  },
  {
    field: "ingresoSalida",
    headerName: "Fecha ingreso y salida",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =>
      row?.row?.fecha_entrada + " - " + row?.row?.fecha_salida,
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "habitacion",
    headerName: "Habitación",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.habitacion?.numero,
  },
  {
    field: "trabajador",
    headerName: "Rut",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.trabajador?.rut,
  },
  {
    field: "trabajadorNombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 230,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.trabajador?.nombre,
  },
  {
    field: "cargo",
    headerName: "Cargo",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.trabajador?.cargo,
  },
  {
    field: "jornada",
    headerName: "jornada",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "turno",
    headerName: "Turno",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.turno?.descripcion,
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const asignacionEspaciosColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit,
  handleCellClick
) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.trabajador?.rut ?? "N/A",
    renderCell: (params) => params.row?.trabajador?.rut ?? "N/A",
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 280,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const nombre = params.row?.trabajador?.nombre ?? "";
      const apellidoPaterno = params.row?.trabajador?.apellidoPaterno ?? "";
      const apellidoMaterno = params.row?.trabajador?.apellidoMaterno ?? "";
      return `${nombre} ${apellidoPaterno} ${apellidoMaterno}`.trim() || "N/A";
    },
    renderCell: (params) => {
      return (
        <div
          style={{
            cursor: "pointer",
            whiteSpace: "normal",
            wordWrap: "break-word",
            overflowWrap: "break-word",
          }}
        >
          {params.value}
        </div>
      );
    },
  },
  {
    field: "items",
    headerName: "Items",
    flex: 1,
    minWidth: 260,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      return params.row?.items ? params.row.items.join(", ") : "N/A";
    },
    renderCell: (params) => {
      const items = params.row?.items ? params.row.items.join(", ") : "N/A";
      return (
        <div
          style={{
            cursor: "pointer",
            whiteSpace: "normal",
            wordWrap: "break-word",
            overflowWrap: "break-word",
          }}
          onClick={() => handleCellClick(items)}
        >
          {items}
        </div>
      );
    },
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.fecha
        ? format(new Date(params.row?.fecha), "dd-MM-yyyy")
        : "-",
    renderCell: (params) =>
      params.row?.fecha
        ? format(new Date(params.row?.fecha), "dd-MM-yyyy")
        : "-",
  },
  {
    field: "numero",
    headerName: "N°",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.espacioAlmacenamiento?.numero ?? "N/A",
    renderCell: (params) => params.row?.espacioAlmacenamiento?.numero ?? "N/A",
  },
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.espacioAlmacenamiento?.campamento?.nombre ?? "N/A",
    renderCell: (params) =>
      params.row?.espacioAlmacenamiento?.campamento?.nombre ?? "N/A",
  },
  {
    field: "fila",
    headerName: "Fila",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.espacioAlmacenamiento?.fila ?? "N/A",
    renderCell: (params) => params.row?.espacioAlmacenamiento?.fila ?? "N/A",
  },
  {
    field: "nivel",
    headerName: "Nivel",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.espacioAlmacenamiento?.nivel ?? "N/A",
    renderCell: (params) => params.row?.espacioAlmacenamiento?.nivel ?? "N/A",
  },
];
export const asignacionEspaciosRetirosColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit,
  handleCellClick
) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.trabajador?.rut ?? "N/A",
    renderCell: (params) => params.value,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 280,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const nombre = params.row?.trabajador?.nombre ?? "";
      const apellidoPaterno = params.row?.trabajador?.apellidoPaterno ?? "";
      const apellidoMaterno = params.row?.trabajador?.apellidoMaterno ?? "";
      return `${nombre} ${apellidoPaterno} ${apellidoMaterno}`.trim() || "N/A";
    },
    renderCell: (params) => (
      <div
        style={{
          cursor: "pointer",
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: "items",
    headerName: "Items",
    flex: 1,
    minWidth: 260,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.items ? params.row.items.join(", ") : "N/A",
    renderCell: (params) => (
      <div
        style={{
          cursor: "pointer",
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflowWrap: "break-word",
        }}
        onClick={() => handleCellClick(params.value)}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.fechaRetiro
        ? format(new Date(params.row?.fechaRetiro), "dd-MM-yyyy")
        : "-",
    renderCell: (params) => params.value,
  },
  {
    field: "numero",
    headerName: "N°",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.espacioAlmacenamiento?.numero ?? "N/A",
    renderCell: (params) => params.value,
  },
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.espacioAlmacenamiento?.campamento?.nombre ?? "N/A",
    renderCell: (params) => params.value,
  },
  {
    field: "fila",
    headerName: "Fila",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.espacioAlmacenamiento?.fila ?? "N/A",
    renderCell: (params) => params.value,
  },
  {
    field: "nivel",
    headerName: "Nivel",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.espacioAlmacenamiento?.nivel ?? "N/A",
    renderCell: (params) => params.value,
  },
];
export const almacenamientoColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.campamento?.nombre,
    renderCell: (row) => row?.row?.campamento?.nombre,
  },
  {
    field: "fila",
    headerName: "Fila",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.value,
    renderCell: (params) => params.value,
  },
  {
    field: "nivel",
    headerName: "Nivel",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.value,
    renderCell: (params) => params.value,
  },
  {
    field: "numero",
    headerName: "ID",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.numero,
    renderCell: (params) => params.row.numero,
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.value,
    renderCell: (params) => (
      <span
        style={{
          padding: "4px 8px",
          borderRadius: "4px",
          color: "#fff",
          backgroundColor: params.value === "Ocupado" ? "#d32f2f" : "#388e3c",
        }}
      >
        {params.value}
      </span>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        {params.row.estado != "Ocupado" && (
          <IconButton
            onClick={() => {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
        )}
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];
export const integridadColumns = () => [
  {
    field: "tabla",
    headerName: "Tabla",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.tabla ?? "",
    renderCell: (row) => row.row.tabla ?? "",
  },
  {
    field: "tieneDatos",
    headerName: "Tiene datos",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => (row.row.tieneDatos ? "Si" : "No"),
    renderCell: (row) => (
      <div
        style={{
          padding: "5px 10px",
          borderRadius: "10px",
          backgroundColor: row.row.tieneDatos ? "green" : "red",
          color: "white",
          textAlign: "center",
          fontWeight: "500",
        }}
      >
        {row.row.tieneDatos ? "Si" : "No"}
      </div>
    ),
  },
];

export const horariosColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.nombre,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.nombre}
      </Box>
    ),
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.descripcion,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.descripcion}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const vistaPersonalColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const gruposHabitaciones = params.row?.gruposHabitaciones || [];
      const campamentos = new Set();

      gruposHabitaciones.forEach((grupo) => {
        grupo?.habitaciones?.forEach((habitacion) => {
          if (habitacion?.pabellon?.campamento?.nombre) {
            campamentos.add(habitacion.pabellon.campamento.nombre);
          }
          if (habitacion?.wing?.campamento?.nombre) {
            campamentos.add(habitacion.wing.campamento.nombre);
          }
        });
      });

      return [...campamentos].join(", ");
    },
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
          maxWidth: "100%",
        }}
      >
        {params.value.split(", ").map((campamento, index) => campamento)}
      </Box>
    ),
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const gruposHabitaciones = params.row?.gruposHabitaciones || [];
      const wingsSet = new Set();

      gruposHabitaciones.forEach((grupo) => {
        grupo?.habitaciones?.forEach((habitacion) => {
          if (habitacion?.wing?.nombre) {
            wingsSet.add(habitacion.wing.nombre);
          }
        });
      });

      return [...wingsSet].join(", ");
    },
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
          maxWidth: "100%",
        }}
      >
        {params.value.split(", ").map((wing, index) => (
          <span key={index} style={{ marginRight: "8px" }}>
            {wing}
          </span>
        ))}
      </Box>
    ),
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const gruposHabitaciones = params.row?.gruposHabitaciones || [];
      const pabellonesSet = new Set();

      gruposHabitaciones.forEach((grupo) => {
        grupo?.habitaciones?.forEach((habitacion) => {
          if (habitacion?.pabellon?.identificador) {
            pabellonesSet.add(habitacion.pabellon.identificador);
          }
        });
      });

      return [...pabellonesSet].join(", ");
    },
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
          maxWidth: "100%",
        }}
      >
        {params.value.split(", ").map((pabellon, index) => (
          <span key={index} style={{ marginRight: "8px" }}>
            {pabellon}
          </span>
        ))}
      </Box>
    ),
  },
  {
    field: "horario",
    headerName: "Horario",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.horarioAseo?.nombre,
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
          maxWidth: "100%",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "rangoHorario",
    headerName: "Rango horario",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const horaInicio = params.row?.horarioAseo?.horaInicio || "";
      const horaTermino = params.row?.horarioAseo?.horaTermino || "";
      return `${horaInicio} - ${horaTermino}`;
    },
    renderCell: (params) => params.value,
  },
  {
    field: "fechaInicioP",
    headerName: "Inicio",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.desde && format(params.row.desde, "dd-MM-yyyy"),
    renderCell: (params) => params.value,
  },
  {
    field: "fechaTerminoP",
    headerName: "Termino",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.hasta && format(params.row.hasta, "dd-MM-yyyy"),
    renderCell: (params) => params.value,
  },
  {
    field: "numeroCamas",
    headerName: "N°Camas",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const gruposHabitaciones = params.row?.gruposHabitaciones || [];
      const totalCamas = gruposHabitaciones.reduce(
        (accumulator, currentGroup) => {
          currentGroup.habitaciones.forEach((habitacion) => {
            accumulator += habitacion.numeroTotalCamas || 0;
          });
          return accumulator;
        },
        0
      );

      return totalCamas;
    },
    renderCell: (params) => params.value,
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <IconButton
        onClick={() => {
          setSeleItem(params.row);
          setOpenEdit(true);
        }}
      >
        <VisibilityIcon style={{ color: "var(--border-blue)" }} />
      </IconButton>
    ),
  },
];
export const vistaPersonalPlanificacionEspecificaColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const pabellonCampamentos =
        params.row?.pabellon
          ?.map((p) => p.campamento?.nombre)
          .filter(Boolean) || [];
      const wingCampamentos =
        params.row?.wing?.map((w) => w.campamento?.nombre).filter(Boolean) ||
        [];
      const campamentoNombres = [
        ...new Set([...pabellonCampamentos, ...wingCampamentos]),
      ];
      return campamentoNombres.join(", ") || "--";
    },
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const wingNombres =
        params.row?.wing?.map((w) => w.nombre).filter(Boolean) || [];
      return [...new Set(wingNombres)].join(", ") || "--";
    },
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const pabellonIdentificadores =
        params.row?.pabellon?.map((p) => p.identificador).filter(Boolean) || [];
      return [...new Set(pabellonIdentificadores)].join(", ") || "--";
    },
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.fecha && format(params.row?.fecha, "dd-MM-yyyy"),
    renderCell: (params) => params.value,
  },
  // {
  //   field: "numeroCamas",
  //   headerName: "N°Camas",
  //   flex: 1,
  //   minWidth: 130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => {
  //     const gruposHabitaciones = params.row?.gruposHabitaciones || [];
  //     const totalCamas = gruposHabitaciones.reduce((accumulator, currentGroup) => {
  //       currentGroup.habitaciones.forEach(habitacion => {
  //         accumulator += habitacion.numeroTotalCamas || 0;
  //       });
  //       return accumulator;
  //     }, 0);

  //     return totalCamas;
  //   }
  // },
  // {
  //   field: "opciones",
  //   headerName: "Opciones",
  //   width: 130,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => (
  //     <IconButton
  //       onClick={() => {
  //         setSeleItem(params.row);
  //         setOpenEdit(true);
  //       }}
  //     >
  //       <VisibilityIcon style={{ color:"var(--border-blue)" }}/>
  //     </IconButton>
  //   )
  // }
];
export const vistaPersonalHabitacionesColumns = (
  setOpen,
  setSeleItem,
  setOpenModalChange,
  setType
) => [
  {
    field: "numero",
    headerName: "N°",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.pabellon?.identificador,
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.wing?.nombre,
  },
  {
    field: "aseoRealizado",
    headerName: "Revisada",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.aseoRealizado ? "Si" : "No"),
  },
  {
    field: "observaciones",
    headerName: "Observación",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.observaciones ?? "",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenModalChange(true);
              setType("habitaciones");
            }}
          >
            <VisibilityIcon style={{ color: "var(--border-blue)" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const vistaPersonalOficinasColumns = (
  setOpen,
  setSeleItem,
  setOpenModalChange,
  setType
) => [
  {
    field: "numero",
    headerName: "N°",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.pabellon?.identificador,
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.wing?.nombre,
  },
  {
    field: "aseoRealizado",
    headerName: "Revisada",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.aseoRealizado ? "Si" : "No"),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenModalChange(true);
              setType("oficinas");
            }}
          >
            <VisibilityIcon style={{ color: "var(--border-blue)" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const vistaPersonalEspaciosColumns = (
  setOpen,
  setSeleItem,
  setOpenModalChange,
  setType
) => [
  {
    field: "numero",
    headerName: "N°",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.pabellon?.identificador,
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.wing?.nombre,
  },
  {
    field: "aseoRealizado",
    headerName: "Revisado",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.aseoRealizado ? "Si" : "No"),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenModalChange(true);
              setType("espacios");
            }}
          >
            <VisibilityIcon style={{ color: "var(--border-blue)" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const planificacionEspecificaColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.nombre,
    renderCell: (params) => (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {params.value ?? ""}
      </div>
    ),
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.fecha,
    renderCell: (params) => {
      try {
        const date = new Date(params.value);
        if (isNaN(date.getTime())) {
          return <div style={{ display: "flex", flexWrap: "wrap" }}></div>;
        } else {
          return (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {format(date, "dd/MM/yyyy")}
            </div>
          );
        }
      } catch (error) {
        return <div style={{ display: "flex", flexWrap: "wrap" }}></div>;
      }
    },
  },
  {
    field: "hora",
    headerName: "Hora",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.fecha,
    renderCell: (params) => {
      try {
        const date = new Date(params.value);
        if (isNaN(date.getTime())) {
          return <div style={{ display: "flex", flexWrap: "wrap" }}></div>;
        } else {
          return (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {format(date, "HH:mm")}
            </div>
          );
        }
      } catch (error) {
        console.error("Error rendering cell:", error);
        return <div style={{ display: "flex", flexWrap: "wrap" }}></div>;
      }
    },
  },
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const row = params.row;
      if (row?.pabellon?.length > 0) {
        return row.pabellon[0]?.campamento?.nombre ?? "";
      } else if (row?.wing?.length > 0) {
        return row.wing[0]?.campamento?.nombre ?? "";
      } else if (row?.edificio?.length > 0) {
        return row.edificio[0]?.campamento?.nombre ?? "";
      }
      return "";
    },
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.wing?.map((wing) => wing?.nombre).join(", ") ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {params.row?.wing?.map((wing) => wing?.nombre).join(", ") ?? ""}
      </Box>
    ),
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.pabellon?.map((pab) => pab?.identificador).join(", ") ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {params.row?.pabellon?.map((pab) => pab?.identificador).join(", ") ??
          ""}
      </Box>
    ),
  },
  {
    field: "edificio",
    headerName: "Recinto",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.edificio?.[0]?.nombre ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "repetirPlanificacion",
    headerName: "Repetir",
    flex: 1,
    minWidth: 240,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 240,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.descripcion,
    renderCell: (params) => (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {params.value ?? ""}
      </div>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];
export const trabajadoresEnOficinaColumns = (removerTrabajadorOficina) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.nombre ?? "",
    renderCell: (row) => row.row?.nombre,
  },
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.rut ?? "",
    renderCell: (row) => row.row?.rut,
  },
  {
    field: "tipo",
    headerName: "Tipo",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.tipo ?? "",
    renderCell: (row) => row.row?.tipo ?? "",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={() => {
              removerTrabajadorOficina(params.row);
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const oficinaColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit,
  setOpenAsignar
) => [
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const pabellonCampamento = row.row?.pabellon?.campamento?.nombre;
      const wingCampamento = row.row?.wing?.campamento?.nombre;
      const edificioCampamento = row.row?.edificio?.campamento?.nombre;
      return pabellonCampamento || wingCampamento || edificioCampamento || "";
    },
    renderCell: (row) => {
      const pabellonCampamento = row.row?.pabellon?.campamento?.nombre;
      const wingCampamento = row.row?.wing?.campamento?.nombre;
      const edificioCampamento = row.row?.edificio?.campamento?.nombre;
      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {pabellonCampamento || wingCampamento || edificioCampamento || ""}
        </Box>
      );
    },
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.wing?.nombre ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.wing?.nombre ?? ""}
      </Box>
    ),
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.pabellon?.identificador ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.pabellon?.identificador ?? ""}
      </Box>
    ),
  },
  {
    field: "recinto",
    headerName: "Recinto",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.edificio?.nombre ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.edificio?.nombre ?? ""}
      </Box>
    ),
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.piso ?? "",
    renderCell: (row) => row.row?.piso,
  },
  {
    field: "numero",
    headerName: "Número",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.numero ?? "",
    renderCell: (row) => row.row?.numero,
  },
  {
    field: "disponibilidad",
    headerName: "Disponibilidad",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.disponibilidad ?? "",
    renderCell: (row) => row.row?.disponibilidad ?? "",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 230,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.descripcion,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.descripcion}
      </Box>
    ),
  },
  {
    field: "chapa",
    headerName: "Chapa",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.chapa ?? "",
    renderCell: (row) => row.row?.chapa,
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={() => {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setSeleItem(params.row);
              setOpenAsignar(true);
            }}
          >
            <AutoModeIcon style={{ color: "#2d3037" }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const oficinaRecepcionestaColumns = (
  setOpenActualizar,
  setOpen,
  setSeleItem,
  setOpenEdit,
  setCheck,
  setOpenAsignar,
  setOpenDelete
) => [
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.wing?.nombre,
    valueGetter: (params) => params.row.wing?.nombre || "",
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.pabellon?.identificador,
    valueGetter: (params) => params.row.pabellon?.identificador || "",
  },
  {
    field: "recinto",
    headerName: "Recinto",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.edificio?.nombre,
    valueGetter: (params) => params.row.edificio?.nombre || "",
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.piso || "",
  },
  {
    field: "numero",
    headerName: "Número",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.numero || "",
  },
  {
    field: "disponibilidad",
    headerName: "Disponibilidad",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      params.value === "Ocupada" ? (
        <span
          style={{
            backgroundColor: "#bd1717",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            fontWeight: "400",
          }}
        >
          {params.value}
        </span>
      ) : params.value === "Disponible" ? (
        <span
          style={{
            backgroundColor: "#afbd22",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            fontWeight: "400",
          }}
        >
          {params.value}
        </span>
      ) : (
        <span
          style={{
            backgroundColor: "#58595b",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            fontWeight: "400",
          }}
        >
          {params.value}
        </span>
      ),
    valueGetter: (params) => params.row.disponibilidad || "",
  },
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.ultimaReserva?.rut,
    valueGetter: (params) => params.row.ultimaReserva?.rut || "",
  },
  {
    field: "Hora_fecha_ocupación",
    headerName: "Inicio y término ocupación",
    flex: 1,
    minWidth: 230,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      const { fechaInicio, fechaTermino } = params.row?.ultimaReserva || {};

      try {
        const inicio = fechaInicio ? parseISO(fechaInicio) : null;
        const termino = fechaTermino ? parseISO(fechaTermino) : null;

        const inicioFormatted = inicio ? format(inicio, "dd/MM/yyyy") : "";
        const terminoFormatted = termino ? format(termino, "dd/MM/yyyy") : "";

        return `${inicioFormatted} - ${terminoFormatted}`;
      } catch (error) {
        console.error("Error al parsear o formatear las fechas:", error);
        return "Fecha inválida";
      }
    },
    valueGetter: (params) => {
      const { fechaInicio, fechaTermino } = params.row?.ultimaReserva || {};

      try {
        const inicio = fechaInicio ? parseISO(fechaInicio) : null;
        const termino = fechaTermino ? parseISO(fechaTermino) : null;

        const inicioFormatted = inicio ? format(inicio, "dd/MM/yyyy") : "";
        const terminoFormatted = termino ? format(termino, "dd/MM/yyyy") : "";

        return `${inicioFormatted} - ${terminoFormatted}`;
      } catch (error) {
        console.error("Error al parsear o formatear las fechas:", error);
        return "Fecha inválida";
      }
    },
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 230,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.descripcion || "",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <RenderCell4
        row={params.row}
        setOpenActualizar={setOpenActualizar}
        setOpen={setOpen}
        setSeleItem={setSeleItem}
        setOpenEdit={setOpenEdit}
        setCheck={setCheck}
        setOpenAsignar={setOpenAsignar}
        setOpenDelete={setOpenDelete}
      />
    ),
  },
];
export const gruposHabitacionesColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.nombre,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.nombre}
      </Box>
    ),
  },
  {
    field: "campamentoName",
    headerName: "Campamento",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.campamentos
        ? row.row.campamentos.map((campamento) => campamento.nombre).join(", ")
        : "", // Retorna una cadena vacía si campamentos es undefined o null
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.campamentos
          ? row.row.campamentos
              .map((campamento) => campamento.nombre)
              .join(", ")
          : ""}
      </Box>
    ),
  },
  {
    field: "wingName",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const wings = params.row?.wings ?? [];
      return wings.length > 0
        ? wings.map((wing) => wing.identificador).join(", ")
        : "----";
    },
    renderCell: (params) => {
      const wings = params.row?.wings ?? [];
      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {wings.length > 0
            ? wings.map((wing) => wing.identificador).join(", ")
            : "----"}
        </Box>
      );
    },
  },
  {
    field: "pabellonName",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const pabellones = params.row?.pabellones ?? [];
      return pabellones.length > 0
        ? pabellones.map((pabellon) => pabellon.identificador).join(", ")
        : "----";
    },
    renderCell: (params) => {
      const pabellones = params.row?.pabellones ?? [];
      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {pabellones.length > 0
            ? pabellones.map((pabellon) => pabellon.identificador).join(", ")
            : "----"}
        </Box>
      );
    },
  },
  {
    field: "recintoName",
    headerName: "Recinto",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const recintos = params.row?.recintos ?? [];
      return recintos.length > 0
        ? recintos.map((recinto) => recinto.nombre).join(", ")
        : "----";
    },
    renderCell: (params) => {
      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value}
        </Box>
      );
    },
  },
  {
    field: "cantidadHabitaciones",
    headerName: "N° Habitaciones",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const pabellones = params.row?.pabellones ?? [];
      const wings = params.row?.wings ?? [];
      const recintos = params.row?.recintos ?? [];
      return pabellones.length || wings.length || recintos.length || 0;
    },
    renderCell: (params) => {
      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value}
        </Box>
      );
    },
  },
  {
    field: "cantidadCamas",
    headerName: "N° Camas",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.camas ?? 0,
    renderCell: (params) => {
      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value}
        </Box>
      );
    },
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];
export const oficinasColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const aseoColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const planificacionDashboardColumns = (
  setOpen,
  setSeleItem,
  setAbrirEdit,
  setAbrirRepetir
) => [
  {
    field: "franja.nombre",
    headerName: "Franja",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.horarioAseo?.nombre ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.horarioAseo?.nombre ?? ""}
      </Box>
    ),
  },
  {
    field: "desde",
    headerName: "Inicio",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const desde = parseISO(params.row?.desde);
      return format(desde, "dd/MM/yyyy");
    },
    renderCell: (params) => {
      if (!params.row?.desde) {
        return "";
      }
      const desde = parseISO(params.row?.desde);
      return format(desde, "dd/MM/yyyy");
    },
  },
  {
    field: "hasta",
    headerName: "Termino",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const hasta = parseISO(params.row?.hasta);
      return format(hasta, "dd/MM/yyyy");
    },
    renderCell: (params) => {
      if (!params.row?.hasta) {
        return "";
      }
      const hasta = parseISO(params.row?.hasta);
      return format(hasta, "dd/MM/yyyy");
    },
  },
  {
    field: "asignados",
    headerName: "Asignados",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "left",
    valueGetter: (params) =>
      params.row?.trabajadores
        ?.map((trabajador) => `${trabajador.rut} (${trabajador.nombre})`)
        .join(", ") ?? "",
    renderCell: (params) => (
      <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {params.value}
      </div>
    ),
  },
];
export const habitacionesNoAsignadasColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  {
    field: "campamentoName",
    headerName: "Campamento",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      if (params.row.pabellon) {
        return params.row.pabellon?.campamento?.nombre ?? "";
      } else if (params.row.wing) {
        return params.row.wing?.campamento?.nombre ?? "";
      } else if (params.row.edificio) {
        return params.row.edificio?.campamento?.nombre ?? "";
      }
      return "";
    },
    renderCell: (params) => params.value,
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.pabellon?.identificador ?? "",
    renderCell: (params) => params.row.pabellon?.identificador ?? "",
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.wing?.nombre ?? "",
    renderCell: (params) => params.row.wing?.nombre ?? "",
  },
  {
    field: "recinto",
    headerName: "Recinto",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.recinto?.nombre ?? "",
    renderCell: (params) => params.row.recinto?.nombre ?? "",
  },
  {
    field: "numero",
    headerName: "Número",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.numero ?? "",
    renderCell: (params) => params.row.numero ?? "",
  },
  // {
  //   field: "n_habitaciones_sin_asignacion",
  //   headerName: "N° Habitaciones",
  //   flex: 1,
  //   minWidth: 230,
  //   headerAlign: "center",
  //   align: "center",
  //   valueGetter: (params) => {
  //     if (params.row.pabellon) {
  //       return params.row.pabellon?.numeroHabitaciones ?? '';
  //     } else if (params.row.wing) {
  //       return params.row.wing?.numeroHabitaciones ?? '';
  //     } else if (params.row.edificio) {
  //       return params.row.edificio?.numeroHabitaciones ?? '';
  //     }
  //     return '';
  //   },
  //   renderCell: (params) => params.value,
  // },
  {
    field: "numeroTotalCamas",
    headerName: "N° Camas",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.numeroTotalCamas ?? "",
    renderCell: (params) => params.row.numeroTotalCamas ?? "",
  },
];
export const planificacionColumns = (
  setOpen,
  setSeleItem,
  setAbrirEdit,
  setAbrirRepetir
) => [
  {
    field: "franja.nombre",
    headerName: "Franja",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.horarioAseo?.nombre,
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "desde",
    headerName: "Inicio",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const desde = params.row?.desde;
      const horaInicio = params.row?.horarioAseo?.horaInicio;
      if (!desde) {
        return "";
      }
      const fecha = format(new Date(desde), "dd/MM/yyyy");
      return `${fecha} ${horaInicio}`;
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "hasta",
    headerName: "Termino",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const hasta = params.row?.hasta;
      const horaTermino = params.row?.horarioAseo?.horaTermino;
      if (!hasta) {
        return "";
      }
      const fecha = format(new Date(hasta), "dd/MM/yyyy");
      return `${fecha} ${horaTermino}`;
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "campamentoName",
    headerName: "Campamento",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      if (
        params.row?.gruposHabitaciones?.length > 0 &&
        params.row.gruposHabitaciones[0]?.habitaciones?.length > 0
      ) {
        const nombreCampamento =
          params.row.gruposHabitaciones[0].habitaciones[0]?.pabellon?.campamento
            ?.nombre ||
          params.row.gruposHabitaciones[0].habitaciones[0]?.wing?.campamento
            ?.nombre;
        return nombreCampamento ? nombreCampamento : "----";
      } else {
        return "----";
      }
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "wingName",
    headerName: "Wing",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      if (params.row?.gruposHabitaciones?.length > 0) {
        const nombresWing = new Set(
          params.row.gruposHabitaciones
            .flatMap((grupo) => grupo.habitaciones || [])
            .map((habitacion) => habitacion.wing?.nombre)
            .filter((nombre) => nombre !== undefined) // Filtrar nombres definidos
        );
        return nombresWing.size > 0
          ? Array.from(nombresWing).join(", ")
          : "----";
      } else {
        return "----";
      }
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "pabellonName",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      if (params.row?.gruposHabitaciones?.length > 0) {
        const numerosPabellon = new Set(
          params.row.gruposHabitaciones
            .flatMap((grupo) => grupo.habitaciones || [])
            .map((habitacion) => habitacion.pabellon?.identificador)
            .filter((identificador) => identificador !== undefined)
        );
        return numerosPabellon.size > 0
          ? Array.from(numerosPabellon).join(", ")
          : "----";
      } else {
        return "----";
      }
    },
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "recintoName",
    headerName: "Recinto",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      if (params.row?.gruposHabitaciones?.length > 0) {
        const nombresRecinto = new Set(
          params.row.gruposHabitaciones
            .flatMap((grupo) => grupo.habitaciones || [])
            .map((habitacion) => habitacion.recinto?.nombre)
            .filter((nombre) => nombre !== undefined) // Filtrar nombres definidos
        );
        return nombresRecinto.size > 0
          ? Array.from(nombresRecinto).join(", ")
          : "----";
      } else {
        return "----";
      }
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "habitaciones",
    headerName: "Total Habitaciones",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      if (!params.row?.gruposHabitaciones) {
        return 0;
      }
      const totalCamas = params.row.gruposHabitaciones.reduce((sum, item) => {
        if (!item.habitaciones) {
          return sum;
        }
        return (
          sum +
          item.habitaciones.reduce(
            (innerSum, habitacion) => innerSum + habitacion.numeroTotalCamas,
            0
          )
        );
      }, 0);
      return totalCamas;
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "asignados",
    headerName: "Asignados",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.trabajadores,
    renderCell: (params) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {params.value?.length > 0
          ? params.value.map((item) => (
              <span key={item.rut}>{`${item.nombre} (${item.rut})`}</span>
            ))
          : "----"}
      </div>
    ),
  },
  {
    field: "tipoAseo",
    headerName: "Tipo aseo",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tipoAseo,
    renderCell: (params) => params.value || "----",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <RenderCell
        row={params.row}
        setOpen={setOpen}
        setSeleItem={setSeleItem}
        setAbrirEdit={setAbrirEdit}
        setAbrirRepetir={setAbrirRepetir}
      />
    ),
  },
];
export const aseoDashboardColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.id,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.id}
      </Box>
    ),
  },
  {
    field: "nombreHuesped",
    headerName: "Nombre del Huésped",
    flex: 1,
    minWidth: 260,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const huesped = params.row?.huesped;
      const nombre = huesped?.nombre ?? "";
      const apellidoPaterno = huesped?.apellidoPaterno ?? "";
      const apellidoMaterno = huesped?.apellidoMaterno ?? "";
      return `${nombre} ${apellidoPaterno} ${apellidoMaterno}`;
    },
    renderCell: (row) => {
      const huesped = row.row?.huesped;
      const nombre = huesped?.nombre ?? "";
      const apellidoPaterno = huesped?.apellidoPaterno ?? "";
      const apellidoMaterno = huesped?.apellidoMaterno ?? "";

      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${nombre} ${apellidoPaterno} ${apellidoMaterno}`}
        </Box>
      );
    },
  },
  {
    field: "especialidad",
    headerName: "Especialidad",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row.criticidadRequerimiento?.especialidad ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.criticidadRequerimiento?.especialidad ?? ""}
      </Box>
    ),
  },
  {
    field: "solicitud",
    headerName: "Solicitud",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row.criticidadRequerimiento?.solicitud ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.criticidadRequerimiento?.solicitud ?? ""}
      </Box>
    ),
  },
  {
    field: "tipo",
    headerName: "Tipo",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.tipo ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.tipo ?? ""}
      </Box>
    ),
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.estado ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.estado ?? ""}
      </Box>
    ),
  },
  {
    field: "asignado",
    headerName: "Asignado",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {params.row?.encargadoASeo
          ? `${params.row.encargadoASeo.nombre} ${params.row.encargadoASeo.apellido}`
          : "-"}
      </Box>
    ),
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.descripcion ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.descripcion ?? ""}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={() => {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
          {/*<IconButton
            onClick={() => {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>*/}
        </div>
      );
    },
  },
];
export const edificiosColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "identificador",
    headerName: "Identificador",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.identificador,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.identificador}
      </Box>
    ),
  },
  {
    field: "generoHuesped",
    headerName: "Huesped",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.generoHuesped,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.generoHuesped}
      </Box>
    ),
  },
  {
    field: "sector.nombre",
    headerName: "Sector",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.sector.nombre,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.sector.nombre ? row.row.sector.nombre : ""}
      </Box>
    ),
  },
  {
    field: "numero",
    headerName: "Numero",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.numero,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.numero ?? ""}
      </Box>
    ),
  },
  {
    field: "numeroPisos",
    headerName: "N°Pisos",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.numeroPisos,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.numeroPisos ?? ""}
      </Box>
    ),
  },
  {
    field: "numeroHabitaciones",
    headerName: "N°Habitaciones",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.numeroHabitaciones,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row.numeroHabitaciones ?? ""}
      </Box>
    ),
  },
  {
    field: "standard.nombre",
    headerName: "Standard",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.standard?.map((standard) => standard.nombre).join(", ") ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.standard?.map((standard, index, array) => (
          <span key={standard.id}>
            {standard.nombre}
            {index < array.length - 1 ? ", " : " "}
          </span>
        ))}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const habitacionGruposPlanificacionRepetirColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit,
  handleDelete
) => [
  // {
  //   field: "numero",
  //   headerName: "Numero",
  //   minWidth:150,
  //   headerAlign: "center",
  //   align: "center",

  // },
  // {
  //   field: "numeroTotalCamas",
  //   headerName: "N°Total Camas",
  //   minWidth:150,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "desde",
    headerName: "Desde",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =>
      row.row?.desde ? format(row.row?.desde, "dd-MM-yyyy") : "",
  },
  {
    field: "hasta",
    headerName: "Hasta",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =>
      row.row?.hasta ? format(row.row?.hasta, "dd-MM-yyyy") : "",
  },
  {
    field: "grupo",
    headerName: "Grupo",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.grupo?.nombre ? row.row?.grupo?.nombre : ""),
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const habitaciones = row.row?.grupo?.habitaciones;
      if (habitaciones && habitaciones.length > 0) {
        return habitaciones.reduce(
          (total, habitacion) => total + habitacion.numeroTotalCamas,
          0
        );
      } else {
        return "";
      }
    },
  },
  {
    field: "pabellonName",
    headerName: "Pabellón",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const habitaciones = row.row?.grupo?.habitaciones;
      const pabellones = row.row?.grupo?.pabellones;

      const identificadoresPabellon = new Set();

      if (pabellones && pabellones.length > 0) {
        pabellones.forEach((pabellon) =>
          identificadoresPabellon.add(pabellon.identificador || pabellon.numero)
        );
      }

      if (habitaciones && habitaciones.length > 0) {
        habitaciones.forEach((habitacion) => {
          if (habitacion.pabellon) {
            identificadoresPabellon.add(
              habitacion.pabellon.numero || habitacion.pabellon.identificador
            );
          }
        });
      }

      return identificadoresPabellon.size > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {Array.from(identificadoresPabellon).join(", ")}
        </Box>
      ) : (
        "----"
      );
    },
  },
  {
    field: "wingName",
    headerName: "Wing",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const habitaciones = row.row?.grupo?.habitaciones;
      const wings = row.row?.grupo?.wings;

      const nombresWing = new Set();

      if (wings && wings.length > 0) {
        wings.forEach((wing) => nombresWing.add(wing.nombre));
      }

      if (habitaciones && habitaciones.length > 0) {
        habitaciones.forEach((habitacion) => {
          if (habitacion.wing) {
            nombresWing.add(habitacion.wing.nombre);
          }
        });
      }

      return nombresWing.size > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {Array.from(nombresWing).join(", ")}
        </Box>
      ) : (
        "----"
      );
    },
  },
  {
    field: "campamentoName",
    headerName: "Campamento",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const habitaciones = row.row?.grupo?.habitaciones;
      if (habitaciones && habitaciones.length > 0) {
        return habitaciones[0]?.pabellon?.campamento?.nombre || "";
      } else if (row.row?.grupo?.campamentos) {
        return row.row?.grupo?.campamentos[0]?.nombre || "";
      } else {
        return "";
      }
    },
  },
  {
    field: "asignado",
    headerName: "Asignado",
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div
        style={{ display: "flex", flexDirection: "column", textAlign: "left" }}
      >
        {params.row?.ruts
          ? params.row.ruts.map((rut, index) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
              >
                <Typography key={index} variant="span">
                  {rut}
                </Typography>
              </Box>
            ))
          : ""}
      </div>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          {/*<IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpenEdit(true);
              }}
            >
              <EditIcon style={{ color:"#f19d38" }}/>
            </IconButton>*/}
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon
              style={{ color: "#d52a2a" }}
              onClick={() => handleDelete(params.row?.id)}
            />
          </IconButton>
        </div>
      );
    },
  },
];
export const trabajadoresColumns = (setOpen, setSeleItem) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nombreCompleto",
    headerName: "Nombre",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "cargo",
  //   headerName: "Cargo",
  //   flex:1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "acreditado",
    headerName: "Acreditación Vigente",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return params.value ? "si" : "no";
    },
  },
];
const obtenerNombreCompleto = (trabajador) => {
  const nombres = trabajador.nombre ?? trabajador.nombres ?? "";
  const apellidoPaterno =
    trabajador.apellidoPaterno ??
    (trabajador.apellidos ? trabajador.apellidos.split(" ")[0] : "");
  const apellidoMaterno =
    trabajador.apellidoMaterno ??
    (trabajador.apellidos && trabajador.apellidos.split(" ").length > 1
      ? trabajador.apellidos.split(" ")[1]
      : "");

  return `${nombres} ${apellidoPaterno} ${apellidoMaterno}`.trim();
};
export const trabajadoresResumenReservaColumns = (setOpen, setSeleItem) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.trabajador?.rut || "N/A",
    renderCell: (params) => params.row.trabajador?.rut || "N/A",
  },
  {
    field: "nombreCompleto",
    headerName: "Nombre",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const trabajador = params.row.trabajador;
      if (trabajador) {
        return obtenerNombreCompleto(trabajador);
      }
      return "N/A";
    },
    renderCell: (params) => {
      const trabajador = params.row.trabajador;
      if (trabajador) {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            {obtenerNombreCompleto(trabajador)}
          </Box>
        );
      }
      return "N/A";
    },
  },
  {
    field: "turno",
    headerName: "Turno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.turno?.descripcion || "N/A",
    renderCell: (params) => params.row.turno?.descripcion || "N/A",
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.jornada || "N/A",
    renderCell: (params) => params.row.jornada || "N/A",
  },
  {
    field: "ingreso",
    headerName: "Ingreso",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row.fechaIngreso
        ? format(new Date(params.row.fechaIngreso), "dd-MM-yyyy")
        : "-",
    renderCell: (params) =>
      params.row.fechaIngreso
        ? format(new Date(params.row.fechaIngreso), "dd-MM-yyyy")
        : "-",
  },
  {
    field: "salida",
    headerName: "Salida",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row.fechaSalida
        ? format(new Date(params.row.fechaSalida), "dd-MM-yyyy")
        : "-",
    renderCell: (params) =>
      params.row.fechaSalida
        ? format(new Date(params.row.fechaSalida), "dd-MM-yyyy")
        : "-",
  },
];
export const trabajadoresAseoColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.rut,
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
          maxWidth: "100%",
        }}
      >
        {row.row?.rut}
      </Box>
    ),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.nombre,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "apellido",
    headerName: "Apellidos",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.apellido,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.email,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "cargo",
    headerName: "Cargo",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.cargo,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "enFaena",
    headerName: "En faena",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => (params.row?.enFaena ? "Sí" : "No"),
    renderCell: (params) => (params.row?.enFaena ? "Si" : "No"),
  },
  {
    field: "suspendido",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => (params.row?.suspendido ? "Suspendido" : "Activo"),
    renderCell: (params) => (params.row?.suspendido ? "Suspendido" : "Activo"),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          {/* <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color:"#f19d38" }}/>
          </IconButton>*/}
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            {/*<DeleteIcon style={{ color:"#d52a2a" }}/>*/}
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const trabajadoresAseoCreateColumns = (
  setOpen,
  setSeleItem,
  handleDeleteItem
) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.rut,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {params?.value}
      </Box>
    ),
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.email,
  },
  {
    field: "nCargo",
    headerName: "Cargo",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.nCargo,
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              handleDeleteItem(params.row.rut);
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const reservaP_externoColumns = (
  setOpen,
  setSeleItem2,
  setOpenEdit,
  setOpenModal
) => [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "fechaSolicitud",
    headerName: "Fecha Solicitud",
    flex: 1,
    minWidth: 140,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row?.row?.fechaSolicitud
        ? format(row?.row?.fechaSolicitud, "dd-MM-yyyy")
        : "",
    renderCell: (row) =>
      row?.row?.fechaSolicitud
        ? format(row?.row?.fechaSolicitud, "dd-MM-yyyy")
        : "",
  },
  {
    field: "vicepresidencia",
    headerName: "Vicepresidencia",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div
        style={{
          whiteSpace: "normal",
          wordBreak: "break-word",
          textAlign: "center",
        }}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: "gerencia",
    headerName: "Gerencia",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div
        style={{
          whiteSpace: "normal",
          wordBreak: "break-word",
          textAlign: "center",
        }}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: "sI_Area",
    headerName: "Si-Area",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div
        style={{
          whiteSpace: "normal",
          wordBreak: "break-word",
          textAlign: "center",
        }}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params.row?.empresa?.razonSocial || "--",
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "trabajadoresDia",
  //   headerName: "N°Trabajadores día",
  //   flex: 1,
  //   minWidth: 160,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "trabajadoresNoche",
  //   headerName: "N°Trabajadores noche",
  //   flex: 1,
  //   minWidth: 180,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "trabajadoresTotales",
    headerName: "N°Trabajadores totales",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.value,
    renderCell: (params) => {
      let color;
      switch (params.value) {
        case "Programada":
          color = "green";
          break;
        case "Pendiente":
          color = "#0086ae";
          break;
        case "Rechazada":
          color = "#bd1717";
          break;
        default:
          color = "gray";
      }

      const badgeStyle = {
        backgroundColor: color,
        color: "white",
        padding: "5px 10px",
        borderRadius: "5px",
        textAlign: "center",
      };

      return <div style={badgeStyle}>{params.value}</div>;
    },
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <RenderCell5
        row={params.row}
        setOpen={setOpen}
        setSeleItem={setSeleItem2}
        setOpenEdit={setOpenEdit}
        setOpenModal={setOpenModal}
      />
    ),
  },
];
const CustomCell = ({ selectedNameByRuts }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {selectedNameByRuts?.length > 0
        ? selectedNameByRuts.map((item) => (
            <span key={item.rut}>
              {`${item.nombre} ${
                item.apellido ?? item.apellidoPaterno ?? ""
              } (${item.rut})`}
            </span>
          ))
        : "----"}
    </div>
  );
};
export const habitacionGruposPlanificacionColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit,
  selectedNameByRuts,
  handleDelete
) => [
  // {
  //   field: "numero",
  //   headerName: "Numero",
  //   minWidth:150,
  //   headerAlign: "center",
  //   align: "center",

  // },
  // {
  //   field: "numeroTotalCamas",
  //   headerName: "N°Total Camas",
  //   minWidth:150,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "grupo",
    headerName: "Grupo",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.grupo?.nombre ? row.row?.grupo?.nombre : ""),
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.grupo?.camas ? row.row?.grupo?.camas : ""),
  },
  {
    field: "pabellonName",
    headerName: "Pabellón",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <>
          {row.row?.grupo?.pabellones?.length > 0 ? (
            <span>{row.row.grupo.pabellones[0].identificador}</span>
          ) : (
            "----"
          )}
        </>
      );
    },
  },
  {
    field: "wingName",
    headerName: "Wing",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <>
          {row.row?.grupo?.wing?.nombre ? (
            <span>{row.row.grupo.wing.nombre}</span>
          ) : (
            "----"
          )}
        </>
      );
    },
  },
  {
    field: "campamentoName",
    headerName: "Campamento",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <>
          {row.row.grupo.campamentos && Array.isArray(row.row.grupo.campamentos)
            ? row.row?.grupo?.campamentos
                .map((campamento) => campamento.nombre)
                .join(", ")
            : "----"}
        </>
      );
    },
  },
  {
    field: "tipo",
    headerName: "Tipo",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.tipo ?? "---",
  },
  {
    field: "asignado",
    headerName: "Asignado",
    minWidth: 500,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <CustomCell selectedNameByRuts={selectedNameByRuts} />
    ),
  },
  // {
  //   field: "asignado",
  //   headerName: "Asignado",
  //   minWidth: 300,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => {
  //     return (
  //       <>
  //         {selectedNameByRuts?.length > 0 ? (
  //           selectedNameByRuts.map((item) => (
  //             <p key={item.rut}>
  //               {`${item.nombre} (${item.rut})`}
  //               <br />
  //             </p>
  //           ))
  //         ) : (
  //           '----'
  //         )}
  //       </>
  //     );
  //   },
  // },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          {/*<IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpenEdit(true);
              }}
            >
              <EditIcon style={{ color:"#f19d38" }}/>
            </IconButton>*/}
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon
              style={{ color: "#d52a2a" }}
              onClick={() => handleDelete(params.row?.id)}
            />
          </IconButton>
        </div>
      );
    },
  },
];
export const habitacionGruposColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "numero",
    headerName: "Numero",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "n_camas",
    headerName: "N°Total Camas",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "pabellonName",
    headerName: "Pabellón",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =>
      row.row?.pabellon?.identificador ? row.row?.pabellon?.identificador : "",
  },
  {
    field: "wingName",
    headerName: "Wing",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.wing?.nombre ? row.row?.wing?.nombre : ""),
  },
  {
    field: "recintoName",
    headerName: "Recinto",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =>
      row.row?.recinto?.nombre ? row.row?.recinto?.nombre : "",
  },
  // {
  //   field: "camapamentoName",
  //   headerName: "Campamento",
  //   minWidth:150,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => (row.row?.pabellon?.campamento.nombre ? row.row?.pabellon?.campamento?.nombre : row.row?.wing?.campamento?.nombre)
  // },
  {
    field: "grupo",
    headerName: "Grupo asignado",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
];
export const tableOficinasGroupColumns = (setOpen, setSeleItem) => [
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      return (
        params.row?.campamento?.nombre ||
        params.row?.pabellon?.campamento?.nombre ||
        params.row?.wing?.campamento?.nombre ||
        params.row?.edificio?.campamento?.nombre ||
        "N/A"
      );
    },
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.wing?.nombre || "N/A",
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.pabellon?.identificador || "N/A",
  },
  {
    field: "recinto",
    headerName: "Recinto",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.recinto?.nombre || "N/A",
  },
  {
    field: "disponibilidad",
    headerName: "disponibilidad",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.disponibilidad || "N/A",
  },
  // {
  //     field: "opciones",
  //     headerName: "Opciones",
  //     flex:1,
  //     minWidth:120,
  //     sortable: false,
  //     headerAlign: "center",
  //     align: "center",
  //     renderCell: (params) => {
  //       return (
  //           <IconButton
  //             onClick={function () {
  //               setSeleItem(params.row);
  //               setOpen(true);
  //             }}
  //           >
  //             <EditIcon style={{ color:"#f19d38" }}/>
  //           </IconButton>
  //       )
  //     },
  //   },
];
export const espaciosGroupColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "pabellonName",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.pabellon?.identificador ?? "",
    renderCell: (params) => params.value,
  },
  {
    field: "wingName",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.wing?.nombre ?? "",
    renderCell: (params) => params.value,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div
        style={{ maxWidth: 200, overflow: "hidden", textOverflow: "ellipsis" }}
      >
        {params.value}
      </div>
    ),
  },
];
export const habitacionHabilitarColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit,
  setOpenAsignar
) => [
  {
    field: "pabellon",
    headerName: "Pabellón",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.pabellon?.identificador ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "wing",
    headerName: "Wing",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.wing?.nombre ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "numero",
    headerName: "Número",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.numero ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "estdo",
    headerName: "Estado",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.habilitada ? "Habilitada" : "Deshabilitada",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "idChapa",
    headerName: "ID chapa",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.idChapa ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "piso",
    headerName: "Piso",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.piso ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "tipoCama",
    headerName: "Tipo cama",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.tipoCama ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.numeroTotalCamas ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "nroCamarotes",
    headerName: "N°Camarotes",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.nroCamarotes ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "nroCamas",
    headerName: "N°Camas",
    minWidth: 30,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.nroCamas ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "tipoHuesped",
    headerName: "Huesped",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.tipoHuesped ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "cargosReservados",
    headerName: "Cargo/s",
    flex: 1,
    minWidth: 500,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.cargosReservados.join(", ") ?? "",
    renderCell: (params) => (
      <div
        style={{
          cursor: "pointer",
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: "standard",
    headerName: "Standard",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.standard?.nombre ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "productos",
    headerName: "Artículos",
    flex: 1,
    minWidth: 500,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.productos?.map((prod) => prod.nombre).join(", ") ?? "",
    renderCell: (params) => (
      <div
        style={{
          cursor: "pointer",
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: "tieneOxigeno",
    headerName: "Oxígeno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return params.row.tieneOxigeno ? (
        <CheckIcon style={{ color: "green" }} />
      ) : (
        <DangerousIcon style={{ color: "red" }} />
      );
    },
  },
];
export const habitacionColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "campamento",
    headerName: "Campamento",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.pabellon
        ? row.row?.pabellon?.campamento?.nombre
        : row.row?.wing?.campamento?.nombre,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.pabellon
          ? row.row?.pabellon?.campamento?.nombre
          : row.row?.wing?.campamento?.nombre}
      </Box>
    ),
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.pabellon?.identificador ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.pabellon?.identificador ?? ""}
      </Box>
    ),
  },
  {
    field: "wing",
    headerName: "Wing",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.wing?.nombre ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.wing?.nombre ?? ""}
      </Box>
    ),
  },
  {
    field: "numero",
    headerName: "Número",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.numero ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.numero ?? ""}
      </Box>
    ),
  },
  {
    field: "idChapa",
    headerName: "ID chapa",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.idChapa ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.idChapa ?? ""}
      </Box>
    ),
  },
  {
    field: "piso",
    headerName: "Piso",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.piso ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.piso ?? ""}
      </Box>
    ),
  },
  {
    field: "tipoCama",
    headerName: "Tipo cama",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.tipoCama ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.tipoCama ?? ""}
      </Box>
    ),
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.numeroTotalCamas ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.numeroTotalCamas ?? ""}
      </Box>
    ),
  },
  {
    field: "nroCamarotes",
    headerName: "N°Camarotes",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.nroCamarotes ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.nroCamarotes ?? ""}
      </Box>
    ),
  },
  {
    field: "nroCamas",
    headerName: "N°Camas",
    minWidth: 30,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.nroCamas ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.nroCamas ?? ""}
      </Box>
    ),
  },
  {
    field: "tipoHuesped",
    headerName: "Huesped",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.tipoHuesped ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.tipoHuesped ?? ""}
      </Box>
    ),
  },
  {
    field: "cargosReservados",
    headerName: "Cargo/s",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.cargosReservados ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.cargosReservados ?? ""}
      </Box>
    ),
  },
  {
    field: "standard",
    headerName: "Standard",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.standard?.nombre ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.standard?.nombre ?? ""}
      </Box>
    ),
  },
  {
    field: "productos",
    headerName: "Artículos",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.productos?.map((prod) => prod.nombre).join(", ") ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.productos?.map((prod) => prod.nombre).join(", ") ?? ""}
      </Box>
    ),
  },
  {
    field: "tieneOxigeno",
    headerName: "Oxígeno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.tieneOxigeno,
    renderCell: (params) => {
      return params.row.tieneOxigeno ? (
        <CheckIcon style={{ color: "green" }} />
      ) : (
        <DangerousIcon style={{ color: "red" }} />
      );
    },
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];
export const motivosColumns = (setOpen, setSeleItem) => [
  // {
  //   field: "id",
  //   headerName: "id",
  //   flex:1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",

  // },
  {
    field: "tipoRequerimiento",
    headerName: "Tipo de requerimiento",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "prioridad",
    headerName: "Prioridad",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
];
export const aprobacionColumns = (setSeleItem, setOpenEdit) => [
  // {
  //   field: "fechaSolicitud",
  //   headerName: "Fecha Solicitud",
  //   flex: 1,
  //   minWidth: 120,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => {
  //     const fecha = new Date(row.row.fechaSolicitud);
  //     const fechaFormateada = fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
  //     return (
  //       <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
  //         {fechaFormateada}
  //       </div>
  //     );
  //   }
  // },
  // {
  //   field: "fechaIngreso",
  //   headerName: "Ingreso",
  //   flex: 1,
  //   minWidth: 120,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => {
  //     const fecha = new Date(row.row.fechaIngreso);
  //     const fechaFormateada = fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
  //     return (
  //       <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
  //         {fechaFormateada}
  //       </div>
  //     );
  //   }
  // },
  // {
  //   field: "fechaSalida",
  //   headerName: "Salida",
  //   flex: 1,
  //   minWidth: 120,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => {
  //     const fecha = new Date(row.row.fechaSalida);
  //     const fechaFormateada = fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
  //     return (
  //       <div style={{ maxWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word' }}>
  //         {fechaFormateada}
  //       </div>
  //     );
  //   }
  // },
  {
    field: "usuario",
    headerName: "Solicitante",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <div
          style={{
            maxWidth: 200,
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {row.row?.usuario
            ? row.row?.usuario.nombre + " " + row.row?.usuario.apellido
            : ""}
        </div>
      );
    },
  },
  {
    field: "emailSolicitante",
    headerName: "Email",
    flex: 1,
    minWidth: 260,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <div
          style={{
            maxWidth: 200,
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {row.row?.usuario ? row.row?.usuario.email : ""}
        </div>
      );
    },
  },
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 260,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <div
          style={{
            maxWidth: 200,
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {row.row?.empresa ? row.row?.empresa.razonSocial : ""}
        </div>
      );
    },
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 150,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <div
        style={{ maxWidth: 200, whiteSpace: "normal", wordWrap: "break-word" }}
      >
        {row.row.estado}
      </div>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <VisibilityIcon style={{ color: "var(--border-blue)" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const feedbackColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit,
  setOpenHistorico
) => [
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.empresa?.razonSocial,
    renderCell: (params) => params.row?.empresa?.razonSocial,
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.tramos?.map((item) => item.contrato).join(", "),
    renderCell: (params) =>
      params.row?.tramos?.map((item, index, array) => (
        <span key={index}>
          {item.contrato}
          {index < array.length - 1 ? ", " : ""}
        </span>
      )),
  },
  {
    field: "trabajadoresDia",
    headerName: "Total Trabajadores Día",
    minWidth: 180,
    flex: 1,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.tramos?.reduce(
        (acc, item) => acc + parseInt(item.trabajadoresDia, 10),
        0
      ),
    renderCell: (params) => {
      const sum = params.row?.tramos?.reduce(
        (acc, item) => acc + parseInt(item.trabajadoresDia, 10),
        0
      );
      return sum;
    },
  },
  {
    field: "trabajadoresNoche",
    headerName: "Total Trabajadores Noche",
    flex: 1,
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.tramos?.reduce(
        (acc, item) => acc + parseInt(item.trabajadoresNoche, 10),
        0
      ),
    renderCell: (params) => {
      const sum = params.row?.tramos?.reduce(
        (acc, item) => acc + parseInt(item.trabajadoresNoche, 10),
        0
      );
      return sum;
    },
  },
  {
    field: "fechaDesde",
    headerName: "Desde",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const fechasDesde = params.row?.tramos
        ?.map((item) => item.fechaDesde && parseISO(item.fechaDesde))
        .filter(Boolean);
      const fechaMinima = fechasDesde.length > 0 ? min(fechasDesde) : null;
      return fechaMinima ? format(fechaMinima, "dd/MM/yyyy") : "";
    },
    renderCell: (params) => {
      const fechasDesde = params.row?.tramos
        ?.map((item) => item.fechaDesde && parseISO(item.fechaDesde))
        .filter(Boolean);
      const fechaMinima = fechasDesde.length > 0 ? min(fechasDesde) : null;
      return fechaMinima ? format(fechaMinima, "dd/MM/yyyy") : "";
    },
  },
  {
    field: "fechaHasta",
    headerName: "Hasta",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const fechasHasta = params.row?.tramos
        ?.map((item) => item.fechaHasta && parseISO(item.fechaHasta))
        .filter(Boolean);
      const fechaMaxima = fechasHasta.length > 0 ? max(fechasHasta) : null;
      return fechaMaxima ? format(fechaMaxima, "dd/MM/yyyy") : "";
    },
    renderCell: (params) => {
      const fechasHasta = params.row?.tramos
        ?.map((item) => item.fechaHasta && parseISO(item.fechaHasta))
        .filter(Boolean);
      const fechaMaxima = fechasHasta.length > 0 ? max(fechasHasta) : null;
      return fechaMaxima ? format(fechaMaxima, "dd/MM/yyyy") : "";
    },
  },
  {
    field: "vicepresidencia",
    headerName: "Vicepresidencia",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.tramos?.map((item) => item.vicepresidencia).join(", "),
    renderCell: (params) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {params.row?.tramos?.map((item, index) => (
          <span key={index}>{item.vicepresidencia}</span>
        ))}
      </div>
    ),
  },
  {
    field: "gerencia",
    headerName: "Gerencia",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.tramos?.map((item) => item.gerencia).join(", "),
    renderCell: (params) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {params.row?.tramos?.map((item, index) => (
          <span key={index}>{item.gerencia}</span>
        ))}
      </div>
    ),
  },
  {
    field: "siArea",
    headerName: "SI Área",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.tramos?.map((item) => item.siArea).join(", "),
    renderCell: (params) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {params?.row?.tramos?.map((item, index) => (
          <span key={index}>{item.siArea}</span>
        ))}
      </div>
    ),
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.estado,
    renderCell: (params) => params.row?.estado,
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <AddCircleOutlineIcon style={{ color: "var(--border-orange)" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenHistorico(true);
          }}
        >
          <VisibilityIcon style={{ color: "var(--border-blue)" }} />
        </IconButton>
      </div>
    ),
  },
];
export const reservasColumns = (setOpen, setSeleItem) => [
  {
    field: "rut",
    headerName: "rut",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nombre",
    headerName: "nombre",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "turno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "fechaIngreso",
    headerName: "Fecha de ingreso",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "fechaSalida",
    headerName: "Fecha de salida",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
];
export const reservasP_externoColumns = (setOpen, setSeleItem, handleDeleteItem) => [
  {
    field: "rut",
    headerName: "rut",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nombreCompleto",
    headerName: "nombre",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row.row?.nombreCompleto
          ? row.row?.nombreCompleto
          : row.row?.nombres
          ? row.row?.nombres
          : row.row?.nombre}
      </Box>
    ),
  },
  {
    field: "apellidos",
    headerName: "Apellidos",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row.row?.apellidos
          ? row.row?.apellidos
          : row.row?.apellido
          ? row.row?.apellido
          : row.row?.apellidoPaterno
          ? `${row.row?.apellidoPaterno} ${row.row?.apellidoMaterno}`
          : "--"}
      </Box>
    ),
  },
  {
    field: "turno",
    headerName: "turno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.turno?.descripcion,
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cargo",
    headerName: "Cargo",
    flex: 1,
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row.row?.cargo?.nCargo
          ? row.row?.cargo?.nCargo
          : row.row?.cargo
          ? row.row?.cargo
          : ""}
      </Box>
    ),
  },
  {
    field: "llegada",
    headerName: "Ingreso",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => {
      const fecha = new Date(params.value);
      const options = { timeZone: "UTC" };
      return fecha.toLocaleDateString("es-ES", options);
    },
  },
  {
    field: "salida",
    headerName: "Salida",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => {
      const fecha = new Date(params.value);
      const options = { timeZone: "UTC" };
      return fecha.toLocaleDateString("es-ES", options);
    },
  },
  {
    field: "genero",
    headerName: "Género",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "telefono",
    headerName: "Teléfono",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 80,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              // setSeleItem(params.row);
              // setOpen(true);
              handleDeleteItem(
                params.row.rut,
                params.row.nombreCompleto
                  ? params.row.nombreCompleto
                  : params.row.nombres
                  ? params.row.nombres
                  : params.row.nombre
                  ? `${params.row.nombre} ${params.row.apellidoPaterno}`
                  : ""
              );
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const espaciosColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "campamento",
    headerName: "Campamento",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.pabellon
        ? row.row?.pabellon?.campamento?.nombre
        : row.row?.wing?.campamento?.nombre,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.pabellon
          ? row.row?.pabellon?.campamento?.nombre
          : row.row?.wing?.campamento?.nombre}
      </Box>
    ),
  },
  {
    field: "pabellonName",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.pabellon?.identificador ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.pabellon?.identificador ?? ""}
      </Box>
    ),
  },
  {
    field: "wingName",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.wing?.nombre ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.wing?.nombre ?? ""}
      </Box>
    ),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.nombre ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.nombre ?? ""}
      </Box>
    ),
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.piso ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.piso ?? ""}
      </Box>
    ),
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.descripcion ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.descripcion ?? ""}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];
export const wingColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.nombre ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.nombre ?? ""}
      </Box>
    ),
  },
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.campamento?.nombre ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.campamento?.nombre ?? ""}
      </Box>
    ),
  },
  {
    field: "standard",
    headerName: "Standard",
    flex: 1,
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.standard?.map((standard) => standard.nombre).join(", ") ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.standard?.map((standard, index, array) => (
          <span key={standard.id}>
            {standard.nombre}
            {index < array.length - 1 ? ", " : " "}
          </span>
        ))}
      </Box>
    ),
  },
  {
    field: "numeroHabitaciones",
    headerName: "N°Habitaciones",
    flex: 1,
    minWidth: 140,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.numeroHabitaciones ?? "",
    renderCell: (row) => row.row?.numeroHabitaciones ?? "",
  },
  {
    field: "generoHuesped",
    headerName: "Huesped",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.generoHuesped ?? "",
    renderCell: (row) => row.row?.generoHuesped ?? "",
  },
  {
    field: "numeroPisos",
    headerName: "N°Pisos",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.numeroPisos ?? "",
    renderCell: (row) => row.row?.numeroPisos ?? "",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {" "}
        {params.value}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];
export const otrosColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "campamentoName",
    headerName: "Campamento",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.campamento?.nombre ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.campamento?.nombre ?? ""}
      </Box>
    ),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.nombre ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.nombre ?? ""}
      </Box>
    ),
  },
  {
    field: "numeroPisos",
    headerName: "N°Pisos",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.numeroPisos ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.numeroPisos ?? ""}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const huespedColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "apellido",
    headerName: "apellido",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "email",
    headerName: "Correo",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cargo",
    headerName: "Cargo",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "genero",
  //   headerName: "Género",
  //   flex: 1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => {
  //     return params.value==1 ? "Femenino" : "Masculino"

  //   }
  // },
  // {
  //   field: "opciones",
  //   headerName: "Opciones",
  //   width: 100,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => {
  //     return (
  //       <div>
  //         <IconButton
  //           onClick={function () {
  //             setSeleItem(params.row);
  //             setOpenEdit(true);
  //           }}
  //         >
  //           <EditIcon style={{ color:"#f19d38" }}/>
  //         </IconButton>
  //       </div>

  //     );
  //   },
  // },
];
export const usersColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.rut,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.rut}
      </Box>
    ),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 250,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.nombre,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.nombre}
      </Box>
    ),
  },
  {
    field: "apellido",
    headerName: "apellido",
    flex: 1,
    minWidth: 250,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.apellido,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.apellido}
      </Box>
    ),
  },
  {
    field: "email",
    headerName: "Correo",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.email,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.email}
      </Box>
    ),
  },
  {
    field: "cargo",
    headerName: "Cargo",
    flex: 1,
    minWidth: 250,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.cargo,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.cargo}
      </Box>
    ),
  },
  {
    field: "rol",
    headerName: "Rol",
    flex: 1,
    minWidth: 320,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.roles?.join(", ") || "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.roles?.join(", ") || ""}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const responsablesColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "problema",
    headerName: "Problema",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.problema,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.problema ?? ""}
      </Box>
    ),
  },
  {
    field: "correo",
    headerName: "Correo",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.correo,
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.correo ?? ""}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const inventarioColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "ordenDeCompra",
    headerName: "Orden",
    flex: 1,
    minWidth: 80,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "guiaDeDespacho",
    headerName: "Guía",
    flex: 1,
    minWidth: 80,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "sku",
    headerName: "SKU",
    flex: 1,
    minWidth: 80,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nombreProveedor",
    headerName: "Proveedor",
    flex: 1,
    minWidth: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "fechaDeRegistro",
    headerName: "Fecha Registro",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => {
      const fecha = new Date(params.value);
      return fecha.toLocaleDateString();
    },
  },
  {
    field: "fechaDeExpiracion",
    headerName: "Fecha Expiración",
    flex: 1,
    minWidth: 140,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => {
      const fecha = new Date(params.value);
      return fecha.toLocaleDateString();
    },
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 60,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "marca",
    headerName: "Marca",
    flex: 1,
    minWidth: 60,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "modelo",
    headerName: "Modelo",
    flex: 1,
    minWidth: 80,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "stockMinimo",
    headerName: "Stock Mínimo",
    flex: 1,
    minWidth: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "stockActual",
    headerName: "Stock Actual",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return params.value ? "Activado" : "Desactivado";
    },
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const tomaInventarioColumns = (setOpen, setSeleItem) => [
  {
    field: "bodega",
    headerName: "Bodega",
    flex: 1,
    minWidth: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.bodega?.nombre ?? "",
    valueGetter: (params) => params.row.bodega?.nombre ?? "",
  },
  {
    field: "cantidad_articulos",
    headerName: "N° Artículos",
    flex: 1,
    minWidth: 200,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const tomaInventarioArticulos = row.row?.tomaInventarioArticulos;
      return tomaInventarioArticulos ? tomaInventarioArticulos.length : "";
    },
    valueGetter: (params) => {
      const tomaInventarioArticulos = params.row.tomaInventarioArticulos;
      return tomaInventarioArticulos ? tomaInventarioArticulos.length : "";
    },
  },
  {
    field: "total_perdida",
    headerName: "Pérdida total",
    flex: 1,
    minWidth: 320,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =>
      row.row?.montoTotal - row.row?.montoTotalTomaInventario >= 0
        ? row.row?.montoTotal - row.row?.montoTotalTomaInventario
        : 0,
    valueGetter: (params) =>
      params.row.montoTotal - params.row.montoTotalTomaInventario >= 0
        ? params.row.montoTotal - params.row.montoTotalTomaInventario
        : 0,
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <IconButton
        onClick={() => {
          setSeleItem(params.row);
          setOpen(true);
        }}
      >
        <AddCircleOutlineIcon style={{ color: "#0086ae" }} />
      </IconButton>
    ),
  },
];
export const articulosTomaColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit,
  setChangeState
) => [
  {
    field: "articulo",
    headerName: "Artículo",
    flex: 1,
    minWidth: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.producto?.nombre ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "cantidadInventariada",
    headerName: "Cantidad Inventariada",
    flex: 1,
    minWidth: 200,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.cantidadInventariada ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "stockMinimo",
    headerName: "Stock",
    flex: 1,
    minWidth: 320,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.producto?.stockMinimo,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
];
export const articulosColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit,
  setChangeState
) => [
  {
    field: "codigo",
    headerName: "Código",
    flex: 1,
    minWidth: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.codigo || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 200,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.nombre || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 320,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.descripcion || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "tiempoDeReposicion",
    headerName: "Días promedio de reposición",
    flex: 1,
    minWidth: 240,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.tiempoDeReposicion || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "duracionMeses",
    headerName: "Duración (meses)",
    flex: 1,
    minWidth: 160,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.duracionMeses || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "stockMinimo",
    headerName: "Stock Mínimo",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.stockMinimo || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "categoria",
    headerName: "Categoría",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.categoria || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const estado = params.row.estado ? "Activado" : "Desactivado";
      return estado;
    },
    renderCell: (params) => {
      const estado = params.row.estado ? "Activado" : "Desactivado";
      const backgroundColor = params.row.estado ? "#afbd22" : "#bd1717";
      return (
        <Box
          sx={{
            backgroundColor: backgroundColor,
            color: "#FFF",
            padding: "5px",
            borderRadius: "8px",
            display: "inline-block",
          }}
        >
          {estado}
        </Box>
      );
    },
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </>
    ),
  },
];
export const solicitudesColumns = (
  setOpen,
  setSeleItem,
  setOpenDetalle,
  setValue
) => [
  {
    field: "fechaSolicitud",
    headerName: "Fecha Solicitud",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaSolicitud);
      const fechaFormateada = fecha.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return (
        <div
          style={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {fechaFormateada}
        </div>
      );
    },
  },
  {
    field: "fechaIngreso",
    headerName: "Ingreso",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaIngreso);
      const fechaFormateada = fecha.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return (
        <div
          style={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {fechaFormateada}
        </div>
      );
    },
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaSalida);
      const fechaFormateada = fecha.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return (
        <div
          style={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {fechaFormateada}
        </div>
      );
    },
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nombreSolicitante",
    headerName: "Solicitante",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const nombre = row.row.nombreSolicitante;
      const apellido = row.row.apellidoSolicitante;
      const nombreCompleto = `${nombre} ${apellido}`;
      return (
        <div
          style={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {nombreCompleto}
        </div>
      );
    },
  },
  {
    field: "emailSolicitante",
    headerName: "Email",
    flex: 1,
    minWidth: 260,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenDetalle(true);
              setValue(1);
            }}
          >
            <AddCircleOutlineIcon style={{ color: "#174300" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const historialSolicitudesColumns = (
  setOpen,
  setSeleItem,
  setOpenDetalle,
  setValue
) => [
  {
    field: "fechaSolicitud",
    headerName: "Fecha Solicitud",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaSolicitud);
      const fechaFormateada = fecha.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return (
        <div
          style={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {fechaFormateada}
        </div>
      );
    },
  },
  {
    field: "fechaIngreso",
    headerName: "Ingreso",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaIngreso);
      const fechaFormateada = fecha.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return (
        <div
          style={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {fechaFormateada}
        </div>
      );
    },
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaSalida);
      const fechaFormateada = fecha.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return (
        <div
          style={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {fechaFormateada}
        </div>
      );
    },
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nombreSolicitante",
    headerName: "Solicitante",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const nombre = row.row.nombreSolicitante;
      const apellido = row.row.apellidoSolicitante;
      const nombreCompleto = `${nombre} ${apellido}`;
      return (
        <div
          style={{
            maxWidth: 200,
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {nombreCompleto}
        </div>
      );
    },
  },
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 260,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const razonSocial = row.row?.empresa?.razonSocial ?? "--";
      return (
        <div
          style={{
            maxWidth: 260,
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {razonSocial}
        </div>
      );
    },
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenDetalle(true);
              setValue(1);
            }}
          >
            <AddCircleOutlineIcon style={{ color: "#174300" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const requerimientosColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "especialidad",
    headerName: "Especialidad",
    flex: 1,
    minWidth: 200,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.especialidad ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.especialidad ?? ""}
      </Box>
    ),
  },
  {
    field: "solicitud",
    headerName: "Solicitud",
    flex: 1,
    minWidth: 400,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.solicitud ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.solicitud ?? ""}
      </Box>
    ),
  },
  {
    field: "prioridad",
    headerName: "Prioridad",
    flex: 1,
    minWidth: 60,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.prioridad ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.prioridad ?? ""}
      </Box>
    ),
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.descripcion ?? "",
    renderCell: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {row.row?.descripcion ?? ""}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const trabajadoresReservaColumns = (setOpen, setSeleItem) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "trabajadorP_INTERNO",
    headerName: "Nombre",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {row.row?.trabajadorP_INTERNO
            ? row.row?.trabajadorP_INTERNO?.nombre +
              " " +
              row.row?.trabajadorP_INTERNO?.apellidoPaterno +
              " " +
              row.row?.trabajadorP_INTERNO?.apellidoMaterno
            : row.row?.trabajadorP_EXTERNO
            ? row.row?.trabajadorP_EXTERNO?.nombre +
              " " +
              row.row?.trabajadorP_EXTERNO?.apellidoPaterno +
              " " +
              row.row?.trabajadorP_EXTERNO?.apellidoMaterno
            : ""}
        </div>
      );
    },
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "turno",
    headerName: "Turno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.turno.descripcion,
  },
  {
    field: "acreditado",
    headerName: "Acreditación Vigente",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {row.row?.trabajadorP_INTERNO
            ? row.row?.trabajadorP_INTERNO?.acreditado
              ? "si"
              : "no"
            : row.row?.trabajadorP_EXTERNO
            ? row.row?.trabajadorP_EXTERNO?.acreditado
              ? "si"
              : "no"
            : ""}
        </div>
      );
    },
  },
];
export const habitacionReservasColumns = (setOpen, setSeleItem) => [
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
        </div>
      );
    },
  },
  // {
  //   field: "pabellonName",
  //   headerName: "Pabellón",
  //   minWidth:150,
  //   headerAlign: "center",
  //   align: "center",

  // },
  // {
  //   field: "wingName",
  //   headerName: "Wing",
  //   minWidth:150,
  //   headerAlign: "center",
  //   align: "center",

  // },
  {
    field: "numero",
    headerName: "Numero",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "piso",
    headerName: "Piso",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    minWidth: 60,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nroCamarotes",
    headerName: "N°camarotes",
    minWidth: 60,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nroCamas",
    headerName: "N°Camas",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tipoHuesped",
    headerName: "Huesped",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cargosReservados",
    headerName: "Cargo/s",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "standard.nombre",
    headerName: "Standard",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row.standard.nombre,
  },
  {
    field: "tieneOxigeno",
    headerName: "Oxígeno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return params.row.tieneOxigeno == true ? (
        <CheckIcon style={{ color: "green" }} />
      ) : (
        <DangerousIcon style={{ color: "red" }} />
      );
    },
  },
];
export const vistaPreviaColumns = (setOpen, setSeleItem) => [
  {
    field: "trabajador.rut",
    headerName: "Rut",
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params.row.trabajador.rut,
  },
  {
    field: "trabajador.nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params.row.trabajador.nombre,
  },
  {
    field: "trabajador.acreditado",
    headerName: "Acreditación vigente",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (params.row.trabajador.acreditado ? "si" : "no"),
  },
  {
    field: "habitacion.numero",
    headerName: "N°Habitacion",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params.row.habitacion.numeroHabitacion,
  },
  // {
  //   field: "habitacion.piso",
  //   headerName: "Piso",
  //   flex:1,
  //   minWidth:200,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => (
  //     params.row.habitacion.piso
  //   )
  // },
  // {
  //   field: "habitacion.pabellonId",
  //   headerName: "Wing/Pabellón",
  //   flex:1,
  //   minWidth:200,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => (
  //     params.row.habitacion.pabellonId
  //   )
  // },
];
const getBadgeColor = (estado) => {
  switch (estado) {
    case "Pendiente":
      return "default";
    case "Aprobada":
      return "success";
    case "Rechazada":
      return "error";
    case "Programada":
      return "primary";
    default:
      return "default";
  }
};
export const estadoReservasHoteleriaColumns = (setOpen, setSeleItem) => [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    minWidth: 60,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "fechaSolicitud",
    headerName: "Fecha Solicitud",
    flex: 1,
    minWidth: 140,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaSolicitud);
      const fechaFormateada = fecha.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return (
        <div
          style={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {fechaFormateada}
        </div>
      );
    },
    valueGetter: (params) => {
      const fecha = new Date(params.row.fechaSolicitud);
      return fecha.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 200,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.empresa ? row.row?.empresa.razonSocial : "-",
    renderCell: (row) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {row.row?.empresa ? row.row?.empresa.razonSocial : "-"}
        </Box>
      );
    },
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.contrato ?? "-",
    renderCell: (row) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {row.row?.contrato ?? "-"}
        </Box>
      );
    },
  },
  {
    field: "nro_trabajadores",
    headerName: "N°Trabajadores",
    flex: 1,
    minWidth: 160,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.trabajadoresTotales || "",
    renderCell: (row) => row.row.trabajadoresTotales,
  },
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <div
          style={{
            maxWidth: 200,
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {row.row.rutSolicitante ?? "-"}
        </div>
      );
    },
    valueGetter: (row) => row.row?.rutSolicitante ?? "-",
  },
  {
    field: "nombreSolicitante",
    headerName: "Solicitante",
    flex: 1,
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const nombre = row.row.nombreSolicitante;
      const apellido = row.row.apellidoSolicitante;
      const nombreCompleto = `${nombre} ${apellido}`;
      return (
        <div
          style={{
            maxWidth: 200,
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {nombreCompleto}
        </div>
      );
    },
    valueGetter: (params) => {
      const nombre = params.row.nombreSolicitante || "";
      const apellido = params.row.apellidoSolicitante || "";
      return `${nombre} ${apellido}`;
    },
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      const estado = params.value;
      return (
        <Chip
          label={estado}
          color={getBadgeColor(estado)}
          style={{ fontWeight: "500" }}
        />
      );
    },
    valueGetter: (params) => params.row.estado || "",
  },
  {
    field: "forecast",
    headerName: "Forecast",
    flex: 1,
    minWidth: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row.forecast || "",
    renderCell: (row) => {
      const estado = row.row.forecast;
      return estado ? "Si" : "No";
    },
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <AddCircleOutlineIcon style={{ color: "#0086ae" }} />
        </IconButton>
      );
    },
  },
];
export const estadoReservasColumns = (setOpen, setSeleItem) => [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    minWidth: 60,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "fechaSolicitud",
    headerName: "Fecha Solicitud",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaSolicitud);
      const fechaFormateada = fecha.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return (
        <div
          style={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {fechaFormateada}
        </div>
      );
    },
    valueGetter: (params) => {
      const fecha = new Date(params.row.fechaSolicitud);
      return fecha.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },
  {
    field: "rut",
    headerName: "Solicitante",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => (row.row?.usuario ? row.row?.usuario?.rut : "-"),
    renderCell: (row) => {
      const rut = row.row?.usuario ? row.row?.usuario?.rut : "-";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {rut}
        </Box>
      );
    },
  },
  {
    field: "fechaIngreso",
    headerName: "Ingreso",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaIngreso);
      const fechaFormateada = fecha.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return (
        <div
          style={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {fechaFormateada}
        </div>
      );
    },
    valueGetter: (params) => {
      const fecha = new Date(params.row.fechaIngreso);
      return fecha.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaSalida);
      const fechaFormateada = fecha.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return (
        <div
          style={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {fechaFormateada}
        </div>
      );
    },
    valueGetter: (params) => {
      const fecha = new Date(params.row.fechaSalida);
      return fecha.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      const estado = params.value;
      return (
        <Chip
          label={estado}
          color={getBadgeColor(estado)}
          style={{ fontWeight: "500" }}
        />
      );
    },
    valueGetter: (params) => params.row.estado || "",
  },
  {
    field: "nombreSolicitante",
    headerName: "Solicitante",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const nombre = row.row.nombreSolicitante;
      const apellido = row.row.apellidoSolicitante;
      const nombreCompleto = `${nombre} ${apellido}`;
      return (
        <div
          style={{
            maxWidth: 200,
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {nombreCompleto}
        </div>
      );
    },
    valueGetter: (params) => {
      const nombre = params.row.nombreSolicitante || "";
      const apellido = params.row.apellidoSolicitante || "";
      return `${nombre} ${apellido}`;
    },
  },
  {
    field: "emailSolicitante",
    headerName: "Email",
    flex: 1,
    minWidth: 260,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <div
          style={{
            maxWidth: 260,
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {row.row.emailSolicitante}
        </div>
      );
    },
    valueGetter: (params) => params.row.emailSolicitante || "",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <AddCircleOutlineIcon style={{ color: "#0086ae" }} />
        </IconButton>
      );
    },
  },
];
export const trabajadoresEstadoReservaColumns = () => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "trabajadorP_INTERNO",
    headerName: "Nombre",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {row.row?.trabajadorP_INTERNO
            ? row.row?.trabajadorP_INTERNO?.nombre +
              " " +
              row.row?.trabajadorP_INTERNO?.apellidoPaterno +
              " " +
              row.row?.trabajadorP_INTERNO?.apellidoMaterno
            : row.row?.trabajadorP_EXTERNO
            ? row.row?.trabajadorP_EXTERNO?.nombre +
              " " +
              row.row?.trabajadorP_EXTERNO?.apellidoPaterno +
              " " +
              row.row?.trabajadorP_EXTERNO?.apellidoMaterno
            : ""}
        </div>
      );
    },
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "turno",
    headerName: "Turno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.turno?.descripcion,
  },
  {
    field: "acreditado",
    headerName: "Acreditación Vigente",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {row.row?.trabajadorP_INTERNO
            ? row.row?.trabajadorP_INTERNO?.acreditado
              ? "si"
              : "no"
            : row.row?.trabajadorP_EXTERNO
            ? row.row?.trabajadorP_EXTERNO?.acreditado
              ? "si"
              : "no"
            : ""}
        </div>
      );
    },
  },
];
export const reclamosSugerenciasHuespedColumns = (setOpen, setSeleItem) => [
  {
    field: "especialidad",
    headerName: "Especialidad",
    flex: 1,
    minWidth: 160,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.criticidadRequerimiento?.especialidad ?? "N/A",
    renderCell: (params) => {
      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value}
        </Box>
      );
    },
  },
  {
    field: "solicitud",
    headerName: "Solicitud",
    flex: 1,
    minWidth: 10,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.criticidadRequerimiento?.solicitud ?? "N/A",
    renderCell: (params) => {
      return (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value}
        </Box>
      );
    },
  },
  // {
  //   field: "prioridad",
  //   headerName: "Prioridad",
  //   flex: 1,
  //   minWidth: 120,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   valueGetter: (params) => params.row?.criticidadRequerimiento?.prioridad ?? 'N/A',
  //   renderCell: (params) => params.value,
  // },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 220,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.descripcion ?? "N/A",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.estado ?? "N/A",
    renderCell: (params) => params.value,
  },
  {
    field: "tipo",
    headerName: "Tipo",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tipo ?? "N/A",
    renderCell: (params) => params.value,
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <VisibilityIcon style={{ color: "#0086ae" }} />
        </IconButton>
      );
    },
  },
];
export const reclamosSugerenciasRecepcionistaColumns = (
  setOpen,
  setSeleItem,
  setOpenShow
) => [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    minWidth: 60,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.id,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row.habitacion?.pabellon?.campamento?.nombre ||
      params.row.habitacion?.wing?.campamento?.nombre ||
      params.row.habitacion?.edificio?.campamento?.nombre ||
      "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.habitacion?.wing?.nombre || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row.habitacion?.pabellon?.identificador || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "edificio",
    headerName: "Edificio",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.habitacion?.edificio?.nombre || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "criticidadRequerimiento.solicitud",
    headerName: "Solicitud",
    flex: 1,
    minWidth: 320,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row.criticidadRequerimiento?.solicitud || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "habitacion",
    headerName: "Habitación",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.habitacion?.numero || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "huesped",
    headerName: "Usuario",
    flex: 1,
    minWidth: 220,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      `${params.row.huesped?.nombre || ""} ${
        params.row.huesped?.apellidoPaterno || ""
      } ${params.row.huesped?.apellidoMaterno || ""}`,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 220,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.descripcion || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.estado || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "tipo",
    headerName: "Tipo",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.tipo || "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <>
          <IconButton
            onClick={() => {
              setSeleItem(params.row);
              setOpenShow(true);
            }}
          >
            <VisibilityIcon style={{ color: "#0086ae" }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
        </>
      );
    },
  },
];
export const tableOficinasDashboardRecepcionistaColumns = (
  setOpen,
  setSeleItem
) => [
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      return (
        params.row?.campamento?.nombre ||
        params.row?.pabellon?.campamento?.nombre ||
        params.row?.wing?.campamento?.nombre ||
        params.row?.edificio?.campamento?.nombre ||
        "N/A"
      );
    },
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.wing?.nombre || "N/A",
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.pabellon?.identificador || "N/A",
  },
  {
    field: "recinto",
    headerName: "Recinto",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.recinto?.nombre || "N/A",
  },
  {
    field: "totalOficinas",
    headerName: "Oficinas totales",
    flex: 1,
    minWidth: 220,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "totalOficinasDisponibles",
    headerName: "Oficinas disponibles",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  // {
  //     field: "opciones",
  //     headerName: "Opciones",
  //     flex:1,
  //     minWidth:120,
  //     sortable: false,
  //     headerAlign: "center",
  //     align: "center",
  //     renderCell: (params) => {
  //       return (
  //           <IconButton
  //             onClick={function () {
  //               setSeleItem(params.row);
  //               setOpen(true);
  //             }}
  //           >
  //             <EditIcon style={{ color:"#f19d38" }}/>
  //           </IconButton>
  //       )
  //     },
  //   },
];
export const solicitudesHuespedColumns = (setOpen, setSeleItem) => [
  {
    field: "numeroHabitacion",
    headerName: "N° Habitación",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.habitacion?.numero ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "identificadorPabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.habitacion?.pabellon?.identificador ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "nombreWing",
    headerName: "Wing",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.habitacion?.wing?.nombre ?? "--",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "nombreCampamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.habitacion?.pabellon?.campamento?.nombre ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.estado ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "tipoJornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.turno?.tipoJornada ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "descripcionTurno",
    headerName: "Turno",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.turno?.descripcion ?? "",
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  // {
  //   field: "opciones",
  //   headerName: "Opciones",
  //   flex: 1,
  //   minWidth: 120,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => {
  //     return (
  //       <div>
  //         <IconButton
  //           onClick={function () {
  //             setSeleItem(params.row);
  //             setOpen(true);
  //           }}
  //         >
  //           <AddCircleOutlineIcon style={{ color: "#174300" }} />
  //         </IconButton>
  //       </div>
  //     );
  //   },
  // },
];
export const tramosP_INTERNOColumns = (setOpen, setSeleItem) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <div
        style={{ maxWidth: 200, whiteSpace: "normal", wordWrap: "break-word" }}
      >
        {row.row.nombre ?? ""}
      </div>
    ),
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <div
        style={{ maxWidth: 200, whiteSpace: "normal", wordWrap: "break-word" }}
      >
        {row.row.contrato ?? ""}
      </div>
    ),
  },
  {
    field: "vicepresidencia",
    headerName: "Vicepresidencia",
    flex: 1,
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <div
        style={{ maxWidth: 200, whiteSpace: "normal", wordWrap: "break-word" }}
      >
        {row.row.vicepresidencia ?? ""}
      </div>
    ),
  },
  {
    field: "gerencia",
    headerName: "Gerencia",
    flex: 1,
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <div
        style={{ maxWidth: 200, whiteSpace: "normal", wordWrap: "break-word" }}
      >
        {row.row.gerencia ?? ""}
      </div>
    ),
  },
  {
    field: "siArea",
    headerName: "SI Area",
    flex: 1,
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <div
        style={{ maxWidth: 200, whiteSpace: "normal", wordWrap: "break-word" }}
      >
        {row.row.siArea ?? ""}
      </div>
    ),
  },
  {
    field: "fechaDesde",
    headerName: "Desde",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <div
        style={{ maxWidth: 200, whiteSpace: "normal", wordWrap: "break-word" }}
      >
        {row.row.fechaDesde ? format(row.row.fechaDesde, "dd-MM-yyyy") : ""}
      </div>
    ),
  },
  {
    field: "fechaHasta",
    headerName: "Hasta",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <div
        style={{ maxWidth: 200, whiteSpace: "normal", wordWrap: "break-word" }}
      >
        {row.row.fechaHasta ? format(row.row.fechaHasta, "dd-MM-yyyy") : ""}
      </div>
    ),
  },
  {
    field: "trabajadoresDia",
    headerName: "N°Trabajadores dia",
    flex: 1,
    minWidth: 220,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "trabajadoresNoche",
    headerName: "N°Trabajadores Noche",
    flex: 1,
    minWidth: 220,
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "acreditado",
  //   headerName: "Acreditación Vigente",
  //   flex:1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => {
  //     return (
  //         <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
  //             {row.row?.trabajadorP_INTERNO ? row.row?.trabajadorP_INTERNO?.acreditado ? 'si':'no' : row.row?.trabajadorP_EXTERNO ? row.row?.trabajadorP_EXTERNO?.acreditado ? 'si' : 'no' : '' }
  //         </div>
  //     );
  // }
  // },
];
export const trabajadoresReservaP_INTERNOColumns = (setOpen, setSeleItem) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "trabajadorP_INTERNO",
    headerName: "Nombre",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {row.row?.trabajadorP_INTERNO
            ? row.row?.trabajadorP_INTERNO?.nombre +
              " " +
              row.row?.trabajadorP_INTERNO?.apellidoPaterno +
              " " +
              row.row?.trabajadorP_INTERNO?.apellidoMaterno
            : row.row?.trabajadorP_EXTERNO
            ? row.row?.trabajadorP_EXTERNO?.nombre +
              " " +
              row.row?.trabajadorP_EXTERNO?.apellidoPaterno +
              " " +
              row.row?.trabajadorP_EXTERNO?.apellidoMaterno
            : ""}
        </div>
      );
    },
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "turno",
    headerName: "Turno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.turno?.descripcion,
  },
  {
    field: "acreditado",
    headerName: "Acreditación Vigente",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {row.row?.trabajadorP_INTERNO
            ? row.row?.trabajadorP_INTERNO?.acreditado
              ? "si"
              : "no"
            : row.row?.trabajadorP_EXTERNO
            ? row.row?.trabajadorP_EXTERNO?.acreditado
              ? "si"
              : "no"
            : ""}
        </div>
      );
    },
  },
];
const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const formattedDate = date.toLocaleDateString();
  return formattedDate;
};
export const reservasResepcionistaDashboardColumns = (setOpen, setSeleItem) => [
  // {
  //   field: "viceprecidencia",
  //   headerName: "Viceprecidencia",
  //   flex:1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "gerencia",
  //   headerName: "Gerencia",
  //   flex:1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "siArea",
  //   headerName: "Si-área",
  //   flex:1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "empresa",
  //   headerName: "Empresa",
  //   flex:1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "contrato",
  //   headerName: "Contrato",
  //   flex:1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "numero_trabajadores",
  //   headerName: "N°trabajadores",
  //   flex:1,
  //   minWidth:220,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "numero_trabajadores_dia",
  //   headerName: "N°trabajadores dia",
  //   flex:1,
  //   minWidth:220,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "numero_trabajadores_noche",
  //   headerName: "N°trabajadores noche",
  //   flex:1,
  //   minWidth:220,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "fechaSolicitud",
    headerName: "Fecha solicitud",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaSolicitud),
  },
  {
    field: "fechaIngreso",
    headerName: "Ingreso",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaIngreso),
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaSalida),
  },
  {
    field: "nombreSolicitante",
    headerName: "Solicitante",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "observaciones",
    headerName: "Observaciones",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <RenderCell3
        row={params.row}
        setOpen={setOpen}
        setSeleItem={setSeleItem}
      />
    ),
  },
];
export const reservasP_INTERNOColumns = (setOpen, setSeleItem) => [
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.wing?.nombre ?? "",
    renderCell: (row) => row.row?.wing?.nombre ?? "",
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.piso : "",
    renderCell: (row) => (row.row?.habitacion ? row.row?.habitacion?.piso : ""),
  },
  {
    field: "habitacion",
    headerName: "Habitación",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.numero : "",
    renderCell: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.numero : "",
  },
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => (row.row?.trabajador ? row.row?.trabajador.rut : ""),
    renderCell: (row) => (row.row?.trabajador ? row.row?.trabajador.rut : ""),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.trabajador
        ? row.row?.trabajador.nombre + " " + row.row?.trabajador.apellido
        : "",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row.row?.trabajador
          ? row.row?.trabajador.nombre + " " + row.row?.trabajador.apellido
          : ""}
      </Box>
    ),
  },
  {
    field: "cargo",
    headerName: "Cargo",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.trabajador ? row.row?.trabajador.cargo : "",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row.row?.trabajador ? row.row?.trabajador.cargo : ""}
      </Box>
    ),
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.trabajador ? row.row?.trabajador.jornada : "",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row.row?.trabajador ? row.row?.trabajador.jornada : ""}
      </Box>
    ),
  },
  {
    field: "turno",
    headerName: "Turno",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.trabajador ? row.row?.trabajador.turno : "",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row.row?.trabajador ? row.row?.trabajador.turno : ""}
      </Box>
    ),
  },
  {
    field: "permanente",
    headerName: "Permanente",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => (row.row?.permanente ? "Si" : "No"),
    renderCell: (row) => (row.row?.permanente ? "Si" : "No"),
  },
  // {
  //   field: "opciones",
  //   headerName: "Opciones",
  //   flex: 1,
  //   minWidth: 120,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => {
  //     return (
  //       <IconButton
  //         onClick={() => {
  //           setSeleItem(params.row);
  //           setOpen(true);
  //         }}
  //       >
  //         <AddCircleOutlineIcon style={{ color: "#FFF" }} />
  //       </IconButton>
  //     );
  //   },
  // },
];
export const reservasResepcionistaDashboardV2Columns = (
  setOpen,
  setSeleItem
) => [
  {
    field: "fechaSolicitud",
    headerName: "Fecha solicitud",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaSolicitud),
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "fechaIngreso",
    headerName: "Ingreso",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaIngreso),
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaSalida),
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "nombreSolicitante",
    headerName: "Solicitante",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row.nombreSolicitante + " " + params.row.apellidoSolicitante,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "observaciones",
    headerName: "Observaciones",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.observaciones,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.estado,
    renderCell: (params) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <AddCircleOutlineIcon style={{ color: "#0086ae" }} />
        </IconButton>
      );
    },
  },
];
export const reservasP_INTERNOGeneralColumns = (setOpen, setSeleItem) => [
  {
    field: "fechaSolicitud",
    headerName: "Fecha solicitud",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaSolicitud),
  },
  {
    field: "fechaIngreso",
    headerName: "Ingreso",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaIngreso),
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaSalida),
  },
  {
    field: "nombreSolicitante",
    headerName: "Solicitante",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row.nombreSolicitante + " " + params.row.apellidoSolicitante,
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {params.row.nombreSolicitante + " " + params.row.apellidoSolicitante}
      </Box>
    ),
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.estado,
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <AddCircleOutlineIcon style={{ color: "#FFF" }} />
        </IconButton>
      );
    },
  },
];
export const reservasTrabajadadoresNoAsignadosRecepcionistaColumns = () => [
  {
    field: "rut",
    headerName: "RUT",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.rut,
    renderCell: (row) => row?.row?.trabajador?.rut,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row?.row?.trabajador?.nombre ?? row?.row?.trabajador?.nombres ?? "--",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row?.row?.trabajador?.nombre ?? row?.row?.trabajador?.nombres ?? "--"}
      </Box>
    ),
  },
  {
    field: "apellidos",
    headerName: "Apellidos",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const apellidoPaterno =
        row?.row?.trabajador?.apellidoPaterno ??
        row?.row?.trabajador?.apellidos ??
        "-";
      const apellidoMaterno = row?.row?.trabajador?.apellidoMaterno ?? "";

      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {`${apellidoPaterno} ${apellidoMaterno}`.trim()}
        </Box>
      );
    },
  },
  {
    field: "genero",
    headerName: "Género",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.trabajador?.genero,
  },
  {
    field: "tipoCargo",
    headerName: "Tipo Cargo",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.tipoCargo,
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row?.row?.trabajador?.tipoCargo}
      </Box>
    ),
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 90,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "turno",
    headerName: "Turno",
    flex: 1,
    minWidth: 90,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.turno?.descripcion,
  },
  {
    field: "acreditado",
    headerName: "Acreditación",
    flex: 1,
    minWidth: 110,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =>
      row?.row?.trabajador?.acreditado ? (
        <span
          style={{
            color: "#004912",
            background: "#7cfb7c",
            padding: "5px 10px",
            borderRadius: "8px",
          }}
        >
          Vigente
        </span>
      ) : (
        <span
          style={{
            color: "red",
            background: "rgb(255, 207, 207)",
            padding: "3px 10px",
            borderRadius: "10px",
          }}
        >
          No Vigente
        </span>
      ),
  },
  // {
  //   field: "Habitación",
  //   headerName: "Habitación",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.numero : '--'
  // },
  // {
  //   field: "piso",
  //   headerName: "Piso",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.piso : '--'
  // },
  // {
  //   field: "pabellon",
  //   headerName: "Pabellón",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.pabellon ? row?.row?.habitacion.pabellon?.identificador : '--' : '--'
  // },
  // {
  //   field: "wing",
  //   headerName: "Wing",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.wing ? row?.row?.habitacion.wing?.nombre : '--' : '--'
  // },
  {
    field: "fechaIngreso",
    headerName: "Llegada",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => formatDate(row?.row?.fechaIngreso),
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => formatDate(row?.row?.fechaSalida),
  },
];
export const reservasTrabajadadoresRecepcionistaColumns = (
  setOpenAsignar,
  setSeleItemTrab
) => [
  {
    field: "rut",
    headerName: "RUT",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.rut,
    renderCell: (row) => row?.row?.trabajador?.rut,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row?.row?.trabajador?.nombre ?? row?.row?.trabajador?.nombres ?? "--",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {" "}
        {row?.row?.trabajador?.nombre ?? row?.row?.trabajador?.nombres ?? "--"}
      </Box>
    ),
  },
  {
    field: "apellidos",
    headerName: "Apellidos",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const apellidoPaterno =
        row?.row?.trabajador?.apellidoPaterno ??
        row?.row?.trabajador?.apellidos ??
        "-";
      const apellidoMaterno = row?.row?.trabajador?.apellidoMaterno ?? "";
      return `${apellidoPaterno} ${apellidoMaterno}`.trim();
    },
    renderCell: (row) => {
      const apellidoPaterno =
        row?.row?.trabajador?.apellidoPaterno ??
        row?.row?.trabajador?.apellidos ??
        "-";
      const apellidoMaterno = row?.row?.trabajador?.apellidoMaterno ?? "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {`${apellidoPaterno} ${apellidoMaterno}`.trim()}
        </Box>
      );
    },
  },
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.empresa && (
        <span>{row.row?.empresa ? row.row?.empresa.razonSocial : "-"}</span>
      ),
    renderCell: (row) =>
      row.row?.empresa && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          <span>{row.row?.empresa ? row.row?.empresa.razonSocial : "-"}</span>
        </Box>
      ),
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.contrato && <span>{row?.row.contrato}</span>,
    renderCell: (row) =>
      row.row?.contrato && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          <span>{row.row?.contrato ?? row.row?.contrato}</span>
        </Box>
      ),
  },
  {
    field: "habitacion",
    headerName: "Habitación",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.habitacion?.numero || "",
    renderCell: (params) => params.row?.habitacion?.numero || "",
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.habitacion?.piso || "",
    renderCell: (params) => params.row?.habitacion?.piso || "",
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.habitacion?.pabellon?.identificador || "",
    renderCell: (params) =>
      params.row?.habitacion?.pabellon?.identificador || "",
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.habitacion?.wing?.nombre || "",
    renderCell: (params) => params.row?.habitacion?.wing?.nombre || "",
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.jornada,
    renderCell: (row) => row?.row?.jornada,
  },
  {
    field: "turno",
    headerName: "Turno",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.turno?.descripcion,
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row?.row?.turno?.descripcion}
      </Box>
    ),
  },
  {
    field: "acreditado",
    headerName: "Acreditación",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =>
      row?.row?.trabajador?.acreditado ? (
        <span
          style={{
            color: "#004912",
            background: "#7cfb7c",
            padding: "5px 10px",
            borderRadius: "8px",
          }}
        >
          Vigente
        </span>
      ) : (
        <span
          style={{
            color: "red",
            background: "rgb(255, 207, 207)",
            padding: "3px 10px",
            borderRadius: "10px",
          }}
        >
          No Vigente
        </span>
      ),
  },
  {
    field: "tipoCargo",
    headerName: "Tipo Cargo",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.tipoCargo,
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row?.row?.trabajador?.tipoCargo}
      </Box>
    ),
  },
  {
    field: "genero",
    headerName: "Género",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.genero,
    renderCell: (row) => row?.row?.trabajador?.genero,
  },
  // {
  //   field: "Habitación",
  //   headerName: "Habitación",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.numero : '--'
  // },
  // {
  //   field: "piso",
  //   headerName: "Piso",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.piso : '--'
  // },
  // {
  //   field: "pabellon",
  //   headerName: "Pabellón",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.pabellon ? row?.row?.habitacion.pabellon?.identificador : '--' : '--'
  // },
  // {
  //   field: "wing",
  //   headerName: "Wing",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.wing ? row?.row?.habitacion.wing?.nombre : '--' : '--'
  // },
  {
    field: "fechaIngreso",
    headerName: "Llegada",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => formatDate(row?.row?.fechaIngreso),
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => formatDate(row?.row?.fechaSalida),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <IconButton
          onClick={function () {
            setSeleItemTrab(params.row);
            setOpenAsignar(true);
          }}
        >
          <AutoModeIcon style={{ color: "#2d3037" }} />
        </IconButton>
      );
    },
  },
];
export const reservasTrabajadadoresDetallesColumns = (
  setOpenAsignar,
  setSeleItemTrab
) => [
  {
    field: "rut",
    headerName: "RUT",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.rut,
    renderCell: (row) => row?.row?.trabajador?.rut,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row?.row?.trabajador?.nombre ?? row?.row?.trabajador?.nombres ?? "--",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {" "}
        {row?.row?.trabajador?.nombre ?? row?.row?.trabajador?.nombres ?? "--"}
      </Box>
    ),
  },
  {
    field: "apellidos",
    headerName: "Apellidos",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const apellidoPaterno =
        row?.row?.trabajador?.apellidoPaterno ??
        row?.row?.trabajador?.apellidos ??
        "-";
      const apellidoMaterno = row?.row?.trabajador?.apellidoMaterno ?? "";
      return `${apellidoPaterno} ${apellidoMaterno}`.trim();
    },
    renderCell: (row) => {
      const apellidoPaterno =
        row?.row?.trabajador?.apellidoPaterno ??
        row?.row?.trabajador?.apellidos ??
        "-";
      const apellidoMaterno = row?.row?.trabajador?.apellidoMaterno ?? "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {`${apellidoPaterno} ${apellidoMaterno}`.trim()}
        </Box>
      );
    },
  },
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.empresa && (
        <span>{row.row?.empresa ? row.row?.empresa.razonSocial : "-"}</span>
      ),
    renderCell: (row) =>
      row.row?.empresa && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          <span>{row.row?.empresa ? row.row?.empresa.razonSocial : "-"}</span>
        </Box>
      ),
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.contrato && <span>{row?.row.contrato}</span>,
    renderCell: (row) =>
      row.row?.contrato && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          <span>{row.row?.contrato ?? row.row?.contrato}</span>
        </Box>
      ),
  },
  {
    field: "habitacion",
    headerName: "Habitación",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.habitacion?.numero || "",
    renderCell: (params) => params.row?.habitacion?.numero || "",
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.habitacion?.piso || "",
    renderCell: (params) => params.row?.habitacion?.piso || "",
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) =>
      params.row?.habitacion?.pabellon?.identificador || "",
    renderCell: (params) =>
      params.row?.habitacion?.pabellon?.identificador || "",
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.habitacion?.wing?.nombre || "",
    renderCell: (params) => params.row?.habitacion?.wing?.nombre || "",
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.jornada,
    renderCell: (row) => row?.row?.jornada,
  },
  {
    field: "turno",
    headerName: "Turno",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.turno?.descripcion,
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row?.row?.turno?.descripcion}
      </Box>
    ),
  },
  {
    field: "acreditado",
    headerName: "Acreditación",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =>
      row?.row?.trabajador?.acreditado ? (
        <span
          style={{
            color: "#004912",
            background: "#7cfb7c",
            padding: "5px 10px",
            borderRadius: "8px",
          }}
        >
          Vigente
        </span>
      ) : (
        <span
          style={{
            color: "red",
            background: "rgb(255, 207, 207)",
            padding: "3px 10px",
            borderRadius: "10px",
          }}
        >
          No Vigente
        </span>
      ),
  },
  {
    field: "tipoCargo",
    headerName: "Tipo Cargo",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.tipoCargo,
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row?.row?.trabajador?.tipoCargo}
      </Box>
    ),
  },
  {
    field: "genero",
    headerName: "Género",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.genero,
    renderCell: (row) => row?.row?.trabajador?.genero,
  },
  // {
  //   field: "Habitación",
  //   headerName: "Habitación",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.numero : '--'
  // },
  // {
  //   field: "piso",
  //   headerName: "Piso",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.piso : '--'
  // },
  // {
  //   field: "pabellon",
  //   headerName: "Pabellón",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.pabellon ? row?.row?.habitacion.pabellon?.identificador : '--' : '--'
  // },
  // {
  //   field: "wing",
  //   headerName: "Wing",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.wing ? row?.row?.habitacion.wing?.nombre : '--' : '--'
  // },
  {
    field: "fechaIngreso",
    headerName: "Llegada",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => formatDate(row?.row?.fechaIngreso),
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => formatDate(row?.row?.fechaSalida),
  },
];
export const itemsTrasladoColumns = () => [
  {
    field: "codigo",
    headerName: "Código",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.descripcion ?? "--",
  },
  {
    field: "precio",
    headerName: "Precio",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => "$" + row?.row?.precio ?? "",
  },
  {
    field: "cantidadTraspaso",
    headerName: "Cantidad traspaso",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
];
export const habitacionAsignacionRecepcionistaColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  {
    field: "pabellon",
    headerName: "Pabellón",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.pabellon?.identificador ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "wing",
    headerName: "Wing",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.wing?.nombre ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "asignado",
    headerName: "Asignado",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      return params.row?.asignado ?? "";
    },
    renderCell: (params) => {
      const asignados = params.row?.asignado?.split(",") ?? [];
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {asignados.map((asignado, index, array) => (
            <span key={index}>
              {asignado}
              {index < array.length - 1 ? ", " : ""}
            </span>
          ))}
        </Box>
      );
    },
  },
  {
    field: "numero",
    headerName: "Número",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "piso",
    headerName: "Piso",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cargosReservados",
    headerName: "Cargo/s",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row.row?.cargosReservados ?? ""}
      </Box>
    ),
    renderCell: (row) => row.row?.cargosReservados ?? "",
  },
  {
    field: "tipoHuesped",
    headerName: "Tipo huésped",
    width: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "trabajadores",
    headerName: "Ocupantes",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.trabajadores.length,
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tipoCama",
    headerName: "Tipo cama",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nroCamarotes",
    headerName: "N°Camarotes",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nroCamas",
    headerName: "N°Camas",
    minWidth: 30,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "idChapa",
    headerName: "ID chapa",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.idChapa ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "standard",
    headerName: "Standard",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.standard?.nombre ?? "",
    renderCell: (row) => row.value,
  },
  // {
  //   field: "productos",
  //   headerName: "Artículos",
  //   flex: 1,
  //   minWidth: 120,
  //   headerAlign: "center",
  //   align: "center",
  //   valueGetter: (row) => row.row?.productos?.map(prod => prod.nombre).join(', ') ?? "",
  //   renderCell: (row) => row.value,
  // },
  {
    field: "tieneOxigeno",
    headerName: "Oxígeno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return params.row.tieneOxigeno ? (
        <CheckIcon style={{ color: "green" }} />
      ) : (
        <DangerousIcon style={{ color: "red" }} />
      );
    },
  },
  // {
  //   field: "opciones",
  //   headerName: "Opciones",
  //   flex: 1,
  //   minWidth: 100,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => (
  //     <div>
  //       <IconButton
  //         onClick={() => {
  //           setSeleItem(params.row);
  //           setOpenEdit(true);
  //         }}
  //       >
  //         <EditIcon style={{ color: "#f19d38" }} />
  //       </IconButton>
  //       <IconButton
  //         onClick={() => {
  //           setSeleItem(params.row);
  //           setOpen(true);
  //         }}
  //       >
  //         <DeleteIcon style={{ color: "#d52a2a" }} />
  //       </IconButton>
  //     </div>
  //   ),
  // },
];
export const habitacionAsignacionCMICColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  {
    field: "wing",
    headerName: "Wing",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.wing?.nombre ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "asignado",
    headerName: "Asignado",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      return params.row?.asignado ?? "";
    },
    renderCell: (row) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {Array.isArray(row.row.asignado) && row.row.asignado.length > 0 ? (
            row.row.asignado.map((asignado, index, array) => (
              <span key={index}>
                {asignado}
                {index < array.length - 1 ? ", " : ""}
              </span>
            ))
          ) : (
            <span>No asignado</span>
          )}
        </Box>
      );
    },
  },
  {
    field: "numero",
    headerName: "Número",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "piso",
    headerName: "Piso",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cargosReservados",
    headerName: "Cargo/s",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {row.row?.cargosReservados ?? ""}
      </Box>
    ),
    renderCell: (row) => row.row?.cargosReservados ?? "",
  },
  {
    field: "tipoHuesped",
    headerName: "Tipo huésped",
    width: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "trabajadores",
    headerName: "Ocupantes",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.trabajadores.length,
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tipoCama",
    headerName: "Tipo cama",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nroCamarotes",
    headerName: "N°Camarotes",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nroCamas",
    headerName: "N°Camas",
    minWidth: 30,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "idChapa",
    headerName: "ID chapa",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.idChapa ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "standard",
    headerName: "Standard",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.standard?.nombre ?? "",
    renderCell: (row) => row.value,
  },
  // {
  //   field: "productos",
  //   headerName: "Artículos",
  //   flex: 1,
  //   minWidth: 120,
  //   headerAlign: "center",
  //   align: "center",
  //   valueGetter: (row) => row.row?.productos?.map(prod => prod.nombre).join(', ') ?? "",
  //   renderCell: (row) => row.value,
  // },
  {
    field: "tieneOxigeno",
    headerName: "Oxígeno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return params.row.tieneOxigeno ? (
        <CheckIcon style={{ color: "green" }} />
      ) : (
        <DangerousIcon style={{ color: "red" }} />
      );
    },
  },
  // {
  //   field: "opciones",
  //   headerName: "Opciones",
  //   flex: 1,
  //   minWidth: 100,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => (
  //     <div>
  //       <IconButton
  //         onClick={() => {
  //           setSeleItem(params.row);
  //           setOpenEdit(true);
  //         }}
  //       >
  //         <EditIcon style={{ color: "#f19d38" }} />
  //       </IconButton>
  //       <IconButton
  //         onClick={() => {
  //           setSeleItem(params.row);
  //           setOpen(true);
  //         }}
  //       >
  //         <DeleteIcon style={{ color: "#d52a2a" }} />
  //       </IconButton>
  //     </div>
  //   ),
  // },
];
export const rotativosHabitacionesColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  // {
  //   field: "permanente",
  //   headerName: "Permanente",
  //   flex:1,
  //   minWidth:190,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row => <Box
  //   sx={{
  //     display: 'flex',
  //     flexDirection: 'column',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     whiteSpace: 'normal',
  //     wordBreak: 'break-word',
  //   }}
  // >
  //   {row.row?.permanente ? 'Si' : 'No'}
  // </Box>
  // },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.habitacion
        ? row.row?.habitacion?.wing
          ? row.row?.habitacion.wing.nombre
          : "-"
        : "-",
    renderCell: (row) =>
      row.row?.habitacion
        ? row.row?.habitacion?.wing
          ? row.row?.habitacion.wing.nombre
          : "-"
        : "-",
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.piso : "-",
    renderCell: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.piso : "-",
  },
  {
    field: "habitacion",
    headerName: "Hab",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.numero : "-",
    renderCell: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.numero : "-",
  },
  {
    field: "tipoCargos",
    headerName: "Cargos aceptados",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      Array.isArray(row.row?.habitacion?.cargosReservados) &&
      row.row?.habitacion?.cargosReservados
        ? row.row?.habitacion?.cargosReservados.join(", ")
        : "-",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {Array.isArray(row.row?.habitacion?.cargosReservados) &&
        row.row?.habitacion?.cargosReservados
          ? row.row?.habitacion.cargosReservados.join(", ")
          : "-"}
      </Box>
    ),
  },
  {
    field: "tipoCama",
    headerName: "Tipo Cama",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.tipoCama ?? "-",
    renderCell: (row) => row.row?.tipoCama ?? "-",
  },
  {
    field: "rut",
    headerName: "RUT",
    flex: 1,
    minWidth: 110,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const rut = row.row?.trabajador ? row.row?.trabajador?.rut : "";
      return rut;
    },
    renderCell: (row) => {
      const rut = row.row?.trabajador ? row.row?.trabajador?.rut : "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {rut}
        </Box>
      );
    },
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const nombre = row.row?.trabajador ? row.row?.trabajador?.nombre : "";
      return nombre;
    },
    renderCell: (row) => {
      const nombre = row.row?.trabajador ? row.row?.trabajador?.nombre : "";
      const apellido = row.row?.trabajador
        ? row.row?.trabajador?.apellido ?? row.row?.trabajador?.apellidoPaterno
        : "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
            backgroundColor: "transparent",
            padding: "0",
            borderRadius: "0",
            color: "#2d3037",
          }}
        >
          {nombre} {apellido}
        </Box>
      );
    },
  },
  {
    field: "cargo",
    headerName: "Cargo",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const nombre = row.row?.trabajador
        ? row.row?.trabajador?.cargo?.nCargo
        : "";
      return nombre;
    },
    renderCell: (row) => {
      const nombre = row.row?.trabajador
        ? row.row?.trabajador?.cargo?.nCargo
        : "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
            backgroundColor: "transparent",
            padding: "0",
            borderRadius: "0",
            color: "#2d3037",
          }}
        >
          {nombre}
        </Box>
      );
    },
  },
  // {
  //   field: "jornada",
  //   headerName: "Jornada",
  //   flex:1,
  //   minWidth:200,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row =>{
  //    const nombre = row.row?.trabajador ? row.row?.trabajador?.jornada : ''
  //    return <Box
  //       sx={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         whiteSpace: 'normal',
  //         wordBreak: 'break-word',
  //         backgroundColor: 'transparent',
  //         padding: '0',
  //         borderRadius: '0',
  //         color:'#2d3037'
  //       }}
  //     >
  //     {nombre}
  //     </Box>
  //   }
  // },
  {
    field: "turno",
    headerName: "Turno",
    flex: 1,
    minWidth: 170,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const nombre = row.row?.trabajador ? row.row?.trabajador?.turno : "";
      return nombre;
    },
    renderCell: (row) => {
      const nombre = row.row?.trabajador ? row.row?.trabajador?.turno : "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
            backgroundColor: "transparent",
            padding: "0",
            borderRadius: "0",
            color: "#2d3037",
          }}
        >
          {nombre}
        </Box>
      );
    },
  },
  // {
  //   field: "genero",
  //   headerName: "Género",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row => {
  //     const pabellon = row.row?.pabellon ??  '' ;
  //     const wing = row.row?.wing ??  '' ;
  //     const genero = pabellon ?? wing ;
  //     return <Box
  //       sx={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         whiteSpace: 'normal',
  //         wordBreak: 'break-word',
  //       }}
  //     >
  //     {genero ? genero.tipoHuesped : '-'}
  //     </Box>
  //   }
  // },
  // {
  //   field: "opciones",
  //   headerName: "Opciones",
  //   width: 130,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => {
  //     return (
  //       <div>
  //         <IconButton
  //           onClick={function () {
  //             setSeleItem(params.row);
  //             setOpenEdit(true);
  //           }}
  //         >
  //           <EditIcon style={{ color:"#f19d38" }}/>
  //         </IconButton>
  //         <IconButton
  //           onClick={function () {
  //             setSeleItem(params.row);
  //             setOpen(true);
  //           }}
  //         >
  //           <DeleteIcon style={{ color:"#d52a2a" }}/>
  //         </IconButton>
  //       </div>

  //     );
  //   },
  // },
];
export const rotativosHabitacionesReservasColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  // {
  //   field: "permanente",
  //   headerName: "Permanente",
  //   flex:1,
  //   minWidth:190,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row => <Box
  //   sx={{
  //     display: 'flex',
  //     flexDirection: 'column',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     whiteSpace: 'normal',
  //     wordBreak: 'break-word',
  //   }}
  // >
  //   {row.row?.permanente ? 'Si' : 'No'}
  // </Box>
  // },
  {
    field: "habitacion",
    headerName: "Hab",
    flex: 1,
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.numero ?? "-",
    renderCell: (row) => row.row?.numero ?? "-",
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.piso ?? "-",
    renderCell: (row) => row.row?.piso ?? "-",
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => (row.row?.wing ? row.row?.wing?.nombre : "-"),
    renderCell: (row) => (row.row?.wing ? row.row?.wing?.nombre : "-"),
  },
  {
    field: "tipoHuesped",
    headerName: "Tipo Huésped",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.tipoHuesped ?? "-",
    renderCell: (row) => row.row?.tipoHuesped ?? "-",
  },
  {
    field: "tipoCargos",
    headerName: "Cargos aceptados",
    flex: 1,
    minWidth: 320,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      Array.isArray(row.row?.cargosReservados) && row.row?.cargosReservados
        ? row.row?.cargosReservados.join(", ")
        : "-",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {Array.isArray(row.row?.cargosReservados) && row.row?.cargosReservados
          ? row.row?.cargosReservados.join(", ")
          : "-"}
      </Box>
    ),
  },
  {
    field: "tipoCama",
    headerName: "Tipo Cama",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.tipoCama ?? "-",
    renderCell: (row) => row.row?.tipoCama ?? "-",
  },
  // {
  //   field: "rut",
  //   headerName: "RUT",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row =>{
  //     const fechaActual = new Date();
  //     let rut = '-';
  //     const registroEnRango = (Array.isArray(row.row?.reservasP_INTERNO) && row.row?.reservasP_INTERNO.length > 0 )
  //       ? row.row?.reservasP_INTERNO.find(reserva => {
  //           const fechaInicio = new Date(reserva.fechaInicio);
  //           const fechaTermino = new Date(reserva.fechaTermino);

  //           return fechaActual >= fechaInicio && fechaActual <= fechaTermino;
  //         })
  //       : null;

  //     if (registroEnRango) {
  //       rut = registroEnRango.trabajador.rut;
  //     }

  //     return <Box
  //        sx={{
  //          display: 'flex',
  //          flexDirection: 'column',
  //          justifyContent: 'center',
  //          alignItems: 'center',
  //          whiteSpace: 'normal',
  //          wordBreak: 'break-word',
  //        }}
  //      >
  //      {rut}
  //      </Box>
  //    }
  // },
  // {
  //   field: "nombre",
  //   headerName: "Nombre",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row =>{
  //     const fechaActual = new Date();
  //     let nombre = '-';
  //     const registroEnRango = (Array.isArray(row.row?.reservasP_INTERNO) && row.row?.reservasP_INTERNO.length > 0 )
  //       ? row.row?.reservasP_INTERNO.find(reserva => {
  //           const fechaInicio = new Date(reserva.fechaInicio);
  //           const fechaTermino = new Date(reserva.fechaTermino);

  //           return fechaActual >= fechaInicio && fechaActual <= fechaTermino;
  //         })
  //       : null;

  //     if (registroEnRango) {
  //       nombre = registroEnRango.trabajador.nombre;
  //     }

  //     return <Box
  //        sx={{
  //          display: 'flex',
  //          flexDirection: 'column',
  //          justifyContent: 'center',
  //          alignItems: 'center',
  //          whiteSpace: 'normal',
  //          wordBreak: 'break-word',
  //        }}
  //      >
  //      {nombre}
  //      </Box>
  //    }
  // },
  // {
  //   field: "cargo",
  //   headerName: "Cargo",
  //   flex:1,
  //   minWidth:200,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row =>{
  //    const nombre = row.row?.trabajador ? row.row?.trabajador?.cargo?.nCargo : ''
  //    return <Box
  //       sx={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         whiteSpace: 'normal',
  //         wordBreak: 'break-word',
  //         backgroundColor: 'transparent',
  //         padding: '0',
  //         borderRadius: '0',
  //         color:'#2d3037'
  //       }}
  //     >
  //     {nombre}
  //     </Box>
  //   }
  // },
  // {
  //   field: "jornada",
  //   headerName: "Jornada",
  //   flex:1,
  //   minWidth:200,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row =>{
  //    const nombre = row.row?.trabajador ? row.row?.trabajador?.jornada : ''
  //    return <Box
  //       sx={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         whiteSpace: 'normal',
  //         wordBreak: 'break-word',
  //         backgroundColor: 'transparent',
  //         padding: '0',
  //         borderRadius: '0',
  //         color:'#2d3037'
  //       }}
  //     >
  //     {nombre}
  //     </Box>
  //   }
  // },
  // {
  //   field: "turno",
  //   headerName: "Turno",
  //   flex:1,
  //   minWidth:200,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row =>{
  //    const nombre = row.row?.trabajador ? row.row?.trabajador?.turno : ''
  //    return <Box
  //       sx={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         whiteSpace: 'normal',
  //         wordBreak: 'break-word',
  //         backgroundColor: 'transparent',
  //         padding: '0',
  //         borderRadius: '0',
  //         color:'#2d3037'
  //       }}
  //     >
  //     {nombre}
  //     </Box>
  //   }
  // },
  // {
  //   field: "genero",
  //   headerName: "Género",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row => {
  //     const pabellon = row.row?.pabellon ??  '' ;
  //     const wing = row.row?.wing ??  '' ;
  //     const genero = pabellon ?? wing ;
  //     return <Box
  //       sx={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         whiteSpace: 'normal',
  //         wordBreak: 'break-word',
  //       }}
  //     >
  //     {genero ? genero.tipoHuesped : '-'}
  //     </Box>
  //   }
  // },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <VisibilityIcon style={{ color: "#0086ae" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const rotativosTrabajadoresColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  // {
  //   field: "habitacion",
  //   headerName: "Hab",
  //   flex:1,
  //   minWidth:60,
  //   headerAlign: "center",
  //   align: "center",
  //   valueGetter: row => row.row?.habitacion ? row.row?.habitacion?.numero : '',
  //   renderCell: row => row.row?.habitacion ? row.row?.habitacion?.numero : ''
  // },
  {
    field: "rut",
    headerName: "RUT",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.trabajador
        ? row.row?.trabajador.rut ?? row.row?.trabajador.trabajador.rut ?? ""
        : "",
    renderCell: (row) =>
      row.row?.trabajador
        ? row.row?.trabajador.rut ?? row.row?.trabajador.trabajador.rut ?? ""
        : "",
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const nombre = row.row?.trabajador
        ? `${
            row.row?.trabajador.nombre ?? row.row?.trabajador.trabajador.nombre
          } ${
            row.row?.trabajador.apellidoPaterno ??
            row.row?.trabajador.trabajador.apellidoPaterno
          }`
        : "";
      return nombre;
    },
    renderCell: (row) => {
      const nombre = row.row?.trabajador
        ? `${row.row?.trabajador.nombre} ${row.row?.trabajador.apellidoPaterno}`
        : "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {nombre}
        </Box>
      );
    },
  },
  // {
  //   field: "cargo",
  //   headerName: "Cargo",
  //   flex:1,
  //   minWidth:180,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row => {
  //     const nombre = row.row?.cargo ? row.row?.cargo?.nCargo : ''
  //     return <Box
  //       sx={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         whiteSpace: 'normal',
  //         wordBreak: 'break-word',
  //       }}
  //     >
  //       {nombre}
  //     </Box>
  //   }
  // },
  // {
  //   field: "genero",
  //   headerName: "Género",
  //   flex:1,
  //   minWidth:80,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row => row.row?.genero ?? ''
  // },
  // {
  //   field: "fechaIngreso",
  //   headerName: "Inicio",
  //   flex:1,
  //   minWidth:80,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row => row.row?.fechaInicio ? format(row.row?.fechaInicio, 'dd-MM-yyyy') : ''
  // },
  // {
  //   field: "fechaSalida",
  //   headerName: "Termino",
  //   flex:1,
  //   minWidth:80,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row => row.row?.fechaTermino ? format(row.row?.fechaTermino, 'dd-MM-yyyy') : ''
  // },
  {
    field: "grupo_rrhh",
    headerName: "Grupo RRHH",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.turno ?? "",
    renderCell: (row) => row.row?.turno ?? "",
  },
];
export const rotativosTrabajadoresConTrabColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  {
    field: "habitacion",
    headerName: "Hab",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.numero : "",
  },
  {
    field: "rut",
    headerName: "RUT",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.trabajador ? row.row?.trabajador.rut : ""),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const nombre = row.row?.trabajador
        ? `${row.row?.trabajador?.nombre} ${row.row?.trabajador.apellidoPaterno}`
        : "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {nombre}
        </Box>
      );
    },
  },
  {
    field: "cargo",
    headerName: "Cargo",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const nombre = row.row?.trabajador.cargo
        ? row.row.trabajador?.cargo?.nCargo
        : "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {nombre}
        </Box>
      );
    },
  },
  {
    field: "genero",
    headerName: "Género",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.trabajador.genero ?? "",
  },
  // {
  //   field: "fechaIngreso",
  //   headerName: "Inicio",
  //   flex:1,
  //   minWidth:80,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row => row.row?.fechaInicio ? format(row.row?.fechaInicio, 'dd-MM-yyyy') : ''
  // },
  // {
  //   field: "fechaSalida",
  //   headerName: "Termino",
  //   flex:1,
  //   minWidth:80,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row => row.row?.fechaTermino ? format(row.row?.fechaTermino, 'dd-MM-yyyy') : ''
  // },
  {
    field: "grupo_rrhh",
    headerName: "Grupo RRHH",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.turno ?? "",
  },
];
export const rotativosTrabajadoresReservasColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  //  {
  //   field: "permanente",
  //   headerName: "Permanente",
  //   flex:1,
  //   minWidth:140,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row => row.row?.habitacion ? row.row?.habitacion?.permanente ? 'Si' : 'No' : ''
  // },
  {
    field: "habitacion",
    headerName: "Hab",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.numero : "-",
    renderCell: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.numero : "-",
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.piso : "-",
    renderCell: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.piso : "-",
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.habitacion
        ? row.row?.habitacion?.wing
          ? row.row?.habitacion?.wing?.nombre
          : "-"
        : "-",
    renderCell: (row) =>
      row.row?.habitacion
        ? row.row?.habitacion?.wing
          ? row.row?.habitacion?.wing?.nombre
          : "-"
        : "-",
  },
  {
    field: "rut",
    headerName: "RUT",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.trabajador.rut ?? "-",
    renderCell: (row) => row.row?.trabajador.rut ?? "-",
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const nombre = row.row?.trabajador.nombre
        ? `${row.row?.trabajador.nombre} ${row.row?.trabajador.apellidoPaterno}`
        : "";
      return nombre;
    },
    renderCell: (row) => {
      const nombre = row.row?.trabajador.nombre
        ? `${row.row?.trabajador.nombre} ${row.row?.trabajador.apellidoPaterno}`
        : "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
            textAlign: "center",
          }}
        >
          {nombre}
        </Box>
      );
    },
  },
  {
    field: "fechaInicio",
    headerName: "Inicio",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.fechaInicio ? format(row.row?.fechaInicio, "dd-MM-yyyy") : "",
    renderCell: (row) =>
      row.row?.fechaInicio ? format(row.row?.fechaInicio, "dd-MM-yyyy") : "",
  },
  {
    field: "fechaTermino",
    headerName: "Termino",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.fechaTermino ? format(row.row?.fechaTermino, "dd-MM-yyyy") : "",
    renderCell: (row) =>
      row.row?.fechaTermino ? format(row.row?.fechaTermino, "dd-MM-yyyy") : "",
  },
  {
    field: "grupo_rrhh",
    headerName: "Grupo RRHH",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.turno ?? "-",
    renderCell: (row) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "normal",
          wordBreak: "break-word",
          textAlign: "center",
        }}
      >
        {row.row?.turno ?? "-"}
      </Box>
    ),
  },
  {
    field: "genero",
    headerName: "Género",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.trabajador.genero ?? "-",
    renderCell: (row) => row.row?.trabajador.genero ?? "-",
  },
  {
    field: "cargo",
    headerName: "Cargo",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const nombre = row.row?.trabajador.cargo
        ? row.row?.trabajador.cargo?.nCargo
        : "-";
      return nombre;
    },
    renderCell: (row) => {
      const nombre = row.row?.trabajador.cargo
        ? row.row?.trabajador.cargo?.nCargo
        : "-";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {nombre}
        </Box>
      );
    },
  },
];
export const asignacionesP_INTERNOReservasColumns = (
  setOpen,
  setSeleItem,
  setOpenEdit
) => [
  {
    field: "permanente",
    headerName: "Permanente",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =>
      row.row?.habitacion
        ? row.row?.habitacion?.permanente
          ? "Si"
          : "No"
        : "",
  },
  {
    field: "habitacion",
    headerName: "Hab",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.numero : "",
    renderCell: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.numero : "",
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.piso : "-",
    renderCell: (row) =>
      row.row?.habitacion ? row.row?.habitacion?.piso : "-",
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.habitacion
        ? row.row?.habitacion?.wing
          ? row.row?.habitacion?.wing?.nombre
          : "-"
        : "-",
    renderCell: (row) =>
      row.row?.habitacion
        ? row.row?.habitacion?.wing
          ? row.row?.habitacion?.wing?.nombre
          : "-"
        : "-",
  },
  {
    field: "rut",
    headerName: "RUT",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.trabajador.rut ?? "",
    renderCell: (row) => row.row?.trabajador.rut ?? "",
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const nombre = row.row?.trabajador
        ? `${row.row?.trabajador.nombre} ${row.row?.trabajador.apellidoPaterno}`
        : "";
      return nombre;
    },
    renderCell: (row) => {
      const nombre = row.row?.trabajador
        ? `${row.row?.trabajador.nombre} ${row.row?.trabajador.apellidoPaterno}`
        : "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {nombre}
        </Box>
      );
    },
  },
  {
    field: "cargo",
    headerName: "Cargo",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const nombre = row.row?.trabajador
        ? row.row?.trabajador?.cargo?.nCargo
        : "";
      return nombre;
    },
    renderCell: (row) => {
      const nombre = row.row?.trabajador
        ? row.row?.trabajador?.cargo?.nCargo
        : "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {nombre}
        </Box>
      );
    },
  },
  {
    field: "genero",
    headerName: "Género",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>
      row.row?.trabajador ? row.row?.trabajador?.genero : "",
    renderCell: (row) =>
      row.row?.trabajador ? row.row?.trabajador?.genero : "",
  },
  // {
  //   field: "fechaIngreso",
  //   headerName: "Inicio",
  //   flex:1,
  //   minWidth:80,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row => row.row?.fechaInicio ? format(row.row?.fechaInicio, 'dd-MM-yyyy') : ''
  // },
  // {
  //   field: "fechaSalida",
  //   headerName: "Termino",
  //   flex:1,
  //   minWidth:80,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: row => row.row?.fechaTermino ? format(row.row?.fechaTermino, 'dd-MM-yyyy') : ''
  // },
  {
    field: "grupo_rrhh",
    headerName: "Grupo RRHH",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.turno ?? "",
    renderCell: (row) => row.row?.turno ?? "",
  },
];
