import { Skeleton } from "@mui/material";

const SkeletonTable = () => {
  return (
    <Skeleton
      variant="rectangular"
      animation="wave"
      width="100%"
      height={500}
      sx={{
        marginTop: "12px",
        "&::after": {
          animationDuration: "1s",
        },
      }}
    />
  );
};

export default SkeletonTable;
