import styles from "./components.module.css";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  Typography,
  Card,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import { useState, useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import {
  asignarHabitacionDisponibleP_INTERNO,
  fetchHabitacionesP_INTERNODisponibles,
  getAllHabitacionesP_INTERNODisponibles,
  getHabitacionesP_INTERNODisponiblesStatus,
  removerTrabajadorP_INTERNOdeListado2,
} from "../../../../redux/features/reservas/reservaSlice";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import SkeletonTable from "../../../../components/Skeleton";
import {
  habitacionesDisponiblesP_INTERNO,
  tramosColumns,
} from "../../../../lib/columns";

const More = ({ openMore, setOpenMore, seleItem }) => {
  const habitacionesDisponibles = useSelector(
    getAllHabitacionesP_INTERNODisponibles
  );
  const status = useSelector(getHabitacionesP_INTERNODisponiblesStatus);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (seleItem) {
      console.log("seleItem", seleItem);
      if (
        seleItem.status === 1 ||
        seleItem.status === 3 ||
        seleItem.status === 4
      ) {
        dispatch(fetchHabitacionesP_INTERNODisponibles());
      }
    }
  }, [seleItem]);

  const asignar = async (id) => {
    try {
      if (!id || !seleItem.trabajador) return null;
      setLoading(true);
      const resp = await dispatch(
        asignarHabitacionDisponibleP_INTERNO({
          id: seleItem.id,
          rut: seleItem.trabajador.rut,
          habitacionId: id,
          turno: seleItem.trabajador.rut,
          fechaInicio: new Date().toISOString(),
          fechaTermino: new Date().toISOString(),
        })
      );
      if (resp.payload.status === "success") {
        setOpenMore(false);
      }
    } catch (error) {
      console.log("error al asignar", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemover = async () => {
    try {
      setLoading(true);
      const resp = await dispatch(
        removerTrabajadorP_INTERNOdeListado2(seleItem.id)
      );
      if (resp.payload.status === "success") {
        setOpenMore(false);
      }
    } catch (error) {
      console.log("error al remover", error);
    } finally {
      setLoading(false);
    }
  };
  const finalColumns = habitacionesDisponiblesP_INTERNO(asignar, handleRemover);

  return (
    <div>
      <Dialog
        open={openMore}
        maxWidth="lg"
        onBackdropClick={() => {
          setOpenMore(false);
        }}
        PaperProps={{
          sx: {
            width: { xs: "100%", sm: "75%", md: "100%" },
            backgroundColor: "#efefef",
            maxHeight: "80vh",
            color: "#2d3037",
            borderRadius: "0",
            overflowY: "auto",
          },
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <DialogTitle>
          <span
            className={styles.label}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            <InfoIcon style={{ fontSize: "18px" }} />
            {seleItem &&
            (seleItem.status == 1 ||
              seleItem.status == 3 ||
              seleItem.status == 4) ? (
              <Typography>Asignar habitación</Typography>
            ) : (
              <Typography>Liberar información</Typography>
            )}
          </span>
        </DialogTitle>
        <DialogContent>
          <Card style={{ padding: "16px" }}>
            <Grid container={true}>
              {seleItem &&
              (seleItem.status == 1 ||
                seleItem.status == 3 ||
                seleItem.status == 4) ? (
                status == "loading" ? (
                  <SkeletonTable />
                ) : (
                  <Grid item xs={12} md={12}>
                    <DataGrid
                      getRowId={(row) => (row.id ? row.id : Math.random())}
                      lots={{ toolbar: GridToolbar }}
                      rows={habitacionesDisponibles ?? []}
                      columns={finalColumns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[5, 10, 15, 20]}
                      localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                        noRowsLabel: "No hay datos disponibles",
                        pagination: {
                          labelRowsPerPage: "Filas por página:",
                        },
                      }}
                      classes={{ menuIcon: styles.menuIcon }}
                      style={{ maxHeight: "500px", border: "none" }}
                      sx={{
                        "& .MuiDataGrid-cell": {
                          color: "#2d3037",
                          borderBottom: "1px solid #c6c6c6",
                        },
                        "& .MuiDataGrid-columnHeader": {
                          color: "#2d3037",
                          borderBottom: "1px solid #c6c6c6",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          borderBottom: "1px solid #c6c6c6",
                        },
                        "& .MuiDataGrid-footerContainer": {
                          color: "#2d3037",
                          borderTop: "1px solid #c6c6c6",
                        },
                        "& .MuiTablePagination-root": {
                          color: "#2d3037",
                          borderTop: "1px solid #c6c6c6",
                        },
                        "& .MuiDataGrid-toolbarContainer": {
                          color: "#2d3037",
                        },
                        "& .MuiDataGrid-menu": {
                          color: "#2d3037",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#9ca91c",
                        },
                        "& .MuiTablePagination-actions": {
                          color: "#2d3037", // Color for pagination actions
                        },
                        "& .MuiTablePagination-select": {
                          color: "#2d3037", // Color for pagination select
                        },
                        "& .MuiTablePagination-selectLabel": {
                          color: "#2d3037", // Color for pagination select label
                        },
                        "& .MuiTablePagination-displayedRows": {
                          color: "#2d3037", // Color for displayed rows text
                        },
                        "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer":
                          {
                            color: "red",
                          },
                        "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root":
                          {
                            color: "red",
                          },
                      }}
                      slotProps={{
                        toolbar: {
                          sx: {
                            color: "#FFF",
                          },
                        },
                        pagination: {
                          sx: {
                            color: "#f19d38",
                          },
                        },
                        menu: {
                          sx: {
                            color: "#f19d38",
                          },
                        },
                      }}
                    />
                  </Grid>
                )
              ) : (
                <Typography>Liberar información</Typography>
              )}
            </Grid>
          </Card>
          <DialogActions>
            <Grid
              container={true}
              style={{
                padding: "0 16px",
                textAlign: "end",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                onClick={() => setOpenMore(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cerrar
              </Button>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default More;
