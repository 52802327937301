import styles from "./components.module.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ErrorIcon from '@mui/icons-material/Error';
import {
  Autocomplete,
  Box,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import { editPlanificacionAseo } from "../../../../redux/features/aseo/aseoSlice";
import { format, parseISO } from "date-fns";

const data = {
  grupo: "",
  horario: "",
  ruts: [],
};

const Edit = ({
  open2,
  setOpen2,
  seleItem,
  open3,
  setOpen3,
  grupos,
  franjas,
  tareas,
  personal,
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedRuts, setSelectedRuts] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [tipo, setTipo] = useState('');
  const [selectedTareas, setSelectedTareas] = useState([]);

  const handleChangeTipo = (event) => {
    setTipo(event.target.value);
    setFormData({...formData, tipoAseo: event.target.value});
  };

  const handleChangeHorario = (event, newValue) => {
    setSelectedCampamento(newValue);
    if (newValue != null) {
      setFormData({ ...formData, horario: newValue.id });
    } else {
      setFormData({ ...formData, horario: 0 });
    }
  };
  const formatISODate = (isoString) => {
    if (!isoString) return '';
    const date = parseISO(isoString);
    return format(date, 'yyyy-MM-dd');
  };

  useEffect(() => {
    if (seleItem) {
      console.log('seleItem:-->', seleItem);
      setSelectedCampamento(seleItem?.horarioAseo)
      setSelectedGroup(seleItem?.gruposHabitaciones[0])
      setSelectedRuts(seleItem?.trabajadores)
      setTipo(seleItem.tipoAseo || '');
      // const initialCheckedItems = seleItem.tareas.map((tarea) => tarea.id);
      // setCheckedItems(initialCheckedItems);
      setSelectedTareas(seleItem?.tareas || []);
      setFormData({ id:seleItem.id, desde: formatISODate(seleItem?.desde), hasta: formatISODate(seleItem?.hasta), horario: seleItem?.horarioAseo.id, grupo:[seleItem?.gruposHabitaciones[0].id], ruts: seleItem.trabajadores.map(item => item.rut), tareasId: seleItem.tareas.map(item => item.id), tipoAseo: seleItem.tipoAseo })
      console.log('tareas',seleItem.tareas);
      console.log(checkedItems);
      console.log('tareas list',tareas);
      }
  }, [seleItem, open2]);

  useEffect(() => {
    console.log('formData:', formData);
  }, [formData])
  

  const handleChangeGroup = (event, newValue) => {
    setSelectedGroup({ id: newValue.id, nombre: newValue.nombre });
    console.log(newValue);
    if (newValue != null) {
      setFormData({ ...formData, grupo: newValue });
    } else {
      setFormData({ ...formData, grupo: 0 });
    }
  };
  const onChangeRutSelect = async (e, newValue) => {
    if (newValue != null) {
      console.log(newValue);
      setFormData({ ...formData, ruts: newValue.map((item) => item.rut) });
      setSelectedRuts(newValue);
    } else {
      setFormData({ ...formData, ruts: [] });
    }
  };
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
  
  const canSave = [formData?.horario, formData?.desde, formData?.hasta, formData?.grupo?.length > 0, formData?.ruts?.length > 0, formData?.tareasId?.length > 0, formData?.tipoAseo].every(Boolean) && addRequestStatus === "idle";
  
  const handleCheckboxChange = (value) => {
    setFormData({ ...formData, tareasId: value.map(tar => tar.id) });
  };

  // const handleCheckboxChange = (tarea) => {
  //   setCheckedItems((prevCheckedItems) => {
  //     let newCheckedItems;
  //     if (prevCheckedItems.includes(tarea.id)) {
  //       newCheckedItems = prevCheckedItems.filter((item) => item !== tarea.id);
  //     } else {
  //       newCheckedItems = [...prevCheckedItems, tarea.id];
  //     }

  //     setFormData({
  //       ...formData,
  //       tareasId: newCheckedItems.map((item) => item),
  //     });
  //     return newCheckedItems;
  //   });
  // };

  const handleSubmit = async () => {
    if (canSave) {
      try {
        setOpen3(true);
        setAddRequestStatus("loading");
        let resp = await dispatch(editPlanificacionAseo(formData));
        if (resp.payload.status === "success") {
          setFormData(data);
          setOpen2(false);
        }
      } catch (error) {
        console.error("Error al actualizar la planificación", error);
      } finally {
        setOpen3(false);
        setAddRequestStatus("idle");
      }
    }
  };

  return (
    <Dialog
      open={open2}
      maxWidth="xs"
      PaperProps={{
          sx: {
           backgroundColor:'#efefef',
           color:'#2d3037',
           borderRadius:'0'
          }
      }}
      onBackdropClick={() => {
        setOpen2(false);
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open3}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle>
                    <Grid container={true} spacing={1} px={2}>
                        <Grid item  xs={12} sm={12} style={{ display:'flex', gap:'5px'}}>
                            <ErrorIcon style={{ fontSize:'3rem' }} className={styles.iconBg}/>
                            <div style={{ display:'flex', flexDirection:'column' }}>
                                <Typography variant="h6" className={styles.textIconEdit}>Actualizar planificación</Typography>
                                <Typography variant="span" className={styles.textIconSpan}>Modifica los campos para actualizar la planificación.</Typography>
                            </div>
                        </Grid>
                    </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container={true} spacing={1} style={{ display:'flex', flexDirection:'column' }}>
          <Grid item xs={12} >
            <label className={styles.label}>Jornada</label>
            <Autocomplete
              className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#2d3037',
                          },
                        }}
              disablePortal
              id="campamentos-autocomplete"
              name="franja"
              disableClearable
              options={
                Array.isArray(franjas) && franjas.length > 0 ? franjas : []
              }
              getOptionLabel={(option) => option.nombre}
              value={selectedCampamento}
              onChange={(e, value) => handleChangeHorario(e, value)}
              renderInput={(params) => (
                <TextField {...params} label="Selecciona un rango de horario" />
              )}
            />
          </Grid>
          <Grid item xs={12} >
            <label className={styles.label}>Grupos</label>
            <Autocomplete
              className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#2d3037',
                          },
                        }}
              disablePortal
              id="campamentos-autocomplete"
              name="grupo"
              disableClearable
              value={selectedGroup}
              options={Array.isArray(grupos) && grupos.length > 0 ? grupos : []}
              getOptionLabel={(option) => option.nombre}
              onChange={(e, value) => handleChangeGroup(e, value)}
              renderInput={(params) => (
                <TextField {...params} label="Selecciona un grupo" />
              )}
            />
          </Grid>
          <Grid item xs={12} >
            <label className={styles.label}>Busque trabajadores</label>
            <Autocomplete
              className={styles.textField}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                },
                '.MuiChip-label':{
                  color: '#2d3037',
                },
                '.MuiSvgIcon-root':{
                  color: '#2d3037',
                },
                '& .MuiInputBase-input': {
                  color: '#2d3037',
                },
                '& .MuiInputLabel-root': {
                  color: '#2d3037',
                },
                '& .MuiAutocomplete-clearIndicator': {
                  color: '#2d3037',
                },
                '& .MuiAutocomplete-popupIndicator': {
                  color: '#2d3037',
                },
              }}
              disablePortal
              disableClearable
              id="sectores-autocomplete"
              name="rut"
              value={selectedRuts || null}
              options={personal ? personal : []}
              multiple
              getOptionLabel={(option) => option.rut + " " + option.nombre}
              onChange={(e, value) => onChangeRutSelect(e, value)}
              renderInput={(params) => (
                <TextField {...params} label="Selecciona un rut" />
              )}
            />
          </Grid>
          <Grid item xs={12} >
            <label className={styles.label}>Desde</label>
            <TextField
              className={styles.textField}
              sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#2d3037',
                          },
              }}
              variant="outlined"
              fullWidth
              name="desde"
              type="date"
              value={formData?.desde || null}
              onChange={(e) => onChange(e)}
            />
          </Grid>
          <Grid item xs={12} >
            <label className={styles.label}>Hasta</label>
            <TextField
              className={styles.textField}
              sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#2d3037',
                          },
              }}
              variant="outlined"
              fullWidth
              name="hasta"
              type="date"
              value={formData?.hasta}
              onChange={(e) => onChange(e)}
            />
          </Grid>
          <Grid item xs={12} >
              <label className={styles.label}>Tipo de aseo</label>
              <FormControl fullWidth>
                <Select
                  className={styles.textField}
                  sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#2d3037',
                          },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={tipo}
                  name='tipo'
                  // defaultValue={tipo}
                  label="Tipo"
                  onChange={handleChangeTipo}
                >
                  <MenuItem value='Aseo normal'>Aseo normal</MenuItem>
                  <MenuItem value='Limpieza terminal'>Limpieza terminal</MenuItem>
                  <MenuItem value='Desempolvado'>Desempolvado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          <Grid item xs={12} >
            <label className={styles.label}>Lista de tareas</label>
            <Autocomplete
                  multiple
                  id="select-multiple-tareas"
                  options={tareas}
                  getOptionLabel={(option) => option.nombre}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#2d3037',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#2d3037',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color: '#2d3037',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color: '#2d3037',
                    },
                    'backgroundColor': '#FFF',
                  }}
                  value={selectedTareas}
                  onChange={(event, newValue) => {
                    setSelectedTareas(newValue);
                    handleCheckboxChange(newValue);
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.id} style={{ gap:'5px' }}>
                      <Typography variant="body1">{option.nombre}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {option.descripcion}
                      </Typography>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Selecciona Tareas"
                      placeholder="Tareas"
                    />
                  )}
                />
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Grid container={true} style={{ padding: "0 16px" }}>
          <Grid item xs={12} sm={12} className={styles.buttonContainer}>
            <Button
              onClick={handleSubmit}
              className={styles.bgColorEdit}
              variant="contained"
              disabled={!canSave || selectedRuts?.newValue?.length <= 0}
            >
              {addRequestStatus == "loading" ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "Actualizar"
              )}
            </Button>
            <Button
              onClick={() => setOpen2(false)}
              className={styles.bgColorOutlined}
              variant="outlined"
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default Edit;
