import { Box, Container } from "@mui/material";
import Header from "../../../components/header/Header";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from "./components/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchMotivos,
  getAllMotivos,
  getMotivosStatus,
} from "../../../redux/features/motivos/motivoSlice";
import SkeletonTable from "../../../components/Skeleton";

const MotivosSolicitud = () => {
  const dispatch = useDispatch();
  const motivos = useSelector(getAllMotivos);
  const status = useSelector(getMotivosStatus);

  useEffect(() => {
    if (status == "idle") {
      dispatch(fetchMotivos());
    }
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Header
          title="Gestión de Motivos de Solicitud"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados con los motivos de solicitud en la plataforma."
        >
          {<AssignmentIcon style={{ fontSize: "1.5rem" }} />}
        </Header>

        <TableCard>
          <Create />
          {status == "loading" ? (
            <SkeletonTable />
          ) : (
            <Table motivos={motivos} />
          )}
        </TableCard>
      </Container>
    </Box>
  );
};

export default MotivosSolicitud;
