import styles from './aseo.module.css'
import { TextField, Card, Box, Container, Grid, CircularProgress, Popper, Select, MenuItem, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Backdrop, Autocomplete } from "@mui/material"
import DashboardIcon from '@mui/icons-material/Dashboard';
import Button from '@mui/material/Button';
import Table from './components/Table'
import { Fragment, useEffect, useState } from 'react';
import { fetchUsers, getAllUsers, getUsersStatusAll } from '../../redux/features/users/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCampamentos, getAllCampamentos, getCampamentosStatus } from "../../redux/features/campamentos/campamentoSlice";
import { useAuth } from '../../context/AppContext';
import { NavLink } from 'react-router-dom';
import Calendario from './planificacion/components/CalendarDash';
import TableDash from './planificacion/components/TableCalendarioDashboard';
import TableHabNoAsignadas from './TableHabNoAsignadas';
import { GridFilterListIcon } from '@mui/x-data-grid';
import { fetchGruposAseo, fetchHabitacionesNoAsignadas, fetchPersonalAseo, fetchPlanificacionDiaria, getAllGruposAseos, getAllHabitacionesNoAsignadas, getAllHorariosAseos, getAllPersonal, getAllPlanificacionesDiarias, getGruposAseoStatus, getHabitacionesNoAsignadasStatus, getPersonalStatus, getPlanificacionesDiariasStatus } from '../../redux/features/aseo/aseoSlice';
import { fetchWings, getAllWings, getWingsStatus } from '../../redux/features/wings/wingSlice';
import { fetchEdificios, getAllEdificios, getEdificiosStatus } from '../../redux/features/edificios/edificioSlice';
import { fetchOtros, getAllOtros, getOtrosStatus } from '../../redux/features/otros/otroSlice';
import { fetchReclamosSugerencias, getAllReclamosSugerencias, getReclamosSugerenciasStatus } from '../../redux/features/reclamos_sugerencias/reclamos_sugerenciaSlice';
import SkeletonTable from '../../components/Skeleton';
import moment from 'moment';

const data = {
  campamento: 0,
}

const tipos = [
  {
    id:'1',
    nombre: 'Aseo'
  }
]


const DashboardAseo = () => {
  const { info } = useAuth();
  const rol = info?.roles;

  const addRequestStatus = 'idle';
  const users = useSelector(getAllUsers);
  // const users = [];
  const status = useSelector(getUsersStatusAll);
  const dispatch = useDispatch();
  const [open3, setOpen3] = useState(false);
  const [open2, setOpen2] = useState(false);
  
  const registros = useSelector(getAllPlanificacionesDiarias);
  const regStatus = useSelector(getPlanificacionesDiariasStatus);
  const grupos = useSelector(getAllGruposAseos);
  const gruposStatus = useSelector(getGruposAseoStatus);
  const planificaciones = useSelector(getAllPlanificacionesDiarias);
  const planStatus = useSelector(getPlanificacionesDiariasStatus);
  const franjas = useSelector(getAllHorariosAseos);
  const campamentos = useSelector(getAllCampamentos);
  const camStatus = useSelector(getCampamentosStatus);
  const wings = useSelector(getAllWings);
  const winStatus = useSelector(getWingsStatus);
  const pabellones = useSelector(getAllEdificios);
  const pabStatus = useSelector(getEdificiosStatus);
  const otros = useSelector(getAllOtros);
  const otStatus = useSelector(getOtrosStatus);
  const habitaciones_no_asignadas = useSelector(getAllHabitacionesNoAsignadas);
  const statusHabitaciones = useSelector(getHabitacionesNoAsignadasStatus);
  const solicitudes = useSelector(getAllReclamosSugerencias);
  const reclamosStatus = useSelector(getReclamosSugerenciasStatus);
  const personal = useSelector(getAllPersonal)
  const personalStatus = useSelector(getPersonalStatus)
  // const habitaciones_no_asignadas = [];
  // const solicitudes = []
  
  const [formData, setFormData] = useState(data);
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [selectedTipo, setSelectedTipo] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedWing, setSelectedWing] = useState();
  const [selectedOtro, setSelectedOtro] = useState();
  const [selectedPabellon, setSelectedPabellon] = useState();
  const [pabellonWing, setPabellonWing] = useState(0);
  const [piso, setPiso] = useState();
  const [selectedItems2, setSelectedItems2] = useState([]);
  const [calendarCount, setCalendarCount] = useState(1);

  const [fakeStadistictis, setFakeStadistictis] = useState(
    {
      totalCamas: 0,
      totalTerminadas:0,
      totalAsignadas:0,
    }
  );  
  
  const [cant, setCant] = useState(1);
  const handleChangePW = (event) => setPabellonWing(event.target.value)

  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    if(newValue != null){
      setFormData({...formData, campamento: newValue.id, });
      setSelectedWing(null);
      setSelectedPabellon(null);
    } else{
      setFormData({...formData, campamento: 0})
    }
  };
  const handleChangePabellon = (event, newValue) => {
    if(newValue != null){
      setSelectedWing(null);
      setFormData({...formData, pabellon: newValue.id, wing: null })
      setSelectedPabellon(newValue);
    } else{
      setFormData({...formData, pabellon: 0})
    }
  };
  const handleChangeWing = (event, newValue) => {
    if(newValue != null){
      setSelectedPabellon(null);
      setFormData({...formData, wing:  newValue.id, pabellon: null })
      setSelectedWing(newValue);
    } else{
      setFormData({...formData, wing: 0})
    }
  };

  const handleChangeOtro = (event, newValue) => {
    if(newValue != null){
      setSelectedPabellon(null);
      setSelectedWing(null);
      setFormData({...formData, wing: null, pabellon: null, otro: newValue.id })
      setSelectedOtro(newValue);
    } else{
      setFormData({...formData, wing: 0})
    }
  };


  const handleChangeFiltro = (event, newValue) => {
    setTipo(newValue.id);
    // if(newValue != null){
    //   setTipo(newValue.id);
    // } else{
    //   setTipo(null);
    // }
  };

  const onChange = (e) => {
    if(e.target.name == 'piso'){
      setPiso(e.target.value)
    }else{
      setFormData({...formData, [e.target.name]: e.target.value });
    }
  }

  const changeCalendarNumber = (e) => {
    const count = parseInt(e.target.value, 10);
    if (!isNaN(count)) {
      setCalendarCount(count);
    } else {
      setCalendarCount(0);
    }
  };

  useEffect(() => {
    if(regStatus == 'idle'){
    }
    
    if(gruposStatus == 'idle'){
      dispatch(fetchGruposAseo())
      }
    if(planStatus == 'idle'){
    }
    dispatch(fetchPlanificacionDiaria())
    if(camStatus == 'idle'){
      dispatch(fetchCampamentos());
    }
    if(winStatus == 'idle'){
      dispatch(fetchWings());
    }
    if(pabStatus == 'idle'){
      dispatch(fetchEdificios());
    }
    if(otStatus == 'idle'){
      dispatch(fetchOtros());
    }
    if(personalStatus == 'idle'){
      dispatch(fetchPersonalAseo());
    }
    if(statusHabitaciones == 'idle'){
      dispatch(fetchHabitacionesNoAsignadas());
    }
    if(reclamosStatus == 'idle'){
    }
    dispatch(fetchReclamosSugerencias());
    setSelectedTipo(tipos[0]);
  }, [])

  useEffect(() => {
    if((selectedWing || selectedOtro || selectedPabellon) && piso){
      setFakeStadistictis({
        totalCamas: 6,
        totalTerminadas:5,
        totalAsignadas:5,
      })
      
    }
  }, [selectedWing, selectedOtro, selectedPabellon, piso])
  
  useEffect(() => {
    console.log('habitacion no asignada', habitaciones_no_asignadas[0])
  }, [habitaciones_no_asignadas])
  
  
  return (
    <div>
        <Box width={1} mt={0} mb={2}>
            <Container className={styles.container} style={{ padding:0 }}>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open3}
              >
              <CircularProgress color="inherit" />
              </Backdrop>
              
                <div style={{ marginBottom:'0px',marginTop:'14px', minHeight:'300px' }}>
                  <Grid container={true} spacing={1} style={{ alignItems:'start' }}>
                      <Grid item xs={12} md={4} mb={2} >
                        <Card style={{ padding: '16px',overflowY:'auto', backgroundColor:'var(--inst-li-blue)', color:'#2d3037' }}>
                          {rol?.includes('Administrador de Aseo (AAS)') && (
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
 
                                  <NavLink to='/grupos' style={{ display:'flex', alignItems:'start',gap:'5px', textDecoration:'none' }}>
                                      <DashboardIcon className={styles.icon} style={{ marginTop:'3px', color:'#f19d38' }}/>
                                      <div style={{ display:'flex', flexDirection:'column', alignItems:'start' }}>
                                        <Typography className={styles.linkText} style={{ fontWeight:'500', color: '#FFF', marginLeft: '0' }}>Grupos</Typography>
                                        <Typography style={{ marginBottom:'10px',color: '#e5e5e5', marginLeft: '0', fontSize:'12px', border:'none' }}>En este módulo, podrás crear grupos de habitaciones para la asignaciòn del personal aseo.</Typography>
                                      </div>
                                  </NavLink>
                                  <NavLink to='/horarios' style={{ display:'flex', alignItems:'start',gap:'5px', textDecoration:'none' }}>
                                      <DashboardIcon className={styles.icon} style={{ marginTop:'3px', color:'#f19d38' }}/>
                                      <div style={{ display:'flex', flexDirection:'column', alignItems:'start' }}>
                                        <Typography className={styles.linkText} style={{ fontWeight:'500', color: '#FFF', marginLeft: '0' }}>Horarios</Typography>
                                        <Typography style={{ marginBottom:'10px',color: '#e5e5e5', marginLeft: '0', fontSize:'12px', border:'none' }}>En este módulo, podrás crear uno o más rangos de horario donde se desarrollarán las tareas de aseo en la plataforma.</Typography>    
                                      </div>
                                  </NavLink>
                                  <NavLink to='/personal' style={{ display:'flex', alignItems:'start',gap:'5px', textDecoration:'none' }}>
                                      <DashboardIcon className={styles.icon} style={{ marginTop:'3px', color:'#f19d38' }}/>
                                      <div style={{ display:'flex', flexDirection:'column', alignItems:'start' }}>
                                        <Typography className={styles.linkText} style={{ fontWeight:'500', color: '#FFF', marginLeft: '0' }}>Personal de aseo</Typography>
                                        <Typography style={{ marginBottom:'10px',color: '#e5e5e5', marginLeft: '0', fontSize:'12px', border:'none' }}>En este módulo, podrás agregar trabajadores al personal de aseo.</Typography>
                                      </div>
                                  </NavLink>
                                  <NavLink to='/tareas' style={{ display:'flex', alignItems:'start',gap:'5px', textDecoration:'none' }}>
                                    <DashboardIcon className={styles.icon} style={{ marginTop:'3px', color:'#f19d38' }}/>
                                    <div style={{ display:'flex', flexDirection:'column', alignItems:'start' }}>
                                      <Typography className={styles.linkText} style={{ fontWeight:'500', color: '#FFF', marginLeft: '0' }}>Tareas de limpieza</Typography>
                                      <Typography style={{ marginBottom:'10px',color: '#e5e5e5', marginLeft: '0', fontSize:'12px', border:'none' }}>En este módulo, podrás crear las tareas de limpieza que serán asignadas a una planificación.</Typography>
                                    </div>
                                </NavLink>
                                  <NavLink to='/planificacion' style={{ display:'flex', alignItems:'start',gap:'5px', textDecoration:'none' }}>
                                      <DashboardIcon className={styles.icon} style={{ marginTop:'3px', color:'#f19d38' }}/>
                                      <div style={{ display:'flex', flexDirection:'column', alignItems:'start' }}>
                                        <Typography className={styles.linkText} style={{ fontWeight:'500', color: '#FFF', marginLeft: '0' }}>Planificación diaria</Typography>
                                        <Typography style={{ marginBottom:'10px',color: '#e5e5e5', marginLeft: '0', fontSize:'12px', border:'none' }}>En este módulo, podrás asignar personal a los horarios creados en la vista de 'Horarios de aseo'.</Typography>
                                      </div>
                                  </NavLink>
                                  <NavLink to='/planificacion_especifica' style={{ display:'flex', alignItems:'start',gap:'5px', textDecoration:'none' }}>
                                      <DashboardIcon className={styles.icon} style={{ marginTop:'3px', color:'#f19d38' }}/>
                                      <div style={{ display:'flex', flexDirection:'column', alignItems:'start' }}>
                                        <Typography className={styles.linkText} style={{ fontWeight:'500', color: '#FFF', marginLeft: '0' }}>Planificación especifica</Typography>
                                        <Typography style={{ marginBottom:'10px',color: '#e5e5e5', marginLeft: '0', fontSize:'12px', border:'none' }}>En este módulo, podrás crear una planificación para una fecha y hora determinada con una tarea en especifico.</Typography>
                                      </div>
                                  </NavLink>
                                </div>
                          )}
                        </Card>
                          {/* 
                        <Card elevation={1} style={{ padding:'16px', display:'flex', marginTop:'8px', alignItems:'center', backgroundColor:'#FFF', color:'#2d3037' }}>
                              <div style={{ flex:'2' }}>
                                <div>
                                  <label className={styles.label}>Campamento</label>
                                  <Autocomplete
                                    className={styles.textField}
                                    sx={{
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          border: 'none',
                                        },
                                        '&:hover fieldset': {
                                          border: 'none',
                                        },
                                        '&.Mui-focused fieldset': {
                                          border: 'none',
                                        },
                                      },
                                      '.MuiChip-label':{
                                        color: 'white',
                                      },
                                      '.MuiSvgIcon-root':{
                                        color: '#FFF !important',
                                      },
                                      '& .MuiInputBase-input': {
                                        color: '#2d3037',
                                      },
                                      '& .MuiInputLabel-root': {
                                        color: '#2d3037',
                                      },
                                      '& .MuiAutocomplete-clearIndicator': {
                                        color: '#2d3037',
                                      },
                                      '& .MuiAutocomplete-popupIndicator': {
                                        color: '#2d3037',
                                      },
                                      'border': '1px solid #2d3037'
                                    }}

                                    disablePortal
                                    disableClearable
                                    id="campamentos-autocomplete"
                                    options={campamentos}
                                    name="idCampamento"
                                    getOptionLabel={(option) => option.nombre}
                                    onChange={(e, value)=> handleChangeCampamento(e, value)}
                                    renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
                                  />
                                </div>
                                <div>
                                  <label className={styles.label}>Pabellón, Wing, recinto</label>
                                  <Select
                                    className={styles.textField}
                                    sx={{
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          border: 'none',
                                        },
                                        '&:hover fieldset': {
                                          border: 'none',
                                        },
                                        '&.Mui-focused fieldset': {
                                          border: 'none',
                                        },
                                      },
                                      '& .MuiInputBase-input': {
                                        color: '#2d3037',
                                      },
                                      '& .MuiInputLabel-root': {
                                        color: '#2d3037',
                                      },
                                      '& .MuiAutocomplete-clearIndicator': {
                                        color: '#2d3037',
                                      },
                                      '& .MuiAutocomplete-popupIndicator': {
                                        color: '#2d3037',
                                      },
                                      'border': '1px solid #2d3037'
                                    }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={pabellonWing}
                                    label="Age"
                                    onChange={handleChangePW}
                                    style={{ width:'100%' }}
                                  >
                                    <MenuItem value={1}>Pabellón</MenuItem>
                                    <MenuItem value={2}>Wing</MenuItem>
                                    <MenuItem value={3}>Recinto</MenuItem>
                                  </Select>
                                </div>
                                {pabellonWing == 0 && ( 
                                  <div>
                                      <label className={styles.label}>---</label>
                                      <Autocomplete
                                       className={styles.textField}
                                        sx={{
                                          '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                              border: 'none',
                                            },
                                            '&:hover fieldset': {
                                              border: 'none',
                                            },
                                            '&.Mui-focused fieldset': {
                                              border: 'none',
                                            },
                                          },
                                          '.MuiChip-label':{
                                            color: 'white',
                                          },
                                          '.MuiSvgIcon-root':{
                                            color: '#FFF !important',
                                          },
                                          '& .MuiInputBase-input': {
                                            color: '#2d3037',
                                          },
                                          '& .MuiInputLabel-root': {
                                            color: '#2d3037',
                                          },
                                          '& .MuiAutocomplete-clearIndicator': {
                                            color: '#2d3037',
                                          },
                                          '& .MuiAutocomplete-popupIndicator': {
                                            color: '#2d3037',
                                          },
                                          'border': '1px solid #2d3037'
                                        }}
                                        disablePortal
                                        disableClearable
                                        id="sectores-autocomplete"
                                        name="pabellon"
                                        multiple
                                        disabled
                                        value={selectedPabellon}
                                        options={pabellones ? pabellones : [] }
                                        getOptionLabel={(option) => option.identificador}
                                        onChange={handleChangePabellon}    
                                        renderInput={(params) => <TextField {...params} label="" />}
                                    />
                                  </div>
                                 )}
                                {pabellonWing == 1 && ( 
                                  <div>
                                      <label className={styles.label}>Pabellón</label>
                                      <Autocomplete
                                        className={styles.textField}
                                        sx={{
                                          '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                              border: 'none',
                                            },
                                            '&:hover fieldset': {
                                              border: 'none',
                                            },
                                            '&.Mui-focused fieldset': {
                                              border: 'none',
                                            },
                                          },
                                          '.MuiChip-label':{
                                            color: 'white',
                                          },
                                          '.MuiSvgIcon-root':{
                                            color: '#FFF !important',
                                          },
                                          '& .MuiInputBase-input': {
                                            color: '#2d3037',
                                          },
                                          '& .MuiInputLabel-root': {
                                            color: '#2d3037',
                                          },
                                          '& .MuiAutocomplete-clearIndicator': {
                                            color: '#2d3037',
                                          },
                                          '& .MuiAutocomplete-popupIndicator': {
                                            color: '#2d3037',
                                          },
                                          'border': '1px solid #2d3037'
                                        }}
                                        disablePortal
                                        disableClearable
                                        id="sectores-autocomplete"
                                        name="pabellon"
                                        value={selectedPabellon}
                                        options={pabellones ? pabellones : [] }
                                        getOptionLabel={(option) => option.identificador}
                                        onChange={handleChangePabellon}    
                                        renderInput={(params) => <TextField {...params} label="Selecciona un pabellón" />}
                                    />
                                  </div>
                                 )} 
                                {pabellonWing == 2 && ( 
                                  <div>
                                      <label className={styles.label}>Wing</label>
                                      <Autocomplete
                                        className={styles.textField}
                                          sx={{
                                            '& .MuiOutlinedInput-root': {
                                              '& fieldset': {
                                                border: 'none',
                                              },
                                              '&:hover fieldset': {
                                                border: 'none',
                                              },
                                              '&.Mui-focused fieldset': {
                                                border: 'none',
                                              },
                                            },
                                            '.MuiChip-label':{
                                              color: 'white',
                                            },
                                            '.MuiSvgIcon-root':{
                                              color: '#FFF !important',
                                            },
                                            '& .MuiInputBase-input': {
                                              color: '#2d3037',
                                            },
                                            '& .MuiInputLabel-root': {
                                              color: '#2d3037',
                                            },
                                            '& .MuiAutocomplete-clearIndicator': {
                                              color: '#2d3037',
                                            },
                                            '& .MuiAutocomplete-popupIndicator': {
                                              color: '#2d3037',
                                            },
                                            'border': '1px solid #2d3037'
                                          }}
                                        disablePortal
                                        disableClearable
                                        id="sectores-autocomplete"
                                        name="wing"
                                        value={selectedWing}
                                        options={wings || []}
                                        getOptionLabel={(option) => option.nombre}
                                        onChange={handleChangeWing}
                                        renderInput={(params) => <TextField {...params} label="Selecciona un wing" />}
                                      />
                                  </div>
                                )} 
                                {pabellonWing == 3 && ( 
                                  <div>
                                      <label className={styles.label}>Recinto</label>
                                      <Autocomplete
                                        className={styles.textField}
                                        sx={{
                                          '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                              border: 'none',
                                            },
                                            '&:hover fieldset': {
                                              border: 'none',
                                            },
                                            '&.Mui-focused fieldset': {
                                              border: 'none',
                                            },
                                          },
                                          '.MuiChip-label':{
                                            color: 'white',
                                          },
                                          '.MuiSvgIcon-root':{
                                            color: '#FFF !important',
                                          },
                                          '& .MuiInputBase-input': {
                                            color: '#2d3037',
                                          },
                                          '& .MuiInputLabel-root': {
                                            color: '#2d3037',
                                          },
                                          '& .MuiAutocomplete-clearIndicator': {
                                            color: '#2d3037',
                                          },
                                          '& .MuiAutocomplete-popupIndicator': {
                                            color: '#2d3037',
                                          },
                                          'border': '1px solid #2d3037'
                                        }}
                                        disablePortal
                                        disableClearable
                                        id="sectores-autocomplete"
                                        name="wing"
                                        value={selectedOtro}
                                        options={otros ?  otros : [] }
                                        getOptionLabel={(option) => option.nombre}
                                        onChange={handleChangeOtro}    
                                        renderInput={(params) => <TextField {...params} label="Selecciona un recinto" />}
                                    />
                                  </div>
                                )} 
                                <div>
                                  <label className={styles.label}>Piso</label>
                                  <TextField
                                     className={styles.textField}
                                      sx={{
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            border: 'none',
                                          },
                                          '&:hover fieldset': {
                                            border: 'none',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: 'none',
                                          },
                                        },
                                        '& .MuiInputBase-input': {
                                          color: '#2d3037',
                                        },
                                        '& .MuiInputLabel-root': {
                                          color: '#2d3037',
                                        },
                                        '& .MuiAutocomplete-clearIndicator': {
                                          color: '#2d3037',
                                        },
                                        '& .MuiAutocomplete-popupIndicator': {
                                          color: '#2d3037',
                                        },
                                        'border': '1px solid #2d3037'
                                      }}
                                    variant="outlined"
                                    fullWidth
                                    name='piso'
                                    type="number"
                                    value={piso}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    onChange={(e) => onChange(e)}
                                  />
                                </div>
                              </div>
                              <div style={{ textAlign:'center', display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', flex:'1' }}>
                                <div>
                                  <Typography>N°Total Camas</Typography>
                                  <p>{fakeStadistictis.totalCamas}</p>
                                </div>
                                <div>
                                  <Typography>N°Camas terminadas</Typography>
                                  <p>{fakeStadistictis.totalTerminadas}</p>
                                </div>
                                <div>
                                  <Typography>N°Camas asignadas</Typography>
                                  <p>{fakeStadistictis.totalAsignadas}</p>
                                </div>
                              </div>
                          </Card>
                        */}
                      </Grid>
                      <Grid item xs={12} md={8} mb={2} style={{ height: '100%' }}>
                        <Card elevation={1} style={{ padding:'16px', backgroundColor:'#FFF', color: '#2d3037' }}>
                          <div className={styles.n_meses}>
                            <label className={styles.label}>N° de Meses</label>
                            <TextField
                              className={styles.textField}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                  },
                                  '&:hover fieldset': {
                                    border: 'none',
                                  },
                                  '&.Mui-focused fieldset': {
                                    border: 'none',
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  color: '#2d3037',
                                },
                                '& .MuiInputLabel-root': {
                                  color: '#2d3037',
                                },
                                '& .MuiAutocomplete-clearIndicator': {
                                  color: '#2d3037',
                                },
                                '& .MuiAutocomplete-popupIndicator': {
                                  color: '#2d3037',
                                },
                                'border': '1px solid #2d3037',
                                'marginBottom':'5px'
                              }}
                              variant="outlined"
                              fullWidth
                              name='meses'
                              type="number"
                              inputProps={{ min: 0 }}
                              value={calendarCount}
                              onChange={(e) => changeCalendarNumber(e)}
                            />
                          </div>
                          <Grid container spacing={2}>
                            {[...Array(calendarCount)].map((_, index) => (
                              <Grid item xs={12} sm={calendarCount === 1 ? 12 : 6} key={index}>                                
                                  <Calendario events={selectedItems || []} defaultDate={moment().add(index, 'month').toDate()} />
                              </Grid>
                            ))}
                          </Grid>
                          { regStatus == 'loading' ? <SkeletonTable/> : <TableDash registros={registros} selectedItems={selectedItems} setSelectedItems={setSelectedItems}/>}
                        </Card>
                      </Grid>
                    </Grid>
                </div>
                {/* FILTRO */}
  
                <Card style={{ padding:'16px', backgroundColor:'#FFF', color:'#2d3037' }}>
                  <Grid container={true} spacing={1}>
                    <Grid item xs={12} md={12} style={{ textAlign:'start' }} mb={2}>
                      <Typography variant='h7' style={{ display:'flex', alignItems:'center', gap:'5px', fontWeight:'500' }}>
                        <GridFilterListIcon style={{ background:'black', color:'white', borderRadius:'50%', padding:'3px', fontSize:'20px' }}/>Listado de habitaciones no asignadas
                        </Typography>
                    </Grid>

                    { statusHabitaciones == 'loading' ? <SkeletonTable/> :<TableHabNoAsignadas rows={habitaciones_no_asignadas} addRequestStatus={addRequestStatus} users={users} setOpen3={setOpen3} grupos={grupos} personal={personal} selectedItems={selectedItems2} setSelectedItems={setSelectedItems2} open3={open3} planificaciones={planificaciones}/>}
                    </Grid>
                </Card>    

                <Card style={{ padding:'16px', backgroundColor:'#FFF', color:'#2d3037',marginTop:'10px' }}>
                  <Grid container={true} spacing={1}>

                    <Grid item xs={12} md={12} style={{ textAlign:'start' }} mb={2}>
                      <Typography variant='h7' style={{ display:'flex', alignItems:'center', gap:'5px', fontWeight:'500'  }}><GridFilterListIcon style={{ background:'black', color:'white', borderRadius:'50, %', padding:'3px', fontSize:'20px' }}/>Listado de solicitudes y reclamos</Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                    { reclamosStatus == 'loading' ? <SkeletonTable/> :<Table rows={solicitudes} addRequestStatus={addRequestStatus} users={users} setOpen3={setOpen3} personal={personal}/>}
                    </Grid>
                  </Grid>
                </Card>

            </Container>
          </Box>
    </div>
  )
}

export default DashboardAseo