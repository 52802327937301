import styles from "./components.module.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Popper,
  Select,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getAllCampamentos } from "../../../../redux/features/campamentos/campamentoSlice";
import { useEffect } from "react";
import { updateSector } from "../../../../redux/features/sectores/sectorSlice";
import { getAllWings } from "../../../../redux/features/wings/wingSlice";
import { getAllEdificios } from "../../../../redux/features/edificios/edificioSlice";
import { liveSearchByRut } from "../../../../redux/features/users/userSlice";
import { updateOficina } from "../../../../redux/features/oficinas/oficinaSlice";

export default function Edit({
  open2,
  setOpen2,
  openEdit,
  setOpenEdit,
  seleItem,
  setOpen3,
  campamentos,
  wings,
  pabellones,
  otros,
}) {
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  // const campamentos = useSelector(getAllCampamentos);
  // const wings = useSelector(getAllWings);
  // const pabellones = useSelector(getAllEdificios);

  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [selectedWing, setSelectedWing] = useState(null);
  const [selectedPabellon, setSelectedPabellon] = useState(null);
  const [selectedOtro, setSelectedOtro] = useState(null);
  const [pabellonWing, setPabellonWing] = useState(0);
  const [filterPabellones, setFilterPabellones] = useState([]);
  const [filterWings, setFilterWings] = useState([]);
  const [filterOtros, setFilterOtros] = useState([]);

  useEffect(() => {
    if (seleItem) {
      console.log("seleItem", seleItem);

      setFormData(seleItem);

      if (seleItem?.pabellon) {
        setPabellonWing(1);
        setFilterPabellones(
          filterPabellonesByCampamento(seleItem?.pabellon?.campamento)
        );
        setSelectedPabellon(seleItem?.pabellon);
        setSelectedCampamento(seleItem?.pabellon?.campamento);
      } else if (seleItem?.wing) {
        setPabellonWing(2);
        setFilterPabellones(
          filterWingsByCampamento(seleItem?.wing?.campamento)
        );
        setSelectedWing(seleItem?.wing);
        setSelectedCampamento(seleItem?.wing?.campamento);
      } else {
        setPabellonWing(3);
        setFilterPabellones(
          filterOtrosByCampamento(seleItem?.otro?.campamento)
        );
        setSelectedOtro(seleItem?.otro);
        setSelectedCampamento(seleItem?.otro?.campamento);
      }
    }
  }, [seleItem]);

  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  const handleChangePW = (event) => {
    setPabellonWing(event.target.value);
  };

  const filterPabellonesByCampamento = (campamento) => {
    return pabellones.filter((pabellon) => pabellon.campamento === campamento);
  };

  const filterWingsByCampamento = (campamento) => {
    return wings.filter((wing) => wing.campamento === campamento);
  };

  const filterOtrosByCampamento = (campamento) => {
    return otros.filter((otro) => otro.campamento === campamento);
  };

  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    if (newValue != null) {
      setFormData({ ...formData, campamento: newValue });
      setFilterPabellones(
        pabellones.filter(
          (pabellon) =>
            (pabellon.campamento && pabellon.campamento.id === newValue.id) ||
            pabellon.campamentoId === newValue.id
        )
      );
      setFilterWings(
        wings.filter(
          (wing) =>
            (wing.campamento && wing.campamento.id === newValue.id) ||
            wing.campamentoId === newValue.id
        )
      );
      setFilterOtros(
        otros.filter(
          (edificio) =>
            (edificio?.campamento && edificio.campamento.id === newValue.id) ||
            edificio.campamentoId === newValue.id
        )
      );

      setSelectedWing(null);
      setSelectedPabellon(null);
    } else {
      setFormData({ ...formData, campamento: 0 });
    }
  };
  const handleChangePabellon = (event, newValue) => {
    if (newValue != null) {
      setSelectedWing(null);
      setFormData({ ...formData, pabellon: newValue.id, wing: null });
      setSelectedPabellon(newValue);
    } else {
      setFormData({ ...formData, pabellon: 0 });
    }
  };
  const handleChangeWing = (event, newValue) => {
    if (newValue != null) {
      setSelectedPabellon(null);
      setFormData({ ...formData, wing: newValue.id, pabellon: null });
      setSelectedWing(newValue);
    } else {
      setFormData({ ...formData, wing: 0 });
    }
  };

  const handleChangeOtro = (event, newValue) => {
    if (newValue != null) {
      setSelectedPabellon(null);
      setSelectedWing(null);
      setFormData({
        ...formData,
        wing: null,
        pabellon: null,
        otro: newValue.id,
      });
      setSelectedOtro(newValue);
    } else {
      setFormData({ ...formData, wing: 0 });
    }
  };

  // const onChange = (e) =>
  //   setFormData({ ...formData, [e.target.name]: e.target.value });

  const onChange = (e) => {
    const value = e.target.value;
    const invalidCharacters = /[<>\/]/;

    if (!invalidCharacters.test(value)) {
      setFormData({ ...formData, [e.target.name]: value });
    } else {
      toast.error("Carácter no válido");
    }
  };

  const canSave =
    [
      // formData.campamento,
      formData.pabellon || formData.wing || formData.otro,
      formData.piso,
      formData.numero,
    ].every(Boolean) && addRequestStatus === "idle";

  const handleEdit = async () => {
    try {
      setOpen3(true);
      setAddRequestStatus("loading");
      let resp = await dispatch(updateOficina(formData));
      if (resp.payload.status === "success") {
        setOpenEdit(false);
      }
    } catch (error) {
      console.error("Error al actualizar la oficina", error.message);
    } finally {
      setAddRequestStatus("idle");
      setOpen3(false);
    }
  };

  return (
    <div>
      <Drawer
        anchor="right"
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        PaperProps={{
          sx: {
            width: { xs: "100%", sm: "75%", md: "50%" },
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
      >
        {seleItem && (
          <div>
            <DialogTitle>
              <Grid container={true} spacing={1}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6" className={styles.textIcon}>
                    <ErrorIcon />
                    Estás a punto de actualizar la oficina N°{seleItem?.numero}
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent>
              <Grid container={true} spacing={1} p={1}>
                <Grid item xs={12} sm={6}>
                  <label className={styles.label}>Numero</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="numero"
                    value={formData?.numero || ""}
                    type="number"
                    onChange={(e) => onChange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label className={styles.label}>Piso</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="piso"
                    value={formData?.piso || ""}
                    type="number"
                    onChange={(e) => onChange(e)}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label className={styles.label}>Campamento</label>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    id="campamentos-autocomplete"
                    name="campamento"
                    value={selectedCampamento || ""}
                    options={
                      Array.isArray(campamentos) && campamentos.length > 0
                        ? campamentos
                        : []
                    }
                    getOptionLabel={(option) => option.nombre}
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          overflowY: "auto",
                        }}
                      />
                    )}
                    onChange={(e, value) => handleChangeCampamento(e, value)}
                    renderInput={(params) => (
                      <TextField {...params} label="Selecciona un campamento" />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label className={styles.label}>
                    Pabellón, Wing, Edificio
                  </label>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={pabellonWing}
                    label="Age"
                    onChange={handleChangePW}
                    style={{ width: "100%" }}
                  >
                    <MenuItem value={1}>Pabellón</MenuItem>
                    <MenuItem value={2}>Wing</MenuItem>
                    <MenuItem value={3}>Recinto</MenuItem>
                  </Select>
                </Grid>
                {/* {selectedSector && ( */}
                {pabellonWing == 1 && (
                  <Grid item xs={12} sm={4}>
                    <label className={styles.label}>Pabellón</label>
                    <Autocomplete
                      disablePortal
                      disableClearable
                      id="sectores-autocomplete"
                      name="wing"
                      value={selectedPabellon}
                      options={
                        Array.isArray(filterPabellones) ? filterPabellones : []
                      }
                      getOptionLabel={(option) => option.identificador}
                      onChange={(e, value) => handleChangePabellon(e, value)}
                      renderInput={(params) => (
                        <TextField {...params} label="Selecciona un pabellón" />
                      )}
                    />
                  </Grid>
                )}
                {/* {selectedCampamento && ( */}
                {pabellonWing == 2 && (
                  <Grid item xs={12} sm={4}>
                    <label className={styles.label}>Wing</label>
                    <Autocomplete
                      disablePortal
                      disableClearable
                      id="sectores-autocomplete"
                      name="wing"
                      value={selectedWing}
                      options={filterWings ?? []}
                      getOptionLabel={(option) => option.nombre}
                      onChange={(e, value) => handleChangeWing(e, value)}
                      renderInput={(params) => (
                        <TextField {...params} label="Selecciona un wing" />
                      )}
                    />
                  </Grid>
                )}
                {pabellonWing == 3 && (
                  <Grid item xs={12} sm={4}>
                    <label className={styles.label}>Recinto</label>
                    <Autocomplete
                      disablePortal
                      disableClearable
                      id="sectores-autocomplete"
                      name="wing"
                      value={selectedOtro}
                      options={filterOtros ?? []}
                      getOptionLabel={(option) => option.nombre}
                      onChange={(e, value) => handleChangeOtro(e, value)}
                      renderInput={(params) => (
                        <TextField {...params} label="Selecciona un recinto" />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12}>
                  <label className={styles.label}>Descripción (opcional)</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="descripcion"
                    type="text"
                    value={formData?.descripcion || null}
                    multiline
                    rows={4}
                    maxRows={4}
                    onChange={(e) => onChange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label className={styles.label}>
                    Chapa electrónica (opcional)
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="idChapa"
                    value={formData?.chapa || null}
                    type="text"
                    onChange={(e) => onChange(e)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container={true}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={styles.buttonContainerEdit}
                >
                  <Button
                    onClick={handleEdit}
                    className={styles.bgColor}
                    variant="contained"
                    disabled={!canSave}
                  >
                    {addRequestStatus == "loading" ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      "Actualizar"
                    )}
                  </Button>
                  <Button
                    onClick={() => setOpenEdit(false)}
                    className={styles.bgColorOutlined}
                    variant="outlined"
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </div>
        )}
      </Drawer>
    </div>
  );
}
