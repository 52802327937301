import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';
import { createAxiosFormDataInstance, createAxiosInstance } from "../../../api/axios";

const RESPONSABLES_URL = '/api/v1/Responsable';
const ADD_RESPONSABLES_URL = '/api/v1/Responsable';
const UPDATE_RESPONSABLES_URL = '/api/v1/Responsable/';
const DELETE_RESPONSABLES_URL = '/api/v1/Responsable/';

const axiosInstance = createAxiosInstance();
const axiosFormDataInstance = createAxiosFormDataInstance();

// ACTIONS
export const fetchResponsables = createAsyncThunk("responsables/fetchResponsables", async ()=> {
    try {
        const response = await axiosInstance.get(RESPONSABLES_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const addNewResponsable = createAsyncThunk("responsables/createResponsable", async (responsable, { dispatch }) => {
    try {
        const response = await axiosInstance.post(ADD_RESPONSABLES_URL, {
            problema: responsable.problema,
            correo: responsable.correo,
        });
        if (response.status === 200) {
            toast.success('Registro añadido correctamente.');
            dispatch(fetchResponsables());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error'} ;
    } catch (e) {
        toast.error('Error al añadir el registro.');
        return {message: e.message, status: 'error'};
    }
})
export const deleteResponsable = createAsyncThunk("responsables/deleteResponsable", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.delete(DELETE_RESPONSABLES_URL+id);
        
        if (response.status >= 200 && response.status < 300) {
            toast.success('Registro eliminado correctamente.');
            dispatch(fetchResponsables());
            return {data: {id}, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al eliminar el registro.');
        return e.message;
    }
})
export const updateResponsable = createAsyncThunk("campamentos/updateResponsable", async (upResponsable, {dispatch})=> {
    try {
        const response = await axiosInstance.put(UPDATE_RESPONSABLES_URL+upResponsable.id, {
            id: upResponsable.id,
            problema: upResponsable.problema,
            correo: upResponsable.correo,
        });
        
        if (response.status >= 200 && response.status < 300) {
            toast.success('Registro actualizado correctamente.');
            dispatch(fetchResponsables());
            return { data: response.data, status: 'success'};
        } 
        return {status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar el registro.');
        return e.message;
    }
})


// idle : no hay operaciones en curso
const initialState = {
    responsables: [],
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const responsableSlice = createSlice({
    name:"responsable",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchResponsables.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchResponsables.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.responsables) !== JSON.stringify(action.payload)) { //fetch
                state.responsables = action.payload;
            }
        })
        .addCase(fetchResponsables.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(addNewResponsable.fulfilled, (state, action) => { //ADD
            if(action.payload.status === 'success'){
                state.status = 'succeeded'
                state.responsables.push(action.payload.data);
            } else{
                state.status = 'failed'
            }
        })
        .addCase(deleteResponsable.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(deleteResponsable.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.responsables = state.responsables.filter(responsable => responsable.id !== action.payload.data.id);
            }else{
                state.status = 'failed'    
            }
        })
        .addCase(deleteResponsable.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(updateResponsable.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(updateResponsable.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.responsables = state.responsables.map(responsable => {
                    if (responsable.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return responsable;
                });
            }else{
                state.status = 'failed'
            }
        })
        .addCase(updateResponsable.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    }
})

export const getAllResponsables = (state) => state.responsables.responsables;
export const getResponsablesStatus = (state) => state.responsables.status;
export const getResponsablesError = (state) => state.responsables.error;

export const {} = responsableSlice.actions;
export default responsableSlice.reducer;