import { Outlet } from "react-router-dom";
import Sidebar from "../adminAplicacion/Sidebar";
import PersistentDrawerLeft from "../adminAplicacion/Sidebar_";
import AdminContratoSidebar from "../adminContrato/Sidebar";
// <Sidebar/>

// <Outlet/>

const LayoutAdminContrato = () => {
  return (
    <div className="App">
    <AdminContratoSidebar/>
    </div>
  )
}

export default LayoutAdminContrato