import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Popper, Select, TextField, Typography } from '@mui/material';

const Show = ({ open3, setOpen3, handleClose, seleItem  }) => {
    
  return (
    <Dialog
      open={open3}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ backgroundColor: "#2d3037", color: "#FFF" }}
      >
        {"Resumen solicitud"}
      </DialogTitle>
      <DialogContent style={{ backgroundColor: "#2d3037", color: "#FFF" }}>
        <Grid container={true} spacing={2}>
          <Grid item xs={12} md={12}></Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ backgroundColor: "#2d3037", color: "#FFF" }}>
        <Button onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Show;
