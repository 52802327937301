import styles from "./components.module.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import {
  addNewProducto,
  updateInventario,
} from "../../../redux/features/inventario/inventarioSlice";
import { useNavigate } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";

const data = {
  codigo: "",
  nombre: "",
  descripcion: "",
  stockMinimo: 0,
  tiempoDeReposicion: 0,
  categoria: "",
  duracion: "",
};
const Edit = ({
  open2,
  setOpen2,
  open,
  setOpen,
  seleItem,
  categorias,
  setOpen3,
}) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(data);
  const [selectedCategoria, setSelectedCategoria] = useState();
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const canSave =
    [
      formData.nombre,
      formData.codigo,
      formData.nombre,
      formData.descripcion,
      formData.stockMinimo,
      formData.tiempoDeReposicion,
      formData.categoria,
    ].every(Boolean) && addRequestStatus === "idle";

  const changeSelect = (event) => {
    setFormData({ ...formData, categoria: event.target.value });
  };

  const handleChangeCategoria = (event, newValue) => {
    setSelectedCategoria(newValue);
    if (newValue != null) {
      setFormData({ ...formData, categoria: newValue });
    } else {
      setFormData({ ...formData, categoria: "" });
    }
  };

  useEffect(() => {
    if (seleItem) {
      console.log("seleItem:-->", seleItem);
      const findCat = categorias.find(
        (item) => item.id == seleItem.categoriaId
      );
      console.log("findCat:-->", findCat);
      setFormData({ ...seleItem, categoria: findCat });
      setSelectedCategoria(findCat);
    }
  }, [seleItem]);

  const handleSubmit = async () => {
    if (canSave) {
      try {
        setOpen3(true);
        setAddRequestStatus("loading");
        let resp = await dispatch(updateInventario(formData));
        if (resp.payload.data.success) {
          setFormData(data);
          setOpen(false);
          return;
        }
      } catch (error) {
        console.error("Error al añadir articulo al inventario.", error.message);
      } finally {
        setAddRequestStatus("idle");
        setOpen3(false);
      }
    }
  };

  const navigate = useNavigate();

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display: "flex", gap: "5px" }}>
          <HolidayVillageIcon
            className={styles.iconBgCreate}
            style={{ fontSize: "3rem", marginTop: "0px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="span"
              style={{ fontSize: "24px", lineHeight: "1" }}
            >
              Editar artículo {seleItem?.nombre}
            </Typography>
            <span className={styles.subTitle}>
              Rellena los siguientes campos para actualizar el artículo.
            </span>
          </div>
        </DialogTitle>

        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item xs={12} sm={4}>
              <label className={styles.label}>Codigo</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="codigo"
                value={formData?.codigo}
                type="text"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <label className={styles.label}>Nombre</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="nombre"
                value={formData?.nombre || ""}
                type="text"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label className={styles.label}>Stock mínimo</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                value={formData?.stockMinimo || ""}
                name="stockMinimo"
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <label className={styles.label}>Categoría</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-clearIndicator": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    color: "#2d3037",
                  },
                }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                name="campamento"
                options={
                  Array.isArray(categorias) && categorias.length > 0
                    ? categorias
                    : []
                }
                value={selectedCategoria || ""}
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value) => handleChangeCategoria(e, value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Busca y selecciona una categoría"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className={styles.label}>
                Tiempo promedio de reposición (dias)
              </label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="tiempoDeReposicion"
                value={formData?.tiempoDeReposicion || ""}
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className={styles.label}>Duración (por meses)</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="duracionMeses"
                value={formData?.duracionMeses || ""}
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Descripciòn</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="descripcion"
                type="text"
                value={formData?.descripcion || ""}
                multiline
                rows={4}
                maxRows={4}
                onChange={(e) => onChange(e)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true} spacing={1} style={{ marginBottom: "15px" }}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained"
                disabled={!canSave}
              >
                {addRequestStatus == "loading" ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Actualizar"
                )}
              </Button>
              <Button
                onClick={() => setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Edit;
