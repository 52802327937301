import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createAxiosFormDataInstance,
  createAxiosInstance,
} from "../../../api/axios";

const RECLAMOS_HUESPED = "/api/v1/ReclamosSugerencia/Huesped";
const RECLAMOS_URL = "/api/v1/ReclamosSugerencia";
const ADD_RECLAMO = "/api/v1/ReclamosSugerencia";
const CHANGE_STATUS = "/api/v1/ReclamosSugerencia/ChangeStatus";

const axiosInstance = createAxiosInstance();
const axiosFormDataInstance = createAxiosFormDataInstance();

// ACTIONS
export const fetchReclamosSugerencias = createAsyncThunk(
  "reclamosSugerencias/fetchReclamosSugerencias",
  async () => {
    try {
      const response = await axiosInstance.get(RECLAMOS_URL);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);

export const fetchReclamosSugerenciasHuesped = createAsyncThunk(
  "reclamosSugerencias/fetchReclamosSugerenciasHuesped",
  async () => {
    try {
      const response = await axiosInstance.get(RECLAMOS_HUESPED);
      return response.data;
    } catch (e) {
      return e.message;
    }
  }
);

export const addNewReclamo = createAsyncThunk(
  "reclamosSugerencias/addNewReclamo",
  async (data, { dispatch }) => {
    try {
      console.log(data);
      const response = await axiosFormDataInstance.post(ADD_RECLAMO, {
        CriticidadRequerimientoId: data.criticidadRequirimientoId,
        Descripcion: data.descripcion,
        Tipo: data.tipo,
        habitacionId: data.habitacionId,
        Foto: data.foto,
        RutHuesped: data.rutHuesped.rut,
        ot: data.rutHuesped.ot,
      });
      if (response.status === 200) {
        dispatch(fetchReclamosSugerencias());
        toast.success("Reclamo/Sugerencia ingresado correctamente.");
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      console.log(e.response);
      toast.error(
        "Error al crear el reclamo/sugerencia: " + e.response.data.message
      );
      return { message: e.message, status: "error" };
    }
  }
);

export const addNewReclamoHuesped = createAsyncThunk(
  "reclamosSugerencias/addNewReclamoHuesped",
  async (data, { dispatch }) => {
    try {
      console.log(data);
      const response = await axiosFormDataInstance.post(ADD_RECLAMO, {
        CriticidadRequerimientoId: data.criticidadRequirimientoId,
        Descripcion: data.descripcion,
        Tipo: data.tipo,
        Foto: data.foto,
        RutHuesped: data.rutHuesped.rut,
      });
      if (response.status === 200) {
        dispatch(fetchReclamosSugerenciasHuesped());
        toast.success("Reclamo/Sugerencia ingresado correctamente.");
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      console.log(e.response);
      if (e.response.data.title) {
        toast.error(
          "Error al ingresar el reclamo/sugerencia: " + e.response.data.title
        );
      } else {
        toast.error("Error al crear el reclamo/sugerencia: " + e.response.data);
      }
      return { message: e.message, status: "error" };
    }
  }
);
export const changeStatus = createAsyncThunk(
  "reclamosSugerencias/changeStatus",
  async (data, { dispatch }) => {
    try {
      console.log("id:", data.id);
      console.log("estado:", data.estado);

      const response = await axiosInstance.post(
        CHANGE_STATUS + "?id=" + data.id + "&status=" + data.estado
      );

      if (response.status === 200) {
        dispatch(fetchReclamosSugerencias());
        toast.success(
          "Estado del Reclamo/Sugerencia modificado correctamente."
        );
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      console.log(e.response);
      toast.error(
        "Error al intentar modificar el estado del reclamo/sugerencia: "
      );
      return { message: e.message, status: "error" };
    }
  }
);
export const changeStatusDashAseo = createAsyncThunk(
  "reclamosSugerencias/changeStatusDashAseo",
  async (data, { dispatch }) => {
    try {
      console.log("llega", data);
      const response = await axiosInstance.post(
        CHANGE_STATUS +
          "?id=" +
          data.id +
          "&status=" +
          data.estado +
          "&RutEncargadoAseo=" +
          data.rut.rut
      );

      if (response.status === 200) {
        dispatch(fetchReclamosSugerencias());
        toast.success(
          "Estado del Reclamo/Sugerencia modificado correctamente."
        );
        return { data: response.data, status: "success" };
      }
      return { status: "error" };
    } catch (e) {
      console.log(e.response);
      toast.error(
        "Error al intentar modificar el estado del reclamo/sugerencia: "
      );
      return { message: e.message, status: "error" };
    }
  }
);

// idle : no hay operaciones en curso
const initialState = {
  reclamosSugerencias: [],
  reclamosSugerenciasHuesped: [],
  otro: {},
  status: "idle", //'idle'|'loading'|'succeeded'|'failed'
  error: null,
};

export const reclamosSugerenciasSlice = createSlice({
  name: "otro",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchReclamosSugerenciasHuesped.pending, (state, action) => {
        state.status = "loading";
      }) //fetch
      .addCase(fetchReclamosSugerenciasHuesped.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (
          JSON.stringify(state.reclamosSugerenciasHuesped) !==
          JSON.stringify(action.payload)
        ) {
          //fetch
          state.reclamosSugerenciasHuesped = action.payload;
        }
      })
      .addCase(fetchReclamosSugerenciasHuesped.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchReclamosSugerencias.pending, (state, action) => {
        state.status = "loading";
      }) //fetch
      .addCase(fetchReclamosSugerencias.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (
          JSON.stringify(state.reclamosSugerencias) !==
          JSON.stringify(action.payload)
        ) {
          //fetch
          state.reclamosSugerencias = action.payload;
        }
      })
      .addCase(fetchReclamosSugerencias.rejected, (state, action) => {
        //fetch
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewReclamoHuesped.fulfilled, (state, action) => {
        //ADD
        if (action.status === "success") {
          state.status = "succeeded";
          state.reclamosSugerenciasHuesped.push(action.payload);
        } else {
          state.status = "failed";
        }
      })
      .addCase(addNewReclamo.fulfilled, (state, action) => {
        //ADD
        if (action.status === "success") {
          state.status = "succeeded";
          state.reclamosSugerencias.push(action.payload);
        } else {
          state.status = "failed";
        }
      })
      .addCase(changeStatus.fulfilled, (state, action) => {
        if (action.payload.status === "success") {
          state.status = "succeeded";
          state.reclamosSugerencias = state.reclamosSugerencias.map(
            (reclamo) => {
              if (reclamo.id === action.payload.data.id) {
                return action.payload.data;
              }
              return reclamo;
            }
          );
        } else {
          state.status = "failed";
        }
      })
      .addCase(changeStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      });
  },
});

export const getAllReclamosSugerencias = (state) =>
  state.reclamosSugerencias.reclamosSugerencias;
export const getAllReclamosSugerenciasHuesped = (state) =>
  state.reclamosSugerencias.reclamosSugerenciasHuesped;
export const fetchReclamosSugerenciasHuespedData = (state) =>
  state.reclamosSugerencias.otro;
export const getReclamosSugerenciasStatus = (state) =>
  state.reclamosSugerencias.status;
export const getReclamosSugerenciasError = (state) => state.otros.error;

export const {} = reclamosSugerenciasSlice.actions;
export default reclamosSugerenciasSlice.reducer;
