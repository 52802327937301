import styles from './components/components.module.css'
import { Backdrop, Box, Container } from "@mui/material"
import Header from "../../../components/header/Header"
import MapIcon from '@mui/icons-material/Map';
import TableCard from "../../../components/tableCard/TableCard";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SkeletonTable from "../../../components/Skeleton";
import { useState } from "react";
import { CircularProgress } from '@mui/material';
import { fetchTrazabilidad, getAllTrazabilidad, getTrazabilidadStatus } from '../../../redux/features/users/userSlice';

const Trasabilidad = () => {

  const dispatch = useDispatch();
  const trazabilidad = useSelector(getAllTrazabilidad);
  const status = useSelector(getTrazabilidadStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    if(status == 'idle'){
      dispatch(fetchTrazabilidad());
    }
  }, []);
  
  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open3}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Trazabilidad"
          subtitle="En este módulo, podrás visualizar la trazabilidad completa del sistema, permitiendo el seguimiento detallado de todas las tablas y registros.">
          {<MapIcon style={{ fontSize:'1.5rem' }} className={styles.iconBg}/>}
        </Header>

        <TableCard>
          {/*<Create setOpen2={setOpen2} open2={open2}/> */}
          { status == 'loading' ? <SkeletonTable/> : <Table trazabilidad={trazabilidad || []} setOpen3={setOpen3} /> }
        </TableCard>
      </Container>
    </Box>
  )
}

export default Trasabilidad