import styles from "./components/components.module.css";
import {
  Backdrop,
  Box,
  Container,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import Header from "../../../components/header/Header";
import InfoIcon from "@mui/icons-material/Info";
import TableCard from "../../../components/tableCard/TableCard";
import Table from "./components/Table";
import TablePorAsignar from "./components/TablePorAsignar";
import { useDispatch, useSelector } from "react-redux";
import { forwardRef, useEffect, useState } from "react";
import SkeletonTable from "../../../components/Skeleton";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { sanitizeFilterModel } from "@mui/x-data-grid/hooks/features/filter/gridFilterUtils";
import { toast } from "react-toastify";
import {
  changeRotativos,
  fetchHabitacionesP_INTERNO,
  fetchRotativos,
  fetchTrabajadoresP_INTERNO,
  getAllHabitacionesP_INTERNO,
  getAllRotativos,
  getAllTrabajadoresP_INTERNO,
  getHabitacionesP_INTERNOStatus,
  getRotativosStatus,
} from "../../../redux/features/reservas/reservaSlice";
import { v4 as uuidv4 } from "uuid";
import Slide from "@mui/material/Slide";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const habitaciones = [
  {
    id: 27562,
    numero: "100",
    standard: {
      id: 60,
      nombre: "ROTATIVO",
      descripcion: "",
      campamento: {
        id: 25,
        nombre: "Coposa",
        direccion: "dsaadsa",
        descripcion: "descripción",
        plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
        latitud: "-33.5281715",
        longitud: "-70.5807978",
      },
      campamentoId: 25,
    },
    nroCamarotes: 0,
    nroCamas: 2,
    numeroTotalCamas: 2,
    tipoHuesped: "Solo hombres",
    tieneOxigeno: false,
    pabellon: null,
    idChapa: null,
    piso: 1,
    wing: {
      id: 14,
      nombre: "A",
      descripcion: "",
      numeroPisos: 3,
      numeroHabitaciones: 0,
      generoHuesped: "Unisex",
      campamento: {
        id: 26,
        nombre: "Pioneros",
        direccion: "santiago",
        descripcion: null,
        plano: null,
        latitud: "-33.3660954",
        longitud: "-70.7514249",
      },
      campamentoId: 26,
      standard: [],
    },
    reservasP_INTERNO: [
      {
        trabajador: {
          rut: "12122745-2",
          nombre: "CARLOS ALBERTO",
          apellidoPaterno: "VALDES",
          apellidoMaterno: "VALDES",
          fechaDeNacimiento: "1971-05-07",
          email: "CAVALDES@PSINET.CL",
          telefono: " ",
          cargo: null,
          cargoId: "10000251",
          acreditado: true,
          habitacionPermanente: null,
          enFaena: false,
          turno: "7X7 Grupo Rotativo  2",
        },
        trabajadorRut: "12122745-2",
        fechaInicio: "2024-08-10T18:47:39.98",
        fechaTermino: "2024-08-12T18:47:39.98",
        habitacion: null,
        turno: "7X7 Grupo Rotativo 2",
      },
      {
        trabajador: {
          rut: "8248386-1",
          nombre: "ALEXIS VITAL",
          apellidoPaterno: "MALUENDA",
          apellidoMaterno: "CARVAJAL",
          fechaDeNacimiento: "1969-11-13",
          email: "AVMALUENDA@PSINET.CL",
          telefono: "982846491",
          cargo: null,
          cargoId: "10001001",
          acreditado: true,
          habitacionPermanente: null,
          enFaena: false,
          turno: "7X7 Grupo Rotativo  3",
        },
        trabajadorRut: "8248386-1",
        fechaInicio: "2024-08-10T16:43:06.175",
        fechaTermino: "2024-08-10T16:43:06.175",
        habitacion: null,
        turno: "7X7 Grupo Rotativo 3",
      },
    ],
  },
  {
    id: 27563,
    numero: "101",
    standard: {
      id: 60,
      nombre: "ROTATIVO",
      descripcion: "",
      campamento: {
        id: 25,
        nombre: "Coposa",
        direccion: "dsaadsa",
        descripcion: "descripción",
        plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
        latitud: "-33.5281715",
        longitud: "-70.5807978",
      },
      campamentoId: 25,
    },
    nroCamarotes: 0,
    nroCamas: 2,
    numeroTotalCamas: 2,
    tipoHuesped: "Solo hombres",
    tieneOxigeno: false,
    pabellon: null,
    idChapa: null,
    piso: 1,
    wing: {
      id: 14,
      nombre: "A",
      descripcion: "",
      numeroPisos: 3,
      numeroHabitaciones: 0,
      generoHuesped: "Unisex",
      campamento: {
        id: 26,
        nombre: "Pioneros",
        direccion: "santiago",
        descripcion: null,
        plano: null,
        latitud: "-33.3660954",
        longitud: "-70.7514249",
      },
      campamentoId: 26,
      standard: [],
    },
    reservasP_INTERNO: [
      {
        trabajador: {
          rut: "13974901-4",
          nombre: "MARCELO FERNANDO",
          apellidoPaterno: "BECERRA",
          apellidoMaterno: "LANAS",
          fechaDeNacimiento: "1981-01-07",
          email: "MBECERRA@PSINET.CL",
          telefono: " ",
          cargo: {
            idCargo: "10000251",
            nCargo: "ELECTRICO A",
          },
          cargoId: "10000251",
          acreditado: true,
          habitacionPermanente: null,
          enFaena: false,
          turno: "7X7 Grupo Rotativo  1",
        },
        trabajadorRut: "13974901-4",
        fechaInicio: "2024-08-10T18:47:39.98",
        fechaTermino: "2024-08-12T18:47:39.98",
        habitacion: null,
        turno: "7X7 Grupo Rotativo 1",
      },
    ],
  },
  {
    id: 27564,
    numero: "102",
    standard: {
      id: 60,
      nombre: "ROTATIVO",
      descripcion: "",
      campamento: {
        id: 25,
        nombre: "Coposa",
        direccion: "dsaadsa",
        descripcion: "descripción",
        plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
        latitud: "-33.5281715",
        longitud: "-70.5807978",
      },
      campamentoId: 25,
    },
    nroCamarotes: 0,
    nroCamas: 2,
    numeroTotalCamas: 2,
    tipoHuesped: "Solo hombres",
    tieneOxigeno: false,
    pabellon: null,
    idChapa: null,
    piso: 1,
    wing: {
      id: 14,
      nombre: "A",
      descripcion: "",
      numeroPisos: 3,
      numeroHabitaciones: 0,
      generoHuesped: "Unisex",
      campamento: {
        id: 26,
        nombre: "Pioneros",
        direccion: "santiago",
        descripcion: null,
        plano: null,
        latitud: "-33.3660954",
        longitud: "-70.7514249",
      },
      campamentoId: 26,
      standard: [],
    },
    reservasP_INTERNO: [],
  },
];

const trabajadores = [
  {
    rut: "12441030-4",
    nombre: "DAVID HECTOR",
    apellidoPaterno: "ROJAS",
    apellidoMaterno: "ROJAS",
    fechaDeNacimiento: "1973-02-04",
    email: "DHROJAS@PSINET.CL",
    telefono: " ",
    cargo: {
      idCargo: "10000251",
      nCargo: "ELECTRICO A",
    },
    cargoId: "10000251",
    acreditado: true,
    habitacionPermanente: null,
    enFaena: false,
    turno: "7X7 Grupo Rotativo  2",
  },
  {
    rut: "12620441-8",
    nombre: "MIGUEL RODRIGO",
    apellidoPaterno: "HUANCA",
    apellidoMaterno: "RIVERA",
    fechaDeNacimiento: "1974-10-22",
    email: "MRHUANCA@PSINET.CL",
    telefono: " ",
    cargo: {
      idCargo: "10000251",
      nCargo: "ELECTRICO A",
    },
    cargoId: "10000251",
    acreditado: true,
    habitacionPermanente: null,
    enFaena: false,
    turno: "7X7 Grupo Rotativo 12",
  },
  {
    rut: "13328373-0",
    nombre: "MANUEL ALIRO",
    apellidoPaterno: "LAFUENTE",
    apellidoMaterno: "BALCAZAR",
    fechaDeNacimiento: "1978-09-29",
    email: "MALAFUEN@PSINET.CL",
    telefono: " ",
    cargo: {
      idCargo: "10000251",
      nCargo: "ELECTRICO A",
    },
    cargoId: "10000251",
    acreditado: true,
    habitacionPermanente: null,
    enFaena: false,
    turno: "7X7 Grupo Rotativo  2",
  },
  {
    rut: "13416528-6",
    nombre: "VICTOR ERASMO",
    apellidoPaterno: "OLIVARES",
    apellidoMaterno: "NUÑEZ",
    fechaDeNacimiento: "1978-04-23",
    email: "VENUNEZ@PSINET.CL",
    telefono: " ",
    cargo: {
      idCargo: "10000251",
      nCargo: "ELECTRICO A",
    },
    cargoId: "10000251",
    acreditado: true,
    habitacionPermanente: null,
    enFaena: false,
    turno: "7X7 Grupo Rotativo  4",
  },
  {
    rut: "13528524-2",
    nombre: "EDUARDO ENRIQUE",
    apellidoPaterno: "CASAS-CORDERO",
    apellidoMaterno: "FARIAS",
    fechaDeNacimiento: "1979-03-11",
    email: "EECASAS@PSINET.CL",
    telefono: " ",
    cargo: {
      idCargo: "10000251",
      nCargo: "ELECTRICO A",
    },
    cargoId: "10000251",
    acreditado: true,
    habitacionPermanente: null,
    enFaena: false,
    turno: "7X7 Grupo Rotativo  2",
  },
  {
    rut: "13974901-4",
    nombre: "MARCELO FERNANDO",
    apellidoPaterno: "BECERRA",
    apellidoMaterno: "LANAS",
    fechaDeNacimiento: "1981-01-07",
    email: "MBECERRA@PSINET.CL",
    telefono: " ",
    cargo: {
      idCargo: "10000251",
      nCargo: "ELECTRICO A",
    },
    cargoId: "10000251",
    acreditado: true,
    habitacionPermanente: null,
    enFaena: true,
    turno: "7X7 Grupo Rotativo  1",
  },
  {
    rut: "15022971-5",
    nombre: "JAIME ANDRES",
    apellidoPaterno: "CATALDO",
    apellidoMaterno: "CARCELES",
    fechaDeNacimiento: "1982-12-19",
    email: "JACATALDO@PSINET.CL",
    telefono: " ",
    cargo: {
      idCargo: "10000251",
      nCargo: "ELECTRICO A",
    },
    cargoId: "10000251",
    acreditado: true,
    habitacionPermanente: null,
    enFaena: true,
    turno: "7X7 Grupo Rotativo  3",
  },
  {
    rut: "15026940-7",
    nombre: "BASILIO ARMANDO",
    apellidoPaterno: "VEGA",
    apellidoMaterno: "VILLARROEL",
    fechaDeNacimiento: "1974-03-29",
    email: "BAVEGA@PSINET.CL",
    telefono: "956223860",
    cargo: {
      idCargo: "10000251",
      nCargo: "ELECTRICO A",
    },
    cargoId: "10000251",
    acreditado: true,
    habitacionPermanente: null,
    enFaena: true,
    turno: "7X7 Grupo Rotativo  1",
  },
  {
    rut: "8793333-4",
    nombre: "NICANOR ALBERTO",
    apellidoPaterno: "SOTO",
    apellidoMaterno: "PEZO",
    fechaDeNacimiento: "1970-03-22",
    email: "NASOTO@PSINET.CL",
    telefono: " ",
    cargo: {
      idCargo: "10000251",
      nCargo: "ELECTRICO A",
    },
    cargoId: "10000251",
    acreditado: true,
    habitacionPermanente: null,
    enFaena: false,
    turno: "7X7 Grupo Rotativo  4",
  },
  {
    rut: "18158327-4",
    nombre: "MAURICIO CESAR",
    apellidoPaterno: "AGUERO",
    apellidoMaterno: "VALENCIA",
    fechaDeNacimiento: "1979-05-15",
    email: "MAGUERO@PSINET.CL",
    telefono: " ",
    cargo: {
      idCargo: "10000252",
      nCargo: "ELECTRICO B",
    },
    cargoId: "10000252",
    acreditado: true,
    habitacionPermanente: null,
    enFaena: true,
    turno: "7X7 Grupo Rotativo 13",
  },
  {
    rut: "15684531-0",
    nombre: "CRISTIAN ULISES",
    apellidoPaterno: "PEREZ",
    apellidoMaterno: "DONOSO",
    fechaDeNacimiento: "1983-05-18",
    email: "CPEREZ@PSINET.CL",
    telefono: " ",
    cargo: {
      idCargo: "10000252",
      nCargo: "ELECTRICO B",
    },
    cargoId: "10000252",
    acreditado: true,
    habitacionPermanente: null,
    enFaena: false,
    turno: "7X7 Grupo Rotativo  4",
  },
];

const Rotativos = () => {
  const habitaciones = useSelector(getAllHabitacionesP_INTERNO);
  const statusHabitaciones = useSelector(getHabitacionesP_INTERNOStatus);
  // const rotativos = useSelector(getAllTrabajadoresP_INTERNO);
  const rotativos = useSelector(getAllRotativos);
  const status = useSelector(getRotativosStatus);
  // const status = 'idle';
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [openBack, setOpenBack] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [filterTrabajadoresTabla, setFilterTrabajadoresTabla] = useState([]);
  const [filterTrabajadoresConId, setFilterTrabajadoresConId] = useState([]);
  const [filterHabitaciones, setFilterHabitaciones] = useState([]);
  const [selectedTrabajadores, setSelectedTrabajadores] = useState({
    trabajador: {},
  });
  const [selectedHabitacion, setSelectedHabitacion] = useState({});
  const [filterTrabajadores, setFilterTrabajadores] = useState([]);
  const [turnos, setTurnos] = useState([]);

  const [openModalRemover, setOpenModalRemover] = useState(false);
  const [habitacionRemover, setHabitacionRemover] = useState({});

  const [habitacionesFilterModel, setHabitacionesFilterModel] = useState({
    items: [],
  });
  const handleChange = (event, newValue) => setValue(newValue);
  const handleHabitacionesFilterModelChange = (newFilterModel) =>
    setHabitacionesFilterModel(sanitizeFilterModel(newFilterModel));

  useEffect(() => {
    if (status == "idle") {
      // dispatch(fetchTrabajadoresP_INTERNO());
    }
    dispatch(fetchRotativos());
    if (statusHabitaciones == "idle") {
    }
    dispatch(fetchHabitacionesP_INTERNO());
  }, []);

  useEffect(() => {
    // const trabajadoresConHabitacion = rotativos.filter(item => item.habitacion)
    // console.log('trabajadoresConHabitacion: -->', trabajadoresConHabitacion)
    console.log("rotativos", rotativos);
    const uniqueRuts = new Set();

    // Filtrar los rotativos para eliminar duplicados por rut
    // Verifica si rotativos es null o no, y establece un arreglo vacío si lo es
    // Asegúrate de que rotativos es un arreglo antes de filtrarlo
    const rotativosNoNulos = Array.isArray(rotativos) ? rotativos : [];

    // Filtra los rotativos únicos
    const rotativosUnicos = rotativosNoNulos.filter((item) => {
      const isUnique = !uniqueRuts.has(item.trabajador.rut);
      uniqueRuts.add(item.trabajador.rut); // Añadir el rut al Set
      return isUnique; // Mantener solo los registros con ruts únicos
    });

    // Crea los IDs únicos para cada rotativo
    const rotativosIds = rotativosUnicos.map((item) => ({
      ...item,
      id: uuidv4(),
      trabajador: {
        nombre: item.trabajador.nombre,
        apellidoPaterno: item.trabajador.apellidoPaterno,
        apellidoMaterno: item.trabajador.apellidoMaterno,
        rut: item.trabajador.rut,
        id: uuidv4(),
      },
    }));

    // Actualiza el estado con los rotativos filtrados y con IDs únicos
    setFilterTrabajadoresTabla(rotativosIds);

    console.log("rotativosIds", rotativosIds);

    setFilterTrabajadoresConId(rotativosIds); // este estado no se toca, se utiliza para encontrar a trabajadores con el id
    // console.log('rotativosIds: -->', rotativosIds)
  }, [rotativos]);
  // },[trabajadores]);

  useEffect(() => {
    console.log("selectedHabitacion cambio: ", selectedHabitacion);
  }, [selectedHabitacion]);

  useEffect(() => {
    if (Array.isArray(habitaciones)) {
      const habitacionesCompletas = [];

      habitaciones.forEach((habitacion) => {
        for (let i = 0; i < 4; i++) {
          habitacionesCompletas.push({
            id: uuidv4(),
            habitacion: habitacion,
            numero: habitacion.numero,
            trabajador: habitacion.reservasP_INTERNO[i]
              ? habitacion.reservasP_INTERNO[i].trabajador
              : null,
          });
        }
      });

      console.log("habitacionesCompletas-->: ", habitacionesCompletas);
      setFilterHabitaciones(habitacionesCompletas);
    }
  }, [habitaciones]);

  const validarAsignacion = (trabajador, habitacion) => {
    if (!trabajador.trabajador.nombre) {
      toast.error("Selecciona un trabajador");
      return false;
    }

    if (!habitacion) {
      toast.error("No se ha encontrado la habitación.");
      return false;
    }
    // if (habitacion.grupo_rrhh != trabajador.trabajador.grupo_rrhh) {
    //     toast.warning('El grupo de la habitación no coincide con el del trabajadador seleccionado.');
    //     // return false;
    // }

    let genero = habitacion.pabellon ?? habitacion.wing;
    if (!trabajador.trabajador.genero) {
      toast.warning(
        "Advertencia: El trabajador seleccionado no tiene género registrado."
      );
    } else if (
      (genero.tipoHuesped === "Solo hombres" &&
        trabajador.trabajador.genero !== "Hombre") ||
      (genero.tipoHuesped === "Solo mujeres" &&
        trabajador.trabajador.genero !== "Mujer")
    ) {
      toast.error("Error: Género no permitido en la habitación.");
      return false;
    }
    return true;
  };

  // const seleccionTablaHabitaciones = (selection) => {
  //     console.log('selection habitación: -->', selection[0]);

  //     const habitacionSeleccionada = filterHabitaciones.find((row) => row.idDos === selection[0]);

  //     if (!habitacionSeleccionada) {
  //         toast.error('No se ha encontrado la habitación.');
  //         return;
  //     }

  //     console.log('selectedTrabajadores--->', selectedTrabajadores);
  //     if (Object.keys(selectedTrabajadores).length === 0 || !selectedTrabajadores.trabajador?.id ) {
  //         if (habitacionSeleccionada.trabajador) {
  //             // Si no hay trabajador seleccionado pero la habitación tiene uno asignado, eliminarlo
  //             const findTrab = filterTrabajadoresConId.find(item => item.trabajador.id === habitacionSeleccionada.trabajador.id);
  //             console.log('findTrab---> ', findTrab)

  //             const updatedHabitaciones = filterHabitaciones.map(habitacion =>
  //                 habitacion.idDos === selection[0]
  //                     ? { ...habitacion, trabajador: undefined }
  //                     : habitacion
  //             );
  //             if(!selectedTrabajadores.trabajador?.id){
  //               console.log('habitacionSeleccionada.trabajador ------> ', habitacionSeleccionada.trabajador);
  //               setFilterTrabajadoresTabla(prev => [...prev, findTrab])
  //             }else{
  //               // const findTrab = rotativos.find(item => item.trabajador.rut == habitacionSeleccionada.trabajador.rut);
  //               const findTrab = filterTrabajadoresConId.find(item => item.trabajador.id == habitacionSeleccionada.trabajador.id);
  //               console.log('findTrab: ----->', findTrab);
  //               setFilterTrabajadoresTabla(prev => [...prev, findTrab])
  //             }
  //             setFilterHabitaciones(updatedHabitaciones);
  //             toast.success('Trabajador removido de la habitación.');
  //             return;
  //         } else {
  //             toast.error('Selecciona un trabajador para asignar.');
  //             return;
  //         }
  //     }

  //     if (habitacionSeleccionada?.trabajador?.id) {
  //         toast.error('La habitación ya tiene un trabajador asignado. Deselecciona el trabajador que seleccionaste y vuelve a seleccionar la habitación para liberarla.');
  //         // setSelectedTrabajadores({});
  //         return;
  //     }

  //     //Capturar las 4 habitaciones con el mismo ID, y comprobar si es que ya se encuentra el turno en alguna habitación
  //     const cuatrohab = filterHabitaciones.filter(hab => hab.trabajador && (hab.id == habitacionSeleccionada.id) && (hab.trabajador.turno == selectedTrabajadores.trabajador.turno));
  //     console.log('cuatrohab', cuatrohab);
  //     if(cuatrohab.length > 0){
  //       toast.error('El turno ya se encuentra registrado en la habitación.');
  //       return;
  //     }

  //     if (!validarAsignacion(selectedTrabajadores, habitacionSeleccionada)) return;

  //     console.log('habitacionSeleccionada: --> ', habitacionSeleccionada);
  //     console.log('Trabajador a asignar: --> ', selectedTrabajadores);
  //     // return;

  //     if(selectedTrabajadores?.habitacion){ // En caso de que el trabajador tenga una habitación ya asignada
  //       const habitacionActualDelTrabajador = selectedTrabajadores?.habitacion;
  //       const estaEnElListado = filterHabitaciones.findIndex(hab => hab.idDos === habitacionActualDelTrabajador.idDos)
  //       console.log('estaEnElListado: -->',estaEnElListado);
  //       if(estaEnElListado === -1){ // la habitación actual del trabajador está en el listado, hay que actualizarla
  //         console.log('No está en el listado --->');

  //         const updatedHabitaciones = [...filterHabitaciones, habitacionActualDelTrabajador]

  //         const updatedHabitacionesNuevo = updatedHabitaciones.map(habitacion =>  //habitacion seleccionada
  //             habitacion.idDos === selection[0]
  //                 ? { ...habitacion, trabajador: selectedTrabajadores?.trabajador }
  //                 : habitacion
  //         );

  //         const updatedTrabajador = filterTrabajadoresTabla.map(trab =>  // trabajador seleccionado con trab
  //             // trab.rut === selectedTrabajadores?.trabajador?.rut
  //             trab.id === selectedTrabajadores?.trabajador?.id
  //                 ? { ...trab, habitacion: undefined }
  //                 : trab
  //         );

  //         // const updatedTrabajadores = updatedTrabajador.filter(trab => trab.rut != selectedTrabajadores?.rut );
  //         const updatedTrabajadores = updatedTrabajador.filter(trab => trab.id != selectedTrabajadores?.id );
  //         setFilterTrabajadoresTabla(updatedTrabajadores);
  //         setFilterHabitaciones(updatedHabitacionesNuevo);

  //         toast.success('Trabajador asignado exitosamente.');
  //         setOpenAlert(false);
  //         setSelectedTrabajadores({});

  //         return

  //       } else{ //está en el listado
  //         console.log('Se encontro la habitación');
  //         filterHabitaciones[estaEnElListado].trabajador = null;
  //         setFilterHabitaciones(filterHabitaciones);
  //       }
  //     }

  //     // Si la habitación no  tiene un trabajador asignado:
  //     // Se agrega el trabajador a la habitación
  //     const updatedHabitaciones = filterHabitaciones.map(habitacion =>
  //         habitacion.idDos === selection[0]
  //             ? { ...habitacion, trabajador: selectedTrabajadores.trabajador }
  //             : habitacion
  //     );

  //     // const updatedTrabajadores = filterTrabajadoresTabla.filter(trab => trab.trabajador.rut != selectedTrabajadores?.trabajador?.rut );
  //     // Actualiza la propiedad habitación del trabajador seleccionado en lugar de removerlo de la tabla
  //     const updatedTrabajadores = filterTrabajadoresTabla.map(trab => {
  //       if (trab.trabajador.id === selectedTrabajadores?.trabajador?.id) {
  //         return {
  //           ...trab,
  //           habitacion: habitacionSeleccionada,
  //         };
  //       }
  //       return trab;
  //     });

  //     setFilterHabitaciones(updatedHabitaciones);
  //     setFilterTrabajadoresTabla(updatedTrabajadores);
  //     toast.success('Trabajador asignado exitosamente.');
  //     setOpenAlert(false);
  //     setSelectedTrabajadores({});
  // };
  const seleccionTablaHabitaciones = (selection) => {
    const findHabitacion = filterHabitaciones.find(
      (hab) => hab.id == selection[0]
    );

    console.log("findHabitacion--> ", findHabitacion);

    if (findHabitacion && findHabitacion.trabajador) {
      console.log("findHabitacion.trabajador", findHabitacion.trabajador);
      setOpenModalRemover(true);
      setHabitacionRemover(findHabitacion);
      return;
    }

    setSelectedHabitacion(findHabitacion);
    setValue(1);
  };

  const handleRowSelectionModelChange = (selectionModel) => {
    if (!selectedHabitacion.habitacion) {
      toast.error("Selecciona una habitación.");
      return;
    }
    const findTrabajador = filterTrabajadoresTabla.find(
      (trab) => trab.trabajador.id === selectionModel[0]
    );

    const mappedHabitaciones = filterHabitaciones.map((hab) => {
      if (hab.id == selectedHabitacion.id) {
        console.log("hab: -->>> >", hab);
        return {
          ...hab,
          trabajador: findTrabajador,
        };
      } else {
        return hab;
      }
    });

    console.log("selectedHabitacion->: ", selectedHabitacion);
    console.log("findTrabajador->: ", findTrabajador);

    handleSubmit(mappedHabitaciones, selectedHabitacion, findTrabajador);

    console.log("selectionModel->: ", selectionModel);
    console.log("mappedHabitaciones->: ", mappedHabitaciones);
  };

  const handleRemover = async () => {
    console.log("habitacionRemover: -> ", habitacionRemover);
    try {
      setOpenBack(true);

      const mappedHabitaciones = filterHabitaciones.map((hab) => {
        if (hab.id === habitacionRemover.id) {
          return {
            ...hab,
            trabajador: null,
          };
        } else {
          return hab;
        }
      });
      setFilterHabitaciones(mappedHabitaciones);
      // const resp = await dispatch();
      // if(resp.payload.success == 'success'){
      //   setHabitacionRemover({})
      toast.success("Trabajador de removido de la habitación.");
      setOpenModalRemover(false);
      // }
    } catch (error) {
      console.log("Error en el handleRemover: ", handleRemover);
    } finally {
      setOpenBack(false);
    }
  };

  const handleSubmit = async (
    mappedHabitaciones,
    selectedHabitacion,
    findTrabajador
  ) => {
    try {
      console.log("---->>aquí---->>");
      setOpenBack(true);
      const resp = await dispatch(
        changeRotativos({
          rut: findTrabajador.rut ?? findTrabajador.trabajador.rut,
          habitacionId: selectedHabitacion.habitacion.id,
          turno: findTrabajador.turno,
          fechaInicio: findTrabajador.fechaInicio,
          fechaTermino: findTrabajador.fechaTermino,
        })
      );
      console.log("resp in rotativosNew", resp);
      if (resp.payload.status == "success") {
        dispatch(fetchRotativos());
        dispatch(fetchHabitacionesP_INTERNO());

        setSelectedHabitacion({});
        setTurnos([]);
        setValue(0);
      }
      // setFilterTrabajadores(filterTrabajadoresTabla);

      // setFilterHabitaciones(mappedHabitaciones);
    } catch (error) {
      console.log("error----------->", error);
    } finally {
      setOpenBack(false);
    }
  };

  return (
    <Box width={1} mt={0} mb={2}>
      <Dialog
        open={openModalRemover}
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle style={{ display: "flex", gap: "5px", margin: "0" }}>
          <InfoIcon
            style={{ fontSize: "3rem", marginTop: "0px" }}
            className={styles.iconBg}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="span"
              style={{ fontSize: "24px", marginBottom: "0", lineHeight: "1" }}
            >
              Estás seguro de remover al trabajador de la habitación N°{" "}
              {habitacionRemover &&
                habitacionRemover.habitacion &&
                habitacionRemover.habitacion.numero}
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Grid container={true}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button
                onClick={handleRemover}
                className={styles.bgColorModalRemover}
                variant="contained"
              >
                Remover
              </Button>
              <Button
                onClick={() => {
                  setOpenModalRemover(false);
                  setHabitacionRemover({});
                }}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBack}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Gestión de turnos rotativos"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados a los turnos rotativos y la asignación de trabajadores a habitaciones."
        >
          {<FactCheckIcon style={{ fontSize: "1.5rem" }} />}
        </Header>

        <TableCard>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Habitaciones a optimizar" {...a11yProps(0)} />
            <Tab label="Trabajadores propuestos" {...a11yProps(1)} />
          </Tabs>
          <Box sx={{ width: "100%" }}>
            <Collapse in={openAlert}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                Trabajador seleccionado:{" "}
                {selectedTrabajadores
                  ? selectedTrabajadores?.trabajador?.nombre
                  : ""}{" "}
                {selectedTrabajadores
                  ? selectedTrabajadores?.trabajador?.apellidoPaterno
                  : ""}{" "}
                {selectedTrabajadores
                  ? selectedTrabajadores?.trabajador?.apellidoMaterno
                  : ""}
                ,{" "}
                {selectedTrabajadores
                  ? selectedTrabajadores?.trabajador?.genero
                  : ""}
              </Alert>
            </Collapse>
          </Box>

          <CustomTabPanel value={value} index={0}>
            {statusHabitaciones === "loading" || status === "loading" ? (
              <SkeletonTable />
            ) : (
              <Table
                habitaciones={filterHabitaciones || []}
                setOpenBack={setOpenBack}
                habitacionesFilterModel={habitacionesFilterModel}
                handleHabitacionesFilterModelChange={
                  handleHabitacionesFilterModelChange
                }
                seleccionTablaHabitaciones={seleccionTablaHabitaciones}
              />
            )}
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            {statusHabitaciones === "loading" || status === "loading" ? (
              <SkeletonTable />
            ) : (
              <TablePorAsignar
                trabajadores={filterTrabajadoresTabla || []}
                setOpenBack={setOpenBack}
                selectedTrabajadores={selectedTrabajadores}
                handleRowSelectionModelChange={handleRowSelectionModelChange}
                selectedHabitacion={selectedHabitacion}
                habitaciones={filterHabitaciones || []}
                setFilterTrabajadores={setFilterTrabajadores}
                filterTrabajadores={filterTrabajadores}
                setTurnos={setTurnos}
                turnos={turnos}
              />
            )}
          </CustomTabPanel>
        </TableCard>
      </Container>
    </Box>
  );
};

export default Rotativos;
