import styles from "./components/components.module.css";
import { Backdrop, Box, CircularProgress, Container } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import Header from "../../../components/header/Header";
import TableCard from "../../../components/tableCard/TableCard";
import Table from "./components/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCargos,
  fetchHuespedes,
  getAllHuespedes,
  getHuespedStatus,
  getUsersError,
} from "../../../redux/features/users/userSlice";

import { useEffect, useState } from "react";
import Create from "./components/Create";
import {
  fetchRoles,
  getAllRoles,
  getRolesStatus,
} from "../../../redux/features/roles/rolSlice";
import {
  fetchEmpresas,
  getAllEmpresas,
  getEmpresasStatus,
} from "../../../redux/features/empresas/empresaSlice";
import SkeletonTable from "../../../components/Skeleton";

const Huespedes = () => {
  const dispatch = useDispatch();

  const users = useSelector(getAllHuespedes);
  const status = useSelector(getHuespedStatus);
  const roles = useSelector(getAllRoles);
  const rolesStatus = useSelector(getRolesStatus);
  const empresas = useSelector(getAllEmpresas);
  const empresasStatus = useSelector(getEmpresasStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    if (status == "idle") {
      dispatch(fetchHuespedes());
      dispatch(fetchCargos());
    }
    if (rolesStatus == "idle") {
      dispatch(fetchRoles());
    }
    if (empresasStatus == "idle") {
      dispatch(fetchEmpresas());
    }
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Gestión de Huéspedes"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados con los huéspedes en la plataforma."
        >
          {
            <GroupIcon
              style={{ fontSize: "1.5rem" }}
              className={styles.iconBgDelete}
            />
          }
        </Header>

        <TableCard>
          <Create
            roles={roles}
            empresas={empresas}
            open2={open2}
            setOpen2={setOpen2}
          />
          {status == "loading" ? (
            <SkeletonTable />
          ) : (
            <Table users={users} setOpen3={setOpen3} />
          )}
        </TableCard>
      </Container>
    </Box>
  );
};

export default Huespedes;
