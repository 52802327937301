import styles from './components.module.css';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Popper, Select, TextField, Typography, Card, Box } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { salidaBodegaShowColumns } from '../../../lib/columns';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';

const Show = ({ open3, setOpen3, handleClose, seleItem  }) => {
    
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = salidaBodegaShowColumns();
  useEffect(() => { seleItem && console.log('seleItem:', seleItem) }, [seleItem]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCellValue, setSelectedCellValue] = useState('');
  const [selectedCellName, setSelectedCellName] = useState('');
  
  const handleCellClick = (params) => {
    console.log(params);
    if (params.field === 'opciones') return;
    setSelectedCellValue(params.value);
    setSelectedCellName(params.field);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog
      open={open3}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
         backgroundColor:'#efefef',
         color:'#2d3037',
         borderRadius:'0'
        }
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
      >
        {"Resumen solicitud de salida"}
      </DialogTitle>
      
      <DialogContent>
        <Card style={{ padding:'16px' }}>
          <Grid container={true} spacing={2} style={{ overflowX:'auto' }}>
              <Grid item xs={12} md={4}>
                <Typography align="left">Bodega:</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.bodega?.nombre}</strong></Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography align="left">Fecha:</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.fecha && format(seleItem?.fecha, 'dd-MM-yyyy') } { seleItem?.fecha && format(seleItem?.fecha, 'HH:mm')}</strong></Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography align="left">Numero documento:</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.numeroDocumento}</strong></Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography align="left">Monto Total:</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography><strong style={{ fontWeight: '500' }}>${seleItem?.montoTotal}</strong></Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography align="left">Retira:</Typography>
              </Grid>
              <Grid item xs={12} md={8} style={{ marginBottom:'15px' }}>
                <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.rutRetira}</strong></Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography align="left">Observaciones:</Typography>
              </Grid>
              <Grid item xs={12} md={8} style={{ marginBottom:'15px' }}>
                <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.observaciones}</strong></Typography>
              </Grid>
          </Grid>
        </Card>

        <Grid container={true} spacing={2} mt={2} style={{ backgroundColor:'#212227', borderRadius:'5px', overflowX:'auto' }}>
            {seleItem?.imagen1 && <Grid item xs={12} md={4}>
              <img style={{ height: '100%', width: '100%' }} src={`${process.env.REACT_APP_API_URL}${seleItem?.imagen1}`} alt="img_plano" />
            </Grid>}
            {seleItem?.imagen2 && <Grid item xs={12} md={4}>
              <img style={{ height: '100%', width: '100%' }} src={`${process.env.REACT_APP_API_URL}${seleItem?.imagen2}`} alt="img_plano" />
            </Grid>}
            {seleItem?.imagen3 && <Grid item xs={12} md={4}>
              <img style={{ height: '100%', width: '100%' }} src={`${process.env.REACT_APP_API_URL}${seleItem?.imagen3}`} alt="img_plano" />
            </Grid>}
        </Grid>

        <Card style={{ padding:'16px' }}>
          <Grid container={true} mt={2}>
              <Grid item xs={12} md={12}>
                <DataGrid
                  getRowId={row=>row.id ? row.id : Math.random() }
                  lots={{ toolbar: GridToolbar }}
                  rows={seleItem?.salidaArticulos || []}
                  onCellClick={handleCellClick}
                  columns={finalColumns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  localeText={{
                  ...esES.components.MuiDataGrid.defaultProps.localeText,
                  noRowsLabel: "No hay datos disponibles",
                  pagination: {
                      labelRowsPerPage: "Filas por página:",
                  }
                  }}
                  classes={{ menuIcon: styles.menuIcon }}
                  style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
                  sx={{ 
                  '& .MuiDataGrid-cell': {
                    color: '#2d3037',
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-columnHeader': {
                    color: '#2d3037',
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-footerContainer': {
                    color: '#2d3037',
                    borderTop: '1px solid #c6c6c6',
                  },
                  '& .MuiTablePagination-root': {
                    color: '#2d3037',
                    borderTop: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-toolbarContainer': {
                    color: '#2d3037',
                  },
                  '& .MuiDataGrid-menu': {
                    color: '#2d3037',
                  },
                  '& .MuiSvgIcon-root':{
                    color: '#9ca91c',
                  },
                  '& .MuiTablePagination-actions': {
                    color: '#2d3037', // Color for pagination actions
                  },
                  '& .MuiTablePagination-select': {
                    color: '#2d3037', // Color for pagination select
                  },
                  '& .MuiTablePagination-selectLabel': {
                    color: '#2d3037', // Color for pagination select label
                  },
                  '& .MuiTablePagination-displayedRows': {
                    color: '#2d3037', // Color for displayed rows text
                  },
                  '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                    color: 'red',
                  },
                  '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                    color: 'red',
                  },
                  }}
                  slotProps={{
                        toolbar:{
                          sx:{
                            color:'#FFF'
                          }
                        },
                        pagination: {
                          sx: {
                            color: '#f19d38',
                          },
                        },
                        menu: {
                          sx: {
                            color: '#f19d38',
                          },
                        },
                  }}
                />
                <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                  <DialogContent>
                    <Box>
                      {selectedCellValue ?? '-'}
                    </Box>
                  </DialogContent>
                </Dialog>
              </Grid>
            </Grid>
          </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "#2d3037" }}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Show;
