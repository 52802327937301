import styles from "./header.module.css"
import { Button, Card, CardContent, Typography } from '@mui/material';

const Header = ({ title, subtitle, children }) => {
  return (
    <Card className={styles.cardBg}>
        <CardContent className={styles.cardContent}>
          <span style={{ marginTop:'10px' }} className={styles.iconHeader}>
            {children}
          </span>
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant="h4" className={styles.header} style={{ fontWeight: '600'  }}>{title}</Typography>
            { subtitle && <span className={styles.subtitle}>{subtitle}</span> }
          </div>
        </CardContent>
    </Card>
  )
}

export default Header