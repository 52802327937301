import styles from "./components.module.css";
import { useState } from "react";
import { monitoreoColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import Delete from "./Delete";
import Edit from "./Edit";
import More from "./More";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import BlockIcon from "@mui/icons-material/Block";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import WorkIcon from "@mui/icons-material/Work";
import { removerTrabajadorP_INTERNOdeListado2 } from "../../../../redux/features/reservas/reservaSlice";
import { useDispatch } from "react-redux";

const Table = ({ rows, setOpen3, scroll, setScroll }) => {
  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [openEdit, setOpenEdit] = useState(false);
  const [openMore, setOpenMore] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCellValue, setSelectedCellValue] = useState("");
  const [selectedCellName, setSelectedCellName] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleCellClick = (params) => {
    if (params.field === "opciones" || params.field === "icono") return;

    const cellValue = params.value?.props?.children || params.value;

    if (params.field === "detalle") {
      const formattedDetail = cellValue ? cellValue.split(",").join("\n") : "-";

      setSelectedCellValue(formattedDetail);
      setOpenDialog(true);
      return;
    }

    setSelectedCellName(params.field);
    setSelectedCellValue(cellValue);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const handleRemover = async (id) => {
    try {
      setLoading(true);
      const resp = await dispatch(removerTrabajadorP_INTERNOdeListado2(id));
      // if (resp.payload.status === "success") {
      //   setOpenMore(false);
      // }
    } catch (error) {
      console.log("error al remover", error);
    } finally {
      setLoading(false);
    }
  };

  const finalColumns = monitoreoColumns(
    setOpen,
    setSeleItem,
    setOpenMore,
    handleRemover
  );

  return (
    <div
      style={{
        marginTop: "16px",
        width: "100%",
        overflowX: "auto",
        overflowY: "hidden",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/*
      <Edit
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        seleItem={seleItem}
        setOpen3={setOpen3}
      />
      */}
      <More openMore={openMore} setOpenMore={setOpenMore} seleItem={seleItem} />
      <Grid container={true} mb={2}>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
          }}
        >
          <Typography style={{ fontSize: "20px", fontWeight: 600 }}>
            Simbología
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <NewReleasesIcon style={{ color: "#000", marginRight: "5px" }} />
          <Typography>Trabajador nuevo</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          style={{ display: "flex", flexDirection: "row", gapx: "5px" }}
        >
          <BlockIcon style={{ color: "#000", marginRight: "5px" }} />
          <Typography>Trabajador no vigente</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          style={{ display: "flex", flexDirection: "row", gapx: "5px" }}
        >
          <SwapHorizIcon style={{ color: "#000", marginRight: "5px" }} />
          <Typography>Cambio de turno</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          style={{ display: "flex", flexDirection: "row", gapx: "5px" }}
        >
          <WorkIcon style={{ color: "#000", marginRight: "5px" }} />
          <Typography>Cambio de cargo</Typography>
        </Grid>
      </Grid>
      <DataGrid
        getRowId={(row) => (row.id ? row.id : Math.random())}
        lots={{ toolbar: GridToolbar }}
        rows={rows ?? []}
        columns={finalColumns}
        onCellClick={handleCellClick}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15, 20]}
        localeText={{
          ...esES.components.MuiDataGrid.defaultProps.localeText,
          noRowsLabel: "No hay datos disponibles",
          pagination: {
            labelRowsPerPage: "Filas por página:",
          },
        }}
        classes={{ menuIcon: styles.menuIcon }}
        style={{ maxHeight: "500px", border: "none" }}
        sx={{
          "& .MuiDataGrid-cell": {
            color: "#2d3037",
            borderBottom: "1px solid #c6c6c6",
          },
          "& .MuiDataGrid-columnHeader": {
            color: "#2d3037",
            borderBottom: "1px solid #c6c6c6",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "1px solid #c6c6c6",
          },
          "& .MuiDataGrid-footerContainer": {
            color: "#2d3037",
            borderTop: "1px solid #c6c6c6",
          },
          "& .MuiTablePagination-root": {
            color: "#2d3037",
            borderTop: "1px solid #c6c6c6",
          },
          "& .MuiDataGrid-toolbarContainer": {
            color: "#2d3037",
          },
          "& .MuiDataGrid-menu": {
            color: "#2d3037",
          },
          "& .MuiSvgIcon-root": {
            color: "#9ca91c",
          },
          "& .MuiTablePagination-actions": {
            color: "#2d3037", // Color for pagination actions
          },
          "& .MuiTablePagination-select": {
            color: "#2d3037", // Color for pagination select
          },
          "& .MuiTablePagination-selectLabel": {
            color: "#2d3037", // Color for pagination select label
          },
          "& .MuiTablePagination-displayedRows": {
            color: "#2d3037", // Color for displayed rows text
          },
          "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
            color: "red",
          },
          "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root":
            {
              color: "red",
            },
        }}
        slotProps={{
          toolbar: {
            sx: {
              color: "#FFF",
            },
          },
          pagination: {
            sx: {
              color: "#f19d38",
            },
          },
          menu: {
            sx: {
              color: "#f19d38",
            },
          },
        }}
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              whiteSpace: "pre-line",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {selectedCellValue ?? "-"}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Table;
