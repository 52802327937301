import styles from "./components.module.css";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Popper,
  Select,
  TextField,
  Typography,
  Card,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";

import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { fetchTrabajadores } from "../../../../redux/features/reservas/reservaSlice";
import { trabajadoresResumenReservaColumns } from "../../../../lib/columns";
import { toast } from "react-toastify";

const Show = ({ open3, setOpen3, handleClose, seleItem }) => {
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = trabajadoresResumenReservaColumns();
  const dispatch = useDispatch();
  const [trabajadores, setTrabajadores] = useState([]);

  const trabajadoresSolicitud = async (id) => {
    try {
      const resp = await dispatch(fetchTrabajadores(id));
      setTrabajadores(resp.payload);
      console.log("trabajadores: ", resp.payload);
    } catch (error) {
      toast.error("Error al traer los trabajadores");
      console.log("error al traer trabajadores", error);
    }
  };

  useEffect(() => {
    if (seleItem) {
      trabajadoresSolicitud(seleItem.id);
      console.log("seleItem: ", seleItem);
    }
  }, [seleItem]);

  return (
    <Dialog
      open={open3}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          backgroundColor: "#efefef",
          color: "#2d3037",
          borderRadius: "0",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {"Resumen solicitud de reserva"}
      </DialogTitle>

      <DialogContent>
        <Card style={{ padding: "16px" }}>
          <Grid container={true} spacing={2} style={{ overflowX: "auto" }}>
            <Grid item xs={12} md={4}>
              <Typography align="left">Estado:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                <strong
                  style={{
                    fontWeight: "500",
                    background: seleItem?.razonRechazo
                      ? "#bd1717"
                      : seleItem?.estado === "Pendiente"
                      ? "#0086ae"
                      : seleItem?.estado === "Programada"
                      ? "#008000"
                      : "",
                    padding: "2px 10px",
                    borderRadius: "4px",
                    color: "white",
                  }}
                >
                  {seleItem?.estado}
                </strong>
              </Typography>
            </Grid>
            {seleItem?.razonRechazo && (
              <>
                <Grid item xs={12} md={4}>
                  <Typography align="left">Razón rechazo:</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography>
                    <strong style={{ fontWeight: "500" }}>
                      {seleItem?.razonRechazo}
                    </strong>
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12} md={4}>
              <Typography align="left">Solicitante:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                <strong style={{ fontWeight: "500" }}>
                  {seleItem?.nombreSolicitante} {seleItem?.apellidoSolicitante}
                </strong>
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Fecha Solicitud:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                <strong style={{ fontWeight: "500" }}>
                  {seleItem?.fechaSolicitud &&
                    format(seleItem?.fechaSolicitud, "dd-MM-yyyy")}{" "}
                  {seleItem?.fecha && format(seleItem?.fechaSolicitud, "HH:mm")}
                </strong>
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Empresa:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                <strong style={{ fontWeight: "500" }}>
                  {seleItem?.empresa?.razonSocial}
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography align="left">Fecha Ingreso:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                <strong style={{ fontWeight: "500" }}>
                  {seleItem?.fechaIngreso &&
                    format(seleItem?.fechaIngreso, "dd-MM-yyyy")}
                </strong>
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Fecha Salida:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                <strong style={{ fontWeight: "500" }}>
                  {seleItem?.fechaSalida &&
                    format(seleItem?.fechaSalida, "dd-MM-yyyy")}
                </strong>
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Vicepresidencia:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                <strong style={{ fontWeight: "500" }}>
                  {seleItem?.vicepresidencia}
                </strong>
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Gerencia:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                <strong style={{ fontWeight: "500" }}>
                  {seleItem?.gerencia}
                </strong>
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">siArea:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                <strong style={{ fontWeight: "500" }}>
                  {seleItem?.sI_Area}
                </strong>
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Total Trabajadores:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                <strong style={{ fontWeight: "500" }}>
                  {seleItem?.trabajadoresTotales}
                </strong>
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Observaciones:</Typography>
            </Grid>
            <Grid item xs={12} md={8} style={{ marginBottom: "15px" }}>
              <Typography>
                <strong style={{ fontWeight: "500" }}>
                  {seleItem?.observaciones ?? "Sin observaciones"}
                </strong>
              </Typography>
            </Grid>
          </Grid>
        </Card>

        <Card style={{ padding: "16px", marginTop: "10px" }}>
          <Grid container={true} mt={2}>
            <Grid item xs={12} md={12}>
              <DataGrid
                getRowId={(row) =>
                  row.trabajador?.rut ? row.trabajador?.rut : Math.random()
                }
                lots={{ toolbar: GridToolbar }}
                rows={trabajadores || []}
                rowHeight={80}
                columns={finalColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 20]}
                localeText={{
                  ...esES.components.MuiDataGrid.defaultProps.localeText,
                  noRowsLabel: "No hay datos disponibles",
                  pagination: {
                    labelRowsPerPage: "Filas por página:",
                  },
                }}
                classes={{ menuIcon: styles.menuIcon }}
                style={{ maxHeight: "500px", color: "#FFF", border: "none" }}
                sx={{
                  "& .MuiDataGrid-cell": {
                    color: "#2d3037",
                    borderBottom: "1px solid #c6c6c6",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    color: "#2d3037",
                    borderBottom: "1px solid #c6c6c6",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    borderBottom: "1px solid #c6c6c6",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    color: "#2d3037",
                    borderTop: "1px solid #c6c6c6",
                  },
                  "& .MuiTablePagination-root": {
                    color: "#2d3037",
                    borderTop: "1px solid #c6c6c6",
                  },
                  "& .MuiDataGrid-toolbarContainer": {
                    color: "#2d3037",
                  },
                  "& .MuiDataGrid-menu": {
                    color: "#2d3037",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#9ca91c",
                  },
                  "& .MuiTablePagination-actions": {
                    color: "#2d3037", // Color for pagination actions
                  },
                  "& .MuiTablePagination-select": {
                    color: "#2d3037", // Color for pagination select
                  },
                  "& .MuiTablePagination-selectLabel": {
                    color: "#2d3037", // Color for pagination select label
                  },
                  "& .MuiTablePagination-displayedRows": {
                    color: "#2d3037", // Color for displayed rows text
                  },
                  "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer":
                    {
                      color: "red",
                    },
                  "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root":
                    {
                      color: "red",
                    },
                }}
                slotProps={{
                  toolbar: {
                    sx: {
                      color: "#FFF",
                    },
                  },
                  pagination: {
                    sx: {
                      color: "#f19d38",
                    },
                  },
                  menu: {
                    sx: {
                      color: "#f19d38",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "#2d3037" }}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Show;
