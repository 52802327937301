import styles from './components.module.css';
import { useState } from "react";
import { aseoDashboardColumns } from "../../../lib/columns";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import SkeletonTable from '../../../components/Skeleton'
import Edit from './Edit';
import { useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Box, Card } from '@mui/material';

const Table = ({rows, addRequestStatus, setOpen3, personal}) => {
    const [seleItem, setSeleItem] = useState();
    const [open, setOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [openEdit, setOpenEdit] = useState(false);
    const finalColumns = aseoDashboardColumns(setOpen, setSeleItem, setOpenEdit);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedCellValue, setSelectedCellValue] = useState('');
    const [selectedCellName, setSelectedCellName] = useState('');
  
    const handleCellClick = (params) => {
      console.log('params: ', params);
      if (params.field === 'opciones') return;
      if (params.field === 'asignado') {
        setSelectedCellName(params.field);
        const nombre = params.row.encargadoASeo ? params.row.encargadoASeo.nombre : '';
        const apellido = params.row.encargadoASeo ? params.row.encargadoASeo.apellido ?? (params.encargadoASeo.apellidoPaterno + ' '+ params.encargadoASeo.apellidoMaterno) : '';
        setSelectedCellValue(nombre + ' '+ apellido);
        setOpenDialog(true);
      }else{
        setSelectedCellName(params.field);
        setSelectedCellValue(params.value);
        setOpenDialog(true);
      }
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };
    

  return (
    <div style={{ width:'100%' }}>
    { addRequestStatus == 'loading' 
        ? <SkeletonTable/> 
        : <Card style={{ padding: '16px', backgroundColor:'#FFF', color:'#2d3037' }}>
            <Edit openEdit={openEdit} setOpenEdit={setOpenEdit} seleItem={seleItem}
             setOpen3={setOpen3} personal={personal}/>
            <DataGrid
              lots={{ toolbar: GridToolbar }}
              rows={rows}
              onCellClick={handleCellClick}
              columns={finalColumns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 15, 20]}
              localeText={{
                  ...esES.components.MuiDataGrid.defaultProps.localeText,
                  noRowsLabel: "No hay datos disponibles",
                  pagination: {
                      labelRowsPerPage: "Filas por página:",
                  }
              }}
              classes={{ menuIcon: styles.menuIcon }}
              style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
              sx={{ 
                    '& .MuiDataGrid-cell': {
                      color: '#2d3037',
                      borderBottom: '1px solid #c6c6c6',
                    },
                    '& .MuiDataGrid-columnHeader': {
                      color: '#2d3037',
                      borderBottom: '1px solid #c6c6c6',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      borderBottom: '1px solid #c6c6c6',
                    },
                    '& .MuiDataGrid-footerContainer': {
                      color: '#2d3037',
                      borderTop: '1px solid #c6c6c6',
                    },
                    '& .MuiTablePagination-root': {
                      color: '#2d3037',
                      borderTop: '1px solid #c6c6c6',
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                      color: '#2d3037',
                    },
                    '& .MuiDataGrid-menu': {
                      color: '#2d3037',
                    },
                    '& .MuiSvgIcon-root':{
                      color: '#9ca91c',
                    },
                    '& .MuiTablePagination-actions': {
                      color: '#2d3037', // Color for pagination actions
                    },
                    '& .MuiTablePagination-select': {
                      color: '#2d3037', // Color for pagination select
                    },
                    '& .MuiTablePagination-selectLabel': {
                      color: '#2d3037', // Color for pagination select label
                    },
                    '& .MuiTablePagination-displayedRows': {
                      color: '#2d3037', // Color for displayed rows text
                    },
                    '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                      color: 'red',
                    },
                    '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                      color: 'red',
                    },
              }}
              slotProps={{
                        toolbar:{
                          sx:{
                            color:'#FFF'
                          }
                        },
                        pagination: {
                          sx: {
                            color: '#f19d38',
                          },
                        },
                        menu: {
                          sx: {
                            color: '#f19d38',
                          },
                        },
              }}
            />
        </Card>
     }
     <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
          <DialogContent>
            <Box>
              {selectedCellValue ?? '-'}
            </Box>
          </DialogContent>
        </Dialog>
    </div>
  )
}

export default Table