import styles from "./components.module.css";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useState, forwardRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import Slide from "@mui/material/Slide";
import { GridFilterListIcon } from "@mui/x-data-grid";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import { createAxiosInstance } from "../../../../api/axios";
import TableEdit from "./TableEdit.jsx";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Edit2({
  openEdit,
  setOpenEdit,
  seleItem,
  setOpen3,
  open3,
  tareasList,
}) {
  const [formData, setFormData] = useState(null);
  const [openTaskList, setOpenTaskList] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const axiosInstance = createAxiosInstance();
  const [habitaciones, setHabitaciones] = useState([]);
  const [oficinas, setOficinas] = useState([]);
  const [espacios, setEspacios] = useState([]);

  const getGrupo = async () => {
    try {
      const response = await axiosInstance.get(
        "/api/v1/Aseo/grupo-habitacion/" + seleItem.gruposHabitaciones[0].id
      );
      setHabitaciones(response.data.habitaciones ?? []);
      setOficinas(response.data.oficinas ?? []);
      setEspacios(response.data.espaciosComunes ?? []);
    } catch (error) {
      console.log(error);
    }
  };
  const getHabitacionesListasPlanificacionDiaria = async () => {
    try {
      const response = await axiosInstance.get(
        "/api/v1/Aseo/GetHabitacionesListas/" + seleItem?.id + "/" + 1
      );
      const habitacionesLimpias = response.data;

      const habitacionesMap = new Map();
      habitacionesLimpias.forEach((limpia) => {
        if (limpia.habitacion) {
          habitacionesMap.set(limpia.habitacion.id, limpia);
        }
        if (limpia.oficina) {
          habitacionesMap.set(limpia.oficina.id, limpia);
        }
        if (limpia.espacio) {
          habitacionesMap.set(limpia.espacio.id, limpia);
        }
      });

      const actualizarAseoRealizado = (lista, tipo) => {
        return lista.map((item) => {
          const habitacionLimpia = habitacionesMap.get(item.id);

          const observaciones =
            habitacionLimpia?.observaciones || item.observaciones || "";

          return {
            ...item,
            aseoRealizado: !!habitacionLimpia,
            observaciones,
          };
        });
      };

      // Actualizamos los estados
      setHabitaciones((prevHabitaciones) =>
        actualizarAseoRealizado(prevHabitaciones, "habitacion")
      );
      setOficinas((prevOficinas) =>
        actualizarAseoRealizado(prevOficinas, "oficina")
      );
      setEspacios((prevEspacios) =>
        actualizarAseoRealizado(prevEspacios, "espacio")
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setFormData(seleItem);
    setHabitaciones([]);
    setOficinas([]);
    setEspacios([]);
    if (seleItem) {
      setOpenTaskList(true);
      console.log("seleItem", seleItem);
    }
    getGrupo();
    getHabitacionesListasPlanificacionDiaria();
  }, [seleItem]);

  useEffect(() => {
    console.log("habitaciones", habitaciones);
  }, [habitaciones]);

  // const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

  // const canSave = [formData?.nombre
  //     // , formData?.campamentoId
  // ].every(Boolean) && addRequestStatus === 'idle';

  // const handleEdit = async () => {
  //     try {
  //         setOpen3(true);
  //         setAddRequestStatus('loading');
  //         let resp = await dispatch(updateSector(formData))
  //         if(resp.payload.status === 'success'){
  //             setOpen3(false);
  //         }else{
  //             setOpen3(false);
  //         }
  //     } catch (error) {
  //         toast.error('Error al actualizar el campamento.');
  //         console.error('Error al actualizar el campamento', error.message);
  //     } finally{
  //         setAddRequestStatus('idle');
  //         setOpenEdit(false);
  //     }
  // }

  return (
    <div>
      {/* MODAL PARA VER LA LISTA DE TAREAS AL SELECCIONAR UNA HABITACION */}
      <Dialog
        open={openTaskList}
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        TransitionComponent={Transition}
        zIndex={99}
        onBackdropClick={() => {
          setOpenTaskList(false);
        }}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              fontWeight: "400",
            }}
          >
            <GridFilterListIcon
              style={{
                background: "black",
                color: "#FFF",
                borderRadius: "50%",
                padding: "3px",
                fontSize: "20px",
              }}
            />
            <span>Lista de tareas</span>
          </div>
          <span
            style={{
              display: "block",
              fontSize: "14px",
              fontWeight: "400",
              marginTop: "10px",
            }}
          >
            Estimado colaborador. Por favor considerar que para las
            habitaciones, oficinas y espaciones comunes asignados debe realizar
            las siguientes tareas
          </span>
        </DialogTitle>
        <DialogContent>
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              padding: "0 20px",
            }}
          >
            {seleItem?.tareas &&
              seleItem?.tareas?.map((item) => (
                <li key={item.id}>{item.nombre}</li>
              ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenTaskList(false)}
            className={styles.bgColorOutlined}
            style={{ border: "none" }}
            variant="outlined"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODAL PRINCIPAL */}
      <Dialog
        open={openEdit}
        maxWidth="lg"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        TransitionComponent={Transition}
        onBackdropClick={() => {
          setOpenEdit(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <DialogTitle
          style={{ display: "flex", gap: "5px", alignItems: "center" }}
        >
          <HolidayVillageIcon className={styles.iconBgCreate} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="span" style={{ fontSize: "24px" }}>
              Listado de habitaciones, oficinas y espacios comunes
            </Typography>
            <span className={styles.subTitle}>
              Selecciona el icono "ojo" en la tabla para ver actualizar el
              estado de aseo en cada habitación, oficina y espacio común.
            </span>
          </div>
        </DialogTitle>

        <DialogContent>
          <Grid container={true} spacing={1} mt={1}>
            <Grid item xs={12} md={12}>
              <TableEdit
                registros={habitaciones}
                oficinas={oficinas}
                espacios={espacios}
                planificacionId={seleItem?.id}
                setOpen3={setOpen3}
                open3={open3}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                tareasList={seleItem?.tareas}
                setOpenEditModal={setOpenEdit}
                getGrupo={getGrupo}
                getHabitacionesListasPlanificacionDiaria={
                  getHabitacionesListasPlanificacionDiaria
                }
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container={true} style={{ padding: "0 16px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              className={styles.buttonContainer}
              style={{ justifyContent: "end" }}
            >
              {/*<Button 
                    onClick={handleEdit}
                    className={styles.bgColor}
                    variant="contained" 
                    disabled={!canSave}>
                    {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                </Button> */}
              <Button
                onClick={() => setOpenEdit(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
