import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Autocomplete, Backdrop, Button, CircularProgress, Container, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCampamentos } from '../../../../redux/features/campamentos/campamentoSlice';
import { useEffect } from 'react';
import { updateSector } from '../../../../redux/features/sectores/sectorSlice';

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3 }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState(null);
    const dispatch = useDispatch();
    const campamentos = useSelector(getAllCampamentos);

    useEffect(() => {
            setFormData(seleItem)
    }, [seleItem])

    const handleChangeCampamento = (e, value) => setFormData({ ...formData, idCampamento: value ? value.id : null });
        
    const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

    const canSave = [formData?.nombre
        // , formData?.campamentoId
    ].every(Boolean) && addRequestStatus === 'idle';

    const handleEdit = async () => {
        try {
            setOpen3(true);
            setAddRequestStatus('loading');
            let resp = await dispatch(updateSector(formData))
            if(resp.payload.status === 'success'){
                setOpen3(false);
            }else{
                setOpen3(false);
            }
        } catch (error) {
            toast.error('Error al actualizar el campamento.');
            console.error('Error al actualizar el campamento', error.message);
        } finally{
            setAddRequestStatus('idle');
            setOpenEdit(false);
        }
    }

  return (
    <div>
      <Drawer
        anchor='right'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        {seleItem && (
            <Box width={1} mt={0} mb={2}>
                <Container >
                {/*{JSON.stringify(formData)} */}
                    <Grid container={true} spacing={1} p={2}>
                        <Grid item  xs={12} sm={12} mb={2}>
                            <Typography variant="h6" className={styles.textIcon}><ErrorIcon/>Estás a punto de actualizar el sector {seleItem?.nombre}</Typography>
                        </Grid>
                        <Grid item  xs={12} sm={12}>
                            <label className={styles.label}>Nombre</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='nombre'
                                type="text"
                                value={formData?.nombre}
                                onChange={(e) => onChange(e)}
                            />
                        </Grid>

                        {/* <Grid item  xs={12} sm={12}>
                            <label className={styles.label}>Campamento</label>
                            <Autocomplete
                                disablePortal
                                id="campamentos-autocomplete"
                                options={campamentos ? campamentos : [] }
                                name="idCampamento"
                                getOptionLabel={(option) => option.nombre}
                                value={campamentos.find(option => option.id === formData?.campamentoId) || null}
                                onChange={(e, value) => handleChangeCampamento(e, value)}
                                renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
                            />
                        </Grid> */}
                        <Grid item  xs={12} sm={12}>
                            <label className={styles.label}>Descripción (opcional)</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='descripcion'
                                type="text"
                                value={formData?.descripcion}
                                multiline
                                rows={2}
                                maxRows={4}
                                onChange={(e) => onChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                            <Button 
                                onClick={handleEdit}
                                className={styles.bgColor}
                                variant="contained" 
                                disabled={!canSave}
                                >
                                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                            </Button>
                            <Button 
                                onClick={()=>setOpenEdit(false)}
                                className={styles.bgColorOutlined}
                                variant="outlined" 
                                >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        )}
      </Drawer>
    </div>
  );
}