import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';

import axios, { createAxiosInstance, createAxiosInstanceHabitaciones } from "../../../api/axios";

const HABITACION_URL = '/api/v1/Habitacion';
const HABITACION_DISPONIBLE_URL = '/api/v1/Solicitud/HabitacionesDisponibles';
const HABITACION_DISPONIBLE_URL_P_INTERNO = '/api/v1/Solicitud/GetHabitacionesDisponiblesP_INTERNO';
const ADD_HABITACION_URL = '/api/v1/Habitacion';
const DELETE_HABITACION_URL = '/api/v1/Habitacion/';
const UPDATE_HABITACION_URL = '/api/v1/Habitacion/';
const CAMBIAR_STATE_URL = '/api/v1/Habitacion/habilitacion';

const axiosInstance = createAxiosInstance();
const axiosInstanceHab = createAxiosInstanceHabitaciones();

// ACTIONS
export const fetchHabitaciones = createAsyncThunk("habitaciones/fetchHabitaciones", async ()=> {
    try {
        const response = await axiosInstanceHab.get(HABITACION_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchHabitacionesDisponibles = createAsyncThunk("habitaciones/fetchHabitacionesDisponibles", async (data) => {
    console.log('llega', data);
    try {
        const response = await axiosInstanceHab.get(HABITACION_DISPONIBLE_URL+'?FechaIngreso='+data.FechaIngreso+'&FechaSalida='+data.FechaSalida);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchHabitacionesDisponiblesP_INTERNO = createAsyncThunk("habitaciones/fetchHabitacionesDisponiblesP_INTERNO", async (data) => {
    console.log('llega', data);
    try {
        const response = await axiosInstanceHab.get(HABITACION_DISPONIBLE_URL_P_INTERNO+'?FechaIngreso='+data.FechaIngreso+'&FechaSalida='+data.FechaSalida);
        return response.data;
      } catch (e) {
        return e.message;
    }
})

export const addNewHabitacion = createAsyncThunk("habitaciones/createHabitaciones", async (initialCampamento, {dispatch}) => {
    try {
        console.log(initialCampamento)
        const response = await axiosInstance.post(ADD_HABITACION_URL, {
            pabellonId: initialCampamento.pabellon, //es pabellonId o wingId
            wingId: initialCampamento.wing,//es pabellonId o wingId
            tipoArea: initialCampamento.tipoArea,
            numero: initialCampamento.numero,
            piso: initialCampamento.piso,
            descripcion: initialCampamento.descripcion,
            idChapa: initialCampamento.idChapa,
            chapaElectronica: initialCampamento.chapa_electronica,
            numeroTotalCamas: initialCampamento.n_camas,
            nroCamarotes:initialCampamento.cantidad_camarotes, //VERIFICAR DEPENDENCIA
            nroCamas:initialCampamento.cantidad_camas_individuales, //VERIFICAR DEPENDENCIA
            tipoHuesped: initialCampamento.tipo_huesped,
            cargosReservados: initialCampamento.tipo_cargo,
            standardId: initialCampamento.tipo_standar, //reemplaza a tipo baño y turnos
            tieneOxigeno: initialCampamento.tieneOxigeno,
            productos: initialCampamento.productos,
            tipoCama: initialCampamento.tipoCama,
            metrosCuadrados: initialCampamento.metrosCuadrados,
            
            });
        if (response.status === 200 ) {
            toast.success('Habitación creada correctamente.');
            dispatch(fetchHabitaciones());
            return { data: response.data, status: 'success' }
        }
        return { status: 'error' };
      } catch (e) {
        toast.error('Error al crear la Habitación.');
        return {message: e.message, status: 'error'};
    }
})

export const cambiarStateHabitacion = createAsyncThunk("habitaciones/cambiarStateHabitacion", async (data, {dispatch})=> {
    console.log('data', data);
    try {
        const response = await axiosInstance.put(CAMBIAR_STATE_URL,
            [
                {
                  "id": data.id,
                  "razonInhabilitacion": "",
                  "habilitada": data.habilitada
                }
              ]
        );
        
        if (response.status === 200) {
            toast.success('Estado de la habitación actualizado correctamente.');
            dispatch(fetchHabitaciones());
            return { data: data ,status: 'success'};
        } 
        return { status: 'error'};
    } catch (e) {
        toast.error('Error al actualizar el estado de la Habitación.');
        return e.message;
    }
});

export const deleteHabitacion = createAsyncThunk("habitaciones/deleteEspacio", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.delete(DELETE_HABITACION_URL+id);
        
        if (response.status === 200) {
            toast.success('Habitación eliminada correctamente.');
            dispatch(fetchHabitaciones());
            return { data: {id} ,status: 'success'};
        } 
        return { status: 'error'};
    } catch (e) {
        toast.error('Error al eliminar la Habitación.');
        return e.message;
    }
});

export const updateHabitacion = createAsyncThunk("habitaciones/updateHabitacion", async (upHabitacion, {dispatch})=> {
    console.log('id', upHabitacion.id);
    try {
        const response = await axiosInstance.put(UPDATE_HABITACION_URL+upHabitacion.id, {
            pabellonId: upHabitacion.pabellonId, //es pabellonId o wingId
            wingId: upHabitacion.wingId,//es pabellonId o wingId
            numero: upHabitacion.numero,
            piso: upHabitacion.piso,
            descripcion: upHabitacion.descripcion,
            idChapa: upHabitacion.idChapa,
            numeroTotalCamas: upHabitacion.numeroTotalCamas,
            nroCamarotes:upHabitacion.nroCamarotes, //VERIFICAR DEPENDENCIA
            nroCamas:upHabitacion.nroCamas, //VERIFICAR DEPENDENCIA
            tipoHuesped: upHabitacion.tipoHuesped,
            cargosReservados: upHabitacion.cargosReservados,
            standardId: upHabitacion.standardId,
            tieneOxigeno: upHabitacion.tieneOxigeno,
            productos: upHabitacion.productosId,
            tipoCama: upHabitacion.tipoCama,
            metrosCuadrados: upHabitacion.metrosCuadrados,
        });
        
        if (response.status === 200 ) {
            toast.success('Habitación actualizada correctamente.');
            dispatch(fetchHabitaciones());
            return { data: response.data ,status: 'success'};
        } 
        return { data: response.data, status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar la habitación.');
        return e.message;
    }
})


// idle : no hay operaciones en curso
const initialState = {
    habitaciones: [],
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const habitacionSlice = createSlice({
    name:"habitacion",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchHabitaciones.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchHabitaciones.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.habitaciones) !== JSON.stringify(action.payload)) { //fetch
                state.habitaciones = action.payload;
            }
        })
        .addCase(fetchHabitaciones.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(addNewHabitacion.fulfilled, (state, action) => { //ADD
            if(action.payload.status === 'success'){
                state.status = 'succeeded'
                state.habitaciones.push(action.payload.data);
            }else{
                state.status = 'failed'    
            }
        })
        .addCase(deleteHabitacion.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(deleteHabitacion.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.habitaciones = state.habitaciones.filter(habitacion => habitacion.id !== action.payload.data.id);
            }else{
                state.status = 'failed'
            }
        })
        .addCase(deleteHabitacion.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(updateHabitacion.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(updateHabitacion.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.habitaciones = state.habitaciones.map(habitacion => {
                    if (habitacion.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return habitacion;
                });
            }else{
                state.status = 'failed'
            }
        })
        .addCase(updateHabitacion.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    }
})

export const getAllHabitaciones = (state) => state.habitaciones.habitaciones;
export const getHabitacionesStatus = (state) => state.habitaciones.status;
export const getHabitacionesError = (state) => state.habitaciones.error;

export const {} = habitacionSlice.actions;
export default habitacionSlice.reducer;