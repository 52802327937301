import styles from "./components.module.css";
import { useEffect, useState } from "react";
import { asignacionEspaciosColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import Delete from "./Delete";
import Edit from "./Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { v4 as uuidv4 } from "uuid";

const Table = ({ registros, setOpen3 }) => {
  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogItems, setDialogItems] = useState([]);
  const [mappedRegistros, setMappedRegistros] = useState([]);

  const handleCellClick = (items) => {
    setDialogItems(items);
    setOpenDialog(true);
  };

  useEffect(() => {
    const registrosConId = registros.map((registro) => ({
      ...registro,
      id: uuidv4(),
    }));

    setMappedRegistros(registrosConId);
  }, [registros]);

  const finalColumns = asignacionEspaciosColumns(
    setOpen,
    setSeleItem,
    setOpenEdit,
    handleCellClick
  );

  return (
    <div style={{ marginTop: "16px" }}>
      <Delete
        open={open}
        setOpen={setOpen}
        seleItem={seleItem}
        setOpen3={setOpen3}
      />
      <Edit
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        seleItem={seleItem}
        setOpen3={setOpen3}
        registros={registros}
      />
      <DataGrid
        getRowId={(row) => row?.id ?? Math.random()}
        rowHeight={100}
        // getRowId={row=>row.espacioAlmacenamiento?.id ?? Math.random() }
        lots={{ toolbar: GridToolbar }}
        rows={mappedRegistros}
        columns={finalColumns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15, 20]}
        localeText={{
          ...esES.components.MuiDataGrid.defaultProps.localeText,
          noRowsLabel: "No hay datos disponibles",
          pagination: {
            labelRowsPerPage: "Filas por página:",
          },
        }}
        classes={{ menuIcon: styles.menuIcon }}
        style={{ maxHeight: "500px", color: "#FFF", border: "none" }}
        sx={{
          "& .MuiDataGrid-cell": {
            color: "#2d3037",
            borderBottom: "1px solid #c6c6c6",
          },
          "& .MuiDataGrid-columnHeader": {
            color: "#2d3037",
            borderBottom: "1px solid #c6c6c6",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "1px solid #c6c6c6",
          },
          "& .MuiDataGrid-footerContainer": {
            color: "#2d3037",
            borderTop: "1px solid #c6c6c6",
          },
          "& .MuiTablePagination-root": {
            color: "#2d3037",
            borderTop: "1px solid #c6c6c6",
          },
          "& .MuiDataGrid-toolbarContainer": {
            color: "#2d3037",
          },
          "& .MuiDataGrid-menu": {
            color: "#2d3037",
          },
          "& .MuiSvgIcon-root": {
            color: "#9ca91c",
          },
          "& .MuiTablePagination-actions": {
            color: "#2d3037", // Color for pagination actions
          },
          "& .MuiTablePagination-select": {
            color: "#2d3037", // Color for pagination select
          },
          "& .MuiTablePagination-selectLabel": {
            color: "#2d3037", // Color for pagination select label
          },
          "& .MuiTablePagination-displayedRows": {
            color: "#2d3037", // Color for displayed rows text
          },
          "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
            color: "red",
          },
          "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root":
            {
              color: "red",
            },
        }}
        slotProps={{
          toolbar: {
            sx: {
              color: "#FFF",
            },
          },
          pagination: {
            sx: {
              color: "#f19d38",
            },
          },
          menu: {
            sx: {
              color: "#f19d38",
            },
          },
        }}
      />
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Items</DialogTitle>
        <DialogContent>{dialogItems}</DialogContent>
      </Dialog>
    </div>
  );
};

export default Table;
