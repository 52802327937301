import styles from "./components.module.css";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addNewSector } from "../../../../redux/features/sectores/sectorSlice";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Box,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Popper,
  Select,
  TextField,
  Typography,
  FormControl,
  Card
} from "@mui/material";
import Slide from "@mui/material/Slide";
import TableRepetir from "./TableHabitacionesRepetir";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {
  getInfoByRut,
  liveSearchByRut,
} from "../../../../redux/features/users/userSlice";
import CalendarioCreate from "./CalendarCreate";
import CalendarioCreateEdit from "./CalendarCreateEdit";
import CalendarioCreateRuts from "./CalendarioCreateRuts";
import Group from "@mui/icons-material/Group";
import { addNewPlanificacionAseoRepetir, planificacionPersona } from "../../../../redux/features/aseo/aseoSlice";
import { format, parseISO } from "date-fns";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid'

const data = {
  id:'',
  grupo: "",
  horario: "",
  ruts: [],
  desde:'',
  hasta:'',
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Edit2 = ({
  open2,
  setOpen2,
  setOpen3,
  grupos,
  habitaciones,
  franjas,
  tareas,
  personal,
  seleItem
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); //variable para abrir y cerrar modal
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedHabitaciones, setSelectedHabitaciones] = useState([]);
  const [ruts, setRuts] = useState([]);
  const [selectedRuts, setSelectedRuts] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [listItems, setListItems] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [selectedNameByRuts, setSelectedNameByRuts] = useState([]);
  const [eventos, setEventos] = useState([])
  const [tipo, setTipo] = useState('');
  const [actualizar, setActualizar] = useState(false);
  const [selecItem, setSelecItem] = useState({id:''});
  const [selectedTareas, setSelectedTareas] = useState([]);

  const handleChangeTipo = (event) => {
    setTipo(event.target.value);
    setFormData({...formData, tipo: event.target.value});
  };

  const handleChangeHorario = (event, newValue) => {
    setSelectedCampamento(newValue);
    if (newValue != null) {
      setFormData({ ...formData, horario: newValue.id });
    } else {
      setFormData({ ...formData, horario: 0 });
    }
  };

  const handleChangeGroup = (event, newValue) => {
    setSelectedGroup({ id: newValue.id, nombre: newValue.nombre });
    console.log(newValue);
    if (newValue != null) {
      setFormData({ ...formData, grupo: newValue });
    } else {
      setFormData({ ...formData, grupo: 0 });
    }
  };

  const formatISODate = (isoString) => {
    if (!isoString) return '';
    const date = parseISO(isoString);
    return format(date, 'yyyy-MM-dd');
  };

  useEffect(() => {
    if (seleItem) {
      setTipo('Aseo normal');
      // setFormData(prev => ({...prev, tipo: seleItem.tipoAseo}));
      setListItems([{id:seleItem.id, desde: formatISODate(seleItem?.desde), hasta: formatISODate(seleItem?.hasta), horario: seleItem?.horarioAseo.id, grupo:seleItem?.gruposHabitaciones[0], ruts: seleItem.trabajadores.map(item => item.rut), tareasId: seleItem.tareas.map(item => item.id), tipo: seleItem.tipoAseo} ])
      console.log('seleItem:',seleItem);
    
      setSelectedCampamento(null)
      setSelectedGroup(null)
      setSelectedRuts([])
      setFormData(data)
      setTipo('')
      setCheckedItems([])
    }
  }, [seleItem]);
  
  useEffect(() => {
    console.log('listItems:', listItems)
  }, [listItems])
  

  useEffect(() => {
    const allRuts = formData.ruts.flatMap(item => item);
    const uniqueRuts = [...new Set(allRuts)];
    setRuts(uniqueRuts);
  }, [formData?.ruts])
  

  const onChangeRutSelect = async (e, newValue) => {    
    if (newValue != null) {
      console.log(newValue);
      setFormData({...formData, ruts: newValue.map(item => item.rut)});
      
      try {
        setOpen3(true);
        setCargando(true);
        for (const rut of newValue.map(item => item.rut)) {
          const resp = await dispatch(planificacionPersona(rut));
          setEventos(prevEventos => [...prevEventos, ...resp.payload.filter(event => !prevEventos.some(prevEvent => prevEvent.id === event.nombre))]);
        }
      } catch (error) {
        console.log('error', error);
      } finally{
        setCargando(false);
        setOpen3(false);
      }
      setSelectedRuts(newValue);
      setSelectedNameByRuts(newValue);
    } else {
      setFormData({...formData, ruts: []});
    }
  }
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const canSaveAgregar =
    [
      formData?.grupo,
      formData?.horario,
      formData?.ruts.length > 0,
      formData?.desde,
      formData?.hasta,
    ].every(Boolean) && addRequestStatus === "idle";

  const canSave = [].every(Boolean) && addRequestStatus === "idle";

  const handleCheckboxChange = (value) => {
    setFormData({ ...formData, tareasId: value.map(tar => tar.id) });
  };

  // const handleCheckboxChange = (tarea) => {
  //   setCheckedItems((prevCheckedItems) => {
  //     let newCheckedItems;
  //     if (prevCheckedItems.some((item) => item.id === tarea.id)) {
  //       newCheckedItems = prevCheckedItems.filter((item) => item.id !== tarea.id);
  //     } else {
  //       newCheckedItems = [...prevCheckedItems, tarea];
  //     }

  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       tareasId: newCheckedItems.map((item) => item.id),
  //     }));
  //     return newCheckedItems;
  //   });
  // };

  const handleActualizar = () => {
    const newDesde = moment(formData.desde);
    const newHasta = moment(formData.hasta);
    console.log('id', selecItem.id);
    // Verificar si el nuevo tramo se solapa con algún tramo existente en listItems
    const isOverlapping = listItems.some(item => {
      if(item.id != seleItem.id){
        const itemDesde = moment(item.desde);
        const itemHasta = moment(item.hasta);
        return (
          (newDesde.isBetween(itemDesde, itemHasta) || newDesde.isSame(itemDesde) || newDesde.isSame(itemHasta)) ||
          (newHasta.isBetween(itemDesde, itemHasta) || newHasta.isSame(itemDesde) || newHasta.isSame(itemHasta))
        );
      }
    });
  
    if (isOverlapping) {
      toast.error("La planificación calza con otra ya ingresada.");
      return; 
    }
    
    const findIndex = listItems.findIndex(item => item.id === selecItem.id)
    if(findIndex !== -1){
      console.log('encontrado', findIndex);
      const newItem = { ...formData, id: selecItem.id };
      const newListItems = [...listItems];
      newListItems[findIndex] = newItem;
      setListItems(newListItems);
    }

    setFormData({ grupo: '', horario: '', ruts: [], desde: '', hasta:'', tareasId: [] })
    setSelectedCampamento(null);
    setSelectedGroup(null);
    setSelectedRuts([]);
    setCheckedItems([]);
    setTipo('')
    setActualizar(false);
    setSelectedTareas([]);
  }

  const handleAgregar = () => {
    console.log('handleAgregar', formData);
    const newDesde = moment(formData.desde);
    const newHasta = moment(formData.hasta);
  
    // Verificar si el nuevo tramo se solapa con algún tramo existente en listItems
    const isOverlapping = listItems.some(item => {
      const itemDesde = moment(item.desde);
      const itemHasta = moment(item.hasta);
      return (
        (newDesde.isBetween(itemDesde, itemHasta) || newDesde.isSame(itemDesde) || newDesde.isSame(itemHasta)) ||
        (newHasta.isBetween(itemDesde, itemHasta) || newHasta.isSame(itemDesde) || newHasta.isSame(itemHasta))
      );
    });
  
    if (isOverlapping) {
      toast.error("La planificación calza con otra ya ingresada.");
      return; 
    }
  
    const newItem = { ...formData, id: uuidv4() };    
    setListItems([...listItems, newItem]);
    setFormData({
      grupo: '',
      horario: '',
      ruts: [],
      desde: '',
      hasta:'',
      tareasId: []
    })
    setSelectedCampamento(null);
    setSelectedGroup(null);
    setSelectedRuts([]);
    setCheckedItems([]);
    setSelectedTareas([]);
  }
  
  const limpiar = () => {
    setActualizar(false);
    setFormData({
      grupo: '',
      horario: '',
      ruts: [],
      desde: '',
      hasta:'',
      tareasId: []
    })
    setSelectedCampamento(null);
    setSelectedGroup(null);
    setSelectedRuts([]);
    setCheckedItems([]);
    setSelectedTareas([]);
  }
  
  const handleDelete = (id) => {
    setListItems(listItems.filter((item) => item.id !== id));
    setFormData({
      grupo: '',
      horario: '',
      ruts: [],
      desde: '',
      hasta:'',
      tareasId: []
    })
    setSelectedCampamento(null);
    setSelectedGroup(null);
    setSelectedRuts([]);
    setCheckedItems([]);
  };

  const handleSubmit = async () => {
    if (canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus("loading");
        let resp = await dispatch(addNewPlanificacionAseoRepetir(listItems));
        if (resp.payload.status === "success") {
          setFormData(data);
          setOpen(false);
        }
      } catch (error) {
        console.error("Error al crear la planificación", error);
      } finally {
        setOpen2(false);
        setAddRequestStatus("idle");
      }
    }
  };

  return (
    <div>
      <Dialog
        open={open2}
        maxWidth="lg"
        PaperProps={{
          sx: {
           backgroundColor:'#efefef',
           color:'#2d3037',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen2(false);
        }}
      >
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <MenuBookIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }}/>
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1'  }}>Asignar personal usando datos anteriores</Typography>
            <span className={styles.subTitle}>Rellena los siguientes campos para asignar el personal.</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <div>
                <label className={styles.label}>Jornada</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#2d3037',
                          },
                  }}
                  disablePortal
                  id="campamentos-autocomplete"
                  name="franja"
                  disableClearable
                  options={
                    Array.isArray(franjas) && franjas.length > 0 ? franjas : []
                  }
                  getOptionLabel={(option) => option.nombre}
                  value={selectedCampamento}
                  onChange={(e, value) => handleChangeHorario(e, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecciona un rango de horario"
                    />
                  )}
                />
              </div>
              <div>
                <label className={styles.label}>Grupos</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#2d3037',
                          },
                  }}
                  disablePortal
                  id="campamentos-autocomplete"
                  name="grupo"
                  disableClearable
                  value={selectedGroup}
                  options={
                    Array.isArray(grupos) && grupos.length > 0 ? grupos : []
                  }
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e, value) => handleChangeGroup(e, value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecciona un grupo" />
                  )}
                />
              </div>
              <div>
                <label className={styles.label}>Busque trabajadores</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '.MuiChip-label':{
                      color: '#2d3037',
                    },
                    '.MuiSvgIcon-root':{
                      color: '#2d3037',
                    },
                    '& .MuiInputBase-input': {
                      color: '#2d3037',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#2d3037',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color: '#2d3037',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color: '#2d3037',
                    },
                  }}
                  disablePortal
                  disableClearable
                  id="sectores-autocomplete"
                  name="rut"
                  value={selectedRuts || null}
                  options={personal ? personal : []}
                  multiple
                  getOptionLabel={(option) => option.rut + ' ' + option.nombre + ' ' + option?.apellido}
                  onChange={(e, value) => onChangeRutSelect(e, value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecciona un rut" />
                  )}
                />
              </div>
              <div>
                <label className={styles.label}>Desde - hasta</label>
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <TextField
                    className={styles.textField}
                    sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#2d3037',
                          },
                    }}
                    variant="outlined"
                    fullWidth
                    name="desde"
                    type="date"
                    value={formData?.desde}
                    onChange={(e) => onChange(e)}
                  />
                  <TextField
                    className={styles.textField}
                    sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#2d3037',
                          },
                    }}
                    variant="outlined"
                    fullWidth
                    name="hasta"
                    type="date"
                    value={formData?.hasta}
                    onChange={(e) => onChange(e)}
                  />

                </div>
              </div>
              <div>
              <label className={styles.label}>Tipo de aseo</label>
              <FormControl fullWidth>
                <Select
                  className={styles.textField}
                  sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#2d3037',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#2d3037',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#2d3037',
                          },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={tipo}
                  name='tipo'
                  label="Tipo"
                  onChange={handleChangeTipo}
                >
                  <MenuItem value='Aseo normal'>Aseo normal</MenuItem>
                  <MenuItem value='Limpieza terminal'>Limpieza terminal</MenuItem>
                  <MenuItem value='Desempolvado'>Desempolvado</MenuItem>
                </Select>
              </FormControl>
            </div>
              <div>
                <label className={styles.label}>Lista de tareas</label>
                <Autocomplete
                  multiple
                  id="select-multiple-tareas"
                  options={tareas}
                  getOptionLabel={(option) => option.nombre}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#2d3037',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#2d3037',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color: '#2d3037',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color: '#2d3037',
                    },
                    'backgroundColor': '#FFF',
                  }}
                  value={selectedTareas}
                  onChange={(event, newValue) => {
                    setSelectedTareas(newValue);
                    handleCheckboxChange(newValue);
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.id} style={{ gap:'5px' }}>
                      <Typography variant="body1">{option.nombre}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {option.descripcion}
                      </Typography>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Selecciona Tareas"
                      placeholder="Tareas"
                    />
                  )}
                />
              </div>
              <Grid container={true} spacing={1}>
                <Grid item xs={12} md={6}>
                  <Button
                    onClick={ actualizar ? handleActualizar : handleAgregar }
                    className={styles.bgColorBlue}
                    variant="contained"
                    disabled={!canSaveAgregar}
                    style={{ marginTop: "10px", width:'100%' }}
                  >
                    {actualizar  ? 'Actualizar' : 'Agregar'}
                  </Button>
                  </Grid>
                <Grid item xs={12} md={6}>
                { actualizar && 
                  <Button 
                  onClick={limpiar}
                  className={styles.bgColorOrange}
                  variant="contained"
                  style={{ marginTop: "10px", width:'100%' }}
                  >
                  Limpiar formulario
                  </Button>
                }
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Card style={{ padding:'5px 0 0 0', textAlign:'center' }}>
                <label className={styles.label}>
                  <strong>Asignaciones.</strong>
                </label>
                <CalendarioCreateEdit events={listItems} ruts={formData?.ruts} setSelectedCampamento={setSelectedCampamento} franjas={franjas} setSelectedGroup={setSelectedGroup} personal={personal} setSelectedRuts={setSelectedRuts} setFormData={setFormData} setTipo={setTipo} tareas={tareas} setCheckedItems={setCheckedItems} setActualizar={setActualizar} setSelecItem={setSelecItem} setSelectedTareas={setSelectedTareas}/>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
            {cargando ?
              <Card style={{ padding:'5px 0 0 0', textAlign:'center', height:'520px', 'width':'100%', display:'flex', justifyContent:'center', alignItems:'center' }}>
                <CircularProgress color="inherit" size={24} /> 
              </Card>
              : <Card style={{ padding:'5px 0 0 0', textAlign:'center' }}>
                  <label className={styles.label}><strong>Asignaciones de los trabajadores seleccionados.</strong></label> <CalendarioCreateRuts events={eventos} ruts={formData?.trabajadoresRut} setCargando={setCargando}/>
                </Card>
                }
            </Grid>
            <Grid item xs={12} sm={12} style={{ marginTop: "7px" }}>
              <Card style={{ padding:'6px' }}>
                <TableRepetir
                  habitaciones={listItems}
                  setOpen3={setOpen3}
                  setSelectedHabitaciones={setSelectedHabitaciones}
                  handleDelete={handleDelete}
                />
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true} style={{ padding: "0 16px" }}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button
                onClick={handleSubmit}
                className={styles.bgColor}
                variant="contained"
                disabled={!canSave || selectedRuts?.newValue?.length <= 0}
              >
                {addRequestStatus == "loading" ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Crear"
                )}
              </Button>
              <Button
                onClick={() => setOpen2(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Edit2;
