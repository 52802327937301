import { Box, Container } from "@mui/material";
import Header from "../../components/header/Header";
import TableCard from "../../components/tableCard/TableCard";
import SkeletonTable from "../../components/Skeleton";
import Create from "./componentsSalidas/Create";
import Table from "./componentsSalidas/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {
  fetchCampamentos,
  getAllCampamentos,
  getCampamentosStatus,
} from "../../redux/features/campamentos/campamentoSlice";
import {
  fetchBodegas,
  fetchSalidasBodega,
  getAllBodegas,
  getAllSalidas,
  getBodegasStatus,
  getSalidasStatus,
} from "../../redux/features/bodegas/bodegaSlice";

const Salidas = () => {
  const dispatch = useDispatch();
  const salidas = useSelector(getAllSalidas);
  const status = useSelector(getSalidasStatus);
  const bodegas = useSelector(getAllBodegas);
  const bodStatus = useSelector(getBodegasStatus);
  const campamentos = useSelector(getAllCampamentos);
  const camStatus = useSelector(getCampamentosStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchSalidasBodega());
    }
    if (bodStatus === "idle") {
      dispatch(fetchBodegas());
    }
    if (camStatus === "idle") {
      dispatch(fetchCampamentos());
    }
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Gestión de salidas a bodega"
          subtitle="En este módulo, podrás ingresar las salidas de los artículos de las bodegas."
        >
          {<ExitToAppIcon style={{ fontSize: "1.5rem" }} />}
        </Header>

        <TableCard>
          <Create
            setOpen2={setOpen2}
            open2={open2}
            campamentos={campamentos}
            bodegas={bodegas}
          />
          {status == "loading" ? (
            <SkeletonTable />
          ) : (
            <Table registros={salidas ?? []} setOpen3={setOpen3} />
          )}
        </TableCard>
      </Container>
    </Box>
  );
};
export default Salidas;
