import { jwtDecode } from "jwt-decode";

export const validateLoginCredentials = (formData) => {
  const { email, pass } = formData;

  if (!email)
    return { error: { type: "email", message: "Ingresar el correo." } };
  if (!pass)
    return { error: { type: "pass", message: "Ingresar la contraseña." } };
  return { success: true };
};
export const validateLoginEmail = (formData) => {
  const { email } = formData;

  if (!email)
    return { error: { type: "email", message: "Ingresar el correo." } };
  return { success: true };
};
export const validateSetPass = (formData) => {
  const { pass, setpass } = formData;
  if (!pass)
    return { error: { type: "pass", message: "Ingresar la contraseña." } };
  const passwordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
  if (!passwordRegex.test(pass)) {
    return {
      error: {
        type: "pass",
        message:
          "La contraseña debe tener al menos 8 caracteres y contener al menos un número, una letra minúscula, una letra mayúscula y un carácter no alfanumérico.",
      },
    };
  }

  if (!setpass)
    return { error: { type: "setpass", message: "Repita la contraseña." } };
  if (setpass !== pass)
    return {
      error: { type: "setpass", message: "Las contraseñas no coinciden." },
    };
  return { success: true };
};

export const hasRole = (targetRole) => {
  const token = localStorage.getItem("token");
  const decoded = jwtDecode(token);
  // console.log(decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
  // console.log('rol', decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === targetRole);
  return (
    decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] ===
    targetRole
  );
};
export const getRole = () => {
  const token = localStorage.getItem("token");
  if (!token) return false;
  const decoded = jwtDecode(token);
  return decoded[
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
  ];
};
