import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EditIcon from "@mui/icons-material/Edit";
import { GridDeleteIcon } from "@mui/x-data-grid";

export const RenderCell4 = ({
  row,
  setOpenActualizar,
  setOpen,
  setSeleItem,
  setCheck,
  setOpenAsignar,
  setOpenDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEditar = () => {
    setSeleItem(row);
    setOpenActualizar(true);
  };
  const handleRemover = () => {
    setSeleItem(row);
    setOpenDelete(true);
  };

  const handleClickAsignar = (number) => {
    setSeleItem(row);
    setOpen(true);
    setCheck(number);
  };

  const handleClickAsignarPersonal = () => {
    setSeleItem(row);
    setOpenAsignar(true);
  };

  return (
    <div>
      <IconButton style={{ color: "#FFF" }} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleEditar}>
          <EditIcon style={{ marginRight: 8 }} />
          Editar oficina
        </MenuItem>
        <MenuItem onClick={handleRemover}>
          <GridDeleteIcon style={{ marginRight: 8 }} />
          Eliminar oficina
        </MenuItem>

        <MenuItem onClick={() => handleClickAsignarPersonal(1)}>
          <AssignmentIcon style={{ marginRight: 8 }} />
          Asignar personal
        </MenuItem>
        <MenuItem onClick={() => handleClickAsignar(2)}>
          <AssignmentIcon style={{ marginRight: 8 }} />
          Liberar oficina
        </MenuItem>
        <MenuItem onClick={() => handleClickAsignar(4)}>
          <AssignmentIcon style={{ marginRight: 8 }} />
          Habilitar oficina
        </MenuItem>
        <MenuItem onClick={() => handleClickAsignar(3)}>
          <AssignmentIcon style={{ marginRight: 8 }} />
          Inhabilitar oficina
        </MenuItem>
      </Menu>
    </div>
  );
};
