import styles from '../components.module.css';
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getAllHabitaciones, getHabitacionesStatus } from "../../../../../redux/features/habitaciones/habitacionSlice";
import SkeletonTable from '../../../../../components/Skeleton';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { Button, Grid, Typography } from '@mui/material';
import { habitacionReservasColumns } from "../../../../../lib/columns";

const Habitaciones = ({ seleccionados, checkedHabitaciones, setCheckedHabitaciones, asignar, setAsignacionHabitacionesTrabajadores, setCheck, check }) => {

    const [seleItem, setSeleItem] = useState();
    const [open, setOpen] = useState(false);
    const habitaciones = useSelector(getAllHabitaciones);
    const [pageSize, setPageSize] = useState(10);
    const finalColumns = habitacionReservasColumns(setOpen, setSeleItem);
    const status = useSelector(getHabitacionesStatus);

    const handleRowSelectionChange = (selectionModel) => {
      setCheck(false);
      setAsignacionHabitacionesTrabajadores([]);
        console.log('seleccion', selectionModel);
        const infoHabitaciones = selectionModel.map((id) => {
            const row = habitaciones.find((row) => row.id === id);
            return {id: row.id, numero: row.numero, tipoHuesped: row.tipoHuesped, piso: row.piso, pabellonId: row.pabellonId};
          });
          setCheckedHabitaciones(infoHabitaciones);
    }

  return (
    <>
        {/* FILTROS */}
        {/* LISTADO DE TRABAJADORES SELECCIONADOS (DARLE UN SCROLL) 
          {JSON.stringify(seleccionados)}
          <br />
          {JSON.stringify(checkedHabitaciones)}
          <br />
        */}
        <Grid container={true} spacing={1}>
            <Grid item sm={12} style={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                <Typography variant='span'>{seleccionados.length - checkedHabitaciones.length == 1 ? 'Queda ' : 'Quedan'} {seleccionados.length - checkedHabitaciones.length} {seleccionados.length - checkedHabitaciones.length == 1 ? 'habitación ' : 'habitaciones'} por seleccionar</Typography>
                <Button
                  onClick={asignar}
                  className={styles.bgColor}
                  variant="contained"
                >
                  Asignar
                </Button>
            </Grid>
        </Grid>

        {
            status == 'loading' 
            ? <SkeletonTable/>
            :
            <Grid item xs={12} sm={12} mt={2}>
                <DataGrid
                    getRowId={row=>row.id ? row.id : Math.random() }
                    checkboxSelection
                    rowSelectionModel ={checkedHabitaciones.map(item => item.id)}
                    onRowSelectionModelChange={handleRowSelectionChange}
                    lots={{ toolbar: GridToolbar }}
                    rows={habitaciones}
                    columns={finalColumns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    localeText={{
                    ...esES.components.MuiDataGrid.defaultProps.localeText,
                    noRowsLabel: "No hay datos disponibles",
                    pagination: {
                        labelRowsPerPage: "Filas por página:",
                    }
                    }}
                    classes={{ menuIcon: styles.menuIcon }}
                />
            </Grid>
        }
    </>
  )
}

export default Habitaciones