import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Autocomplete, Backdrop, Button, CircularProgress, Container, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCampamentos } from '../../../redux/features/campamentos/campamentoSlice';
import { useEffect } from 'react';
import { updateSector } from '../../../redux/features/sectores/sectorSlice';
import { updateMerma } from '../../../redux/features/merma/mermaSlice';

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3 }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState(null);
    const dispatch = useDispatch();
    const campamentos = useSelector(getAllCampamentos);

    useEffect(() => {
      if(seleItem){
        setFormData(seleItem)
        setFormData(prev => {
          return {...prev, descripcion: seleItem?.producto?.descripcion, codigo: seleItem?.producto?.codigo}
          }
        )
      }
    }, [seleItem])
        
    const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

    const canSave = [ formData?.codigo, formData?.descripcion, formData?.motivo, formData?.cantidad].every(Boolean) && addRequestStatus === 'idle';

    const handleEdit = async () => {
        try {
            setOpen3(true);
            setAddRequestStatus('loading');
            let resp = await dispatch(updateMerma(formData))
            if(resp.payload.status === 'success'){
                setOpenEdit(false);
            }
        } catch (error) {
            console.error('Error al actualizar el item', error.message);
        } finally{
            setAddRequestStatus('idle');
            setOpen3(false);
        }
    }

  return (
    <div>
      <Drawer
        anchor='right'
        open={openEdit}
        PaperProps={
          {
            sx: {
              width: { xs: '100%', sm: '75%', md: '50%' }
            }
          }
        }
        onClose={() => setOpenEdit(false)}
      >
        {seleItem && (
            <Box width={1} mt={0} mb={2}>
                <Container >
                {/*
              */}
              {JSON.stringify(formData)} 
                    <Grid container={true} spacing={1} p={2}>
                        <Grid item  xs={12} sm={12} mb={2}>
                            <Typography variant="h6" className={styles.textIcon}><ErrorIcon/>Estás a punto de actualizar el artículo {seleItem?.codigo}</Typography>
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                        <label className={styles.label}>Código</label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={formData?.codigo}
                          name='codigo'
                          disabled
                          type="text"
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      <Grid item  xs={12} sm={6}>
                        <label className={styles.label}>Cantidad</label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name='cantidad'
                          value={formData?.cantidad}
                          type="number"
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      <Grid item  xs={12} sm={12}>
                        <label className={styles.label}>Motivo</label>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData?.motivo || ''}
                          name="motivo"
                          onChange={(e) => onChange(e)}
                          style={{ width:'100%' }}
                        >
                          <MenuItem value='Deterioro'>Deterioro</MenuItem>
                          <MenuItem value='Caducidad'>Caducidad</MenuItem>
                          <MenuItem value='Rotura o daño durante el transporte y manipulación'>Rotura o daño durante el transporte y manipulación</MenuItem>
                          <MenuItem value='Obsolescencia'>Obsolescencia</MenuItem>
                          <MenuItem value='Defectuoso'>Defectuoso</MenuItem>
                        </Select>              
                      </Grid>
                      <Grid item  xs={12} sm={12}>
                        <label className={styles.label}>Observaciones</label>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name='observaciones'
                          value={formData?.observaciones}
                          type="text"
                          multiline
                          rows={2}
                          maxRows={4}
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      
                        <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                            <Button 
                                onClick={handleEdit}
                                className={styles.bgColor}
                                variant="contained" 
                                disabled={!canSave}
                                >
                                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                            </Button>
                            <Button 
                                onClick={()=>setOpenEdit(false)}
                                className={styles.bgColorOutlined}
                                variant="outlined" 
                                >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        )}
      </Drawer>
    </div>
  );
}