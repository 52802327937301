import styles from './components.module.css';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Popper, Select, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState, Fragment } from 'react';

import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { fetchTrabajadores } from '../../../../redux/features/reservas/reservaSlice';
import { trabajadoresResumenReservaColumns } from '../../../../lib/columns';

const Show = ({ open3, setOpen3, handleClose, seleItem  }) => {
    
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = trabajadoresResumenReservaColumns()
  const dispatch = useDispatch();
  const [trabajadores, setTrabajadores] = useState([])
  const formatKey = (key) => {
    return key
      .replace(/([a-z])([A-Z])/g, '$1 $2')  // Convert camelCase to space-separated words
      .replace(/_/g, ' ')                  // Replace underscores with spaces
      .replace(/([A-Z])/g, ' $1')          // Add space before capital letters
      .trim()                              // Remove leading/trailing spaces
      .toLowerCase()                       // Convert to lowercase
      .replace(/^\w/, c => c.toUpperCase()); // Capitalize first letter of each word
  };

  useEffect(() => { 
    seleItem && console.log('seleItem: ', seleItem)

  }, [seleItem]);
  
  const renderData = (data, parentKey = '') => {
    return Object.entries(data).map(([key, value]) => {
      if (key.toLowerCase() === 'id') return null; // Skip rendering for 'id'
      const formattedKey = parentKey ? `${parentKey} ${formatKey(key)}` : formatKey(key);
      if (typeof value === 'object' && value !== null) {
        return (
          <Fragment key={key}>
            {renderData(value, formattedKey)}
          </Fragment>
        );
      } else {
        return (
          <Fragment key={key}>
            <Grid item xs={12} md={4}>
              <Typography align="left">{formattedKey}:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                <strong style={{ fontWeight: '500' }}>
                  {key.includes('fecha') ? format(new Date(value), 'dd-MM-yyyy') : key.includes('id') ? '' : (value ? value : '-')}
                </strong>
              </Typography>
            </Grid>
          </Fragment>
        );
      }
    });
  };

  
  return (
    <Dialog
      open={open3}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
         backgroundColor:'#efefef',
         color:'#2d3037',
         borderRadius:'0'
        }
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
      >
        {"Detalles de la acción"}
      </DialogTitle>
      
      <DialogContent>
        <Grid container={true} spacing={2} mt={2} style={{ backgroundColor:'#fafafa', borderRadius:'5px', overflowX:'auto' }}>
            <Grid item xs={12} md={4}>
              <Typography align="left">Fecha:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.fecha && format(seleItem?.fecha, 'dd-MM-yyyy') }</strong></Typography>
            </Grid>

            {seleItem?.data && renderData(seleItem?.data)}
            
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color:'#2d3037' }}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Show;
