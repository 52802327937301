import { Box, Container } from "@mui/material";
import Header from "../../components/header/Header";
import TableCard from "../../components/tableCard/TableCard";
import SkeletonTable from "../../components/Skeleton";
import Create from "./componentsIngresos/Create";
import Table from "./componentsIngresos/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import {
  fetchCampamentos,
  getAllCampamentos,
  getCampamentosStatus,
} from "../../redux/features/campamentos/campamentoSlice";
import {
  fetchBodegas,
  fetchIngresosBodega,
  getAllBodegas,
  getAllIngresos,
  getBodegasStatus,
  getIngresosStatus,
} from "../../redux/features/bodegas/bodegaSlice";
import {
  fetchUsers,
  fetchUsersInventarioBodega,
  getAllUsers,
  getAllUsersBodegaInventario,
  getUsersStatusAll,
} from "../../redux/features/users/userSlice";
import {
  fetchInventarios,
  getAllInventarios,
} from "../../redux/features/inventario/inventarioSlice";

const Ingresos = () => {
  const dispatch = useDispatch();
  const ingresos = useSelector(getAllIngresos);
  const status = useSelector(getIngresosStatus);
  const campamentos = useSelector(getAllCampamentos);
  const camStatus = useSelector(getCampamentosStatus);
  const bodegas = useSelector(getAllBodegas);
  const bodStatus = useSelector(getBodegasStatus);
  const users = useSelector(getAllUsersBodegaInventario);
  const inventario = useSelector(getAllInventarios);
  const [filterUsers, setFilterUsers] = useState([]);
  const statusUsers = useSelector(getUsersStatusAll);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    if (status === "idle") {
    }
    dispatch(fetchIngresosBodega());
    dispatch(fetchInventarios());
    if (status === "idle") {
    }
    dispatch(fetchUsersInventarioBodega());
    if (camStatus === "idle") {
      dispatch(fetchCampamentos());
    }
    if (bodStatus === "idle") {
    }
    dispatch(fetchBodegas());
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Gestión de ingresos a bodega"
          subtitle="En este módulo, podrás ingresar artículos al stock de bodegas."
        >
          {<AlignHorizontalCenterIcon style={{ fontSize: "1.5rem" }} />}
        </Header>

        <TableCard>
          <Create
            setOpen2={setOpen2}
            open2={open2}
            campamentos={campamentos}
            bodegas={bodegas}
            users={users}
            inventario={inventario}
          />
          {status == "loading" ? (
            <SkeletonTable />
          ) : (
            <Table
              registros={ingresos ? ingresos : []}
              setOpen3={setOpen3}
              campamentos={campamentos}
              bodegas={bodegas}
              users={users}
            />
          )}
        </TableCard>
      </Container>
    </Box>
  );
};
export default Ingresos;
