import styles from "./components/components.module.css";
import { Backdrop, Box, Container } from "@mui/material";
import Header from "../../../components/header/Header";
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from "./components/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchSector,
  fetchSectores,
  getAllSectores,
  getSectoresStatus,
} from "../../../redux/features/sectores/sectorSlice";
import {
  fetchCampamentos,
  getAllCampamentos,
  getCampamentosStatus,
} from "../../../redux/features/campamentos/campamentoSlice";
import {
  fetchEdificios,
  getAllEdificios,
  getEdificiosStatus,
} from "../../../redux/features/edificios/edificioSlice";
import SkeletonTable from "../../../components/Skeleton";
import { useState } from "react";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import {
  fetchEstandares,
  getEstandarStatus,
} from "../../../redux/features/estandares/estandarSlice";

const Edificios = () => {
  const dispatch = useDispatch();
  const sectores = useSelector(getAllSectores);
  const campamentos = useSelector(getAllCampamentos);
  const campamentosStatus = useSelector(getCampamentosStatus);
  const status = useSelector(getSectoresStatus);
  const edificios = useSelector(getAllEdificios);
  const edificiosStatus = useSelector(getEdificiosStatus);
  const estandarStatus = useSelector(getEstandarStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    if (status == "idle") {
      dispatch(fetchSectores());
    }
    if (campamentosStatus == "idle") {
      dispatch(fetchCampamentos());
    }
    if (edificiosStatus == "idle") {
      dispatch(fetchEdificios());
    }
    if (estandarStatus == "idle") {
      dispatch(fetchEstandares());
    }
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Gestión de Pabellones"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados con los pabellones en la plataforma."
        >
          {
            <ApartmentIcon
              style={{ fontSize: "1.5rem" }}
              className={styles.iconBgDelete}
            />
          }
        </Header>

        <TableCard>
          <Create
            campamentos={campamentos}
            setOpen2={setOpen2}
            open2={open2}
            sectores={sectores}
          />
          {edificiosStatus == "loading" ? (
            <SkeletonTable />
          ) : (
            <Table edificios={edificios} setOpen3={setOpen3} />
          )}
        </TableCard>
      </Container>
    </Box>
  );
};

export default Edificios;
