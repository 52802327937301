import styles from "./components.module.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { deleteSector } from "../../../../redux/features/sectores/sectorSlice";
import { deleteOficina } from "../../../../redux/features/oficinas/oficinaSlice";

export default function Delete({ open, setOpen, seleItem, setOpen3 }) {
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      setOpen3(true);
      setAddRequestStatus("loading");
      await dispatch(deleteOficina(seleItem.id));
    } catch (error) {
      console.error("Error al eliminar la oficina", error.message);
    } finally {
      setAddRequestStatus("idle");
      setOpen(false);
      setOpen3(false);
    }
  };

  return (
    <div>
      <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
        {seleItem && (
          <Box width={1} mt={0} className={styles.boxDelete}>
            <Container className={styles.containerDelete}>
              <div className={styles.textContainer}>
                <Typography variant="h6" className={styles.textIcon}>
                  <ErrorIcon />
                  Estás a punto de eliminar la oficina N°{seleItem?.numero}
                </Typography>
                <Typography variant="span" className={styles.paragrap}>
                  Está acción es irreversible, el registro será removido de la
                  base de datos, <strong>¿Estas seguro de proceder?</strong>
                </Typography>
              </div>
              <Grid container={true} spacing={1} mt={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={styles.buttonContainerDelete}
                >
                  <Button
                    onClick={() => setOpen(false)}
                    className={styles.bgColorOutlinedWhite}
                    variant="outlined"
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={handleDelete}
                    className={styles.bgColorDelete}
                    variant="contained"
                  >
                    {addRequestStatus == "loading" ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      "Eliminar"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Box>
        )}
      </Drawer>
    </div>
  );
}
