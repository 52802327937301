import styles from "./components.module.css";
import {
  Autocomplete,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Popper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import { useEffect, useState } from "react";
import {
  getInfoByRutAdmUsuarios,
  getOficinaByRutAdmUsuarios,
  liveSearchByRutAdmUsuarios,
} from "../../../../redux/features/users/userSlice";
import { useDispatch } from "react-redux";
import { format } from "date-fns";

const data = {
  nombre: "",
  apellido: "",
  email: "",
  rut: "",
  rol: [],
  cargoId: "",
  empresa: [],
  genero: "",
  telefono: "",
  bodegas: [],
  oficinas: [],
};

function BuscarOficina({ open, setOpen }) {
  const [openBack, setOpenBack] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [ruts, setRuts] = useState([]);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(data);

  useEffect(() => {
    // No ejecutar la búsqueda si no hay texto
    if (!searchValue) return;

    const handler = setTimeout(async () => {
      try {
        setOpenBack(true);
        const resp = await dispatch(liveSearchByRutAdmUsuarios(searchValue));
        console.log(resp);

        setRuts(resp.payload);
      } catch (error) {
        console.log(error);
      } finally {
        setOpenBack(false);
      }
    }, 1000); // Espera de 1 segundo (1000 milisegundos)

    // Limpiar timeout en caso de que el usuario escriba antes de que se complete el tiempo
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue, dispatch]);

  // Handler para el cambio en el input del autocomplete
  const onChangeRutInput = (e) => {
    setSearchValue(e.target.value);
  };
  const onChangeRutSelect = async (e, newValue) => {
    console.log(newValue.rut);
    if (newValue != null) {
      setFormData({ ...formData, rut: newValue.rut });
      try {
        setOpenBack(true);
        const resp = await dispatch(getInfoByRutAdmUsuarios(newValue.rut));
        const oficinasResp = await dispatch(
          getOficinaByRutAdmUsuarios(newValue.rut)
        );
        const info = resp.payload;
        const infoOficina = oficinasResp.payload;
        console.log("info Oficina", infoOficina);

        setFormData({
          ...formData,
          nombre: info.nombre,
          email: info.email == null ? "" : info.email,
          apellido: info.apellido,
          telefono: info.telefono == null ? "" : info.telefono,
          rut: newValue.rut,
          cargoId: info.idCargo == null ? "" : info.idCargo,
          persona: info,
          oficinas: infoOficina.data ?? [],
        });
      } catch (error) {
      } finally {
        setOpenBack(false);
      }
    } else {
      setFormData({ ...formData, rut: null });
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <Typography>
          El siguiente <strong>botón</strong> te permitirá buscar una oficina
          asignada ingresando un rut.
        </Typography>
        <Button
          variant="contanied"
          size="large"
          className={styles.bgColorOrange}
          onClick={() => setOpen(true)}
        >
          <CircleIcon className={styles.iconButton} /> Buscar trabajador
        </Button>
      </div>

      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBack}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display: "flex", gap: "5px" }}>
          <BookmarkAddIcon
            className={styles.iconBgCreate}
            style={{ fontSize: "3rem", marginTop: "0px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="span"
              style={{ fontSize: "24px", lineHeight: "1" }}
            >
              Buscar oficina asignada
            </Typography>
            <span className={styles.subTitle}>
              Ingresa un rut y selecciona el trabajador.
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Rut</label>
              <Autocomplete
                disablePortal
                disableClearable
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                id="sectores-autocomplete"
                name="rut"
                value={formData.persona || null}
                options={ruts ? ruts : []}
                getOptionLabel={(option) =>
                  option ? option?.rut + " " + option?.nombre : ""
                }
                onChange={(e, value) => onChangeRutSelect(e, value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ingresa un rut para buscar un trabajador"
                    onChange={onChangeRutInput}
                  />
                )}
              />
            </Grid>
            {Array.isArray(formData.oficinas) &&
              formData.oficinas &&
              formData?.oficinas?.map((oficina, i) => (
                <Grid container xs={12} sm={12} key={i} p={1} mb={2}>
                  <Grid item xs={6} sm={6}>
                    <Typography>N°</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography>{oficina.oficina.numero}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography>Ubicación</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography>
                      {oficina.oficina.wingId
                        ? "Wing"
                        : oficina.oficina.pabellonId
                        ? "Pabellón"
                        : oficina.oficina.edificioId
                        ? "Edificio"
                        : ""}{" "}
                      {oficina.oficina.wing
                        ? oficina.oficina.wing.nombre
                        : oficina.oficina.pabellon
                        ? oficina.oficina.pabellon.identificador
                        : oficina.oficina.edificio
                        ? oficina.oficina.edificio.nombre
                        : ""}{" "}
                      {oficina.oficina.wing ??
                        oficina.oficina.pabellonId ??
                        oficina.oficina.edificioId}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography>Piso</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography>{oficina.oficina.piso}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography>Estado</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography>{oficina.estado}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography>Fecha Inicio</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography>
                      {format(oficina.fechaInicio, "dd-MM-yyyy")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography>Fecha Termino</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography>
                      {format(oficina.fechaTermino, "dd-MM-yyyy")}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={12} className={styles.buttonContainer}>
            <Button
              onClick={() => setOpen(false)}
              className={styles.bgColorOutlined}
              variant="outlined"
            >
              Cancelar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BuscarOficina;
