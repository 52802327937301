import styles from "./components.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { addNewEstandar } from "../../../../redux/features/estandares/estandarSlice";
import SettingsInputSvideoIcon from "@mui/icons-material/SettingsInputSvideo";
import CircleIcon from "@mui/icons-material/Circle";

const data = {
  nombre: "",
  campamento: 0,
  descripcion: "",
};
const Create = ({ campamentos, open2, setOpen2 }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [selectedCampamento, setSelectedCampamento] = useState(null);

  // const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

  const onChange = (e) => {
    const value = e.target.value;
    const invalidCharacters = /[<>\/]/;

    if (!invalidCharacters.test(value)) {
      setFormData({ ...formData, [e.target.name]: value });
    } else {
      toast.error("Carácter no válido");
    }
  };

  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    if (newValue != null) {
      setFormData({ ...formData, campamento: newValue.id });
    } else {
      setFormData({ ...formData, campamento: 0 });
    }
  };

  const canSave =
    [formData.nombre, formData.descripcion, formData.campamento].every(
      Boolean
    ) && addRequestStatus === "idle";

  const handleSubmit = async () => {
    if (!formData.nombre || !formData.campamento || !formData.descripcion) {
      toast.error("Por favor ingrese los datos.");
      return;
    }
    if (canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus("loading");
        let resp = await dispatch(addNewEstandar(formData));
        if (resp.payload.data.success) {
          setFormData(data);
          setOpen(false);
          return;
        }
      } catch (error) {
        console.error("Error al crear el estandar de habitación", error);
      } finally {
        setAddRequestStatus("idle");
        setOpen2(false);
      }
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          El siguiente <strong>botón</strong> te mostrará un formulario para
          poder registrar un estandar.
        </Typography>
        <Button
          variant="contanied"
          size="large"
          disabled={campamentos.length == 0}
          className={styles.bgColor}
          onClick={() => setOpen(true)}
        >
          <CircleIcon className={styles.iconButton} /> Crear Estandar
        </Button>
      </div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display: "flex", gap: "5px" }}>
          <SettingsInputSvideoIcon
            className={styles.iconBgCreate}
            style={{ fontSize: "3rem", marginTop: "0px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="span"
              style={{ fontSize: "24px", lineHeight: "1" }}
            >
              Crear estandar
            </Typography>
            <span className={styles.subTitle}>
              Rellena los siguientes campos para crear el estandar.
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item xs={12} sm={6}>
              <label className={styles.label}>Campamento</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                disablePortal
                id="campamentos-autocomplete"
                options={campamentos}
                name="idCampamento"
                disableClearable
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value) => handleChangeCampamento(e, value)}
                renderInput={(params) => (
                  <TextField {...params} label="Selecciona un campamento" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className={styles.label}>Nombre</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="nombre"
                type="text"
                value={formData.nombre}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Descripción</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="descripcion"
                type="text"
                multiline
                rows={4}
                maxRows={4}
                value={formData.descripcion}
                onChange={(e) => onChange(e)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true} style={{ padding: "0 16px" }}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained"
                disabled={!canSave}
              >
                {addRequestStatus == "loading" ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Crear"
                )}
              </Button>
              <Button
                onClick={() => setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Create;
