import styles from "./components.module.css";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addNewCampamento } from "../../../../redux/features/campamentos/campamentoSlice";
import { toast } from "react-toastify";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import Map from "./MapCreate";
import CircleIcon from "@mui/icons-material/Circle";
import { fileTypeFromBuffer } from "file-type";

const data = {
  nombre: "",
  direccion: "",
  descripcion: "",
  FilePlano: {},
};
let center = {
  lat: -20.970199203995477,
  lng: -68.62726002237389,
};

const Create = ({ open2, setOpen2 }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); //variable para abrir y cerrar modal

  const [position, setPosition] = useState(center);
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [value, setValue] = useState(null);

  const handleFileChange = async (newValue) => {
    const fileBuffer = await newValue.arrayBuffer();
    const type = await fileTypeFromBuffer(fileBuffer);

    if (
      !type ||
      !["image/png", "image/jpeg", "image/jpg"].includes(type.mime)
    ) {
      return false;
    }
    return true;
  };

  const handleChange = async (newValue) => {
    if (newValue) {
      const isValidFile = await handleFileChange(newValue);

      if (!isValidFile) {
        toast.error("Tipo de archivo no permitido.");
        return;
      }

      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(newValue.type)) {
        toast.error("Tipo de archivo no permitido.");
        return;
      }
      if (newValue.size > 5 * 1024 * 1024) {
        toast.error("El archivo es demasiado grande.");
        return;
      }

      setValue(newValue);
      setFormData({ ...formData, FilePlano: newValue });
    } else {
      toast.error("Tipo de archivo no permitido.");
    }
  };

  useEffect(() => {
    setValue(null);
    setFormData(data);
  }, [open]);

  // const onChange = (e) =>
  //   setFormData({ ...formData, [e.target.name]: e.target.value });

  const onChange = (e) => {
    const value = e.target.value;
    const invalidCharacters = /[<>\/]/;

    if (!invalidCharacters.test(value)) {
      setFormData({ ...formData, [e.target.name]: value });
    } else {
      toast.error("Carácter no válido");
    }
  };

  const canSave =
    [formData.nombre, formData.direccion].every(Boolean) &&
    addRequestStatus === "idle";

  const handleSubmit = async () => {
    if (!formData.nombre || !formData.direccion) {
      toast.error("Por favor ingrese los datos.");
      return;
    }
    if (canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus("loading");
        let resp = await dispatch(addNewCampamento(formData));
        if (resp.payload.data.success) {
          setFormData(data);
          setOpen(false);
          return false;
        }
      } catch (error) {
        toast.error("Error al crear el campamento");
        console.error("Error al crear el campamento", error.message);
      } finally {
        setAddRequestStatus("idle");
        setOpen2(false);
      }
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          El siguiente <strong>botón</strong> te mostrará un formulario para
          poder registrar un campamento.
        </Typography>
        <Button
          variant="contanied"
          size="large"
          className={styles.bgColor}
          onClick={() => setOpen(true)}
        >
          <CircleIcon className={styles.iconButton} /> Crear Campamento
        </Button>
      </div>
      <Dialog
        style={{ borderRadius: "0" }}
        open={open}
        maxWidth="md"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle
          style={{ display: "flex", gap: "5px", margin: "0", padding: "16px" }}
        >
          <HolidayVillageIcon
            style={{ fontSize: "3rem", marginTop: "0px" }}
            className={styles.iconBg}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="span"
              style={{ fontSize: "24px", marginBottom: "0", lineHeight: "1" }}
            >
              Crear campamento
            </Typography>
            <span className={styles.subTitle}>
              Rellena los siguientes campos para crear el campamento.
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item xs={12} sm={6}>
              <label className={styles.label}>Nombre</label>
              <TextField
                variant="outlined"
                fullWidth
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                name="nombre"
                type="text"
                value={formData.nombre}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            {/*<Grid item  xs={12} sm={6}>
              <label className={styles.label}>Dirección</label>
              <TextField
                variant="outlined"
                className={styles.textField}
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color:'#2d3037',
                  },
                  '& .MuiInputLabel-root': {
                    color:'#2d3037',
                  },
                }}
                name='direccion'
                type="text"
                onChange={(e) => onChange(e)}
              />
            </Grid> */}
            <Grid item xs={12} sm={12}>
              <label
                className={styles.label}
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <CloudUploadIcon style={{ height: "20px" }} /> Imagen del plano
                (opcional)
              </label>

              <MuiFileInput
                style={{ width: "100%", color: "#FFF" }}
                inputProps={{ accept: "image/png, image/jpeg, image/jpg" }}
                value={value}
                sx={{
                  // Agrega estilos adicionales aquí si es necesario
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                    borderColor: "#FFF",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#FFF",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#FFF",
                  },
                  "&:hover .MuiInput-underline:before": {
                    borderBottomColor: "#FFF",
                  },
                  "& .MuiFormHelperText-root": {
                    color: "#2d3037",
                  },
                }}
                className={styles.textField}
                onChange={handleChange}
                placeholder="Suba una imagen"
                helperText="Extensiones validas: .jpg, .jpeg, .png"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Descripción (opcional)</label>
              <TextField
                variant="outlined"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                name="descripcion"
                type="text"
                multiline
                value={formData.descripcion}
                className={styles.textField}
                rows={4}
                maxRows={4}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Map
              position={position}
              setPosition={setPosition}
              setFormData={setFormData}
              formData={formData}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true} style={{ padding: "0 16px" }}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained"
                disabled={!canSave}
              >
                {addRequestStatus == "loading" ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Crear"
                )}
              </Button>
              <Button
                onClick={() => setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Create;
