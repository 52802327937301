import React, { useEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { useAuth } from "../../context/AppContext";
import { createAxiosInstance } from "../../api/axios";

const GetToken = () => {
  const { instance } = useMsal();
  const { loginSSO } = useAuth();
  const hasLoggedInRef = useRef(false); // Usamos useRef para evitar múltiples llamadas

  const axiosInstance = createAxiosInstance();

  const logToBackend = async (message, type = "info") => {
    try {
      const logData = {
        message: typeof message === "string" ? message : message.toString(),
        type,
        timestamp: new Date().toISOString(),
      };
      console.log(logData);

      await axiosInstance.post(
        "/api/v1/Auth/SendLogSSO?detail=" + logData.message
      );
    } catch (error) {
      console.error("Error al enviar el log al backend:", error);
    }
  };

  useEffect(() => {
    const getUrlParameters = () => {
      // Verificar tanto la query string como el hash
      const queryParams = new URLSearchParams(window.location.search); // Para parámetros de la query string
      const hashParams = new URLSearchParams(window.location.hash.substring(1)); // Para parámetros del hash

      return {
        code: queryParams.get("code") || hashParams.get("code"),
        client_info:
          queryParams.get("client_info") || hashParams.get("client_info"),
        state: queryParams.get("state") || hashParams.get("state"),
        session_state:
          queryParams.get("session_state") || hashParams.get("session_state"),
      };
    };

    const decodeBase64 = (base64String) => {
      try {
        const decodedString = atob(base64String);
        return JSON.parse(decodedString); // Convertir la cadena decodificada en JSON
      } catch (error) {
        console.error("Error al decodificar Base64:", error);
        return null;
      }
    };

    const handleLogin = async () => {
      const params = getUrlParameters();
      console.log("Parámetros capturados:", params);
      logToBackend("Parámetros capturados", "info");

      if (params.client_info && !hasLoggedInRef.current) {
        const decodedClientInfo = decodeBase64(params.client_info);
        if (decodedClientInfo) {
          const { uid, utid } = decodedClientInfo; // Extraer uid y utid
          console.log("UID:", uid);
          console.log("UTID:", utid);

          // Llamar a loginSSO con el uid extraído
          await loginSSO(uid);
          hasLoggedInRef.current = true; // Actualizamos ref para evitar múltiples llamadas
        }
      }
    };

    // Llama a handleLogin solo una vez al montar el componente
    handleLogin();
  }, [loginSSO]); // Solo depende de loginSSO

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <div>
      <h1>...</h1>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default GetToken;
