import styles from './components.module.css';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useState } from 'react';
import { vistaPreviaColumns } from "../../../../lib/columns";
import { Button, Grid, Typography, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { asignarHabitaciones } from '../../../../redux/features/reservas/reservaSlice';

const VistaPrevia = ({ checkedState, reserva, checkedHabitaciones, envioS, formatFecha }) => {
    const [seleItem, setSeleItem] = useState();
    const [open, setOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [addRequestStatus, setAddRequestStatus] = useState('idle');    
    const finalColumns = vistaPreviaColumns(setOpen, setSeleItem);
    const dispatch = useDispatch();
    
    

    const asignar = async () => {
        setAddRequestStatus('loading')
        console.log('reserva',reserva)
        try {
            console.log(envioS)
            if(checkedHabitaciones.length > checkedState.length){
                toast.warning('Se han seleccionado más habitaciones que trabajadores, solo se asignará una cantidad habitaciones equivalente a los trabajadores seleccionados.')
            }else if(checkedHabitaciones.length < checkedState.length){
                    toast.warning('Se han seleccionado menos habitaciones que trabajadores, solo se asignará una cantidad de habitaciones equivalente a los trabajadores seleccionados.')
            }
            const resp = await dispatch(asignarHabitaciones(envioS))
            if(resp.payload.status === 'success'){
                
            }
        } catch (error) {
            console.log('Error al asignar las habitaciones: ',error.message)
        } finally {
            setAddRequestStatus('idle')
        }
    }

  return (
    <Grid container={true} spacing={2}>
        <Grid item xs={12} sm={6}>
            <Typography variant="span">Solicitante: <strong>{reserva.nombreSolicitante} {reserva.apellidoSolicitante}</strong></Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Typography variant="span">Email: <strong>{reserva.emailSolicitante}</strong></Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Typography variant="span">Fecha de ingreso: <strong>{formatFecha(reserva.fechaIngreso)}</strong></Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Typography variant="span">Fecha de salida: <strong>{formatFecha(reserva.fechaSalida)}</strong></Typography>
        </Grid>
        <Grid item xs={12} sm={12} style={{ textAlign:'end' }}>
        <Button
            onClick={asignar}
            className={styles.bgColor}
            variant="contained"
            disabled={addRequestStatus == 'loading'}
        >
        {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Asignar'}
        </Button>
        </Grid>
        <Grid item xs={12} sm={12} mt={1}>
            <DataGrid
                getRowId={row=>row.id ? row.id : Math.random() }
                // checkboxSelection
                // onRowSelectionModelChange={handleRowSelectionChange}
                lots={{ toolbar: GridToolbar }}
                rows={checkedHabitaciones}
                columns={finalColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 20]}
                localeText={{
                    ...esES.components.MuiDataGrid.defaultProps.localeText,
                    noRowsLabel: "No hay datos disponibles",
                    pagination: {
                        labelRowsPerPage: "Filas por página:",
                    }
                }}
                classes={{ menuIcon: styles.menuIcon }}
            />
        </Grid>
    </Grid>
  )
}

export default VistaPrevia