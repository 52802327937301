import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es'; // Importa la localización de moment en español
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment-timezone'; // Importa moment-timezone
import { addDays, format, parseISO } from 'date-fns';

const calendarStyles = {
  color: 'white',
  backgroundColor: 'var(--border-orange)',
  borderRadius: '0px',
  border: 'none',
};


const messages = {
  today: 'Hoy',
  previous: 'Anterior',
  next: 'Siguiente',
  month: 'Mes',
  week: 'Semana',
  day: 'Día',
  agenda: 'Agenda',
  date: 'Fecha',
  time: 'Hora',
  event: 'Evento',
};

const localizer = momentLocalizer(moment);

const Calendario = ({ events }) => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  const mappedEvents = events.map(evento => {
    const fechaInicio = format(parseISO(evento.desde), 'yyyy-MM-dd') + 'T' + evento.horarioAseo.horaInicio;
    const fechaTermino = format(parseISO(evento.hasta), 'yyyy-MM-dd') + 'T' + evento.horarioAseo.horaTermino;
    const trabajadores = evento.trabajadores.map(trabajador => `${trabajador.nombre}(${trabajador.rut})`).join(', ');
    const title = `${evento.horarioAseo.nombre}\n : ${trabajadores}`;
    console.log('fechaInicio: ',fechaInicio)
    console.log('fechaTermino: ',fechaTermino)
    return {
      title,
      start: moment(fechaInicio).toDate(),
      end: moment(fechaTermino).toDate(),
    };
  });

  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        fontSize: '12px', // Tamaño de la fuente deseado
        wordBreak: 'break-word',
      },
    };
  };

  return (
    <div style={{ height: '585px', width:'100%' }}>
    <style>{`
      .rbc-header {
        ${calendarStyles} 
      }
      .rbc-btn-group > button {
        ${calendarStyles}
      }
      .rbc-toolbar button {
        color: #373a3c;
        display: inline-block;
        margin: 0;
        text-align: center;
        vertical-align: middle;
        background: none;
        background-image: none;
        border: 1px solid #ccc;
        padding: 0.375rem 1rem;
        border-radius: 4px;
        line-height: normal;
        white-space: nowrap;
        color: white;
      }
        .rbc-today {
          background-color: var(--border-blue);
        }
        .rbc-off-range-bg {
          background-color: var(--textfield-bg);
        }
    `}</style>
      <Calendar
        localizer={localizer}
        events={mappedEvents}
        startAccessor="start"
        endAccessor="end"
        views={['month', 'week', 'day']}
        defaultView="month"
        messages={messages}
        eventPropGetter={(event, start, end, isSelected) => {
          let newStyle = {
            backgroundColor: "#004a91",
            color: 'white',
            borderRadius: "0px",
            border: "none",
            fontSize: '12px', // Tamaño de la fuente deseado
            wordBreak: 'break-word',
          };
      
          if (event.isMine) {
            newStyle.backgroundColor = "#174300";
          }
      
          return {
            className: "",
            style: newStyle
          };
        }}
      />
    </div>
  );
};

export default Calendario;


// import { Calendar, dayjsLocalizer } from 'react-big-calendar';
// import dayjs from 'dayjs'
// import 'moment/locale/es'; // Importa la localización de moment en español
// import 'react-big-calendar/lib/css/react-big-calendar.css';
// import 'moment-timezone'; // Importa moment-timezone
// import { addDays, format, parseISO } from 'date-fns';

// const messages = {
//   today: 'Hoy',
//   previous: 'Anterior',
//   next: 'Siguiente',
//   month: 'Mes',
//   week: 'Semana',
//   day: 'Día',
//   agenda: 'Agenda',
//   date: 'Fecha',
//   time: 'Hora',
//   event: 'Evento',
// };

// const localizer = dayjsLocalizer(dayjs);

// const Calendario = ({ events }) => {
//   const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
//   const mappedEvents = events.map(evento => {
//     const fechaInicio = format(parseISO(evento.desde), 'yyyy-MM-dd') + 'T' + evento.horarioAseo.horaInicio;
//     const fechaTermino = format(parseISO(evento.hasta), 'yyyy-MM-dd') + 'T' + evento.horarioAseo.horaTermino;
//     const title = `${evento.horarioAseo.nombre}`;
//     console.log('fechaInicio: ',fechaInicio);
//     console.log('fechaTermino: ',fechaTermino);
    
//     console.log('fechaInicioMoment: ', dayjs(fechaInicio).toDate());
//     console.log('fechaTerminoMoment: ', dayjs(fechaTermino).toDate(),);

//     return {
//       title,
//       start: dayjs('2024-06-10T:15:00:00').toDate(),
//       end: dayjs('2024-06-10T:16:00:00').toDate(),
//       // start: dayjs(fechaInicio).toDate(),
//       // end: dayjs(fechaTermino).toDate(),
//     };
//   });

//   const eventStyleGetter = (event, start, end, isSelected) => {
//     return {
//       style: {
//         fontSize: '12px', // Tamaño de la fuente deseado
//         wordBreak: 'break-word',
//       },
//     };
//   };

//   return (
//     <div style={{ height: '585px', width:'100%' }}>
//     {/*<style>{`
//       .rbc-time-header {
//         height: 100%;
//       }
//       .rbc-time-view {
//         .rbc-label {
//           display: none;
//         }
//         .rbc-allday-cell {
//           height: 100%
//           max-height: unset;
//         }
//         .rbc-time-content {
//           display: none;
//         }
//       }
//     `}</style> */}
//       <Calendar
//         localizer={localizer}
//         events={mappedEvents}
//         startAccessor="start"
//         endAccessor="end"
//         views={['month', 'week', 'day']}
//         defaultView="month"
//         messages={messages}
//         eventPropGetter={eventStyleGetter}
//       />
//     </div>
//   );
// };

// export default Calendario;
