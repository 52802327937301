import styles from './components.module.css';
import { useEffect, useState } from "react";
import { aprobacionColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import Edit from './Edit';

const Table = ({ solicitudes, setOpen3 }) => {

  const [seleItem, setSeleItem] = useState();
  const [ open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = aprobacionColumns(setSeleItem, setOpenEdit);
  
  // useEffect(() => {
  //   console.log('aaa',checkedState);
  // }, [checkedState]);

  return (
    <div style={{ marginTop:'16px' }}>
      <Edit openEdit={openEdit} setOpenEdit={setOpenEdit} seleItem={seleItem} 
      setOpen3={setOpen3}
      />
        <DataGrid
            getRowId={row=>row.rut ? row.rut : Math.random() }
            // checkboxSelection
            // onRowSelectionModelChange={handleRowSelectionChange}
            lots={{ toolbar: GridToolbar }}
            rows={solicitudes}
            columns={finalColumns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15, 20]}
            localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
                labelRowsPerPage: "Filas por página:",
            }
            }}
            classes={{ menuIcon: styles.menuIcon }}
            style={{ maxHeight:'500px' }}
        />
    </div>
  )
}

export default Table