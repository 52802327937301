import styles from "./components/components.module.css";
import {
  Box,
  Card,
  Container,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchReservas,
  getAllreservas,
  getReservasStatus,
} from "../../redux/features/reservas/reservaSlice";
import Table from "./components/Table";
import TableCard from "../../components/tableCard/TableCard";
import SkeletonTable from "../../components/Skeleton";

// const reservas = [
//   {
//     id: 101,
//     fechaSolicitud: "2024-09-01T10:15:00.8405137",
//     fechaIngreso: "2024-09-01T00:00:00",
//     fechaSalida: "2024-09-10T00:00:00",
//     observaciones: "Revisión técnica",
//     estado: "Pendiente",
//     razonRechazo: null,
//     vicepresidencia: "VP - Innovación y Desarrollo",
//     gerencia: "GERENCIA INNOVACIÓN TECNOLÓGICA",
//     sI_Area: "SI INGENIERÍA",
//     empresa: {
//       idEmpresa: "76254800-2",
//       razonSocial: "TECNOLOGÍA AVANZADA S.A.",
//       representanteLegal: "José Pérez",
//       direccion: "CALLE LAS TORRES 123, SANTIAGO",
//       fono: "569 98765432",
//       email: "jperez@tecnologiaavanzada.cl",
//       contacto: "Carlos Muñoz",
//     },
//     contrato: "ITC00234",
//     trabajadoresTotales: 3,
//     aprobaoPor: null,
//     rechazadoPor: null,
//     rutSolicitante: "15431234-5",
//     nombreSolicitante: "Pedro Andrés",
//     apellidoSolicitante: "González Rivas",
//     emailSolicitante: "pgonzalez@ejemplo.cl",
//     forecast: true,
//   },
//   {
//     id: 102,
//     fechaSolicitud: "2024-09-05T14:30:00.3379093",
//     fechaIngreso: "2024-09-06T00:00:00",
//     fechaSalida: "2024-09-20T00:00:00",
//     observaciones: "Montaje equipo",
//     estado: "Programada",
//     razonRechazo: null,
//     vicepresidencia: "VP - Producción",
//     gerencia: "GERENCIA PRODUCCIÓN INDUSTRIAL",
//     sI_Area: "SI PRODUCCIÓN MECÁNICA",
//     empresa: {
//       idEmpresa: "76982345-1",
//       razonSocial: "INDUSTRIAS MECÁNICAS LTDA.",
//       representanteLegal: "María López",
//       direccion: "AV. SAN MARTÍN 456, CONCEPCIÓN",
//       fono: "569 65432198",
//       email: "mlopez@indmecanicas.cl",
//       contacto: "Fernando Castro",
//     },
//     contrato: "PROD00123",
//     trabajadoresTotales: 5,
//     aprobaoPor: null,
//     rechazadoPor: null,
//     rutSolicitante: "16872345-6",
//     nombreSolicitante: "Ana María",
//     apellidoSolicitante: "Fernández Silva",
//     emailSolicitante: "afernandez@ejemplo.cl",
//     forecast: false,
//   },
//   {
//     id: 94,
//     fechaSolicitud: "2024-09-10T09:45:00.3806676",
//     fechaIngreso: "2024-09-12T00:00:00",
//     fechaSalida: "2024-09-18T00:00:00",
//     observaciones: "Instalación cancelada",
//     estado: "Rechazada",
//     razonRechazo:
//       "No se encontro Forecast del contrato en las fechas solicitadas",
//     vicepresidencia: "VP - Recursos Humanos",
//     gerencia: "GERENCIA PERSONAL Y BIENESTAR",
//     sI_Area: "SI RECURSOS HUMANOS",
//     empresa: {
//       idEmpresa: "75567834-4",
//       razonSocial: "CONSULTORA GESTIÓN HUMANA",
//       representanteLegal: "Luis García",
//       direccion: "CALLE PRINCIPAL 789, VALPARAÍSO",
//       fono: "569 87654321",
//       email: "lgarcia@consultoragestion.cl",
//       contacto: "Sofía Rojas",
//     },
//     contrato: "HRG00456",
//     trabajadoresTotales: 2,
//     aprobaoPor: null,
//     rechazadoPor: {
//       phoneNumber: "3215",
//       rut: "23456789-0",
//       nombre: "Claudia Patricia",
//       apellido: "Martínez López",
//       genero: "F",
//       cargo: "Jefa de Personal",
//       cargoId: "20002001",
//       fechaNacimiento: "1985-07-12T00:00:00",
//       id: "bcf23562-4a7b-42a5-95be-c8507ef350de",
//       userName: "cmartinez@consultora.cl",
//       email: "cmartinez@consultora.cl",
//     },
//     rutSolicitante: "18923456-7",
//     nombreSolicitante: "Rodrigo Javier",
//     apellidoSolicitante: "Morales Soto",
//     emailSolicitante: "rmorales@ejemplo.cl",
//     forecast: false,
//   },
// ];

const DashboardHoteleria = () => {
  const addRequestStatus = "idle";
  const reservas = useSelector(getAllreservas);
  const dispatch = useDispatch();
  const status = useSelector(getReservasStatus);

  useEffect(() => {
    if (status == "idle") {
      dispatch(fetchReservas());
    }
  }, []);
  useEffect(() => {
    console.log("status", status);
  }, [status]);

  return (
    <div>
      <Box width={1} mt={0} mb={2}>
        <Container className={styles.container} style={{ padding: 0 }}>
          <TableCard>
            {status == "loading" ? (
              <SkeletonTable />
            ) : (
              <Table
                registros={reservas || []}
                addRequestStatus={addRequestStatus}
              />
            )}
          </TableCard>
        </Container>
      </Box>
    </div>
  );
};

export default DashboardHoteleria;
