import { Box, Container } from "@mui/material"
import Header from "../../../components/header/Header"
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components_ingreso/Create";
import Table from './components_ingreso/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SkeletonTable from "../../../components/Skeleton";
import { Backdrop, CircularProgress } from '@mui/material';
import StormIcon from '@mui/icons-material/Storm';
import { fetchForecastAreas, fetchForecastGerencias, fetchForecasts, getAllAreas, getAllForecast, getAllGerencia, getforecastStatus, getforecastStatusSolicitud } from "../../../redux/features/forecast/forecastSlice";
import { fetchEmpresas, getAllEmpresas } from "../../../redux/features/empresas/empresaSlice";
import { useAuth } from "../../../context/AppContext";
import { fetchTurnos, getAllTurnos, getTurnosStatus } from "../../../redux/features/users/userSlice";

const Gestion = () => {
  const { info } = useAuth();
  const empresas = info?.empresas;

  const dispatch = useDispatch();
  const status = useSelector(getforecastStatus);
  const statusSolicitudes = useSelector(getforecastStatusSolicitud);
  const forecast = useSelector(getAllForecast);
  const gerencia = useSelector(getAllGerencia);
  const siArea = useSelector(getAllAreas);
  const turnos = useSelector(getAllTurnos);
  const turnosStatus = useSelector(getTurnosStatus);

  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [tramos, setTramos] = useState([]);
  // const [forecasts, setForecasts] = useState([]);
  const [viceprecidencia, setViceprecidencia] = useState([]);

  useEffect(()=>{
    if(status === 'idle'){
      dispatch(fetchForecastGerencias());
      dispatch(fetchForecastAreas());
    }
    if(turnosStatus === 'idle'){
    }
    if(statusSolicitudes === 'idle'){
    }
    dispatch(fetchForecasts());
    dispatch(fetchTurnos());
  },[]);

  useEffect(() => {
    // console.log('forecastss', forecast)
  }, [forecast])
  useEffect(() => {
    // console.log('turnos::::', turnos)
  }, [turnos])
  
  const data = {
  vicepresidencia: '',
  gerencia: '',
  empresa: '',
  area: '',
  cantidad_trabajadores_noche: 0,
  cantidad_trabajadores_dia: 0,
}

useEffect(() => {
  if (Array.isArray(gerencia)) {
    const vp = gerencia.filter(item => typeof item === 'string' && item.startsWith('VP'));
    setViceprecidencia(vp);
  }
}, [gerencia]);



  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestión de Forecast"
          subtitle="Este módulo, vizualizar y modificar los forecast registrados. Selecciona los registros del calendario para vizualizar los tramos de cada forecast.">
          {<StormIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>
        <TableCard>
          <Create setOpen2={setOpen2} vicep={viceprecidencia} siArea={siArea} empresas={empresas} ger={gerencia} open2={open2} data={data} tramos={tramos} setTramos={setTramos} registros={forecast} turnos={turnos} statusSolicitudes={statusSolicitudes}/>
          {/*{ status == 'loading' ? <SkeletonTable/> : <Table registros={registros ? registros : []} setOpen3={setOpen3}/>
        } */}
        </TableCard>
      </Container>
    </Box>
  )
}
export default Gestion