import styles from "./header2.module.css"
import { Card, CardContent, Typography, Button } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

const Header2 = ({ title, subtitle, children, buttonProps }) => {
  return (
      <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center' }} className={styles.headerCont}>
        <div className={styles.cardContent}>
          <span style={{ marginTop: '5px' }} className={styles.iconHeader}>
            {children}
          </span>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5" className={styles.header} style={{ fontWeight: '600' }}>
              {title}
            </Typography>
            {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
          </div>
        </div>
        {buttonProps && (
          <Button 
            variant="contained"
            disabled={buttonProps.disabled} 
            onClick={buttonProps.onClick} 
            className={styles.bgColor}
            style={{ height: '40px' }}
          >
          <CircleIcon className={styles.iconButton}/> {buttonProps.label}
          </Button>
        )}
      </div>
  );
}

export default Header2;
