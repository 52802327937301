import { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es'; // Importa la localización de moment en español
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment-timezone'; // Importa moment-timezone
import { format, isValid, parseISO } from 'date-fns';
import Slide from '@mui/material/Slide';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, ListItemText, TextField, Typography,Card } from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const calendarStyles = {
  color: 'white',
  backgroundColor: 'var(--border-orange)',
  borderRadius: '0px',
  border: 'none',
};

const messages = {
  today: 'Hoy',
  previous: 'Anterior',
  next: 'Siguiente',
  month: 'Mes',
  week: 'Semana',
  day: 'Día',
  agenda: 'Agenda',
  date: 'Fecha',
  time: 'Hora',
  event: 'Evento',
  more: 'más',
  showMore: function showMore(total) {
    return '+' + total + ' más';
  }
};

// Configura el localizador de fechas usando moment.js
const localizer = momentLocalizer(moment);

const CustomDateCellWrapper = ({ children }) => {
  useEffect(() => {
    const dateNumberElement = children.props.children;
    if (dateNumberElement) {
      // Busca el elemento con la clase .rbc-button-link
      const spanElement = dateNumberElement.querySelector('.rbc-button-link');
      const hours = dateNumberElement.querySelector('.rbc-event-label');
      if (spanElement) {
        spanElement.style.borderBottom = '2px solid #000'; // Cambia #000 al color que desees
        if (hours) {
          hours.style.display = 'none';
        }
      }
    }
  }, [children]);

  return children;
};

const MyCalendar = ({ events, inputRefs, setTramos, setActualizar, defaultDate }) => {
  const [open, setOpen] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState([]);

  // Filtra los eventos para asegurarse de que tengan ambas fechas
  const colors = ['#FF6347', '#FFA500', '#32CD32', '#4682B4', '#6A5ACD', '#D2691E']; // Define una lista de colores
  
  const validEvents = Array.isArray(events) && events.filter(evento => evento.fechaDesde && evento.fechaHasta);

  useEffect(() => {
    if (Array.isArray(events)) {
      setSelectedEvents(events);
    }
  }, [events]);

  // Mapea los eventos en un formato compatible con react-big-calendar
  const localTimeZone = 'America/Santiago';
  const mappedEvents = Array.isArray(validEvents) && validEvents.map((evento, index) => {
    // Ajusta la fecha de inicio y término con las horas específicas
    const fechaInicio = moment.tz(evento.fechaDesde, localTimeZone).startOf('day').toDate();
    const fechaTermino = moment.tz(evento.fechaHasta, localTimeZone).endOf('day').toDate();

    // Verifica si NumeroTrabajadores está definido antes de generar el título del evento
    // const title = (evento.nombre) ? `${evento.nombre} D:${evento.trabajadoresDia ?? 0} N:${evento.trabajadoresNoche ?? 0}` : 'Sin información de trabajadores';
    const title = evento.nombre 
    ? evento.nombre + ' D: ' + (evento.trabajadoresDia ?? '0') + ' N: ' + (evento.trabajadoresNoche ?? '0') 
    : 'Sin información de trabajadores';

    const colorIndex = index % colors.length;
    console.log({
      ...evento,
      title,
      start: fechaInicio,
      end: fechaTermino})

    return {
      ...evento,
      title,
      start: fechaInicio,
      end: fechaTermino,
      color: colors[colorIndex],
    };
  });

  const handleEventClick = event => {
    if (event) {
      console.log('event', event);
      const eventStart = moment(event.start).tz('America/Santiago').format('YYYY-MM-DD');
      const eventEnd = moment(event.end).tz('America/Santiago').endOf('day').subtract(1, 'second').format('YYYY-MM-DD');
      const index = Array.isArray(events) && events.find(e => e.id === event.id);
      
      const formatDate = (dateString) => {
        const date = parseISO(dateString);
        if (isValid(date)) {
          return format(date, 'yyyy-MM-dd');
        } else {
          return dateString;
        }
      };

      if (index !== -1) {
        const mapped = {
          ...index,
          fechaDesde: formatDate(index.fechaDesde),
          fechaHasta: formatDate(index.fechaHasta),
        };
        setTramos(mapped);
        setActualizar(true);
      }
    }
  };

  const handleSlotSelect = (slotInfo) => {
    const selectedDate = moment(slotInfo.start).startOf('day').toDate();
    const eventsOnDate = mappedEvents.filter(event =>
      moment(event.start).isSameOrBefore(selectedDate, 'day') &&
      moment(event.end).isSameOrAfter(selectedDate, 'day')
    );
    setSelectedEvents(eventsOnDate);
    setOpen(true);
  };

  const handleShowMore = (events, date) => {
    setSelectedEvents(events);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const currentDate = new Date();
  const startDate = moment(currentDate).subtract(0, 'month').toDate();

  const formats = {
    eventTimeRangeFormat: () => { 
      return "";
    },
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    color: 'black',
  };

  return (
    <div style={{ height: 500 }}>
      <style>{`
        .rbc-header {
          ${calendarStyles} 
        }
        .rbc-btn-group > button {
          ${calendarStyles}
        }
        .rbc-toolbar button {
          color: #373a3c;
          display: inline-block;
          margin: 0;
          text-align: center;
          vertical-align: middle;
          background: none;
          background-image: none;
          border: 1px solid #ccc;
          padding: 0.375rem 1rem;
          border-radius: 4px;
          line-height: normal;
          white-space: nowrap;
          color: white;
        }
        .rbc-today {
          background-color: var(--border-blue);
        }
        .rbc-off-range-bg {
          background-color: var(--button-color-outlined);
          color: gray;
        }
        .rbc-active {
          color: black !important;
        }
      `}</style>
      <Calendar
        localizer={localizer}
        showAllEvents={false}
        events={mappedEvents}
        startAccessor="start"
        endAccessor="end"
        defaultDate={defaultDate}
        views={['month']}
        defaultView="month"
        messages={messages}
        components={{
          dateCellWrapper: CustomDateCellWrapper,
          event: Event,
        }}
        formats={formats}
        eventPropGetter={(event, start, end, isSelected) => {
          let newStyle = {
            backgroundColor: event.color || "#FFF",
            color: '#000',
            borderRadius: "0px",
            border: "none"
          };
      
          return {
            className: "",
            style: newStyle
          };
        }}
        onSelectEvent={handleEventClick}
        onShowMore={handleShowMore}
        selectable={true}
        onSelectSlot={handleSlotSelect}
      />
      <Dialog
        open={open}
        maxWidth="lg"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        TransitionComponent={Transition}
        onBackdropClick={() => {
          // setOpen(false);
        }}
      >
      <DialogTitle>
        <Typography variant="h6" component="h2">Tramos seleccionados</Typography>
      </DialogTitle>
      <DialogContent>
          <Grid container={true}>
            {Array.isArray(selectedEvents) && selectedEvents.length > 0 ? (
              selectedEvents.map(event => (
                <Grid item xs={12} md={12} key={event.id} style={{ backgroundColor:'#212227', color:'#FFF', padding:'10px', marginBottom:'5px' }}>
                  <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                    <strong style={{ marginRight:'5px' }}>{event.title}</strong>
                    <p>{moment(event.start).format('DD/MM/YYYY')} - {moment(event.end).format('DD/MM/YYYY')}</p>
                  </div>
                  {event.turno && <div>
                    <p>ID Turno: {event?.turno.idTurno}</p>
                    <p>Tipo Jornada: {event?.turno.tipoJornada}</p>
                    <p>Descripción: {event?.turno.descripcion}</p>
                  </div>}
                  <div style={{ marginTop:'5px' }}>
                    <p>Gerencia: {event?.gerencia}</p>
                    <p>Vicepresidencia: {event.vicepresidencia}</p>
                    <p>siArea: {event?.siArea}</p>
                    <p>Contrato: {event?.contrato}</p>
                  </div>
                  
                </Grid>
              ))
            ) : (
              <p>No hay eventos para mostrar</p>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button style={{ color:'#FFF', marginTop:'15px', padding:'0' }} onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// Componente Event personalizado
const Event = ({ event }) => (
  <span>
    <strong style={{ fontSize: '12px', fontWeight: '500' }}>{event.title}</strong>
  </span>
);

export default MyCalendar;
