import styles from "./components.module.css";
import Drawer from "@mui/material/Drawer";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  Popper,
  Select,
  TextField,
  Typography,
  ListItemText,
  MenuItem,
  OutlinedInput,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { updateHabitacion } from "../../../../redux/features/habitaciones/habitacionSlice";
import { getAllWings } from "../../../../redux/features/wings/wingSlice";
import { getAllEstandares } from "../../../../redux/features/estandares/estandarSlice";
import { getAllEdificios } from "../../../../redux/features/edificios/edificioSlice";
import { getAllInventarios } from "../../../../redux/features/inventario/inventarioSlice";

const tipo_huesped = [
  { label: "Sólo mujeres", value: "Solo mujeres" },
  { label: "Sólo hombres", value: "Solo hombres" },
  { label: "Unisex", value: "Unisex" },
];
const tipo_cargos = [
  "Administrador de contrato",
  "Administrativo",
  "Supervisor",
  "Operador",
  "Mantenedor",
];

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Edit({
  openEdit,
  setOpenEdit,
  seleItem,
  setOpen3,
  inventario,
}) {
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [formData, setFormData] = useState(null);
  const dispatch = useDispatch();
  const wings = useSelector(getAllWings);
  const pabellones = useSelector(getAllEdificios);
  const estandares = useSelector(getAllEstandares);
  const [cargo, setCargo] = useState(
    seleItem?.cargosReservados ? seleItem?.cargosReservados : []
  );
  const [checked, setChecked] = useState(true);
  const productos = useSelector(getAllInventarios);

  useEffect(() => {
    setFormData(seleItem);
    console.log(seleItem);
    setCargo(seleItem?.cargosReservados);
    setChecked(seleItem?.tieneOxigeno);
    console.log(estandares);
  }, [seleItem]);

  const handleChangeSector = (e, value) =>
    setFormData({ ...formData, pabellonId: value ? value.id : null });

  // const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });
  const onChange = (e) => {
    const value = e.target.value;
    const invalidCharacters = /[<>\/]/;

    if (!invalidCharacters.test(value)) {
      setFormData({ ...formData, [e.target.name]: value });
    } else {
      toast.error("Carácter no válido");
    }
  };

  const onChangeCheckbox = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
    setChecked((prev) => !prev);
  };

  const onchangeProducto = (event, newValue) => {
    console.log(newValue);
    if (newValue != null) {
      //   const prod = newValue.map((it)=> it.id)
      setFormData({ ...formData, productos: newValue });
    } else {
      setFormData({ ...formData, productos: [] });
    }
  };

  useEffect(() => {
    if (formData) {
      const prod = formData.productos.map((it) => it.id);
      setFormData({ ...formData, productosId: prod });
    }
  }, [formData?.productos]);

  const handleChangeTipo = (event, newValue, tipo) => {
    if (newValue != null) {
      if (tipo == "huesped") {
        setFormData({ ...formData, tipoHuesped: newValue.value });
      } else if (tipo == "standard") {
        setFormData({ ...formData, standarId: newValue.value });
      }
    }
  };
  const handleChange = (event) => {
    const cargoValue =
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value;
    setCargo(cargoValue);
    setFormData({ ...formData, cargosReservados: cargoValue });
  };

  const canSave =
    [
      formData?.numero,
      formData?.piso,
      formData?.tipoHuesped,
      formData?.numeroTotalCamas,
      formData?.cargosReservados,
      formData?.standardId,
      //  formData?.idChapa
    ].every(Boolean) && addRequestStatus === "idle";

  const handleEdit = async () => {
    let suma =
      parseInt(formData?.cantidadCamarotes) * 2 +
      parseInt(formData?.cantidadCamasIndividuales);
    if (
      suma > parseInt(formData?.cantidadCamas) ||
      suma < parseInt(formData?.cantidadCamas)
    ) {
      toast.error(
        "La suma de los camarotes y camas individuales no concuerda con el total de camas."
      );
      return false;
    }

    if (formData.nroCamas == "") {
      formData.nroCamas = 0;
    }
    if (formData.nroCamarotes == "") {
      formData.nroCamarotes = 0;
    }

    try {
      setOpen3(true);
      setAddRequestStatus("loading");
      let resp = await dispatch(updateHabitacion(formData));
      console.log("resp", resp);
      if (resp.payload.data.success) {
        setFormData(null);
        return;
      }
    } catch (error) {
      toast.error("Error al actualizar el habitación.");
      console.error("Error al actualizar el habitación", error.message);
    } finally {
      setAddRequestStatus("idle");
      setOpenEdit(false);
      setOpen3(false);
    }
  };

  return (
    <div>
      <Drawer
        anchor="right"
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        PaperProps={{
          sx: {
            width: { xs: "100%", sm: "75%", md: "50%" },
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
      >
        {seleItem && (
          <>
            <DialogTitle>
              <Grid container={true} spacing={1} p={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ display: "flex", gap: "5px" }}
                >
                  <ErrorIcon
                    style={{ fontSize: "3rem" }}
                    className={styles.iconBg}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h6" className={styles.textIconEdit}>
                      Actualizar habitación {seleItem?.numero}
                    </Typography>
                    <Typography variant="span" className={styles.textIconSpan}>
                      Modifica los campos para actualizar la habitación.
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent>
              <Grid container={true} spacing={2} p={2}>
                {formData?.pabellonId ? (
                  <Grid item xs={12} sm={8}>
                    <label className={styles.label}>Pabellón</label>
                    <Autocomplete
                      disablePortal
                      disableClearable
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                      }}
                      id="sectores-autocomplete"
                      options={pabellones}
                      name="pabellones"
                      getOptionLabel={(option) => option.identificador}
                      value={
                        (formData?.pabellonId &&
                          pabellones.find(
                            (option) => option.id === formData?.pabellonId
                          )) ||
                        null
                      }
                      onChange={(e, value) => handleChangeSector(e, value)}
                      renderInput={(params) => (
                        <TextField {...params} label="Selecciona un Pabellón" />
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={8}>
                    <label className={styles.label}>Wing</label>
                    <Autocomplete
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                      }}
                      disablePortal
                      disableClearable
                      id="sectores-autocomplete"
                      options={wings}
                      name="wing"
                      getOptionLabel={(option) => option.nombre}
                      value={
                        (formData?.wingId &&
                          wings.find(
                            (option) => option.id === formData?.wingId
                          )) ||
                        null
                      }
                      onChange={(e, value) => handleChangeSector(e, value)}
                      renderInput={(params) => (
                        <TextField {...params} label="Selecciona un wing" />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={4}>
                  <label className={styles.label}>Numero</label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name="numero"
                    type="number"
                    value={formData?.numero}
                    onChange={(e) => onChange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label className={styles.label}>Piso</label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name="piso"
                    type="number"
                    value={formData?.piso}
                    onChange={(e) => onChange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label className={styles.label}>N°camas</label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name="numeroTotalCamas"
                    value={formData?.numeroTotalCamas}
                    type="number"
                    onChange={(e) => onChange(e)}
                  />
                </Grid>
                {/* CONSIDERAR: AGREGAR LA DEPENDENCIA Y EL ERROR DE NO COINCIDENCIA */}

                <Grid item xs={12} sm={6}>
                  <label className={styles.label}>
                    N°camarotes (2 camas x unidad)
                  </label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name="nroCamarotes"
                    value={formData?.nroCamarotes}
                    type="number"
                    onChange={(e) => onChange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label className={styles.label}>N°camas individuales</label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name="nroCamas"
                    value={formData?.nroCamas}
                    type="number"
                    onChange={(e) => onChange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label className={styles.label}>Descripción (opcional)</label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name="descripcion"
                    type="text"
                    multiline
                    rows={4}
                    maxRows={4}
                    value={formData?.descripcion}
                    onChange={(e) => onChange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label className={styles.label}>Metros cuadrados</label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name="metrosCuadrados"
                    type="number"
                    value={formData?.metrosCuadrados || ""}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e) => onChange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label className={styles.label}>
                    ID chapa electrónica (opcional)
                  </label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name="idChapa"
                    value={formData?.idChapa}
                    type="text"
                    onChange={(e) => onChange(e)}
                  />
                </Grid>
                {/* <Grid item  xs={12} sm={12}>
                        <label className={styles.label}>Chapa electrónica (opcional)</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            name='chapa_electronica'
                            type="text"
                            multiline
                            rows={2}
                            maxRows={4}
                            onChange={(e) => onChange(e)}
                        />
                        </Grid> */}
                <Grid item xs={12} sm={6}>
                  <label className={styles.label}>Tipo de cama</label>
                  <Select
                    style={{ width: "100%" }}
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    name="tipoCama"
                    value={formData?.tipoCama || null}
                    onChange={(e) => onChange(e)}
                  >
                    <MenuItem value="Una plaza">Una plaza</MenuItem>
                    <MenuItem value="Plaza y media">Plaza y media</MenuItem>
                    <MenuItem value="Dos plazas">Dos plazas</MenuItem>
                    <MenuItem value="Queen">Queen</MenuItem>
                    <MenuItem value="King">King</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label className={styles.label}>Tipo de huesped</label>
                  <Autocomplete
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    disablePortal
                    id="campamentos-autocomplete"
                    options={tipo_huesped}
                    name="tipo_huesped"
                    getOptionLabel={(option) => option.label}
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          height: "200px",
                          overflowY: "auto",
                        }}
                      />
                    )}
                    value={
                      tipo_huesped.find(
                        (option) => option.value == formData?.tipoHuesped
                      ) || null
                    }
                    onChange={(e, value) =>
                      handleChangeTipo(e, value, "huesped")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona un tipo de huesped"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label className={styles.label}>Tipo de cargo</label>
                  <Select
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    style={{ width: "100%" }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={cargo ? cargo : []}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {tipo_cargos.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={cargo?.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label className={styles.label}>Tipo de standard</label>
                  <Autocomplete
                    disablePortal
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    id="campamentos-autocomplete"
                    options={estandares}
                    name="standardId"
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          overflowY: "auto",
                        }}
                      />
                    )}
                    value={
                      (formData?.standardId &&
                        estandares.find(
                          (option) => option.id == formData?.standardId
                        )) ||
                      null
                    }
                    getOptionLabel={(option) => option.nombre}
                    onChange={(e, value) =>
                      handleChangeTipo(e, value, "standard")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona un tipo de standard"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <label className={styles.label}>
                    Busca un articulo del inventario
                  </label>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    id="sectores-autocomplete"
                    name="inventario"
                    multiple
                    value={formData?.productos || []}
                    options={productos ? productos : []}
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          maxHeight: "200px",
                          overflowY: "auto",
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option.nombre}
                    onChange={(e, value) => onchangeProducto(e, value)}
                    renderInput={(params) => (
                      <TextField {...params} label="Selecciona articulos" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label className={styles.label}>
                    Cuenta con sistema fijo de oxígeno
                  </label>
                  {seleItem.id != null && (
                    <Checkbox
                      {...label}
                      checked={checked ? checked : false}
                      sx={{
                        color: "#2d3037",
                        "&.Mui-checked": {
                          color: "#2d3037",
                        },
                        "& .MuiSvgIcon-root": {
                          borderColor: "#2d3037",
                        },
                      }}
                      name="tieneOxigeno"
                      onChange={(e) => onChangeCheckbox(e)}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container style={{ padding: "0 16px" }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={styles.buttonContainerEdit}
                >
                  <Button
                    onClick={handleEdit}
                    className={styles.bgColorEdit}
                    variant="contained"
                    disabled={!canSave}
                  >
                    {addRequestStatus == "loading" ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      "Actualizar"
                    )}
                  </Button>
                  <Button
                    onClick={() => setOpenEdit(false)}
                    className={styles.bgColorOutlined}
                    variant="outlined"
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        )}
      </Drawer>
    </div>
  );
}
