import styles from "./components.module.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
import { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllEdificios } from "../../../../redux/features/edificios/edificioSlice";
import { updateEspacio } from "../../../../redux/features/espacios/espacioSlice";
import { getAllWings } from "../../../../redux/features/wings/wingSlice";
import { getAllCampamentos } from "../../../../redux/features/campamentos/campamentoSlice";

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3 }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedWing, setSelectedWing] = useState(null);
  const [selectedPabellon, setSelectedPabellon] = useState(null);
  const [selectedOtro, setSelectedOtro] = useState();

  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState("");

  const [formData, setFormData] = useState(null);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [pabellonWing, setPabellonWing] = useState(0);

  const [filterPabellones, setFilterPabellones] = useState([]);
  const [filterWings, setFilterWings] = useState([]);
  const [filterOtros, setFilterOtros] = useState([]);

  const campamentos = useSelector(getAllCampamentos);
  const pabellones = useSelector(getAllEdificios);
  const wings = useSelector(getAllWings);

  useEffect(() => {
    if (seleItem) {
      setFormData(seleItem);
      console.log("seleItem: -->-->", seleItem);
      if (seleItem.pabellon) {
        setSelectedPabellon(seleItem.pabellon);
        setSelectedCampamento(seleItem.pabellon.campamento ?? null);
        setPabellonWing(1);
      }
      if (seleItem.wing) {
        setSelectedWing(seleItem.wing);
        setSelectedCampamento(seleItem.wing.campamento ?? null);
        setPabellonWing(2);
      }
      // if(seleItem.otro){
      //   setSelectedPabellon(seleItem.pabellon);
      // }
    }
  }, [seleItem]);

  const handleChange = (event) => {
    setPabellonWing(event.target.value);
  };

  // const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });
  const onChange = (e) => {
    const value = e.target.value;
    const invalidCharacters = /[<>\/]/;

    if (!invalidCharacters.test(value)) {
      setFormData({ ...formData, [e.target.name]: value });
    } else {
      toast.error("Carácter no válido");
    }
  };

  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    if (newValue != null) {
      setFormData({
        ...formData,
        campamento: newValue.id,
        sector: null,
        wingId: null,
        pabellonId: null,
      });
      setFilterPabellones(
        pabellones.filter(
          (pabellon) =>
            pabellon.campamento && pabellon.campamento.id === newValue.id
        )
      );
      setFilterWings(
        wings.filter(
          (wing) => wing.campamento && wing.campamento.id === newValue.id
        )
      );
      // setFilterOtros(otros.filter(edificio => edificio?.campamento && edificio.campamento.id === newValue.id));

      setSelectedSector(null);
      setSelectedPabellon(null);
      setSelectedWing(null);
    } else {
      setFormData({ ...formData, campamento: 0 });
    }
  };
  const handleChangeSector = (event, newValue) => {
    if (newValue != null) {
      setFormData({
        ...formData,
        sector: newValue.id,
        wingId: null,
        pabellonId: null,
      });
      setSelectedSector(newValue);
      setSelectedPabellon(null);
      setSelectedWing(null);
    } else {
      setFormData({ ...formData, sector: 0 });
    }
  };
  const handleChangePabellon = (event, newValue) => {
    if (newValue != null) {
      setSelectedWing(null);
      setFormData({ ...formData, pabellonId: newValue.id, wingId: null });
      setSelectedPabellon(newValue);
    } else {
      setFormData({ ...formData, pabellonId: 0 });
    }
  };
  const handleChangeWing = (event, newValue) => {
    if (newValue != null) {
      setFormData({
        ...formData,
        wingId: newValue.id,
        pabellonId: null,
        sector: null,
      });
      setSelectedSector(null);
      setSelectedPabellon(null);
      setSelectedWing(newValue);
    } else {
      setFormData({ ...formData, wingId: 0 });
    }
  };

  const handleChangeOtro = (event, newValue) => {
    if (newValue != null) {
      setSelectedPabellon(null);
      setSelectedWing(null);
      setFormData({
        ...formData,
        wing: null,
        pabellon: null,
        otro: newValue.id,
      });
      setSelectedOtro(newValue);
    } else {
      setFormData({ ...formData, wing: 0 });
    }
  };

  const canSave =
    [
      formData?.nombre,
      formData?.pabellonId || formData?.wingId,
      formData?.descripcion,
      formData?.piso,
    ].every(Boolean) && addRequestStatus === "idle";

  const handleEdit = async () => {
    try {
      setOpen3(true);
      setAddRequestStatus("loading");
      let resp = await dispatch(updateEspacio(formData));
      if (resp.payload.success) {
        setOpen3(false);
      } else {
        setOpen3(false);
      }
    } catch (error) {
      toast.error("Error al actualizar el espacio común.");
      console.error("Error al actualizar el espacio común", error.message);
    } finally {
      setAddRequestStatus("idle");
      setOpenEdit(false);
    }
  };

  return (
    <div>
      <Drawer
        anchor="right"
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        PaperProps={{
          sx: {
            width: { xs: "100%", sm: "75%", md: "50%" },
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
      >
        {seleItem && (
          <>
            <DialogTitle>
              <Grid container={true} spacing={1} px={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ display: "flex", gap: "5px" }}
                >
                  <ErrorIcon
                    style={{ fontSize: "3rem" }}
                    className={styles.iconBg}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h6" className={styles.textIconEdit}>
                      Actualizar espacio común {seleItem?.nombre}
                    </Typography>
                    <Typography variant="span" className={styles.textIconSpan}>
                      Modifica los campos para actualizar el espacio común.
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container={true} spacing={1} p={2}>
                <Grid item xs={12} sm={12}>
                  <label className={styles.label}>Campamento</label>
                  <Autocomplete
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    disablePortal
                    disableClearable
                    id="campamentos-autocomplete"
                    name="campamento"
                    value={selectedCampamento}
                    options={
                      Array.isArray(campamentos) && campamentos.length > 0
                        ? campamentos
                        : []
                    }
                    getOptionLabel={(option) => option.nombre}
                    onChange={(e, value) => handleChangeCampamento(e, value)}
                    renderInput={(params) => (
                      <TextField {...params} label="Selecciona un campamento" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label className={styles.label}>
                    Pabellón, Wing o Recinto
                  </label>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={pabellonWing}
                    label="Age"
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  >
                    <MenuItem value={1}>Pabellón</MenuItem>
                    <MenuItem value={2}>Wing</MenuItem>
                    <MenuItem value={3}>Recinto</MenuItem>
                  </Select>
                </Grid>
                {pabellonWing == 1 && (
                  <Grid item xs={12} sm={6}>
                    <label className={styles.label}>Pabellón</label>
                    <Autocomplete
                      disablePortal
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                      }}
                      disableClearable
                      id="sectores-autocomplete"
                      name="wing"
                      value={selectedPabellon}
                      options={filterPabellones ?? []}
                      getOptionLabel={(option) => option.identificador}
                      onChange={(e, value) => handleChangePabellon(e, value)}
                      renderInput={(params) => (
                        <TextField {...params} label="Selecciona un pabellón" />
                      )}
                    />
                  </Grid>
                )}
                {pabellonWing == 2 && (
                  <Grid item xs={12} sm={6}>
                    <label className={styles.label}>Wing</label>
                    <Autocomplete
                      disablePortal
                      disableClearable
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                      }}
                      id="sectores-autocomplete"
                      name="wing"
                      value={selectedWing}
                      options={filterWings ?? []}
                      getOptionLabel={(option) => option.nombre}
                      onChange={(e, value) => handleChangeWing(e, value)}
                      renderInput={(params) => (
                        <TextField {...params} label="Selecciona un wing" />
                      )}
                    />
                  </Grid>
                )}
                {/*{pabellonWing == 3 && ( 
                        <Grid item  xs={12} sm={6}>
                            <label className={styles.label}>Recinto</label>
                            <Autocomplete
                              disablePortal
                              disableClearable
                              id="sectores-autocomplete"
                              name="wing"
                              className={styles.textField}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                  },
                                  '&:hover fieldset': {
                                    border: 'none',
                                  },
                                  '&.Mui-focused fieldset': {
                                    border: 'none',
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  color:'#2d3037',
                                },
                                '& .MuiInputLabel-root': {
                                  color:'#2d3037',
                                },
                              }}
                              value={selectedOtro}
                              options={filterOtros ?? [] }
                              getOptionLabel={(option) => option.nombre}
                              onChange={handleChangeOtro}    
                              renderInput={(params) => <TextField {...params} label="Selecciona un recinto" />}
                          />
                        </Grid>
                      )}  */}
                <Grid item xs={12} sm={8}>
                  <label className={styles.label}>Nombre</label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name="nombre"
                    type="text"
                    value={formData?.nombre}
                    onChange={(e) => onChange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label className={styles.label}>Piso</label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name="piso"
                    type="number"
                    value={formData?.piso}
                    onChange={(e) => onChange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label className={styles.label}>Descripción</label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#2d3037",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2d3037",
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name="descripcion"
                    type="text"
                    multiline
                    rows={4}
                    maxRows={4}
                    value={formData?.descripcion}
                    onChange={(e) => onChange(e)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container={true} style={{ padding: "0 16px" }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={styles.buttonContainerEdit}
                >
                  <Button
                    onClick={handleEdit}
                    className={styles.bgColorEdit}
                    variant="contained"
                    disabled={!canSave}
                  >
                    {addRequestStatus == "loading" ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      "Actualizar"
                    )}
                  </Button>
                  <Button
                    onClick={() => setOpenEdit(false)}
                    className={styles.bgColorOutlined}
                    variant="outlined"
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        )}
      </Drawer>
    </div>
  );
}
