import styles from "./componentsReclamos.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { addNewReclamoHuesped } from "../../../redux/features/reclamos_sugerencias/reclamos_sugerenciaSlice";
import { liveSearchByRut } from "../../../redux/features/users/userSlice";
import { MuiFileInput } from "mui-file-input";
import { useEffect } from "react";
import { useAuth } from "../../../context/AppContext";
import CircleIcon from "@mui/icons-material/Circle";
import { fileTypeFromBuffer } from "file-type";
import { toast } from "react-toastify";

const data = {
  criticidadRequirimientoId: "",
  descripcion: "",
  habitacionId: "",
  rutHuesped: "",
  tipo: "",
  foto: "",
};

const Create = ({
  open2,
  setOpen2,
  motivos,
  campamentos,
  wings,
  pabellones,
  habitaciones,
  otros,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); //variable para abrir y cerrar modal

  const [selectedEspecialidad, setSelectedEspecialidad] = useState(null);
  const [selectedSolicitud, setSelectedSolicitud] = useState(null);
  const [selectedPrioridad, setSelectedPrioridad] = useState(null);
  const [selectedCriticidad, setSelectedCriticidad] = useState(null);
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [selectedHabitacion, setSelectedHabitacion] = useState(null);
  const [selectedPabellon, setSelectedPabellon] = useState(null);
  const [selectedWing, setSelectedWing] = useState(null);
  const [pabellonWing, setPabellonWing] = useState(0);
  const [selectedOtro, setSelectedOtro] = useState();
  const [habitacionesFiltradas, setHabitacionesFiltradas] = useState([]);

  const [ruts, setRuts] = useState([]);

  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [value, setValue] = useState(null);
  const [newImage, setNewImage] = useState(null);

  const handleChangePW = (event) => {
    setPabellonWing(event.target.value);
  };

  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    if (newValue != null) {
      setFormData({ ...formData, campamento: newValue.id });
      setSelectedWing(null);
      setSelectedPabellon(null);
    } else {
      setFormData({ ...formData, campamento: 0 });
    }
  };
  const handleChangeHabitacion = (event, newValue) => {
    setSelectedHabitacion(newValue);
    if (newValue != null) {
      setFormData({ ...formData, habitacionId: newValue.id });
    } else {
      setFormData({ ...formData, habitacionId: 0 });
    }
  };
  const handleChangePabellon = (event, newValue) => {
    console.log("pabellon", newValue);
    if (newValue != null) {
      setSelectedWing(null);
      setFormData({
        ...formData,
        pabellon: newValue.id,
        wing: null,
        edificio: null,
      });
      setSelectedPabellon(newValue);
      const habitacionesFiltradas = habitaciones.filter(
        (habitacion) => habitacion.pabellonId === newValue.id
      );
      setHabitacionesFiltradas(habitacionesFiltradas);
    } else {
      setFormData({ ...formData, pabellon: 0 });
      setHabitacionesFiltradas([]);
    }
  };
  const handleChangeWing = (event, newValue) => {
    console.log("wing", newValue);
    if (newValue != null) {
      setSelectedPabellon(null);
      setFormData({ ...formData, wing: newValue.id, pabellon: null });
      setSelectedWing(newValue);
      const habitacionesFiltradas = habitaciones.filter(
        (habitacion) => habitacion.wingId === newValue.id
      );
      setHabitacionesFiltradas(habitacionesFiltradas);
    } else {
      setFormData({ ...formData, wing: 0 });
      setHabitacionesFiltradas([]);
    }
  };

  const handleChangeOtro = (event, newValue) => {
    if (newValue != null) {
      setSelectedPabellon(null);
      setSelectedWing(null);
      setFormData({
        ...formData,
        wing: null,
        pabellon: null,
        edificio: newValue.id,
      });
      setSelectedOtro(newValue);
    } else {
      setFormData({ ...formData, wing: 0 });
    }
  };

  const onChangeRut = async (e) => {
    try {
      setOpen2(true);
      const resp = await dispatch(liveSearchByRut(e.target.value));
      setRuts(resp.payload.data);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen2(false);
    }
  };
  const onChangeRutSelect = async (e, newValue) => {
    console.log(newValue.rut);
    if (newValue != null) {
      setFormData({ ...formData, rutHuesped: newValue });
    } else {
      setFormData({ ...formData, rutHuesped: null });
    }
  };

  const handleChangeEspecialidad = (event, newValue) => {
    console.log(newValue);
    setSelectedPrioridad("");
    if (newValue != null) {
      setFormData({
        ...formData,
        especialidad: newValue.nombre,
        solicitud: "",
        prioridad: "",
        id: "",
      });
      setSelectedEspecialidad(newValue);
      setSelectedSolicitud(null);
      setSelectedPrioridad("");
    } else {
      setFormData({
        ...formData,
        especialidad: "",
        solicitud: "",
        prioridad: "",
        id: "",
      });
    }
  };
  const handleChangeSolicitud = (event, newValue) => {
    console.log(newValue, "solicutud");
    if (newValue != null) {
      setFormData({
        ...formData,
        solicitud: newValue.solicitud,
        prioridad: newValue.prioridad,
        id: newValue.id,
      });
      setSelectedSolicitud(newValue);
      setSelectedPrioridad(newValue.prioridad);
    } else {
      setFormData({ ...formData, solicitud: "", prioridad: "", id: "" });
      setSelectedPrioridad(null);
    }
  };
  const onChangeMotivo = (event, newValue) => {
    console.log(newValue, "solicutud");
    if (newValue != null) {
      setFormData({ ...formData, criticidadRequirimientoId: newValue.id });
      setSelectedCriticidad(newValue);
      // setSelectedSolicitud(newValue);
      // setSelectedPrioridad(newValue.prioridad);
    } else {
      setFormData({ ...formData, solicitud: "", prioridad: "", id: "" });
      setSelectedPrioridad(null);
    }
  };

  const groupBySpecialty = (motivos) => {
    const grouped = {};
    motivos.forEach((item) => {
      if (!grouped[item.especialidad]) {
        grouped[item.especialidad] = [];
      }
      grouped[item.especialidad].push({
        id: item.id,
        solicitud: item.solicitud,
        prioridad: "Alta",
      }); // Puedes ajustar la prioridad según tus necesidades
    });
    return grouped;
  };

  // Función para convertir el grupo en el formato deseado
  const convertToDesiredFormat = (groupedData) => {
    return Object.keys(groupedData).map((key, index) => ({
      id: index + 1,
      nombre: key,
      options: groupedData[key],
    }));
  };

  // Convertir y mostrar el resultado
  const groupedBySpecialty = groupBySpecialty(motivos);
  const convertedData = convertToDesiredFormat(groupedBySpecialty);
  // console.log('data nueva',JSON.stringify(convertedData, null, 2));

  const { info } = useAuth();
  useEffect(() => {
    setFormData({ ...formData, rutHuesped: info?.rut });
  }, [info]);

  const handleFileChange = async (newValue) => {
    const fileBuffer = await newValue.arrayBuffer();
    const type = await fileTypeFromBuffer(fileBuffer);

    if (
      !type ||
      !["image/png", "image/jpeg", "image/jpg"].includes(type.mime)
    ) {
      return false;
    }
    return true;
  };

  const handleChange = async (newValue) => {
    if (newValue) {
      const isValidFile = await handleFileChange(newValue);

      if (!isValidFile) {
        toast.error("Tipo de archivo no permitido.");
        return;
      }

      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(newValue.type)) {
        toast.error("Tipo de archivo no permitido.");
        return;
      }
      if (newValue.size > 5 * 1024 * 1024) {
        toast.error("El archivo es demasiado grande.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setNewImage(reader.result);
      };
      reader.readAsDataURL(newValue);

      setValue(newValue);
      setFormData({ ...formData, foto: newValue });
    } else {
      toast.error("Tipo de archivo no permitido.");
    }
  };

  // const handleChange = async (newValue) => {
  //   if (newValue) {
  //     const isValidFile = await handleFileChange(newValue);

  //     if (!isValidFile) {
  //       toast.error("Tipo de archivo no permitido.");
  //       return;
  //     }

  //     if (newValue.size > 5 * 1024 * 1024) {
  //       toast.error("El archivo es demasiado grande.");
  //       return;
  //     }

  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setNewImage(reader.result);
  //     };
  //     reader.readAsDataURL(newValue);

  //     setValue(newValue);
  //     setFormData({ ...formData, foto: newValue });
  //   }
  // };

  //   const handleChange = (newValue) => {
  //     setValue(newValue);
  //     setFormData({...formData, foto: newValue });

  //     if (newValue) {
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //             setNewImage(reader.result);
  //         };
  //         reader.readAsDataURL(newValue);
  //     } else {
  //         setNewImage(null);
  //     }
  // }

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const canSave =
    [
      formData.rutHuesped,
      formData.tipo,
      formData.criticidadRequirimientoId,
      formData.foto,
    ].every(Boolean) && addRequestStatus === "idle";

  const handleSubmit = async () => {
    if (canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus("loading");
        let resp = await dispatch(addNewReclamoHuesped(formData));
        console.log(resp);
        if (resp.payload.status === "success") {
          setFormData(data);
          setValue(null);
          setSelectedCriticidad();
          setOpen(false);
          setNewImage();
        }
      } catch (error) {
        // toast.error('Error al añadir el registro.');
        console.error("Error al añadir el registro", error.message);
      } finally {
        setOpen2(false);
        setAddRequestStatus("idle");
      }
    }
  };

  return (
    <div>
      {/*
       */}
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          El siguiente <strong>botón</strong> te mostrará un formulario para
          poder ingresar un reclamo y una sugerencia al sistema.
        </Typography>
        <Button
          variant="contanied"
          size="large"
          className={styles.bgColor}
          onClick={() => setOpen(true)}
        >
          <CircleIcon className={styles.iconButton} /> Añadir registro
        </Button>
      </div>
      <Dialog
        open={open}
        maxWidth="md"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display: "flex", gap: "5px" }}>
          <TextsmsIcon
            className={styles.iconBgCreate}
            style={{ fontSize: "3rem", marginTop: "0px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="span"
              style={{ fontSize: "24px", lineHeight: "1" }}
            >
              Rergistrar Reclamo/Sugerencia
            </Typography>
            <span className={styles.subTitle}>
              Rellena los siguientes campos para ingresar el registro.
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            {/*  <Grid item  xs={12} sm={6} >
              <label className={styles.label}>Campamento</label>
              <Autocomplete
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                options={campamentos}
                name="idCampamento"
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value)=> handleChangeCampamento(e, value)}
                renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Pabellón, Wing, Recinto</label>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={pabellonWing}
                label="Age"
                onChange={handleChangePW}
                style={{ width:'100%' }}
              >
                <MenuItem value={1}>Pabellón</MenuItem>
                <MenuItem value={2}>Wing</MenuItem>
                <MenuItem value={3}>Recinto</MenuItem>
              </Select>
            </Grid> */}
            {/* {selectedSector && ( */}
            {/*{pabellonWing == 1 && ( 
              <Grid item  xs={12} sm={6}>
                  <label className={styles.label}>Pabellón</label>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    id="sectores-autocomplete"
                    name="wing"
                    value={selectedPabellon}
                    options={pabellones ? pabellones : [] }
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          height: '200px', 
                          overflowY: 'auto',
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option.identificador}
                    onChange={(e, value) => handleChangePabellon(e, value)}    
                    renderInput={(params) => <TextField {...params} label="Selecciona un pabellón" />}
                />
              </Grid>
            )}  */}
            {/* {selectedCampamento && ( */}
            {/*{pabellonWing == 2 && ( 
              <Grid item  xs={12} sm={6}>
                  <label className={styles.label}>Wing</label>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    id="sectores-autocomplete"
                    name="wing"
                    value={selectedWing}
                    options={wings ?  wings : [] }
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          height: '100px', 
                          overflowY: 'auto',
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option.nombre}
                    onChange={(e, value) => handleChangeWing(e, value)}    
                    renderInput={(params) => <TextField {...params} label="Selecciona un wing" />}
                />
              </Grid>
            )} 
            {pabellonWing == 3 && ( 
              <Grid item  xs={12} sm={12}>
                  <label className={styles.label}>Recinto</label>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    id="sectores-autocomplete"
                    name="wing"
                    value={selectedOtro}
                    options={otros ?  otros : [] }
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          maxHeight: '100px', 
                          overflowY: 'auto',
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option.nombre}
                    onChange={handleChangeOtro}    
                    renderInput={(params) => <TextField {...params} label="Selecciona un recinto" />}
                />
              </Grid>
            )} 
            <Grid item  xs={12} sm={6} >
              <label className={styles.label}>Habitación</label>
              <Autocomplete
                disablePortal
                disableClearable
                id="habitacion-autocomplete"
                options={habitacionesFiltradas}
                name="idHabitacion"
                getOptionLabel={(option) => option.numero}
                onChange={(e, value)=> handleChangeHabitacion(e, value)}
                renderInput={(params) => <TextField {...params} label="Selecciona una habitación" />}
              />
            </Grid> */}
            {/*<Grid item  xs={12} sm={6} >
                <label className={styles.label}>Rut</label>
                <Autocomplete
                    disablePortal
                    disableClearable
                    id="sectores-autocomplete"
                    name="rutHuesped"
                    value={formData?.rutHuesped || ''}
                    options={ruts ? ruts : [] }
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          maxHeight: '200px', 
                          overflowY: 'auto',
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option ? option?.rut + ' ' + option?.nombre : ''}
                    onChange={(e, value) => onChangeRutSelect(e, value)}
                    renderInput={(params) => <TextField {...params} label="Selecciona un rut" onChange={(e) => onChangeRut(e)}/>}
                />
              </Grid> */}
            <Grid item xs={12} sm={6}>
              <label className={styles.label}>Tipo</label>
              <Select
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.tipo}
                name="tipo"
                onChange={(e) => onChange(e)}
                style={{ width: "100%" }}
              >
                <MenuItem value="Reclamo">Reclamo</MenuItem>
                <MenuItem value="Sugerencia">Sugerencia</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className={styles.label}>Foto</label>
              <MuiFileInput
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-clearIndicator": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    color: "#2d3037",
                  },
                  "& .MuiFormHelperText-root": {
                    color: "#2d3037",
                  },
                }}
                inputProps={{ accept: "image/png, image/jpeg, image/jpg" }}
                value={value}
                onChange={handleChange}
                helperText="Extensiones validas: .jpg, .jpeg, .png"
                style={{ width: "100%", color: "#FFF" }}
              />
            </Grid>
            {newImage && (
              <Grid
                item
                xs={12}
                sm={12}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <img
                  style={{
                    height: "auto",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={newImage}
                  alt="img_bodega"
                />
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Motivo</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-clearIndicator": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    color: "#2d3037",
                  },
                }}
                disablePortal
                disableClearable
                id="sectores-autocomplete"
                name="rut"
                value={selectedCriticidad || ""}
                options={motivos ? motivos : []}
                getOptionLabel={(option) =>
                  option ? option?.especialidad + " - " + option?.solicitud : ""
                }
                onChange={(e, value) => onChangeMotivo(e, value)}
                renderInput={(params) => (
                  <TextField {...params} label="Busca un motivo" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Descripción (opcional)</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-clearIndicator": {
                    color: "#2d3037",
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="descripcion"
                type="text"
                multiline
                rows={4}
                maxRows={4}
                onChange={(e) => onChange(e)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true} style={{ padding: "0 16px" }}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained"
                disabled={!canSave}
              >
                {addRequestStatus == "loading" ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Crear"
                )}
              </Button>
              <Button
                onClick={() => setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined"
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Create;

// <Grid item  xs={12} sm={6}>
//               {/* SELECT DE ESPECIALIDAD  */}
//               <label className={styles.label}>Especialidad</label>
//               <Autocomplete
//                 disablePortal
//                 id="campamentos-autocomplete"
//                 name="campamento"
//                 disableClearable
//                 options={Array.isArray(convertedData) && convertedData.length > 0 ? convertedData : []}
//                 getOptionLabel={(option) => option.nombre}
//                 PopperComponent={(props) => (
//                   <Popper
//                     {...props}
//                     sx={{
//                       maxHeight: '200px',
//                       overflowY: 'auto',
//                     }}
//                   />
//                 )}
//                 onChange={(e, value)=> handleChangeEspecialidad(e, value)}
//                 renderInput={(params) => <TextField {...params} label="Selecciona una especialidad" />}
//               />
//             </Grid>
//             {selectedEspecialidad ?
//               <Grid item  xs={12} sm={12}>
//               {/* SELECT DE SOLICITUD QUE DEPENDE DE LA ESPECIALIDAD SELECCIONADA  */}
//                 <label className={styles.label}>Solicitud</label>
//                 <Autocomplete
//                   disablePortal
//                   disableClearable
//                   id="campamentos-autocomplete"
//                   name="solicitud"
//                   value={selectedSolicitud}
//                   options={selectedEspecialidad.options}
//                   getOptionLabel={(option) => option.solicitud}
//                   PopperComponent={(props) => (
//                     <Popper
//                       {...props}
//                       sx={{
//                         maxHeight: '200px',
//                         overflowY: 'auto',
//                       }}
//                     />
//                   )}
//                   onChange={(e, value)=> handleChangeSolicitud(e, value)}
//                   renderInput={(params) => <TextField {...params} label="Selecciona una solicitud" />}
//                 />
//               </Grid>
//               :<Grid item  xs={12} sm={12}>
//                 <label className={styles.label}>Seleccione una especialidad para poder seleccionar una solicitud</label>
//               </Grid>
//             }
