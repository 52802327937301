import styles from "./components.module.css";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import {
  Grid,
  Backdrop,
  Dialog,
  Button,
  DialogTitle,
  Autocomplete,
  DialogActions,
  TextField,
  DialogContent,
  Typography,
  Card,
  CircularProgress,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import {
  asociarPersonalOficina,
  getInfoByRut,
  getInfoByRutAdmUsuarios,
  liveSearchByRutAdmUsuarios,
} from "../../../../redux/features/users/userSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  asignarOficina,
  fetchReservasOficinas,
  reenviarClaveOficina,
} from "../../../../redux/features/oficinas/oficinaSlice";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  trabajadoresReservaOficina,
  trabajadorReservaOficina,
} from "../../../../lib/columns";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Asignar = ({ open, setOpen, seleItem }) => {
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [addRequestStatus2, setAddRequestStatus2] = useState("idle");
  const [searchValue, setSearchValue] = useState("");
  const [value, setValue] = useState(0);
  const [ruts, setRuts] = useState([]);
  const [openBack, setOpenBack] = useState(false);
  const [formData, setFormData] = useState({ ruts: [] });
  const [openTrab, setOpenTrab] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [claveChapa, setClaveChapa] = useState(null);
  const [filterOficinas, setFilterOficinas] = useState([]);
  const [selectedReserva, setSelectedReserva] = useState([]);
  const [prevNewValue, setPrevNewValue] = useState([]);
  const finalColumns = trabajadoresReservaOficina(
    setSelectedReserva,
    setOpenTrab,
    setClaveChapa
  );

  const dispatch = useDispatch();
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const getReservasOficinas = async () => {
    setOpenBack(true);
    try {
      const resp = await dispatch(fetchReservasOficinas());
      const filterOficina = resp.payload.data.filter(
        (item) => item.oficina.id === seleItem.id
      );
      console.log("filterOficina-->: ", filterOficina);
      setFilterOficinas(filterOficina);
    } catch (error) {
      console.log("error al traer las reservas de la oficina", error);
    } finally {
      setOpenBack(false);
    }
  };

  useEffect(() => {
    console.log("seleItem", seleItem);
    if (seleItem) {
      getReservasOficinas();
      setFormData({ ...formData, oficina: seleItem?.id });
    }
  }, [seleItem]);

  const onChange = (e, index) => {
    const updatedRuts = [...formData.ruts];
    updatedRuts[index] = {
      ...updatedRuts[index],
      [e.target.name]: e.target.value,
    };
    setFormData({ ...formData, ruts: updatedRuts });
  };

  const onChange2 = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (!searchValue) return;

    const handler = setTimeout(async () => {
      try {
        setOpenBack(true);
        const resp = await dispatch(liveSearchByRutAdmUsuarios(searchValue));
        console.log(resp);

        setRuts(resp.payload);
      } catch (error) {
        console.log(error);
      } finally {
        setOpenBack(false);
      }
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue, dispatch]);

  const onChangeRutInput = (e) => {
    setSearchValue(e.target.value);
  };

  const onChangeRutSelect = async (e, newValue) => {
    console.log(newValue); // `newValue` es un array cuando `multiple` está habilitado

    // Verifica si se eliminó un elemento usando filter
    if (newValue.length < prevNewValue.length) {
      // Filtra los `ruts` que existen en `newValue`
      const filteredRuts = formData.ruts.filter((item) =>
        newValue.some((newItem) => newItem.rut === item.rut)
      );

      setFormData({ ...formData, ruts: filteredRuts });
      setPrevNewValue(newValue);
      return; // No ejecutes la lógica cuando se quita un elemento
    }

    if (newValue.length > 0) {
      try {
        setOpenBack(true);
        const rutsInfo = await Promise.all(
          newValue.map(async (item, index) => {
            const resp = await dispatch(getInfoByRutAdmUsuarios(item.rut));
            if (index === newValue.length - 1 && !resp.payload.email) {
              toast.info(
                `El personal seleccionado: ${resp.payload.nombre} no presenta un correo registrado.`
              );
            }
            return {
              rut: item.rut,
              nombre: resp.payload.nombre ?? "",
              apellidoPaterno:
                resp.payload.apellidoPaterno ??
                resp.payload.apellidos ??
                resp.payload.apellido ??
                "",
              apellidoMaterno: resp.payload.apellidoMaterno ?? "",
              email: resp.payload.email ?? "",
            };
          })
        );

        // Actualizar el estado con los datos completos de cada `rut`
        setFormData({ ...formData, ruts: rutsInfo });
        console.log("info de los usuarios", rutsInfo);
      } catch (error) {
        console.log(error);
      } finally {
        setOpenBack(false);
      }
    } else {
      setFormData({ ...formData, ruts: [] });
    }

    // Actualiza el valor anterior
    setPrevNewValue(newValue);
  };

  const handleSubmit = async () => {
    console.log("formData: ", formData);
    try {
      setOpenBack(true);
      setAddRequestStatus("loading");

      // Ejecuta todas las promesas al mismo tiempo y espera a que todas se resuelvan
      const promises = formData.ruts.map((item) =>
        dispatch(
          asignarOficina({
            rut: item,
            fechaInicio: formData.desde,
            fechaHasta: formData.hasta,
            oficinaId: seleItem.id,
          })
        )
      );

      // Espera que todas las promesas terminen
      const results = await Promise.all(promises);

      // Verifica si todas las asignaciones fueron exitosas
      const success = results.every(
        (resp) => resp.payload?.status === "success"
      );

      if (success) {
        setFormData({ ruts: [] });
        getReservasOficinas();
        setValue(0);
        // setOpen(false);
      } else {
        console.error("Error: Una o más asignaciones fallaron");
      }
    } catch (error) {
      console.error("<<--- Error --->>", error);
    } finally {
      setAddRequestStatus("idle");
      setOpenBack(false);
    }
  };

  const reenviar = async (rut) => {
    console.log("a: ", {
      rut,
      fechaInicio: selectedReserva.fechaInicio,
      fechaTermino: selectedReserva.fechaTermino,
    });

    try {
      setOpenBack(true);
      setAddRequestStatus2("loading");
      const resp = await dispatch(
        reenviarClaveOficina({
          rut,
          fechaInicio: selectedReserva.fechaInicio,
          fechaTermino: selectedReserva.fechaTermino,
        })
      );
      console.log("resp", resp);
      if (resp.payload.status === "success") {
        return;
      }
    } catch (error) {
      console.error("Error al crear el usuario", error);
    } finally {
      setAddRequestStatus2("idle");
      setOpenBack(false);
    }
  };

  const reenviarAll = async () => {
    try {
      setOpenBack(true);
      setAddRequestStatus2("loading");

      // Ejecuta todas las promesas en paralelo y espera a que todas terminen
      const results = await Promise.all(
        selectedReserva.trabajadores.map((item) =>
          dispatch(
            reenviarClaveOficina({
              rut: item.rut,
              fechaInicio: selectedReserva.fechaInicio,
              fechaTermino: selectedReserva.fechaTermino,
            })
          )
        )
      );

      const success = results.every(
        (resp) => resp.payload?.status === "success"
      );

      if (success) {
        console.log("Todas las claves fueron reenviadas con éxito");
      } else {
        console.error("Error: Una o más claves no pudieron ser reenviadas");
      }
    } catch (error) {
      console.error("Error al reenviar las claves", error);
    } finally {
      setAddRequestStatus2("idle");
      setOpenBack(false);
    }
  };

  const finalColumnsTrabajadore = trabajadorReservaOficina(reenviar);

  return (
    <>
      <Dialog
        open={openTrab}
        maxWidth="md"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        onBackdropClick={() => {
          setOpenTrab(false);
        }}
      >
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item xs={12} sm={4}>
              <label className={styles.label}>Clave chapa oficina</label>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2d3037",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2d3037",
                  },
                }}
                variant="outlined"
                fullWidth
                name="email"
                value={claveChapa}
                disabled
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <DataGrid
                getRowId={(row) => (row.id ? row.id : Math.random())}
                lots={{ toolbar: GridToolbar }}
                rows={selectedReserva.trabajadores || []}
                columns={finalColumnsTrabajadore}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 20]}
                localeText={{
                  ...esES.components.MuiDataGrid.defaultProps.localeText,
                  noRowsLabel: "No hay datos disponibles",
                  pagination: {
                    labelRowsPerPage: "Filas por página:",
                  },
                }}
                classes={{ menuIcon: styles.menuIcon }}
                style={{
                  maxHeight: "500px",
                  color: "#FFF",
                  border: "none",
                }}
                sx={{
                  "& .MuiDataGrid-cell": {
                    color: "#2d3037",
                    borderBottom: "1px solid #c6c6c6",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    color: "#2d3037",
                    borderBottom: "1px solid #c6c6c6",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    borderBottom: "1px solid #c6c6c6",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    color: "#2d3037",
                    borderTop: "1px solid #c6c6c6",
                  },
                  "& .MuiTablePagination-root": {
                    color: "#2d3037",
                    borderTop: "1px solid #c6c6c6",
                  },
                  "& .MuiDataGrid-toolbarContainer": {
                    color: "#2d3037",
                  },
                  "& .MuiDataGrid-menu": {
                    color: "#2d3037",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#9ca91c",
                  },
                  "& .MuiTablePagination-actions": {
                    color: "#2d3037", // Color for pagination actions
                  },
                  "& .MuiTablePagination-select": {
                    color: "#2d3037", // Color for pagination select
                  },
                  "& .MuiTablePagination-selectLabel": {
                    color: "#2d3037", // Color for pagination select label
                  },
                  "& .MuiTablePagination-displayedRows": {
                    color: "#2d3037", // Color for displayed rows text
                  },
                  "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer":
                    {
                      color: "red",
                    },
                  "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root":
                    {
                      color: "red",
                    },
                }}
                slotProps={{
                  toolbar: {
                    sx: {
                      color: "#FFF",
                    },
                  },
                  pagination: {
                    sx: {
                      color: "#f19d38",
                    },
                  },
                  menu: {
                    sx: {
                      color: "#f19d38",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={12} className={styles.buttonContainer}>
            <Button
              onClick={reenviarAll}
              className={styles.bgColorModal}
              variant="contained"
              disabled={
                addRequestStatus2 === "loading" ||
                (selectedReserva.trabajadores &&
                  selectedReserva.trabajadores.length === 0)
              }
            >
              Reenviar clave a todos los trabajadores
            </Button>

            <Button
              onClick={() => setOpenTrab(false)}
              className={styles.bgColorOutlined}
              variant="outlined"
            >
              Cancelar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        maxWidth="lg"
        PaperProps={{
          sx: {
            backgroundColor: "#efefef",
            color: "#2d3037",
            borderRadius: "0",
          },
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBack}
        ></Backdrop>{" "}
        <DialogTitle style={{ display: "flex", gap: "5px" }}>
          <AutoModeIcon
            className={styles.iconBgCreate}
            style={{ fontSize: "3rem", marginTop: "0px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="span"
              style={{ fontSize: "24px", lineHeight: "1" }}
            >
              Asignar personal a la oficina {seleItem?.id} en{" "}
              {seleItem?.pabellon?.identificador
                ? seleItem.pabellon.identificador
                : seleItem?.wing?.nombre ?? ""}
              ,{" "}
              {seleItem?.pabellon?.campamento
                ? seleItem.pabellon?.campamento.nombre
                : seleItem?.wing?.campamento?.nombre ?? ""}
            </Typography>
            <span className={styles.subTitle}>
              Un correo será enviado al personal seleccionado.
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item xs={12} sm={6}>
              <Grid container={true}>
                <Card style={{ padding: "10px", width: "100%" }}>
                  <Grid item xs={12} sm={6}>
                    <label
                      className={styles.label}
                      style={{ fontWeight: "500" }}
                    >
                      ID
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label className={styles.label}>
                      {seleItem?.id ?? "-"}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "5px" }}>
                    <label
                      className={styles.label}
                      style={{ fontWeight: "500" }}
                    >
                      Chapa
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label className={styles.label}>
                      {seleItem?.chapa ?? "-"}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "5px" }}>
                    <label
                      className={styles.label}
                      style={{ fontWeight: "500" }}
                    >
                      Disponibilidad
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label className={styles.label}>
                      {seleItem?.disponibilidad ?? "-"}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "5px" }}>
                    <label
                      className={styles.label}
                      style={{ fontWeight: "500" }}
                    >
                      Razón disponibilidad
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label className={styles.label}>
                      {seleItem?.razonDisponibilidad ?? "-"}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "5px" }}>
                    <label
                      className={styles.label}
                      style={{ fontWeight: "500" }}
                    >
                      {seleItem?.pabellon?.campamento
                        ? "Campamento"
                        : seleItem?.wing?.campamento
                        ? "Campamento"
                        : ""}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label className={styles.label}>
                      {seleItem?.pabellon?.campamento
                        ? seleItem.pabellon?.campamento.nombre
                        : seleItem?.wing?.campamento?.nombre ?? "-"}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "5px" }}>
                    <label
                      className={styles.label}
                      style={{ fontWeight: "500" }}
                    >
                      {seleItem?.pabellon
                        ? "Pabellón"
                        : seleItem?.pabellon
                        ? "Wing"
                        : ""}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label className={styles.label}>
                      {seleItem?.pabellon?.identificador
                        ? seleItem.pabellon.identificador
                        : seleItem?.wing?.nombre ?? "-"}
                    </label>
                  </Grid>

                  <Grid item xs={12} sm={12} style={{ marginTop: "5px" }}>
                    <label
                      className={styles.label}
                      style={{ fontWeight: "500" }}
                    >
                      Descripción
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ textAlign: "justify" }}>
                    <label className={styles.label}>
                      {seleItem?.descripcion ?? "-"}
                    </label>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tabs
                value={value}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
                style={{ borderRight: "none" }}
                sx={{
                  borderRight: 1,
                  borderColor: "divider",
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#FFF",
                    height: 3,
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: "#2d3037",
                  },
                  "& .MuiTab-root:not(.Mui-selected)": {
                    color: "gray",
                  },
                  "& .MuiTabs-root": {
                    borderRight: "none",
                  },
                }}
              >
                <Tab label="Reservas" {...a11yProps(0)} />
                <Tab label="Asignar" {...a11yProps(1)} />
              </Tabs>
              <CustomTabPanel
                value={value}
                index={0}
                style={{ padding: "0", margin: "0" }}
              >
                <Grid container={true} spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <DataGrid
                      getRowId={(row) => (row.id ? row.id : Math.random())}
                      lots={{ toolbar: GridToolbar }}
                      rows={filterOficinas}
                      columns={finalColumns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[5, 10, 15, 20]}
                      localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                        noRowsLabel: "No hay datos disponibles",
                        pagination: {
                          labelRowsPerPage: "Filas por página:",
                        },
                      }}
                      classes={{ menuIcon: styles.menuIcon }}
                      style={{
                        maxHeight: "500px",
                        color: "#FFF",
                        border: "none",
                      }}
                      sx={{
                        "& .MuiDataGrid-cell": {
                          color: "#2d3037",
                          borderBottom: "1px solid #c6c6c6",
                        },
                        "& .MuiDataGrid-columnHeader": {
                          color: "#2d3037",
                          borderBottom: "1px solid #c6c6c6",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          borderBottom: "1px solid #c6c6c6",
                        },
                        "& .MuiDataGrid-footerContainer": {
                          color: "#2d3037",
                          borderTop: "1px solid #c6c6c6",
                        },
                        "& .MuiTablePagination-root": {
                          color: "#2d3037",
                          borderTop: "1px solid #c6c6c6",
                        },
                        "& .MuiDataGrid-toolbarContainer": {
                          color: "#2d3037",
                        },
                        "& .MuiDataGrid-menu": {
                          color: "#2d3037",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#9ca91c",
                        },
                        "& .MuiTablePagination-actions": {
                          color: "#2d3037", // Color for pagination actions
                        },
                        "& .MuiTablePagination-select": {
                          color: "#2d3037", // Color for pagination select
                        },
                        "& .MuiTablePagination-selectLabel": {
                          color: "#2d3037", // Color for pagination select label
                        },
                        "& .MuiTablePagination-displayedRows": {
                          color: "#2d3037", // Color for displayed rows text
                        },
                        "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer":
                          {
                            color: "red",
                          },
                        "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root":
                          {
                            color: "red",
                          },
                      }}
                      slotProps={{
                        toolbar: {
                          sx: {
                            color: "#FFF",
                          },
                        },
                        pagination: {
                          sx: {
                            color: "#f19d38",
                          },
                        },
                        menu: {
                          sx: {
                            color: "#f19d38",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel
                value={value}
                index={1}
                style={{ padding: "0", margin: "0" }}
              >
                <Grid container={true} spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <label className={styles.label}>Desde</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name="desde"
                      type="date"
                      onChange={(e) => onChange2(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label className={styles.label}>Hasta</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name="hasta"
                      type="date"
                      onChange={(e) => onChange2(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <label className={styles.label}>Ruts</label>
                    <Autocomplete
                      multiple
                      disablePortal
                      disableClearable
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#2d3037",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#2d3037",
                        },
                      }}
                      id="sectores-autocomplete"
                      name="rut"
                      value={formData.ruts ?? []}
                      options={ruts ? ruts : []}
                      getOptionLabel={(option) =>
                        option ? `${option.rut} ${option.nombre}` : ""
                      }
                      onChange={(e, value) => onChangeRutSelect(e, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={searchValue}
                          label="Ingresa un rut para buscar un trabajador"
                          onChange={onChangeRutInput}
                        />
                      )}
                    />
                  </Grid>
                  {/*
                {formData?.ruts &&
                Array.isArray(formData?.ruts) &&
                formData?.ruts.length > 0 && (
                  <Grid item xs={12} sm={12}>
                    <label className={styles.label}>Correos</label>
                    <Grid
                      container={true}
                      style={{ maxHeight: "300px", overflowY: "auto" }}
                    >
                      {formData?.ruts.map((item, index) => (
                        <Grid item xs={12} sm={12} key={index}>
                          <label className={styles.label}>
                            {item.nombre} {item.apellidoPaterno}{" "}
                            {item.apellidoMaterno}
                          </label>
                          <TextField
                            className={styles.textField}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "none",
                                },
                                "&:hover fieldset": {
                                  border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "none",
                                },
                              },
                              "& .MuiInputBase-input": {
                                color: "#2d3037",
                              },
                              "& .MuiInputLabel-root": {
                                color: "#2d3037",
                              },
                            }}
                            variant="outlined"
                            fullWidth
                            name="email"
                            value={item.email}
                            type="text"
                            onChange={(e) => onChange(e, index)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
                */}
                </Grid>
              </CustomTabPanel>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={12} className={styles.buttonContainer}>
            <Button
              onClick={handleSubmit}
              className={styles.bgColorModal}
              variant="contained"
              disabled={
                formData?.ruts && formData?.ruts.length <= 0
                // ||!formData.ruts.every((item) => item.email)
              }
            >
              {addRequestStatus === "loading" ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "Asignar trabajadores"
              )}
            </Button>

            <Button
              onClick={() => setOpen(false)}
              className={styles.bgColorOutlined}
              variant="outlined"
            >
              Cancelar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Asignar;
