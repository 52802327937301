import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Autocomplete, Backdrop, Button, CircularProgress, Container, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCampamentos } from '../../../redux/features/campamentos/campamentoSlice';
import { useEffect } from 'react';
import { updateSector } from '../../../redux/features/sectores/sectorSlice';

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3 }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState(null);
    const dispatch = useDispatch();
    const campamentos = useSelector(getAllCampamentos);
    const [selectedBodega, setSelectedBodega] = useState(null);

    useEffect(() => {
            setFormData(seleItem)
    }, [seleItem])

    const handleChangeCampamento = (e, value) => setFormData({ ...formData, idCampamento: value ? value.id : null });
        
    const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });
    
    const handleChangeBodega = (event, newValue) => {
      setSelectedBodega(newValue);
      if(newValue != null){
        setFormData({...formData, bodega: newValue});
      } else{
        setFormData({...formData, bodega: 0})
      }
      
    };

    const canSave = [formData?.nombre
        // , formData?.campamentoId
    ].every(Boolean) && addRequestStatus === 'idle';

    const handleEdit = async () => {
        try {
            setOpen3(true);
            setAddRequestStatus('loading');
            // let resp = await dispatch(updateSector(formData))
            // if(resp.payload.status === 'success'){
            //     setOpen3(false);
            // }
        } catch (error) {
            console.error('Error al actualizar el item', error.message);
        } finally{
            setAddRequestStatus('idle');
            setOpenEdit(false);
        }
    }
    
    const bodegas = [
      {
      id:1,
      codigo: '13133',
      nombre: 'bodega 1',
      descripcion: 'registro falso',
      lat: 10.23213,
      alt: 12.2132,
    },
    {
      id:2,
      codigo: '32142',
      nombre: 'bodega 2',
      descripcion: 'registro falso',
      lat: 9.23213,
      alt: 0.2132,
    }
    ]

  return (
    <div>
      <Drawer
        anchor='right'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        PaperProps={{
          sx: {
           backgroundColor:'#efefef',
           color:'#2d3037',
           borderRadius:'0'
          }
        }}
      >
        {seleItem && (
            <Box width={1} mt={0} mb={2}>
                <Container >
                {/*{JSON.stringify(formData)} */}
                    <Grid container={true} spacing={1} p={2}>
                        <Grid item  xs={12} sm={12} mb={2}>
                            <Typography variant="h6" className={styles.textIcon}><ErrorIcon/>Estás a punto de actualizar el artículo {seleItem?.codigo}</Typography>
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                          <label className={styles.label}>Bodega</label>
                          <Autocomplete
                            disablePortal
                            id="campamentos-autocomplete"
                            options={bodegas}
                            value={formData?.bodega || ''}
                            name="bodega"
                            getOptionLabel={(option) => option.nombre}
                            onChange={(e, value)=> handleChangeBodega(e, value)}
                            renderInput={(params) => <TextField {...params} label="Selecciona una bodega" />}
                          />      
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                          <label className={styles.label}>Pasillo vertical (Ejemplo: V1)</label>
                          <TextField
                            variant="outlined"
                            fullWidth
                            value={formData?.p_vertical || ''}
                            name='p_vertical'
                            type="text"
                            onChange={(e) => onChange(e)}
                          />
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                          <label className={styles.label}>Pasillo horizontal (Ejemplo: H1)</label>
                          <TextField
                            variant="outlined"
                            fullWidth
                            name='p_horizontal'
                            value={formData?.p_horizontal || ''}
                            type="text"
                            onChange={(e) => onChange(e)}
                          />
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                          <label className={styles.label}>Ubicación (Ejemplo: Contenedor 1A)</label>
                          <TextField
                            variant="outlined"
                            fullWidth
                            name='ubicacion'
                            value={formData?.ubicacion || ''}
                            type="text"
                            onChange={(e) => onChange(e)}
                          />
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                          <label className={styles.label}>Altura (Ejemplo: 1)</label>
                          <TextField
                            variant="outlined"
                            fullWidth
                            name='altura'
                            value={formData?.altura || ''}
                            type="number"
                            onChange={(e) => onChange(e)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                            <Button 
                                onClick={handleEdit}
                                className={styles.bgColor}
                                variant="contained" 
                                disabled={!canSave}
                                >
                                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                            </Button>
                            <Button 
                                onClick={()=>setOpenEdit(false)}
                                className={styles.bgColorOutlined}
                                variant="outlined" 
                                >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        )}
      </Drawer>
    </div>
  );
}