import { useState, useRef, useEffect, memo } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Icon,
  CircleMarker,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Button, Grid, TextField, Autocomplete, Popper } from "@mui/material";
import styles from "./components.module.css";
import * as L from "leaflet";
import GeoCoderMarker from "./GeoCoderMarker";
import CircleIcon from "@mui/icons-material/Circle";

const Map = ({ position, setPosition, formData, setFormData }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const inputRef = useRef(null);
  const mapRef = useRef(null);

  const lat = formData ? parseFloat(formData.latitud) || 0 : 0;
  const lng = formData ? parseFloat(formData.longitud) || 0 : 0;
  // Asegurarse de que el mapa tenga un centro y un zoom inicial
  const initialCenter = position || { lat: -22.4359832, lng: -68.9249763 };
  const initialZoom = 13;

  useEffect(() => {
    inputRef.current.focus(); // Mantener el enfoque en el input
  }, [searchResults]);

  useEffect(() => {
    if (formData?.direccion) {
      setSearchQuery(formData.direccion);
    }
  }, [formData.direccion]);

  const searchAddress = async () => {
    if (!searchQuery) return;

    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
        searchQuery
      )}`
    );
    const data = await response.json();

    setSearchResults(data);
  };
  const handleSearch = () => {
    searchAddress();
  };
  const handleResultClick = (e, result) => {
    console.log("result-->-->", result);
    const lat = parseFloat(result.lat);
    const lon = parseFloat(result.lon);
    setPosition([lat, lon]);
    setFormData({
      ...formData,
      latitud: lat,
      longitud: lon,
      direccion: result.display_name,
    });
    setSearchResults([]);
    if (mapRef.current) {
      mapRef.current.setView([lat, lon]);
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <div style={{ position: "relative" }}>
          <label className={styles.label}>Busca una dirección</label>
          <div style={{ display: "flex", gap: "5px", width: "100%" }}>
            <TextField
              ref={inputRef}
              type="text"
              className={styles.textField}
              value={searchQuery}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#2d3037",
                },
                "& .MuiInputLabel-root": {
                  color: "#2d3037",
                },
              }}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Buscar dirección..."
              style={{ width: "100%", marginBottom: "8px" }}
            />
            <Button
              style={{
                backgroundColor: "#0086ae",
                color: "#fff",
                marginBottom: "8px",
                padding: "0 20px",
              }}
              onClick={handleSearch}
            >
              Buscar
            </Button>
          </div>
          {Array.isArray(searchResults) && searchResults.length > 0 && (
            <Autocomplete
              disablePortal
              className={styles.textField}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#2d3037",
                },
                "& .MuiInputLabel-root": {
                  color: "#2d3037",
                },
              }}
              id="campamentos-autocomplete"
              name="campamento"
              options={
                Array.isArray(searchResults) && searchResults.length > 0
                  ? searchResults
                  : []
              }
              getOptionLabel={(option) => option.display_name}
              disableClearable
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  sx={{
                    height: "200px",
                    overflowY: "auto",
                  }}
                />
              )}
              onChange={(e, value) => handleResultClick(e, value)}
              renderInput={(params) => (
                <TextField {...params} label="Selecciona una dirección" />
              )}
            />
          )}
          <label
            className={styles.label}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              marginTop: "10px",
            }}
          >
            Seleccione el marcador y arrástrelo
          </label>

          <MapContainer
            center={initialCenter}
            zoom={initialZoom}
            scrollWheelZoom={true}
            style={{ height: "480px", width: "100%" }}
            ref={mapRef}
          >
            <TileLayer
              attribution=""
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <GeoCoderMarker lat={lat} lng={lng} setPosition={setPosition} />
          </MapContainer>
        </div>
      </Grid>
    </>
  );
};

export default Map;

{
  /*
                <Marker
                position={position}
                    draggable={true}
                    
                    eventHandlers={{
                        dragend: (event) => {
                            setPosition(event.target.getLatLng());
                        }
                    }}
                    icon={icon}
                >
                    <Popup minWidth={90}>
                      <span>Arrastre el marcador</span>
                    </Popup>
                </Marker>                
                */
}
