import styles from "./components.module.css";
import { Backdrop, Box, Container, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import {
  vistaPersonalEspaciosColumns,
  vistaPersonalHabitacionesColumns,
  vistaPersonalOficinasColumns,
} from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import Delete from "./Delete";
import Edit from "./Edit";
import Edit2 from "./Edit2";
import ModalChange from "./ModalChange";
import PropTypes from "prop-types";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TableEdit = ({
  registros,
  oficinas,
  espacios,
  setOpen3,
  open3,
  tareasList,
  planificacionId,
  setOpenEditModal,
  getGrupo,
  getHabitacionesListasPlanificacionDiaria
}) => {
  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openModalChange, setOpenModalChange] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [aseo, setAseo] = useState(true);
  const [openModal, setOpenModal] = useState(true);
  const [type, setType] = useState("");
  const finalColumns = vistaPersonalHabitacionesColumns(
    setOpen,
    setSeleItem,
    setOpenModalChange,
    setType
  );
  const finalColumnsOficinas = vistaPersonalOficinasColumns(
    setOpen,
    setSeleItem,
    setOpenModalChange,
    setType
  );
  const finalColumnsEspacios = vistaPersonalEspaciosColumns(
    setOpen,
    setSeleItem,
    setOpenModalChange,
    setType
  );
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    console.log("seleItem aa: ", seleItem);
  }, [seleItem]);

  return (
    <div style={{ marginTop: "16px" }}>
      {/*<Edit openEdit={openEdit} setOpenEdit={setOpenEdit} seleItem={seleItem} setOpen3={setOpen3} open3={open3}/> */}
      <ModalChange
        seleItem={seleItem}
        setOpen3={setOpen3}
        open3={open3}
        tareasList={tareasList}
        planificacionId={planificacionId}
        openModal={openModalChange}
        setOpenModal={setOpenModalChange}
        type={type}
        setOpenEditModal={setOpenEditModal}
        getGrupo={getGrupo}
        getHabitacionesListasPlanificacionDiaria={getHabitacionesListasPlanificacionDiaria}
      />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTab-root": { color: "gray" },
            "& .MuiTab-root.Mui-selected": { color: "#2d3037" },
            "& .MuiTabs-indicator": { bgcolor: "#2d3037" },
          }}
        >
          <Tab label="Habitaciones" {...a11yProps(0)} />
          <Tab label="Oficinas" {...a11yProps(1)} />
          <Tab label="Espacios comunes" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <DataGrid
          getRowId={(row) => (row.id ? row.id : Math.random())}
          lots={{ toolbar: GridToolbar }}
          rows={registros}
          columns={finalColumns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15, 20]}
          localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
              labelRowsPerPage: "Filas por página:",
            },
          }}
          classes={{ menuIcon: styles.menuIcon }}
          style={{ maxHeight: "500px", color: "#FFF", border: "none" }}
          sx={{
            "& .MuiDataGrid-cell": {
              color: "#2d3037",
              borderBottom: "1px solid #c6c6c6",
            },
            "& .MuiDataGrid-columnHeader": {
              color: "#2d3037",
              borderBottom: "1px solid #c6c6c6",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "1px solid #c6c6c6",
            },
            "& .MuiDataGrid-footerContainer": {
              color: "#2d3037",
              borderTop: "1px solid #c6c6c6",
            },
            "& .MuiTablePagination-root": {
              color: "#2d3037",
              borderTop: "1px solid #c6c6c6",
            },
            "& .MuiDataGrid-toolbarContainer": {
              color: "#2d3037",
            },
            "& .MuiDataGrid-menu": {
              color: "#2d3037",
            },
            "& .MuiSvgIcon-root": {
              color: "#9ca91c",
            },
            "& .MuiTablePagination-actions": {
              color: "#2d3037", // Color for pagination actions
            },
            "& .MuiTablePagination-select": {
              color: "#2d3037", // Color for pagination select
            },
            "& .MuiTablePagination-selectLabel": {
              color: "#2d3037", // Color for pagination select label
            },
            "& .MuiTablePagination-displayedRows": {
              color: "#2d3037", // Color for displayed rows text
            },
            "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
              color: "red",
            },
            "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root":
              {
                color: "red",
              },
          }}
          slotProps={{
            toolbar: {
              sx: {
                color: "#FFF",
              },
            },
            pagination: {
              sx: {
                color: "#f19d38",
              },
            },
            menu: {
              sx: {
                color: "#f19d38",
              },
            },
          }}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <DataGrid
          getRowId={(row) => (row.id ? row.id : Math.random())}
          lots={{ toolbar: GridToolbar }}
          rows={oficinas}
          columns={finalColumnsOficinas}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15, 20]}
          localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
              labelRowsPerPage: "Filas por página:",
            },
          }}
          classes={{ menuIcon: styles.menuIcon }}
          style={{ maxHeight: "500px", color: "#FFF", border: "none" }}
          sx={{
            "& .MuiDataGrid-cell": {
              color: "#2d3037",
              borderBottom: "1px solid #c6c6c6",
            },
            "& .MuiDataGrid-columnHeader": {
              color: "#2d3037",
              borderBottom: "1px solid #c6c6c6",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "1px solid #c6c6c6",
            },
            "& .MuiDataGrid-footerContainer": {
              color: "#2d3037",
              borderTop: "1px solid #c6c6c6",
            },
            "& .MuiTablePagination-root": {
              color: "#2d3037",
              borderTop: "1px solid #c6c6c6",
            },
            "& .MuiDataGrid-toolbarContainer": {
              color: "#2d3037",
            },
            "& .MuiDataGrid-menu": {
              color: "#2d3037",
            },
            "& .MuiSvgIcon-root": {
              color: "#9ca91c",
            },
            "& .MuiTablePagination-actions": {
              color: "#2d3037", // Color for pagination actions
            },
            "& .MuiTablePagination-select": {
              color: "#2d3037", // Color for pagination select
            },
            "& .MuiTablePagination-selectLabel": {
              color: "#2d3037", // Color for pagination select label
            },
            "& .MuiTablePagination-displayedRows": {
              color: "#2d3037", // Color for displayed rows text
            },
            "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
              color: "red",
            },
            "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root":
              {
                color: "red",
              },
          }}
          slotProps={{
            toolbar: {
              sx: {
                color: "#FFF",
              },
            },
            pagination: {
              sx: {
                color: "#f19d38",
              },
            },
            menu: {
              sx: {
                color: "#f19d38",
              },
            },
          }}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <DataGrid
          getRowId={(row) => (row.id ? row.id : Math.random())}
          lots={{ toolbar: GridToolbar }}
          rows={espacios}
          columns={finalColumnsEspacios}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15, 20]}
          localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
              labelRowsPerPage: "Filas por página:",
            },
          }}
          classes={{ menuIcon: styles.menuIcon }}
          style={{ maxHeight: "500px", color: "#FFF", border: "none" }}
          sx={{
            "& .MuiDataGrid-cell": {
              color: "#2d3037",
              borderBottom: "1px solid #c6c6c6",
            },
            "& .MuiDataGrid-columnHeader": {
              color: "#2d3037",
              borderBottom: "1px solid #c6c6c6",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "1px solid #c6c6c6",
            },
            "& .MuiDataGrid-footerContainer": {
              color: "#2d3037",
              borderTop: "1px solid #c6c6c6",
            },
            "& .MuiTablePagination-root": {
              color: "#2d3037",
              borderTop: "1px solid #c6c6c6",
            },
            "& .MuiDataGrid-toolbarContainer": {
              color: "#2d3037",
            },
            "& .MuiDataGrid-menu": {
              color: "#2d3037",
            },
            "& .MuiSvgIcon-root": {
              color: "#9ca91c",
            },
            "& .MuiTablePagination-actions": {
              color: "#2d3037", // Color for pagination actions
            },
            "& .MuiTablePagination-select": {
              color: "#2d3037", // Color for pagination select
            },
            "& .MuiTablePagination-selectLabel": {
              color: "#2d3037", // Color for pagination select label
            },
            "& .MuiTablePagination-displayedRows": {
              color: "#2d3037", // Color for displayed rows text
            },
            "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
              color: "red",
            },
            "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root":
              {
                color: "red",
              },
          }}
          slotProps={{
            toolbar: {
              sx: {
                color: "#FFF",
              },
            },
            pagination: {
              sx: {
                color: "#f19d38",
              },
            },
            menu: {
              sx: {
                color: "#f19d38",
              },
            },
          }}
        />
      </CustomTabPanel>
    </div>
  );
};

export default TableEdit;
