import styles from "./components.module.css";
import { useState } from "react";
import { habitacionHabilitarColumns } from "../../../../lib/columns";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { Card, Dialog, DialogContent, Box } from "@mui/material";
import SkeletonTable from "../../../../components/Skeleton";
import { useDispatch } from "react-redux";
import { cambiarStateHabitacion } from "../../../../redux/features/habitaciones/habitacionSlice";
import ReenviarChapaHabitacion from "./ReenviarChapaHabitacion";

const TableHabilitar = ({ habitaciones, addRequestStatus, setOpen3 }) => {
  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openShow, setOpenShow] = useState(false);
  const [openAsignar, setOpenAsignar] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [selectedHabitacion, setSelectedHabitacion] = useState(null);
  const finalColumns = habitacionHabilitarColumns(
    setOpen,
    setSeleItem,
    setOpenShow,
    setOpenAsignar
  );
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCellValue, setSelectedCellValue] = useState("");
  const [selectedCellName, setSelectedCellName] = useState("");
  const [lastClickedColumn, setLastClickedColumn] = useState(null);

  const handleCellClick = (params) => {
    console.log(params);
    if (params.field === "opciones") return;
    setSelectedCellValue(params.value);
    setSelectedCellName(params.field);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleRowSelectionModelChange = async (selectionModel) => {
    console.log("HabitacionSeleccionada: ", selectionModel);
    const habitacion = habitaciones.find((it) => it.id === selectionModel[0]);
    const selection = selectionModel.length > 0 ? [selectionModel[0]] : [];
    setSelectedHabitacion(selection[0]);
    setOpen3(true);
    try {
      const resp = await dispatch(
        cambiarStateHabitacion({
          id: selection[0],
          habilitada: !habitacion.habilitada,
        })
      );
    } catch (error) {
      console.log("error al habilitar/dehabilitar la habitación:", error);
    } finally {
      setOpen3(false);
    }
  };

  return (
    <div style={{ width: "100%", marginTop: "10px" }}>
      {addRequestStatus == "loading" ? (
        <SkeletonTable />
      ) : (
        <Card style={{ backgroundColor: "#FFF" }}>
          <ReenviarChapaHabitacion
            setOpen={setOpenAsignar}
            open={openAsignar}
            seleItem={seleItem}
          />
          <DataGrid
            rowSelectionModel={selectedHabitacion ? [selectedHabitacion] : []}
            checkboxSelection={false}
            getRowId={(row) => (row.id ? row.id : Math.random())}
            lots={{ toolbar: GridToolbar }}
            rows={habitaciones}
            rowHeight={80}
            onCellClick={(params) => {
              console.log("params", params.field);
              if (params.field !== "opciones") {
                handleRowSelectionModelChange([params.id]);
              }
            }}
            columns={finalColumns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15, 20]}
            localeText={{
              ...esES.components.MuiDataGrid.defaultProps.localeText,
              noRowsLabel: "No hay datos disponibles",
              pagination: {
                labelRowsPerPage: "Filas por página:",
              },
            }}
            classes={{ menuIcon: styles.menuIcon }}
            style={{ maxHeight: "500px", color: "#FFF", border: "none" }}
            sx={{
              "& .MuiDataGrid-cell": {
                color: "#2d3037",
                borderBottom: "1px solid #c6c6c6",
              },
              "& .MuiDataGrid-columnHeader": {
                color: "#2d3037",
                borderBottom: "1px solid #c6c6c6",
              },
              "& .MuiDataGrid-columnHeaders": {
                borderBottom: "1px solid #c6c6c6",
              },
              "& .MuiDataGrid-footerContainer": {
                color: "#2d3037",
                borderTop: "1px solid #c6c6c6",
              },
              "& .MuiTablePagination-root": {
                color: "#2d3037",
                borderTop: "1px solid #c6c6c6",
              },
              "& .MuiDataGrid-toolbarContainer": {
                color: "#2d3037",
              },
              "& .MuiDataGrid-menu": {
                color: "#2d3037",
              },
              "& .MuiSvgIcon-root": {
                color: "#9ca91c",
              },
              "& .MuiTablePagination-actions": {
                color: "#2d3037", // Color for pagination actions
              },
              "& .MuiTablePagination-select": {
                color: "#2d3037", // Color for pagination select
              },
              "& .MuiTablePagination-selectLabel": {
                color: "#2d3037", // Color for pagination select label
              },
              "& .MuiTablePagination-displayedRows": {
                color: "#2d3037", // Color for displayed rows text
              },
              "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
                color: "red",
              },
              "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root":
                {
                  color: "red",
                },
            }}
            slotProps={{
              toolbar: {
                sx: {
                  color: "#FFF",
                },
              },
              pagination: {
                sx: {
                  color: "#f19d38",
                },
              },
              menu: {
                sx: {
                  color: "#f19d38",
                },
              },
            }}
          />
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            maxWidth="sm"
            fullWidth
          >
            <DialogContent>
              <Box>{selectedCellValue ?? "-"}</Box>
            </DialogContent>
          </Dialog>
        </Card>
      )}
    </div>
  );
};

export default TableHabilitar;
